<template>
   <!-- BLOCK EDITAR "TITULO" -->
    <div class="edicion_block">
        <h4>{{$t('drags.ubicacion')}}</h4>
        
        <div class="row form_row">
            <div class="col-12">
                <label>{{$t('drags.address')}}</label>
                <div class="form-group">
                    <input type="text"  maxlength="500" v-gmaps-searchbox='valor' class="form-control input_wicon input_website"  :placeholder="$t('general.entervalue')">
                </div>
            </div>
        </div>

    </div>
</template>

<script>



export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value
		}
    },  
	watch: {
		valor(val) {
			this.$emit('input', val);
		}
	},
}
</script>

