<template>
<div class="d-flex flex-column site_wrapper notfound"  >
  <img  src="@/assets/images/Inactive_Linckard.svg"/>
  <label class="notff">{{leyenda}}</label>
  <!--  <button type="button" class="notff_button" @click="$router.push('/login')">Volver</button>-->
  
</div>
</template>
<script>
export default {
    props:['leyenda'],
    data(){
        return{
            cantStars:this.value
        }
    }
}
</script>

<style>
.site_wrapper.notfound{
display:flex;
width:100%;
background:#0a90e1;
align-items:center;
text-align:center !important;
justify-content:center;
}
.notff{
  color:white !important;
}

.notff_button{
  display: block !important;
  background: #0a90e1 !important;
  color: white !important;
}

button,label{
visibility: visible !important;
}
</style>
