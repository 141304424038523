<template>
<div class="colapsables">
     <div class="edicion_block">
        <div  @click="show.s1=!show.s1" class="navcola manito"  style="border-top:0px !important;">
        <h4>{{$t('style.info.menu')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s1" v-show-slide="show.s1">
          
            <div class="row form_row">
                <div class="col-lg-6">
                    <colorPicker  :label="$t('pickers.colorfondo')"  :color="general.menu_sup.background" v-model="general.menu_sup.background"/>
                </div>
                <div class="col-lg-6">
                    <colorPicker :color="general.menu_sup.color" v-model="general.menu_sup.color" />
                </div>
            </div>
        </div>
    </div>
    <div  class="edicion_block" >
            <div  @click="show.s2=!show.s2" class="navcola manito" >
                <h4>{{$t('style.generales.fondo')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s2" v-show-slide="show.s2">
                <div class="row form_row">
                    <div class="col-lg-8">
                        <imagePicker imgid="background" :btext="$t('pickers.loadi')" v-model="membre.fondo.img" />
                    </div>
                    <div class="col-lg-4">
                        <switchPicker v-model='membre.fondo.active'/>
                    </div>
                    <div class="col-lg-6">
                        <switchPicker v-model='membre.fondo.animacion' :label="$t('pickers.movement')"/>
                    </div>
                    <div class="col-lg-6">
                        <opacityPicker v-model="membre.fondo.opacity"/>
                    </div>
                    <div class="col-lg-6">
                        <colorPicker :label="$t('pickers.colorfondo')"  :color="membre.fondo.background" v-model="membre.fondo.background" />
                    </div>
                    <div class="col-lg-6">
                        <sizePicker v-model="membre.fondo.width" :min='50' :max='1200' :interval="50"/>
                    </div>
                    <div class="col-lg-6">
                        <sizePicker :label="$t('pickers.hori')" v-model="membre.fondo.left" :min='-200' :max='200' :interval="10" unit="%"/>
                    </div>
                    <div class="col-lg-6">
                        <sizePicker :label="$t('pickers.verti')" v-model="membre.fondo.top" :min='-100' :max='200' :interval="10" />
                    </div>
                </div>
            </div>
    </div> 
    <div  class="edicion_block" >
            <div  @click="show.s3=!show.s3" class="navcola manito" >
        <h4>{{$t('style.info.nombre')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s3?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s3" v-show-slide="show.s3">
                <div class="row form_row">
                    <div class="col-12">
                        <fontPicker  v-model="membre.nombre.family"/>
                    </div>
                </div>
                <div class="row form_row">
                    
                    <div class="col-lg-6">
                        <colorPicker :color="membre.nombre.color"  v-model="membre.nombre.color" />
                    </div>
                    <div class="col-lg-6">
                        <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="membre.nombre.weight"/>
                    </div>
                    <div class="col-lg-6">
                        <sizePicker v-model="membre.nombre.size"/>
                    </div>
                </div>
            </div>
    </div>
    <div  class="edicion_block" >
            <div  @click="show.s4=!show.s4" class="navcola manito" >
                <h4>{{$t('style.info.mnumber')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s4?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s4" v-show-slide="show.s4">
                <div class="row form_row">
                    <div class="col-12">
                        <fontPicker  v-model="membre.cargo.family"/>
                    </div>
                </div>
                <div class="row form_row">
                    <div class="col-lg-6">
                        <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="membre.cargo.weight"/>
                    </div>
                    <div class="col-lg-6">
                        <sizePicker v-model="membre.cargo.size"/>
                    </div>
                </div>

                <div class="row form_row">
                    <div class="col-lg-12">
                        <colorPicker :color="membre.cargo.color" v-model="membre.cargo.color" />
                    </div>
                </div>
            </div>
    </div>
    <div  class="edicion_block" >
            <div  @click="show.s5=!show.s5" class="navcola manito" >
                <h4>{{$t('style.membre.punta')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s5?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s5" v-show-slide="show.s5">
                <div class="row form_row">
                    <div class="col-lg-6">
                        <colorPicker  :label="$t('pickers.colorfondo')"  :color="membre.creditos.background" v-model="membre.creditos.background"/>
                    </div>
                    <div class="col-lg-6">
                        <colorPicker :color="membre.creditos.color" v-model="membre.creditos.color" />
                    </div>
                </div>
            </div>
    </div>
    
    <div  class="edicion_block" >
            <div  @click="show.s6=!show.s6" class="navcola manito" >
                <h4>{{$t('style.membre.info')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s6?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s6" v-show-slide="show.s6">
                <div class="row form_row">
                    <div class="col-lg-6">
                        <colorPicker  :label="$t('pickers.colorfondo')"  :color="membre.datos.background" v-model="membre.datos.background"/>
                    </div>
                    <div class="col-lg-6">
                        <colorPicker :color="membre.datos.color" v-model="membre.datos.color" />
                    </div>
                </div>
            </div>
    </div>

    
    <!-- BLOCK EDITAR "CAJA DE LOGO" -->
    <div  class="edicion_block" >
            <div  @click="show.s7=!show.s7" class="navcola manito" >
                <h4>{{$t('style.info.caja')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s7?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s7" v-show-slide="show.s7">
                
                <div class="row form_row">
                    <div class="col-lg-8">
                        <imagePicker imgid="bottom_logo" v-model="membre.caja_logo.img" />        
                    </div>
                    <div class="col-lg-4">
                        <label class="switch">
                            <input type="checkbox" v-model='membre.caja_logo.active'>
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="col-lg-12">
                        <colorPicker :label="$t('pickers.colorfondo')"  :color="membre.caja_logo.background" v-model="membre.caja_logo.background" />
                    </div>
                    <div class="col-lg-12">
                        <label>{{$t('general.url')}}</label>
                        <input type="text" maxlength="150" v-validate="'url:require_protocol'" name="url" class="form-control input_wicon input_website"  :placeholder="$t('style.info.urlp')" v-model="membre.caja_logo.url">
                        <client-only><span class="vuerror">{{ errors.first('url') }}</span></client-only>
                    </div>
                </div>
            </div>
    </div>

</div>
</template>
<script>

import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import animacionPicker from './dinputs/animacionPicker'
import efectPicker from './dinputs/efectPicker'
import opacityPicker from './dinputs/opacityPicker'
import imagePicker from '@/components/imagePicker.vue'
import fontPicker from './dinputs/fontPicker'
import switchPicker from './dinputs/switchPicker'

export default {
    data(){
        return{
            show:{
                s1:false,s2:false,s3:false,s4:false,s5:false,s6:false,s7:false,s8:false,
            },
            colors:'',
        }
    },
  
    computed: {
        
        membre () { return this.$store.getters.editingLinckard.style.membre},
        general () { return this.$store.getters.editingLinckard.style.general}

    },
    methods:{

    },
    components: {
        'colorPicker':colorPicker,
        'fontPicker':fontPicker,
        'switchPicker':switchPicker,
        'imagePicker':imagePicker,
        'sizePicker':sizePicker,
        'animacionPicker':animacionPicker,
        'efectPicker':efectPicker,
        'opacityPicker':opacityPicker,
    }
}


</script>


<style>


</style>
