<template>
<div class="template_sample pthumb_parent_container" >
        <div style="height: 100%;" :style="generateCSS()" >
        <div class="pthumb_full_container pthumb_landing " :style="'background-color: ' + (preview.style?preview.style.sobre.fondo.background:null)" >
            <div v-if="preview.style && preview.style.sobre.fondo.active && preview.style.sobre.fondo.fullwidth" class="pthumb_background" :style="{'background-image':'url('+$util.getSobreBackImage(preview,null)}"></div>
            <div v-else-if="preview.style &&  preview.style.sobre.fondo.active && !preview.style.sobre.fondo.fullwidth"  class="pthumb_background"><div class="pthumb_bg_logo"><img :src='$util.getSobreBackImage(preview,null)' ></div></div>
            <div class="pthumb_content_wrapper pthumb_inner_wrapper">
                <template v-for="(element,index) in preview.sobredrags"  :style="{'animation-delay':  (animCounter+0.1*index)+ 's !important'  }">
                    <div v-if='(element.compo=="editParrafo" || element.compo=="editParrafo_new" ) && element.value && element.value.length<100' :key="index" class="pthumb_title" :style="'background-color: ' + element.color"> </div>
                    <div v-else-if='(element.compo=="editParrafo" || element.compo=="editParrafo_new" ) '  class="pthumb_description" :key="index">
                        <span class="pthumb_desc_line" :style="'background-color: ' + element.color" style="max-width: 100%;"></span>
                        <span class="pthumb_desc_line" :style="'background-color: ' + element.color" style="max-width: 100%;"></span>
                        <span class="pthumb_desc_line" :style="'background-color: ' + element.color" style="max-width: 30%;"></span>
                    </div>
                    <div v-else-if='element.compo=="editSocial"'  class="pthumb_description" :key="index">
                        <span class="pthumb_desc_line" :style="'background-color: ' + element.color" style="max-width: 10%;"></span>
                    </div>
                    <div v-else-if='element.compo=="editSpace"' class="pthumb_space" style="height: 0.5em;" :key="index"></div>
                    <div v-else-if='element.compo=="editBoton"' :style="'background-color: ' + element.background" class="pthumb_large_btn" style="background-color: #ccc" :key="index">
                        <span style="font-family: Arial;" :style="'color: ' + element.color">Button</span>
                    </div>
                    <!-- image -->

        
                    <div v-else-if='element.compo=="editImagen"' :alt="element.alt" class="pthumb_image" :key="index" >
                        <img :src="element.img"   @error="getSobreImagen_error" />
                    </div>
                    <div v-else-if='element.compo=="editAvatar"' class="tarjeta_foto_round" style="" :key="index" >
                        <img :src="element.img" @error="getSobreImagen_error"   />
                    </div>
                    <div v-else-if='element.compo=="editVideo"' class="pthumb_video" :key="index">
                        <img src="@/assets/images/tarjeta_video_preview.png">
                    </div>
                    <!-- video -->
                    <div v-else-if='element.compo=="editHTML"' class="pthumb_video" :key="index">
                        <img src="@/assets/images/tarjeta_code_preview.png">
                    </div>
                    <div v-else-if='element.compo=="editLine"' class="pthumb_video" :key="index"><hr> </div>
                    <!-- map -->
                    <div v-if='element.compo=="editMapa"' class="pthumb_map" :key="index">
                        <img src="@/assets/images/tarjeta_map_preview.png">
                    </div>
                </template>


            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:['estilo','type','info'],
    data(){
        return{
            animCounter:0.4,
            preview:this.$util.parseVariables(this.info),
            elstyle:this.estilo,
            nombre:'sobre'
        }
    },
    computed:{
        styleinfo(){
            return this.elstyle.info?this.elstyle.info:this.elstyle.membre
        }
    },
    methods:{

        getBackImage_error(event) {
            event.target.src = this.$util.getBackImage(this.preview,null)
        },
        getFooterImg_error(event) {
            event.target.src = this.$util.getFooterImg(this.preview,null)
        },
        getSobreImagen_error(event) {
            event.target.src = this.$util.getLincImage(this.preview,null)
        },
        getLincImage_error(event) {
            event.target.src = this.$util.getLincImage(this.preview,null)
        },

        getMapa(element){
        var mapskey='AIzaSyDhyuzW32WIEYtnmA3vdiGfTjQjZ_DyGlU';
        if(!element)return 'https://www.google.com/maps/embed/v1/place?key='+mapskey+'&q=Apple+Garage';
        var id=element.place_id;
        var address=element.formatted_address;
    
        
        if(id)address="place_id:"+id;
        return 'https://www.google.com/maps/embed/v1/place?key='+mapskey+'&q='+address;

        },
        generateCSS(){
            let style=this.elstyle
            let obj={}

            if(style && style['sobre']){
                obj['--template-fondo-background']=style[this.nombre]['fondo']['background']
                obj['--template-fondo-animacion']=style[this.nombre]['fondo']['animacion']?'spin':'none'
                obj['--template-fondo-opacity']=style[this.nombre]['fondo']['opacity']
                obj['--template-fondo-left']=style[this.nombre]['fondo']['left']
                obj['--template-fondo-top']=style[this.nombre]['fondo']['top']
                obj['--template-fondo-margin-left']=(parseInt(style[this.nombre]['fondo']['width'])/15*-1)+'px'
                obj['--template-fondo-width']=(parseInt(style[this.nombre]['fondo']['width'])/2.3)+'px'
            }

            return obj
        }
    },
	
}
</script>
<style scoped>


.pthumb_background {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: var(--template-fondo-opacity) !important;
}

.pthumb_background .pthumb_bg_logo{
    position:relative;
    opacity: var(--template-fondo-opacity) !important;
    width: var(--template-fondo-width) !important;
    left:var(--template-fondo-left) !important;
    top:var(--template-fondo-top) !important;
    animation-name:var(--template-fondo-animacion) !important;
    -ms-animation-name:var(--template-fondo-animacion) !important;
    -moz-animation-name:var(--template-fondo-animacion) !important;
    -webkit-animation-name:var(--template-fondo-animacion) !important;
    margin-left:var(--template-fondo-margin-left) !important;
    margin-top:20px;
}

.pthumb_background .pthumb_bg_logo  {
    width: 100%;
    -webkit-animation-duration: 70000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 70000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-duration: 70000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-duration: 70000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

}
/* Plantilla miniatura Landing (NOV 2020) */
.template_sample{

border-radius:10px;
}
.pthumb_landing {
	font-size: 6px;
	border-radius: 10px;
}

.pthumb_landing .pthumb_inner_wrapper {
	max-width: 17.33em;
	padding: 15px 0;
}

.pthumb_landing .pthumb_large_btn {
    margin: 0;
    margin-top:5px;
}

.pthumb_landing .pthumb_large_btn span {
    margin: auto;
    color:grey;
}

.pthumb_landing .pthumb_title {
	width: 100%;
	max-width: 60%;
	height: 2.5em;
	margin: 0 auto 1.42em;
}

.pthumb_landing .pthumb_description .pthumb_desc_line {
	width: 100%;
	height: 1.14em;
	margin: 0 auto 1.14em;
	display: block;
}

.pthumb_landing .pthumb_image,
.pthumb_landing .pthumb_video,
.pthumb_landing .pthumb_map {
	margin: 1.14em auto;
	display: table;
	border-radius: 2px;
	overflow: hidden;
}

.pthumb_landing .pthumb_image img,
.pthumb_landing .pthumb_video img,
.pthumb_landing .pthumb_map img {
	width: 100%;
	max-width: 100%;
}

.pthumb_landing .pthumb_hover_container {
	padding: 20px;
}



.pthumb_large_btn {
	width: 100%;
    height: 5em;
	margin: 0 0 1em;
	text-align: center;
	border-radius: .6em;
	display: inline-flex;
}



</style>