let db;
let functions;
let notifications;

const plans = {
    professional: { name: 'Professional' },
    enterprise: { name: 'Enterprise' },
    founder: { name: 'Founder' },
    free: { name: 'Freemium' },
    demo: { name: 'Demo' },
}

module.exports = {
    init(storeDatabase, firebaseFunctions, popupNotifications) {
        db = storeDatabase;
        functions = firebaseFunctions;
        notifications = popupNotifications
    },
    getUser: async (id) => {
        return await db
            .collection("users")
            .doc(id)
            .get()
            .then(function (querySnapshot) {
                return querySnapshot.data();
            })
            .catch(function (error) {
            });
    },
    getSectors: async (id) => {
        return (await module.exports.getUser(id)).sectors;
    },


    
    getPaymentStatus: async (user) => {
        const result = await functions.httpsCallable('getstripe_sub')({ profileInfo: user })
        notifications.success("Se leyo de Stripe el estado");
        return {
            ...module.exports.getPlan(user),
            ...result.data.subscriptions.data[0].plan.metadata,
            ...result.data.subscriptions.data[0].metadata,
            status: result.data.subscriptions.data[0].status,
            id: result.data.subscriptions.data[0].plan.nickname
        }
    },
    getPlan: (user) => {
        // Primero hardcodeos
        if (user.email == "daiana.bossana@sancorsalud.com.ar") return plans.enterprise;
        if (user.email == "cecilia.rossi@sancorsalud.com.ar") return plans.enterprise;
        if (user.email == "nordec-it@nordec.com") return plans.enterprise;
        if (user.email?.includes("inlat")) return plans.founder;
        if (user.email?.includes("efex")) return plans.founder;
        if (user.email?.includes("fafa.com")) return plans.founder;
        if (user.email?.includes("grosfeld")) return plans.founder;
        if (user.email == "fabrizio@linckard.com") return plans.founder;
        if (user.email == "fabrizio@linkcard.app") return plans.founder;
        if (user.email == "f@altoweb.co") return plans.founder;
        if (user.email == "roni@inlat.biz") return plans.founder;
        if (user.email == "roni@linkcard.app") return plans.founder;
        if (user.email == "nicolas@linkcard.app") return plans.founder;
        if (user.email == "demos@linkcard.app") return plans.demo;
        if (user.email == "demos1@linkcard.app") return plans.demo;
        if (user.email == "demos2@linkcard.app") return plans.demo;
        if (user.email == "demos3@linkcard.app") return plans.demo;
        if (user.email == "demos4@linkcard.app") return plans.demo;

        // Segundo francia

        // Tercero flags
        if (user.isManualPro) return plans.professional;
        if (user.isEnterprise) return plans.enterprise;
        if (user.isFounder) return plans.founder;
        if (user.isDemo) return plans.demo;

        // Cuarto plan de la DB
        if (user.stripe_details?.stripe_subscriptions.find(e => { return e.plan_id == 'freemium_' + type })) return plans.professional;

        // Quinto free!
        return plans.free;
    },
}