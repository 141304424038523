<template>
    <div :class="$util.getFooterImg(preview)?'template_sample manito':'template_sample h100 manito'" :style="generateCSS()" >
        <div style="height: 100%;" class="">
            <div class="pthumb_full_container " >
                <div class="pthumb_background">
                    <div v-if="preview && preview.style.info.fondo.active" class="pthumb_bg_logo">
                        <img :src='$util.getBackImage(preview,null)' width="100%" id="tarjetaBgLogo">
                    </div>
                </div>
                <ul class="pthumb_top_menu">
                    <li class="size_20" ></li>
                    <li class="size_20" ></li>
                    <li class="size_20" ></li>
                    <li class="size_20" ></li>
                </ul>
                <div class="pthumb_content_wrapper">
                    <div class="pthumb_photo" >
                        <img v-if="type=='loyalty'" :src="$util.getLincImageLoyalty(preview)" @error="function(event){event.target.src=$util.getLincImageLoyalty(preview,null)}">
                        <img v-else  :src="$util.getLincImage(preview)"  @error="function(event){event.target.src=$util.getLincImage(preview,null)}" class="w-100">
                    </div>
                    <ul v-if="type=='bc' && preview && preview.info" class="pthumb_name_wrapper_text">
                        <h4 v-if="preview.info.nombre.length>0">{{preview.info.nombre}}</h4>
                        <h4 v-else>{{$t('info.nombrecc')}}</h4>
                    </ul>
                    <ul v-else class="pthumb_name_wrapper">
                        <li class="size_45" ></li>
                        <li class="size_45" ></li>
                    </ul>
                    <ul class="pthumb_prof_wrapper sub">
                        <li class="size_60" ></li>
                        <li class="size_30" ></li>
                    </ul>
                    <template v-if="type=='loyalty'">
                        <div class="pthumb_large_btn" ></div>
                        <div class="pthumb_info_wrapper">
                            <ul class="pthumb_list_row">
                                <li class="size_25" ></li>
                                <li class="size_70" ></li>
                            </ul>
                            <ul class="pthumb_list_row">
                                <li class="size_30" ></li>
                                <li class="size_65" ></li>
                            </ul>
                            <ul class="pthumb_list_row">
                                <li class="size_15" ></li>
                                <li class="size_80" ></li>
                            </ul>
                            <ul class="pthumb_list_row">
                                <li class="size_25" ></li>
                                <li class="size_70" ></li>
                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <div class="pthumb_large_btn pthumb_large_btn_bnss main" ></div>
                        <div class="pthumb_btn_group">
                            <div class="pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0" ></div>
                            <div class="pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0" ></div>
                        </div>
                        
                        <ul class="pthumb_social_wrapper size_80">
                            <li ></li>
                            <li ></li>
                            <li ></li>
                            <li ></li>
                        </ul>

                        <ul class="pthumb_prof_wrapper desc">
                            <li class="size_60 d-table m-auto" ></li>
                        </ul>
                        <ul class="pthumb_desc_wrapper desc size_80">
                            <li class="size_30" ></li>
                            <li class="size_30" ></li>
                            <li class="size_30" ></li>
                        </ul>
                        <ul class="pthumb_desc_wrapper desc size_60" style="margin: .83em auto;background:none;">
                            <li class="size_45" ></li>
                            <li class="size_45" ></li>
                        </ul>
                    </template>
                </div>
                <div class="pthumb_foot" >
                   <img  :src="$util.getFooterImg(preview)"    @error="function(event){event.target.src=$util.getFooterImg(preview,null)}"  >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['estilo','type','info'],
    data(){
        return{
            preview:this.info,
            elstyle:this.estilo,
            nombre:this.type=='loyalty'?'membre':'info'
        }
    },
    computed:{
        styleinfo(){
            return this.elstyle.info?this.elstyle.info:this.elstyle.membre
        }
    },
    methods:{
        getBackImage_error(event) {
            event.target.src = this.$util.getBackImage(this.preview,null)
        },
        getFooterImg_error(event) {
            event.target.src = this.$util.getFooterImg(this.preview,null)
        },
        getLincImage_error(event) {
            console.log(event)
            event.target.src = this.$util.getLincImage(this.preview,null)
        },
        generateCSS(){
            let style=this.elstyle
            if(!style)return {}
            let obj= {
                '--template-general-menu_sup-background':style['general']['menu_sup']['background'],
                '--template-general-menu_sup-color':style['general']['menu_sup']['color'],
            }
            obj['--template-fondo-background']=style[this.nombre]['fondo']['background']
            obj['--template-fondo-animacion']=style[this.nombre]['fondo']['animacion']?'spin':'none'
            obj['--template-fondo-opacity']=style[this.nombre]['fondo']['opacity']
            obj['--template-fondo-left']=style[this.nombre]['fondo']['left']
            obj['--template-fondo-top']=style[this.nombre]['fondo']['top']
            obj['--template-fondo-margin-left']=(parseInt(style[this.nombre]['fondo']['width'])/15*-1)+'px'
            obj['--template-fondo-width']=(parseInt(style[this.nombre]['fondo']['width'])/2.3)+'px'
            obj['--template-nombre-color']=style[this.nombre]['nombre']['color'],
            obj['--template-cargo-color']=style[this.nombre]['cargo']['color'],
            obj['--template-caja_logo-background']=style[this.nombre]['caja_logo']['background']
            if(this.type=='loyalty'){
                obj['--template-datos-background']=style[this.nombre]['datos']['background']
                obj['--template-creditos-background']=style[this.nombre]['creditos']['background']
            }else{
                obj['--template-botones_c1-background']=style[this.nombre]['botones_c1']['background']
                obj['--template-botones_c2-background']=style[this.nombre]['botones_c2']['background']
                obj['--template-redes-color']=style[this.nombre]['redes']['color']
            }

            return obj
        }
    },
	
}
</script>
<style scoped>
/*BC*/
.pthumb_desc_wrapper.desc li,.pthumb_prof_wrapper.desc li{

    background-color: var(--template-redes-color);
}
.pthumb_social_wrapper li{
    background-color: var(--template-redes-color);
}
.pthumb_large_btn.main{

    background-color: var(--template-botones_c1-background);
}
.pthumb_large_btn.pthumb_btn_sm{
    background-color: var(--template-botones_c2-background);
    
}
.pthumb_name_wrapper li{
    background-color: var(--template-nombre-color);
}
.size_60,.size_30,.pthumb_prof_wrapper li{
    background-color: var(--template-cargo-color);
}
.pthumb_full_container{
    background-color: var(--template-fondo-background);
    
}
.pthumb_top_menu{

    background-color: var(--template-general-menu_sup-background);
}
.pthumb_top_menu li{

    background-color: var(--template-general-menu_sup-color);
}
.size_45{
    background-color: var(--template-nombre-color);
}
.pthumb_foot{
    background-color: var(--template-caja_logo-background);

}
.pthumb_large_btn,
.pthumb_mid_text .pthumb_list_row li{
    background-color: var(--template-creditos-background);
}
.pthumb_info_wrapper .pthumb_list_row li,
.pthumb_desc_wrapper li{
    background-color: var(--template-datos-background);
}

.pthumb_background {
    position: absolute;
    width: 100%;
    height: 100%;
}
.pthumb_background .pthumb_bg_logo{
    position:relative;
    opacity: var(--template-fondo-opacity) !important;
    width: var(--template-fondo-width) !important;
    left:var(--template-fondo-left) !important;
    top:var(--template-fondo-top) !important;
    animation-name:var(--template-fondo-animacion) !important;
    -ms-animation-name:var(--template-fondo-animacion) !important;
    -moz-animation-name:var(--template-fondo-animacion) !important;
    -webkit-animation-name:var(--template-fondo-animacion) !important;
    margin-left:var(--template-fondo-margin-left) !important;
    margin-top:20px;
}

.pthumb_background .pthumb_bg_logo  {
    width: 100%;
    -webkit-animation-duration: 70000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 70000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-duration: 70000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-duration: 70000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

}

</style>