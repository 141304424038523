<template>
<div  v-if="login"  >
  <h4 class="mb10">{{$t('firmas.editor_s3')}} <b>G Suite</b></h4>
    <small class="info_text_sm">{{$t('firmas.editor_gs2')}}</small>
    <div class="pvw_video mt20 mb20">
        <iframe :src="'https://www.youtube.com/embed/'+($i18n.locale=='es'?'IpnSYq3PeaE':'gFVOjNm94oI')+'?rel=0&amp;fs=0&amp;showinfo=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
    </div>
    
    <button type="button" v-if="login" class="btn btn-info btn-initial mr5" @click="loginG()"><font-awesome-icon v-if="!loading"  :icon="['fab','google']"/> <font-awesome-icon v-if="loading" icon="circle-notch" spin />{{$t('firmas.editor_gs0')}}</button>
    <form class="mt20 ng-pristine ng-valid">
        <div class="form-group">
            <label>1) {{$t('firmas.editor_gs3')}}</label>
            <a href="https://admin.google.com/ac/owl/domainwidedelegation" target="_blank" class="t_16px d-block" style="color:blue !important;">{{$t('firmas.editor_gs1')}}</a>
        </div>

        <div class="form-group">
            <label>2) {{$t('firmas.editor_gs4')}}</label>
            <input style="width:50%;" class="form-control dark" type="text" maxlength="50" value="112965220733255500209">
        </div>

        <div class="form-group">
            <label>3) {{$t('firmas.editor_gs5')}}</label>
            <input style="width:50%;" class="form-control dark" type="text" maxlength="50" value="https://www.googleapis.com/auth/gmail.settings.basic">
        </div>
    </form>
</div>
<div v-else class=" ">
    <h4 class="mb10">{{$t('firmas.editor_s3')}} <b>G Suite</b></h4>
    <div v-if="!login && usersArr.length>0"  style="overflow-y: scroll; max-height:100vh;">
        <div  class="row">
            <div class="form-group col-sm-2 mb0">
                <label class="d_block">{{$t('firmas.act3')}}</label>
            </div>
                <div class="form-group col-sm-2">
                <label class="d_block">Email</label>
            </div>
                <div class="form-group col-sm-4">
                <label class="d_block">{{$t('firmas.act4')}}</label>
            </div>
        </div>
        <div v-for="cardVal in usersArr" :key="cardVal.id" class="row">
            <div class="form-group col-sm-2">
                <input class="form-control dark" type="text" maxlength="50" v-model="cardVal.info.nombre">
            </div>
            <div class="form-group col-sm-2">
                <input class="form-control dark" type="text" maxlength="50" v-model="cardVal.info.email">
            </div>
            <div class="form-group col-sm-6 d-flex align-items-center">
                <font-awesome-icon :icon="['fas','eye']" @click="modalSignature(cardVal)" class="manito"/> 
                <div v-show="false" id="firma_wrapper" bgcolor="#fff"  :class="$util.hasSignaBanner(getUser.signature)?'conbanner':'sinbanner'">
                    <table :id="'firma_wrapper_tables_'+cardVal.id" border="0" cellspacing="0" cellpadding="0">
                        <templates :profile="getUser" :signature="getUser.signature" :linkcard="cardVal" :profile_photo='!(cardVal.style.firma_new && cardVal.style.firma_new.active)' />
                        <tfoot v-if="$util.hasSignaPartner(cardVal.info)" class="pvw_banner" style="display:block;margin-top:10px;" >
                            <tr  valign="top" style="">
                                <td v-if="cardVal.info.xai" ><a target="_blank"  style="display:block;" :href="$util.sanitizar(cardVal.info.xai,'xai')"><img  src="@/assets/images/signa_partners/xai.gif" width="145px" alt="signature_banner" /></a></td>
                                <td v-if="cardVal.info.xai" width="15"><div style="width: 15px;"></div></td>
                                <td v-if="cardVal.info.whereby"><a target="_blank"  style="display:block;" :href="$util.sanitizar(cardVal.info.whereby,'whereby')"><img src="@/assets/images/signa_partners/whereby.gif" width="145px"  alt="signature_banner" /></a></td>
                                <td v-if="cardVal.info.whereby" width="15"><div style="width: 15px;"></div></td>
                                <td v-if="cardVal.info.calendarcom" style=""><a target="_blank"  style="display:block;" :href="$util.sanitizar(cardVal.info.calendarcom,'calendarcom')"><img src="@/assets/images/signa_partners/calendarcom.gif" width="145px"  alt="signature_banner" /></a></td>
                                <td v-if="cardVal.info.calendarcom" width="15"><div style="width: 15px;"></div></td>
                            </tr>
                        </tfoot>
                        <tfoot v-if="$util.hasSignaBanner(getUser.signature)" class="pvw_banner" style="display:block;margin-top:20px;" >
                            <tr valign="top" style=""><td style=""><a target="_blank"  style="display:block;" :href="$util.sanitizar(getUser.signature.url)"><img  :src="$util.getSignaBanner(getUser.signature)" :style="'width:'+(getUser.signature.banner.width)+'px'" :width="getUser.signature.banner.width" alt="signature_banner" /></a></td></tr>
                        </tfoot>
                        <tfoot v-if="getUser.signature.desc" class="pvw_banner" style="display:block;margin-top:5px;max-width:600px;" >
                             <tr valign="top" ><td ><p style="display:block;font-size:10px;margin-top:10px;">{{getUser.signature.desc}}</p></td></tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!login && usersArr.length==0"  class="edicion_block mt20"><div class="form-group">{{$t('firmas.act2')}}</div></div>
    <button type="button" class="btn btn-social btn-google btn-sm " @click="logout()"><font-awesome-icon :icon="['fab','google']"/> <font-awesome-icon v-if="loading" icon="circle-notch" spin />{{$t('general.logout')}}</button>
    <button type="button" v-if="!login && usersArr.length>0"  class="btn btn-primary btn-sm" @click="push()">
    <font-awesome-icon v-if="!loading"  :icon="['fas','sync-alt']"/>
    <font-awesome-icon v-if="loading" icon="circle-notch" spin />
    {{$t('firmas.act1')}}</button>
    <span>{{status}}</span>
</div>
</template>

<script>
import templates from "@/layouts/signature_Customizer/templates.vue"
import {functions } from '@/services/firebaseConfig.js';

export default {
    data() {
        return {
            login:true,
            loading:false,
            getUser:this.$store.state.userProfile,
            usersArr:[],
            status:''
        }
    },
    async mounted(){
        let dis = this
        this.init()
    },
    methods: {

        modalSignature(linkcard){
            let index=this.getUser.linckards.findIndex(function(i){return i.id == linkcard.id;}) 
            if(this.getUser.linckards[index].style && !this.getUser.linckards[index].style.firma_new)this.getUser.linckards[index].style.firma_new={}
            this.$modal.show('modal-firma',{linkcard:this.getUser.linckards[index]})
        },
        init(){
            let dis = this
            this.$gapi.getGapiClient().then((gapi) => {
                console.log('signed in', gapi.auth2.getAuthInstance());
                dis.listUsers(gapi)
            }).catch(async err =>{
                dis.login=true
            })
        },
        logout() {
            let dis = this
            dis.loading=true
            this.$gapi.getGapiClient().then(async (gapi) => {
                await gapi.auth2.getAuthInstance().signOut();
                dis.login=true
                dis.loading=false
                console.log('loggedd out')
            })
        },
        loginG() {
            let dis = this
            dis.loading=true
            this.$gapi.getGapiClient()
            .then(async (gapi) => {
                await gapi.auth2.getAuthInstance().signIn();
                dis.login=false
                dis.init()
            })
        },
        async push(){
            let dis = this
            dis.loading=true
            
            for (let i = 0; i < this.usersArr.length; i++) {
                let html=document.getElementById('firma_wrapper_tables_'+dis.usersArr[i].id).outerHTML
                html=html.replaceAll('&nbsp;','')
                html=html.replaceAll('> <','><')
                html=html.replaceAll('<!---->','')
                this.usersArr[i].signature=html
                
                console.log('ok:',this.usersArr[i].info.mail)
            }
            try{

                const gsuiteSignature = functions.httpsCallable('gsuiteSignature');
                let ja=await gsuiteSignature({users:dis.usersArr}).then(res => {
                    console.log(res)
                    return res.data
                })
                if(ja && ja.status=='Success'){
                    dis.status=ja.status
                    dis.$util.notif_success()
                }
            }catch(e){
                dis.status='error'
                console.log(e)
                dis.$util.notif_error()
            }
            dis.loading=false
            
        },
        async listUsers(gapi){
            let dis = this
            dis.loading=true
            dis.usersArr=[]
            await gapi.client.directory.users.list({
                    'customer': 'my_customer',
                    'maxResults': 300,
                    'orderBy': 'email'
                }).then(async response => {
                var users = response.result.users;
                dis.login=false
                console.log('users:',users.length)
                if (users && users.length > 0) {
                    for (let i = 0; i < users.length; i++) {
                        var user = users[i];
                        let linc=dis.getUser.linckards.find(e=>{return e.info.email.toLowerCase()==user.primaryEmail.toLowerCase()})
                        if(!linc) continue;
                        
                        linc.isPreview=true
                        dis.usersArr.push(linc)
                    }
                    dis.loading=false
                } else {
                    console.log('No users found.');
                }
                console.log('Users ok:',dis.usersArr.length)
            }).catch(async result =>{
                console.log(result)
                dis.loading=false
                dis.login=true
            })
        },
    },
    components:{
        templates
    }
}
</script>