<template>
<div class="search_col edicion_container" id="searchPanel">
            <div>
                <div class="edicion_block mb10">
                    <img src="@/assets/images/searchColorIcon.svg" width="48" class="mb10">
                    <h4 class="mb5">{{$t('general.buscar')}}</h4>
                    <div class="row">
                        <div class="form-group col-12">
                            
                            <input v-model="searchVal"  @keyup.enter="searchToggle()"  class="form-control dark" type="search" maxlength="50" :placeholder="$t('general.cescribir')+'...'" value="">
                    
                            
                            <small>* {{$t('general.puedes')}}</small>
                        </div>
                        <div class="col-12">
                             <div class="d_flex">
                                <button type="button"  @click="searchToggle()" class="btn btn-primary btn-initial text-uppercase">{{$t('general.buscar')}}</button>
                             <!--  <button type="button" @click='avanz=!avanz' class="btn btn-initial ml-auto mr-0 btn-adv-search">
                                    <img src="@/assets/images/icon_settings_blue.svg" width="16" style="position: relative; top: -2px;">
                                    {{$t('general.avanz')}}
                                </button>-->
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>

</template>

<script>


import {sectors} from '@/mixins/categories.js'

export default {
    props: ['search'],
    data() {
        return { 
            avanz:false,
            searchVal: this.search,
            sectors:sectors,
        }
    },
    methods:{
        searchToggle(){
            const el = document.body.classList;
            if(el.contains("search_open"))el.remove("search_open");
            this.$emit('update:search', this.searchVal)
        
        }
    },
    watch:{
        searchVal(val){
            if(!val)this.$emit('update:search', '');
        }
    }
}
</script>

