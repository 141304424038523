<template>
    <div class="colapsables">
        <div  class="edicion_block" >
            <div  @click="show.s1=!show.s1" class="navcola manito" style="border-top:0px !important;">
                <h4> {{$t('info.informacion')}} </h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s1" v-show-slide="show.s1">
                <imagePicker :btext="$t('pickers.loadi')" imgid="profile" v-model="preview.img" :isCircle="true" :isCrop="true" :aspect="1"  />
                <span class="info_text">* {{$t('info.formato')}}</span>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="$util.isLoyaltyCustomizer()?$t('loyaltys.nombrecc'):$t('info.nombrecc')" v-model='preview.nombre'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true}" name="trabajo" maxlength="50" :placeholder="$util.isLoyaltyCustomizer()?$t('loyaltys.profes'):$t('info.profes')"  v-model='preview.trabajo'>
                    <client-only><span class="vuerror">{{ errors.first('trabajo') }}</span></client-only>
                </div>
                <div class="form-group">
                    <textarea maxlength="400" style="overflow:hidden" rows="6"  class="form-control" type="text"  :placeholder="$t('info.pieplace')"  v-model='preview.pie'/>
                </div>
            </div>
            
        </div>
        
        <div  class="edicion_block">
            <div  @click="show.s2=!show.s2" class="navcola manito">
                <h4> {{$t('info.informacionc')}} </h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s2" v-show-slide="show.s2">
                    <div class="form-group">
                        <vue-phone-number-input
                        v-model="mobilephone"
                        :valid-color="'none'"
                        :default-country-code="preview.mobilephone_code"
                        :placeholder="$t('info.telmovil')"
                        @update="onUpdateMobile"
                        :translations="$t('inputphone_mobile')" />
                    </div>
                    <div  class="form-group">
                        <vue-phone-number-input
                        v-model="fixedphone"
                        :valid-color="'none'"
                        :default-country-code="preview.phone_code"
                        :no-validator-state="false"
                        :placeholder="$t('info.telfijo')"
                        @update="onUpdateFixed" 
                        :translations="$t('inputphone_work')" />
                    </div>
                    <div class="form-group">
                        <input class="form-control" autocomplete="off"  v-validate="'required|email'" name="email" type="text" maxlength="100"  :placeholder="$t('info.correoelec')" v-model='preview.email' >
                        <client-only><span class="vuerror">{{ errors.first('email') }}</span></client-only>
                    </div>
                    <div  class="form-group">
                        <input class="form-control"  name="url" type="text" maxlength="200" placeholder="Website" v-model='preview.url'>
                    </div>
                    <div  class="form-group">
                        <input class="form-control"  name="url" type="text" maxlength="200" :placeholder="$t('bc.calendurl')" v-model='preview.calendar'>
                    </div>
                    <div  class="form-group">
                        <input class="form-control"  name="address" type="text" maxlength="200" :placeholder="$t('bc.address')" v-model='preview.address'>
                    </div>
            </div>
            
        </div>
        
        <div class="edicion_block">
            <div  @click="show.s3=!show.s3" class="navcola manito">
                <h4>{{$t('info.formatocontacto')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s3?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s3" v-show-slide="show.s3">
                <div class="row text-center row_radiogroup">
                    <div class="col-6 col-sm-4 rb_col" v-for='(item) in clayouts' :key='item.id'>
                        <div class="radiobutton_group">
                            <p-radio class="p-default p-curve" v-bind:id="'radio'+item.id" v-bind:value="item.id" v-bind:name="'radio'+item.id" v-model="preview.clayout" ></p-radio>
                            <label class="title">{{item.text}}</label>
                        </div>
                        <img v-bind:id="'radio'+item.id" :src="require('@/assets/images/tipocontacto0'+(item.id)+'.svg')" ><!--@click="selectImage(item.id)"-->
                    </div>
                </div>
            </div>
        </div>
        <div class="edicion_block">
            <div  @click="show.s4=!show.s4" class="navcola manito">
                <h4>{{$t('info.tusredes')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s4?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s4" v-show-slide="show.s4">
                <span class="info_text">* {{$t('info.copiaypega')}}</span>
                <div class="form-group">
                    <input class="form-control input_wicon input_website"  name="website"  type="text" maxlength="200" placeholder="Website" v-model='preview.website'>
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_facebook" type="text" maxlength="100" placeholder="Facebook" v-model='preview.facebook' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_instagram" type="text" maxlength="100" placeholder="Instagram" v-model='preview.instagram' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_twitter" type="text" maxlength="100" placeholder="Twitter" v-model='preview.twitter' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_linkedin" type="text" maxlength="100" placeholder="LinkedIn" v-model='preview.linkedin' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_youtube" type="text" maxlength="100" placeholder="Youtube" v-model='preview.youtube' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_vimeo" type="text" maxlength="100" placeholder="Vimeo" v-model='preview.vimeo' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_skype" type="text" maxlength="100" placeholder="Skype" v-model='preview.skype' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_behance" type="text" maxlength="100" placeholder="Behance" v-model='preview.behance' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_github" type="text" maxlength="100" placeholder="Github" v-model='preview.github' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_tiktok" type="text" maxlength="100" placeholder="Tiktok" v-model='preview.tiktok' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_spotify" type="text" maxlength="100" placeholder="Spotify" v-model='preview.spotify' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_snapchat" type="text" maxlength="100" placeholder="Snapchat" v-model='preview.snapchat' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_line" type="text" maxlength="100" placeholder="Line" v-model='preview.line' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_googlemap" type="text" maxlength="200" placeholder="Google Map" v-model='preview.googlemaps'>
                </div>
            </div>
        </div>

    </div>

</template>


<script>
import leftColInfoD from "./leftColInfoD.vue"
import imagePicker from '@/components/imagePicker.vue'
import switchPicker from './dinputs/switchPicker'
import {sectors} from '@/mixins/categories.js'
import {regions} from '@/mixins/regions.js'

export default {
    props:['profile'],
    data(){
        return{
            show:{
                s1:true,s2:false,s3:false,s4:false,s5:false,s6:false,s7:false,s8:false,s9:false,s10:false,
            },
            getUser:this.profile,
            image: '',
            tags:this.$store.getters.editingLinckard.info.tags,
            mobilephone:'',
            fixedphone:'',
            sectors:sectors,
            regions:regions,
            sector:this.$store.getters.editingLinckard.sector_id,
            country:this.$store.getters.editingLinckard.info.country,
            state:this.$store.getters.editingLinckard.info.state,

            mylang:this.$store.getters.editingLinckard.locale?this.$store.getters.editingLinckard.locale:this.$store.state.userProfile.account_details.account_locale,
            langs: [
                {id:'es',name:'Español'},
                {id:'en',name:'English'},
                {id:'ar',name:'Arabic'},
                {id:'zh-CN',name:'Chinese simplified'},
                {id:'zh-TW',name:'Chinese traditional'},
                {id:'pt',name:'Portuguese'},
                {id:'ru',name:'Russian'},
                {id:'fr',name:'French'},
                {id:'ca',name:'Catalán'},
                {id:'sw',name:'Swahili'},
                {id:'el',name:'Greek'},
                {id:'tr',name:'Turkish'},
                {id:'hi',name:'Hindi'},
                {id:'ur',name:'Urdu'},
                {id:'iw',name:'Hebrew'},
                {id:'fa',name:'Persian'},
                {id:'ja',name:'Japanese'},
                {id:'ko',name:'Korean'},
                {id:'de',name:'German'},
                {id:'it',name:'Italian'},
                {id:'nl',name:'Dutch'}
            ],
            clayouts:[
                {id:1,text:'Cel/Mail/Whatsapp'},
                {id:2,text:'Cel/Mail/SMS'},
                {id:3,text:'Tel/Mail/SMS'},
                {id:4,text:'Tel/Mail/Whatsapp'},
                {id:5,text:'Cel/Mail'},
                {id:6,text:'Tel/Mail'},
                {id:7,text:'Mail/Web'},
                {id:8,text:'Web/Mail'},
                {id:9,text:'Whatsapp/Mail'},   
                {id:10,text:'Meeting/Whatsapp'},
                {id:11,text:'Meeting/Email'},   
                {id:12,text:'Meeting/Phone'},
            ],
             //Loading Signiup
            isLoading: false,
            status: '',
            
            
        }
    },
    computed: {
        cardVal () {return this.$store.getters.editingLinckard},
        preview () {return this.$store.getters.editingLinckard.info},
        coutryCode(){return this.$store.getters.countryCode}
    },
    beforeMount(){
        this.mobilephone=this.getNumberOK(this.preview.mobilephone)
        this.fixedphone=this.getNumberOK(this.preview.phone)
        if(!this.preview.mobilephone_code)this.preview.mobilephone_code=this.$store.getters.countryCode
        if(!this.preview.phone_code)this.preview.phone_code=this.$store.getters.countryCode
    },
    mounted(){
        this.$bus.$on('validateLinc',comp=>{
            this.$validator.validateAll()
        });
    },
    methods:{
        getLabel(label){
            if(label=='Info')return 'Info'
            else if(label=='Sobre')return this.cardVal.isLanding?'Landing':this.$t('bc.about')
            else if(label=='Compartir')return this.$t('bc.share')
            else if(label=='Extra')return 'Extra'
        },
        onTagsUpdated(){
            this.preview.tags=this.tags
        },
        getNumberOK(myString){
            if(!myString)return '';//impotante devolver stirng
            var str = myString.substring(myString.indexOf(' ')+1)
            return str
        },
        onUpdateMobile(payload) {
            let number=payload.formatInternational?payload.formatInternational:''
            this.preview.mobilephone=number
            if(payload.countryCode)this.preview.mobilephone_code=payload.countryCode
        },
        onUpdateFixed(payload) {
            let number=payload.formatInternational?payload.formatInternational:''
            this.preview.phone=number
            if(payload.countryCode)this.preview.phone_code=payload.countryCode
        },
        regionstates(){
            if(this.country !=null && this.country.length>0){

                return this.regions.find(x => x.label == this.country).regions.map(type => type.label)
            }
            return []
        },
        addLabel (newTag) {
            if (typeof this.preview.labels !== 'object')this.preview.labels=[]
            this.preview.labels.push(newTag)
        },
        dameImg: function (id) {
            return require('@/assets/images/tipocontacto0'+id+'.svg');
        },
      
        
    },
    watch: {
        mylang(locale){
            this.$store.getters.editingLinckard.locale=locale
            this.preview.locale=locale
        },
        clayout: function(newValue, oldValue) {
            this.$store.getters.editingLinckard.info.clayout=newValue;
        },
        sector(val){
            this.$store.getters.editingLinckard.sector_id=val
        },
        country(val){
            this.preview.country=val
            this.state=''
        },
        state(val){
            this.preview.state=val
        },
        
        
    },
    components:{
        leftColInfoD,
        imagePicker,
        switchPicker
    }
}
</script>
<style>  
.manitomove{
    cursor: move;
}
.order_contenedor .switchet{
    margin:0px !important;
    margin-top:5px !important;
    margin-right: 5px !important;;
}
.order_contenedor{
    background: white;;
    cursor: hand;
    border:1px solid black;
    margin-bottom: 5px;;
    padding: 5px;;
    padding-left: 10px;
    padding-right: 10px;
    border-radius:10px;
}
label::before,label::after{
    display:none !important;
}  
.radiobutton_group label{
    padding-top: 10px !important;
}
.radiobutton_group label{
color:grey !important;
}
.radiobutton_group .title{
display: block !important;
font-size:10px;
}

.edicion_block .dropdown-toggle::after{
    display:none;
}

input[type="file"] {
    display: none;
}

.pretty .state label:after, .pretty .state label:before {

    display:block !important;
}
.p-default{
    margin:0 auto !important;
}



.pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.pretty.p-curve .state label:after, .pretty.p-curve .state label:before{
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #d8d8d8;
    border:0px;
}

.pretty.p-default input:checked~.state label:after{

    color:#878787;
    background-color:#878787 !important;
    content: '';
    width: 10px;
    height: 10px;
    background: #878787;
    position: absolute;
    top: 0;
    margin-top: 5px;
    left: 50%;
    margin-left: -5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

}
.tags-input-badge{
font-weight:400;
margin-bottom:2px;
padding:7px !important;
}



</style>


