<template>
   <div class="edicion_block">
        <h4 class="mb10" style="font-size: 22px;">{{$t('sobre.presiona')}}</h4>
        <img src="@/assets/images/editar_bg.png"  style="display: table; margin: 40px auto; max-height: 200px;">
    </div>
</template>

<script>

export default {
}
</script>

