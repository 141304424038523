var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$util.getFooterImg(_vm.preview)?'template_sample manito':'template_sample h100 manito',style:(_vm.generateCSS())},[_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"pthumb_full_container"},[_c('div',{staticClass:"pthumb_background"},[(_vm.preview && _vm.preview.style.info.fondo.active)?_c('div',{staticClass:"pthumb_bg_logo"},[_c('img',{attrs:{"src":_vm.$util.getBackImage(_vm.preview,null),"width":"100%","id":"tarjetaBgLogo"}})]):_vm._e()]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"pthumb_content_wrapper"},[_c('div',{staticClass:"pthumb_photo"},[(_vm.type=='loyalty')?_c('img',{attrs:{"src":_vm.$util.getLincImageLoyalty(_vm.preview)},on:{"error":function(event){event.target.src=_vm.$util.getLincImageLoyalty(_vm.preview,null)}}}):_c('img',{staticClass:"w-100",attrs:{"src":_vm.$util.getLincImage(_vm.preview)},on:{"error":function(event){event.target.src=_vm.$util.getLincImage(_vm.preview,null)}}})]),_vm._v(" "),(_vm.type=='bc' && _vm.preview && _vm.preview.info)?_c('ul',{staticClass:"pthumb_name_wrapper_text"},[(_vm.preview.info.nombre.length>0)?_c('h4',[_vm._v(_vm._s(_vm.preview.info.nombre))]):_c('h4',[_vm._v(_vm._s(_vm.$t('info.nombrecc')))])]):_c('ul',{staticClass:"pthumb_name_wrapper"},[_c('li',{staticClass:"size_45"}),_vm._v(" "),_c('li',{staticClass:"size_45"})]),_vm._v(" "),_vm._m(1),_vm._v(" "),(_vm.type=='loyalty')?[_c('div',{staticClass:"pthumb_large_btn"}),_vm._v(" "),_vm._m(2)]:[_c('div',{staticClass:"pthumb_large_btn pthumb_large_btn_bnss main"}),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7)]],2),_vm._v(" "),_c('div',{staticClass:"pthumb_foot"},[_c('img',{attrs:{"src":_vm.$util.getFooterImg(_vm.preview)},on:{"error":function(event){event.target.src=_vm.$util.getFooterImg(_vm.preview,null)}}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_top_menu"},[_c('li',{staticClass:"size_20"}),_vm._v(" "),_c('li',{staticClass:"size_20"}),_vm._v(" "),_c('li',{staticClass:"size_20"}),_vm._v(" "),_c('li',{staticClass:"size_20"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_prof_wrapper sub"},[_c('li',{staticClass:"size_60"}),_vm._v(" "),_c('li',{staticClass:"size_30"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pthumb_info_wrapper"},[_c('ul',{staticClass:"pthumb_list_row"},[_c('li',{staticClass:"size_25"}),_vm._v(" "),_c('li',{staticClass:"size_70"})]),_vm._v(" "),_c('ul',{staticClass:"pthumb_list_row"},[_c('li',{staticClass:"size_30"}),_vm._v(" "),_c('li',{staticClass:"size_65"})]),_vm._v(" "),_c('ul',{staticClass:"pthumb_list_row"},[_c('li',{staticClass:"size_15"}),_vm._v(" "),_c('li',{staticClass:"size_80"})]),_vm._v(" "),_c('ul',{staticClass:"pthumb_list_row"},[_c('li',{staticClass:"size_25"}),_vm._v(" "),_c('li',{staticClass:"size_70"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pthumb_btn_group"},[_c('div',{staticClass:"pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0"}),_vm._v(" "),_c('div',{staticClass:"pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_social_wrapper size_80"},[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_prof_wrapper desc"},[_c('li',{staticClass:"size_60 d-table m-auto"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_desc_wrapper desc size_80"},[_c('li',{staticClass:"size_30"}),_vm._v(" "),_c('li',{staticClass:"size_30"}),_vm._v(" "),_c('li',{staticClass:"size_30"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pthumb_desc_wrapper desc size_60",staticStyle:{"margin":".83em auto","background":"none"}},[_c('li',{staticClass:"size_45"}),_vm._v(" "),_c('li',{staticClass:"size_45"})])
}]

export { render, staticRenderFns }