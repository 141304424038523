<template>
<ul >
    <li :class="cantStars>=1?'active':''"><font-awesome-icon icon="star"/></li>
    <li :class="cantStars>=2?'active':''"><font-awesome-icon icon="star"/></li>
    <li :class="cantStars>=3?'active':''"><font-awesome-icon icon="star"/></li>
    <li :class="cantStars>=4?'active':''"><font-awesome-icon icon="star"/></li>
    <li :class="cantStars>=5?'active':''"><font-awesome-icon icon="star"/></li>
</ul>
</template>

<script>
export default {
    props:['value'],
    data(){
        return{
            cantStars:this.value
        }
    }
}
</script>
<style>
.ngdialog-content{
    margin:0px;
}
</style>

