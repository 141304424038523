
<template>
<client-only>
  <modal name="modal-agentes"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
        <div class="modal_wrapper">
            <div slot="top-right">
                <button @click="$modal.hide('modal-agentes')" class="ngdialog-close"></button>
            </div>
        <div>
        <p  class="text_large text_font_primary text-center mb10">{{$t('loyaltys.sucur')}}</p>
        <p  class="text_large  text-center mb10" style="font-size:12px;">{{$t('loyaltys.sucursub')}}</p>
        <p  class=" text_font_primary text-center" style="margin:0px;">{{$t('loyaltys.userparat')}}</p>
        <p  class=" text_font_primary text-center mb20" style="color:#3A79BC;">{{getUser.email}}</p>
        <form v-if="objMain"  class="container " novalidate @submit.prevent="saveSettings()">
            <!--<template v-if="$util.isTesting()" >{{agentes}}</template>-->
            <div class="listaagentes">
            <div class="row mb10" v-for="(agente,index) in agentes" :key="agente.id" v-if="!agente.deleted" >
                
                <div class="col-5 align-items-center text-center">
                    <label>{{$t('loyaltys.sucu')}}</label>
                    <input class="form-control dark"  type="text" maxlength="50" placeholder="Nombre" v-model="agente.label" >
                </div>
                <div class="col-5 text-center">
                    <label>{{$t('login.claveapp')}}</label>
                    <input class="form-control dark"  type="text" maxlength="50" placeholder="password"  v-model="agente.code" >
                </div>
                <div class="col-2 manito text-center" v-if="index>0">
                    <font-awesome-icon :icon="['fas','trash']" style="color:red;" @click="eliminarAgent(index)" />
                </div>
            </div>
            </div>
            <div class="edicion_block text-center mb10 manito" style="text-align:center;">
                <font-awesome-icon icon="plus-square" style="font-size:40px;text-align:center;" @click="addAgent()" />
            </div>
            <client-only><button-spinner  :is-loading="loading" :disabled="loading" :status="status" class="btn btn-primary w-100" >{{$t('general.guardar')}}</button-spinner></client-only>
        </form>
        </div>
    </div>

</modal>
</client-only>
</template>
<script>
import { Validator } from "vee-validate";
import {functions,usersCollection } from '@/services/firebaseConfig.js';

import { planes_businesscards} from '@/mixins/stripe.js'
import {sectors} from '@/mixins/categories.js'

export default {
    data () {
      return {
        getUser:this.$store.state.userProfile,
        status:'',
        loading:false,
        objMain:[],
      }
    },

    methods: {
        beforeOpen (event) {
            var dis=this
            this.objMain=JSON.parse(JSON.stringify(event.params.obj))
        },
        addAgent(){
            var dis=this

            if(this.$util.isProLoyaltyAgents(this.$modal,this.getUser,this.objMain)){
                
                let pass=this.$util.getAgentPassword()
                dis.objMain.agents.push({id:pass,label:'',code:pass,deleted:false})
            }
        },
		eliminarAgent(index){
            var dis=this;
            this.$modal.show('dialog', {title: dis.$t('notis.atencion'),text: dis.$t('general.estaseguro'),
            buttons: [
                {title: dis.$t('notis.si'), handler: () => { 
                    dis.objMain.agents[index].deleted=true
                    dis.$modal.hide('dialog')
				}},
                {title: dis.$t('general.no')}
            ]
          	})
            
            
        },
        saveSettings(){
            var dis=this

            for(let i in dis.agentes){
                if(!this.$util.checkLoyaltyCode(dis.agentes[i].code,this.objMain,this.getUser.loyaltys,i))return;
            }
            dis.loading=true
            let index=dis.getUser.loyaltys.findIndex(function(i){return i.id == dis.objMain.id})
            dis.getUser.loyaltys[index].agents=dis.agentes


            usersCollection.doc(dis.getUser.uid)
            .update({loyaltys:dis.getUser.loyaltys})
            .then(function(){
                dis.$util.notif_success(dis.$t('notis.setsaved'));
                dis.loading=false;
                //this.$modal.hide('modal-agentes')
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
        }
    },
    computed:{
        agentes(){
            return this.objMain.agents
        }
    }
  }
</script>

<style scoped>
.listaagentes{
max-height: 450px !important;
overflow-y: scroll;
}
</style>