<template>
    <li class="dropdown">
        <a class="text_font_primary" :class="{ open: open }" style="border:0px;">
                     


            <Icon :icon="icon" style="font-size: 18px;" />
            {{ text }}
        </a>
        <div class="dropdown_submenu" :class="{ dd_open: open }"
                            :style="'height:' + (open ? (items * 30 + 10) + 'px' : '0px')">
            <ul class="dd_wrapper ">
                <slot></slot>
            </ul>
        </div>
    </li>
</template>

<script>
export default {
    props: ['icon','text','open','items'],
}
</script>