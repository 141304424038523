<template>
<tr :style="elParent" >
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.website)"> <a :href="openUrl($util.sanitizar(preview?preview.info.website:null,'http://'))" target="_blank"><img :src="url_node+'/url/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.facebook) || !preview"> <a :href="openUrl($util.sanitizar(preview?preview.info.facebook:null,'facebook'))"  target="_blank"><img :src="url_node+'/facebook/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.twitter) || !preview"> <a :href="openUrl($util.sanitizar(preview?preview.info.twitter:null,'twitter'))" target="_blank"><img :src="url_node+'/twitter/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.linkedin) || !preview"> <a :href="openUrl($util.sanitizar(preview?preview.info.linkedin:null,'linkedin'))" target="_blank"><img :src="url_node+'/linkedin/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.instagram) || !preview"> <a :href="openUrl($util.sanitizar(preview?preview.info.instagram:null,'instagram'))" target="_blank"><img :src="url_node+'/instagram/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="(preview && preview.info.youtube) || !preview"> <a :href="openUrl($util.sanitizar(preview?preview.info.youtube:null,'youtube'))" target="_blank"><img :src="url_node+'/youtube/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.vimeo"> <a :href="openUrl($util.sanitizar(preview?preview.info.vimeo:null,'vimeo'))" target="_blank"><img :src="url_node+'/vimeo/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.skype"> <a :href="openUrl(preview?preview.info.skype:null,'skype')" target="_blank"><img :src="url_node+'/skype/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.github"> <a :href="openUrl(preview.info.github,'github')" target="_blank"><img :src="url_node+'/github/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.spotify"> <a :href="openUrl(preview.info.spotify,'spotify')" target="_blank"><img :src="url_node+'/spotify/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.behance"> <a :href="openUrl(preview.info.behance,'behance')" target="_blank"><img :src="url_node+'/behance/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.tiktok"> <a :href="openUrl(preview.info.tiktok,'tiktok')" target="_blank"><img :src="url_node+'/tiktok/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.line"> <a :href="openUrl(preview.info.line,'line')" target="_blank"><img :src="url_node+'/line/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.telegram"> <a :href="openUrl(preview.info.telegram,'telegram')" target="_blank"><img :src="url_node+'/telegram/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
    <td :align="elAlign" :style="elChild" v-if="preview && preview.info.googlemaps"> <a :href="openUrl($util.sanitizar(preview?preview.info.googlemaps:null,'googlemaps'))" target="_blank"><img :src="url_node+'/map/icon/'+signa.color_redes.replace('#','')+'.png'" width="16" height="16" /></a></td>
</tr>
</template>
<script>
import {functions } from '@/services/firebaseConfig.js';

export default {
    props:['parent','child','align','linkcard','signature'],
    data() {
        return {
            url_node:'https://api.linkcard.app/api/signature',
            //url_node:'http://localhost:5001/linckard-1545486188489/us-central1',
            elParent:this.padre,
            elChild:this.child,
            elAlign:this.align,
            signa:this.signature,
            preview:this.linkcard
            
        }
    },
    methods: {
        getUrl(){
            if(process.client)return window.location
        },
 
        openUrl(url) {
            if(typeof url == 'string' && url.includes('mailto:')){
                 url+='?subject='+this.$t('general.whatsmsg')
            }
            /*if(url.includes('mailto:') || url.includes('tel:')){
                window.location.href=url
            }else if (('standalone' in window.navigator) && window.navigator.standalone) {
                var win = window.open(url, '_top');
                win.focus();
            } else {
                var win = window.open(url, '_blank');
                win.focus();
            }*/
            return url
        },
    },
    computed:{

    }
}
</script>