<template>
<div>
	<label>{{$t('pickers.efecto')}}</label>
	<div class="input-group mb-3" uib-dropdown>
	<input type="text" maxlength="50" class="form-control"  placeholder="Multiplicar" v-model="inputVal">
</div></div>
</template>
<script>

export default {
	props: ['value'],
	data() {
		return { inputVal: this.value }
	},
	watch: {
		inputVal(val) {
			this.$emit('input', val);
		}
	},
}
</script>