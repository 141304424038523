<template>
<div>
  <div v-if="$store.state.sobreComp!='leftColSettings'" id="edicionMenuLft" class="edicion_menu mt20">
      <div  class="simple_tab_wrapper ">
          <ul class="simple_tab mb20 mt20">
              <li ><button :class="{ active: currentCompDesign === 'leftColDC' }" @click="switchComponentRightDesign('leftColDC')"><span class="etn_hide"> {{$t('bc.blocks')}}</span></button></li>
              <li id="v-step-4"><button :class="{ active: currentCompDesign === 'leftColDM' }" @click="switchComponentRightDesign('leftColDM')"><span class="etn_hide"> {{$t('bc.mydesigns')}}</span></button></li>
              <li id="v-step-5"><button :class="{ active: currentCompDesign === 'leftColDT' }" @click="switchComponentRightDesign('leftColDT')"><span class="etn_hide"> {{$t('bc.templates')}}</span></button></li>
          </ul>
      </div>
  </div>
  <div id="edicionMenuLft" class="edicion_menu "  style="padding-top:0px;">
      <div id="edicionLeft" v-if="!$util.isMobile()" class="edicion_container scolable">
          <component v-if="currentCompDesign == 'leftColDC' || $store.state.sobreComp=='leftColSettings'" :is="leftComp" :profile="getUser" :editcual="editCual" :key="editCual.index"></component>
          <rightColPlantilla v-else :type="currentCompDesign=='leftColDM'?getUser.uid:'link'" :key="currentCompDesign=='leftColDM'?getUser.uid:'link'" :profile="getUser" :linckard='cardVal'></rightColPlantilla>
      </div>
  </div>  
</div>
</template>

<script>


import leftColInfoA from "./leftColInfoA.vue"
import leftColMembreA from "./leftColMembreA.vue"
import leftColMembreD from "./leftColMembreD.vue"
import leftColInfoD from "./leftColInfoD.vue"
import leftColSettings from "./leftColSettings.vue"
import leftColSobreA from "./leftColSobreA.vue"
import leftColSobreD from "./leftColSobreD.vue"
import leftColGuardarA from "./leftColGuardarA.vue"
import leftColGuardarD from "./leftColGuardarD.vue"
import leftColCompartirA from "./leftColCompartirA.vue"
import leftColCompartirD from "./leftColCompartirD.vue"
import rightColPlantilla from "@/layouts/main_Customizer/rightColPlantilla.vue"

export default {
  props:['profile'],
  data(){
      return{
          cardVal:this.$store.getters.editingLinckard,
          getUser:this.profile,
          editCual:'editEmpty',
          currentComp: this.$util.isLoyaltyCustomizer()?'leftColMembre':this.$store.getters.editingLinckard.isLanding?'leftColSobre':'leftColInfo',
          currentCompDesign:'leftColDC'
            
      }
  },
  created(){
    this.$bus.$on('switchCompLeft', comp => {
        this.currentComp = comp;
        if(comp=='design')this.currentCompDesign='leftColDT'
    });
    this.$bus.$on('switchComponentRightDesign', comp => {
        this.currentCompDesign=comp
        console.log(comp)
    });
    this.$bus.$on('switchCompSobre', comp => {
            this.editCual='editEmpty';
            if(comp=='leftColSobreA')this.$store.state.infoMode='content'
            if(comp=='leftColSobreD')this.$store.state.infoMode='design'
            this.$store.state.sobreComp==comp
            this.currentComp='leftColSobre'
      });
      this.$bus.$on('currentCompSobreEditables',comp=>{
          this.editCual=comp;
          this.currentComp='leftColSobreD'
          this.$store.state.sobreComp=='leftColSobreDA'
          this.currentCompDesign='leftColDC'
          this.$store.state.infoMode='content'
        });
      this.$bus.$on('switchComp', comp => {
          comp=comp.replace('preview', "leftCol");
          this.currentComp = comp;
          console.log('cuatro',comp)
      });
  },
  computed:{
    leftComp(){
      if(this.$store.state.infoMode=='settings')return 'leftColSettings'
      if(this.currentComp=='leftColSobreD')return 'leftColSobreD'
      let suffix=this.$store.state.infoMode=='design'?'D':'A'
      let comp=this.currentComp+suffix
      return comp
    },
  },
  methods: {
    closeAllDivs(){

      this.$store.state.sobreComp=='leftColSobreDA'
      this.$store.state.infoMode='content'
      this.currentComp='leftColSobre'
      this.$util.closeAllDivs()

    },
    switchComponentRightDesign(comp) {
      //this.$bus.$emit("switchCompLeft", comp);
      this.currentCompDesign=comp
    },
    switchComponentLeft(comp) {
      this.$bus.$emit("switchCompLeft", comp);
      this.currentComp=comp
    },
     menuToggle(){            
       this.$util.toggleDiv("menu_open");
    },
  },

  components:{
        'leftColInfoA': leftColInfoA,
        'leftColMembreA':leftColMembreA,
        'leftColMembreD':leftColMembreD,
        'leftColInfoD': leftColInfoD,
        'leftColGuardarA': leftColGuardarA,
        'leftColGuardarD': leftColGuardarD,
        'leftColSobreA': leftColSobreA,
        'leftColSobreD': leftColSobreD,
        'leftColCompartirA': leftColCompartirA,
        'leftColCompartirD': leftColCompartirD,
        'rightColPlantilla':rightColPlantilla,
        'leftColSettings':leftColSettings
  }

  
};
</script>

<style>

</style>


