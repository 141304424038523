var linckard_model={
  status:"active",
  fecha:'',
  sector_id:0,
  directory:true,
  vcard:'',
  locale:'',
  seo:{
    img:'',
    desc:'',
    title:''
  },
  info:{
    img:'',
    nombre:"",
    trabajo:"",
    clayout: 1,
    mobilephone:"",
    phone:"",
    email:"",
    url:"",
    website:"",
    facebook:"",
    instagram:"",
    twitter:"",
    linkedin:"",
    youtube:"",
    vimeo:"",
    skype:"",
    github:"",
    snapchat:"",
    line:"",
    telegram:"",
    googlemaps:"",
    category:"",
    labels:[],
    country:"",
    state:"",
    pie:"",
    tags:[]
  },
  style:{
    general:{
      menu_sup:{
        family:"Oswald",
        weight:"400",
        size:"15px",
        color:"#fff",
        background:"#1D5A9A",
      },
    },
    
    info:{
        fondo:{
          background:"#008ee3",
          img:"",
          animacion:true,
          opacity:".4",
          active:true,
          animation:true,
          width:"380px",
          left:'0%',
          top:'50px'
        },
        nombre:{
          family:"Oswald",
          weight:"600",
          size:"28px",
          color:"#fff",
        },
        cargo:{
          family:"Nunito",
          weight:"600",
          size:"14px",
          color:"#fff",
        },
        botones:{
          family:"Oswald",
          weight:"400",
          size:"15px",
        },
        botones_c1:{ 
          color:"#fff",
          background:"#3abcfc",
        },
        botones_c2:{ 
          color:"#fff",
          background:"#dc88e5",
        },
        redes:{
          size:"20px",
          color:"#fff",
        },
        pie:{
          family:"Open Sans",
          weight:"400",
          size:"12px",
          color:"#fff",
          lineh:"20px",
        },
        caja_logo:{
          active:true,
          background:"#fff",
          img:"",
          url:'#',
        }
    },
    sobre:{
      fondo:{
        background:"#fff",
        img:"",
        fullwidth:true,
        animacion:true,
        opacity:".4",
        active:true,
        animation:true,
        width:"380px",
        left:'0%',
        top:'50px'
      },
    },
    firma_new:{
      img:"",
      boton:{
        background:"#328EE3",
      }, 
    } 
  },
  sobredrags:[],
  sobredrags_auto:[]
};
var profile_model={
  uid:'',
  email:'',
  username:'',
  type:'',
  contacts:[],
  denom_contacts:[],
  stats:{
      cant_linkcards:0,
      cant_linkcards_active:0,
      c_sectors:1,
      c_loyaltys:0,
      c_linckards:0,
      visits:0,
      likes:0
  },
  sectors:[
    {name:'Default',isLanding:true,status:'active',id:0}
  ],
  signature:{
    template_id:1,
    color_nombre:'#444444',
    color_btn_principal:'#686868',
    color_btn_sec:'#686868',
    color_redes:'#06868680',
    img:null,
    desc:'',
    banner:{
      img:null,
      width:400
    },
    url:''
  },
  linckards:[], 
  account_details:{
      account_date:'',
      account_name:'',
      account_country:'',
      account_state:'',
      account_picture:'',
      account_category:'',
      account_locale:'en',
      account_shortdesc:'',
  },
  stripe_details:{
      stripe_customer_id:'',
      stripe_subscriptions:[]
  }
  
  
  
}
let loyalty_style={
    fondo:{
      background:"#604422",
      img:"",
      animacion:true,
      opacity:".4",
      active:true,
      animation:true,
      width:"380px",
      left:'0%',
      top:'50px'
    },
    nombre:{
      family:"Oswald",
      weight:"600",
      size:"28px",
      color:"#fff",
    },
    cargo:{
      family:"Nunito",
      weight:"600",
      size:"14px",
      color:"#d8983d",
    },
    creditos:{ 
      color:"#604422",
      background:"#d8983d",
    },
    datos:{ 
      color:"#604422",
      background:"#d8983d",
    },
    pie:{
      family:"Open Sans",
      weight:"400",
      size:"12px",
      color:"#fff",
      lineh:"20px",
    },
    caja_logo:{
      active:true,
      background:"#d8983d",
      img:"",
      url:'#',
    }
};


//module.exports = {
export {
  loyalty_style,
  profile_model,
  linckard_model
} 