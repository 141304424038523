<template>
    <!--SI ES EN EL CUSTOMIZER-->
    <div :id='elid' class="tarjeta_wrapper" :style="$util.generateCSS(cardVal.style)">
        <div id="tarjetaContainer">
            <div class="tarjeta_wrapper" ng-controller="tarjetaController" >
                <previewMenu :current-comp="currentComp" :preview='cardVal'/>
                <div :class="'tarjeta_content  '+currentComp" >
                    <component :is="currentComp" :profile="getUser" :diruser="dirUser" :preview='cardVal' />
                </div>
            </div>
        </div>
        <div v-if="!$util.isPro(getUser)" @click="verPlanes()"  class="tarjeta_foot_lc" >
            <div class="d_table mr-auto ml-auto">
                <a class="manito" >
                    <img width="100" src="@/assets/images/Linkcard_Logo_Footer.svg"/>
                </a>
                <label style="color:white;" >* {{$t('general.removerbrand')}}</label>
            </div>
        </div>
    </div>
</template>

<script>

import previewMenu from "@/layouts/main_Preview/previewMenu.vue"
import previewMembre from "@/layouts/main_Preview/previewMembre.vue"
import previewInfo from "@/layouts/main_Preview/previewInfo.vue"
import previewSobre from "@/layouts/main_Preview/previewSobre.vue"
import camaraQR from "@/layouts/main_Preview/camaraQR.vue"
import {linckard_model} from '@/mixins/data.js'
import { planes_businesscards} from '@/mixins/stripe.js'

export default {
    props:['linckard','profile','elid','diruser'],
    data() {
        return {
            rolling:false,  
            getUser:this.profile,
            dirUser:this.diruser?this.diruser:this.profile,
            currentComp: this.linckard.loyalty_id>=0?'previewMembre':this.linckard.isLanding?'previewSobre':'previewInfo',
            cardVal:(this.linckard),
        };
    },
    beforeMount(){
        if(!this.cardVal.style)this.cardVal.style=linckard_model.style
         if(this.$route.hash=='#camara'){
             if(!this.$util.isLogged()){
                window.sessionStorage.setItem('gobacktocam', "/"+this.dirUser.username+'/'+this.preview.id+"#camara");
                this.$router.push('/login')
            }else this.$bus.$emit("switchComp", "camaraQR");
         }
    },
    created(){
        ////console.log('Historia: El user con email: '+this.getUser.email+' Esta viendo la linckard con id: '+this.cardVal.id+' del user: '+this.username_from)
        var dis=this;
        this.$bus.$on('switchComp', comp => {
                this.currentComp = comp;
        });
        if(this.$util.isCustomizer()){
            this.$bus.$off('undo')
            this.$bus.$off('redo')
            
            this.$bus.$on('undo', comp => {     
                this.undo()
            });
            this.$bus.$on('redo', comp => {
                this.redo()
            });

        }
    },
    methods:{
            
        verPlanes(){
            this.$modal.show('modal-planes',{place:'watermark',user:this.getUser,planes:planes_businesscards})
        },
        undo(){
            if(this.$store.state.editor.index<=1){
              //  //console.log('no hay para undo')
                return
            }
            this.rolling=true;
            this.$store.state.editor.index--
            let arr=this.$store.state.editor.versions

            this.$store.state.editor.active=JSON.parse(JSON.stringify(arr[this.$store.state.editor.index-1]))
            this.cardVal=this.$store.getters.editingLinckard

        },
        redo(){
            if(this.$store.state.editor.index>this.$store.state.editor.versions.length-1){
                return
            }
            this.rolling=true;
            this.$store.state.editor.index++
            let arr=this.$store.state.editor.versions
            
            this.$store.state.editor.active=JSON.parse(JSON.stringify(arr[this.$store.state.editor.index-1]))
            this.cardVal=this.$store.getters.editingLinckard
        },

        menuToggle(){
            this.$util.toggleDiv("menu_open");
        },
        isNumeric: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
    },
    components: {
        previewMenu,
        'previewInfo':previewInfo,
        'previewMembre':previewMembre,
        'previewSobre':previewSobre,
        'camaraQR':camaraQR
    },
    watch: {
        cardVal: {
            handler(val) {
                if(!this.$util.isCustomizer())return
                //Si esta haciendo un drag
                if(this.$store.state.isLiveEditing)return;
                //Si esta justo guardando?
                if(this.$store.state.editor.isSaving)return;
                //Si esta volviendo pa tras o palante
                if(this.rolling){
                    this.rolling=false;
                    return;
                }
                this.$store.commit('changeActiveEdit',val)
            },
            deep: true,
        },
    },
}
</script>

<style>
body.menu_open #card .btn_menu_toggle svg{
    display: none;
}

.tarjeta_wrapper .edicion_menu > ul {
    display: table;
    width: auto;
    margin: auto;
    text-align: center;
}


#tarjetaMuestra .tarjeta_content #tarjetaInfo a,
#tarjetaMuestra .tarjeta_content #tarjetaInfo button,
#tarjetaMuestra .tarjeta_content #tarjetaInfo div,
#tarjetaMuestra .tarjeta_content #tarjetaGuardar a,
#tarjetaMuestra .tarjeta_content #tarjetaGuardar button,
#tarjetaMuestra .tarjeta_content #tarjetaGuardar div,
#tarjetaMuestra .tarjeta_content #tarjetaCompartir a,
#tarjetaMuestra .tarjeta_content #tarjetaCompartir button,
#tarjetaMuestra .tarjeta_content #tarjetaCompartir div{
    pointer-events: none;
}
#tarjetaMuestra .tarjeta_content #tarjetaInfo textarea{
    pointer-events: auto !important;
}
#tarjetaMuestra .button_group{
    display: none;
}

#tarjetaMuestra .tarjeta_content #tarjetaSobreMi .btn{
    pointer-events: none !important;
}
.edicion_menu {
  background-color: var(--general-menu_sup-background) !important;
}
.tarjeta_wrapper{
    background: #fff;
}
.tarjeta_banner_block{

    pointer-events: auto !important;
}


/*acomodo todos los anchos*/

</style>
