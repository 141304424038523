import jwtDecode from 'jwt-decode'

export default async function ({ store, redirect,app,route }) {
  let params=document.location.search
  if(!app.$cookies || !app.$cookies.get('__session')) {
      app.$cookies.remove('__session')
      store.commit("setCurrentUser", null);
      return redirect('/login'+params)
  }
  const decodedToken = jwtDecode(app.$cookies.get('__session'))
  if (decodedToken) {
      let uid=decodedToken.user_id;
      let email = decodedToken.email;
      store.commit("setCurrentUser", { email, uid,redirect });
      ///console.log({ email, uid ,redirect})
      await store.dispatch('fetchUserProfile')
      //console.log(store.state.userProfile)
      if(!store.state.userProfile)return redirect('/login'+params)
  }
  if (!store || !store.state || !store.state.currentUser || !store.state.userProfile) {
    //console.log('entra1-redi')
    return redirect('/login'+params)
  }
  if(route.name=='index'){
    //console.log('entra2-redi')
    return app.$util.gotoHOME()
  }
}
