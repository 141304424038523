<template>
    <div class="colapsables">
    
     <div class="edicion_block">
        <div  @click="show.s1=!show.s1" class="navcola manito"  style="border-top:0px !important;">
        <h4>{{$t('style.info.menu')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s1" v-show-slide="show.s1">
          
            <div class="row form_row">
                <div class="col-lg-6">
                    <colorPicker  :label="$t('pickers.colorfondo')"  :color="general.menu_sup.background" v-model="general.menu_sup.background"/>
                </div>
                <div class="col-lg-6">
                    <colorPicker :color="general.menu_sup.color" v-model="general.menu_sup.color" />
                </div>
            </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s2=!show.s2" class="navcola manito" >
            <h4>{{$t('style.generales.fondo')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s2" v-show-slide="show.s2">
        <div class="row form_row">
            <div class="col-lg-6">
                <imagePicker imgid="background" :btext="$t('pickers.loadi')" v-model="preview.fondo.img" />
            </div>
            <div class="col-lg-6">
                <switchPicker v-model='preview.fondo.active'/>
            </div>
            <div class="col-lg-6">
                <switchPicker v-model='preview.fondo.animacion' :label="$t('pickers.movement')"/>
            </div>
            <div class="col-lg-6">
                <opacityPicker v-model="preview.fondo.opacity"/>
            </div>
            <div class="col-lg-6">
                <colorPicker :label="$t('pickers.colorfondo')"  :color="preview.fondo.background" v-model="preview.fondo.background" />
            </div>
            <div class="col-lg-6">
                <sizePicker v-model="preview.fondo.width" :min='50' :max='1200' :interval="50"/>
            </div>
            <div class="col-lg-6">
                <sizePicker :label="$t('pickers.hori')" v-model="preview.fondo.left" :min='-200' :max='200' :interval="10" unit="%"/>
            </div>
            <div class="col-lg-6">
                <sizePicker :label="$t('pickers.verti')" v-model="preview.fondo.top" :min='-100' :max='200' :interval="10" />
            </div>
        </div>
        </div>
    </div> 

     <div class="edicion_block">
        <div  @click="show.s3=!show.s3" class="navcola manito" >
            <h4>{{$t('style.info.nombre')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s3?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s3" v-show-slide="show.s3">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker  v-model="preview.nombre.family"/>
                </div>
            </div>
            <div class="row form_row">
                
                <div class="col-lg-6">
                    <colorPicker :color="preview.nombre.color"  v-model="preview.nombre.color" />
                </div>
                <div class="col-lg-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="preview.nombre.weight"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker v-model="preview.nombre.size"/>
                </div>
            </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s4=!show.s4" class="navcola manito" >
        <h4>{{$util.isLoyaltyCustomizer()?$t('loyaltys.profes'):$t('info.profes')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s4?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s4" v-show-slide="show.s4">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker  v-model="preview.cargo.family"/>
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="preview.cargo.weight"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker v-model="preview.cargo.size"/>
                </div>
            </div>

            <div class="row form_row">
                <div class="col-lg-12">
                    <colorPicker :color="preview.cargo.color" v-model="preview.cargo.color" />
                </div>
            </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s5=!show.s5" class="navcola manito" >
            <h4>{{$t('style.generales.botones')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s5?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s5" v-show-slide="show.s5">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker  v-model="preview.botones.family"/>
                </div>
            </div>

            <div class="row form_row">
                <div class="col-lg-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"   v-model="preview.botones.weight"/>
                </div>

                <div class="col-lg-6">
                    <sizePicker  v-model="preview.botones.size"/>
                </div>
            </div>
        </div>
    </div>
    
     <div class="edicion_block">
        <div  @click="show.s6=!show.s6" class="navcola manito" >
        <h4>{{$t('style.info.btnprin')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s6?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s6" v-show-slide="show.s6">
  
        <div class="row form_row">
             <div class="col-lg-12">
                <colorPicker :color="preview.botones_c1.color"  v-model="preview.botones_c1.color"/>
            </div>
            <div class="col-lg-12">
                <colorPicker :label="$t('pickers.colorfondo')"  :color="preview.botones_c1.background" v-model="preview.botones_c1.background" />
            </div>
        </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s7=!show.s7" class="navcola manito" >
             <h4>{{$t('style.info.btnsec')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s7?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s7" v-show-slide="show.s7">
            <div class="row form_row">
                <div class="col-lg-12">
                    <colorPicker :color="preview.botones_c2.color"  v-model="preview.botones_c2.color"/>
                </div>
                <div class="col-lg-12">
                    <colorPicker :label="$t('pickers.colorfondo')"  :color="preview.botones_c2.background" v-model="preview.botones_c2.background" />
                </div>
            </div>
        </div>
    </div>
    
     <div class="edicion_block">
        <div  @click="show.s8=!show.s8" class="navcola manito" >
            <h4>{{$t('style.info.iconosr')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s8?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s8" v-show-slide="show.s8">
            <div class="row form_row">
                <div class="col-lg-12">
                    <sizePicker  v-model="preview.redes.size"/>
                </div>

                <div class="col-lg-12">
                    <colorPicker :color="preview.redes.color" v-model="preview.redes.color" />
                </div>
            </div>
        </div>

    </div>
    
     <div class="edicion_block">
        <div  @click="show.s9=!show.s9" class="navcola manito" >
        <h4>{{$t('style.info.infopie')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s9?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s9" v-show-slide="show.s9">
        <div class="row form_row">
            <div class="col-12">
                <fontPicker  v-model="preview.pie.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-6">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"   v-model="preview.pie.weight"/>
            </div>

            <div class="col-lg-6">
                <sizePicker  v-model="preview.pie.size"/>
            </div>
        </div>

        <div class="row form_row">

            <div class="col-lg-6">
                <colorPicker :color="preview.pie.color" v-model="preview.pie.color" />
            </div>
            <div class="col-lg-6">
                <sizePicker :label="$t('pickers.inter')"  v-model="preview.pie.lineh"/>
            </div>
            
        </div>
        </div>


    </div>
    
     <div class="edicion_block">
        <div  @click="show.s10=!show.s10" class="navcola manito" >
                <h4>{{$t('style.info.caja')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s10?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s10" v-show-slide="show.s10">
            <div class="row form_row">
                <div class="col-lg-8">
                    <imagePicker imgid="bottom_logo" v-model="preview.caja_logo.img" />        
                </div>
                
                <div class="col-lg-4">
                    <label class="switch">
                        <input type="checkbox" v-model='preview.caja_logo.active'>
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="col-lg-12">
                    <colorPicker :label="$t('pickers.colorfondo')"  :color="preview.caja_logo.background" v-model="preview.caja_logo.background" />
                </div>
                <div class="col-lg-12">
                    <label>{{$t('general.url')}}</label>
                    <input type="text" maxlength="150" v-validate="'url:require_protocol'" name="url" class="form-control input_wicon input_website"  :placeholder="$t('style.info.urlp')" v-model="preview.caja_logo.url">
                    <client-only><span class="vuerror">{{ errors.first('url') }}</span></client-only>
                </div>
            </div>
        </div>
    </div>

</div>

</template>



<script>

import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import animacionPicker from './dinputs/animacionPicker'
import efectPicker from './dinputs/efectPicker'
import opacityPicker from './dinputs/opacityPicker'
import imagePicker from '@/components/imagePicker.vue'
import fontPicker from './dinputs/fontPicker'
import switchPicker from './dinputs/switchPicker'



export default {
    data(){
        return{
            show:{
                s1:false,s2:false,s3:false,s4:false,s5:false,s6:false,s7:false,s8:false,s9:false,s10:false,
            },
            colors:'',
        }
    },
  
    computed: {
        
        //info () { return this.$store.getters.editingLinckard.info},
        preiveww(){return  this.$store.getters.editingLinckard},
        preview () { return this.$store.getters.editingLinckard.style.info},
        general () { return this.$store.getters.editingLinckard.style.general},

    },
    components: {
        'colorPicker':colorPicker,
        'fontPicker':fontPicker,
        'switchPicker':switchPicker,
        'imagePicker':imagePicker,
        'sizePicker':sizePicker,
        'animacionPicker':animacionPicker,
        'efectPicker':efectPicker,
        'opacityPicker':opacityPicker,
    }
}


</script>


<style>


</style>
