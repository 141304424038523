<template>
 <div id="edicionMenuTop" class="edicion_menu">
      <nav class="edicion_top_nav menu_firmas ">
          <div class="row">
              <div class="col-lg-4 pr-0 pl-0 etn_col">
                  <ul class="etn_menu etn_lt">
                        <li :class="{active: $store.state.signaComp === 'leftCol_ST' }"><a @click="switchCompSignature('leftCol_ST',$bus)"><font-awesome-icon icon="swatchbook" /><span class="etn_hide"> {{$t('firmas.templates')}}</span></a></li>
                        <li :class="{active: $store.state.signaComp === 'leftCol_SD' }"><a @click="switchCompSignature('leftCol_SD',$bus)"><font-awesome-icon icon="pen" /><span class="etn_hide"> {{$t('firmas.design')}}</span></a></li>
                        <li :class="{active: $store.state.signaComp === 'leftCol_SC' }"><a @click="switchCompSignature('leftCol_SC',$bus)"><font-awesome-icon icon="cog" /><span class="etn_hide"> {{$t('firmas.sync')}}</span></a></li>
                  </ul>
              </div>

              <div class="col-lg-8 pl-0 etn_col">
                  <ul class="etn_menu etn_rt">
                      <!--<li class="etn_short"><a @click="undo()"><font-awesome-icon :icon="['fas','undo']" /></a></li>
                      <li class="etn_short"><a @click="redo()"><font-awesome-icon :icon="['fas','redo']" /></a></li>-->
                      <li class="btn-success_container" style="width: 160px;">
                          <a  v-if="checktime" class="btn-success w-100" style="background:green"><font-awesome-icon icon="check"  /></a>
                          <a  v-else-if="loading" class="btn-success w-100"><font-awesome-icon icon="circle-notch" spin /></a>
                          <a  v-else-if="$store.getters.signatureEdited" @click="guardarFirma()" class="btn-success w-100"><font-awesome-icon :icon="['fas','check']"  /><span class="etn_hide"> {{$t('bc.publicar')}} </span></a>
                          <a  v-else  class="btn-success w-100" style="background:#686868;height:100%;"><font-awesome-icon :icon="['fas','save']"  /><span class="etn_hide"> {{$t('bc.sincambios')}} </span></a>
                        </li>
                  </ul>
              </div>
          </div>
      </nav>
</div>
</template>

<script>

import LeftNav from '@/components/LeftNav.vue'
import notfound from "@/components/notfound";
import {db } from '@/services/firebaseConfig.js';

export default {
    props:['signature'],
    data() {
        return {
            getUser:this.$store.state.userProfile,
            checktime:false,
            loading:false,
            preview:this.signature

        }
    },
    beforeRouteLeave (to, from, next) { this.cerrarEditorVerify(null,next)},
    beforeMount(){
        this.$store.state.editor.isSaving=false
        window.addEventListener('beforeunload', this.documentClick);
    },
    beforeDestroy(){
        window.removeEventListener('beforeunload', this.documentClick);
    },
    created(){
        this.switchCompSignature('leftCol_ST',this.$bus)
        if(this.$util.isSignatureCustomizer()){
            this.$bus.$off('undo_s')
            this.$bus.$off('redo_s')
            
            this.$bus.$on('undo_s', comp => {     
                this.undo()
            });
            this.$bus.$on('redo_s', comp => {
                this.redo()
            });

        }
    },

    methods:{
        async guardarFirma(){
            var dis=this
            this.loading=true
            let nueva=this.preview
            if(!this.$store.getters.signatureEdited){
                alert('No changes to save!')
                this.loading=false
                return;
            }
            this.preview=await this.$util.uploadImages(this.preview);
            await db.collection('users').doc(this.getUser.uid).update({signature:nueva})
            this.$store.state.editor.isSaving=false;
            this.$util.registerStat('save_signature')

            this.$store.commit('resetSaveSignatureVersioning')

            dis.checktime=true
            dis.loading=false
            setTimeout(() => { dis.checktime = false }, 1000)
        },
        switchCompSignature(comp){
            this.$store.state.signaComp=comp
            this.$bus.$emit('switchCompSignature',comp)
        }
    },
    components: {
        LeftNav,
        notfound,
        
    },
}

</script>


<style scoped>
.etn_menu .active{
    background: #686868;
}
.etn_menu .active .etn_hide,
.etn_menu .active a .svg{
    color: white !important;
}
@media (max-width: 799px) {
    .edicion_top_nav .etn_menu > li.btn-success_container{
        min-width:80px !important;
        width:80px !important;
    }
    .edicion_top_nav .etn_menu > li > a.btn-success{
        width:80px !important;
        min-width:80px !important;
    }
}
</style>