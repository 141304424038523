<template>
<div class="mb-3">
	<label v-show="labelName" style="width:100%;">{{labelName}}</label>
	<label class="switch">
		<input type="checkbox" v-model="inputVal">
		<span class="slider round"></span>
	</label>
</div>
</template>



<script>

export default {
	props: ['value','label'],
	data() {
		return { 
			inputVal:this.value,
			labelName:this.label?this.label:''
		}
	},
	watch: {
		inputVal(val) {
			this.$emit('input', val);
		}
	},
}
</script>