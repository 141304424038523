<template>
    <div class="edicion_block">
        <h4>{{$t('drags.imagen')}}</h4>
        <div class="row form_row">
            <div class="col-12">
                <imagePicker imgid="sobre"  :btext="$t('pickers.loadi')"  v-model="valor.img" aspect="2"  /> 222
            </div>
        </div>

        <div class="row form_row">
            <div class="col-12">
                <label>{{$t('general.url')}}</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "  :placeholder="$t('general.entervalue')" v-model="valor.url">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <label>{{$t('general.alt')}}</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "  :placeholder="$t('general.entervalue')" v-model="valor.alt">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <sizePicker :value="valor.size==undefined?'100%':valor.size" min="10" max="100" unit="%"  v-model="valor.size"/>
            </div>
        </div>
    </div>
</template>

<script>



import imagePicker from '@/components/imagePicker.vue'
import sizePicker from '../dinputs/sizePicker'

export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value
		}
    },
    components:{
        imagePicker,
        sizePicker
    },
    
}
</script>

