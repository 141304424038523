import { render, staticRenderFns } from "./landingLight.vue?vue&type=template&id=13086f60&scoped=true&"
import script from "./landingLight.vue?vue&type=script&lang=js&"
export * from "./landingLight.vue?vue&type=script&lang=js&"
import style0 from "./landingLight.vue?vue&type=style&index=0&id=13086f60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13086f60",
  null
  
)

export default component.exports