<template>
<div v-if="loading" class="eventos_wrapper_empty" style="background:#EEEEEE;height:100vh">
    <div class="wrapper_middle">
        <img :src="require('@/assets/images/loading.gif')" width="180">
    </div>
</div>
<!--<div v-if="loading"  style="height: 100% !important;width:100% !important;position:absolute;display:block !important;z-index:2000;" class=""><div class="pthumb_full_container " style="background-color: #f9f9f9;">
    <div class="pthumb_background"></div>
    <ul class="pthumb_top_menu" style="background-color: #e4e4e4">
        <li class="size_20" style="background-color: #ccc"></li>
        <li class="size_20" style="background-color: #ccc"></li>
        <li class="size_20" style="background-color: #ccc"></li>
        <li class="size_20" style="background-color: #ccc"></li>
    </ul>
    <div class="pthumb_content_wrapper">
        <div class="pthumb_photo" style="background-color: #ccc">
            <img src="@/assets/images/profile_nophoto.png">
        </div>
        <ul class="pthumb_name_wrapper">
            <li class="size_80 d-table m-auto" style="background-color: #ccc"></li>
        </ul>
        <ul class="pthumb_prof_wrapper">
            <li class="size_60 d-table m-auto" style="background-color: #ccc"></li>
        </ul>
        <div class="pthumb_large_btn pthumb_large_btn_bnss main" style="background-color: #ccc"></div>
        <div class="pthumb_btn_group">
            <div class="pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0" style="background-color: #ccc"></div>
            <div class="pthumb_large_btn pthumb_large_btn_bnss pthumb_btn_sm mt-0" style="background-color: #ccc"></div>
        </div>
       <div class="pthumb_mid_text">
            <ul class="pthumb_list_row">
                <li class="size_30" style="background-color: #ccc"></li>
                <li class="size_30" style="background-color: #ccc"></li>
                <li class="size_30" style="background-color: #ccc"></li>
            </ul>
            <ul class="pthumb_list_row size_90">
                <li class="size_30" style="background-color: #ccc"></li>
                <li class="size_30" style="background-color: #ccc"></li>
                <li class="size_30" style="background-color: #ccc"></li>
            </ul>
        </div>
        <ul class="pthumb_social_wrapper size_80">
            <li style="background-color: #ccc"></li>
            <li style="background-color: #ccc"></li>
            <li style="background-color: #ccc"></li>
            <li style="background-color: #ccc"></li>
        </ul>

        <ul class="pthumb_prof_wrapper">
            <li class="size_60 d-table m-auto" style="background-color: #ccc"></li>
        </ul>
        <ul class="pthumb_desc_wrapper size_80">
            <li class="size_30" style="background-color: #ccc"></li>
            <li class="size_30" style="background-color: #ccc"></li>
            <li class="size_30" style="background-color: #ccc"></li>
        </ul>
        <ul class="pthumb_desc_wrapper size_60" style="margin: .83em auto;">
            <li class="size_45" style="background-color: #ccc"></li>
            <li class="size_45" style="background-color: #ccc"></li>
        </ul>
    </div>
    <div class="pthumb_foot" style="background-color: #ccc">
    </div>
</div>
</div>-->
</template>


<script>
export default {
  data: () => ({
    loading: false
  }),
  methods: {
    start () {
      this.loading = true
    },
    finish () {
      this.loading = false
    }
  }
}
</script>
