<template>
   <div  class="edicion_block">
        <h4>{{$t('drags.line')}}</h4>
        <div class="row form_row">
            <div class="col-lg-6">
                <sizePicker :min="1" :max="5"  :label="$t('pickers.size')" :unit="''" v-model="valor.size" />
            </div>
            <div class="col-lg-6">
                <sizePicker :min="4" :max="20" :interval="4" :label="$t('drags.space')" :unit="''" v-model="valor.weight" />
            </div>
            <div class="col-lg-6">
                <colorPicker :color="valor.color"  :label="$t('pickers.color')" v-model="valor.color" />
            </div>
        </div>
   </div>
</template>

<script>





import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'

export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value,
		}
    },  
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker
    }
}
</script>

