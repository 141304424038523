<template >
 <div v-if="!preview.isLanding" class="edicion_menu" >
    <button type="button" v-if="$util.isLogged()" class="btn_menu_toggle"  @click="menuToggle()"  >
        <font-awesome-icon icon="bars" />
        <img src="@/assets/images/icon_close.svg" style="width: 17px; position: relative; top: -1px;">
    </button>
    <ul v-if="preview.tabs && preview.loyalty_id>=0" :class="$util.isLogged()?'text_font_primary menu_sup menuabierto':'text_font_primary menu_sup'">
        <template  v-for="(tab,index) in preview.tabs" >
          <li v-if="tab.name=='Membre' && !preview.isLanding" :key="index">
          <button type="button"
          :class="{ active: currentComp === 'previewMembre' }"
          @click="switchComponent('previewMembre');"
          :disabled="currentComp === 'previewMembre'">
          <template >
            <Icon icon="ph:user-circle-light" style="font-size: 25px;" />
          </template>
          </button></li>

          <li v-if="tab.name=='Sobre' && !preview.isLanding" :key="index">
          <button type="button" 
          :class="{ active: currentComp === 'previewSobre' }"
          @click="switchComponent('previewSobre');"
          :disabled="currentComp === 'previewSobre'">
          <template>
            <Icon icon="ph:trophy-light" style="font-size: 25px;" />
          </template>
          </button></li>

          <li v-if="tab.name=='Info' && !preview.isLanding" :key="index">
          <button 
          type="button"
          :class="{ active: currentComp === 'previewInfo' }"
          @click="switchComponent('previewInfo');"
          :disabled="currentComp === 'previewInfo'">
          <template>
            <Icon icon="ph:info-light" style="font-size: 25px;" />
          </template>
          </button></li>
        </template>
    </ul>
    <ul v-else class="text_font_primary menu_sup menuabierto">
        <li ><button type="button"
        :class="{ active: currentComp === 'previewInfo' }"
        @click="switchComponent('previewInfo');"
        :disabled="currentComp === 'previewInfo'">
        <template ><font-awesome-icon :icon="['fas','user-circle']"/></template>
        </button></li>

        <li v-if="sobreTabShow()"><button type="button" 
        :class="{ active: currentComp === 'previewSobre' }"
        @click="switchComponent('previewSobre');"
        :disabled="currentComp === 'previewSobre'">
        <template v-if="preview.loyalty_id>=0"><font-awesome-icon :icon="['fas','trophy-alt']"/></template>
        <template v-else ><font-awesome-icon :icon="['fas','info-circle']"/></template>
        </button></li>
    </ul>
  </div>
</template>

<script>
 
export default {
  props:['preview','currentComp','diruser','tabs'],
  data(){
    return{
      pestas:this.tabs,
      getUser:this.diruser,
      activeLinckard:this.preview,
    }
  },
  created() {
    this.$bus.$on('switchCompLeft', comp => {
      this.switchComponent(comp.replace('leftCol', "preview"));
    });
  },

  methods: {
    sobreTabShow(){
      if(this.$util.isCustomizer())return true;
      if(this.activeLinckard.sobredrags && this.activeLinckard.sobredrags.length>0)return true
      if(this.$util.isOnboarding())return true
      return false
    },
    switchComponent(comp) {      
      this.$bus.$emit("switchComp", comp);
    },
    menuToggle(){
       this.$util.toggleDiv("menu_open");
    },
  },
 
};

</script>

<style >
/*Fix linea blanca*/
#card .tarjeta_content {
	padding-top: 54px;
}

@media (max-width: 767px) {
	#card .tarjeta_content {
		padding-top: 39px;
	}
}

#card .tarjeta_wrapper .edicion_menu > ul > li > button ,
#card .tarjeta_wrapper .edicion_menu  svg ,
.tarjeta_wrapper .edicion_menu > ul > li > button {
  
  /*font-size: var(--general-menu_sup-size)  !important;*/
  font-weight: var(--general-menu_sup-weight)  !important;
  color:var(--general-menu_sup-color) !important;
}

.edicion_menu > ul > li > button{

  font-family: var(--general-menu_sup-family)  !important;
}


.tarjeta_wrapper .edicion_menu,.menu_sup {
  background: var(--general-menu_sup-background) !important;
}

#tarjetaMuestra .tarjeta_wrapper .edicion_menu > ul {

/*el ancho de los botones*/
    display: flex;
    
    width: 100%;
    max-width: 300px;
    margin: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around !important;
    text-align: center;
}



</style>