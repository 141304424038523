<template>
<div>
  
    <div class="edicion_block">
        <template >
        <h4 class="mb10">{{$t('guardar.config')}}</h4>
        <span class="info_text">
            {{$t('guardar.configsub')}}
        </span>
        </template>
        <template>
        <h4 class="mb10">{{$t('guardar.evento')}}</h4>
        <span class="info_text">
            {{$t('guardar.eventosub')}}
        </span>
        </template>
        
        <ul  class="list_check mt20 mb10">
           
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_vcard.svg">
                    <b>{{$t('guardar.vcard')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.vcard'>
                    <span class="slider round"></span>
                </label>

                <button v-tooltip="$t('guardar.tool1')"  type="button" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>

            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_add.svg">
                    <b>{{$t('guardar.acceso')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.directo'>
                    <span class="slider round"></span>
                </label>

                <button v-tooltip="$t('guardar.tool2')"  type="button" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
            <!--
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_iphonewallet.svg">
                    <b>{{$t('guardar.wallet')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.iwallet'>
                    <span class="slider round"></span>
                </label>

                <button type="button" v-tooltip="$t('guardar.tool2')"  class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
            -->
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_favs.svg">
                    <b>{{$t('guardar.favs')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.favs'>
                    <span class="slider round"></span>
                </label>

                <button v-tooltip="$t('guardar.tool4')"  type="button" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
            <!--
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_share.svg">
                    <b>{{$t('guardar.visits')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.visits'>
                    <span class="slider round"></span>
                </label>

                <button type="button" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
            -->
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_search.svg">
                    <b>{{$t('guardar.direct')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.directory'>
                    <span class="slider round"></span>
                </label>

                <button type="button" v-tooltip="$t('guardar.tool5')" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
<!--
            <li>
                <span class="label_wicon">
                    <img src="@/assets/images/icon_google.svg">
                    <b>{{$t('guardar.google')}}</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.buscadores'>
                    <span class="slider round"></span>
                </label>

                <button type="button" class="btn_help"><img src="@/assets/images/icon_info.svg"></button>
            </li>
-->

        </ul>

    </div>

    <hr class="separator">

</div>
</template>

<script>


export default {
    computed: {
        preview () { return this.$store.getters.editingLinckard.guardar},

    },
}
</script>
