<template>
<div>
	<label>{{labelName}}</label>
	<div class="input-group mb-3 color-picker" ref="colorpicker">
		<div class="input-group-prepend">
			<client-only><chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker"  /></client-only>
			<a class="btn btn-outline-secondary dropdown-toggle">
				<span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
			</a>
		</div>
		<input type="text" maxlength="50" class="form-control" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
	</div>
</div>
</template>

<script>

export default {

	props: ['color','label'],
	data() {
		return {
			colors: {
				hex: '#000000',
			},
			labelName:this.label?this.label:this.$t('pickers.color_font'),
			colorValue: '',
			displayPicker: false,
		    defaultColor: '#FF0000'
		}
	},
	mounted() {
		this.setColor(this.color || '#000000');
	},
	methods: {
		setColor(color) {
			this.updateColors(color);
			this.colorValue = color;
		},
		updateColors(color) {
			if(color.slice(0, 1) == '#') {
				this.colors = {
					hex: color
				};
			}
			else if(color.slice(0, 4) == 'rgba') {
				var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
					hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
				this.colors = {
					hex: hex,
					a: rgba[3],
				}
			}
		},
		showPicker() {

			this.$store.state.isLiveEditing=true;

			document.addEventListener('click', this.documentClick);
			this.displayPicker = true;
		},
		hidePicker() {
			document.removeEventListener('click', this.documentClick);
			this.displayPicker = false;

			this.$store.state.isLiveEditing=false
			this.$emit('input', '');
			this.$emit('input', this.colorValue);

		},
		togglePicker() {
			this.displayPicker ? this.hidePicker() : this.showPicker();
		},
		updateFromInput() {
			this.updateColors(this.colorValue);
		},
		updateFromPicker(color) {
			this.colors = color;
			if(color.rgba.a == 1) {
				this.colorValue = color.hex;
			}
			else {
				this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
			}
		},
		documentClick(e) {
			var el = this.$refs.colorpicker,target = e.target;
			console.log(el,target)
			if(el!= undefined  && el !== target && !el.contains(target)) {
				this.hidePicker()
			}
		}
	},
	watch: {
		colorValue(val) {
			if(val) {
				this.updateColors(val);
				this.$emit('input', val);
			}
		}
	},

}
</script>

<style>

.current-color {
	display: inline-block;
	width: 50%;
	height: 50%;
	background-color: #000;
	cursor: pointer;
}

.vc-sketch {
	position: absolute !important;
	top: 40px !important;
	left: 0 !important;
	z-index: 100000 !important;
}

</style>