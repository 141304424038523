<template>
    <div class="row mt-0 ml-0">
        <div style="background-color: white; border-radius: 50%; width: 38px; height: 38px; padding-top: 5px;">
            <button type="button" style="display: block; vertical-align: middle;">
                <Icon icon="ph:qr-code-thin" style="font-size: 25px;" />
            </button>
        </div>
        <div style="width: 250px;"></div>
        <div style="background-color: white; border-radius: 50%; width: 38px; height: 38px; padding-top: 5px;">
            <button type="button">
                <Icon icon="ph:identification-card-thin" style="font-size: 25px;" />
            </button>
        </div>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2"

export default {
    props: ['element'],
    components: {
        Icon
    },
}
</script>