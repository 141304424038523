import Vue from 'vue'
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
  locale: 'es',
  events: 'blur' ,
  dictionary: {
    es: {
      messages: {
        min: function (val,length) { 
          return "Al menos "+length+" caracteres"
        },
        is: function () { 
          return "No coincide"
        },
        email: function () { 
          return "Formato invalido"
        },
        url: function () { 
          return "Formato invalido"
        },
        required:function(){
          return 'Campo requerido'
        },
      }
    },
    en: {
      messages: {
        min: function (val,length) { 
          return "At least "+length+" characters"
        },
        is: function () { 
          return "Do not match"
        },
        email: function () { 
          return "Invalid format"
        },
        url: function () { 
          return "Invalid format"
        },
        required:function(){
          return 'Required field'
        },
      }
    }
  }
});