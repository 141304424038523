import { db, functions } from '@/services/firebaseConfig.js';
import linkcard from '../linkcard';
import Vue from 'vue'

export default ({ app, store, route }, inject) => {
    linkcard.init(db, functions, {
        success: (title, text) => {
            Vue.prototype.$vs.notification({ position: 'top-right', color: 'success', title, text })
        },
        error: (title, text) => {
            Vue.prototype.$vs.notification({ position: 'top-right', color: 'danger', title, text })
        },
        warning: (title, text) => {
            Vue.prototype.$vs.notification({ position: 'top-right', color: 'warn', title, text })
        },
        debug: (title, text)=>{
            console.log(title,text)
        }
    })
    inject("linkcard", linkcard);
}
