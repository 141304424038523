<template>
    <div class="edicion_block">
        <h4>Save the date</h4>
        <div class="row form_row">
            <div class="col-12">
                <label>Title</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "
                        :placeholder="$t('general.entervalue')" v-model="valor.title">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <label>Details</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "
                        :placeholder="$t('general.entervalue')" v-model="valor.details">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <label>Location</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "
                        :placeholder="$t('general.entervalue')" v-model="valor.location">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <dateTimePicker label="From" v-model="valor.fromDate" :value="valor.fromDate" />
        </div>
        <div class="row form_row">
            <dateTimePicker label="To" v-model="valor.toDate" :value="valor.toDate"/>
        </div>
    </div>

</template>

<script>

import dateTimePicker from '@/layouts/main_Customizer/dinputs/dateTimePicker'

export default {
    props: ['value'],
    data() {
        return {
            valor: this.value
        }
    },
    components: {
        dateTimePicker
    },

}
</script>

