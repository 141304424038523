
<template>
<modal name="modal-share"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
    <div class="modal_wrapper card_share_dropdown account panel_bc " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-share')" class="ngdialog-close"></button>
        </div>
        <div class="card container panel_bc">
            <div class="mt20 card_section_head pr-0">
                <h4 class="mb10 text-left">{{$t((!isPublish?'comparte':'publish')+'.comparte')}}</h4>
            </div>
            <p class="mb10 d_block t_11px text-left">{{$t((!isPublish?'comparte':'publish')+'.elije'+$store.state.mode)}}</p>
            <small class="mb20">{{$t((!isPublish?'comparte':'publish')+'.elijesub')}}</small>
            <div class="form-group inputycopy">
                <label>{{$t('onboarding.popup')}}</label>
                <div class="d-flex align-items-center">
                    <input id="copiarurl" class="form-control dark manito" style="width:95%" @click="openLinckard()" type="text" :value="getLinkcardURL()" readonly="readonly"><font-awesome-icon style="width:5%" @click="doCopy" icon="copy" />
                </div>
            </div>
            <label @click="$router.push('/account#profile')" class="mb20 manito" style="text-decoration:underline;"><font-awesome-icon icon="globe"/> {{$t('general.connect')}} </label>
            
            <ul class="list_social_inline mb10  " >
                <span style="width:20%" v-if="$util.isMobile()" tabindex="0"><li><a :href="'whatsapp://send?text='+$i18n.t('share.desc')+' '+getLinkcardURL()" target="_blank" class="btnWhatsapp"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-whatsapp fa-w-14"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" class=""></path></svg></a></li></span>
                <span style="width:20%"  v-else tabindex="0"><li><a :href="'https://api.whatsapp.com/send?text='+$i18n.t('share.desc')+' '+getLinkcardURL()" target="_blank" class="btnWhatsapp"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-whatsapp fa-w-14"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" class=""></path></svg></a></li></span>
               
            <social-sharing :url='getLinkcardURL()'
                            @open="open"
                            :title="$t('share.title')"
                            :description="$t('share.desc')"
                            :quote="$t('share.quote')"
                            hashtags="linkcard,business,card,tool"
                            twitter-user="linckard"
                            :networks="$util.overriddenNetworks(getLinkcardURL())"
                            v-cloak inline-template>
                    <div class=" list_social_inline " >
                       <!-- <network network="whatsappnative" ><li><a class="btnWhatsapp"><font-awesome-icon :icon="['fab','whatsapp']"/></a></li></network>
                        <network network="wpweb"><li><a class="btnWhatsapp"><font-awesome-icon :icon="['fab','whatsapp']"/></a></li></network>-->
                        <network network="email"><li><a class="btnEmail"><font-awesome-icon icon="envelope"/></a></li></network>
                        <network network="messenger" v-if="$util.isMobile()" ><li><a class="btnMessenger"><font-awesome-icon :icon="['fab','facebook-messenger']"/></a></li></network>
                        <network network="facebook"><li><a class="btnFacebook"><font-awesome-icon :icon="['fab','facebook-square']"/></a></li></network>
                        <network network="linkedin"><li><a class="btnLinkedIn"><font-awesome-icon :icon="['fab','linkedin']"/></a></li></network>
                        <network network="twitter"><li><a class="btnTwitter"><font-awesome-icon :icon="['fab','twitter']"/></a></li></network>
                    </div>
            </social-sharing>
                </ul>  
        </div>
        <div  class="card_dd_wrapper card_dd_send pt20" :style="'display:'+(isSend?'block':'none')">
            <ais-instant-search :search-client="searchClient" :index-name="algolia_index" >
                <ais-search-box >
                    <div slot-scope="{ currentRefinement, isSearchStalled, refine }" class="form-group pl20 pr20">
                        <label><font-awesome-icon icon="paper-plane" style="color: #0090e4;"/> {{$t('general.sendtarjeta')}}</label>
                        <input @keyup.enter="searchToggle()"  @input="refine($event.currentTarget.value)" class="form-control dark" type="search" maxlength="50" :placeholder="$t('general.cescribir')+'...'" value="">
                    </div>
                </ais-search-box>
                <div class="card_dd_users custom_scrollbar">
                        <ais-infinite-hits :transform-items="orderbyName">
                            <template slot-scope="{ items }">
                            <ul class="card_dd_userlist">
                                <li v-for="user in items" class="partner_block">
                                    <div class="partner_top">
                                        <div class="partner_avatar">
                                            <img :src="$util.getImage(user)" width="100%">
                                        </div>
                                        <div class="partner_info">
                                            <h6 class="partner_user_name">{{user.account_details.account_name}}</h6>
                                            <span class="partner_user_prof">{{user.account_details.account_subtitle}}</span>
                                        </div>
                                    </div>
                                    <button @click="sendLinkcard(user)" class="btn-primary btn-send" style="background-color: #0090e4;"><font-awesome-icon icon="paper-plane" style="color: #fff;"/></button>
                                </li>
                            </ul>
                            </template>
                    </ais-infinite-hits>
                </div>
            </ais-instant-search>
        </div>
    </div>
  </modal>
</template>
<script>
import algoliasearch from 'algoliasearch/lite'
import { algolia} from '@/services/firebaseConfig.js'

export default {
    data() {
        return {
            searchClient: algoliasearch(
                'GOGEG33K6D',
                algolia.apikey
            ),
            isPublish:false,
            algolia_index:algolia.index,
            getUser:this.$store.state.userProfile,
            isSend:false,
            dirUser:{},
            cardVal:{},
        };
    },
    methods:{
        open(type){
            console.log('er',type)
        },
        beforeOpen (event) {

            this.cardVal=JSON.parse(JSON.stringify(event.params.linkcard))
            this.isSend=event.params.send?event.params.send:false
            this.isPublish=event.params.publish?true:false
            this.dirUser=event.params.user?JSON.parse(JSON.stringify(event.params.user)):this.$store.state.userProfile
        },
        sendLinkcard(user_to){
            let source = this.$router.history.current.name.includes('sector')?'sectors':this.$router.history.current.name.includes('contacts')?'contacts':'directory'
            this.dirUser.linkcard_id=this.cardVal.id
            let notiObij={
                linkcard_id:this.cardVal.id,
                uid:this.dirUser.uid,
                email:this.dirUser.email,
                username:this.dirUser.username,
                title:this.cardVal.info.nombre,
                subtitle:this.cardVal.info.trabajo,
                img:this.cardVal.info.img,
                locale:this.dirUser.account_details.account_locale?this.dirUser.account_details.account_locale:'es'
            }
            
            this.$util.registerStat_old('send_action','linkcard',source,'bc',user_to,notiObij)
            this.$util.notif_success(this.$t('general.sendtarjetaexito'))
            this.$util.hideAllPopups();
        },
        orderbyName(items) {
            return items.sort((a, b) => {
                let ca=a.account_details.account_name
                let cb=b.account_details.account_name
                return ca.localeCompare(cb)
            })
            
        },
        doCopy() {
            var dis=this
            if(process.client){
                document.getElementById("copiarurl").select()
                document.execCommand("copy");
                this.$util.notif_success(this.$t('firma.docopy'))
            }
        },
        getLinkcardURL() {
            var dis=this
            let shareurl=this.cardVal.share_url
            if(shareurl && shareurl.includes('pge.link') && !shareurl.includes('pge.link/m'))this.cardVal.share_url=null
            return shareurl?shareurl:dis.$util.cardURL()+this.dirUser.username+'/'+this.cardVal.id
        },
        openLinckard() {
            window.open(this.getLinkcardURL(), '_blank');
        },
    },
    computed:{
        wpweb(){
            return 'wpweb_'+this.id
        },
        messenger(){
            return 'msg_'+this.id
        },
    },
}

</script>

