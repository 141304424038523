import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _be4934ec = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _bc08ea28 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _ed4ada74 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _20a97d31 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _6ddf1c8a = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _14817050 = () => interopDefault(import('../pages/import.vue' /* webpackChunkName: "pages/import" */))
const _51c0cd0a = () => interopDefault(import('../pages/linkcards_no.vue' /* webpackChunkName: "pages/linkcards_no" */))
const _3a33da06 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49865c6e = () => interopDefault(import('../pages/login_old.vue' /* webpackChunkName: "pages/login_old" */))
const _59586f3d = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _75415305 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _a7df0d56 = () => interopDefault(import('../pages/signature.vue' /* webpackChunkName: "pages/signature" */))
const _4cab5b24 = () => interopDefault(import('../pages/signatures.vue' /* webpackChunkName: "pages/signatures" */))
const _90b07c92 = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _557ba442 = () => interopDefault(import('../pages/uikit.vue' /* webpackChunkName: "pages/uikit" */))
const _9413ddb6 = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _62bd5ea1 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _ce977d32 = () => interopDefault(import('../pages/leads/landings.vue' /* webpackChunkName: "pages/leads/landings" */))
const _4d5e6ee4 = () => interopDefault(import('../pages/leads/linkcards.vue' /* webpackChunkName: "pages/leads/linkcards" */))
const _38f14e3d = () => interopDefault(import('../pages/leads/settings_landings.vue' /* webpackChunkName: "pages/leads/settings_landings" */))
const _40b55678 = () => interopDefault(import('../pages/leads/settings_linkcards.vue' /* webpackChunkName: "pages/leads/settings_linkcards" */))
const _ff42c2ee = () => interopDefault(import('../pages/loyalty/loyaltys.vue' /* webpackChunkName: "pages/loyalty/loyaltys" */))
const _1f35ab71 = () => interopDefault(import('../pages/loyalty/memberships.vue' /* webpackChunkName: "pages/loyalty/memberships" */))
const _0b851254 = () => interopDefault(import('../pages/onboarding/landing.vue' /* webpackChunkName: "pages/onboarding/landing" */))
const _0dc8fa4a = () => interopDefault(import('../pages/onboarding/linkcard.vue' /* webpackChunkName: "pages/onboarding/linkcard" */))
const _277c5185 = () => interopDefault(import('../pages/onboarding/loyalty.vue' /* webpackChunkName: "pages/onboarding/loyalty" */))
const _5c9cecea = () => interopDefault(import('../pages/sector/import.vue' /* webpackChunkName: "pages/sector/import" */))
const _505c165f = () => interopDefault(import('../pages/stats/landings.vue' /* webpackChunkName: "pages/stats/landings" */))
const _16a39296 = () => interopDefault(import('../pages/stats/linkcards.vue' /* webpackChunkName: "pages/stats/linkcards" */))
const _05749004 = () => interopDefault(import('../pages/wizard/data.js' /* webpackChunkName: "pages/wizard/data" */))
const _53234324 = () => interopDefault(import('../pages/sector/sectors/landings.vue' /* webpackChunkName: "pages/sector/sectors/landings" */))
const _6cc1fe71 = () => interopDefault(import('../pages/sector/sectors/linkcards.vue' /* webpackChunkName: "pages/sector/sectors/linkcards" */))
const _709dc262 = () => interopDefault(import('../pages/wizard/steps/sector.vue' /* webpackChunkName: "pages/wizard/steps/sector" */))
const _01f99be0 = () => interopDefault(import('../pages/wizard/steps/templates.vue' /* webpackChunkName: "pages/wizard/steps/templates" */))
const _08020ce3 = () => interopDefault(import('../pages/wizard/steps/type.vue' /* webpackChunkName: "pages/wizard/steps/type" */))
const _5f1ea103 = () => interopDefault(import('../pages/wizard/steps/upgrade.vue' /* webpackChunkName: "pages/wizard/steps/upgrade" */))
const _5ee75ca4 = () => interopDefault(import('../pages/sector/sectors/_type/index.vue' /* webpackChunkName: "pages/sector/sectors/_type/index" */))
const _d3025f52 = () => interopDefault(import('../pages/sector/sectors/_type/_sector.vue' /* webpackChunkName: "pages/sector/sectors/_type/_sector" */))
const _4f14f18e = () => interopDefault(import('../pages/editor/_id/index.vue' /* webpackChunkName: "pages/editor/_id/index" */))
const _c9a0b034 = () => interopDefault(import('../pages/loyalty/_loyalty/index.vue' /* webpackChunkName: "pages/loyalty/_loyalty/index" */))
const _f957f912 = () => interopDefault(import('../pages/sectors/_type/index.vue' /* webpackChunkName: "pages/sectors/_type/index" */))
const _30f9cd20 = () => interopDefault(import('../pages/templates/_type.vue' /* webpackChunkName: "pages/templates/_type" */))
const _1f89f5c1 = () => interopDefault(import('../pages/loyalty/_loyalty/import.vue' /* webpackChunkName: "pages/loyalty/_loyalty/import" */))
const _5f5f44a6 = () => interopDefault(import('../pages/loyalty/_loyalty/members.vue' /* webpackChunkName: "pages/loyalty/_loyalty/members" */))
const _4fc29533 = () => interopDefault(import('../pages/loyalty/_loyalty/stats.vue' /* webpackChunkName: "pages/loyalty/_loyalty/stats" */))
const _20152f6c = () => interopDefault(import('../pages/sector/_sector/landings.vue' /* webpackChunkName: "pages/sector/_sector/landings" */))
const _3e0d9b29 = () => interopDefault(import('../pages/sector/_sector/linkcards.vue' /* webpackChunkName: "pages/sector/_sector/linkcards" */))
const _107fd747 = () => interopDefault(import('../pages/linkcards/_uid/_sid/index.vue' /* webpackChunkName: "pages/linkcards/_uid/_sid/index" */))
const _9199597c = () => interopDefault(import('../pages/sharing/_user/_id/index.vue' /* webpackChunkName: "pages/sharing/_user/_id/index" */))
const _708c36ef = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _be4934ec,
    name: "account"
  }, {
    path: "/admin",
    component: _bc08ea28,
    name: "admin"
  }, {
    path: "/contacts",
    component: _ed4ada74,
    name: "contacts"
  }, {
    path: "/dashboard",
    component: _20a97d31,
    name: "dashboard"
  }, {
    path: "/directory",
    component: _6ddf1c8a,
    name: "directory"
  }, {
    path: "/import",
    component: _14817050,
    name: "import"
  }, {
    path: "/linkcards_no",
    component: _51c0cd0a,
    name: "linkcards_no"
  }, {
    path: "/login",
    component: _3a33da06,
    name: "login"
  }, {
    path: "/login_old",
    component: _49865c6e,
    name: "login_old"
  }, {
    path: "/logout",
    component: _59586f3d,
    name: "logout"
  }, {
    path: "/notifications",
    component: _75415305,
    name: "notifications"
  }, {
    path: "/signature",
    component: _a7df0d56,
    name: "signature"
  }, {
    path: "/signatures",
    component: _4cab5b24,
    name: "signatures"
  }, {
    path: "/solutions",
    component: _90b07c92,
    name: "solutions"
  }, {
    path: "/uikit",
    component: _557ba442,
    name: "uikit"
  }, {
    path: "/users",
    component: _9413ddb6,
    name: "users"
  }, {
    path: "/wizard",
    component: _62bd5ea1,
    name: "wizard"
  }, {
    path: "/leads/landings",
    component: _ce977d32,
    name: "leads-landings"
  }, {
    path: "/leads/linkcards",
    component: _4d5e6ee4,
    name: "leads-linkcards"
  }, {
    path: "/leads/settings_landings",
    component: _38f14e3d,
    name: "leads-settings_landings"
  }, {
    path: "/leads/settings_linkcards",
    component: _40b55678,
    name: "leads-settings_linkcards"
  }, {
    path: "/loyalty/loyaltys",
    component: _ff42c2ee,
    name: "loyalty-loyaltys"
  }, {
    path: "/loyalty/memberships",
    component: _1f35ab71,
    name: "loyalty-memberships"
  }, {
    path: "/onboarding/landing",
    component: _0b851254,
    name: "onboarding-landing"
  }, {
    path: "/onboarding/linkcard",
    component: _0dc8fa4a,
    name: "onboarding-linkcard"
  }, {
    path: "/onboarding/loyalty",
    component: _277c5185,
    name: "onboarding-loyalty"
  }, {
    path: "/sector/import",
    component: _5c9cecea,
    name: "sector-import"
  }, {
    path: "/stats/landings",
    component: _505c165f,
    name: "stats-landings"
  }, {
    path: "/stats/linkcards",
    component: _16a39296,
    name: "stats-linkcards"
  }, {
    path: "/wizard/data",
    component: _05749004,
    name: "wizard-data"
  }, {
    path: "/sector/sectors/landings",
    component: _53234324,
    name: "sector-sectors-landings"
  }, {
    path: "/sector/sectors/linkcards",
    component: _6cc1fe71,
    name: "sector-sectors-linkcards"
  }, {
    path: "/wizard/steps/sector",
    component: _709dc262,
    name: "wizard-steps-sector"
  }, {
    path: "/wizard/steps/templates",
    component: _01f99be0,
    name: "wizard-steps-templates"
  }, {
    path: "/wizard/steps/type",
    component: _08020ce3,
    name: "wizard-steps-type"
  }, {
    path: "/wizard/steps/upgrade",
    component: _5f1ea103,
    name: "wizard-steps-upgrade"
  }, {
    path: "/sector/sectors/:type",
    component: _5ee75ca4,
    name: "sector-sectors-type"
  }, {
    path: "/sector/sectors/:type?/:sector",
    component: _d3025f52,
    name: "sector-sectors-type-sector"
  }, {
    path: "/editor/:id",
    component: _4f14f18e,
    name: "editor-id"
  }, {
    path: "/loyalty/:loyalty",
    component: _c9a0b034,
    name: "loyalty-loyalty"
  }, {
    path: "/sectors/:type",
    component: _f957f912,
    name: "sectors-type"
  }, {
    path: "/templates/:type?",
    component: _30f9cd20,
    name: "templates-type"
  }, {
    path: "/loyalty/:loyalty?/import",
    component: _1f89f5c1,
    name: "loyalty-loyalty-import"
  }, {
    path: "/loyalty/:loyalty?/members",
    component: _5f5f44a6,
    name: "loyalty-loyalty-members"
  }, {
    path: "/loyalty/:loyalty?/stats",
    component: _4fc29533,
    name: "loyalty-loyalty-stats"
  }, {
    path: "/sector/:sector?/landings",
    component: _20152f6c,
    name: "sector-sector-landings"
  }, {
    path: "/sector/:sector?/linkcards",
    component: _3e0d9b29,
    name: "sector-sector-linkcards"
  }, {
    path: "/linkcards/:uid?/:sid",
    component: _107fd747,
    name: "linkcards-uid-sid"
  }, {
    path: "/sharing/:user?/:id",
    component: _9199597c,
    name: "sharing-user-id"
  }, {
    path: "/",
    component: _708c36ef,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
