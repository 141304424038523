<template>
   <div  class="edicion_block">
        <h4>{{$t('drags.code')}}</h4>
        <label>{{$t('drags.codesub')}}</label>
        <div class="row form_row">
            <div class="col-12">
                <div class="form-group">
                    <textarea maxlength="1000"  rows="7" type="text" :placeholder="$t('general.entervalue')" v-model="valor.value"  style="width:100% !important;border:0px;overflow:hidden;" ></textarea>
                </div>
            </div>
        </div>
   </div>
</template>

<script>





import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'
import fontPicker from '../dinputs/fontPicker'

export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value,
		}
    },  
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'fontPicker':fontPicker
    }
}
</script>

