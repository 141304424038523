
<template>
<client-only>
  <modal name="modal-datosmember"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto">
         
   <div class="modal_wrapper " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-datosmember')" class="ngdialog-close"></button>
        </div>
        <form  class="card " novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h3 class="text-center">{{$t('onboarding.comp')}}</h3>
                <small class="text-center mb20">{{$t('loyaltys.faltandatos')}}</small>
                <div  class="form-group">
                    <label>{{$t('info.birthday')}}</label>
                    <div style="width:100%;">
                        <input type="text" style="width:30%;display:inline-block;" pattern="\d*" maxlength="2" v-model.trim="birthday_day"  name="birthday_day" v-validate="'required|min:2|max:2'"  :class="{ 'is-invalid': errors.has('birthday_day') }" :placeholder="'DD'" class="form-control dark" >
                        <input type="text" style="width:30%;display:inline-block;" pattern="\d*" maxlength="2"  v-model.trim="birthday_month"  name="birthday_month" v-validate="'required|min:2|max:2'" :class="{ 'is-invalid': errors.has('birthday_month') }" :placeholder="'MM'" class="form-control dark" >
                        <input type="text" style="width:30%;display:inline-block;" pattern="\d*" maxlength="4"  v-model.trim="birthday_year"  name="birthday_year" v-validate="'required|min:4|max:4'" :class="{ 'is-invalid': errors.has('birthday_year') }" :placeholder="'AAAA'" class="form-control dark" >
                    </div>
                    <client-only><span class="vuerror">{{ errors.first('birthday_day') }}</span></client-only>
                    <client-only><span class="vuerror">{{ errors.first('birthday_month') }}</span></client-only>
                    <client-only><span class="vuerror">{{ errors.first('birthday_year') }}</span></client-only>
                </div>
                <div class="form-group ">
                    <label>{{$t('info.dni')}}</label>
                    <input type="text" v-model.trim="getUser.account_details.account_dni"  name="dni" v-validate="{ required: true}"  :class="{ 'is-invalid': errors.has('dni') }" class="form-control dark" >
                    <div class="vuerror" v-if="errors.has('dni')" >{{ errors.first('dni') }}</div>
                </div>
                <div class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100" 
                    type="submit" 
                    :is-loading="loading"
                    :disabled="loading"
                    :status="status"> 
                        {{$t('general.guardar')}} 
                    </button-spinner>
                </div>

            </div>
        </form>
      
        
    </div>
  </modal>
</client-only>
</template>
<script>
import { Validator } from "vee-validate";
import {functions,db } from '@/services/firebaseConfig.js';

import { planes_businesscards} from '@/mixins/stripe.js'
import {sectors} from '@/mixins/categories.js'

export default {
    data () {
      return {
        getUser:this.$store.state.userProfile,
        status:'',
        loading:false,
        birthday_day:'',
        birthday_month:'',
        birthday_year:'',
        dni:'',
      }
    },
    watch:{
        birthday_day(){ this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)},
        birthday_month(){this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)},
        birthday_year(){ this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)}
    },
    methods: {

        async save(){
            var dis=this
            //nuevo
            db.collection('users').doc(this.getUser.uid).update({account_details:this.getUser.account_details})
            dis.loading = false
            dis.status = '';
            dis.$modal.hide('modal-datosmember') 
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll().then((result) => {
                if(result){
                    dis.save()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
    },
  }
</script>

<style scoped>
.listaagentes{
max-height: 450px !important;
overflow-y: scroll;
}
.card-body h3{
    font-size:20px;
    text-align: left;
    margin-top:10px;
    color:#448FDE;
}
</style>