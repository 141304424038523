<template>
<div>
    <div v-if="!$util.isCustomizer()" class="horizontal_nav" >
        <a @click="menuToggle()" class="nav_menu_button">
        <font-awesome-icon :icon="['fas','bars']" /> 
        <!--<span v-if="$store.state.userProfile.notis>=1" class="notify_dot"></span>-->
        </a>
        <ul class="nav_center">
           <!-- <template v-if="$router.history.current.name.includes('editor') && $router.history.current.name.includes('half')">
            <li v-if="!editor"><a @click="editor=!editor"><font-awesome-icon icon="edit"/> </a></li>
            <li v-else><a @click="editor=!editor"><font-awesome-icon icon="window-close"/> </a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('editor')">
            <li v-if="!editor"><a @click="editor=!editor"><font-awesome-icon icon="edit"/> </a></li>
            <li v-else><a @click="editor=!editor"><font-awesome-icon icon="window-close"/> </a></li>
            <li v-if="!signature"><a @click="rightColToggle()"><font-awesome-icon icon="signature"/> </a></li>
            <li v-else><a @click="rightColToggle()"><font-awesome-icon icon="window-close"/> </a></li>
            </template>-->
            <template v-if="$router.history.current.name.includes('notifications')">
            <li :class="{ active: $route.hash=='#favs'}"><a @click="$router.push('/notifications#favs')" ><font-awesome-icon   :icon="['fas','heart']"/> </a></li>
            <li :class="{ active: $route.hash=='#shares'}"><a @click="$router.push('/notifications#shares')" ><font-awesome-icon  :icon="['fas','paper-plane']" /> </a></li>
            <li :class="{ active: $route.hash=='#inbox'}"><a @click="$router.push('/notifications#inbox')" ><font-awesome-icon  :icon="['fas','envelope']" /> </a></li>
            <li :class="{ active: $route.hash=='#visits'}"><a @click="$router.push('/notifications#visits')" ><font-awesome-icon  :icon="['fas','eye']" /> </a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('partner')">
            <li v-if="!search_open"><a @click="searchToggle()" ><font-awesome-icon  :icon="['fas','search']"/><small>{{$t('general.buscar')}}</small></a></li>
            <li v-else><a @click="searchToggle()"><font-awesome-icon  icon="window-close"/> </a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('account')">
                <li :class="{ active: $route.hash=='#profile'}"><a @click="$router.push('/account#profile')" ><font-awesome-icon :icon="['fas','user']"/><small>{{$t('micuenta.micuenta')}}</small></a></li>
                <li :class="{ active: $route.hash=='#plans'}" ><a @click="$router.push('/account#plans')"  ><font-awesome-icon :icon="['fas','credit-card']"/><small>{{$t('micuenta.subs')}}</small></a></li>
                <li :class="{ active: $route.hash=='#billing'}"><a @click="gotoPortal()"><font-awesome-icon :icon="['fab','stripe']"/><small>{{$t('micuenta.miplan')}}</small></a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('directory')">
            <li v-if="!search_open"><a @click="searchToggle()" ><font-awesome-icon  :icon="['fas','search']"/><small>{{$t('general.buscar')}}</small></a></li>
            <li v-else><a @click="searchToggle()" ><font-awesome-icon  icon="window-close"/></a></li>
            </template>
            <!--            <template v-else-if="$util.isBCDash()">
            <li :class="{ active: $router.history.current.name.includes('linkcards')}"><a @click="$util.gotoHOME()" ><font-awesome-icon  :icon="['fas','id-badge']"/><small>{{$t('leftnav.milinc')}}</small></a></li>
            <li :class="{ active: $router.history.current.name.includes('signatures')}"><a @click="$router.push('/signatures')" ><font-awesome-icon  :icon="['fas','signature']"/><small> {{$t('leftnav.signature')}}</small></a></li>
            <li :class="{ active: $router.history.current.name.includes('stats')}"><a @click="$router.push('/stats/linkcards')" ><font-awesome-icon  :icon="['fas','chart-line']"/><small> {{$t('micuenta.mismetricas')}}</small></a></li>
            </template>*/-->
            <template v-else>
            <li :class="{ active: $router.history.current.name === 'sector-sector-landings' || $router.history.current.name === 'sector-sectors-landings'}"><a @click="toggleRoute($util.DASHHOME_url())">
                <Icon icon="ph:magic-wand-light"/>
                                <small>{{$t('leftnav.milinc')}}</small></a></li>
            <li :class="{ active: $router.history.current.name == 'stats-landings' }"><a @click="enable_Route('/stats/landings')">
                <Icon icon="ph:chart-line-light"/>
                                <small>{{$t('micuenta.mismetricas')}}</small></a></li>
            <li :class="{ active: $router.history.current.name == 'leads-landings' }"><a @click="enable_Route('/leads/landings')">
                <Icon icon="ph:users-three-light"/>
                                <small>{{$t('leftnav.leads')}}</small></a></li>
            </template>
        </ul>
    </div>
    <div class="content_overlay" @click="menuToggle()"  />
    <leftColMobile :profile="getUser" v-if="$util.isCustomizer()" />
</div>

</template>
    

<script>
import {auth,functions,db } from '@/services/firebaseConfig.js';
import leftColMobile from '@/layouts/main_Customizer/leftCol_Mobile.vue'

export default {
    props:['value','comp'],
    data(){
        return{
            search_open:false,
            signature:false,
            getUser:this.$store.state.userProfile,
            loading:false
        }
    },
    created() {
        var dis = this
        this.$bus.$on('switchComp', comp => {
            comp=comp.replace('preview', "leftCol");
            if(comp=='leftColSobre')dis.editor=true
            else dis.editor=false
        });
    },
    methods:{

        enable_Route(route){
            if(this.$util.isTrialOrPro(this.$modal,route))this.toggleRoute(route)
        },

        toggleRoute(route){
            this.$router.push(route)
            this.$util.closeAllDivs()
        },
        async gotoPortal(){
            var dis=this
            this.$router.push('/account#portal')
            this.loading=true

            const get_stripe_portal = functions.httpsCallable('get_stripe_portal');
            await get_stripe_portal({customer_id:this.getUser.stripe_details.stripe_customer_id,return_url:process.env.URL+'account#profile',})
            .then(function(result) {
                window.location.href=result.data.url
                dis.loading_dash=false
            }).catch(error => {
                console.log(error)
            })
        },
        customToggle(custom){
            this.$util.toggleDiv(custom);

        },
        rightColToggle() {
            this.signature=!this.signature
            this.$util.toggleDiv("rightcol_open");
		},
		searchClose(){
            this.$util.toggleDiv("search_open");
		},
        searchToggle() {
            this.search_open=!this.search_open
            this.$util.toggleDiv("search_open");
        },
        menuToggle(){
            this.$util.toggleDiv("menu_open");
        },
        isRight(){
			if(process.client){
                return document.body.classList.contains('rightcol_open');
			}
        }
    },
	components:{
		leftColMobile
	}
}
</script>

<style scoped>

li.active a small,
li.active a svg{
    color:grey !important;
}
</style>