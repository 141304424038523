<template>
    <div style="display: flex; margin: 0px; text-align: left; width: 100%;">
        <a @click="toggleRoute('/account#profile')" style="display: flex; margin: 0px; text-align: left; width: 100%;">
            <vs-avatar size="40" style="border-radius: 50%;">
                <img :src="user.account_details?.account_picture" alt="">
            </vs-avatar>
            <div style="margin-left: 10px; line-height: 12px; text-align: left;">
                {{ user.account_details?.account_name }}<br />
                <font size="1"> {{ user.email }}</font><br />
                <font size="1" style="color: rgb(0, 144, 228)">Plan: <b> {{ user.stripe_details?.stripe_plan?.name }}</b></font><br />
                <font size="1">Up to {{ user.stripe_details?.stripe_plan?.max_linkcards }} linkcards</font><br />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: ['user'],
}
</script>