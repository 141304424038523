<template>
   <div  class="edicion_block">
        <div  @click="show.s1=!show.s1" class="navcola manito" style="border-top:0px !important;">
            <h4>{{$t('bc.content')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s1" v-show-slide="show.s1">
            <div class="row form_row">
                <div class="col-12">
                    <div class="form-group">
                        <input class="form-control input_wicon input_website"  name="website"  type="text" maxlength="200" placeholder="Website" v-model='valor.value.website'>
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_facebook" type="text" maxlength="100" placeholder="Facebook" v-model='valor.value.facebook' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_instagram" type="text" maxlength="100" placeholder="Instagram" v-model='valor.value.instagram' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_twitter" type="text" maxlength="100" placeholder="Twitter" v-model='valor.value.twitter' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_linkedin" type="text" maxlength="100" placeholder="LinkedIn" v-model='valor.value.linkedin' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_youtube" type="text" maxlength="100" placeholder="Youtube" v-model='valor.value.youtube' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_vimeo" type="text" maxlength="100" placeholder="Vimeo" v-model='valor.value.vimeo' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_skype" type="text" maxlength="100" placeholder="Skype" v-model='valor.value.skype' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_behance" type="text" maxlength="100" placeholder="Behance" v-model='valor.value.behance' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_github" type="text" maxlength="100" placeholder="Github" v-model='valor.value.github' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_tiktok" type="text" maxlength="100" placeholder="Tiktok" v-model='valor.value.tiktok' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_spotify" type="text" maxlength="100" placeholder="Spotify" v-model='valor.value.spotify' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_snapchat" type="text" maxlength="100" placeholder="Snapchat" v-model='valor.value.snapchat' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_line" type="text" maxlength="100" placeholder="Line" v-model='valor.value.line' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_telegram" type="text" maxlength="100" placeholder="Telegram" v-model='valor.value.telegram' >
                    </div>
                    <div class="form-group">
                        <input class="form-control input_wicon input_googlemap" type="text" maxlength="200" placeholder="Google Map" v-model='valor.value.googlemaps'>
                    </div>
                </div>
            </div>
        </div>
        <div  @click="show.s2=!show.s2" class="navcola manito" style="border-top:0px !important;">
            <h4>{{$t('bc.design')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s2" v-show-slide="show.s2">
            <div class="row form_row">
                <div class="col-lg-6">
                    <colorPicker :color="valor.color" :label="$t('pickers.color_icon')" v-model="valor.color" />
                </div>
                <div class="col-lg-6">
                    <sizePicker :value="valor.size==undefined?'20px':valor.size"  v-model="valor.size"/>
            
                </div>
            </div>
        </div>
    </div>
</template>

<script>






import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'

export default {
    props: ['value'],
	data() {
		return { 
            show:{s1:true,s2:false },
            valor:this.value,
		}
    },  
    beforeMount(){
        if(!this.valor.value)this.valor.value={facebook:'#',instagram:'#',website:'#',twitter:'#',linkedin:'#'}
    },
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker
    }
}
</script>

