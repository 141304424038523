
<template>
  <modal name="modal-limitededition"
        transition="nice-modal-fade"
        class="limitededition"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="300"
        height="auto">
   <div class="modal_wrapper " >
        <div  class="card " >
            <div class="card-body text-center">
                <img width="100%" src="@/assets/images/editor.jpg" />
                <h4 class="text-center mb20 mt20">{{$t('editor.edi1')}}</h4>
                <p class="text-center mb20">{{$t('editor.edi2')}}</p>
                <button @click="$modal.hide('modal-limitededition')" class="btn btn-primary btn-initial " type="button" >{{$t('notis.gotit')}}</button> 
            </div>
        </div>
    </div>
  </modal>
</template>
<script>
export default {
    data () {
        return {
        }
    },
    methods:{
    }
}
</script>
