<template >
<div class="tarjeta_edit_wrapper" style="min-height: 645px;" @click.stop="doThat" >
<div class="tarjeta_bg animated fadeIn">
    <div v-if="linc.style.membre.fondo.active" class="tarjeta_bg_container" >
        <div class="tarjeta_bg_logo" style="position:relative;" :style="{'animation-name':linc.style.membre.fondo.animacion?'spin':'none'}">
            <img :src='$util.getBackMembreImage(linc,null)' width="100%" id="tarjetaBgLogo">
        </div>
    </div>
</div>
<div class="tarjeta_evento_wrapper tarjeta_loyalty_wrapper tarjeta_section current_view" id="tarjetaInfo" >
<div style="position: relative;"> <!--z-index: 2-->
    <div class="tarjeta_header mb25">
        <div class="tarjeta_foto animated fadeInDown" style="-webkit-animation-delay: .4s; animation-delay: .4s;">
            <img  :src="$util.getLincImageLoyalty(preview)" @error="function(event){event.target.src=$util.getLincImageLoyalty(preview,null)}" class="w-100">
        </div>
        <h3 v-if="!$util.isLoyaltyCustomizer() && !$util.isOnboarding()" class="tarjeta_nombre animated flipInX" style="-webkit-animation-delay: .5s; animation-delay: .5s;">{{linc.membre.nombre}}</h3>
        <h3 v-else class="tarjeta_nombre animated flipInX" style="-webkit-animation-delay: .5s; animation-delay: .5s;">{{$t('info.nombremiembro')}}</h3>
        <h6  class="tarjeta_profesion animated fadeInUp" style="text-transform:uppercase;-webkit-animation-delay: .6s; animation-delay: .6s;">{{$t('general.member')}} #{{$util.paddy(linc.membre.number,5)}}</h6>
        
        <div class="button_group animated fadeIn" style="-webkit-animation-delay: .4s; animation-delay: .4s;">
            <button  type="button"   @click="switchComponent('previewQR_link');"  class="button_camera"><font-awesome-icon icon="qrcode"/></button>
        </div>
    </div>
    <div class="tarjeta_loyalty_details">
        <template v-if="!isNoPoints()" >
            <div class="loyalty_puntaje animated fadeInDown" style="background-color: #d8983d; -webkit-animation-delay: .5s; animation-delay: .5s;">
                <span>{{$t('preview.membre.punta')}}</span>
                <strong>{{linc.membre.credits}}</strong>
            </div>
            <p  class="subtexto animated fadeInDown" style="color: #d8983d; -webkit-animation-delay: .6s; animation-delay: .6s;">-<!--{{$t('preview.membre.sumay')}}--></p>
        </template>
        <template v-else >
            <div class="loyalty_puntaje animated fadeInDown" style="background-color: transparent!important; -webkit-animation-delay: .5s; animation-delay: .5s;">
                <span></span>
                <strong></strong>
            </div>
            <p class="subtexto animated fadeInDown" style="color: #d8983d; -webkit-animation-delay: .6s; animation-delay: .6s;"></p>
        </template>
        <ul class="loyalty_datos animated fadeInLeft" style="-webkit-animation-delay: .7s; animation-delay: .7s;">
            <li><label style="background-color: #d8983d;">{{$t('preview.membre.email')}}</label><span style="background-color: #d8983d;">{{linc.membre.email}}</span></li>
            <li><label style="background-color: #d8983d;">{{$t('preview.membre.cumple')}}</label><span style="background-color: #d8983d;">{{$util.getDateAS(dirUser,linc.membre.birthday)}}</span></li>
            <li><label style="background-color: #d8983d;">{{$t('preview.membre.dni')}}</label><span style="background-color: #d8983d;">{{linc.membre.dni}}</span></li>
            <li><label style="background-color: #d8983d;">{{$t('preview.membre.mobile')}}</label><span style="background-color: #d8983d;">{{linc.membre.mobilephone}}</span></li>
        </ul>
        <a @click="abrirTuto()"  class="loyalty_link animated fadeInRight" style="color: #d8983d; -webkit-animation-delay: .8s; animation-delay: .8s;">{{$t('preview.membre.comog')}}</a>
    </div>
</div>
</div>  
    <div class="tarjeta_foot_wrapper">
        <div class="tarjeta_footer animated fadeInUp" v-show='linc.style.membre.caja_logo && linc.style.membre.caja_logo.active' id="tarjetaFooter"> 
            <button type="button" @click="openUrl(linc.style.membre.caja_logo.url)">
            <img :src="$util.getFooterImgMember(linc,null)" class="w-100">
            </button>
        </div>
    </div>
</div>
</template>
<script>


export default {
    props:['preview','diruser'],
    data(){
        return{
            dirUser:this.diruser,
            colors:'',
            linc: this.preview,
        }
        
    },
    methods: {
        isNoPoints(){
            if(this.preview.nopoints!=undefined)return this.preview.nopoints//frontend
            else if(this.dirUser && this.dirUser.loyaltys){//backend
                let loyalty_id=this.$route.params.loyalty
                let index=this.dirUser.loyaltys.findIndex(function(i){return i.id == loyalty_id;}) 
                let loy= this.dirUser.loyaltys[index]
                if(loy && loy.nopoints!=undefined)return loy.nopoints
            }
            return false
        },
        getLincImage_error(event) {
            console.log(event)
          event.target.src = this.$util.getLincImage_error(this.preview,null)
        },
        abrirTuto(){
            this.switchComponent('previewADDTOHOME');
        },

        doThat(){
            if(this.$util.isCustomizer())alert(this.$t('notis.edicion'))
            if(this.$util.isOnboarding())alert(this.$t('notis.edicion'))
        },

        switchComponent(comp) {
            this.$bus.$emit("switchComp", comp);
        },
    }
};
</script>

<style>
.tarjeta_loyalty_wrapper .tarjeta_header .tarjeta_nombre {
    font-family: var(--membre-nombre-family) !important;
    color:var(--membre-nombre-color) !important;
    font-size: var(--membre-nombre-size) !important;
    font-weight: var(--membre-nombre-weight) !important;
}
.previewMembre .tarjeta_profesion {
    font-family: var(--membre-cargo-family)!important;
    color:var(--membre-cargo-color) !important;
    font-size: var(--membre-cargo-size) !important;
    font-weight: var(--membre-cargo-weight) !important;
}

.previewMembre .tarjeta_bg{
    background-color: var(--membre-fondo-background) !important;
}

.previewMembre .tarjeta_bg .tarjeta_bg_logo{
    opacity: var(--membre-fondo-opacity) !important;
    width: var(--membre-fondo-width) !important;
    left:var(--membre-fondo-left) !important;
    top:var(--membre-fondo-top) !important;
}
#tarjetaInfo .tarjeta_descripcion p,
#tarjetaInfo .tarjeta_descripcion p pre{
    font-family: var(--membre-pie-family) !important;
    color:var(--membre-pie-color) !important;
    line-height:var(--membre-pie-lineh) !important;
    font-size: var(--membre-pie-size) !important;
    font-weight: var(--membre-pie-weight) !important;
    min-height:100px !important;
}


.previewMembre .tarjeta_footer{
    background-color: var(--membre-caja_logo-background) !important;
}

.tarjeta_loyalty_wrapper .loyalty_puntaje {
    color:var(--membre-creditos-color) !important;
    background-color: var(--membre-creditos-background) !important;
}
.tarjeta_loyalty_wrapper .tarjeta_loyalty_details p{
    color:var(--membre-creditos-background) !important;
}

.tarjeta_loyalty_wrapper .loyalty_datos span ,
.tarjeta_loyalty_wrapper .loyalty_datos label{
    color:var(--membre-datos-color) !important;
    background-color: var(--membre-datos-background) !important;
    
}

.tarjeta_loyalty_wrapper .loyalty_link{
    color:var(--membre-datos-background) !important;
    
}
</style>
