<template>
<div class="mb-3">
	<label>{{labelName}}</label>
	<vue-slider style="margin-top:10px;"
 	@drag-start="$store.state.isLiveEditing=true" 
 	@drag-end="sendlast()" 
	v-model="inputVal" 
	:min="pmin"
	:max="pmax"
	:interval="pinterval"/>
</div>
</template>


<script>


export default {
	props: ['value','min','max','interval','label','unit'],
	data() {
		return {
			isDragging:false,
			punit:this.unit!=undefined?this.unit:'px',
			pmin:this.min?this.min:10,
			pmax:this.max?this.max:100,
			labelName:this.label?this.label:this.$t('pickers.size'),
			pinterval:this.interval?this.interval:1,
			inputVal:parseInt(this.value ) 
		}
	},
	methods:{
		sendlast(){
			this.$store.state.isLiveEditing=false;
			var dis=this
			let val=this.inputVal+this.punit;

			//Fix a la velocidad de la luz
			dis.$emit('input', '');
			dis.$emit('input', val);
		}
	},
	watch: {
		inputVal(val) {
			this.$emit('input', val+this.punit);
		}
	},
}
</script>