
<template>
  <modal name="modal-loyalty-terms"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
        
   <div class="modal_wrapper " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-loyalty')" class="ngdialog-close"></button>
        </div>
        <form class="card mb20" novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h4  style="color:#438FDE;font-size: 28px;text-align:center; margin: 0 0 0px;display:block !important;">{{$t('loyaltys.terms')}}</h4>
                <p style="text-align:center;font-size: 10px;">{{$t('loyaltys.termssub')}}</p>
                
                <div class="form-group">
                    <textarea maxlength="400" style="overflow:hidden" rows="6"  name="desc" v-validate="{ required: true }" class="form-control dark" type="text"  :placeholder="$t('micuenta.breve')"  v-model='objMain.desc'/>
                    <client-only><span class="vuerror">{{ errors.first('desc') }}</span></client-only>
                </div>

                <div class="form-group mt20" style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100" 
                    type="submit" 
                    :is-loading="loading"
                    :disabled="loading"
                    :status="status"> 
                        {{$t('general.guardar')}} 
                    </button-spinner>
                </div>

            </div>
        </form>
      
        
    </div>

</modal>
</template>
<script>

import {storage,usersCollection } from '@/services/firebaseConfig.js';

export default {
    data () {
      return {
          getUser:this.$store.state.userProfile,
          loading:false,
          status:'',
          objMain:{
              id:null,
              name:'',
              desc:'',
              locale:this.$i18n.locale,
              location:'',
          },
          yaseedito:false
      }
    },
    
    methods: {
        beforeOpen (event) {
            var dis=this;
            dis.status=''
            dis.loading=false

            if(event.params.loyalty){
                this.objMain=JSON.parse(JSON.stringify(event.params.loyalty)); 
                this.yaseedito=this.objMain.name.length>0 
            }/*else {
                this.objMain=this.$util.initLoyalty(this.objMain,this.getUser)
                this.yaseedito=false
            }*/
        },  
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll()
            .then((result) => {
                if(result){
                    dis.saveLoyalty()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },

        async saveLoyalty(){
            var dis=this;

            //Reemplazo si existe
            if(!this.getUser.loyaltys)this.getUser.loyaltys=[]
            let index=dis.getUser.loyaltys.findIndex(function(i){return i.id == dis.objMain.id})
            if(index>=0){
                dis.getUser.loyaltys[index].name=this.objMain.name
                dis.getUser.loyaltys[index].desc=this.objMain.desc
                dis.getUser.loyaltys[index].nopoints=this.objMain.nopoints
                dis.getUser.loyaltys.splice(index,1,this.objMain);

            }else this.getUser.loyaltys.push(this.objMain);

            //Aca entra si ya existia
            usersCollection.doc(dis.getUser.uid)
            .update({loyaltys:dis.getUser.loyaltys,stats:dis.getUser.stats})
            .then(function(){
                dis.$modal.hide('modal-loyalty')
                dis.$util.initTrial(dis.getUser,"loyalty")
                dis.$util.notif_success(dis.$t('notis.setsaved'));
                dis.loading=false;
                dis.$bus.$emit('updateLoyaltys',dis.getUser);
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
            
        },
        
        
    }
    
  }
</script>
<style>
.modal_wrapper {
padding: 10px;
font-style: 13px;
border-radius: 5px;
}
.v--modal-overlay[data-modal="size-modal"] {
background: #ededed;
}
</style>
