<template>
<form>
   
    <!-- BLOCK EDITAR "FONDO" -->
    <div class="edicion_block">
        <h4>{{$t('style.generales.fondo')}}</h4>

        <div class="row form_row">
            <div class="col-12">
                <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.fondo.background"  v-model="preview.fondo.background" />
            </div>
        </div>
    </div><!-- /BLOCK EDITAR "FONDO" -->

    <hr class="separator mt20 mb20">


    <div class="edicion_block">
        <h4>{{$t('style.guardar.tit')}}</h4>
        <div class="row form_row">
            <div class="col-12">
                <fontPicker v-model="preview.tit.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-6">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''" v-model="preview.tit.weight" />
            </div>
             <div class="col-lg-6">
                <sizePicker   v-model="preview.tit.size"/>
            </div>
        </div>

        <div class="row form_row">

           
            <div class="col-lg-12">
                <colorPicker :color="preview.tit.color"   v-model="preview.tit.color" />
            </div>
        </div>
    </div>
    <hr class="separator mt20 mb20">

    <div class="edicion_block">
        <h4>{{$t('style.guardar.sec')}}</h4>
        <div class="row form_row">
            <div class="col-12">
                <fontPicker v-model="preview.sec.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-12">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"   v-model="preview.sec.weight" />
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-6">
                <sizePicker  :label="$t('pickers.inter')"  v-model="preview.sec.lineh"/>
            </div>
            <div class="col-lg-6">
                <sizePicker   v-model="preview.sec.size"/>
            </div>
            <div class="col-lg-12">
                <colorPicker :color="preview.sec.color"  v-model="preview.sec.color" />
            </div>
        </div>
    </div>

    <hr class="separator mt20 mb20">
    <div class="edicion_block" >
        <h4 style="font-size: 24px">
            <b>{{$t('style.generales.botones')}}</b>
        </h4>
        <div class="row form_row">
            <div class="col-12">
                
                <fontPicker v-model="preview.botones.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-6">
                
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="preview.botones.weight" />
            </div>
            <div class="col-lg-6">
                <sizePicker   v-model="preview.botones.size"/>
            </div>
          
        </div>


    </div><!-- /BLOCK EDITAR "AGENDAR EN CONTACTOS" -->

    <hr class="separator mt20 mb20">
    

    <hr class="separator mt20 mb20">
    <!-- BLOCK EDITAR "AGENDAR EN CONTACTOS" -->
    <div class="edicion_block" v-if="state.vcard">
        <h4 style="font-size: 24px">
            <img src="@/assets/images/icon_vcard_blue.svg" width="24" class="mr5">
            {{$t('guardar.vcard')}}
        </h4>
        <div class="row form_row">
            <div class="col-lg-12">
                <colorPicker :color="preview.contactos.color"  v-model="preview.contactos.color" />
            </div>
             <div class="col-lg-12">
                <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.contactos.background"  v-model="preview.contactos.background" />
            </div>
        </div>
    </div><!-- /BLOCK EDITAR "AGENDAR EN CONTACTOS" -->


    <hr class="separator mt20 mb20">

    <!-- BLOCK EDITAR "AÑADIR ACCESO DIRECTO" -->
    <div class="edicion_block" v-if="state.directo">
        <h4 style="font-size: 24px">
            <img src="@/assets/images/icon_add_blue.svg" width="24" class="mr5">
            {{$t('guardar.acceso')}}
        </h4>
        

        <div class="row form_row">

            <div class="col-lg-12">
                
                <colorPicker :color="preview.directo.color"  v-model="preview.directo.color" />
            </div>
            <div class="col-lg-12">
                
                <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.directo.background"  v-model="preview.directo.background" />
            </div>
        </div>

    </div><!-- /BLOCK EDITAR "AÑADIR ACCESO DIRECTO" -->

    <hr class="separator mt20 mb20">

    <!-- BLOCK EDITAR "IPHONE WALLET" 
    <div class="edicion_block" v-if="state.iwallet">
        <h4 style="font-size: 24px">
            <img src="@/assets/images/icon_iphonewallet_blue.svg" width="24" class="mr5">
           {{$t('guardar.wallet')}}
        </h4>
       <div class="row form_row">
            <div class="col-lg-6">
                <colorPicker :color="preview.iwallet.color"  v-model="preview.iwallet.color" />
            </div>
             <div class="col-lg-6">
                
                <colorPicker :color="preview.iwallet.background"  v-model="preview.iwallet.background" />
            </div>
        </div>

    </div>
    
    <hr class="separator mt20 mb20">
    -->



    <!-- BLOCK EDITAR "GUARDAR EN FAVORITAS" -->
    <div class="edicion_block" v-if="state.favs">
        <h4 style="font-size: 24px">
            <img src="@/assets/images/icon_favs_blue.svg" width="24" class="mr5">
           {{$t('guardar.favs')}}
        </h4>
        <div class="row form_row">
            <div class="col-lg-12">
                <colorPicker :color="preview.favs.color"  v-model="preview.favs.color" />
            </div>
             <div class="col-lg-12">
                <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.favs.background"  v-model="preview.favs.background" />
            </div>
        </div>

    </div><!-- /BLOCK EDITAR "GUARDAR EN FAVORITAS" -->

    <hr class="separator mt20 mb20">

    <!-- BLOCK EDITAR "CONTADOR DE VISITAS" 
    <div class="edicion_block" v-if="state.visits">
        <h4 style="font-size: 24px">
            <img src="@/assets/images/icon_share_blue.svg" width="24" class="mr5">
            {{$t('guardar.visits')}}
        </h4>
        <div class="row form_row">
            <div class="col-lg-6">
                <colorPicker :color="preview.visits.color"  v-model="preview.visits.color" />
            </div>
             <div class="col-lg-6">
                <colorPicker :color="preview.visits.background"  v-model="preview.visits.background" />
            </div>
        </div>

    </div>
    <hr class="separator mb20">
     -->

  
</form>
</template>



<script>
import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import fontPicker from './dinputs/fontPicker'

export default {
  
  
    computed: {
        preview () { 
            let elgua=this.$store.getters.editingLinckard.style.guardar
            if(!elgua.tit)elgua.tit={}
            if(!elgua.sec)elgua.sec={}
            
            return this.$store.getters.editingLinckard.style.guardar},
        state () { return this.$store.getters.editingLinckard.guardar},
    },
 
    components: {
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'fontPicker':fontPicker
        
    }
}


</script>
