<template>
 <div v-if="!loading"  class="main_content ">
    <div class="nav_filter">
            <div class="nav_wrapper d_flex pr-0">
            <ul class="botonera_left ml-0 mr-auto ">
                <li>
                    <button type="button" @click="cerrarPreview()" title="Cerrar" class="buttonCerrar manito">
                        <font-awesome-icon icon="times-circle" style="color:#8C8C8C;"/>
                    </button>
                </li>
                <li>
                    <button type="button" v-if="activeLinckard && isLincFav()" @click='removeLincFav()'  class="firma_btn_fav buttonGuardar"><font-awesome-icon icon="heart" style="color:#F531A6;"/></button>
                    <button type="button" v-else  @click='addLincFav()' class="firma_btn_fav buttonGuardar" ><font-awesome-icon icon="heart"/></button>
                </li>
            </ul>
            <div v-if="$util.isTesting()" class='fguespe d-none d-md-block'>{{dirUser.uid}}</div>
            <div @click="openLinckard()" class="d-none d-md-block ml-auto mr-auto tarjeta_link pt15 pb15" style="cursor:pointer;">
                <font-awesome-icon :icon="['fa','link']"/>  <span style='text-transform:lowercase;'> /{{dirUser.username}}<span v-if="dirLincIndex>0">/{{activeLinckard.id}} </span></span>
            </div>
            <div @click="openLinckard()" class="d-md-none ml-auto mr-auto tarjeta_link pt15 pb15" style="cursor:pointer;">
                <font-awesome-icon :icon="['fa','link']"/>  <span style='text-transform:lowercase;'> {{$t('general.abrir')}}</span>
            </div>
            <ul class="botonera_right ml-auto mr-0">
                <li v-if="laLista.length>1 || dirLinckards.length>1">
                    <button v-if='dirUserIndex > 0 || (dirUserIndex >= 0 && dirLincIndex > 0) ' type="button" @click='prevCard()' title="Anterior" class="buttonPrev"><img src="@/assets/images/icon_prev_circle.svg"></button>
                    <button v-if='dirUserIndex < (laLista.length-1) || (dirUserIndex < laLista.length &&  dirLincIndex < (dirLinckards.length-1)) ' type="button" @click='nextCard()' title="Siguiente" class="buttonNext"><img src="@/assets/images/icon_next_circle.svg"></button>
                </li>
            </ul>
        </div>
    </div>
    <mainPreview :profile="getUser" :key='dirUserIndex+"-"+activeLinckard.id'   :linckard='activeLinckard' :diruser="dirUser" elid="card" class="scolable" style="overflow:visible;" />
</div>
<div v-else-if="loading"  class="wrapper pt20 pb20 pl20 pr20  text-center"><font-awesome-icon icon="circle-notch" style="color:#99287B;font-size:50px;" spin /></div>
</template>



<script>


import mainPreview from "../../main_Preview/main_Preview.vue";
import mPartner from "./miniPartner.vue";
import mDirectory from "./miniDirectory.vue";
import mMulticard from "./miniMulticard.vue";

export default {
    props:['linc','users','user','profile'],
    data() {
        return{
            getUser:this.profile,
            //Nop
            laLista:this.users,
            preDirUser:this.user,
            dirUserIndex:0,
            dirLincIndex:0,
            loading:false,
            cardVal:this.linc
        }
    },
    beforeMount(){
        var dis=this
        if(this.laLista && this.laLista.length>0){
            this.dirUserIndex=this.laLista.findIndex(function(i){return i.uid == dis.preDirUser.uid})
 
        }else {
            this.laLista=[]
            this.laLista.push(this.preDirUser)
            this.dirUserIndex=0
        }
        this.dirLincIndex=this.dirUser.linckards.findIndex(function(i){return i.id == dis.cardVal.id;}) 

    },
    methods:{
        
        cerrarPreview(){
            var dis=this
            let isPartner=this.$router.history.current.name=='partners'
            let isContact=this.$router.history.current.name=='contacts'
            let isDirectory=this.$router.history.current.name=='directory'

            if(isPartner || (isContact && this.$store.state.contactView=='partners')){
                //Se tratan diferente
                
                if(this.dirUser.activeLincs==1){
                   
                    this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser",null)
                    this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'catalogo')
                    
                }else if(!this.users && this.dirUser.isPartner){    //Aca entra si estoy viendo solo la primera de partners            
                    
                    this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'catalogo')
                }else{

                    this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'subdirectorio')
                }
                
            }else if(isContact && this.$store.state.contactView=='directorio'){
                if(this.dirUser.activeLincs==1  ||//unilincjard
                    this.$util.isLincFav(this.getUser,this.dirUser.uid,this.cardVal.id)){//empleado
                    this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser",null);
                    this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'directorio');
                }else{//Subempleado
                    this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'subdirectorio');
                }
                    

            }else if(isDirectory){
                if(this.dirUser.activeLincs==1)
                    this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser",null);

                this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'directorio');
            }
            //else
            
        },

        isLincFav(){
            return this.$util.isLincFav(this.getUser,this.dirUser.uid,this.activeLinckard.id)
        },
        addLincFav(){
            return this.$util.addLincFav(this.$modal,this.getUser,this.dirUser,this.activeLinckard.id)
        },

        removeLincFav(){
            return this.$util.removeLincFav(this.getUser,this.dirUser.uid,this.activeLinckard.id)
        },
        /*
        isFav(){
            return this.$util.isFav(this.getUser,this.dirUser.uid);
        },
        removeFav(){
            return this.$util.removeFav(this.getUser,this.dirUser.uid);
        },
        */
        async nextCard(){
            if( this.dirLincIndex < (this.dirUser.activeLincs-1)){
                this.dirLincIndex++
            }else {
                let checkDirUser=this.laLista[this.dirUserIndex+1]
                if(!checkDirUser.linckards){
                    this.loading=true
                    let newobj=await this.$util.dbgetUser(checkDirUser.uid)
                    newobj.linckards=this.$util.getActiveLinckards(newobj)
                    let index=this.laLista.findIndex(function(i){return i.uid == newobj.uid})
                    this.laLista[index]=newobj
                    this.loading=false
                }

                this.dirUserIndex++
                this.dirLincIndex=0


                this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser", this.dirUser);
                
            }
        },
        async prevCard(){
            if(this.dirLincIndex > 0)
                this.dirLincIndex--
            else {
                let checkDirUser=this.laLista[this.dirUserIndex-1]
                if(!checkDirUser.linckards){
                    this.loading=true
                    let newobj=await this.$util.dbgetUser(checkDirUser.uid)
                    newobj.linckards=this.$util.getActiveLinckards(newobj)
                    let index=this.laLista.findIndex(function(i){return i.uid == newobj.uid})
                    this.laLista[index]=newobj
                    this.loading=false
                }
                this.dirUserIndex--
                this.dirLincIndex=this.dirUser.activeLincs-1

                this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser", this.dirUser);
            }
        },
        getFavLincs(user){
            var favslincs=[];
            var dis=this;
            user.linckards.forEach(function (linc, i) {
                    if(dis.isLincFav(user.uid,i))favslincs.push(linc);
            });
            return favslincs;
        },

        openLinckard() {
            
            window.open('https://linkcard.app/'+this.dirUser.username+'/'+this.activeLinckard.id, '_blank');
        },

        

    },
    computed:{
        dirUser(){
            
            let firstUser=this.laLista[this.dirUserIndex]
            return firstUser
        },
        
        activeLinckard(){
            let lalinc=this.dirUser.linckards[this.dirLincIndex]
            return lalinc
        },
        dirLinckards(){
            let lincs=this.dirUser.linckards
            return lincs
        },
    },
    components:{
        mPartner,mDirectory,mMulticard,mainPreview
    }
}

</script>

<style>

.buttonGuardar svg,
.buttonCerrar svg,
.buttonGuardar img,
.buttonURL svg,
.buttonURL img{
    font-size: 25px !important;
}
</style>
