<template>
    <center>
        <a
            target="_blank"
            :href="'https://calendar.google.com/calendar/render?action=TEMPLATE&text=' + element.title + '&details=' + element.details + '&dates=' + googleDate(element.fromDate) + '/' + googleDate(element.toDate) + '00&location=' + element.location + ''">
            <Icon icon="logos:google-calendar" style="font-size: 48px;" />
        </a>
        &nbsp;
        <a
            :href="'https://outlook.office.com/calendar/0/deeplink/compose?subject=' + element.title + '&body=' + element.details + '&startdt=' + outlookDate(element.fromDate) + '&enddt=' + outlookDate(element.toDate) + '&location=' + element.location + '&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent'">
            <Icon icon="vscode-icons:file-type-outlook" style="font-size: 48px;" />
        </a>
    </center>
</template>
<script>
import { Icon } from "@iconify/vue2"

export default {
    props: ['element'],
    methods:{
		googleDate(val){
            if(val && val.date && val.time){
                return val.date.replace('-','').replace('-','') + 'T' + val.time.replace(':','') + '00'
            }
            return '';
		},
        outlookDate(val){
            if(val && val.date && val.time){
                return val.date + 'T' + val.time + ':00+00'
            }
            return '';
		}
	},
    components: {
        Icon
    },
}
</script>