

let planes_businesscards=[
    {
        name:'Freemium',
        prod_id:'prod_F46r21GmoYrvdV',
        type:"bc",
        max_linkcards:1,
        max_contacts:20,
        img:'01_freemium',
        plans:[
            {
                type:'Monthly',
                plan_id:'freemium_bc',
                price:0,
            }
        ],
        functions:{
            es:[
                'Business Card Builder',
                'Templates prediseñados',
                'Widget para website',
                'Firma digital personalizada',
            ],en:[
                'Business Card Builder',
                'Designer Made Templates',
                'Widget for your website',
                'Email signature'
            ]
        }
    },
    {
        name:'Professional',
        prod_id:'prod_PoJvoyY1bLlxv7',
        type:"bc",
        max_linkcards:10,
        max_contacts:100,
        img:'01_freemium',
        plans:[
            {
                type:'Monthly',
                plan_id:'price_1OyhHmES9JaRReUPtjknLuKg',
                price:20.00,
            },
            {
                type:'Yearly',
                plan_id:'price_1OyhJHES9JaRReUPGNJs6YOG',
                price:180.00,
            },
        ],
        functions:{
            es:[
                'Business Card Builder',
                'Templates prediseñados',
                'Widget para website',
                'Firma digital personalizada',
                'Analíticas'
            ],en:[
                'Business Card Builder',
                'Designer Made Templates',
                'Widget for your website',
                'Email signature',
                'Business Card analytics'
            ]
        }
    },
    {
        name:'Business',
        prod_id:'prod_PoJwRhCLJghaNJ',
        type:"bc",
        max_linkcards:50,
        max_contacts:100,
        img:'02_startup',
        plans:[
            {
                type:'Monthly',
                plan_id:'price_1OyhIEES9JaRReUPZrbkgTzn',
                price:90.00,
            },
            {
                type:'Yearly',
                plan_id:'price_1OyhJYES9JaRReUPnXXpgF6R',
                price:810.00,
            },
        ],
        functions:{
            es:[
                'Business Card Builder',
                'Templates prediseñados',
                'Widget para website',
                'Firma digital personalizada',
                'Analíticas',
                'Carpetas'
            ],en:[
                'Business Card Builder',
                'Designer Made Templates',
                'Widget for your website',
                'Email signature',
                'Business Card analytics',
                'Order by folders',
            ]
        }
    },
    
]

if(process.env.AMBIENTE=='development'){
    //Fremoim
    planes_businesscards[0].prod_id='prod_HL8FLq29c7OM7y'
    //Professional
    planes_businesscards[1].prod_id='prod_HL9KGKLiT1h2wk'
    planes_businesscards[1].plans[0].plan_id='price_HL9KRx90krdF3F'
    planes_businesscards[1].plans[1].plan_id='price_HL9Kd6fhCOSKMp'
    //Business
    planes_businesscards[2].prod_id='prod_HL9QB34RoeCtcZ'
    planes_businesscards[2].plans[0].plan_id='price_HL9QXp4lQkx05f'
    planes_businesscards[2].plans[1].plan_id='price_HL9QuVWdu2dOCi'

}

let planes_loyalty=[
    {
        name:'Freemium',
        prod_id:'prod_Fz9gSntZq7Juz4',
        type:"loyalty",
        max_members:50,
        max_programs:1,
        max_agents:1,
        img:'01_freemium',
        plans:[
            {
                type:'Monthly',
                plan_id:'freemium_loyalty',
                price:0,
            },
        ],
        functions:{
            es:[
                'Diseño editable',
                'Multi plantillas*',
                /*'Hasta 1 programa',*/
                'Hasta 5 sucursales/programa',
                'Hasta 10 miembros activos',
                'Analítica',
                'Notificaciones Push',
            ],en:[
                'Custom design',
                'Muli-templates*',
                /*'Up to 1 programs',*/
                'Up to 5 locations/program',
                'Up to 01 active members',
                'Analytics',
                'Push Notifications',
            ]
        }
        
    },
    {
        name:'Micro',
        prod_id:'prod_GIiQ7vNeWruPdm',
        type:"loyalty",
        max_members:500,
        max_programs:1,
        max_agents:1,
        img:'01_freemium',
        plans:[
            {
                type:'Monthly',
                plan_id:'plan_GIiQG77nc2W1gr',
                price:34.99,
            },
            {
                type:'Yearly',
                plan_id:'plan_GIiQzv91KMFoc7',
                price:Math.floor(34.99*12*0.75),
            },
        ],
        functions:{
            es:[
                'Diseño editable',
                'Multi plantillas*',
                /*'1 programa',*/
                '1 sucursal/programa',
                'Hasta 500 miembros activos',
            ],en:[
                'Custom design',
                'Muli-templates*',
               /* '1 program',*/
                '1 location/program',
                'Up to 500 active members',
            ]
        }
        
    },
    {
        name:'Small',
        prod_id:'prod_FwGOHohlnPvVdb',
        type:"loyalty",
        max_members:1000,
        max_programs:3,
        max_agents:5,
        img:'02_startup',
        plans:[
            {
                type:'Monthly',
                plan_id:'plan_GIiBYEwloUN5Fh',
                price:49.99,
            },
            {
                type:'Yearly',
                plan_id:'plan_GIiDvOy0gSrj14',
                price:Math.floor(49.99*12*0.75),
            },
        ],
        functions:{
            es:[
                'Diseño editable',
                'Multi plantillas*',
               /* 'Hasta 3 programa',*/
                'Hasta 5 sucursales/programa',
                'Hasta 1000 miembros activos',
                'Analítica',
                'Notificaciones Push',
            ],en:[
                'Custom design',
                'Muli-templates*',
               /* 'Up to 3 programs',*/
                'Up to 5 locations/program',
                'Up to 1000 active members',
                'Analytics',
                'Push Notifications',
            ]
        }
    },
    {
        name:'Medium',
        prod_id:'prod_FwGQlWefa5MBuT',
        type:"loyalty",
        max_members:3000,
        max_programs:10,
        max_agents:15,
        img:'03_business',
        plans:[
            {
                type:'Monthly',
                plan_id:'plan_GIiKg0NHIMNHee',
                price:99.99,
            },
            {
                type:'Yearly',
                plan_id:'plan_GIiKFvednmYNYN',
                price:Math.floor(99.99*12*0.75),
            },
        ],
        functions:{
            es:[
                'Diseño editable',
                'Multi plantillas*',
                /*'Hasta 10 programa',*/
                'Hasta 15 sucursales/programa',
                'Hasta 3000 miembros activos',
                'Analítica',
                'Notificaciones Push',
                'Campos personalizados',
            ],en:[
                'Custom design',
                'Muli-templates*',
                /*'Up to 10 programs',*/
                'Up to 15 locations/program',
                'Up to 3000 active members',
                'Analytics',
                'Push Notifications',
                'Custom fields',

                        
            ]
        }
    },
    {
        name:'Large',
        prod_id:'prod_FwGWPeATVpWzAf',
        type:"loyalty",
        max_members:5000,
        max_programs:20,
        max_agents:30,
        img:'04_company',
        plans:[
            {
                type:'Monthly',
                plan_id:'plan_GIiNcqtjp8DJYu',
                price:149.99,
            },
            {
                type:'Yearly',
                plan_id:'plan_GIiNCj8FqQ5EKa',
                price:Math.floor(149.99*12*0.75),
            },
        ],
        functions:{
            es:[
                'Diseño editable',
                'Multi plantillas*',
               /* 'Hasta 20 programa',*/
                'Hasta 30 sucursales/programa',
                'Hasta 5000 miembros activos',
                'Analítica',
                'Notificaciones Push',
                'Campos personalizados',
            ],
            en:[
                'Custom design',
                'Muli-templates*',
                /*'Up to 20 programs',*/
                'Up to 30 locations/program',
                'Up to 5000 active members',
                'Analytics',
                'Push Notifications',
                'Custom fields',
                
            ]
        }
        
    }
    
]

if(process.env.AMBIENTE=='development'){
    planes_loyalty[0].prod_id='prod_HLjTIukUE9cJSt'
    planes_loyalty[1].prod_id='prod_GXIkYztk9L3BQ1'
    planes_loyalty[2].prod_id='prod_FysdENI6dxAJqZ'
    planes_loyalty[3].prod_id='prod_FyshsartMal4ZI'
    planes_loyalty[4].prod_id='prod_Fysi7amLfDbplf'
}


//module.exports = {
export {
    planes_businesscards,
    planes_loyalty
}