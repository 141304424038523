<template>
<div class="tarjeta_edit_wrapper" style="min-height: 645px;">

<div v-if="preview.style.sobre.fondo.active && preview.style.sobre.fondo.fullwidth" class="krolhj" :style="{'background-image':'url('+$util.getSobreBackImage(preview,null)}"></div>
<div v-else-if="preview.style.sobre.fondo.active && !preview.style.sobre.fondo.fullwidth" class="tarjeta_bg animated fadeIn">
  <div  class="tarjeta_bg_container" >
      <div class="tarjeta_bg_logo" style="position:relative;" :style="{'animation-name':preview.style.sobre.fondo.animacion?'spin':'none'}">
          <img :src='$util.getSobreBackImage(preview,null)' width="100%" id="tarjetaBgLogo">
      </div>
  </div>
</div>
<previewDrag :profile="getUser" :preview='preview' :drags="sobredrags"  />
</div>
</template>

<script>
import previewDrag from '@/layouts/main_Preview/previewSobreDrag'
export default {
    props:['preview','profile','drags'],
    data() {
        return {
            getUser:this.profile,
            sobredrags:this.drags
        }
    },
    components:{
      previewDrag
    }
};
</script>

<style>



#card .tarjeta_view_block {
  /*fix width en mobile sobre directorio*/
  width: 100%;
  max-width: 336px;
}


/*magen de fonto*/
.laul{
  height:100px !important;
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-size: contain !important; /* Resize the background image to cover the entire container */
}
/*el fix del repetir del fondo*/
.list-group:not(.laul){
  background-image:none !important;
}



/*css de la lista de la izq* al arrastrar*/
.tarjeta_section.editar  .module_block{
  width:100% !important;
  border:1px solid grey;
  padding:20px !important;
}


.edit_content p{
  /*un margin que viene con el p*/
  margin-bottom:0px !important;
  margin-top:0px !important;
  padding: 0px;
}

.edit_content .emptyspace{
border:1px solid #C9C9C9 !important;
}


#edicionMiddle #tarjetaMuestra #tarjetaCoantainer{
  /*Esto fixea todo*/
  width:inherit !important;
}




.previewSobre .tarjeta_bg{
    background-color: var(--sobre-fondo-background) !important;
}
.previewSobre .tarjeta_bg .tarjeta_bg_logo{
    opacity: var(--sobre-fondo-opacity) !important;
    width: var(--sobre-fondo-width) !important;
    left:var(--sobre-fondo-left) !important;
    top:var(--sobre-fondo-top) !important;
}
</style>
