
<template>
  <modal name="modal-transactions"
        transition="nice-modal-fade"
        classes="ngdialog-content"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen">
    <div class="modal_wrapper">
        <div slot="top-right">
            <button @click="$modal.hide('modal-transactions')" class="ngdialog-close"></button>
        </div>
        <h2 class="mt10 mb20"><b>{{$t('loyaltys.history')}}</b></h2>
        <div v-if="loading"  class="wrapper pt20 pb20 pl20 pr20  text-center"><font-awesome-icon icon="circle-notch" style="color:#99287B;font-size:50px;" spin /></div>
        <div class="modalSusc">
           <pre v-for="(line,index) in log"  :key='index' >{{line}}</pre>
        </div>
        <a v-if="log.length>0 && log.length==cant" @click="refineNext()">{{$t('loyaltys.showmore')}}</a>
    </div>
</modal>
</template>

<script>
import {functions } from '@/services/firebaseConfig.js';

export default {
    data () {
        return {
            log:[],
            trans:[],
            getUser:this.$store.state.userProfile,
            cant:10,
            loading:false,
            member:''
        }
    },
    methods: {
        beforeOpen (event) {
            var dis=this
            dis.log=[]
            dis.loading=true
            dis.member=event.params.user; 
            const loyalty_member_transactions = functions.httpsCallable('loyalty_member_transactions');
            loyalty_member_transactions({uid_from:dis.member.loyalty_from,uid:dis.member.uid,loyalty_id:dis.member.loyalty_id,cant:dis.cant})
            .then(async result => {
                dis.log=[]
                let arr=result.data
                for(let i in arr){
                    let obj=arr[i]
                    dis.trans.push(obj)
                    dis.logtrans(obj)
                }
                dis.loading=false
                if(dis.log.length==0)dis.log.push('Todavia no se registraron transacciones')

            }).catch(e=>{
                console.log(e)
            });
        },
        logtrans(obj){
            var dis=this
            let puntos=obj.credits-obj.old
            let label=this.$t('loyaltys.sumaron')
            if(puntos<0){
                label=this.$t('loyaltys.restaron')
                puntos=puntos*-1
            }
            dis.log.push(puntos+' '+label+dis.$util.getDateAS(dis.getUser,obj['date']))
        },
        refineNext () {
            var dis=this
            let start={id:dis.trans[dis.trans.length-1].id}
            const loyalty_member_transactions = functions.httpsCallable('loyalty_member_transactions');
            loyalty_member_transactions({uid_from:dis.member.loyalty_from,uid:dis.member.uid,loyalty_id:dis.member.loyalty_id,cant:dis.cant,start:start})
            .then(async result => {
                let arr=result.data
                for(let i in arr){
                    let obj=arr[i]
                    dis.trans.push(obj)
                    dis.logtrans(obj)
                }

            }).catch(e=>{
                console.log(e)
            });
        },
    }
}
</script>
<style>


</style>


