<template>
<form>
 
    <div class="edicion_block">
        <h4 class="mb10">{{$t('comparte.comparte')}}</h4>
        <span class="info_text">
            {{$t('comparte.elije')}}
        </span>


        <ul class="list_check mt20 mb10">
   
            <li>
                <span class="label_wicon">
                    <font-awesome-icon :icon="['fab','whatsapp']"/>
                    <b>{{$t('comparte.por')}} Whatsapp</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.whatsapp'>
                    <span class="slider round"></span>
                </label>

            </li>

            <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  icon="envelope"/>
                    <b>{{$t('comparte.por')}} Email</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.email'>
                    <span class="slider round"></span>
                </label>

            </li>

            <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','weixin']"/>
                    <b>{{$t('comparte.por')}} WeChat</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.wechat'>
                    <span class="slider round"></span>
                </label>

            </li>
            <li>
                <span class="label_wicon">
                    
                    <font-awesome-icon color='#8C8C8C' icon="comment"/>
                    <b>{{$t('comparte.por')}} SMS</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.sms'>
                    <span class="slider round"></span>
                </label>

            </li>

           
            <li>
                <span class="label_wicon">
                    
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','facebook-square']"/>
                    <b>{{$t('comparte.por')}} Facebook</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.facebook'>
                    <span class="slider round"></span>
                </label>

            </li>

            <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','linkedin']"/>
                    <b>{{$t('comparte.por')}} LinkedIn</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.linkedin'>
                    <span class="slider round"></span>
                </label>

            </li>
             <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','skype']"/>
                    
                    <b>{{$t('comparte.por')}} Skype</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.skype'>
                    <span class="slider round"></span>
                </label>

            </li>
             <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','reddit']"/>
                    
                    <b>{{$t('comparte.por')}} Reddit</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.reddit'>
                    <span class="slider round"></span>
                </label>

            </li>
            <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','twitter']"/>
                    
                    <b>{{$t('comparte.por')}} Twitter</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.twitter'>
                    <span class="slider round"></span>
                </label>

            </li>
             <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','pinterest']"/>
                    
                    <b>{{$t('comparte.por')}} Pinterest</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.pinterest'>
                    <span class="slider round"></span>
                </label>

            </li>
             <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','line']"/>
                    
                    <b>{{$t('comparte.por')}} Line</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.line'>
                    <span class="slider round"></span>
                </label>

            </li>
             <li>
                <span class="label_wicon">
                    <font-awesome-icon color='#8C8C8C'  :icon="['fab','telegram']"/>
                    
                    <b>{{$t('comparte.por')}} Telegram</b>
                </span>

                <label class="switch">
                    <input type="checkbox" v-model='preview.telegram'>
                    <span class="slider round"></span>
                </label>

            </li>

           

            


        </ul>

    </div>

    <!--<hr class="separator">

    
    -->

</form>
</template>
<script>


export default {
    computed: {
        preview () { return this.$store.getters.editingLinckard.compartir},
    },
}
</script>

<style>
#edicionLeft .list_check > li .label_wicon svg {
    margin-right: 5px;
    width: px;
    font-size: 20px;
    vertical-align: middle;
    border-style: none;
    color:'#8C8C8C' !important;
}
#edicionLeft .list_check>li .label_wicon b {
   font-weight: 400;
   display: inline-block;
   padding: 5px 0px 0px 10px;
}

</style>
