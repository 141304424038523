

  //module.exports = {
  export default {
      isImgUploaded:function(evaluando){
        if(!evaluando)return false;
        if(evaluando.obj)return true
        else if(evaluando &&  typeof evaluando==='string')return evaluando.indexOf("data:") >=0
        return false
      },  
      getImage:function(user){
        if(user && user.account_details && user.account_details.account_picture && user.account_details.account_picture.length>10)return user.account_details.account_picture
        else if(user && user.linckards && user.linckards.length>0 && user.linckards[0].info.img !== null && (this.isImgUploaded(user.linckards[0].info.img) || (user.linckards[0].info.img && user.linckards[0].info.img.length>0)  )){
          return user.linckards[0].info.img;
        }else{
          return null
        }
      },
      getDate:function(){
        return new Date().toISOString()
      },
      getCountryCode:function(account_country,regions){
        return regions.find(x => x.label == account_country).countryShortCode
      },
      getCountryPhoneCode:function(country_code,phonecodes){
        return phonecodes.find(x => x.code == country_code).dial_code
      },
      isValidDate:function(d){
        if (Object.prototype.toString.call(d) === "[object Date]") {
          if (isNaN(d.getTime())) {  // d.valueOf() could also work
             return false
          } else {
            return true
          }
        } else {
          return false
        }
      },
  
      parseDate:function(profile,datestring,overridedate=false){
        if(!datestring)return ''
        datestring=datestring.replace("-","/")
        datestring=datestring.split("/")
  
        let vieneenelformato='le'
        if(overridedate)vieneenelformato=overridedate
        else if(profile)vieneenelformato=profile.account_details.account_date_format?profile.account_details.account_date_format:vieneenelformato
        
        if(vieneenelformato=='le'){//dmy
          datestring=datestring[2]+'/'+datestring[1]+'/'+datestring[0]
        }else if(vieneenelformato=='me'){//d/m/y
          datestring=datestring[2]+'/'+datestring[0]+'/'+datestring[1]
        }
        
        let fechafinal=new Date(datestring)
        //console.log("Sale: ",twhis.isValidDate(fechafinal))
        if(this.isValidDate(fechafinal))return fechafinal.toISOString()
        else return 'date_error'
      },
      getDateAS:function(profile,date){
        if(!date || !(typeof date === 'string') )return ''
        let mostrarcomo='le'
        if(date.includes("yyyy"))return date.replace('yyyy/','')
        if(profile)mostrarcomo=profile.account_details.account_date_format?profile.account_details.account_date_format:mostrarcomo
        //Parseo para los le
        if(!this.isValidDate(new Date(date)) && mostrarcomo=='le')date=this.parseDate(profile,date)
        
        date = new Date(date)
        if(mostrarcomo=='le'){
          var dd = String(date.getDate()).padStart(2, '0');
          var mm = String(date.getMonth()+1).padStart(2, '0'); //January is 0!
          var yyyy = date.getFullYear();
          var date=dd + '/' + mm + '/' + yyyy;
        }else if(mostrarcomo=='be'){
  
          var dd = String(date.getDate()).padStart(2, '0');
          var mm = String(date.getMonth()+1).padStart(2, '0'); //January is 0!
          var yyyy = date.getFullYear();
          var date= yyyy + '/' + mm + '/' + dd;
        }else if(mostrarcomo=='me'){
  
          var dd = String(date.getDate()).padStart(2, '0');
          var mm = String(date.getMonth()+1).padStart(2, '0'); //January is 0!
          var yyyy = date.getFullYear();
          var date= mm + '/' + dd + '/' + yyyy;
        }
        return date;
      },
  
      linckardObj:function(linc){
            return {
                id:linc.id,
                info:{
                  nombre:linc.info.nombre,
                  trabajo:linc.info.trabajo,
                  img:linc.info.img
                }
            }
      },
      diffDays(date_to_diff){
        var today = new Date();
        let date2 = new Date(date_to_diff);
        var timeDiff = Math.abs(date2.getTime() - today.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        return diffDays
      },
      isMemberActive(last_transaction,month){
        if(!last_transaction)return false//los nuevos no entran porq pueeden venir de migraciones etc
        return this.diffDays(last_transaction)<=30*month
      },
      getActiveMembers:function(members){
        return members.filter( u=> {
          u=u.membre?u.membre:u
          return this.isMemberActive(u.last_transaction,6)
        })
      },
      getKeywords:function(nombre, username , number,email,dni=false){
        let keywords=[]
        if(username)username=username.toLowerCase()
        if(username)keywords.push(username)
        if(email)keywords.push(email)
        if(number)keywords.push(number)
        if(dni)keywords.push(dni)
        if(nombre)nombre=this.normalizarBusqueda(nombre)
        if(nombre)keywords.push(nombre)
        let explode=nombre.split(" ")
        for(let i in explode)keywords.push(this.normalizarBusqueda(explode[i]))
        return keywords
      },
  
      
      guestObj:function(profile,linkcard){
        let keywords=this.getKeywords(linkcard.info.nombre,profile.username,null,profile.email)
        let mobilephone=linkcard.info.mobilephone_code?linkcard.info.mobilephone_code+' ':''
        mobilephone=mobilephone+' '+linkcard.info.mobilephone?linkcard.info.mobilephone:''
        
        return {
          uid:profile.uid,
          status:"created",
          keywords:keywords,
          username:profile.username,
          email:profile.email,
          event_id:linkcard.event_id,
          event_from:linkcard.event_from,
          linkcard_id:linkcard.id,
          nombre:linkcard.info.nombre,
          company:linkcard.info.company,
          trabajo:linkcard.info.trabajo,
          img:linkcard.info.img?linkcard.info.img:'',
          deleted:false,
          asists:false,
          mobilephone:mobilephone,
          date:this.getDate(),
          date_time:new Date().getTime(),
          status:linkcard.status
        }
      },
      memberObj:function(profile,linkcard){
        let number=linkcard.membre.number?linkcard.membre.number:0
        let keywords=this.getKeywords(linkcard.membre.nombre,profile.username,number,profile.email,linkcard.membre.dni)
        let mobilephone=linkcard.membre.mobilephone_code?linkcard.membre.mobilephone_code+' ':''
        mobilephone=mobilephone+' '+linkcard.membre.mobilephone?linkcard.membre.mobilephone:''
        
        let last_transaction_time=linkcard.membre.last_transaction?new Date(linkcard.membre.last_transaction).getTime():''
        if(isNaN(last_transaction_time) && linkcard.membre.last_transaction){
          let trans=this.parseDate(null,linkcard.membre.last_transaction,'le')
          last_transaction_time=new Date(trans).getTime()
        }
  
        return {
          uid:profile.uid,
          status:"created",
          keywords:keywords,
          username:profile.username,
          email:profile.email,
          loyalty_id:linkcard.loyalty_id,
          loyalty_from:linkcard.loyalty_from,
          linkcard_id:linkcard.id,
          nombre:linkcard.membre.nombre,
          img:linkcard.membre.img?linkcard.membre.img:linkcard.info.img, 
          last_transaction:linkcard.membre.last_transaction?linkcard.membre.last_transaction:'',
          last_transaction_time:last_transaction_time,
          number:number,
          deleted:false,
          /*birthday:linkcard.membre.birthday,
          dni:linkcard.membre.dni,
          mobilephone:mobilephone,*/
          credits:linkcard.membre.credits,
          date:this.getDate(),
          date_time:new Date().getTime(),
          status:linkcard.status
        }
      },
      normalizarBusqueda:function(text){
        if(typeof text != 'string')return text
        text=text.toLowerCase()
        text=text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        return String(text)
      },
      contactObj:function(contact,linc_id=0){
        let image=this.getImage(contact)
        let isPartner=contact.isPartner?true:false
        let obj= {
          uid:contact.uid,
          type:contact.type,
          account_details:{
            account_name:contact.account_details.account_name,
            account_subtitle:contact.account_details.account_subtitle,
            account_picture:image
          },
          
          isPartner:isPartner
          
        }
        if(contact.linckards){//Si ya tiene linkcards, osea no es un gran dir que no les guarde ni una
          let lincs=JSON.parse(JSON.stringify(contact.linckards));
          let index=lincs.findIndex(function(i){return i.id === linc_id;}) 
          let lincobj=this.linckardObj(lincs[index])
          delete obj.isPartner
          obj.linckards=[]
          obj.linckards.push(lincobj)
        }
  
        return obj
      },
      hasOnePremiumPlan(profile,type){
        if(!profile || !profile.stripe_details || !profile.stripe_details.stripe_subscriptions)return false
        let elresu= profile.stripe_details.stripe_subscriptions.find(e=>{return e.plan_id=='freemium_'+type})
        return elresu==undefined
      },
      directoryOK(getUser){
        return true
        //return getUser.stats.cant_linkcards_active>=1 && !getUser.isHide && (getUser.isPro || this.isManualPro(getUser) || this.hasOnePremiumPlan(getUser,'bc') )
      },
      updateAlgolia:async function(alg_index,getUser){
        try{
          if(!this.directoryOK(getUser)) return alg_index.deleteObject(getUser.uid);
          console.log('Update algolia: '+getUser.uid)
          let uid=getUser.uid
          getUser.linckards= this.getActiveLinckards(getUser)
          getUser.stats.cant_linkcards_active=getUser.linckards.length
          if(getUser.stats.cant_linkcards_active==0 || getUser.isHide){
            alg_index.deleteObject(uid); 
            return;
          }
          getUser.objectID=uid
          getUser.account_details.account_picture=this.getImage(getUser)
          getUser.lincsearch=[]
          getUser.tags=[]
          getUser.linckards.forEach(linc => {
              if(linc.directory==undefined)linc.directory=true
              if(linc && linc.info && linc.info.nombre && linc.directory){
                  let nombre=linc.info.nombre
                  if(!getUser.lincsearch.includes(nombre))getUser.lincsearch.push(nombre)
                  if(linc.info.tags){
                    linc.info.tags.forEach(tag => {getUser.tags.push(tag.value)})
                  }
              }
          });
          if(getUser.linckards.length>=2)delete getUser.linckards
          else{//Guardo el obj para una unica linckard
            let newobj=this.linckardObj(getUser.linckards[0])
            delete getUser.linckards
            getUser.linckards=[]
            getUser.linckards.push(newobj)
          }
          
          let algoliaUser={
            objectID:getUser.objectID,
            lincsearch:getUser.lincsearch,
            uid:getUser.uid,
            username:getUser.username,
            tags:getUser.tags,
            email:getUser.email,
            account_details:{
              account_category:getUser.account_details.account_category,
              account_country:getUser.account_details.account_country,
              account_name:getUser.account_details.account_name,
              account_picture:getUser.account_details.account_picture,
              account_state:getUser.account_details.account_state,
              account_subtitle:getUser.account_details.account_subtitle
            },
            stats:{
              cant_linkcards_active:getUser.stats.cant_linkcards_active,
            },
            isPartner:getUser.isPartner?true:false,
            linckards:getUser.linckards,
          }
          await alg_index.saveObject({uid,...algoliaUser});
            
        }catch(e){
          console.log(e)
        }
      },
      randomchar:function(){
        return Math.random().toString(36).slice(-8);
      },
      removeToken:function(img,size=''){
        //let cortada=img.substring(0, img.indexOf("&token="))
        if(!img || typeof img != 'string')return
        let cortada=img.split('&token=')[0]
        if(size)cortada=cortada.replace('?alt=media','_'+size+'?alt=media')
        return cortada;
      },
      getActiveLandings:function(user){
        if(!user || !user.linckards)return []
        var lincs=user.linckards;
        lincs= lincs.filter(u=>{
            let lincOK=/*u.guardar.directory && */u.status==='active'/* && u.info.img!=null */&& !(u.event_id>=0 ||u.loyalty_id>=0) && u.isLanding
            
            if(user.sectors && user.sectors.length>0){
              let index = user.sectors.findIndex(function(i){
                return i.id == u.sector_id
              })
              let sector = user.sectors[index]
              lincOK=lincOK && sector && sector.status==='active';
            }
            return lincOK;
            
        });
        return lincs;
      },
      getActiveLinckards:function(user){
        if(!user || !user.linckards)return []
        var lincs=user.linckards;
        lincs= lincs.filter(u=>{
            let lincOK=/*u.guardar.directory && */u.status==='active'/* && u.info.img!=null */&& !(u.event_id>=0 ||u.loyalty_id>=0) && !u.isLanding
            
            if(user.sectors && user.sectors.length>0){
              let index = user.sectors.findIndex(function(i){
                return i.id == u.sector_id
              })
              let sector = user.sectors[index]
              lincOK=lincOK && sector && sector.status==='active';
            }
            return lincOK;
            
        });
        return lincs;
      }
    }