<template>
<div v-if="!loading" class="firma_wrapper contacto">
    <div class="firma_container">
        <div class="contacto_wrapper">
            <div class="firma_avatar_wrapper">
                <div class="firma_avatar">
                    <a href="#">
                        <img :src="getImage()" width="100%">
                    </a>
                </div>
            </div>
            <div v-if="cardVal && cardVal.info" class="firma_user_info">
                <h6 v-if="cardVal.info.nombre.length>0" class="firma_user_name">{{cardVal.info.nombre}}</h6>
                <h6 v-else class="firma_user_name">{{$t('info.nombrecc')}}</h6>
                <span v-if="cardVal.info.trabajo.length>0" class="firma_user_prof">{{cardVal.info.trabajo}}</span>
                <span v-else class="firma_user_prof">{{$t('info.profes')}}</span>
                <button type="button" @click="openLinckcardPreview()"  class="firma_url">{{$t('general.ver')}} LINKCARD</button>
            </div>
            <div class="firma_buttons card_share_dropdown ocultar">
                <button type="button"  @click="$util.showPopup(dirUser.uid)"  class="firma_btn_msj firma_btn_top"><font-awesome-icon icon="paper-plane" /></button>
                <button type="button"  style="left:-40px;" @click="modalMsg(dirUser.uid)"  class="firma_btn_msj firma_btn_middle"><font-awesome-icon icon="envelope" /></button>
                <button type="button" v-if="isLincFav()" @click='removeLincFav()'  class="firma_btn_fav firma_btn_bottom"><font-awesome-icon icon="heart" style="color:#F531A6;"/></button>
                <button type="button" v-else class="firma_btn_fav firma_btn_bottom"  @click='addLincFav()' ><font-awesome-icon icon="heart" /></button>  
            </div>
        </div>
    </div>
</div>
<div v-else-if="loading"  class="firma_wrapper">
    <font-awesome-icon icon="circle-notch" style="color:#99287B;" spin />
</div>

</template>

<script>





export default {
    props: ['linc','user','users'],
	data() {
		return { 
            dirUser: this.user,
            getUser:this.$store.state.userProfile,
            cardVal: this.linc,
            loading:false,
            laLista:this.users,
        }
    },
    methods:{
        modalMsg(){
            let user_to={
                img:this.getImage(),
                uid:this.dirUser.uid,
                email:this.dirUser.email,
                username:this.dirUser.username,
                linkcard_id:this.cardVal.id,
                title:this.cardVal.info.nombre,
                subtitle:this.cardVal.info.trabajo,
                locale:this.dirUser.account_details.account_locale?this.dirUser.account_details.account_locale:'es'
            }
            this.$modal.show('modal-msg',{user_to:user_to})
        },
        getImage(){
            var img=null
            if(this.cardVal && this.cardVal.info)img=this.cardVal.info.img;
            if(img==null)return  require("@/assets/images/profile_nophoto_color.png");
            else if(img && img.obj)return img.obj;
            else if(img)return this.$util.removeToken(img)
        },
        async openLinckcardPreview() {
            var dis=this;
            var dis=this
            let loadUser=JSON.parse(JSON.stringify(this.dirUser));
            if(this.$util.isSubLinckard(loadUser)){
                loadUser=await dis.$util.dbgetUser(loadUser.uid)
                loadUser.linckards=this.$util.getActiveLinckards(loadUser)
                let index=this.laLista.findIndex(function(i){return i.uid == loadUser.uid})
                this.laLista[index]=loadUser
            }
            
            
            window.open('https://m.pge.link/'+loadUser.username+'/'+dis.cardVal.id, '_blank');

            
        },
        isLincFav(){
            if(this.dirUser && this.cardVal)return this.$util.isLincFav(this.getUser,this.dirUser.uid,this.cardVal.id);
        },
        addLincFav(){
            this.$util.addLincFav(this.$modal,this.getUser,this.dirUser,this.cardVal.id);
        },
        removeLincFav(){
            this.$util.removeLincFav(this.getUser,this.dirUser.uid,this.cardVal.id);
        },
        
      
    },
  
}

</script>


<style >

.card_share_dropdown.ocultar .send{
    display:none !important;
}

</style>
