
<template>
<client-only>
  <modal name="modal-signup"
        transition="nice-modal-fade"
        :clickToClose="false"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="500"
        height="auto"
        @before-open="beforeOpen">
    <div class="modal_wrapper " >
        <form   class="card " novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h3 class="text-center">{{$t('company.tellus')}}</h3>
                <p class="text-center">{{$t('company.tellussub')}}</p>
                <div class="form-group ">
                    <label>{{$t('company.name')}}</label>
                    <input type="text" v-model="getUser.account_details.company_name"  name="name" v-validate="{ required: true}"  :class="{ 'is-invalid': errors.has('name') }" class="form-control dark" >
                    <div class="vuerror" v-if="errors.has('name')" >{{ errors.first('name') }}</div>
                </div>
                <div class="form-group ">
                    <label>{{$t('company.which')}}</label>
                    <input type="text" v-model="getUser.account_details.company_position"  name="name" v-validate="{ required: true}"  :class="{ 'is-invalid': errors.has('name') }" class="form-control dark" >
                    <div class="vuerror" v-if="errors.has('name')" >{{ errors.first('name') }}</div>
                </div>
                <div class="form-group ">
                    <label>{{$t('company.size')}}</label>
                    <multiselect 
                        :allow-empty="false"
                        class=""
                        name="size"
                        v-validate="{ required: true}"
                        v-model="getUser.account_details.company_size"
                        :options="employees" 
                        :placeholder="$t('general.select2')"
                        deselectLabel=""
                        selectLabel=""/>
                    <div class="vuerror" v-if="errors.has('size')" >{{ errors.first('size') }}</div>
                </div> 
                <div class="form-group">
                    <label>{{$t('info.categoria')}}</label>
                    <multiselect 
                    :allow-empty="false"
                    v-model="cat"
                    :options="sectors[$store.state.locale].map(type => type.id)" 
                    :custom-label="opt => sectors[$store.state.locale].find(x => x.id == opt).label"              
                    :placeholder="$t('general.select2')"
                    deselectLabel=""
                    selectLabel=""/>
                </div>
                <div class="form-group">
                    <label>{{$t('info.pais')}}</label>
                    <multiselect 
                        :allow-empty="false"
                        class=""
                        name="pais"
                        v-validate="{ required: true}"
                        v-model="country"
                        :options="regions.map(type => type.label)" 
                        :placeholder="$t('general.select2')"
                        deselectLabel=""
                        selectLabel=""/>
                    <div class="vuerror" v-if="errors.has('pais')" >{{ errors.first('pais') }}</div>
                </div>
                <div class="form-group ">
                    <label>{{$t('info.prov')}}</label>
                    <multiselect 
                        :allow-empty="false"
                        class=""
                        name="prov"
                        v-validate="{ required: true}"
                        v-model="state"
                        :options="regionstates()"
                        :placeholder="$t('general.select2')"
                        deselectLabel=""
                        selectLabel=""/>
                    <div class="vuerror" v-if="errors.has('prov')" >{{ errors.first('prov') }}</div>
                </div>
                <div class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100"  type="submit"  :is-loading="loading" :disabled="loading" :status="status"> {{$t('general.guardar')}} </button-spinner>
                </div>
            </div>
        </form>
    </div>
  </modal>
</client-only>
</template>
<script>
import { Validator } from "vee-validate";
import {functions,db } from '@/services/firebaseConfig.js';
import {regions} from '@/mixins/regions.js'
import { planes_businesscards} from '@/mixins/stripe.js'
import {sectors} from '@/mixins/categories.js'
import axios from 'axios';


export default {
    data () {
      return {
        getUser:this.$store.state.userProfile,
        loading:false,
        status:'',
        regions:regions,
        sectors:sectors,
        employees:['1','2-10','11-40','41-100','100-250','250-500','>500'],
        cat:(typeof this.$store.state.userProfile.account_details.account_category ==='string')?'':this.$store.state.userProfile.account_details.account_category,
        country:this.$store.state.userProfile.account_details.account_country,
        state:this.$store.state.userProfile.account_details.account_state,
      }
    },
    watch:{
        country(val){
            this.getUser.account_details.account_country=val;
            this.state=''
        },
        state(val){
            this.getUser.account_details.account_state=val
        },
        cat(val){
            this.getUser.account_details.account_category=val
        }
    },
    methods: {
        beforeOpen (event) {   
            var dis=this
        },
        
        async save(){
            var dis=this
            //if(ga && this.getUser.linckards.length==1)
            await this.sendRoniEmail();

            await db.collection('users').doc(this.getUser.uid).update({account_details:this.getUser.account_details})
            dis.loading = false
            dis.$modal.hide('modal-signup') 
        },

        async sendRoniEmail(){
            if(!['11-40','41-100','100-250','250-500','>500'].includes(this.getUser.account_details.company_size))return
            let templates={
                bc:{
                    es:'8ab0ef22fe314414be7fec0f777dc50d',
                    en:'a83f71c902ce4d4a832a77d5170f30cd'
                }
            }

            let dynamic_template_data={
                name:this.getUser.account_details.account_name,
                locale:this.getUser.account_details.account_locale,
                email:this.getUser.email
            }

            functions.httpsCallable('send_mail_sendgrid')({"subtype":"pipeline_init","which":"bc","templateId":templates[this.$util.getMode()][this.$store.state.locale],"dynamic_template_data":dynamic_template_data,"email":false,"bcc":"roni@linkcard.app"})
            .then(function(result) {
                console.log('mail enviado')
            }).catch(e => {
                console.log(e)
                return null
            })

        },
        async savePipedrive(){
            let dis=this

            if(this.$util.isFounder())return
            if(!['11-40','41-100','100-250','250-500','>500'].includes(this.getUser.account_details.company_size))return
           
            let size=this.getUser.account_details.company_size//.replace('-','.')
            console.log("Es enterprise",size)

            let api_token = '60791cb02d8ca4f80c04a6ca05c84a584bf3ce85';
            let company_domain = 'linkcard';
            

            let locale=this.getUser.account_details.account_locale
            let org_id=null
            let user_id=null

            let cate=this.sectors[this.$store.state.locale].find(x => x.id == this.cat).label
            console.log('cate',cate)
            let url = 'https://' + company_domain + '.pipedrive.com/api/v1/organizations?api_token=' + api_token;
            let data= {
                'name':this.getUser.account_details.company_name,
                '19906681d9beeaffdf27437a7cab02383023c91e':size,
                '172059150eb95fb44145fffb226f8687764043cb':cate,
                '01b76f2da315ed8d2d5371943b7f66f00c9c72a9':this.getUser.account_details.account_country,
                '792254cdb6c27387d02c7c6fe1a8f92b5081ca79':this.getUser.account_details.account_state,
                'b227b48aed904cf3b979be0297b30b40aec716f0':locale
            }
            await axios({
                method: 'post',
                url: url,
                headers: { 'Content-Type': 'application/json' },
                data:data
            }).then(response => {
                console.log(response.data)
                console.log('Empresa creada: '+response.data.data.id)
                org_id=response.data.data.id
                
            }).catch(e => {
                console.log(e)
                return null
            })


            url = 'https://' + company_domain + '.pipedrive.com/api/v1/persons?api_token=' + api_token;
            data= {
                'name':this.getUser.account_details.account_name,
                'email':this.getUser.email,
                'org_id':org_id
            }
            await axios({
                method: 'post',
                url: url,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then(response => {
                console.log('Contacto creado: '+response.data.data.id)
                user_id=response.data.data.id
            }).catch(e => {
                console.log(e)
                return null
            })

            url = 'https://' + company_domain + '.pipedrive.com/api/v1/deals?api_token=' + api_token;
            data= {
                'title':'Nuevo Signup '+this.getUser.account_details.company_name,
                'org_id':org_id,
                'person_id':user_id,
                'stage_id':locale=='en'?9:8,
                '03d7642305f62da8526a44391ff96f3442b9a360':locale
            }
            await axios({
                method: 'post',
                url: url,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then(response => {
                console.log('Deal creado: '+response.data.data.id)
            }).catch(e => {
                console.log(e)
                return null
            })
        },
        regionstates(){
            if(this.country !=null && this.country.length>0){
                return this.regions.find(x => x.label == this.country).regions.map(type => type.label)
            }
            return []
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll().then((result) => {
                if(result){
                    dis.save()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
    },
  }
</script>

<style scoped>
.listaagentes{
max-height: 450px !important;
overflow-y: scroll;
}
.card-body h3{
    font-size:20px;
    text-align: left;
    margin-top:10px;
    color:#448FDE;
}
</style>