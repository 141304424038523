<template>

<div class=" colapsables">
    <component  :is="editCual.compo"  :value="editCual" :key="editCual.index+$store.state.mainComp"></component>

    <hr  class="separator mt10 mb10">
    <div v-if="$store.state.sobreComp=='leftColSobreDD'"  class="edicion_block">
        <div  @click="show.s1=!show.s1" class="navcola manito" style="border-top:0px !important;">
            <h4>{{$t('style.generales.fondo')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s1" v-show-slide="show.s1">
            <div class="row form_row">
                <div class="col-lg-6">
                    <imagePicker imgid="background" :btext="$t('pickers.loadi')" v-model="preview.fondo.img" />
                </div>
                <div class="col-lg-6">
                    <switchPicker v-model='preview.fondo.active' :label="$t('pickers.pattern')"/>
                </div>
                <div class="col-lg-6">
                    <switchPicker v-model='preview.fondo.animacion' :label="$t('pickers.movement')"/>
                </div>
                <div class="col-lg-6">
                    <opacityPicker v-model="preview.fondo.opacity"/>
                </div>
                <div class="col-lg-6">
                    <colorPicker :label="$t('pickers.colorfondo')"  :color="preview.fondo.background" v-model="preview.fondo.background" />
                </div>
                <div class="col-lg-6">
                    <sizePicker v-model="preview.fondo.width" :min='50' :max='1200' :interval="50"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker :label="$t('pickers.hori')" v-model="preview.fondo.left" :min='-200' :max='200' :interval="10" unit="%"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker :label="$t('pickers.verti')" v-model="preview.fondo.top" :min='-100' :max='200' :interval="10" />
                </div>
            </div>
        </div>
    </div> 
    <hr v-if="$store.state.sobreComp=='leftColSobreDD'" class="separator mt10 mb10">
    <div  class="edicion_block" >
      <a @click="switchSobreBackground()" class="btn btn-info btn-initial text-uppercase mb20"  > {{$t('general.cerrareditor')}}</a>
    </div>

</div>
</template>



<script>


//Edits
import editBoton from './dragndrop/editBoton'
import editHTML from './dragndrop/editHTML'
import editLine from './dragndrop/editLine'
import editParrafo_new from './dragndrop/editParrafo'
import editParrafo from './dragndrop/editParrafo'
import editTitulo from './dragndrop/editTitulo'
import editPremio from './dragndrop/editPremio'
import editBeneficio from './dragndrop/editBeneficio'
import editEmpty from './dragndrop/editEmpty'
import editSpace from './dragndrop/editSpace'
import editImagen from './dragndrop/editImagen'
import editVideo from './dragndrop/editVideo'
import editUbicacion from './dragndrop/editUbicacion'
import editSocial from './dragndrop/editSocial'
import editAvatar from './dragndrop/editAvatar'
import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import animacionPicker from './dinputs/animacionPicker'
import efectPicker from './dinputs/efectPicker'
import opacityPicker from './dinputs/opacityPicker'
import imagePicker from '@/components/imagePicker.vue'
import fontPicker from './dinputs/fontPicker'
import switchPicker from './dinputs/switchPicker'
import editQRCode from '@/components/blocks/qrcode/settings.vue'
import editIcon from '@/components/blocks/icon/settings.vue'
import editSaveDate from '@/components/blocks/saveDate/settings.vue'
import editFlags from '@/components/blocks/flags/settings.vue'
import editLeads from '@/components/blocks/leads/settings.vue'




export default {
    props:['editcual'],
    data(){
        return{
            show:{
                s1:true
            },
            cardVal:this.$store.getters.editingLinckard,
            editCual:this.editcual,
        }
    },
    computed:{
        preview () { return this.$store.getters.editingLinckard.style.sobre},  

    },
    methods:{
        switchSobreBackground(){
            this.$util.closeAllDivs();
            this.$bus.$emit("switchCompSobre", 'leftColSobreA');
        },
    },
    components: {
        'editUbicacion':editUbicacion,
        'editAvatar':editAvatar,
        'editSocial':editSocial,
        'editVideo':editVideo,
        'editImagen':editImagen,
        'editTitulo':editTitulo,
        'editPremio':editPremio,
        'editBeneficio':editBeneficio,
        'editParrafo':editParrafo,
        'editHTML':editHTML,
        'editLine':editLine,
        'editSpace':editSpace,
        'editBoton':editBoton,
        'editEmpty':editEmpty,
        'colorPicker':colorPicker,
        'fontPicker':fontPicker,
        'switchPicker':switchPicker,
        'imagePicker':imagePicker,
        'sizePicker':sizePicker,
        'animacionPicker':animacionPicker,
        'efectPicker':efectPicker,
        'editParrafo_new':editParrafo_new,
        'opacityPicker':opacityPicker,
        editQRCode, 
        editIcon, 
        editSaveDate,
        editFlags, 
        editLeads,
    }
}


</script>

<style scoped>
.btn-initial:hover{
    color:white !important;
}
.edicion_block a{
    color:white ;
}
</style>

"