import jwtDecode from 'jwt-decode'

export default async function ({ store, redirect,app,route }) {
  let params=document.location.search
  if(!app.$cookies.get('__session')) {
      app.$cookies.remove('__session')
      store.commit("setCurrentUser", null);
      return redirect('/login'+params)
  }
  const decodedToken = jwtDecode(app.$cookies.get('__session'))
  if (decodedToken) {
    let uid=decodedToken.user_id;
    let email = decodedToken.email;
    store.commit("setCurrentUser", { email, uid });
    await store.dispatch('fetchUserProfile')
    if(!store.state.userProfile)return redirect('/login'+params)
  }

  if (!store || !store.state || !store.state.currentUser) {
    return redirect('/login'+params)
  }

  if(!app.$util.isFounder(store.state.currentUser) && !app.$util.isTesting()){
    return redirect('/login'+params)
  }

}

