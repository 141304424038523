<template>
    
    <section id="pricing" :class="annual?'left':'right'" style="">
        <div class="container text-center">
            <div class="title-wrapper" style="margin-bottom: 20px;">
                <h3>{{$t('pricing.plans1')}} <b>{{$t('pricing.plans2')}}</b></h3>
                <p>{{$t('pricing.plansub')}}</p>
                <div class="d-table ml-auto mr-auto">
                    <div :class="annual?'pricing-switch left-active':'pricing-switch right-active'">
                        <button @click="annual=true"  type="button" class="switch-button switch-option-left"><span>{{$t('pricing.annually')}}</span> <label class="switch-discount">{{$t('pricing.save')}} 25%</label></button>
                        <button @click="annual=false" type="button"  class="switch-button switch-option-right"><span>{{$t('pricing.monthly')}}</span></button>
                    </div>
                </div>
            </div>
            <div class="row pricing-row">
                <div class="col-md-6 col-lg-3">
                    <div class="pricing-block" id="planFreemium">
                        <div class="pricing-head">
                            <h4 class="text-uppercase">Freemium</h4>
                        </div>
                        <div class="pricing-price">
                            <div class="price">
                                <strong><sup>U$D</sup>0</strong>
                                <small>{{$t('pricing.forever')}}</small>
                            </div>
                        </div>
                        <div class="pricing-body">
                            <div class="pricing-important">
                                <span><b>{{$t('pricing.upto')}}</b></span>
                                <span>{{planes_businesscards[0].max_linkcards}} Mobile Page</span>
                            </div>
                            <ul class="pricing-feature-list">
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t1')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t2')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t3')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t4')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t5')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t6')}}</li>
                            </ul>
                        </div>
                        <div class="w-100 mt-auto mb-0 button-container">
                            <button v-if="loading" type="button" class="btn btn-primary btn-initial btn-rounded"> <font-awesome-icon icon="circle-notch" spin /></button>
                            <button v-else-if="$util.hasCurrentProduct(getUser, planes_businesscards[0],annual)" type="button"  class="btn btn-info btn-rounded">{{$t('billing.tuplan')}}</button>
                            <button v-else @click="gotoCheckout(planes_businesscards[0])" type="button" class="btn btn-primary btn-initial btn-rounded">{{$t('planes.start')}}</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-3">
                    <div class="pricing-block most-popular" id="planProfessional">
                        <label class="pricing-label">{{$t('pricing.maspopu')}}</label>
                        <div class="pricing-head">
                            <h4 class="text-uppercase">Professional</h4>
                        </div>
                        <div class="pricing-price">
                            <div class="price anual">
                                <strong><sup>U$D</sup>{{ planes_businesscards[1].plans[1].price / 12 }}<span class="decimal">.00</span></strong>
                                <small>{{$t('pricing.pormes')}} / {{$t('pricing.banual')}}</small>
                            </div>
                            <div class="price mensual">
                                <strong><sup>U$D</sup>{{ planes_businesscards[1].plans[0].price }}<span class="decimal">.00</span></strong>
                                <small>{{$t('pricing.pormes')}} / {{$t('pricing.bmensual')}}</small>
                            </div>
                        </div>
                        <div class="pricing-body">
                            <div class="pricing-important">
                                <span><b>{{$t('pricing.upto')}}</b></span>
                                <span>{{planes_businesscards[1].max_linkcards}} Mobile Pages</span>
                            </div>
                            <ul class="pricing-feature-list">
                                <li><font-awesome-icon :icon="['fas','check']" /> <b>{{$t('pricing.t7')}}</b></li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t8')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t9')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t10')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t11')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t12')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t13')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t14')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t15')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t16')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t16b')}}</li>
                            </ul>
                        </div>
                        <div class="w-100 mt-auto mb-0 button-container">
                            <button v-if="loading" type="button" class="btn btn-primary btn-initial btn-rounded"> <font-awesome-icon icon="circle-notch" spin /></button>
                            <button v-else-if="$util.hasCurrentProduct(getUser, planes_businesscards[1],annual)"  type="button" class="btn btn-info btn-rounded">{{$t('billing.tuplan')}}</button>
                            <button v-else @click="gotoCheckout(planes_businesscards[1])" type="button" class="btn btn-primary btn-initial btn-rounded">{{$t('planes.start')}}</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-3">
                    <div class="pricing-block " id="planBusiness">
                        <div class="pricing-head">
                            <h4 class="text-uppercase">Business</h4>
                        </div>
                        <div class="pricing-price">
                            <div class="price anual">
                                <strong><sup>U$D</sup>{{ planes_businesscards[2].plans[1].price / 12 }}<span class="decimal"></span></strong>
                                <small>{{$t('pricing.pormes')}} / {{$t('pricing.banual')}}</small>
                            </div>
                            <div class="price mensual" >
                                <strong><sup>U$D</sup>{{ planes_businesscards[2].plans[0].price }}<span class="decimal">.00</span></strong>
                                <small>{{$t('pricing.pormes')}} / {{$t('pricing.bmensual')}}</small>
                            </div>
                        </div>
                        <div class="pricing-body">
                            <div class="pricing-important">
                                <span><b>{{$t('pricing.upto')}}</b></span>
                                <span>{{planes_businesscards[2].max_linkcards}} Mobile Page</span>
                            </div>
                            <ul class="pricing-feature-list">
                                <li><font-awesome-icon :icon="['fas','check']" /> <b>{{$t('pricing.t17')}}</b></li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t18')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t19')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t20')}}</li>
                                <li><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.t21')}}</li>
                            </ul>
                        </div>
                        <div class="w-100 mt-auto mb-0 button-container">
                            <button v-if="loading"  type="button" class="btn btn-primary btn-initial btn-rounded"> <font-awesome-icon icon="circle-notch" spin /></button>
                            <button v-else-if="$util.hasCurrentProduct(getUser, planes_businesscards[2],annual)"  class="btn btn-info btn-rounded">{{$t('billing.tuplan')}}</button>
                            <button v-else @click="gotoCheckout(planes_businesscards[2])"  class="btn btn-primary btn-initial btn-rounded">{{$t('planes.start')}}</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-3">
                    <div class="pricing-block" id="planEnterprise">
                        <div class="pricing-head">
                            <h4 class="text-uppercase">Enterprise</h4>
                        </div>
                        <div class="pricing-price">
                            <img alt="enterprise" src="@/assets/images/enterprise_graphic.svg">
                        </div>
                        <div class="pricing-body">
                            <div class="pricing-important">
                                <span> <b>{{$t('pricing.need')}}</b></span>
                            </div>
                            <ul class="pricing-feature-list">
                                <li class="color-primary"><font-awesome-icon :icon="['fas','check']" /> {{$t('pricing.everyenter')}}</li>
                            </ul>
                        </div>
                        <div class="w-100 mt-auto mb-0 button-container">
                            <a target="_blank" :href="$util.getURL_Formsales()"  class="btn btn-info text-uppercase btn-rounded">{{$t('pricing.moreinfo')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>

import {loadStripe} from '@stripe/stripe-js';
import { stripe_public,functions } from '@/services/firebaseConfig.js'
import { planes_businesscards} from '@/mixins/stripe.js'
export default {
    props:['place'],
    middleware: 'auth-check',
    head () {return this.$util.simpleMetaHead()},
    data(){
        return{
            loading:false,
            lugar:this.place,
            planes_businesscards:planes_businesscards,
            getUser:this.$store.state.userProfile,
            annual:true,
            sessionId:''
        }
    },
    methods:{
        async gotoCheckout_client(product){
                var dis=this
                let price=product.plans[product.plans.length>1 && this.annual?1:0]
                this.loading=true
                
                let stripe = await loadStripe('pk_live_KM0WAadM3l0YbDQtCkvnaSLE00OuTdfw3Q');
                stripe.redirectToCheckout({mode:'subscription',lineItems:[{price:price.plan_id,quantity:1}],locale:dis.$store.state.locale,successUrl:'https://my.linkcard.app/account#success',cancelUrl:'https://my.linkcard.app/account#plans'}).then(function(result) {
                    console.log(result);
                    dis.$util.registerStat('payment',{subtype:'intent',freq:price.type,price:price.price,place:dis.lugar})
                }).catch(function(error) {
                    console.error(error);
                });
        },
        async gotoCheckout(product){
              var dis=this
              if(!dis.getUser.stripe_details.stripe_customer_id)return alert('Stripe ID missing. Please refresh. Contact support')
              let price=product.plans[product.plans.length>1 && this.annual?1:0]
              this.loading=true
              let url=process.env.URL
              const get_stripe_checkout = functions.httpsCallable('get_stripe_checkout');
              await get_stripe_checkout({customer_id:dis.getUser.stripe_details.stripe_customer_id,price_id:price.plan_id,locale:dis.$store.state.locale,success_url:url+'account#success',cancel_url:url+'account#plans'})
              .then(function(result) {
                    dis.$util.registerStat('payment',{subtype:'intent',freq:price.type,price:price.price,place:dis.lugar})
                    var stripe = Stripe(stripe_public);
                    stripe.redirectToCheckout({sessionId: result.data.id}).catch(error => {console.log(error) })

                    dis.loading=false
              }).catch(error => {
                  console.log(error)
              })
        },
    }
}
</script>

<style scoped>
.switch-button{
text-transform: uppercase;
}
.right-active .switch-option-right span,
.left-active .switch-option-left span{
color:white !important;
}
#pricing.left .price.mensual,
#pricing.right .price.anual{
    display:none;
}

.pricing-important span{
display:block;
}

.pricing-important{
font-size:12px !important;
}
</style>