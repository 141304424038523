import Vue from "vue";
import { Icon, loadIcons } from "@iconify/vue2";

Vue.component("Icon", Icon);

loadIcons([
  "ph:activity-light",
  "ph:activity-thin",
  "ph:activity",
  "ph:address-book-bold",
  "ph:address-book-duotone",
  "ph:address-book-fill",
  "ph:address-book-light",
  "ph:address-book-thin",
  "ph:address-book",
  "ph:air-traffic-control-bold",
  "ph:air-traffic-control-duotone",
  "ph:air-traffic-control-fill",
  "ph:air-traffic-control-light",
  "ph:air-traffic-control-thin",
  "ph:air-traffic-control",
  "ph:airplane-bold",
  "ph:airplane-duotone",
  "ph:airplane-fill",
  "ph:airplane-in-flight-bold",
  "ph:airplane-in-flight-duotone",
  "ph:airplane-in-flight-fill",
  "ph:airplane-in-flight-light",
  "ph:airplane-in-flight-thin",
  "ph:airplane-in-flight",
  "ph:airplane-landing-bold",
  "ph:airplane-landing-duotone",
  "ph:airplane-landing-fill",
  "ph:airplane-landing-light",
  "ph:airplane-landing-thin",
  "ph:airplane-landing",
  "ph:airplane-light",
  "ph:airplane-takeoff-bold",
  "ph:airplane-takeoff-duotone",
  "ph:airplane-takeoff-fill",
  "ph:airplane-takeoff-light",
  "ph:airplane-takeoff-thin",
  "ph:airplane-takeoff",
  "ph:airplane-thin",
  "ph:airplane-tilt-bold",
  "ph:airplane-tilt-duotone",
  "ph:airplane-tilt-fill",
  "ph:airplane-tilt-light",
  "ph:airplane-tilt-thin",
  "ph:airplane-tilt",
  "ph:airplane",
  "ph:airplay-bold",
  "ph:airplay-duotone",
  "ph:airplay-fill",
  "ph:airplay-light",
  "ph:airplay-thin",
  "ph:airplay",
  "ph:alarm-bold",
  "ph:alarm-duotone",
  "ph:alarm-fill",
  "ph:alarm-light",
  "ph:alarm-thin",
  "ph:alarm",
  "ph:alien-bold",
  "ph:alien-duotone",
  "ph:alien-fill",
  "ph:alien-light",
  "ph:alien-thin",
  "ph:alien",
  "ph:align-bottom-bold",
  "ph:align-bottom-duotone",
  "ph:align-bottom-fill",
  "ph:align-bottom-light",
  "ph:align-bottom-simple-bold",
  "ph:align-bottom-simple-duotone",
  "ph:align-bottom-simple-fill",
  "ph:align-bottom-simple-light",
  "ph:align-bottom-simple-thin",
  "ph:align-bottom-simple",
  "ph:align-bottom-thin",
  "ph:align-bottom",
  "ph:align-center-horizontal-bold",
  "ph:align-center-horizontal-duotone",
  "ph:align-center-horizontal-fill",
  "ph:align-center-horizontal-light",
  "ph:align-center-horizontal-simple-bold",
  "ph:align-center-horizontal-simple-duotone",
  "ph:align-center-horizontal-simple-fill",
  "ph:align-center-horizontal-simple-light",
  "ph:align-center-horizontal-simple-thin",
  "ph:align-center-horizontal-simple",
  "ph:align-center-horizontal-thin",
  "ph:align-center-horizontal",
  "ph:align-center-vertical-bold",
  "ph:align-center-vertical-duotone",
  "ph:align-center-vertical-fill",
  "ph:align-center-vertical-light",
  "ph:align-center-vertical-simple-bold",
  "ph:align-center-vertical-simple-duotone",
  "ph:align-center-vertical-simple-fill",
  "ph:align-center-vertical-simple-light",
  "ph:align-center-vertical-simple-thin",
  "ph:align-center-vertical-simple",
  "ph:align-center-vertical-thin",
  "ph:align-center-vertical",
  "ph:align-left-bold",
  "ph:align-left-duotone",
  "ph:align-left-fill",
  "ph:align-left-light",
  "ph:align-left-simple-bold",
  "ph:align-left-simple-duotone",
  "ph:align-left-simple-fill",
  "ph:align-left-simple-light",
  "ph:align-left-simple-thin",
  "ph:align-left-simple",
  "ph:align-left-thin",
  "ph:align-left",
  "ph:align-right-bold",
  "ph:align-right-duotone",
  "ph:align-right-fill",
  "ph:align-right-light",
  "ph:align-right-simple-bold",
  "ph:align-right-simple-duotone",
  "ph:align-right-simple-fill",
  "ph:align-right-simple-light",
  "ph:align-right-simple-thin",
  "ph:align-right-simple",
  "ph:align-right-thin",
  "ph:align-right",
  "ph:align-top-bold",
  "ph:align-top-duotone",
  "ph:align-top-fill",
  "ph:align-top-light",
  "ph:align-top-simple-bold",
  "ph:align-top-simple-duotone",
  "ph:align-top-simple-fill",
  "ph:align-top-simple-light",
  "ph:align-top-simple-thin",
  "ph:align-top-simple",
  "ph:align-top-thin",
  "ph:align-top",
  "ph:amazon-logo-bold",
  "ph:amazon-logo-duotone",
  "ph:amazon-logo-fill",
  "ph:amazon-logo-light",
  "ph:amazon-logo-thin",
  "ph:amazon-logo",
  "ph:anchor-bold",
  "ph:anchor-duotone",
  "ph:anchor-fill",
  "ph:anchor-light",
  "ph:anchor-simple-bold",
  "ph:anchor-simple-duotone",
  "ph:anchor-simple-fill",
  "ph:anchor-simple-light",
  "ph:anchor-simple-thin",
  "ph:anchor-simple",
  "ph:anchor-thin",
  "ph:anchor",
  "ph:android-logo-bold",
  "ph:android-logo-duotone",
  "ph:android-logo-fill",
  "ph:android-logo-light",
  "ph:android-logo-thin",
  "ph:android-logo",
  "ph:angular-logo-bold",
  "ph:angular-logo-duotone",
  "ph:angular-logo-fill",
  "ph:angular-logo-light",
  "ph:angular-logo-thin",
  "ph:angular-logo",
  "ph:aperture-bold",
  "ph:aperture-duotone",
  "ph:aperture-fill",
  "ph:aperture-light",
  "ph:aperture-thin",
  "ph:aperture",
  "ph:app-store-logo-bold",
  "ph:app-store-logo-duotone",
  "ph:app-store-logo-fill",
  "ph:app-store-logo-light",
  "ph:app-store-logo-thin",
  "ph:app-store-logo",
  "ph:app-window-bold",
  "ph:app-window-duotone",
  "ph:app-window-fill",
  "ph:app-window-light",
  "ph:app-window-thin",
  "ph:app-window",
  "ph:apple-logo-bold",
  "ph:apple-logo-duotone",
  "ph:apple-logo-fill",
  "ph:apple-logo-light",
  "ph:apple-logo-thin",
  "ph:apple-logo",
  "ph:apple-podcasts-logo-bold",
  "ph:apple-podcasts-logo-duotone",
  "ph:apple-podcasts-logo-fill",
  "ph:apple-podcasts-logo-light",
  "ph:apple-podcasts-logo-thin",
  "ph:apple-podcasts-logo",
  "ph:archive-bold",
  "ph:archive-box-bold",
  "ph:archive-box-duotone",
  "ph:archive-box-fill",
  "ph:archive-box-light",
  "ph:archive-box-thin",
  "ph:archive-box",
  "ph:archive-duotone",
  "ph:archive-fill",
  "ph:archive-light",
  "ph:archive-thin",
  "ph:archive-tray-bold",
  "ph:archive-tray-duotone",
  "ph:archive-tray-fill",
  "ph:archive-tray-light",
  "ph:archive-tray-thin",
  "ph:archive-tray",
  "ph:archive",
  "ph:armchair-bold",
  "ph:armchair-duotone",
  "ph:armchair-fill",
  "ph:armchair-light",
  "ph:armchair-thin",
  "ph:armchair",
  "ph:arrow-arc-left-bold",
  "ph:arrow-arc-left-duotone",
  "ph:arrow-arc-left-fill",
  "ph:arrow-arc-left-light",
  "ph:arrow-arc-left-thin",
  "ph:arrow-arc-left",
  "ph:arrow-arc-right-bold",
  "ph:arrow-arc-right-duotone",
  "ph:arrow-arc-right-fill",
  "ph:arrow-arc-right-light",
  "ph:arrow-arc-right-thin",
  "ph:arrow-arc-right",
  "ph:arrow-bend-double-up-left-bold",
  "ph:arrow-bend-double-up-left-duotone",
  "ph:arrow-bend-double-up-left-fill",
  "ph:arrow-bend-double-up-left-light",
  "ph:arrow-bend-double-up-left-thin",
  "ph:arrow-bend-double-up-left",
  "ph:arrow-bend-double-up-right-bold",
  "ph:arrow-bend-double-up-right-duotone",
  "ph:arrow-bend-double-up-right-fill",
  "ph:arrow-bend-double-up-right-light",
  "ph:arrow-bend-double-up-right-thin",
  "ph:arrow-bend-double-up-right",
  "ph:arrow-bend-down-left-bold",
  "ph:arrow-bend-down-left-duotone",
  "ph:arrow-bend-down-left-fill",
  "ph:arrow-bend-down-left-light",
  "ph:arrow-bend-down-left-thin",
  "ph:arrow-bend-down-left",
  "ph:arrow-bend-down-right-bold",
  "ph:arrow-bend-down-right-duotone",
  "ph:arrow-bend-down-right-fill",
  "ph:arrow-bend-down-right-light",
  "ph:arrow-bend-down-right-thin",
  "ph:arrow-bend-down-right",
  "ph:arrow-bend-left-down-bold",
  "ph:arrow-bend-left-down-duotone",
  "ph:arrow-bend-left-down-fill",
  "ph:arrow-bend-left-down-light",
  "ph:arrow-bend-left-down-thin",
  "ph:arrow-bend-left-down",
  "ph:arrow-bend-left-up-bold",
  "ph:arrow-bend-left-up-duotone",
  "ph:arrow-bend-left-up-fill",
  "ph:arrow-bend-left-up-light",
  "ph:arrow-bend-left-up-thin",
  "ph:arrow-bend-left-up",
  "ph:arrow-bend-right-down-bold",
  "ph:arrow-bend-right-down-duotone",
  "ph:arrow-bend-right-down-fill",
  "ph:arrow-bend-right-down-light",
  "ph:arrow-bend-right-down-thin",
  "ph:arrow-bend-right-down",
  "ph:arrow-bend-right-up-bold",
  "ph:arrow-bend-right-up-duotone",
  "ph:arrow-bend-right-up-fill",
  "ph:arrow-bend-right-up-light",
  "ph:arrow-bend-right-up-thin",
  "ph:arrow-bend-right-up",
  "ph:arrow-bend-up-left-bold",
  "ph:arrow-bend-up-left-duotone",
  "ph:arrow-bend-up-left-fill",
  "ph:arrow-bend-up-left-light",
  "ph:arrow-bend-up-left-thin",
  "ph:arrow-bend-up-left",
  "ph:arrow-bend-up-right-bold",
  "ph:arrow-bend-up-right-duotone",
  "ph:arrow-bend-up-right-fill",
  "ph:arrow-bend-up-right-light",
  "ph:arrow-bend-up-right-thin",
  "ph:arrow-bend-up-right",
  "ph:arrow-circle-down-bold",
  "ph:arrow-circle-down-duotone",
  "ph:arrow-circle-down-fill",
  "ph:arrow-circle-down-left-bold",
  "ph:arrow-circle-down-left-duotone",
  "ph:arrow-circle-down-left-fill",
  "ph:arrow-circle-down-left-light",
  "ph:arrow-circle-down-left-thin",
  "ph:arrow-circle-down-left",
  "ph:arrow-circle-down-light",
  "ph:arrow-circle-down-right-bold",
  "ph:arrow-circle-down-right-duotone",
  "ph:arrow-circle-down-right-fill",
  "ph:arrow-circle-down-right-light",
  "ph:arrow-circle-down-right-thin",
  "ph:arrow-circle-down-right",
  "ph:arrow-circle-down-thin",
  "ph:arrow-circle-down",
  "ph:arrow-circle-left-bold",
  "ph:arrow-circle-left-duotone",
  "ph:arrow-circle-left-fill",
  "ph:arrow-circle-left-light",
  "ph:arrow-circle-left-thin",
  "ph:arrow-circle-left",
  "ph:arrow-circle-right-bold",
  "ph:arrow-circle-right-duotone",
  "ph:arrow-circle-right-fill",
  "ph:arrow-circle-right-light",
  "ph:arrow-circle-right-thin",
  "ph:arrow-circle-right",
  "ph:arrow-circle-up-bold",
  "ph:arrow-circle-up-duotone",
  "ph:arrow-circle-up-fill",
  "ph:arrow-circle-up-left-bold",
  "ph:arrow-circle-up-left-duotone",
  "ph:arrow-circle-up-left-fill",
  "ph:arrow-circle-up-left-light",
  "ph:arrow-circle-up-left-thin",
  "ph:arrow-circle-up-left",
  "ph:arrow-circle-up-light",
  "ph:arrow-circle-up-right-bold",
  "ph:arrow-circle-up-right-duotone",
  "ph:arrow-circle-up-right-fill",
  "ph:arrow-circle-up-right-light",
  "ph:arrow-circle-up-right-thin",
  "ph:arrow-circle-up-right",
  "ph:arrow-circle-up-thin",
  "ph:arrow-circle-up",
  "ph:arrow-clockwise-bold",
  "ph:arrow-clockwise-duotone",
  "ph:arrow-clockwise-fill",
  "ph:arrow-clockwise-light",
  "ph:arrow-clockwise-thin",
  "ph:arrow-clockwise",
  "ph:arrow-counter-clockwise-bold",
  "ph:arrow-counter-clockwise-duotone",
  "ph:arrow-counter-clockwise-fill",
  "ph:arrow-counter-clockwise-light",
  "ph:arrow-counter-clockwise-thin",
  "ph:arrow-counter-clockwise",
  "ph:arrow-down-bold",
  "ph:arrow-down-duotone",
  "ph:arrow-down-fill",
  "ph:arrow-down-left-bold",
  "ph:arrow-down-left-duotone",
  "ph:arrow-down-left-fill",
  "ph:arrow-down-left-light",
  "ph:arrow-down-left-thin",
  "ph:arrow-down-left",
  "ph:arrow-down-light",
  "ph:arrow-down-right-bold",
  "ph:arrow-down-right-duotone",
  "ph:arrow-down-right-fill",
  "ph:arrow-down-right-light",
  "ph:arrow-down-right-thin",
  "ph:arrow-down-right",
  "ph:arrow-down-thin",
  "ph:arrow-down",
  "ph:arrow-elbow-down-left-bold",
  "ph:arrow-elbow-down-left-duotone",
  "ph:arrow-elbow-down-left-fill",
  "ph:arrow-elbow-down-left-light",
  "ph:arrow-elbow-down-left-thin",
  "ph:arrow-elbow-down-left",
  "ph:arrow-elbow-down-right-bold",
  "ph:arrow-elbow-down-right-duotone",
  "ph:arrow-elbow-down-right-fill",
  "ph:arrow-elbow-down-right-light",
  "ph:arrow-elbow-down-right-thin",
  "ph:arrow-elbow-down-right",
  "ph:arrow-elbow-left-bold",
  "ph:arrow-elbow-left-down-bold",
  "ph:arrow-elbow-left-down-duotone",
  "ph:arrow-elbow-left-down-fill",
  "ph:arrow-elbow-left-down-light",
  "ph:arrow-elbow-left-down-thin",
  "ph:arrow-elbow-left-down",
  "ph:arrow-elbow-left-duotone",
  "ph:arrow-elbow-left-fill",
  "ph:arrow-elbow-left-light",
  "ph:arrow-elbow-left-thin",
  "ph:arrow-elbow-left-up-bold",
  "ph:arrow-elbow-left-up-duotone",
  "ph:arrow-elbow-left-up-fill",
  "ph:arrow-elbow-left-up-light",
  "ph:arrow-elbow-left-up-thin",
  "ph:arrow-elbow-left-up",
  "ph:arrow-elbow-left",
  "ph:arrow-elbow-right-bold",
  "ph:arrow-elbow-right-down-bold",
  "ph:arrow-elbow-right-down-duotone",
  "ph:arrow-elbow-right-down-fill",
  "ph:arrow-elbow-right-down-light",
  "ph:arrow-elbow-right-down-thin",
  "ph:arrow-elbow-right-down",
  "ph:arrow-elbow-right-duotone",
  "ph:arrow-elbow-right-fill",
  "ph:arrow-elbow-right-light",
  "ph:arrow-elbow-right-thin",
  "ph:arrow-elbow-right-up-bold",
  "ph:arrow-elbow-right-up-duotone",
  "ph:arrow-elbow-right-up-fill",
  "ph:arrow-elbow-right-up-light",
  "ph:arrow-elbow-right-up-thin",
  "ph:arrow-elbow-right-up",
  "ph:arrow-elbow-right",
  "ph:arrow-elbow-up-left-bold",
  "ph:arrow-elbow-up-left-duotone",
  "ph:arrow-elbow-up-left-fill",
  "ph:arrow-elbow-up-left-light",
  "ph:arrow-elbow-up-left-thin",
  "ph:arrow-elbow-up-left",
  "ph:arrow-elbow-up-right-bold",
  "ph:arrow-elbow-up-right-duotone",
  "ph:arrow-elbow-up-right-fill",
  "ph:arrow-elbow-up-right-light",
  "ph:arrow-elbow-up-right-thin",
  "ph:arrow-elbow-up-right",
  "ph:arrow-fat-down-bold",
  "ph:arrow-fat-down-duotone",
  "ph:arrow-fat-down-fill",
  "ph:arrow-fat-down-light",
  "ph:arrow-fat-down-thin",
  "ph:arrow-fat-down",
  "ph:arrow-fat-left-bold",
  "ph:arrow-fat-left-duotone",
  "ph:arrow-fat-left-fill",
  "ph:arrow-fat-left-light",
  "ph:arrow-fat-left-thin",
  "ph:arrow-fat-left",
  "ph:arrow-fat-line-down-bold",
  "ph:arrow-fat-line-down-duotone",
  "ph:arrow-fat-line-down-fill",
  "ph:arrow-fat-line-down-light",
  "ph:arrow-fat-line-down-thin",
  "ph:arrow-fat-line-down",
  "ph:arrow-fat-line-left-bold",
  "ph:arrow-fat-line-left-duotone",
  "ph:arrow-fat-line-left-fill",
  "ph:arrow-fat-line-left-light",
  "ph:arrow-fat-line-left-thin",
  "ph:arrow-fat-line-left",
  "ph:arrow-fat-line-right-bold",
  "ph:arrow-fat-line-right-duotone",
  "ph:arrow-fat-line-right-fill",
  "ph:arrow-fat-line-right-light",
  "ph:arrow-fat-line-right-thin",
  "ph:arrow-fat-line-right",
  "ph:arrow-fat-line-up-bold",
  "ph:arrow-fat-line-up-duotone",
  "ph:arrow-fat-line-up-fill",
  "ph:arrow-fat-line-up-light",
  "ph:arrow-fat-line-up-thin",
  "ph:arrow-fat-line-up",
  "ph:arrow-fat-lines-down-bold",
  "ph:arrow-fat-lines-down-duotone",
  "ph:arrow-fat-lines-down-fill",
  "ph:arrow-fat-lines-down-light",
  "ph:arrow-fat-lines-down-thin",
  "ph:arrow-fat-lines-down",
  "ph:arrow-fat-lines-left-bold",
  "ph:arrow-fat-lines-left-duotone",
  "ph:arrow-fat-lines-left-fill",
  "ph:arrow-fat-lines-left-light",
  "ph:arrow-fat-lines-left-thin",
  "ph:arrow-fat-lines-left",
  "ph:arrow-fat-lines-right-bold",
  "ph:arrow-fat-lines-right-duotone",
  "ph:arrow-fat-lines-right-fill",
  "ph:arrow-fat-lines-right-light",
  "ph:arrow-fat-lines-right-thin",
  "ph:arrow-fat-lines-right",
  "ph:arrow-fat-lines-up-bold",
  "ph:arrow-fat-lines-up-duotone",
  "ph:arrow-fat-lines-up-fill",
  "ph:arrow-fat-lines-up-light",
  "ph:arrow-fat-lines-up-thin",
  "ph:arrow-fat-lines-up",
  "ph:arrow-fat-right-bold",
  "ph:arrow-fat-right-duotone",
  "ph:arrow-fat-right-fill",
  "ph:arrow-fat-right-light",
  "ph:arrow-fat-right-thin",
  "ph:arrow-fat-right",
  "ph:arrow-fat-up-bold",
  "ph:arrow-fat-up-duotone",
  "ph:arrow-fat-up-fill",
  "ph:arrow-fat-up-light",
  "ph:arrow-fat-up-thin",
  "ph:arrow-fat-up",
  "ph:arrow-left-bold",
  "ph:arrow-left-duotone",
  "ph:arrow-left-fill",
  "ph:arrow-left-light",
  "ph:arrow-left-thin",
  "ph:arrow-left",
  "ph:arrow-line-down-bold",
  "ph:arrow-line-down-duotone",
  "ph:arrow-line-down-fill",
  "ph:arrow-line-down-left-bold",
  "ph:arrow-line-down-left-duotone",
  "ph:arrow-line-down-left-fill",
  "ph:arrow-line-down-left-light",
  "ph:arrow-line-down-left-thin",
  "ph:arrow-line-down-left",
  "ph:arrow-line-down-light",
  "ph:arrow-line-down-right-bold",
  "ph:arrow-line-down-right-duotone",
  "ph:arrow-line-down-right-fill",
  "ph:arrow-line-down-right-light",
  "ph:arrow-line-down-right-thin",
  "ph:arrow-line-down-right",
  "ph:arrow-line-down-thin",
  "ph:arrow-line-down",
  "ph:arrow-line-left-bold",
  "ph:arrow-line-left-duotone",
  "ph:arrow-line-left-fill",
  "ph:arrow-line-left-light",
  "ph:arrow-line-left-thin",
  "ph:arrow-line-left",
  "ph:arrow-line-right-bold",
  "ph:arrow-line-right-duotone",
  "ph:arrow-line-right-fill",
  "ph:arrow-line-right-light",
  "ph:arrow-line-right-thin",
  "ph:arrow-line-right",
  "ph:arrow-line-up-bold",
  "ph:arrow-line-up-duotone",
  "ph:arrow-line-up-fill",
  "ph:arrow-line-up-left-bold",
  "ph:arrow-line-up-left-duotone",
  "ph:arrow-line-up-left-fill",
  "ph:arrow-line-up-left-light",
  "ph:arrow-line-up-left-thin",
  "ph:arrow-line-up-left",
  "ph:arrow-line-up-light",
  "ph:arrow-line-up-right-bold",
  "ph:arrow-line-up-right-duotone",
  "ph:arrow-line-up-right-fill",
  "ph:arrow-line-up-right-light",
  "ph:arrow-line-up-right-thin",
  "ph:arrow-line-up-right",
  "ph:arrow-line-up-thin",
  "ph:arrow-line-up",
  "ph:arrow-right-bold",
  "ph:arrow-right-duotone",
  "ph:arrow-right-fill",
  "ph:arrow-right-light",
  "ph:arrow-right-thin",
  "ph:arrow-right",
  "ph:arrow-square-down-bold",
  "ph:arrow-square-down-duotone",
  "ph:arrow-square-down-fill",
  "ph:arrow-square-down-left-bold",
  "ph:arrow-square-down-left-duotone",
  "ph:arrow-square-down-left-fill",
  "ph:arrow-square-down-left-light",
  "ph:arrow-square-down-left-thin",
  "ph:arrow-square-down-left",
  "ph:arrow-square-down-light",
  "ph:arrow-square-down-right-bold",
  "ph:arrow-square-down-right-duotone",
  "ph:arrow-square-down-right-fill",
  "ph:arrow-square-down-right-light",
  "ph:arrow-square-down-right-thin",
  "ph:arrow-square-down-right",
  "ph:arrow-square-down-thin",
  "ph:arrow-square-down",
  "ph:arrow-square-in-bold",
  "ph:arrow-square-in-duotone",
  "ph:arrow-square-in-fill",
  "ph:arrow-square-in-light",
  "ph:arrow-square-in-thin",
  "ph:arrow-square-in",
  "ph:arrow-square-left-bold",
  "ph:arrow-square-left-duotone",
  "ph:arrow-square-left-fill",
  "ph:arrow-square-left-light",
  "ph:arrow-square-left-thin",
  "ph:arrow-square-left",
  "ph:arrow-square-out-bold",
  "ph:arrow-square-out-duotone",
  "ph:arrow-square-out-fill",
  "ph:arrow-square-out-light",
  "ph:arrow-square-out-thin",
  "ph:arrow-square-out",
  "ph:arrow-square-right-bold",
  "ph:arrow-square-right-duotone",
  "ph:arrow-square-right-fill",
  "ph:arrow-square-right-light",
  "ph:arrow-square-right-thin",
  "ph:arrow-square-right",
  "ph:arrow-square-up-bold",
  "ph:arrow-square-up-duotone",
  "ph:arrow-square-up-fill",
  "ph:arrow-square-up-left-bold",
  "ph:arrow-square-up-left-duotone",
  "ph:arrow-square-up-left-fill",
  "ph:arrow-square-up-left-light",
  "ph:arrow-square-up-left-thin",
  "ph:arrow-square-up-left",
  "ph:arrow-square-up-light",
  "ph:arrow-square-up-right-bold",
  "ph:arrow-square-up-right-duotone",
  "ph:arrow-square-up-right-fill",
  "ph:arrow-square-up-right-light",
  "ph:arrow-square-up-right-thin",
  "ph:arrow-square-up-right",
  "ph:arrow-square-up-thin",
  "ph:arrow-square-up",
  "ph:arrow-u-down-left-bold",
  "ph:arrow-u-down-left-duotone",
  "ph:arrow-u-down-left-fill",
  "ph:arrow-u-down-left-light",
  "ph:arrow-u-down-left-thin",
  "ph:arrow-u-down-left",
  "ph:arrow-u-down-right-bold",
  "ph:arrow-u-down-right-duotone",
  "ph:arrow-u-down-right-fill",
  "ph:arrow-u-down-right-light",
  "ph:arrow-u-down-right-thin",
  "ph:arrow-u-down-right",
  "ph:arrow-u-left-down-bold",
  "ph:arrow-u-left-down-duotone",
  "ph:arrow-u-left-down-fill",
  "ph:arrow-u-left-down-light",
  "ph:arrow-u-left-down-thin",
  "ph:arrow-u-left-down",
  "ph:arrow-u-left-up-bold",
  "ph:arrow-u-left-up-duotone",
  "ph:arrow-u-left-up-fill",
  "ph:arrow-u-left-up-light",
  "ph:arrow-u-left-up-thin",
  "ph:arrow-u-left-up",
  "ph:arrow-u-right-down-bold",
  "ph:arrow-u-right-down-duotone",
  "ph:arrow-u-right-down-fill",
  "ph:arrow-u-right-down-light",
  "ph:arrow-u-right-down-thin",
  "ph:arrow-u-right-down",
  "ph:arrow-u-right-up-bold",
  "ph:arrow-u-right-up-duotone",
  "ph:arrow-u-right-up-fill",
  "ph:arrow-u-right-up-light",
  "ph:arrow-u-right-up-thin",
  "ph:arrow-u-right-up",
  "ph:arrow-u-up-left-bold",
  "ph:arrow-u-up-left-duotone",
  "ph:arrow-u-up-left-fill",
  "ph:arrow-u-up-left-light",
  "ph:arrow-u-up-left-thin",
  "ph:arrow-u-up-left",
  "ph:arrow-u-up-right-bold",
  "ph:arrow-u-up-right-duotone",
  "ph:arrow-u-up-right-fill",
  "ph:arrow-u-up-right-light",
  "ph:arrow-u-up-right-thin",
  "ph:arrow-u-up-right",
  "ph:arrow-up-bold",
  "ph:arrow-up-duotone",
  "ph:arrow-up-fill",
  "ph:arrow-up-left-bold",
  "ph:arrow-up-left-duotone",
  "ph:arrow-up-left-fill",
  "ph:arrow-up-left-light",
  "ph:arrow-up-left-thin",
  "ph:arrow-up-left",
  "ph:arrow-up-light",
  "ph:arrow-up-right-bold",
  "ph:arrow-up-right-duotone",
  "ph:arrow-up-right-fill",
  "ph:arrow-up-right-light",
  "ph:arrow-up-right-thin",
  "ph:arrow-up-right",
  "ph:arrow-up-thin",
  "ph:arrow-up",
  "ph:arrows-clockwise-bold",
  "ph:arrows-clockwise-duotone",
  "ph:arrows-clockwise-fill",
  "ph:arrows-clockwise-light",
  "ph:arrows-clockwise-thin",
  "ph:arrows-clockwise",
  "ph:arrows-counter-clockwise-bold",
  "ph:arrows-counter-clockwise-duotone",
  "ph:arrows-counter-clockwise-fill",
  "ph:arrows-counter-clockwise-light",
  "ph:arrows-counter-clockwise-thin",
  "ph:arrows-counter-clockwise",
  "ph:arrows-down-up-bold",
  "ph:arrows-down-up-duotone",
  "ph:arrows-down-up-fill",
  "ph:arrows-down-up-light",
  "ph:arrows-down-up-thin",
  "ph:arrows-down-up",
  "ph:arrows-horizontal-bold",
  "ph:arrows-horizontal-duotone",
  "ph:arrows-horizontal-fill",
  "ph:arrows-horizontal-light",
  "ph:arrows-horizontal-thin",
  "ph:arrows-horizontal",
  "ph:arrows-in-bold",
  "ph:arrows-in-cardinal-bold",
  "ph:arrows-in-cardinal-duotone",
  "ph:arrows-in-cardinal-fill",
  "ph:arrows-in-cardinal-light",
  "ph:arrows-in-cardinal-thin",
  "ph:arrows-in-cardinal",
  "ph:arrows-in-duotone",
  "ph:arrows-in-fill",
  "ph:arrows-in-light",
  "ph:arrows-in-line-horizontal-bold",
  "ph:arrows-in-line-horizontal-duotone",
  "ph:arrows-in-line-horizontal-fill",
  "ph:arrows-in-line-horizontal-light",
  "ph:arrows-in-line-horizontal-thin",
  "ph:arrows-in-line-horizontal",
  "ph:arrows-in-line-vertical-bold",
  "ph:arrows-in-line-vertical-duotone",
  "ph:arrows-in-line-vertical-fill",
  "ph:arrows-in-line-vertical-light",
  "ph:arrows-in-line-vertical-thin",
  "ph:arrows-in-line-vertical",
  "ph:arrows-in-simple-bold",
  "ph:arrows-in-simple-duotone",
  "ph:arrows-in-simple-fill",
  "ph:arrows-in-simple-light",
  "ph:arrows-in-simple-thin",
  "ph:arrows-in-simple",
  "ph:arrows-in-thin",
  "ph:arrows-in",
  "ph:arrows-left-right-bold",
  "ph:arrows-left-right-duotone",
  "ph:arrows-left-right-fill",
  "ph:arrows-left-right-light",
  "ph:arrows-left-right-thin",
  "ph:arrows-left-right",
  "ph:arrows-merge-bold",
  "ph:arrows-merge-duotone",
  "ph:arrows-merge-fill",
  "ph:arrows-merge-light",
  "ph:arrows-merge-thin",
  "ph:arrows-merge",
  "ph:arrows-out-bold",
  "ph:arrows-out-cardinal-bold",
  "ph:arrows-out-cardinal-duotone",
  "ph:arrows-out-cardinal-fill",
  "ph:arrows-out-cardinal-light",
  "ph:arrows-out-cardinal-thin",
  "ph:arrows-out-cardinal",
  "ph:arrows-out-duotone",
  "ph:arrows-out-fill",
  "ph:arrows-out-light",
  "ph:arrows-out-line-horizontal-bold",
  "ph:arrows-out-line-horizontal-duotone",
  "ph:arrows-out-line-horizontal-fill",
  "ph:arrows-out-line-horizontal-light",
  "ph:arrows-out-line-horizontal-thin",
  "ph:arrows-out-line-horizontal",
  "ph:arrows-out-line-vertical-bold",
  "ph:arrows-out-line-vertical-duotone",
  "ph:arrows-out-line-vertical-fill",
  "ph:arrows-out-line-vertical-light",
  "ph:arrows-out-line-vertical-thin",
  "ph:arrows-out-line-vertical",
  "ph:arrows-out-simple-bold",
  "ph:arrows-out-simple-duotone",
  "ph:arrows-out-simple-fill",
  "ph:arrows-out-simple-light",
  "ph:arrows-out-simple-thin",
  "ph:arrows-out-simple",
  "ph:arrows-out-thin",
  "ph:arrows-out",
  "ph:arrows-split-bold",
  "ph:arrows-split-duotone",
  "ph:arrows-split-fill",
  "ph:arrows-split-light",
  "ph:arrows-split-thin",
  "ph:arrows-split",
  "ph:arrows-vertical-bold",
  "ph:arrows-vertical-duotone",
  "ph:arrows-vertical-fill",
  "ph:arrows-vertical-light",
  "ph:arrows-vertical-thin",
  "ph:arrows-vertical",
  "ph:article-bold",
  "ph:article-duotone",
  "ph:article-fill",
  "ph:article-light",
  "ph:article-medium-bold",
  "ph:article-medium-duotone",
  "ph:article-medium-fill",
  "ph:article-medium-light",
  "ph:article-medium-thin",
  "ph:article-medium",
  "ph:article-ny-times-bold",
  "ph:article-ny-times-duotone",
  "ph:article-ny-times-fill",
  "ph:article-ny-times-light",
  "ph:article-ny-times-thin",
  "ph:article-ny-times",
  "ph:article-thin",
  "ph:article",
  "ph:asterisk-bold",
  "ph:asterisk-duotone",
  "ph:asterisk-fill",
  "ph:asterisk-light",
  "ph:asterisk-simple-bold",
  "ph:asterisk-simple-duotone",
  "ph:asterisk-simple-fill",
  "ph:asterisk-simple-light",
  "ph:asterisk-simple-thin",
  "ph:asterisk-simple",
  "ph:asterisk-thin",
  "ph:asterisk",
  "ph:at-bold",
  "ph:at-duotone",
  "ph:at-fill",
  "ph:at-light",
  "ph:at-thin",
  "ph:at",
  "ph:atom-bold",
  "ph:atom-duotone",
  "ph:atom-fill",
  "ph:atom-light",
  "ph:atom-thin",
  "ph:atom",
  "ph:baby-bold",
  "ph:baby-duotone",
  "ph:baby-fill",
  "ph:baby-light",
  "ph:baby-thin",
  "ph:baby",
  "ph:backpack-bold",
  "ph:backpack-duotone",
  "ph:backpack-fill",
  "ph:backpack-light",
  "ph:backpack-thin",
  "ph:backpack",
  "ph:backspace-bold",
  "ph:backspace-duotone",
  "ph:backspace-fill",
  "ph:backspace-light",
  "ph:backspace-thin",
  "ph:backspace",
  "ph:bag-bold",
  "ph:bag-duotone",
  "ph:bag-fill",
  "ph:bag-light",
  "ph:bag-simple-bold",
  "ph:bag-simple-duotone",
  "ph:bag-simple-fill",
  "ph:bag-simple-light",
  "ph:bag-simple-thin",
  "ph:bag-simple",
  "ph:bag-thin",
  "ph:bag",
  "ph:balloon-bold",
  "ph:balloon-duotone",
  "ph:balloon-fill",
  "ph:balloon-light",
  "ph:balloon-thin",
  "ph:balloon",
  "ph:bandaids-bold",
  "ph:bandaids-duotone",
  "ph:bandaids-fill",
  "ph:bandaids-light",
  "ph:bandaids-thin",
  "ph:bandaids",
  "ph:bank-bold",
  "ph:bank-duotone",
  "ph:bank-fill",
  "ph:bank-light",
  "ph:bank-thin",
  "ph:bank",
  "ph:barbell-bold",
  "ph:barbell-duotone",
  "ph:barbell-fill",
  "ph:barbell-light",
  "ph:barbell-thin",
  "ph:barbell",
  "ph:barcode-bold",
  "ph:barcode-duotone",
  "ph:barcode-fill",
  "ph:barcode-light",
  "ph:barcode-thin",
  "ph:barcode",
  "ph:barricade-bold",
  "ph:barricade-duotone",
  "ph:barricade-fill",
  "ph:barricade-light",
  "ph:barricade-thin",
  "ph:barricade",
  "ph:baseball-bold",
  "ph:baseball-cap-bold",
  "ph:baseball-cap-duotone",
  "ph:baseball-cap-fill",
  "ph:baseball-cap-light",
  "ph:baseball-cap-thin",
  "ph:baseball-cap",
  "ph:baseball-duotone",
  "ph:baseball-fill",
  "ph:baseball-light",
  "ph:baseball-thin",
  "ph:baseball",
  "ph:basket-bold",
  "ph:basket-duotone",
  "ph:basket-fill",
  "ph:basket-light",
  "ph:basket-thin",
  "ph:basket",
  "ph:basketball-bold",
  "ph:basketball-duotone",
  "ph:basketball-fill",
  "ph:basketball-light",
  "ph:basketball-thin",
  "ph:basketball",
  "ph:bathtub-bold",
  "ph:bathtub-duotone",
  "ph:bathtub-fill",
  "ph:bathtub-light",
  "ph:bathtub-thin",
  "ph:bathtub",
  "ph:battery-charging-bold",
  "ph:battery-charging-duotone",
  "ph:battery-charging-fill",
  "ph:battery-charging-light",
  "ph:battery-charging-thin",
  "ph:battery-charging-vertical-bold",
  "ph:battery-charging-vertical-duotone",
  "ph:battery-charging-vertical-fill",
  "ph:battery-charging-vertical-light",
  "ph:battery-charging-vertical-thin",
  "ph:battery-charging-vertical",
  "ph:battery-charging",
  "ph:battery-empty-bold",
  "ph:battery-empty-duotone",
  "ph:battery-empty-fill",
  "ph:battery-empty-light",
  "ph:battery-empty-thin",
  "ph:battery-empty",
  "ph:battery-full-bold",
  "ph:battery-full-duotone",
  "ph:battery-full-fill",
  "ph:battery-full-light",
  "ph:battery-full-thin",
  "ph:battery-full",
  "ph:battery-high-bold",
  "ph:battery-high-duotone",
  "ph:battery-high-fill",
  "ph:battery-high-light",
  "ph:battery-high-thin",
  "ph:battery-high",
  "ph:battery-low-bold",
  "ph:battery-low-duotone",
  "ph:battery-low-fill",
  "ph:battery-low-light",
  "ph:battery-low-thin",
  "ph:battery-low",
  "ph:battery-medium-bold",
  "ph:battery-medium-duotone",
  "ph:battery-medium-fill",
  "ph:battery-medium-light",
  "ph:battery-medium-thin",
  "ph:battery-medium",
  "ph:battery-plus-bold",
  "ph:battery-plus-duotone",
  "ph:battery-plus-fill",
  "ph:battery-plus-light",
  "ph:battery-plus-thin",
  "ph:battery-plus-vertical-bold",
  "ph:battery-plus-vertical-duotone",
  "ph:battery-plus-vertical-fill",
  "ph:battery-plus-vertical-light",
  "ph:battery-plus-vertical-thin",
  "ph:battery-plus-vertical",
  "ph:battery-plus",
  "ph:battery-vertical-empty-bold",
  "ph:battery-vertical-empty-duotone",
  "ph:battery-vertical-empty-fill",
  "ph:battery-vertical-empty-light",
  "ph:battery-vertical-empty-thin",
  "ph:battery-vertical-empty",
  "ph:battery-vertical-full-bold",
  "ph:battery-vertical-full-duotone",
  "ph:battery-vertical-full-fill",
  "ph:battery-vertical-full-light",
  "ph:battery-vertical-full-thin",
  "ph:battery-vertical-full",
  "ph:battery-vertical-high-bold",
  "ph:battery-vertical-high-duotone",
  "ph:battery-vertical-high-fill",
  "ph:battery-vertical-high-light",
  "ph:battery-vertical-high-thin",
  "ph:battery-vertical-high",
  "ph:battery-vertical-low-bold",
  "ph:battery-vertical-low-duotone",
  "ph:battery-vertical-low-fill",
  "ph:battery-vertical-low-light",
  "ph:battery-vertical-low-thin",
  "ph:battery-vertical-low",
  "ph:battery-vertical-medium-bold",
  "ph:battery-vertical-medium-duotone",
  "ph:battery-vertical-medium-fill",
  "ph:battery-vertical-medium-light",
  "ph:battery-vertical-medium-thin",
  "ph:battery-vertical-medium",
  "ph:battery-warning-bold",
  "ph:battery-warning-duotone",
  "ph:battery-warning-fill",
  "ph:battery-warning-light",
  "ph:battery-warning-thin",
  "ph:battery-warning-vertical-bold",
  "ph:battery-warning-vertical-duotone",
  "ph:battery-warning-vertical-fill",
  "ph:battery-warning-vertical-light",
  "ph:battery-warning-vertical-thin",
  "ph:battery-warning-vertical",
  "ph:battery-warning",
  "ph:bed-bold",
  "ph:bed-duotone",
  "ph:bed-fill",
  "ph:bed-light",
  "ph:bed-thin",
  "ph:bed",
  "ph:beer-bottle-bold",
  "ph:beer-bottle-duotone",
  "ph:beer-bottle-fill",
  "ph:beer-bottle-light",
  "ph:beer-bottle-thin",
  "ph:beer-bottle",
  "ph:beer-stein-bold",
  "ph:beer-stein-duotone",
  "ph:beer-stein-fill",
  "ph:beer-stein-light",
  "ph:beer-stein-thin",
  "ph:beer-stein",
  "ph:behance-logo-bold",
  "ph:behance-logo-duotone",
  "ph:behance-logo-fill",
  "ph:behance-logo-light",
  "ph:behance-logo-thin",
  "ph:behance-logo",
  "ph:bell-bold",
  "ph:bell-duotone",
  "ph:bell-fill",
  "ph:bell-light",
  "ph:bell-ringing-bold",
  "ph:bell-ringing-duotone",
  "ph:bell-ringing-fill",
  "ph:bell-ringing-light",
  "ph:bell-ringing-thin",
  "ph:bell-ringing",
  "ph:bell-simple-bold",
  "ph:bell-simple-duotone",
  "ph:bell-simple-fill",
  "ph:bell-simple-light",
  "ph:bell-simple-ringing-bold",
  "ph:bell-simple-ringing-duotone",
  "ph:bell-simple-ringing-fill",
  "ph:bell-simple-ringing-light",
  "ph:bell-simple-ringing-thin",
  "ph:bell-simple-ringing",
  "ph:bell-simple-slash-bold",
  "ph:bell-simple-slash-duotone",
  "ph:bell-simple-slash-fill",
  "ph:bell-simple-slash-light",
  "ph:bell-simple-slash-thin",
  "ph:bell-simple-slash",
  "ph:bell-simple-thin",
  "ph:bell-simple-z-bold",
  "ph:bell-simple-z-duotone",
  "ph:bell-simple-z-fill",
  "ph:bell-simple-z-light",
  "ph:bell-simple-z-thin",
  "ph:bell-simple-z",
  "ph:bell-simple",
  "ph:bell-slash-bold",
  "ph:bell-slash-duotone",
  "ph:bell-slash-fill",
  "ph:bell-slash-light",
  "ph:bell-slash-thin",
  "ph:bell-slash",
  "ph:bell-thin",
  "ph:bell-z-bold",
  "ph:bell-z-duotone",
  "ph:bell-z-fill",
  "ph:bell-z-light",
  "ph:bell-z-thin",
  "ph:bell-z",
  "ph:bell",
  "ph:bezier-curve-bold",
  "ph:bezier-curve-duotone",
  "ph:bezier-curve-fill",
  "ph:bezier-curve-light",
  "ph:bezier-curve-thin",
  "ph:bezier-curve",
  "ph:bicycle-bold",
  "ph:bicycle-duotone",
  "ph:bicycle-fill",
  "ph:bicycle-light",
  "ph:bicycle-thin",
  "ph:bicycle",
  "ph:binoculars-bold",
  "ph:binoculars-duotone",
  "ph:binoculars-fill",
  "ph:binoculars-light",
  "ph:binoculars-thin",
  "ph:binoculars",
  "ph:bird-bold",
  "ph:bird-duotone",
  "ph:bird-fill",
  "ph:bird-light",
  "ph:bird-thin",
  "ph:bird",
  "ph:bluetooth-bold",
  "ph:bluetooth-connected-bold",
  "ph:bluetooth-connected-duotone",
  "ph:bluetooth-connected-fill",
  "ph:bluetooth-connected-light",
  "ph:bluetooth-connected-thin",
  "ph:bluetooth-connected",
  "ph:bluetooth-duotone",
  "ph:bluetooth-fill",
  "ph:bluetooth-light",
  "ph:bluetooth-slash-bold",
  "ph:bluetooth-slash-duotone",
  "ph:bluetooth-slash-fill",
  "ph:bluetooth-slash-light",
  "ph:bluetooth-slash-thin",
  "ph:bluetooth-slash",
  "ph:bluetooth-thin",
  "ph:bluetooth-x-bold",
  "ph:bluetooth-x-duotone",
  "ph:bluetooth-x-fill",
  "ph:bluetooth-x-light",
  "ph:bluetooth-x-thin",
  "ph:bluetooth-x",
  "ph:bluetooth",
  "ph:boat-bold",
  "ph:boat-duotone",
  "ph:boat-fill",
  "ph:boat-light",
  "ph:boat-thin",
  "ph:boat",
  "ph:bone-bold",
  "ph:bone-duotone",
  "ph:bone-fill",
  "ph:bone-light",
  "ph:bone-thin",
  "ph:bone",
  "ph:book-bold",
  "ph:book-bookmark-bold",
  "ph:book-bookmark-duotone",
  "ph:book-bookmark-fill",
  "ph:book-bookmark-light",
  "ph:book-bookmark-thin",
  "ph:book-bookmark",
  "ph:book-duotone",
  "ph:book-fill",
  "ph:book-light",
  "ph:book-open-bold",
  "ph:book-open-duotone",
  "ph:book-open-fill",
  "ph:book-open-light",
  "ph:book-open-text-bold",
  "ph:book-open-text-duotone",
  "ph:book-open-text-fill",
  "ph:book-open-text-light",
  "ph:book-open-text-thin",
  "ph:book-open-text",
  "ph:book-open-thin",
  "ph:book-open",
  "ph:book-thin",
  "ph:book",
  "ph:bookmark-bold",
  "ph:bookmark-duotone",
  "ph:bookmark-fill",
  "ph:bookmark-light",
  "ph:bookmark-simple-bold",
  "ph:bookmark-simple-duotone",
  "ph:bookmark-simple-fill",
  "ph:bookmark-simple-light",
  "ph:bookmark-simple-thin",
  "ph:bookmark-simple",
  "ph:bookmark-thin",
  "ph:bookmark",
  "ph:bookmarks-bold",
  "ph:bookmarks-duotone",
  "ph:bookmarks-fill",
  "ph:bookmarks-light",
  "ph:bookmarks-simple-bold",
  "ph:bookmarks-simple-duotone",
  "ph:bookmarks-simple-fill",
  "ph:bookmarks-simple-light",
  "ph:bookmarks-simple-thin",
  "ph:bookmarks-simple",
  "ph:bookmarks-thin",
  "ph:bookmarks",
  "ph:books-bold",
  "ph:books-duotone",
  "ph:books-fill",
  "ph:books-light",
  "ph:books-thin",
  "ph:books",
  "ph:boot-bold",
  "ph:boot-duotone",
  "ph:boot-fill",
  "ph:boot-light",
  "ph:boot-thin",
  "ph:boot",
  "ph:bounding-box-bold",
  "ph:bounding-box-duotone",
  "ph:bounding-box-fill",
  "ph:bounding-box-light",
  "ph:bounding-box-thin",
  "ph:bounding-box",
  "ph:bowl-food-bold",
  "ph:bowl-food-duotone",
  "ph:bowl-food-fill",
  "ph:bowl-food-light",
  "ph:bowl-food-thin",
  "ph:bowl-food",
  "ph:brackets-angle-bold",
  "ph:brackets-angle-duotone",
  "ph:brackets-angle-fill",
  "ph:brackets-angle-light",
  "ph:brackets-angle-thin",
  "ph:brackets-angle",
  "ph:brackets-curly-bold",
  "ph:brackets-curly-duotone",
  "ph:brackets-curly-fill",
  "ph:brackets-curly-light",
  "ph:brackets-curly-thin",
  "ph:brackets-curly",
  "ph:brackets-round-bold",
  "ph:brackets-round-duotone",
  "ph:brackets-round-fill",
  "ph:brackets-round-light",
  "ph:brackets-round-thin",
  "ph:brackets-round",
  "ph:brackets-square-bold",
  "ph:brackets-square-duotone",
  "ph:brackets-square-fill",
  "ph:brackets-square-light",
  "ph:brackets-square-thin",
  "ph:brackets-square",
  "ph:brain-bold",
  "ph:brain-duotone",
  "ph:brain-fill",
  "ph:brain-light",
  "ph:brain-thin",
  "ph:brain",
  "ph:brandy-bold",
  "ph:brandy-duotone",
  "ph:brandy-fill",
  "ph:brandy-light",
  "ph:brandy-thin",
  "ph:brandy",
  "ph:bridge-bold",
  "ph:bridge-duotone",
  "ph:bridge-fill",
  "ph:bridge-light",
  "ph:bridge-thin",
  "ph:bridge",
  "ph:briefcase-bold",
  "ph:briefcase-duotone",
  "ph:briefcase-fill",
  "ph:briefcase-light",
  "ph:briefcase-metal-bold",
  "ph:briefcase-metal-duotone",
  "ph:briefcase-metal-fill",
  "ph:briefcase-metal-light",
  "ph:briefcase-metal-thin",
  "ph:briefcase-metal",
  "ph:briefcase-simple-bold",
  "ph:briefcase-simple-duotone",
  "ph:briefcase-simple-fill",
  "ph:briefcase-simple-light",
  "ph:briefcase-simple-thin",
  "ph:briefcase-simple",
  "ph:briefcase-thin",
  "ph:briefcase",
  "ph:broadcast-bold",
  "ph:broadcast-duotone",
  "ph:broadcast-fill",
  "ph:broadcast-light",
  "ph:broadcast-thin",
  "ph:broadcast",
  "ph:broom-bold",
  "ph:broom-duotone",
  "ph:broom-fill",
  "ph:broom-light",
  "ph:broom-thin",
  "ph:broom",
  "ph:browser-bold",
  "ph:browser-duotone",
  "ph:browser-fill",
  "ph:browser-light",
  "ph:browser-thin",
  "ph:browser",
  "ph:browsers-bold",
  "ph:browsers-duotone",
  "ph:browsers-fill",
  "ph:browsers-light",
  "ph:browsers-thin",
  "ph:browsers",
  "ph:bug-beetle-bold",
  "ph:bug-beetle-duotone",
  "ph:bug-beetle-fill",
  "ph:bug-beetle-light",
  "ph:bug-beetle-thin",
  "ph:bug-beetle",
  "ph:bug-bold",
  "ph:bug-droid-bold",
  "ph:bug-droid-duotone",
  "ph:bug-droid-fill",
  "ph:bug-droid-light",
  "ph:bug-droid-thin",
  "ph:bug-droid",
  "ph:bug-duotone",
  "ph:bug-fill",
  "ph:bug-light",
  "ph:bug-thin",
  "ph:bug",
  "ph:buildings-bold",
  "ph:buildings-duotone",
  "ph:buildings-fill",
  "ph:buildings-light",
  "ph:buildings-thin",
  "ph:buildings",
  "ph:bus-bold",
  "ph:bus-duotone",
  "ph:bus-fill",
  "ph:bus-light",
  "ph:bus-thin",
  "ph:bus",
  "ph:butterfly-bold",
  "ph:butterfly-duotone",
  "ph:butterfly-fill",
  "ph:butterfly-light",
  "ph:butterfly-thin",
  "ph:butterfly",
  "ph:cactus-bold",
  "ph:cactus-duotone",
  "ph:cactus-fill",
  "ph:cactus-light",
  "ph:cactus-thin",
  "ph:cactus",
  "ph:cake-bold",
  "ph:cake-duotone",
  "ph:cake-fill",
  "ph:cake-light",
  "ph:cake-thin",
  "ph:cake",
  "ph:calculator-bold",
  "ph:calculator-duotone",
  "ph:calculator-fill",
  "ph:calculator-light",
  "ph:calculator-thin",
  "ph:calculator",
  "ph:calendar-blank-bold",
  "ph:calendar-blank-duotone",
  "ph:calendar-blank-fill",
  "ph:calendar-blank-light",
  "ph:calendar-blank-thin",
  "ph:calendar-blank",
  "ph:calendar-bold",
  "ph:calendar-check-bold",
  "ph:calendar-check-duotone",
  "ph:calendar-check-fill",
  "ph:calendar-check-light",
  "ph:calendar-check-thin",
  "ph:calendar-check",
  "ph:calendar-duotone",
  "ph:calendar-fill",
  "ph:calendar-light",
  "ph:calendar-plus-bold",
  "ph:calendar-plus-duotone",
  "ph:calendar-plus-fill",
  "ph:calendar-plus-light",
  "ph:calendar-plus-thin",
  "ph:calendar-plus",
  "ph:calendar-thin",
  "ph:calendar-x-bold",
  "ph:calendar-x-duotone",
  "ph:calendar-x-fill",
  "ph:calendar-x-light",
  "ph:calendar-x-thin",
  "ph:calendar-x",
  "ph:calendar",
  "ph:call-bell-bold",
  "ph:call-bell-duotone",
  "ph:call-bell-fill",
  "ph:call-bell-light",
  "ph:call-bell-thin",
  "ph:call-bell",
  "ph:camera-bold",
  "ph:camera-duotone",
  "ph:camera-fill",
  "ph:camera-light",
  "ph:camera-plus-bold",
  "ph:camera-plus-duotone",
  "ph:camera-plus-fill",
  "ph:camera-plus-light",
  "ph:camera-plus-thin",
  "ph:camera-plus",
  "ph:camera-rotate-bold",
  "ph:camera-rotate-duotone",
  "ph:camera-rotate-fill",
  "ph:camera-rotate-light",
  "ph:camera-rotate-thin",
  "ph:camera-rotate",
  "ph:camera-slash-bold",
  "ph:camera-slash-duotone",
  "ph:camera-slash-fill",
  "ph:camera-slash-light",
  "ph:camera-slash-thin",
  "ph:camera-slash",
  "ph:camera-thin",
  "ph:camera",
  "ph:campfire-bold",
  "ph:campfire-duotone",
  "ph:campfire-fill",
  "ph:campfire-light",
  "ph:campfire-thin",
  "ph:campfire",
  "ph:car-bold",
  "ph:car-duotone",
  "ph:car-fill",
  "ph:car-light",
  "ph:car-profile-bold",
  "ph:car-profile-duotone",
  "ph:car-profile-fill",
  "ph:car-profile-light",
  "ph:car-profile-thin",
  "ph:car-profile",
  "ph:car-simple-bold",
  "ph:car-simple-duotone",
  "ph:car-simple-fill",
  "ph:car-simple-light",
  "ph:car-simple-thin",
  "ph:car-simple",
  "ph:car-thin",
  "ph:car",
  "ph:cardholder-bold",
  "ph:cardholder-duotone",
  "ph:cardholder-fill",
  "ph:cardholder-light",
  "ph:cardholder-thin",
  "ph:cardholder",
  "ph:cards-bold",
  "ph:cards-duotone",
  "ph:cards-fill",
  "ph:cards-light",
  "ph:cards-thin",
  "ph:cards",
  "ph:caret-circle-double-down-bold",
  "ph:caret-circle-double-down-duotone",
  "ph:caret-circle-double-down-fill",
  "ph:caret-circle-double-down-light",
  "ph:caret-circle-double-down-thin",
  "ph:caret-circle-double-down",
  "ph:caret-circle-double-left-bold",
  "ph:caret-circle-double-left-duotone",
  "ph:caret-circle-double-left-fill",
  "ph:caret-circle-double-left-light",
  "ph:caret-circle-double-left-thin",
  "ph:caret-circle-double-left",
  "ph:caret-circle-double-right-bold",
  "ph:caret-circle-double-right-duotone",
  "ph:caret-circle-double-right-fill",
  "ph:caret-circle-double-right-light",
  "ph:caret-circle-double-right-thin",
  "ph:caret-circle-double-right",
  "ph:caret-circle-double-up-bold",
  "ph:caret-circle-double-up-duotone",
  "ph:caret-circle-double-up-fill",
  "ph:caret-circle-double-up-light",
  "ph:caret-circle-double-up-thin",
  "ph:caret-circle-double-up",
  "ph:caret-circle-down-bold",
  "ph:caret-circle-down-duotone",
  "ph:caret-circle-down-fill",
  "ph:caret-circle-down-light",
  "ph:caret-circle-down-thin",
  "ph:caret-circle-down",
  "ph:caret-circle-left-bold",
  "ph:caret-circle-left-duotone",
  "ph:caret-circle-left-fill",
  "ph:caret-circle-left-light",
  "ph:caret-circle-left-thin",
  "ph:caret-circle-left",
  "ph:caret-circle-right-bold",
  "ph:caret-circle-right-duotone",
  "ph:caret-circle-right-fill",
  "ph:caret-circle-right-light",
  "ph:caret-circle-right-thin",
  "ph:caret-circle-right",
  "ph:caret-circle-up-bold",
  "ph:caret-circle-up-down-bold",
  "ph:caret-circle-up-down-duotone",
  "ph:caret-circle-up-down-fill",
  "ph:caret-circle-up-down-light",
  "ph:caret-circle-up-down-thin",
  "ph:caret-circle-up-down",
  "ph:caret-circle-up-duotone",
  "ph:caret-circle-up-fill",
  "ph:caret-circle-up-light",
  "ph:caret-circle-up-thin",
  "ph:caret-circle-up",
  "ph:caret-double-down-bold",
  "ph:caret-double-down-duotone",
  "ph:caret-double-down-fill",
  "ph:caret-double-down-light",
  "ph:caret-double-down-thin",
  "ph:caret-double-down",
  "ph:caret-double-left-bold",
  "ph:caret-double-left-duotone",
  "ph:caret-double-left-fill",
  "ph:caret-double-left-light",
  "ph:caret-double-left-thin",
  "ph:caret-double-left",
  "ph:caret-double-right-bold",
  "ph:caret-double-right-duotone",
  "ph:caret-double-right-fill",
  "ph:caret-double-right-light",
  "ph:caret-double-right-thin",
  "ph:caret-double-right",
  "ph:caret-double-up-bold",
  "ph:caret-double-up-duotone",
  "ph:caret-double-up-fill",
  "ph:caret-double-up-light",
  "ph:caret-double-up-thin",
  "ph:caret-double-up",
  "ph:caret-down-bold",
  "ph:caret-down-duotone",
  "ph:caret-down-fill",
  "ph:caret-down-light",
  "ph:caret-down-thin",
  "ph:caret-down",
  "ph:caret-left-bold",
  "ph:caret-left-duotone",
  "ph:caret-left-fill",
  "ph:caret-left-light",
  "ph:caret-left-thin",
  "ph:caret-left",
  "ph:caret-right-bold",
  "ph:caret-right-duotone",
  "ph:caret-right-fill",
  "ph:caret-right-light",
  "ph:caret-right-thin",
  "ph:caret-right",
  "ph:caret-up-bold",
  "ph:caret-up-down-bold",
  "ph:caret-up-down-duotone",
  "ph:caret-up-down-fill",
  "ph:caret-up-down-light",
  "ph:caret-up-down-thin",
  "ph:caret-up-down",
  "ph:caret-up-duotone",
  "ph:caret-up-fill",
  "ph:caret-up-light",
  "ph:caret-up-thin",
  "ph:caret-up",
  "ph:carrot-bold",
  "ph:carrot-duotone",
  "ph:carrot-fill",
  "ph:carrot-light",
  "ph:carrot-thin",
  "ph:carrot",
  "ph:cassette-tape-bold",
  "ph:cassette-tape-duotone",
  "ph:cassette-tape-fill",
  "ph:cassette-tape-light",
  "ph:cassette-tape-thin",
  "ph:cassette-tape",
  "ph:castle-turret-bold",
  "ph:castle-turret-duotone",
  "ph:castle-turret-fill",
  "ph:castle-turret-light",
  "ph:castle-turret-thin",
  "ph:castle-turret",
  "ph:cat-bold",
  "ph:cat-duotone",
  "ph:cat-fill",
  "ph:cat-light",
  "ph:cat-thin",
  "ph:cat",
  "ph:cell-signal-full-bold",
  "ph:cell-signal-full-duotone",
  "ph:cell-signal-full-fill",
  "ph:cell-signal-full-light",
  "ph:cell-signal-full-thin",
  "ph:cell-signal-full",
  "ph:cell-signal-high-bold",
  "ph:cell-signal-high-duotone",
  "ph:cell-signal-high-fill",
  "ph:cell-signal-high-light",
  "ph:cell-signal-high-thin",
  "ph:cell-signal-high",
  "ph:cell-signal-low-bold",
  "ph:cell-signal-low-duotone",
  "ph:cell-signal-low-fill",
  "ph:cell-signal-low-light",
  "ph:cell-signal-low-thin",
  "ph:cell-signal-low",
  "ph:cell-signal-medium-bold",
  "ph:cell-signal-medium-duotone",
  "ph:cell-signal-medium-fill",
  "ph:cell-signal-medium-light",
  "ph:cell-signal-medium-thin",
  "ph:cell-signal-medium",
  "ph:cell-signal-none-bold",
  "ph:cell-signal-none-duotone",
  "ph:cell-signal-none-fill",
  "ph:cell-signal-none-light",
  "ph:cell-signal-none-thin",
  "ph:cell-signal-none",
  "ph:cell-signal-slash-bold",
  "ph:cell-signal-slash-duotone",
  "ph:cell-signal-slash-fill",
  "ph:cell-signal-slash-light",
  "ph:cell-signal-slash-thin",
  "ph:cell-signal-slash",
  "ph:cell-signal-x-bold",
  "ph:cell-signal-x-duotone",
  "ph:cell-signal-x-fill",
  "ph:cell-signal-x-light",
  "ph:cell-signal-x-thin",
  "ph:cell-signal-x",
  "ph:certificate-bold",
  "ph:certificate-duotone",
  "ph:certificate-fill",
  "ph:certificate-light",
  "ph:certificate-thin",
  "ph:certificate",
  "ph:chair-bold",
  "ph:chair-duotone",
  "ph:chair-fill",
  "ph:chair-light",
  "ph:chair-thin",
  "ph:chair",
  "ph:chalkboard-bold",
  "ph:chalkboard-duotone",
  "ph:chalkboard-fill",
  "ph:chalkboard-light",
  "ph:chalkboard-simple-bold",
  "ph:chalkboard-simple-duotone",
  "ph:chalkboard-simple-fill",
  "ph:chalkboard-simple-light",
  "ph:chalkboard-simple-thin",
  "ph:chalkboard-simple",
  "ph:chalkboard-teacher-bold",
  "ph:chalkboard-teacher-duotone",
  "ph:chalkboard-teacher-fill",
  "ph:chalkboard-teacher-light",
  "ph:chalkboard-teacher-thin",
  "ph:chalkboard-teacher",
  "ph:chalkboard-thin",
  "ph:chalkboard",
  "ph:champagne-bold",
  "ph:champagne-duotone",
  "ph:champagne-fill",
  "ph:champagne-light",
  "ph:champagne-thin",
  "ph:champagne",
  "ph:charging-station-bold",
  "ph:charging-station-duotone",
  "ph:charging-station-fill",
  "ph:charging-station-light",
  "ph:charging-station-thin",
  "ph:charging-station",
  "ph:chart-bar-bold",
  "ph:chart-bar-duotone",
  "ph:chart-bar-fill",
  "ph:chart-bar-horizontal-bold",
  "ph:chart-bar-horizontal-duotone",
  "ph:chart-bar-horizontal-fill",
  "ph:chart-bar-horizontal-light",
  "ph:chart-bar-horizontal-thin",
  "ph:chart-bar-horizontal",
  "ph:chart-bar-light",
  "ph:chart-bar-thin",
  "ph:chart-bar",
  "ph:chart-donut-bold",
  "ph:chart-donut-duotone",
  "ph:chart-donut-fill",
  "ph:chart-donut-light",
  "ph:chart-donut-thin",
  "ph:chart-donut",
  "ph:chart-line-bold",
  "ph:chart-line-down-bold",
  "ph:chart-line-down-duotone",
  "ph:chart-line-down-fill",
  "ph:chart-line-down-light",
  "ph:chart-line-down-thin",
  "ph:chart-line-down",
  "ph:chart-line-duotone",
  "ph:chart-line-fill",
  "ph:chart-line-light",
  "ph:chart-line-thin",
  "ph:chart-line-up-bold",
  "ph:chart-line-up-duotone",
  "ph:chart-line-up-fill",
  "ph:chart-line-up-light",
  "ph:chart-line-up-thin",
  "ph:chart-line-up",
  "ph:chart-line",
  "ph:chart-pie-bold",
  "ph:chart-pie-duotone",
  "ph:chart-pie-fill",
  "ph:chart-pie-light",
  "ph:chart-pie-slice-bold",
  "ph:chart-pie-slice-duotone",
  "ph:chart-pie-slice-fill",
  "ph:chart-pie-slice-light",
  "ph:chart-pie-slice-thin",
  "ph:chart-pie-slice",
  "ph:chart-pie-thin",
  "ph:chart-pie",
  "ph:chart-polar-bold",
  "ph:chart-polar-duotone",
  "ph:chart-polar-fill",
  "ph:chart-polar-light",
  "ph:chart-polar-thin",
  "ph:chart-polar",
  "ph:chart-scatter-bold",
  "ph:chart-scatter-duotone",
  "ph:chart-scatter-fill",
  "ph:chart-scatter-light",
  "ph:chart-scatter-thin",
  "ph:chart-scatter",
  "ph:chat-bold",
  "ph:chat-centered-bold",
  "ph:chat-centered-dots-bold",
  "ph:chat-centered-dots-duotone",
  "ph:chat-centered-dots-fill",
  "ph:chat-centered-dots-light",
  "ph:chat-centered-dots-thin",
  "ph:chat-centered-dots",
  "ph:chat-centered-duotone",
  "ph:chat-centered-fill",
  "ph:chat-centered-light",
  "ph:chat-centered-text-bold",
  "ph:chat-centered-text-duotone",
  "ph:chat-centered-text-fill",
  "ph:chat-centered-text-light",
  "ph:chat-centered-text-thin",
  "ph:chat-centered-text",
  "ph:chat-centered-thin",
  "ph:chat-centered",
  "ph:chat-circle-bold",
  "ph:chat-circle-dots-bold",
  "ph:chat-circle-dots-duotone",
  "ph:chat-circle-dots-fill",
  "ph:chat-circle-dots-light",
  "ph:chat-circle-dots-thin",
  "ph:chat-circle-dots",
  "ph:chat-circle-duotone",
  "ph:chat-circle-fill",
  "ph:chat-circle-light",
  "ph:chat-circle-text-bold",
  "ph:chat-circle-text-duotone",
  "ph:chat-circle-text-fill",
  "ph:chat-circle-text-light",
  "ph:chat-circle-text-thin",
  "ph:chat-circle-text",
  "ph:chat-circle-thin",
  "ph:chat-circle",
  "ph:chat-dots-bold",
  "ph:chat-dots-duotone",
  "ph:chat-dots-fill",
  "ph:chat-dots-light",
  "ph:chat-dots-thin",
  "ph:chat-dots",
  "ph:chat-duotone",
  "ph:chat-fill",
  "ph:chat-light",
  "ph:chat-teardrop-bold",
  "ph:chat-teardrop-dots-bold",
  "ph:chat-teardrop-dots-duotone",
  "ph:chat-teardrop-dots-fill",
  "ph:chat-teardrop-dots-light",
  "ph:chat-teardrop-dots-thin",
  "ph:chat-teardrop-dots",
  "ph:chat-teardrop-duotone",
  "ph:chat-teardrop-fill",
  "ph:chat-teardrop-light",
  "ph:chat-teardrop-text-bold",
  "ph:chat-teardrop-text-duotone",
  "ph:chat-teardrop-text-fill",
  "ph:chat-teardrop-text-light",
  "ph:chat-teardrop-text-thin",
  "ph:chat-teardrop-text",
  "ph:chat-teardrop-thin",
  "ph:chat-teardrop",
  "ph:chat-text-bold",
  "ph:chat-text-duotone",
  "ph:chat-text-fill",
  "ph:chat-text-light",
  "ph:chat-text-thin",
  "ph:chat-text",
  "ph:chat-thin",
  "ph:chat",
  "ph:chats-bold",
  "ph:chats-circle-bold",
  "ph:chats-circle-duotone",
  "ph:chats-circle-fill",
  "ph:chats-circle-light",
  "ph:chats-circle-thin",
  "ph:chats-circle",
  "ph:chats-duotone",
  "ph:chats-fill",
  "ph:chats-light",
  "ph:chats-teardrop-bold",
  "ph:chats-teardrop-duotone",
  "ph:chats-teardrop-fill",
  "ph:chats-teardrop-light",
  "ph:chats-teardrop-thin",
  "ph:chats-teardrop",
  "ph:chats-thin",
  "ph:chats",
  "ph:check-bold",
  "ph:check-circle-bold",
  "ph:check-circle-duotone",
  "ph:check-circle-fill",
  "ph:check-circle-light",
  "ph:check-circle-thin",
  "ph:check-circle",
  "ph:check-duotone",
  "ph:check-fat-bold",
  "ph:check-fat-duotone",
  "ph:check-fat-fill",
  "ph:check-fat-light",
  "ph:check-fat-thin",
  "ph:check-fat",
  "ph:check-fill",
  "ph:check-light",
  "ph:check-square-bold",
  "ph:check-square-duotone",
  "ph:check-square-fill",
  "ph:check-square-light",
  "ph:check-square-offset-bold",
  "ph:check-square-offset-duotone",
  "ph:check-square-offset-fill",
  "ph:check-square-offset-light",
  "ph:check-square-offset-thin",
  "ph:check-square-offset",
  "ph:check-square-thin",
  "ph:check-square",
  "ph:check-thin",
  "ph:check",
  "ph:checks-bold",
  "ph:checks-duotone",
  "ph:checks-fill",
  "ph:checks-light",
  "ph:checks-thin",
  "ph:checks",
  "ph:church-bold",
  "ph:church-duotone",
  "ph:church-fill",
  "ph:church-light",
  "ph:church-thin",
  "ph:church",
  "ph:circle-bold",
  "ph:circle-dashed-bold",
  "ph:circle-dashed-duotone",
  "ph:circle-dashed-fill",
  "ph:circle-dashed-light",
  "ph:circle-dashed-thin",
  "ph:circle-dashed",
  "ph:circle-duotone",
  "ph:circle-fill",
  "ph:circle-half-bold",
  "ph:circle-half-duotone",
  "ph:circle-half-fill",
  "ph:circle-half-light",
  "ph:circle-half-thin",
  "ph:circle-half-tilt-bold",
  "ph:circle-half-tilt-duotone",
  "ph:circle-half-tilt-fill",
  "ph:circle-half-tilt-light",
  "ph:circle-half-tilt-thin",
  "ph:circle-half-tilt",
  "ph:circle-half",
  "ph:circle-light",
  "ph:circle-notch-bold",
  "ph:circle-notch-duotone",
  "ph:circle-notch-fill",
  "ph:circle-notch-light",
  "ph:circle-notch-thin",
  "ph:circle-notch",
  "ph:circle-thin",
  "ph:circle-wavy-bold",
  "ph:circle-wavy-check-bold",
  "ph:circle-wavy-check-duotone",
  "ph:circle-wavy-check-fill",
  "ph:circle-wavy-check-light",
  "ph:circle-wavy-check-thin",
  "ph:circle-wavy-check",
  "ph:circle-wavy-duotone",
  "ph:circle-wavy-fill",
  "ph:circle-wavy-light",
  "ph:circle-wavy-question-bold",
  "ph:circle-wavy-question-duotone",
  "ph:circle-wavy-question-fill",
  "ph:circle-wavy-question-light",
  "ph:circle-wavy-question-thin",
  "ph:circle-wavy-question",
  "ph:circle-wavy-thin",
  "ph:circle-wavy-warning-bold",
  "ph:circle-wavy-warning-duotone",
  "ph:circle-wavy-warning-fill",
  "ph:circle-wavy-warning-light",
  "ph:circle-wavy-warning-thin",
  "ph:circle-wavy-warning",
  "ph:circle-wavy",
  "ph:circle",
  "ph:circles-four-bold",
  "ph:circles-four-duotone",
  "ph:circles-four-fill",
  "ph:circles-four-light",
  "ph:circles-four-thin",
  "ph:circles-four",
  "ph:circles-three-bold",
  "ph:circles-three-duotone",
  "ph:circles-three-fill",
  "ph:circles-three-light",
  "ph:circles-three-plus-bold",
  "ph:circles-three-plus-duotone",
  "ph:circles-three-plus-fill",
  "ph:circles-three-plus-light",
  "ph:circles-three-plus-thin",
  "ph:circles-three-plus",
  "ph:circles-three-thin",
  "ph:circles-three",
  "ph:circuitry-bold",
  "ph:circuitry-duotone",
  "ph:circuitry-fill",
  "ph:circuitry-light",
  "ph:circuitry-thin",
  "ph:circuitry",
  "ph:clipboard-bold",
  "ph:clipboard-duotone",
  "ph:clipboard-fill",
  "ph:clipboard-light",
  "ph:clipboard-text-bold",
  "ph:clipboard-text-duotone",
  "ph:clipboard-text-fill",
  "ph:clipboard-text-light",
  "ph:clipboard-text-thin",
  "ph:clipboard-text",
  "ph:clipboard-thin",
  "ph:clipboard",
  "ph:clock-afternoon-bold",
  "ph:clock-afternoon-duotone",
  "ph:clock-afternoon-fill",
  "ph:clock-afternoon-light",
  "ph:clock-afternoon-thin",
  "ph:clock-afternoon",
  "ph:clock-bold",
  "ph:clock-clockwise-bold",
  "ph:clock-clockwise-duotone",
  "ph:clock-clockwise-fill",
  "ph:clock-clockwise-light",
  "ph:clock-clockwise-thin",
  "ph:clock-clockwise",
  "ph:clock-countdown-bold",
  "ph:clock-countdown-duotone",
  "ph:clock-countdown-fill",
  "ph:clock-countdown-light",
  "ph:clock-countdown-thin",
  "ph:clock-countdown",
  "ph:clock-counter-clockwise-bold",
  "ph:clock-counter-clockwise-duotone",
  "ph:clock-counter-clockwise-fill",
  "ph:clock-counter-clockwise-light",
  "ph:clock-counter-clockwise-thin",
  "ph:clock-counter-clockwise",
  "ph:clock-duotone",
  "ph:clock-fill",
  "ph:clock-light",
  "ph:clock-thin",
  "ph:clock",
  "ph:closed-captioning-bold",
  "ph:closed-captioning-duotone",
  "ph:closed-captioning-fill",
  "ph:closed-captioning-light",
  "ph:closed-captioning-thin",
  "ph:closed-captioning",
  "ph:cloud-arrow-down-bold",
  "ph:cloud-arrow-down-duotone",
  "ph:cloud-arrow-down-fill",
  "ph:cloud-arrow-down-light",
  "ph:cloud-arrow-down-thin",
  "ph:cloud-arrow-down",
  "ph:cloud-arrow-up-bold",
  "ph:cloud-arrow-up-duotone",
  "ph:cloud-arrow-up-fill",
  "ph:cloud-arrow-up-light",
  "ph:cloud-arrow-up-thin",
  "ph:cloud-arrow-up",
  "ph:cloud-bold",
  "ph:cloud-check-bold",
  "ph:cloud-check-duotone",
  "ph:cloud-check-fill",
  "ph:cloud-check-light",
  "ph:cloud-check-thin",
  "ph:cloud-check",
  "ph:cloud-duotone",
  "ph:cloud-fill",
  "ph:cloud-fog-bold",
  "ph:cloud-fog-duotone",
  "ph:cloud-fog-fill",
  "ph:cloud-fog-light",
  "ph:cloud-fog-thin",
  "ph:cloud-fog",
  "ph:cloud-light",
  "ph:cloud-lightning-bold",
  "ph:cloud-lightning-duotone",
  "ph:cloud-lightning-fill",
  "ph:cloud-lightning-light",
  "ph:cloud-lightning-thin",
  "ph:cloud-lightning",
  "ph:cloud-moon-bold",
  "ph:cloud-moon-duotone",
  "ph:cloud-moon-fill",
  "ph:cloud-moon-light",
  "ph:cloud-moon-thin",
  "ph:cloud-moon",
  "ph:cloud-rain-bold",
  "ph:cloud-rain-duotone",
  "ph:cloud-rain-fill",
  "ph:cloud-rain-light",
  "ph:cloud-rain-thin",
  "ph:cloud-rain",
  "ph:cloud-slash-bold",
  "ph:cloud-slash-duotone",
  "ph:cloud-slash-fill",
  "ph:cloud-slash-light",
  "ph:cloud-slash-thin",
  "ph:cloud-slash",
  "ph:cloud-snow-bold",
  "ph:cloud-snow-duotone",
  "ph:cloud-snow-fill",
  "ph:cloud-snow-light",
  "ph:cloud-snow-thin",
  "ph:cloud-snow",
  "ph:cloud-sun-bold",
  "ph:cloud-sun-duotone",
  "ph:cloud-sun-fill",
  "ph:cloud-sun-light",
  "ph:cloud-sun-thin",
  "ph:cloud-sun",
  "ph:cloud-thin",
  "ph:cloud-warning-bold",
  "ph:cloud-warning-duotone",
  "ph:cloud-warning-fill",
  "ph:cloud-warning-light",
  "ph:cloud-warning-thin",
  "ph:cloud-warning",
  "ph:cloud-x-bold",
  "ph:cloud-x-duotone",
  "ph:cloud-x-fill",
  "ph:cloud-x-light",
  "ph:cloud-x-thin",
  "ph:cloud-x",
  "ph:cloud",
  "ph:club-bold",
  "ph:club-duotone",
  "ph:club-fill",
  "ph:club-light",
  "ph:club-thin",
  "ph:club",
  "ph:coat-hanger-bold",
  "ph:coat-hanger-duotone",
  "ph:coat-hanger-fill",
  "ph:coat-hanger-light",
  "ph:coat-hanger-thin",
  "ph:coat-hanger",
  "ph:coda-logo-bold",
  "ph:coda-logo-duotone",
  "ph:coda-logo-fill",
  "ph:coda-logo-light",
  "ph:coda-logo-thin",
  "ph:coda-logo",
  "ph:code-block-bold",
  "ph:code-block-duotone",
  "ph:code-block-fill",
  "ph:code-block-light",
  "ph:code-block-thin",
  "ph:code-block",
  "ph:code-bold",
  "ph:code-duotone",
  "ph:code-fill",
  "ph:code-light",
  "ph:code-simple-bold",
  "ph:code-simple-duotone",
  "ph:code-simple-fill",
  "ph:code-simple-light",
  "ph:code-simple-thin",
  "ph:code-simple",
  "ph:code-thin",
  "ph:code",
  "ph:codepen-logo-bold",
  "ph:codepen-logo-duotone",
  "ph:codepen-logo-fill",
  "ph:codepen-logo-light",
  "ph:codepen-logo-thin",
  "ph:codepen-logo",
  "ph:codesandbox-logo-bold",
  "ph:codesandbox-logo-duotone",
  "ph:codesandbox-logo-fill",
  "ph:codesandbox-logo-light",
  "ph:codesandbox-logo-thin",
  "ph:codesandbox-logo",
  "ph:coffee-bold",
  "ph:coffee-duotone",
  "ph:coffee-fill",
  "ph:coffee-light",
  "ph:coffee-thin",
  "ph:coffee",
  "ph:coin-bold",
  "ph:coin-duotone",
  "ph:coin-fill",
  "ph:coin-light",
  "ph:coin-thin",
  "ph:coin-vertical-bold",
  "ph:coin-vertical-duotone",
  "ph:coin-vertical-fill",
  "ph:coin-vertical-light",
  "ph:coin-vertical-thin",
  "ph:coin-vertical",
  "ph:coin",
  "ph:coins-bold",
  "ph:coins-duotone",
  "ph:coins-fill",
  "ph:coins-light",
  "ph:coins-thin",
  "ph:coins",
  "ph:columns-bold",
  "ph:columns-duotone",
  "ph:columns-fill",
  "ph:columns-light",
  "ph:columns-thin",
  "ph:columns",
  "ph:command-bold",
  "ph:command-duotone",
  "ph:command-fill",
  "ph:command-light",
  "ph:command-thin",
  "ph:command",
  "ph:compass-bold",
  "ph:compass-duotone",
  "ph:compass-fill",
  "ph:compass-light",
  "ph:compass-thin",
  "ph:compass-tool-bold",
  "ph:compass-tool-duotone",
  "ph:compass-tool-fill",
  "ph:compass-tool-light",
  "ph:compass-tool-thin",
  "ph:compass-tool",
  "ph:compass",
  "ph:computer-tower-bold",
  "ph:computer-tower-duotone",
  "ph:computer-tower-fill",
  "ph:computer-tower-light",
  "ph:computer-tower-thin",
  "ph:computer-tower",
  "ph:confetti-bold",
  "ph:confetti-duotone",
  "ph:confetti-fill",
  "ph:confetti-light",
  "ph:confetti-thin",
  "ph:confetti",
  "ph:contactless-payment-bold",
  "ph:contactless-payment-duotone",
  "ph:contactless-payment-fill",
  "ph:contactless-payment-light",
  "ph:contactless-payment-thin",
  "ph:contactless-payment",
  "ph:control-bold",
  "ph:control-duotone",
  "ph:control-fill",
  "ph:control-light",
  "ph:control-thin",
  "ph:control",
  "ph:cookie-bold",
  "ph:cookie-duotone",
  "ph:cookie-fill",
  "ph:cookie-light",
  "ph:cookie-thin",
  "ph:cookie",
  "ph:cooking-pot-bold",
  "ph:cooking-pot-duotone",
  "ph:cooking-pot-fill",
  "ph:cooking-pot-light",
  "ph:cooking-pot-thin",
  "ph:cooking-pot",
  "ph:copy-bold",
  "ph:copy-duotone",
  "ph:copy-fill",
  "ph:copy-light",
  "ph:copy-simple-bold",
  "ph:copy-simple-duotone",
  "ph:copy-simple-fill",
  "ph:copy-simple-light",
  "ph:copy-simple-thin",
  "ph:copy-simple",
  "ph:copy-thin",
  "ph:copy",
  "ph:copyleft-bold",
  "ph:copyleft-duotone",
  "ph:copyleft-fill",
  "ph:copyleft-light",
  "ph:copyleft-thin",
  "ph:copyleft",
  "ph:copyright-bold",
  "ph:copyright-duotone",
  "ph:copyright-fill",
  "ph:copyright-light",
  "ph:copyright-thin",
  "ph:copyright",
  "ph:corners-in-bold",
  "ph:corners-in-duotone",
  "ph:corners-in-fill",
  "ph:corners-in-light",
  "ph:corners-in-thin",
  "ph:corners-in",
  "ph:corners-out-bold",
  "ph:corners-out-duotone",
  "ph:corners-out-fill",
  "ph:corners-out-light",
  "ph:corners-out-thin",
  "ph:corners-out",
  "ph:couch-bold",
  "ph:couch-duotone",
  "ph:couch-fill",
  "ph:couch-light",
  "ph:couch-thin",
  "ph:couch",
  "ph:cpu-bold",
  "ph:cpu-duotone",
  "ph:cpu-fill",
  "ph:cpu-light",
  "ph:cpu-thin",
  "ph:cpu",
  "ph:credit-card-bold",
  "ph:credit-card-duotone",
  "ph:credit-card-fill",
  "ph:credit-card-light",
  "ph:credit-card-thin",
  "ph:credit-card",
  "ph:crop-bold",
  "ph:crop-duotone",
  "ph:crop-fill",
  "ph:crop-light",
  "ph:crop-thin",
  "ph:crop",
  "ph:cross-bold",
  "ph:cross-duotone",
  "ph:cross-fill",
  "ph:cross-light",
  "ph:cross-thin",
  "ph:cross",
  "ph:crosshair-bold",
  "ph:crosshair-duotone",
  "ph:crosshair-fill",
  "ph:crosshair-light",
  "ph:crosshair-simple-bold",
  "ph:crosshair-simple-duotone",
  "ph:crosshair-simple-fill",
  "ph:crosshair-simple-light",
  "ph:crosshair-simple-thin",
  "ph:crosshair-simple",
  "ph:crosshair-thin",
  "ph:crosshair",
  "ph:crown-bold",
  "ph:crown-duotone",
  "ph:crown-fill",
  "ph:crown-light",
  "ph:crown-simple-bold",
  "ph:crown-simple-duotone",
  "ph:crown-simple-fill",
  "ph:crown-simple-light",
  "ph:crown-simple-thin",
  "ph:crown-simple",
  "ph:crown-thin",
  "ph:crown",
  "ph:cube-bold",
  "ph:cube-duotone",
  "ph:cube-fill",
  "ph:cube-focus-bold",
  "ph:cube-focus-duotone",
  "ph:cube-focus-fill",
  "ph:cube-focus-light",
  "ph:cube-focus-thin",
  "ph:cube-focus",
  "ph:cube-light",
  "ph:cube-thin",
  "ph:cube-transparent-bold",
  "ph:cube-transparent-duotone",
  "ph:cube-transparent-fill",
  "ph:cube-transparent-light",
  "ph:cube-transparent-thin",
  "ph:cube-transparent",
  "ph:cube",
  "ph:currency-btc-bold",
  "ph:currency-btc-duotone",
  "ph:currency-btc-fill",
  "ph:currency-btc-light",
  "ph:currency-btc-thin",
  "ph:currency-btc",
  "ph:currency-circle-dollar-bold",
  "ph:currency-circle-dollar-duotone",
  "ph:currency-circle-dollar-fill",
  "ph:currency-circle-dollar-light",
  "ph:currency-circle-dollar-thin",
  "ph:currency-circle-dollar",
  "ph:currency-cny-bold",
  "ph:currency-cny-duotone",
  "ph:currency-cny-fill",
  "ph:currency-cny-light",
  "ph:currency-cny-thin",
  "ph:currency-cny",
  "ph:currency-dollar-bold",
  "ph:currency-dollar-duotone",
  "ph:currency-dollar-fill",
  "ph:currency-dollar-light",
  "ph:currency-dollar-simple-bold",
  "ph:currency-dollar-simple-duotone",
  "ph:currency-dollar-simple-fill",
  "ph:currency-dollar-simple-light",
  "ph:currency-dollar-simple-thin",
  "ph:currency-dollar-simple",
  "ph:currency-dollar-thin",
  "ph:currency-dollar",
  "ph:currency-eth-bold",
  "ph:currency-eth-duotone",
  "ph:currency-eth-fill",
  "ph:currency-eth-light",
  "ph:currency-eth-thin",
  "ph:currency-eth",
  "ph:currency-eur-bold",
  "ph:currency-eur-duotone",
  "ph:currency-eur-fill",
  "ph:currency-eur-light",
  "ph:currency-eur-thin",
  "ph:currency-eur",
  "ph:currency-gbp-bold",
  "ph:currency-gbp-duotone",
  "ph:currency-gbp-fill",
  "ph:currency-gbp-light",
  "ph:currency-gbp-thin",
  "ph:currency-gbp",
  "ph:currency-inr-bold",
  "ph:currency-inr-duotone",
  "ph:currency-inr-fill",
  "ph:currency-inr-light",
  "ph:currency-inr-thin",
  "ph:currency-inr",
  "ph:currency-jpy-bold",
  "ph:currency-jpy-duotone",
  "ph:currency-jpy-fill",
  "ph:currency-jpy-light",
  "ph:currency-jpy-thin",
  "ph:currency-jpy",
  "ph:currency-krw-bold",
  "ph:currency-krw-duotone",
  "ph:currency-krw-fill",
  "ph:currency-krw-light",
  "ph:currency-krw-thin",
  "ph:currency-krw",
  "ph:currency-kzt-bold",
  "ph:currency-kzt-duotone",
  "ph:currency-kzt-fill",
  "ph:currency-kzt-light",
  "ph:currency-kzt-thin",
  "ph:currency-kzt",
  "ph:currency-ngn-bold",
  "ph:currency-ngn-duotone",
  "ph:currency-ngn-fill",
  "ph:currency-ngn-light",
  "ph:currency-ngn-thin",
  "ph:currency-ngn",
  "ph:currency-rub-bold",
  "ph:currency-rub-duotone",
  "ph:currency-rub-fill",
  "ph:currency-rub-light",
  "ph:currency-rub-thin",
  "ph:currency-rub",
  "ph:cursor-bold",
  "ph:cursor-click-bold",
  "ph:cursor-click-duotone",
  "ph:cursor-click-fill",
  "ph:cursor-click-light",
  "ph:cursor-click-thin",
  "ph:cursor-click",
  "ph:cursor-duotone",
  "ph:cursor-fill",
  "ph:cursor-light",
  "ph:cursor-text-bold",
  "ph:cursor-text-duotone",
  "ph:cursor-text-fill",
  "ph:cursor-text-light",
  "ph:cursor-text-thin",
  "ph:cursor-text",
  "ph:cursor-thin",
  "ph:cursor",
  "ph:cylinder-bold",
  "ph:cylinder-duotone",
  "ph:cylinder-fill",
  "ph:cylinder-light",
  "ph:cylinder-thin",
  "ph:cylinder",
  "ph:database-bold",
  "ph:database-duotone",
  "ph:database-fill",
  "ph:database-light",
  "ph:database-thin",
  "ph:database",
  "ph:desktop-bold",
  "ph:desktop-duotone",
  "ph:desktop-fill",
  "ph:desktop-light",
  "ph:desktop-thin",
  "ph:desktop-tower-bold",
  "ph:desktop-tower-duotone",
  "ph:desktop-tower-fill",
  "ph:desktop-tower-light",
  "ph:desktop-tower-thin",
  "ph:desktop-tower",
  "ph:desktop",
  "ph:detective-bold",
  "ph:detective-duotone",
  "ph:detective-fill",
  "ph:detective-light",
  "ph:detective-thin",
  "ph:detective",
  "ph:dev-to-logo-bold",
  "ph:dev-to-logo-duotone",
  "ph:dev-to-logo-fill",
  "ph:dev-to-logo-light",
  "ph:dev-to-logo-thin",
  "ph:dev-to-logo",
  "ph:device-mobile-bold",
  "ph:device-mobile-camera-bold",
  "ph:device-mobile-camera-duotone",
  "ph:device-mobile-camera-fill",
  "ph:device-mobile-camera-light",
  "ph:device-mobile-camera-thin",
  "ph:device-mobile-camera",
  "ph:device-mobile-duotone",
  "ph:device-mobile-fill",
  "ph:device-mobile-light",
  "ph:device-mobile-speaker-bold",
  "ph:device-mobile-speaker-duotone",
  "ph:device-mobile-speaker-fill",
  "ph:device-mobile-speaker-light",
  "ph:device-mobile-speaker-thin",
  "ph:device-mobile-speaker",
  "ph:device-mobile-thin",
  "ph:device-mobile",
  "ph:device-tablet-bold",
  "ph:device-tablet-camera-bold",
  "ph:device-tablet-camera-duotone",
  "ph:device-tablet-camera-fill",
  "ph:device-tablet-camera-light",
  "ph:device-tablet-camera-thin",
  "ph:device-tablet-camera",
  "ph:device-tablet-duotone",
  "ph:device-tablet-fill",
  "ph:device-tablet-light",
  "ph:device-tablet-speaker-bold",
  "ph:device-tablet-speaker-duotone",
  "ph:device-tablet-speaker-fill",
  "ph:device-tablet-speaker-light",
  "ph:device-tablet-speaker-thin",
  "ph:device-tablet-speaker",
  "ph:device-tablet-thin",
  "ph:device-tablet",
  "ph:devices-bold",
  "ph:devices-duotone",
  "ph:devices-fill",
  "ph:devices-light",
  "ph:devices-thin",
  "ph:devices",
  "ph:diamond-bold",
  "ph:diamond-duotone",
  "ph:diamond-fill",
  "ph:diamond-light",
  "ph:diamond-thin",
  "ph:diamond",
  "ph:diamonds-four-bold",
  "ph:diamonds-four-duotone",
  "ph:diamonds-four-fill",
  "ph:diamonds-four-light",
  "ph:diamonds-four-thin",
  "ph:diamonds-four",
  "ph:dice-five-bold",
  "ph:dice-five-duotone",
  "ph:dice-five-fill",
  "ph:dice-five-light",
  "ph:dice-five-thin",
  "ph:dice-five",
  "ph:dice-four-bold",
  "ph:dice-four-duotone",
  "ph:dice-four-fill",
  "ph:dice-four-light",
  "ph:dice-four-thin",
  "ph:dice-four",
  "ph:dice-one-bold",
  "ph:dice-one-duotone",
  "ph:dice-one-fill",
  "ph:dice-one-light",
  "ph:dice-one-thin",
  "ph:dice-one",
  "ph:dice-six-bold",
  "ph:dice-six-duotone",
  "ph:dice-six-fill",
  "ph:dice-six-light",
  "ph:dice-six-thin",
  "ph:dice-six",
  "ph:dice-three-bold",
  "ph:dice-three-duotone",
  "ph:dice-three-fill",
  "ph:dice-three-light",
  "ph:dice-three-thin",
  "ph:dice-three",
  "ph:dice-two-bold",
  "ph:dice-two-duotone",
  "ph:dice-two-fill",
  "ph:dice-two-light",
  "ph:dice-two-thin",
  "ph:dice-two",
  "ph:disc-bold",
  "ph:disc-duotone",
  "ph:disc-fill",
  "ph:disc-light",
  "ph:disc-thin",
  "ph:disc",
  "ph:discord-logo-bold",
  "ph:discord-logo-duotone",
  "ph:discord-logo-fill",
  "ph:discord-logo-light",
  "ph:discord-logo-thin",
  "ph:discord-logo",
  "ph:divide-bold",
  "ph:divide-duotone",
  "ph:divide-fill",
  "ph:divide-light",
  "ph:divide-thin",
  "ph:divide",
  "ph:dna-bold",
  "ph:dna-duotone",
  "ph:dna-fill",
  "ph:dna-light",
  "ph:dna-thin",
  "ph:dna",
  "ph:dog-bold",
  "ph:dog-duotone",
  "ph:dog-fill",
  "ph:dog-light",
  "ph:dog-thin",
  "ph:dog",
  "ph:door-bold",
  "ph:door-duotone",
  "ph:door-fill",
  "ph:door-light",
  "ph:door-open-bold",
  "ph:door-open-duotone",
  "ph:door-open-fill",
  "ph:door-open-light",
  "ph:door-open-thin",
  "ph:door-open",
  "ph:door-thin",
  "ph:door",
  "ph:dot-bold",
  "ph:dot-duotone",
  "ph:dot-fill",
  "ph:dot-light",
  "ph:dot-outline-bold",
  "ph:dot-outline-duotone",
  "ph:dot-outline-fill",
  "ph:dot-outline-light",
  "ph:dot-outline-thin",
  "ph:dot-outline",
  "ph:dot-thin",
  "ph:dot",
  "ph:dots-nine-bold",
  "ph:dots-nine-duotone",
  "ph:dots-nine-fill",
  "ph:dots-nine-light",
  "ph:dots-nine-thin",
  "ph:dots-nine",
  "ph:dots-six-bold",
  "ph:dots-six-duotone",
  "ph:dots-six-fill",
  "ph:dots-six-light",
  "ph:dots-six-thin",
  "ph:dots-six-vertical-bold",
  "ph:dots-six-vertical-duotone",
  "ph:dots-six-vertical-fill",
  "ph:dots-six-vertical-light",
  "ph:dots-six-vertical-thin",
  "ph:dots-six-vertical",
  "ph:dots-six",
  "ph:dots-three-bold",
  "ph:dots-three-circle-bold",
  "ph:dots-three-circle-duotone",
  "ph:dots-three-circle-fill",
  "ph:dots-three-circle-light",
  "ph:dots-three-circle-thin",
  "ph:dots-three-circle-vertical-bold",
  "ph:dots-three-circle-vertical-duotone",
  "ph:dots-three-circle-vertical-fill",
  "ph:dots-three-circle-vertical-light",
  "ph:dots-three-circle-vertical-thin",
  "ph:dots-three-circle-vertical",
  "ph:dots-three-circle",
  "ph:dots-three-duotone",
  "ph:dots-three-fill",
  "ph:dots-three-light",
  "ph:dots-three-outline-bold",
  "ph:dots-three-outline-duotone",
  "ph:dots-three-outline-fill",
  "ph:dots-three-outline-light",
  "ph:dots-three-outline-thin",
  "ph:dots-three-outline-vertical-bold",
  "ph:dots-three-outline-vertical-duotone",
  "ph:dots-three-outline-vertical-fill",
  "ph:dots-three-outline-vertical-light",
  "ph:dots-three-outline-vertical-thin",
  "ph:dots-three-outline-vertical",
  "ph:dots-three-outline",
  "ph:dots-three-thin",
  "ph:dots-three-vertical-bold",
  "ph:dots-three-vertical-duotone",
  "ph:dots-three-vertical-fill",
  "ph:dots-three-vertical-light",
  "ph:dots-three-vertical-thin",
  "ph:dots-three-vertical",
  "ph:dots-three",
  "ph:download-bold",
  "ph:download-duotone",
  "ph:download-fill",
  "ph:download-light",
  "ph:download-simple-bold",
  "ph:download-simple-duotone",
  "ph:download-simple-fill",
  "ph:download-simple-light",
  "ph:download-simple-thin",
  "ph:download-simple",
  "ph:download-thin",
  "ph:download",
  "ph:dress-bold",
  "ph:dress-duotone",
  "ph:dress-fill",
  "ph:dress-light",
  "ph:dress-thin",
  "ph:dress",
  "ph:dribbble-logo-bold",
  "ph:dribbble-logo-duotone",
  "ph:dribbble-logo-fill",
  "ph:dribbble-logo-light",
  "ph:dribbble-logo-thin",
  "ph:dribbble-logo",
  "ph:drop-bold",
  "ph:drop-duotone",
  "ph:drop-fill",
  "ph:drop-half-bold",
  "ph:drop-half-bottom-bold",
  "ph:drop-half-bottom-duotone",
  "ph:drop-half-bottom-fill",
  "ph:drop-half-bottom-light",
  "ph:drop-half-bottom-thin",
  "ph:drop-half-bottom",
  "ph:drop-half-duotone",
  "ph:drop-half-fill",
  "ph:drop-half-light",
  "ph:drop-half-thin",
  "ph:drop-half",
  "ph:drop-light",
  "ph:drop-thin",
  "ph:drop",
  "ph:dropbox-logo-bold",
  "ph:dropbox-logo-duotone",
  "ph:dropbox-logo-fill",
  "ph:dropbox-logo-light",
  "ph:dropbox-logo-thin",
  "ph:dropbox-logo",
  "ph:ear-bold",
  "ph:ear-duotone",
  "ph:ear-fill",
  "ph:ear-light",
  "ph:ear-slash-bold",
  "ph:ear-slash-duotone",
  "ph:ear-slash-fill",
  "ph:ear-slash-light",
  "ph:ear-slash-thin",
  "ph:ear-slash",
  "ph:ear-thin",
  "ph:ear",
  "ph:egg-bold",
  "ph:egg-crack-bold",
  "ph:egg-crack-duotone",
  "ph:egg-crack-fill",
  "ph:egg-crack-light",
  "ph:egg-crack-thin",
  "ph:egg-crack",
  "ph:egg-duotone",
  "ph:egg-fill",
  "ph:egg-light",
  "ph:egg-thin",
  "ph:egg",
  "ph:eject-bold",
  "ph:eject-duotone",
  "ph:eject-fill",
  "ph:eject-light",
  "ph:eject-simple-bold",
  "ph:eject-simple-duotone",
  "ph:eject-simple-fill",
  "ph:eject-simple-light",
  "ph:eject-simple-thin",
  "ph:eject-simple",
  "ph:eject-thin",
  "ph:eject",
  "ph:elevator-bold",
  "ph:elevator-duotone",
  "ph:elevator-fill",
  "ph:elevator-light",
  "ph:elevator-thin",
  "ph:elevator",
  "ph:engine-bold",
  "ph:engine-duotone",
  "ph:engine-fill",
  "ph:engine-light",
  "ph:engine-thin",
  "ph:engine",
  "ph:envelope-bold",
  "ph:envelope-duotone",
  "ph:envelope-fill",
  "ph:envelope-light",
  "ph:envelope-open-bold",
  "ph:envelope-open-duotone",
  "ph:envelope-open-fill",
  "ph:envelope-open-light",
  "ph:envelope-open-thin",
  "ph:envelope-open",
  "ph:envelope-simple-bold",
  "ph:envelope-simple-duotone",
  "ph:envelope-simple-fill",
  "ph:envelope-simple-light",
  "ph:envelope-simple-open-bold",
  "ph:envelope-simple-open-duotone",
  "ph:envelope-simple-open-fill",
  "ph:envelope-simple-open-light",
  "ph:envelope-simple-open-thin",
  "ph:envelope-simple-open",
  "ph:envelope-simple-thin",
  "ph:envelope-simple",
  "ph:envelope-thin",
  "ph:envelope",
  "ph:equalizer-bold",
  "ph:equalizer-duotone",
  "ph:equalizer-fill",
  "ph:equalizer-light",
  "ph:equalizer-thin",
  "ph:equalizer",
  "ph:equals-bold",
  "ph:equals-duotone",
  "ph:equals-fill",
  "ph:equals-light",
  "ph:equals-thin",
  "ph:equals",
  "ph:eraser-bold",
  "ph:eraser-duotone",
  "ph:eraser-fill",
  "ph:eraser-light",
  "ph:eraser-thin",
  "ph:eraser",
  "ph:escalator-down-bold",
  "ph:escalator-down-duotone",
  "ph:escalator-down-fill",
  "ph:escalator-down-light",
  "ph:escalator-down-thin",
  "ph:escalator-down",
  "ph:escalator-up-bold",
  "ph:escalator-up-duotone",
  "ph:escalator-up-fill",
  "ph:escalator-up-light",
  "ph:escalator-up-thin",
  "ph:escalator-up",
  "ph:exam-bold",
  "ph:exam-duotone",
  "ph:exam-fill",
  "ph:exam-light",
  "ph:exam-thin",
  "ph:exam",
  "ph:exclude-bold",
  "ph:exclude-duotone",
  "ph:exclude-fill",
  "ph:exclude-light",
  "ph:exclude-square-bold",
  "ph:exclude-square-duotone",
  "ph:exclude-square-fill",
  "ph:exclude-square-light",
  "ph:exclude-square-thin",
  "ph:exclude-square",
  "ph:exclude-thin",
  "ph:exclude",
  "ph:export-bold",
  "ph:export-duotone",
  "ph:export-fill",
  "ph:export-light",
  "ph:export-thin",
  "ph:export",
  "ph:eye-bold",
  "ph:eye-closed-bold",
  "ph:eye-closed-duotone",
  "ph:eye-closed-fill",
  "ph:eye-closed-light",
  "ph:eye-closed-thin",
  "ph:eye-closed",
  "ph:eye-duotone",
  "ph:eye-fill",
  "ph:eye-light",
  "ph:eye-slash-bold",
  "ph:eye-slash-duotone",
  "ph:eye-slash-fill",
  "ph:eye-slash-light",
  "ph:eye-slash-thin",
  "ph:eye-slash",
  "ph:eye-thin",
  "ph:eye",
  "ph:eyedropper-bold",
  "ph:eyedropper-duotone",
  "ph:eyedropper-fill",
  "ph:eyedropper-light",
  "ph:eyedropper-sample-bold",
  "ph:eyedropper-sample-duotone",
  "ph:eyedropper-sample-fill",
  "ph:eyedropper-sample-light",
  "ph:eyedropper-sample-thin",
  "ph:eyedropper-sample",
  "ph:eyedropper-thin",
  "ph:eyedropper",
  "ph:eyeglasses-bold",
  "ph:eyeglasses-duotone",
  "ph:eyeglasses-fill",
  "ph:eyeglasses-light",
  "ph:eyeglasses-thin",
  "ph:eyeglasses",
  "ph:face-mask-bold",
  "ph:face-mask-duotone",
  "ph:face-mask-fill",
  "ph:face-mask-light",
  "ph:face-mask-thin",
  "ph:face-mask",
  "ph:facebook-logo-bold",
  "ph:facebook-logo-duotone",
  "ph:facebook-logo-fill",
  "ph:facebook-logo-light",
  "ph:facebook-logo-thin",
  "ph:facebook-logo",
  "ph:factory-bold",
  "ph:factory-duotone",
  "ph:factory-fill",
  "ph:factory-light",
  "ph:factory-thin",
  "ph:factory",
  "ph:faders-bold",
  "ph:faders-duotone",
  "ph:faders-fill",
  "ph:faders-horizontal-bold",
  "ph:faders-horizontal-duotone",
  "ph:faders-horizontal-fill",
  "ph:faders-horizontal-light",
  "ph:faders-horizontal-thin",
  "ph:faders-horizontal",
  "ph:faders-light",
  "ph:faders-thin",
  "ph:faders",
  "ph:fan-bold",
  "ph:fan-duotone",
  "ph:fan-fill",
  "ph:fan-light",
  "ph:fan-thin",
  "ph:fan",
  "ph:fast-forward-bold",
  "ph:fast-forward-circle-bold",
  "ph:fast-forward-circle-duotone",
  "ph:fast-forward-circle-fill",
  "ph:fast-forward-circle-light",
  "ph:fast-forward-circle-thin",
  "ph:fast-forward-circle",
  "ph:fast-forward-duotone",
  "ph:fast-forward-fill",
  "ph:fast-forward-light",
  "ph:fast-forward-thin",
  "ph:fast-forward",
  "ph:feather-bold",
  "ph:feather-duotone",
  "ph:feather-fill",
  "ph:feather-light",
  "ph:feather-thin",
  "ph:feather",
  "ph:figma-logo-bold",
  "ph:figma-logo-duotone",
  "ph:figma-logo-fill",
  "ph:figma-logo-light",
  "ph:figma-logo-thin",
  "ph:figma-logo",
  "ph:file-archive-bold",
  "ph:file-archive-duotone",
  "ph:file-archive-fill",
  "ph:file-archive-light",
  "ph:file-archive-thin",
  "ph:file-archive",
  "ph:file-arrow-down-bold",
  "ph:file-arrow-down-duotone",
  "ph:file-arrow-down-fill",
  "ph:file-arrow-down-light",
  "ph:file-arrow-down-thin",
  "ph:file-arrow-down",
  "ph:file-arrow-up-bold",
  "ph:file-arrow-up-duotone",
  "ph:file-arrow-up-fill",
  "ph:file-arrow-up-light",
  "ph:file-arrow-up-thin",
  "ph:file-arrow-up",
  "ph:file-audio-bold",
  "ph:file-audio-duotone",
  "ph:file-audio-fill",
  "ph:file-audio-light",
  "ph:file-audio-thin",
  "ph:file-audio",
  "ph:file-bold",
  "ph:file-cloud-bold",
  "ph:file-cloud-duotone",
  "ph:file-cloud-fill",
  "ph:file-cloud-light",
  "ph:file-cloud-thin",
  "ph:file-cloud",
  "ph:file-code-bold",
  "ph:file-code-duotone",
  "ph:file-code-fill",
  "ph:file-code-light",
  "ph:file-code-thin",
  "ph:file-code",
  "ph:file-css-bold",
  "ph:file-css-duotone",
  "ph:file-css-fill",
  "ph:file-css-light",
  "ph:file-css-thin",
  "ph:file-css",
  "ph:file-csv-bold",
  "ph:file-csv-duotone",
  "ph:file-csv-fill",
  "ph:file-csv-light",
  "ph:file-csv-thin",
  "ph:file-csv",
  "ph:file-dashed-bold",
  "ph:file-dashed-duotone",
  "ph:file-dashed-fill",
  "ph:file-dashed-light",
  "ph:file-dashed-thin",
  "ph:file-dashed",
  "ph:file-doc-bold",
  "ph:file-doc-duotone",
  "ph:file-doc-fill",
  "ph:file-doc-light",
  "ph:file-doc-thin",
  "ph:file-doc",
  "ph:file-dotted-bold",
  "ph:file-dotted-duotone",
  "ph:file-dotted-fill",
  "ph:file-dotted-light",
  "ph:file-dotted-thin",
  "ph:file-dotted",
  "ph:file-duotone",
  "ph:file-fill",
  "ph:file-html-bold",
  "ph:file-html-duotone",
  "ph:file-html-fill",
  "ph:file-html-light",
  "ph:file-html-thin",
  "ph:file-html",
  "ph:file-image-bold",
  "ph:file-image-duotone",
  "ph:file-image-fill",
  "ph:file-image-light",
  "ph:file-image-thin",
  "ph:file-image",
  "ph:file-jpg-bold",
  "ph:file-jpg-duotone",
  "ph:file-jpg-fill",
  "ph:file-jpg-light",
  "ph:file-jpg-thin",
  "ph:file-jpg",
  "ph:file-js-bold",
  "ph:file-js-duotone",
  "ph:file-js-fill",
  "ph:file-js-light",
  "ph:file-js-thin",
  "ph:file-js",
  "ph:file-jsx-bold",
  "ph:file-jsx-duotone",
  "ph:file-jsx-fill",
  "ph:file-jsx-light",
  "ph:file-jsx-thin",
  "ph:file-jsx",
  "ph:file-light",
  "ph:file-lock-bold",
  "ph:file-lock-duotone",
  "ph:file-lock-fill",
  "ph:file-lock-light",
  "ph:file-lock-thin",
  "ph:file-lock",
  "ph:file-magnifying-glass-bold",
  "ph:file-magnifying-glass-duotone",
  "ph:file-magnifying-glass-fill",
  "ph:file-magnifying-glass-light",
  "ph:file-magnifying-glass-thin",
  "ph:file-magnifying-glass",
  "ph:file-minus-bold",
  "ph:file-minus-duotone",
  "ph:file-minus-fill",
  "ph:file-minus-light",
  "ph:file-minus-thin",
  "ph:file-minus",
  "ph:file-pdf-bold",
  "ph:file-pdf-duotone",
  "ph:file-pdf-fill",
  "ph:file-pdf-light",
  "ph:file-pdf-thin",
  "ph:file-pdf",
  "ph:file-plus-bold",
  "ph:file-plus-duotone",
  "ph:file-plus-fill",
  "ph:file-plus-light",
  "ph:file-plus-thin",
  "ph:file-plus",
  "ph:file-png-bold",
  "ph:file-png-duotone",
  "ph:file-png-fill",
  "ph:file-png-light",
  "ph:file-png-thin",
  "ph:file-png",
  "ph:file-ppt-bold",
  "ph:file-ppt-duotone",
  "ph:file-ppt-fill",
  "ph:file-ppt-light",
  "ph:file-ppt-thin",
  "ph:file-ppt",
  "ph:file-rs-bold",
  "ph:file-rs-duotone",
  "ph:file-rs-fill",
  "ph:file-rs-light",
  "ph:file-rs-thin",
  "ph:file-rs",
  "ph:file-search-bold",
  "ph:file-search-duotone",
  "ph:file-search-fill",
  "ph:file-search-light",
  "ph:file-search-thin",
  "ph:file-search",
  "ph:file-sql-bold",
  "ph:file-sql-duotone",
  "ph:file-sql-fill",
  "ph:file-sql-light",
  "ph:file-sql-thin",
  "ph:file-sql",
  "ph:file-svg-bold",
  "ph:file-svg-duotone",
  "ph:file-svg-fill",
  "ph:file-svg-light",
  "ph:file-svg-thin",
  "ph:file-svg",
  "ph:file-text-bold",
  "ph:file-text-duotone",
  "ph:file-text-fill",
  "ph:file-text-light",
  "ph:file-text-thin",
  "ph:file-text",
  "ph:file-thin",
  "ph:file-ts-bold",
  "ph:file-ts-duotone",
  "ph:file-ts-fill",
  "ph:file-ts-light",
  "ph:file-ts-thin",
  "ph:file-ts",
  "ph:file-tsx-bold",
  "ph:file-tsx-duotone",
  "ph:file-tsx-fill",
  "ph:file-tsx-light",
  "ph:file-tsx-thin",
  "ph:file-tsx",
  "ph:file-video-bold",
  "ph:file-video-duotone",
  "ph:file-video-fill",
  "ph:file-video-light",
  "ph:file-video-thin",
  "ph:file-video",
  "ph:file-vue-bold",
  "ph:file-vue-duotone",
  "ph:file-vue-fill",
  "ph:file-vue-light",
  "ph:file-vue-thin",
  "ph:file-vue",
  "ph:file-x-bold",
  "ph:file-x-duotone",
  "ph:file-x-fill",
  "ph:file-x-light",
  "ph:file-x-thin",
  "ph:file-x",
  "ph:file-xls-bold",
  "ph:file-xls-duotone",
  "ph:file-xls-fill",
  "ph:file-xls-light",
  "ph:file-xls-thin",
  "ph:file-xls",
  "ph:file-zip-bold",
  "ph:file-zip-duotone",
  "ph:file-zip-fill",
  "ph:file-zip-light",
  "ph:file-zip-thin",
  "ph:file-zip",
  "ph:file",
  "ph:files-bold",
  "ph:files-duotone",
  "ph:files-fill",
  "ph:files-light",
  "ph:files-thin",
  "ph:files",
  "ph:film-reel-bold",
  "ph:film-reel-duotone",
  "ph:film-reel-fill",
  "ph:film-reel-light",
  "ph:film-reel-thin",
  "ph:film-reel",
  "ph:film-script-bold",
  "ph:film-script-duotone",
  "ph:film-script-fill",
  "ph:film-script-light",
  "ph:film-script-thin",
  "ph:film-script",
  "ph:film-slate-bold",
  "ph:film-slate-duotone",
  "ph:film-slate-fill",
  "ph:film-slate-light",
  "ph:film-slate-thin",
  "ph:film-slate",
  "ph:film-strip-bold",
  "ph:film-strip-duotone",
  "ph:film-strip-fill",
  "ph:film-strip-light",
  "ph:film-strip-thin",
  "ph:film-strip",
  "ph:fingerprint-bold",
  "ph:fingerprint-duotone",
  "ph:fingerprint-fill",
  "ph:fingerprint-light",
  "ph:fingerprint-simple-bold",
  "ph:fingerprint-simple-duotone",
  "ph:fingerprint-simple-fill",
  "ph:fingerprint-simple-light",
  "ph:fingerprint-simple-thin",
  "ph:fingerprint-simple",
  "ph:fingerprint-thin",
  "ph:fingerprint",
  "ph:finn-the-human-bold",
  "ph:finn-the-human-duotone",
  "ph:finn-the-human-fill",
  "ph:finn-the-human-light",
  "ph:finn-the-human-thin",
  "ph:finn-the-human",
  "ph:fire-bold",
  "ph:fire-duotone",
  "ph:fire-extinguisher-bold",
  "ph:fire-extinguisher-duotone",
  "ph:fire-extinguisher-fill",
  "ph:fire-extinguisher-light",
  "ph:fire-extinguisher-thin",
  "ph:fire-extinguisher",
  "ph:fire-fill",
  "ph:fire-light",
  "ph:fire-simple-bold",
  "ph:fire-simple-duotone",
  "ph:fire-simple-fill",
  "ph:fire-simple-light",
  "ph:fire-simple-thin",
  "ph:fire-simple",
  "ph:fire-thin",
  "ph:fire",
  "ph:first-aid-bold",
  "ph:first-aid-duotone",
  "ph:first-aid-fill",
  "ph:first-aid-kit-bold",
  "ph:first-aid-kit-duotone",
  "ph:first-aid-kit-fill",
  "ph:first-aid-kit-light",
  "ph:first-aid-kit-thin",
  "ph:first-aid-kit",
  "ph:first-aid-light",
  "ph:first-aid-thin",
  "ph:first-aid",
  "ph:fish-bold",
  "ph:fish-duotone",
  "ph:fish-fill",
  "ph:fish-light",
  "ph:fish-simple-bold",
  "ph:fish-simple-duotone",
  "ph:fish-simple-fill",
  "ph:fish-simple-light",
  "ph:fish-simple-thin",
  "ph:fish-simple",
  "ph:fish-thin",
  "ph:fish",
  "ph:flag-banner-bold",
  "ph:flag-banner-duotone",
  "ph:flag-banner-fill",
  "ph:flag-banner-light",
  "ph:flag-banner-thin",
  "ph:flag-banner",
  "ph:flag-bold",
  "ph:flag-checkered-bold",
  "ph:flag-checkered-duotone",
  "ph:flag-checkered-fill",
  "ph:flag-checkered-light",
  "ph:flag-checkered-thin",
  "ph:flag-checkered",
  "ph:flag-duotone",
  "ph:flag-fill",
  "ph:flag-light",
  "ph:flag-pennant-bold",
  "ph:flag-pennant-duotone",
  "ph:flag-pennant-fill",
  "ph:flag-pennant-light",
  "ph:flag-pennant-thin",
  "ph:flag-pennant",
  "ph:flag-thin",
  "ph:flag",
  "ph:flame-bold",
  "ph:flame-duotone",
  "ph:flame-fill",
  "ph:flame-light",
  "ph:flame-thin",
  "ph:flame",
  "ph:flashlight-bold",
  "ph:flashlight-duotone",
  "ph:flashlight-fill",
  "ph:flashlight-light",
  "ph:flashlight-thin",
  "ph:flashlight",
  "ph:flask-bold",
  "ph:flask-duotone",
  "ph:flask-fill",
  "ph:flask-light",
  "ph:flask-thin",
  "ph:flask",
  "ph:floppy-disk-back-bold",
  "ph:floppy-disk-back-duotone",
  "ph:floppy-disk-back-fill",
  "ph:floppy-disk-back-light",
  "ph:floppy-disk-back-thin",
  "ph:floppy-disk-back",
  "ph:floppy-disk-bold",
  "ph:floppy-disk-duotone",
  "ph:floppy-disk-fill",
  "ph:floppy-disk-light",
  "ph:floppy-disk-thin",
  "ph:floppy-disk",
  "ph:flow-arrow-bold",
  "ph:flow-arrow-duotone",
  "ph:flow-arrow-fill",
  "ph:flow-arrow-light",
  "ph:flow-arrow-thin",
  "ph:flow-arrow",
  "ph:flower-bold",
  "ph:flower-duotone",
  "ph:flower-fill",
  "ph:flower-light",
  "ph:flower-lotus-bold",
  "ph:flower-lotus-duotone",
  "ph:flower-lotus-fill",
  "ph:flower-lotus-light",
  "ph:flower-lotus-thin",
  "ph:flower-lotus",
  "ph:flower-thin",
  "ph:flower-tulip-bold",
  "ph:flower-tulip-duotone",
  "ph:flower-tulip-fill",
  "ph:flower-tulip-light",
  "ph:flower-tulip-thin",
  "ph:flower-tulip",
  "ph:flower",
  "ph:flying-saucer-bold",
  "ph:flying-saucer-duotone",
  "ph:flying-saucer-fill",
  "ph:flying-saucer-light",
  "ph:flying-saucer-thin",
  "ph:flying-saucer",
  "ph:folder-bold",
  "ph:folder-dashed-bold",
  "ph:folder-dashed-duotone",
  "ph:folder-dashed-fill",
  "ph:folder-dashed-light",
  "ph:folder-dashed-thin",
  "ph:folder-dashed",
  "ph:folder-dotted-bold",
  "ph:folder-dotted-duotone",
  "ph:folder-dotted-fill",
  "ph:folder-dotted-light",
  "ph:folder-dotted-thin",
  "ph:folder-dotted",
  "ph:folder-duotone",
  "ph:folder-fill",
  "ph:folder-light",
  "ph:folder-lock-bold",
  "ph:folder-lock-duotone",
  "ph:folder-lock-fill",
  "ph:folder-lock-light",
  "ph:folder-lock-thin",
  "ph:folder-lock",
  "ph:folder-minus-bold",
  "ph:folder-minus-duotone",
  "ph:folder-minus-fill",
  "ph:folder-minus-light",
  "ph:folder-minus-thin",
  "ph:folder-minus",
  "ph:folder-notch-bold",
  "ph:folder-notch-duotone",
  "ph:folder-notch-fill",
  "ph:folder-notch-light",
  "ph:folder-notch-minus-bold",
  "ph:folder-notch-minus-duotone",
  "ph:folder-notch-minus-fill",
  "ph:folder-notch-minus-light",
  "ph:folder-notch-minus-thin",
  "ph:folder-notch-minus",
  "ph:folder-notch-open-bold",
  "ph:folder-notch-open-duotone",
  "ph:folder-notch-open-fill",
  "ph:folder-notch-open-light",
  "ph:folder-notch-open-thin",
  "ph:folder-notch-open",
  "ph:folder-notch-plus-bold",
  "ph:folder-notch-plus-duotone",
  "ph:folder-notch-plus-fill",
  "ph:folder-notch-plus-light",
  "ph:folder-notch-plus-thin",
  "ph:folder-notch-plus",
  "ph:folder-notch-thin",
  "ph:folder-notch",
  "ph:folder-open-bold",
  "ph:folder-open-duotone",
  "ph:folder-open-fill",
  "ph:folder-open-light",
  "ph:folder-open-thin",
  "ph:folder-open",
  "ph:folder-plus-bold",
  "ph:folder-plus-duotone",
  "ph:folder-plus-fill",
  "ph:folder-plus-light",
  "ph:folder-plus-thin",
  "ph:folder-plus",
  "ph:folder-simple-bold",
  "ph:folder-simple-dashed-bold",
  "ph:folder-simple-dashed-duotone",
  "ph:folder-simple-dashed-fill",
  "ph:folder-simple-dashed-light",
  "ph:folder-simple-dashed-thin",
  "ph:folder-simple-dashed",
  "ph:folder-simple-dotted-bold",
  "ph:folder-simple-dotted-duotone",
  "ph:folder-simple-dotted-fill",
  "ph:folder-simple-dotted-light",
  "ph:folder-simple-dotted-thin",
  "ph:folder-simple-dotted",
  "ph:folder-simple-duotone",
  "ph:folder-simple-fill",
  "ph:folder-simple-light",
  "ph:folder-simple-lock-bold",
  "ph:folder-simple-lock-duotone",
  "ph:folder-simple-lock-fill",
  "ph:folder-simple-lock-light",
  "ph:folder-simple-lock-thin",
  "ph:folder-simple-lock",
  "ph:folder-simple-minus-bold",
  "ph:folder-simple-minus-duotone",
  "ph:folder-simple-minus-fill",
  "ph:folder-simple-minus-light",
  "ph:folder-simple-minus-thin",
  "ph:folder-simple-minus",
  "ph:folder-simple-plus-bold",
  "ph:folder-simple-plus-duotone",
  "ph:folder-simple-plus-fill",
  "ph:folder-simple-plus-light",
  "ph:folder-simple-plus-thin",
  "ph:folder-simple-plus",
  "ph:folder-simple-star-bold",
  "ph:folder-simple-star-duotone",
  "ph:folder-simple-star-fill",
  "ph:folder-simple-star-light",
  "ph:folder-simple-star-thin",
  "ph:folder-simple-star",
  "ph:folder-simple-thin",
  "ph:folder-simple-user-bold",
  "ph:folder-simple-user-duotone",
  "ph:folder-simple-user-fill",
  "ph:folder-simple-user-light",
  "ph:folder-simple-user-thin",
  "ph:folder-simple-user",
  "ph:folder-simple",
  "ph:folder-star-bold",
  "ph:folder-star-duotone",
  "ph:folder-star-fill",
  "ph:folder-star-light",
  "ph:folder-star-thin",
  "ph:folder-star",
  "ph:folder-thin",
  "ph:folder-user-bold",
  "ph:folder-user-duotone",
  "ph:folder-user-fill",
  "ph:folder-user-light",
  "ph:folder-user-thin",
  "ph:folder-user",
  "ph:folder",
  "ph:folders-bold",
  "ph:folders-duotone",
  "ph:folders-fill",
  "ph:folders-light",
  "ph:folders-thin",
  "ph:folders",
  "ph:football-bold",
  "ph:football-duotone",
  "ph:football-fill",
  "ph:football-light",
  "ph:football-thin",
  "ph:football",
  "ph:footprints-bold",
  "ph:footprints-duotone",
  "ph:footprints-fill",
  "ph:footprints-light",
  "ph:footprints-thin",
  "ph:footprints",
  "ph:fork-knife-bold",
  "ph:fork-knife-duotone",
  "ph:fork-knife-fill",
  "ph:fork-knife-light",
  "ph:fork-knife-thin",
  "ph:fork-knife",
  "ph:frame-corners-bold",
  "ph:frame-corners-duotone",
  "ph:frame-corners-fill",
  "ph:frame-corners-light",
  "ph:frame-corners-thin",
  "ph:frame-corners",
  "ph:framer-logo-bold",
  "ph:framer-logo-duotone",
  "ph:framer-logo-fill",
  "ph:framer-logo-light",
  "ph:framer-logo-thin",
  "ph:framer-logo",
  "ph:function-bold",
  "ph:function-duotone",
  "ph:function-fill",
  "ph:function-light",
  "ph:function-thin",
  "ph:function",
  "ph:funnel-bold",
  "ph:funnel-duotone",
  "ph:funnel-fill",
  "ph:funnel-light",
  "ph:funnel-simple-bold",
  "ph:funnel-simple-duotone",
  "ph:funnel-simple-fill",
  "ph:funnel-simple-light",
  "ph:funnel-simple-thin",
  "ph:funnel-simple",
  "ph:funnel-thin",
  "ph:funnel",
  "ph:game-controller-bold",
  "ph:game-controller-duotone",
  "ph:game-controller-fill",
  "ph:game-controller-light",
  "ph:game-controller-thin",
  "ph:game-controller",
  "ph:garage-bold",
  "ph:garage-duotone",
  "ph:garage-fill",
  "ph:garage-light",
  "ph:garage-thin",
  "ph:garage",
  "ph:gas-can-bold",
  "ph:gas-can-duotone",
  "ph:gas-can-fill",
  "ph:gas-can-light",
  "ph:gas-can-thin",
  "ph:gas-can",
  "ph:gas-pump-bold",
  "ph:gas-pump-duotone",
  "ph:gas-pump-fill",
  "ph:gas-pump-light",
  "ph:gas-pump-thin",
  "ph:gas-pump",
  "ph:gauge-bold",
  "ph:gauge-duotone",
  "ph:gauge-fill",
  "ph:gauge-light",
  "ph:gauge-thin",
  "ph:gauge",
  "ph:gavel-bold",
  "ph:gavel-duotone",
  "ph:gavel-fill",
  "ph:gavel-light",
  "ph:gavel-thin",
  "ph:gavel",
  "ph:gear-bold",
  "ph:gear-duotone",
  "ph:gear-fill",
  "ph:gear-fine-bold",
  "ph:gear-fine-duotone",
  "ph:gear-fine-fill",
  "ph:gear-fine-light",
  "ph:gear-fine-thin",
  "ph:gear-fine",
  "ph:gear-light",
  "ph:gear-six-bold",
  "ph:gear-six-duotone",
  "ph:gear-six-fill",
  "ph:gear-six-light",
  "ph:gear-six-thin",
  "ph:gear-six",
  "ph:gear-thin",
  "ph:gear",
  "ph:gender-female-bold",
  "ph:gender-female-duotone",
  "ph:gender-female-fill",
  "ph:gender-female-light",
  "ph:gender-female-thin",
  "ph:gender-female",
  "ph:gender-intersex-bold",
  "ph:gender-intersex-duotone",
  "ph:gender-intersex-fill",
  "ph:gender-intersex-light",
  "ph:gender-intersex-thin",
  "ph:gender-intersex",
  "ph:gender-male-bold",
  "ph:gender-male-duotone",
  "ph:gender-male-fill",
  "ph:gender-male-light",
  "ph:gender-male-thin",
  "ph:gender-male",
  "ph:gender-neuter-bold",
  "ph:gender-neuter-duotone",
  "ph:gender-neuter-fill",
  "ph:gender-neuter-light",
  "ph:gender-neuter-thin",
  "ph:gender-neuter",
  "ph:gender-nonbinary-bold",
  "ph:gender-nonbinary-duotone",
  "ph:gender-nonbinary-fill",
  "ph:gender-nonbinary-light",
  "ph:gender-nonbinary-thin",
  "ph:gender-nonbinary",
  "ph:gender-transgender-bold",
  "ph:gender-transgender-duotone",
  "ph:gender-transgender-fill",
  "ph:gender-transgender-light",
  "ph:gender-transgender-thin",
  "ph:gender-transgender",
  "ph:ghost-bold",
  "ph:ghost-duotone",
  "ph:ghost-fill",
  "ph:ghost-light",
  "ph:ghost-thin",
  "ph:ghost",
  "ph:gif-bold",
  "ph:gif-duotone",
  "ph:gif-fill",
  "ph:gif-light",
  "ph:gif-thin",
  "ph:gif",
  "ph:gift-bold",
  "ph:gift-duotone",
  "ph:gift-fill",
  "ph:gift-light",
  "ph:gift-thin",
  "ph:gift",
  "ph:git-branch-bold",
  "ph:git-branch-duotone",
  "ph:git-branch-fill",
  "ph:git-branch-light",
  "ph:git-branch-thin",
  "ph:git-branch",
  "ph:git-commit-bold",
  "ph:git-commit-duotone",
  "ph:git-commit-fill",
  "ph:git-commit-light",
  "ph:git-commit-thin",
  "ph:git-commit",
  "ph:git-diff-bold",
  "ph:git-diff-duotone",
  "ph:git-diff-fill",
  "ph:git-diff-light",
  "ph:git-diff-thin",
  "ph:git-diff",
  "ph:git-fork-bold",
  "ph:git-fork-duotone",
  "ph:git-fork-fill",
  "ph:git-fork-light",
  "ph:git-fork-thin",
  "ph:git-fork",
  "ph:git-merge-bold",
  "ph:git-merge-duotone",
  "ph:git-merge-fill",
  "ph:git-merge-light",
  "ph:git-merge-thin",
  "ph:git-merge",
  "ph:git-pull-request-bold",
  "ph:git-pull-request-duotone",
  "ph:git-pull-request-fill",
  "ph:git-pull-request-light",
  "ph:git-pull-request-thin",
  "ph:git-pull-request",
  "ph:github-logo-bold",
  "ph:github-logo-duotone",
  "ph:github-logo-fill",
  "ph:github-logo-light",
  "ph:github-logo-thin",
  "ph:github-logo",
  "ph:gitlab-logo-bold",
  "ph:gitlab-logo-duotone",
  "ph:gitlab-logo-fill",
  "ph:gitlab-logo-light",
  "ph:gitlab-logo-simple-bold",
  "ph:gitlab-logo-simple-duotone",
  "ph:gitlab-logo-simple-fill",
  "ph:gitlab-logo-simple-light",
  "ph:gitlab-logo-simple-thin",
  "ph:gitlab-logo-simple",
  "ph:gitlab-logo-thin",
  "ph:gitlab-logo",
  "ph:globe-bold",
  "ph:globe-duotone",
  "ph:globe-fill",
  "ph:globe-hemisphere-east-bold",
  "ph:globe-hemisphere-east-duotone",
  "ph:globe-hemisphere-east-fill",
  "ph:globe-hemisphere-east-light",
  "ph:globe-hemisphere-east-thin",
  "ph:globe-hemisphere-east",
  "ph:globe-hemisphere-west-bold",
  "ph:globe-hemisphere-west-duotone",
  "ph:globe-hemisphere-west-fill",
  "ph:globe-hemisphere-west-light",
  "ph:globe-hemisphere-west-thin",
  "ph:globe-hemisphere-west",
  "ph:globe-light",
  "ph:globe-simple-bold",
  "ph:globe-simple-duotone",
  "ph:globe-simple-fill",
  "ph:globe-simple-light",
  "ph:globe-simple-thin",
  "ph:globe-simple",
  "ph:globe-stand-bold",
  "ph:globe-stand-duotone",
  "ph:globe-stand-fill",
  "ph:globe-stand-light",
  "ph:globe-stand-thin",
  "ph:globe-stand",
  "ph:globe-thin",
  "ph:globe",
  "ph:goggles-bold",
  "ph:goggles-duotone",
  "ph:goggles-fill",
  "ph:goggles-light",
  "ph:goggles-thin",
  "ph:goggles",
  "ph:goodreads-logo-bold",
  "ph:goodreads-logo-duotone",
  "ph:goodreads-logo-fill",
  "ph:goodreads-logo-light",
  "ph:goodreads-logo-thin",
  "ph:goodreads-logo",
  "ph:google-cardboard-logo-bold",
  "ph:google-cardboard-logo-duotone",
  "ph:google-cardboard-logo-fill",
  "ph:google-cardboard-logo-light",
  "ph:google-cardboard-logo-thin",
  "ph:google-cardboard-logo",
  "ph:google-chrome-logo-bold",
  "ph:google-chrome-logo-duotone",
  "ph:google-chrome-logo-fill",
  "ph:google-chrome-logo-light",
  "ph:google-chrome-logo-thin",
  "ph:google-chrome-logo",
  "ph:google-drive-logo-bold",
  "ph:google-drive-logo-duotone",
  "ph:google-drive-logo-fill",
  "ph:google-drive-logo-light",
  "ph:google-drive-logo-thin",
  "ph:google-drive-logo",
  "ph:google-logo-bold",
  "ph:google-logo-duotone",
  "ph:google-logo-fill",
  "ph:google-logo-light",
  "ph:google-logo-thin",
  "ph:google-logo",
  "ph:google-photos-logo-bold",
  "ph:google-photos-logo-duotone",
  "ph:google-photos-logo-fill",
  "ph:google-photos-logo-light",
  "ph:google-photos-logo-thin",
  "ph:google-photos-logo",
  "ph:google-play-logo-bold",
  "ph:google-play-logo-duotone",
  "ph:google-play-logo-fill",
  "ph:google-play-logo-light",
  "ph:google-play-logo-thin",
  "ph:google-play-logo",
  "ph:google-podcasts-logo-bold",
  "ph:google-podcasts-logo-duotone",
  "ph:google-podcasts-logo-fill",
  "ph:google-podcasts-logo-light",
  "ph:google-podcasts-logo-thin",
  "ph:google-podcasts-logo",
  "ph:gradient-bold",
  "ph:gradient-duotone",
  "ph:gradient-fill",
  "ph:gradient-light",
  "ph:gradient-thin",
  "ph:gradient",
  "ph:graduation-cap-bold",
  "ph:graduation-cap-duotone",
  "ph:graduation-cap-fill",
  "ph:graduation-cap-light",
  "ph:graduation-cap-thin",
  "ph:graduation-cap",
  "ph:grains-bold",
  "ph:grains-duotone",
  "ph:grains-fill",
  "ph:grains-light",
  "ph:grains-slash-bold",
  "ph:grains-slash-duotone",
  "ph:grains-slash-fill",
  "ph:grains-slash-light",
  "ph:grains-slash-thin",
  "ph:grains-slash",
  "ph:grains-thin",
  "ph:grains",
  "ph:graph-bold",
  "ph:graph-duotone",
  "ph:graph-fill",
  "ph:graph-light",
  "ph:graph-thin",
  "ph:graph",
  "ph:grid-four-bold",
  "ph:grid-four-duotone",
  "ph:grid-four-fill",
  "ph:grid-four-light",
  "ph:grid-four-thin",
  "ph:grid-four",
  "ph:grid-nine-bold",
  "ph:grid-nine-duotone",
  "ph:grid-nine-fill",
  "ph:grid-nine-light",
  "ph:grid-nine-thin",
  "ph:grid-nine",
  "ph:guitar-bold",
  "ph:guitar-duotone",
  "ph:guitar-fill",
  "ph:guitar-light",
  "ph:guitar-thin",
  "ph:guitar",
  "ph:hamburger-bold",
  "ph:hamburger-duotone",
  "ph:hamburger-fill",
  "ph:hamburger-light",
  "ph:hamburger-thin",
  "ph:hamburger",
  "ph:hammer-bold",
  "ph:hammer-duotone",
  "ph:hammer-fill",
  "ph:hammer-light",
  "ph:hammer-thin",
  "ph:hammer",
  "ph:hand-bold",
  "ph:hand-coins-bold",
  "ph:hand-coins-duotone",
  "ph:hand-coins-fill",
  "ph:hand-coins-light",
  "ph:hand-coins-thin",
  "ph:hand-coins",
  "ph:hand-duotone",
  "ph:hand-eye-bold",
  "ph:hand-eye-duotone",
  "ph:hand-eye-fill",
  "ph:hand-eye-light",
  "ph:hand-eye-thin",
  "ph:hand-eye",
  "ph:hand-fill",
  "ph:hand-fist-bold",
  "ph:hand-fist-duotone",
  "ph:hand-fist-fill",
  "ph:hand-fist-light",
  "ph:hand-fist-thin",
  "ph:hand-fist",
  "ph:hand-grabbing-bold",
  "ph:hand-grabbing-duotone",
  "ph:hand-grabbing-fill",
  "ph:hand-grabbing-light",
  "ph:hand-grabbing-thin",
  "ph:hand-grabbing",
  "ph:hand-heart-bold",
  "ph:hand-heart-duotone",
  "ph:hand-heart-fill",
  "ph:hand-heart-light",
  "ph:hand-heart-thin",
  "ph:hand-heart",
  "ph:hand-light",
  "ph:hand-palm-bold",
  "ph:hand-palm-duotone",
  "ph:hand-palm-fill",
  "ph:hand-palm-light",
  "ph:hand-palm-thin",
  "ph:hand-palm",
  "ph:hand-pointing-bold",
  "ph:hand-pointing-duotone",
  "ph:hand-pointing-fill",
  "ph:hand-pointing-light",
  "ph:hand-pointing-thin",
  "ph:hand-pointing",
  "ph:hand-soap-bold",
  "ph:hand-soap-duotone",
  "ph:hand-soap-fill",
  "ph:hand-soap-light",
  "ph:hand-soap-thin",
  "ph:hand-soap",
  "ph:hand-swipe-left-bold",
  "ph:hand-swipe-left-duotone",
  "ph:hand-swipe-left-fill",
  "ph:hand-swipe-left-light",
  "ph:hand-swipe-left-thin",
  "ph:hand-swipe-left",
  "ph:hand-swipe-right-bold",
  "ph:hand-swipe-right-duotone",
  "ph:hand-swipe-right-fill",
  "ph:hand-swipe-right-light",
  "ph:hand-swipe-right-thin",
  "ph:hand-swipe-right",
  "ph:hand-tap-bold",
  "ph:hand-tap-duotone",
  "ph:hand-tap-fill",
  "ph:hand-tap-light",
  "ph:hand-tap-thin",
  "ph:hand-tap",
  "ph:hand-thin",
  "ph:hand-waving-bold",
  "ph:hand-waving-duotone",
  "ph:hand-waving-fill",
  "ph:hand-waving-light",
  "ph:hand-waving-thin",
  "ph:hand-waving",
  "ph:hand",
  "ph:handbag-bold",
  "ph:handbag-duotone",
  "ph:handbag-fill",
  "ph:handbag-light",
  "ph:handbag-simple-bold",
  "ph:handbag-simple-duotone",
  "ph:handbag-simple-fill",
  "ph:handbag-simple-light",
  "ph:handbag-simple-thin",
  "ph:handbag-simple",
  "ph:handbag-thin",
  "ph:handbag",
  "ph:hands-clapping-bold",
  "ph:hands-clapping-duotone",
  "ph:hands-clapping-fill",
  "ph:hands-clapping-light",
  "ph:hands-clapping-thin",
  "ph:hands-clapping",
  "ph:hands-praying-bold",
  "ph:hands-praying-duotone",
  "ph:hands-praying-fill",
  "ph:hands-praying-light",
  "ph:hands-praying-thin",
  "ph:hands-praying",
  "ph:handshake-bold",
  "ph:handshake-duotone",
  "ph:handshake-fill",
  "ph:handshake-light",
  "ph:handshake-thin",
  "ph:handshake",
  "ph:hard-drive-bold",
  "ph:hard-drive-duotone",
  "ph:hard-drive-fill",
  "ph:hard-drive-light",
  "ph:hard-drive-thin",
  "ph:hard-drive",
  "ph:hard-drives-bold",
  "ph:hard-drives-duotone",
  "ph:hard-drives-fill",
  "ph:hard-drives-light",
  "ph:hard-drives-thin",
  "ph:hard-drives",
  "ph:hash-bold",
  "ph:hash-duotone",
  "ph:hash-fill",
  "ph:hash-light",
  "ph:hash-straight-bold",
  "ph:hash-straight-duotone",
  "ph:hash-straight-fill",
  "ph:hash-straight-light",
  "ph:hash-straight-thin",
  "ph:hash-straight",
  "ph:hash-thin",
  "ph:hash",
  "ph:headlights-bold",
  "ph:headlights-duotone",
  "ph:headlights-fill",
  "ph:headlights-light",
  "ph:headlights-thin",
  "ph:headlights",
  "ph:headphones-bold",
  "ph:headphones-duotone",
  "ph:headphones-fill",
  "ph:headphones-light",
  "ph:headphones-thin",
  "ph:headphones",
  "ph:headset-bold",
  "ph:headset-duotone",
  "ph:headset-fill",
  "ph:headset-light",
  "ph:headset-thin",
  "ph:headset",
  "ph:heart-bold",
  "ph:heart-break-bold",
  "ph:heart-break-duotone",
  "ph:heart-break-fill",
  "ph:heart-break-light",
  "ph:heart-break-thin",
  "ph:heart-break",
  "ph:heart-duotone",
  "ph:heart-fill",
  "ph:heart-half-bold",
  "ph:heart-half-duotone",
  "ph:heart-half-fill",
  "ph:heart-half-light",
  "ph:heart-half-thin",
  "ph:heart-half",
  "ph:heart-light",
  "ph:heart-straight-bold",
  "ph:heart-straight-break-bold",
  "ph:heart-straight-break-duotone",
  "ph:heart-straight-break-fill",
  "ph:heart-straight-break-light",
  "ph:heart-straight-break-thin",
  "ph:heart-straight-break",
  "ph:heart-straight-duotone",
  "ph:heart-straight-fill",
  "ph:heart-straight-light",
  "ph:heart-straight-thin",
  "ph:heart-straight",
  "ph:heart-thin",
  "ph:heart",
  "ph:heartbeat-bold",
  "ph:heartbeat-duotone",
  "ph:heartbeat-fill",
  "ph:heartbeat-light",
  "ph:heartbeat-thin",
  "ph:heartbeat",
  "ph:hexagon-bold",
  "ph:hexagon-duotone",
  "ph:hexagon-fill",
  "ph:hexagon-light",
  "ph:hexagon-thin",
  "ph:hexagon",
  "ph:high-heel-bold",
  "ph:high-heel-duotone",
  "ph:high-heel-fill",
  "ph:high-heel-light",
  "ph:high-heel-thin",
  "ph:high-heel",
  "ph:highlighter-circle-bold",
  "ph:highlighter-circle-duotone",
  "ph:highlighter-circle-fill",
  "ph:highlighter-circle-light",
  "ph:highlighter-circle-thin",
  "ph:highlighter-circle",
  "ph:hoodie-bold",
  "ph:hoodie-duotone",
  "ph:hoodie-fill",
  "ph:hoodie-light",
  "ph:hoodie-thin",
  "ph:hoodie",
  "ph:horse-bold",
  "ph:horse-duotone",
  "ph:horse-fill",
  "ph:horse-light",
  "ph:horse-thin",
  "ph:horse",
  "ph:hourglass-bold",
  "ph:hourglass-duotone",
  "ph:hourglass-fill",
  "ph:hourglass-high-bold",
  "ph:hourglass-high-duotone",
  "ph:hourglass-high-fill",
  "ph:hourglass-high-light",
  "ph:hourglass-high-thin",
  "ph:hourglass-high",
  "ph:hourglass-light",
  "ph:hourglass-low-bold",
  "ph:hourglass-low-duotone",
  "ph:hourglass-low-fill",
  "ph:hourglass-low-light",
  "ph:hourglass-low-thin",
  "ph:hourglass-low",
  "ph:hourglass-medium-bold",
  "ph:hourglass-medium-duotone",
  "ph:hourglass-medium-fill",
  "ph:hourglass-medium-light",
  "ph:hourglass-medium-thin",
  "ph:hourglass-medium",
  "ph:hourglass-simple-bold",
  "ph:hourglass-simple-duotone",
  "ph:hourglass-simple-fill",
  "ph:hourglass-simple-high-bold",
  "ph:hourglass-simple-high-duotone",
  "ph:hourglass-simple-high-fill",
  "ph:hourglass-simple-high-light",
  "ph:hourglass-simple-high-thin",
  "ph:hourglass-simple-high",
  "ph:hourglass-simple-light",
  "ph:hourglass-simple-low-bold",
  "ph:hourglass-simple-low-duotone",
  "ph:hourglass-simple-low-fill",
  "ph:hourglass-simple-low-light",
  "ph:hourglass-simple-low-thin",
  "ph:hourglass-simple-low",
  "ph:hourglass-simple-medium-bold",
  "ph:hourglass-simple-medium-duotone",
  "ph:hourglass-simple-medium-fill",
  "ph:hourglass-simple-medium-light",
  "ph:hourglass-simple-medium-thin",
  "ph:hourglass-simple-medium",
  "ph:hourglass-simple-thin",
  "ph:hourglass-simple",
  "ph:hourglass-thin",
  "ph:hourglass",
  "ph:house-bold",
  "ph:house-duotone",
  "ph:house-fill",
  "ph:house-light",
  "ph:house-line-bold",
  "ph:house-line-duotone",
  "ph:house-line-fill",
  "ph:house-line-light",
  "ph:house-line-thin",
  "ph:house-line",
  "ph:house-simple-bold",
  "ph:house-simple-duotone",
  "ph:house-simple-fill",
  "ph:house-simple-light",
  "ph:house-simple-thin",
  "ph:house-simple",
  "ph:house-thin",
  "ph:house",
  "ph:ice-cream-bold",
  "ph:ice-cream-duotone",
  "ph:ice-cream-fill",
  "ph:ice-cream-light",
  "ph:ice-cream-thin",
  "ph:ice-cream",
  "ph:identification-badge-bold",
  "ph:identification-badge-duotone",
  "ph:identification-badge-fill",
  "ph:identification-badge-light",
  "ph:identification-badge-thin",
  "ph:identification-badge",
  "ph:identification-card-bold",
  "ph:identification-card-duotone",
  "ph:identification-card-fill",
  "ph:identification-card-light",
  "ph:identification-card-thin",
  "ph:identification-card",
  "ph:image-bold",
  "ph:image-duotone",
  "ph:image-fill",
  "ph:image-light",
  "ph:image-square-bold",
  "ph:image-square-duotone",
  "ph:image-square-fill",
  "ph:image-square-light",
  "ph:image-square-thin",
  "ph:image-square",
  "ph:image-thin",
  "ph:image",
  "ph:images-bold",
  "ph:images-duotone",
  "ph:images-fill",
  "ph:images-light",
  "ph:images-square-bold",
  "ph:images-square-duotone",
  "ph:images-square-fill",
  "ph:images-square-light",
  "ph:images-square-thin",
  "ph:images-square",
  "ph:images-thin",
  "ph:images",
  "ph:infinity-bold",
  "ph:infinity-duotone",
  "ph:infinity-fill",
  "ph:infinity-light",
  "ph:infinity-thin",
  "ph:infinity",
  "ph:info-bold",
  "ph:info-duotone",
  "ph:info-fill",
  "ph:info-light",
  "ph:info-thin",
  "ph:info",
  "ph:instagram-logo-bold",
  "ph:instagram-logo-duotone",
  "ph:instagram-logo-fill",
  "ph:instagram-logo-light",
  "ph:instagram-logo-thin",
  "ph:instagram-logo",
  "ph:intersect-bold",
  "ph:intersect-duotone",
  "ph:intersect-fill",
  "ph:intersect-light",
  "ph:intersect-square-bold",
  "ph:intersect-square-duotone",
  "ph:intersect-square-fill",
  "ph:intersect-square-light",
  "ph:intersect-square-thin",
  "ph:intersect-square",
  "ph:intersect-thin",
  "ph:intersect-three-bold",
  "ph:intersect-three-duotone",
  "ph:intersect-three-fill",
  "ph:intersect-three-light",
  "ph:intersect-three-thin",
  "ph:intersect-three",
  "ph:intersect",
  "ph:jeep-bold",
  "ph:jeep-duotone",
  "ph:jeep-fill",
  "ph:jeep-light",
  "ph:jeep-thin",
  "ph:jeep",
  "ph:kanban-bold",
  "ph:kanban-duotone",
  "ph:kanban-fill",
  "ph:kanban-light",
  "ph:kanban-thin",
  "ph:kanban",
  "ph:key-bold",
  "ph:key-duotone",
  "ph:key-fill",
  "ph:key-light",
  "ph:key-return-bold",
  "ph:key-return-duotone",
  "ph:key-return-fill",
  "ph:key-return-light",
  "ph:key-return-thin",
  "ph:key-return",
  "ph:key-thin",
  "ph:key",
  "ph:keyboard-bold",
  "ph:keyboard-duotone",
  "ph:keyboard-fill",
  "ph:keyboard-light",
  "ph:keyboard-thin",
  "ph:keyboard",
  "ph:keyhole-bold",
  "ph:keyhole-duotone",
  "ph:keyhole-fill",
  "ph:keyhole-light",
  "ph:keyhole-thin",
  "ph:keyhole",
  "ph:knife-bold",
  "ph:knife-duotone",
  "ph:knife-fill",
  "ph:knife-light",
  "ph:knife-thin",
  "ph:knife",
  "ph:ladder-bold",
  "ph:ladder-duotone",
  "ph:ladder-fill",
  "ph:ladder-light",
  "ph:ladder-simple-bold",
  "ph:ladder-simple-duotone",
  "ph:ladder-simple-fill",
  "ph:ladder-simple-light",
  "ph:ladder-simple-thin",
  "ph:ladder-simple",
  "ph:ladder-thin",
  "ph:ladder",
  "ph:lamp-bold",
  "ph:lamp-duotone",
  "ph:lamp-fill",
  "ph:lamp-light",
  "ph:lamp-thin",
  "ph:lamp",
  "ph:laptop-bold",
  "ph:laptop-duotone",
  "ph:laptop-fill",
  "ph:laptop-light",
  "ph:laptop-thin",
  "ph:laptop",
  "ph:layout-bold",
  "ph:layout-duotone",
  "ph:layout-fill",
  "ph:layout-light",
  "ph:layout-thin",
  "ph:layout",
  "ph:leaf-bold",
  "ph:leaf-duotone",
  "ph:leaf-fill",
  "ph:leaf-light",
  "ph:leaf-thin",
  "ph:leaf",
  "ph:lifebuoy-bold",
  "ph:lifebuoy-duotone",
  "ph:lifebuoy-fill",
  "ph:lifebuoy-light",
  "ph:lifebuoy-thin",
  "ph:lifebuoy",
  "ph:lightbulb-bold",
  "ph:lightbulb-duotone",
  "ph:lightbulb-filament-bold",
  "ph:lightbulb-filament-duotone",
  "ph:lightbulb-filament-fill",
  "ph:lightbulb-filament-light",
  "ph:lightbulb-filament-thin",
  "ph:lightbulb-filament",
  "ph:lightbulb-fill",
  "ph:lightbulb-light",
  "ph:lightbulb-thin",
  "ph:lightbulb",
  "ph:lighthouse-bold",
  "ph:lighthouse-duotone",
  "ph:lighthouse-fill",
  "ph:lighthouse-light",
  "ph:lighthouse-thin",
  "ph:lighthouse",
  "ph:lightning-a-bold",
  "ph:lightning-a-duotone",
  "ph:lightning-a-fill",
  "ph:lightning-a-light",
  "ph:lightning-a-thin",
  "ph:lightning-a",
  "ph:lightning-bold",
  "ph:lightning-duotone",
  "ph:lightning-fill",
  "ph:lightning-light",
  "ph:lightning-slash-bold",
  "ph:lightning-slash-duotone",
  "ph:lightning-slash-fill",
  "ph:lightning-slash-light",
  "ph:lightning-slash-thin",
  "ph:lightning-slash",
  "ph:lightning-thin",
  "ph:lightning",
  "ph:line-segment-bold",
  "ph:line-segment-duotone",
  "ph:line-segment-fill",
  "ph:line-segment-light",
  "ph:line-segment-thin",
  "ph:line-segment",
  "ph:line-segments-bold",
  "ph:line-segments-duotone",
  "ph:line-segments-fill",
  "ph:line-segments-light",
  "ph:line-segments-thin",
  "ph:line-segments",
  "ph:link-bold",
  "ph:link-break-bold",
  "ph:link-break-duotone",
  "ph:link-break-fill",
  "ph:link-break-light",
  "ph:link-break-thin",
  "ph:link-break",
  "ph:link-duotone",
  "ph:link-fill",
  "ph:link-light",
  "ph:link-simple-bold",
  "ph:link-simple-break-bold",
  "ph:link-simple-break-duotone",
  "ph:link-simple-break-fill",
  "ph:link-simple-break-light",
  "ph:link-simple-break-thin",
  "ph:link-simple-break",
  "ph:link-simple-duotone",
  "ph:link-simple-fill",
  "ph:link-simple-horizontal-bold",
  "ph:link-simple-horizontal-break-bold",
  "ph:link-simple-horizontal-break-duotone",
  "ph:link-simple-horizontal-break-fill",
  "ph:link-simple-horizontal-break-light",
  "ph:link-simple-horizontal-break-thin",
  "ph:link-simple-horizontal-break",
  "ph:link-simple-horizontal-duotone",
  "ph:link-simple-horizontal-fill",
  "ph:link-simple-horizontal-light",
  "ph:link-simple-horizontal-thin",
  "ph:link-simple-horizontal",
  "ph:link-simple-light",
  "ph:link-simple-thin",
  "ph:link-simple",
  "ph:link-thin",
  "ph:link",
  "ph:linkedin-logo-bold",
  "ph:linkedin-logo-duotone",
  "ph:linkedin-logo-fill",
  "ph:linkedin-logo-light",
  "ph:linkedin-logo-thin",
  "ph:linkedin-logo",
  "ph:linux-logo-bold",
  "ph:linux-logo-duotone",
  "ph:linux-logo-fill",
  "ph:linux-logo-light",
  "ph:linux-logo-thin",
  "ph:linux-logo",
  "ph:list-bold",
  "ph:list-bullets-bold",
  "ph:list-bullets-duotone",
  "ph:list-bullets-fill",
  "ph:list-bullets-light",
  "ph:list-bullets-thin",
  "ph:list-bullets",
  "ph:list-checks-bold",
  "ph:list-checks-duotone",
  "ph:list-checks-fill",
  "ph:list-checks-light",
  "ph:list-checks-thin",
  "ph:list-checks",
  "ph:list-dashes-bold",
  "ph:list-dashes-duotone",
  "ph:list-dashes-fill",
  "ph:list-dashes-light",
  "ph:list-dashes-thin",
  "ph:list-dashes",
  "ph:list-duotone",
  "ph:list-fill",
  "ph:list-light",
  "ph:list-magnifying-glass-bold",
  "ph:list-magnifying-glass-duotone",
  "ph:list-magnifying-glass-fill",
  "ph:list-magnifying-glass-light",
  "ph:list-magnifying-glass-thin",
  "ph:list-magnifying-glass",
  "ph:list-numbers-bold",
  "ph:list-numbers-duotone",
  "ph:list-numbers-fill",
  "ph:list-numbers-light",
  "ph:list-numbers-thin",
  "ph:list-numbers",
  "ph:list-plus-bold",
  "ph:list-plus-duotone",
  "ph:list-plus-fill",
  "ph:list-plus-light",
  "ph:list-plus-thin",
  "ph:list-plus",
  "ph:list-thin",
  "ph:list",
  "ph:lock-bold",
  "ph:lock-duotone",
  "ph:lock-fill",
  "ph:lock-key-bold",
  "ph:lock-key-duotone",
  "ph:lock-key-fill",
  "ph:lock-key-light",
  "ph:lock-key-open-bold",
  "ph:lock-key-open-duotone",
  "ph:lock-key-open-fill",
  "ph:lock-key-open-light",
  "ph:lock-key-open-thin",
  "ph:lock-key-open",
  "ph:lock-key-thin",
  "ph:lock-key",
  "ph:lock-laminated-bold",
  "ph:lock-laminated-duotone",
  "ph:lock-laminated-fill",
  "ph:lock-laminated-light",
  "ph:lock-laminated-open-bold",
  "ph:lock-laminated-open-duotone",
  "ph:lock-laminated-open-fill",
  "ph:lock-laminated-open-light",
  "ph:lock-laminated-open-thin",
  "ph:lock-laminated-open",
  "ph:lock-laminated-thin",
  "ph:lock-laminated",
  "ph:lock-light",
  "ph:lock-open-bold",
  "ph:lock-open-duotone",
  "ph:lock-open-fill",
  "ph:lock-open-light",
  "ph:lock-open-thin",
  "ph:lock-open",
  "ph:lock-simple-bold",
  "ph:lock-simple-duotone",
  "ph:lock-simple-fill",
  "ph:lock-simple-light",
  "ph:lock-simple-open-bold",
  "ph:lock-simple-open-duotone",
  "ph:lock-simple-open-fill",
  "ph:lock-simple-open-light",
  "ph:lock-simple-open-thin",
  "ph:lock-simple-open",
  "ph:lock-simple-thin",
  "ph:lock-simple",
  "ph:lock-thin",
  "ph:lock",
  "ph:lockers-bold",
  "ph:lockers-duotone",
  "ph:lockers-fill",
  "ph:lockers-light",
  "ph:lockers-thin",
  "ph:lockers",
  "ph:magic-wand-bold",
  "ph:magic-wand-duotone",
  "ph:magic-wand-fill",
  "ph:magic-wand-light",
  "ph:magic-wand-thin",
  "ph:magic-wand",
  "ph:magnet-bold",
  "ph:magnet-duotone",
  "ph:magnet-fill",
  "ph:magnet-light",
  "ph:magnet-straight-bold",
  "ph:magnet-straight-duotone",
  "ph:magnet-straight-fill",
  "ph:magnet-straight-light",
  "ph:magnet-straight-thin",
  "ph:magnet-straight",
  "ph:magnet-thin",
  "ph:magnet",
  "ph:magnifying-glass-bold",
  "ph:magnifying-glass-duotone",
  "ph:magnifying-glass-fill",
  "ph:magnifying-glass-light",
  "ph:magnifying-glass-minus-bold",
  "ph:magnifying-glass-minus-duotone",
  "ph:magnifying-glass-minus-fill",
  "ph:magnifying-glass-minus-light",
  "ph:magnifying-glass-minus-thin",
  "ph:magnifying-glass-minus",
  "ph:magnifying-glass-plus-bold",
  "ph:magnifying-glass-plus-duotone",
  "ph:magnifying-glass-plus-fill",
  "ph:magnifying-glass-plus-light",
  "ph:magnifying-glass-plus-thin",
  "ph:magnifying-glass-plus",
  "ph:magnifying-glass-thin",
  "ph:magnifying-glass",
  "ph:map-pin-bold",
  "ph:map-pin-duotone",
  "ph:map-pin-fill",
  "ph:map-pin-light",
  "ph:map-pin-line-bold",
  "ph:map-pin-line-duotone",
  "ph:map-pin-line-fill",
  "ph:map-pin-line-light",
  "ph:map-pin-line-thin",
  "ph:map-pin-line",
  "ph:map-pin-thin",
  "ph:map-pin",
  "ph:map-trifold-bold",
  "ph:map-trifold-duotone",
  "ph:map-trifold-fill",
  "ph:map-trifold-light",
  "ph:map-trifold-thin",
  "ph:map-trifold",
  "ph:marker-circle-bold",
  "ph:marker-circle-duotone",
  "ph:marker-circle-fill",
  "ph:marker-circle-light",
  "ph:marker-circle-thin",
  "ph:marker-circle",
  "ph:martini-bold",
  "ph:martini-duotone",
  "ph:martini-fill",
  "ph:martini-light",
  "ph:martini-thin",
  "ph:martini",
  "ph:mask-happy-bold",
  "ph:mask-happy-duotone",
  "ph:mask-happy-fill",
  "ph:mask-happy-light",
  "ph:mask-happy-thin",
  "ph:mask-happy",
  "ph:mask-sad-bold",
  "ph:mask-sad-duotone",
  "ph:mask-sad-fill",
  "ph:mask-sad-light",
  "ph:mask-sad-thin",
  "ph:mask-sad",
  "ph:math-operations-bold",
  "ph:math-operations-duotone",
  "ph:math-operations-fill",
  "ph:math-operations-light",
  "ph:math-operations-thin",
  "ph:math-operations",
  "ph:medal-bold",
  "ph:medal-duotone",
  "ph:medal-fill",
  "ph:medal-light",
  "ph:medal-military-bold",
  "ph:medal-military-duotone",
  "ph:medal-military-fill",
  "ph:medal-military-light",
  "ph:medal-military-thin",
  "ph:medal-military",
  "ph:medal-thin",
  "ph:medal",
  "ph:medium-logo-bold",
  "ph:medium-logo-duotone",
  "ph:medium-logo-fill",
  "ph:medium-logo-light",
  "ph:medium-logo-thin",
  "ph:medium-logo",
  "ph:megaphone-bold",
  "ph:megaphone-duotone",
  "ph:megaphone-fill",
  "ph:megaphone-light",
  "ph:megaphone-simple-bold",
  "ph:megaphone-simple-duotone",
  "ph:megaphone-simple-fill",
  "ph:megaphone-simple-light",
  "ph:megaphone-simple-thin",
  "ph:megaphone-simple",
  "ph:megaphone-thin",
  "ph:megaphone",
  "ph:messenger-logo-bold",
  "ph:messenger-logo-duotone",
  "ph:messenger-logo-fill",
  "ph:messenger-logo-light",
  "ph:messenger-logo-thin",
  "ph:messenger-logo",
  "ph:meta-logo-bold",
  "ph:meta-logo-duotone",
  "ph:meta-logo-fill",
  "ph:meta-logo-light",
  "ph:meta-logo-thin",
  "ph:meta-logo",
  "ph:metronome-bold",
  "ph:metronome-duotone",
  "ph:metronome-fill",
  "ph:metronome-light",
  "ph:metronome-thin",
  "ph:metronome",
  "ph:microphone-bold",
  "ph:microphone-duotone",
  "ph:microphone-fill",
  "ph:microphone-light",
  "ph:microphone-slash-bold",
  "ph:microphone-slash-duotone",
  "ph:microphone-slash-fill",
  "ph:microphone-slash-light",
  "ph:microphone-slash-thin",
  "ph:microphone-slash",
  "ph:microphone-stage-bold",
  "ph:microphone-stage-duotone",
  "ph:microphone-stage-fill",
  "ph:microphone-stage-light",
  "ph:microphone-stage-thin",
  "ph:microphone-stage",
  "ph:microphone-thin",
  "ph:microphone",
  "ph:microsoft-excel-logo-bold",
  "ph:microsoft-excel-logo-duotone",
  "ph:microsoft-excel-logo-fill",
  "ph:microsoft-excel-logo-light",
  "ph:microsoft-excel-logo-thin",
  "ph:microsoft-excel-logo",
  "ph:microsoft-outlook-logo-bold",
  "ph:microsoft-outlook-logo-duotone",
  "ph:microsoft-outlook-logo-fill",
  "ph:microsoft-outlook-logo-light",
  "ph:microsoft-outlook-logo-thin",
  "ph:microsoft-outlook-logo",
  "ph:microsoft-powerpoint-logo-bold",
  "ph:microsoft-powerpoint-logo-duotone",
  "ph:microsoft-powerpoint-logo-fill",
  "ph:microsoft-powerpoint-logo-light",
  "ph:microsoft-powerpoint-logo-thin",
  "ph:microsoft-powerpoint-logo",
  "ph:microsoft-teams-logo-bold",
  "ph:microsoft-teams-logo-duotone",
  "ph:microsoft-teams-logo-fill",
  "ph:microsoft-teams-logo-light",
  "ph:microsoft-teams-logo-thin",
  "ph:microsoft-teams-logo",
  "ph:microsoft-word-logo-bold",
  "ph:microsoft-word-logo-duotone",
  "ph:microsoft-word-logo-fill",
  "ph:microsoft-word-logo-light",
  "ph:microsoft-word-logo-thin",
  "ph:microsoft-word-logo",
  "ph:minus-bold",
  "ph:minus-circle-bold",
  "ph:minus-circle-duotone",
  "ph:minus-circle-fill",
  "ph:minus-circle-light",
  "ph:minus-circle-thin",
  "ph:minus-circle",
  "ph:minus-duotone",
  "ph:minus-fill",
  "ph:minus-light",
  "ph:minus-square-bold",
  "ph:minus-square-duotone",
  "ph:minus-square-fill",
  "ph:minus-square-light",
  "ph:minus-square-thin",
  "ph:minus-square",
  "ph:minus-thin",
  "ph:minus",
  "ph:money-bold",
  "ph:money-duotone",
  "ph:money-fill",
  "ph:money-light",
  "ph:money-thin",
  "ph:money",
  "ph:monitor-bold",
  "ph:monitor-duotone",
  "ph:monitor-fill",
  "ph:monitor-light",
  "ph:monitor-play-bold",
  "ph:monitor-play-duotone",
  "ph:monitor-play-fill",
  "ph:monitor-play-light",
  "ph:monitor-play-thin",
  "ph:monitor-play",
  "ph:monitor-thin",
  "ph:monitor",
  "ph:moon-bold",
  "ph:moon-duotone",
  "ph:moon-fill",
  "ph:moon-light",
  "ph:moon-stars-bold",
  "ph:moon-stars-duotone",
  "ph:moon-stars-fill",
  "ph:moon-stars-light",
  "ph:moon-stars-thin",
  "ph:moon-stars",
  "ph:moon-thin",
  "ph:moon",
  "ph:moped-bold",
  "ph:moped-duotone",
  "ph:moped-fill",
  "ph:moped-front-bold",
  "ph:moped-front-duotone",
  "ph:moped-front-fill",
  "ph:moped-front-light",
  "ph:moped-front-thin",
  "ph:moped-front",
  "ph:moped-light",
  "ph:moped-thin",
  "ph:moped",
  "ph:mosque-bold",
  "ph:mosque-duotone",
  "ph:mosque-fill",
  "ph:mosque-light",
  "ph:mosque-thin",
  "ph:mosque",
  "ph:motorcycle-bold",
  "ph:motorcycle-duotone",
  "ph:motorcycle-fill",
  "ph:motorcycle-light",
  "ph:motorcycle-thin",
  "ph:motorcycle",
  "ph:mountains-bold",
  "ph:mountains-duotone",
  "ph:mountains-fill",
  "ph:mountains-light",
  "ph:mountains-thin",
  "ph:mountains",
  "ph:mouse-bold",
  "ph:mouse-duotone",
  "ph:mouse-fill",
  "ph:mouse-light",
  "ph:mouse-simple-bold",
  "ph:mouse-simple-duotone",
  "ph:mouse-simple-fill",
  "ph:mouse-simple-light",
  "ph:mouse-simple-thin",
  "ph:mouse-simple",
  "ph:mouse-thin",
  "ph:mouse",
  "ph:music-note-bold",
  "ph:music-note-duotone",
  "ph:music-note-fill",
  "ph:music-note-light",
  "ph:music-note-simple-bold",
  "ph:music-note-simple-duotone",
  "ph:music-note-simple-fill",
  "ph:music-note-simple-light",
  "ph:music-note-simple-thin",
  "ph:music-note-simple",
  "ph:music-note-thin",
  "ph:music-note",
  "ph:music-notes-bold",
  "ph:music-notes-duotone",
  "ph:music-notes-fill",
  "ph:music-notes-light",
  "ph:music-notes-plus-bold",
  "ph:music-notes-plus-duotone",
  "ph:music-notes-plus-fill",
  "ph:music-notes-plus-light",
  "ph:music-notes-plus-thin",
  "ph:music-notes-plus",
  "ph:music-notes-simple-bold",
  "ph:music-notes-simple-duotone",
  "ph:music-notes-simple-fill",
  "ph:music-notes-simple-light",
  "ph:music-notes-simple-thin",
  "ph:music-notes-simple",
  "ph:music-notes-thin",
  "ph:music-notes",
  "ph:navigation-arrow-bold",
  "ph:navigation-arrow-duotone",
  "ph:navigation-arrow-fill",
  "ph:navigation-arrow-light",
  "ph:navigation-arrow-thin",
  "ph:navigation-arrow",
  "ph:needle-bold",
  "ph:needle-duotone",
  "ph:needle-fill",
  "ph:needle-light",
  "ph:needle-thin",
  "ph:needle",
  "ph:newspaper-bold",
  "ph:newspaper-clipping-bold",
  "ph:newspaper-clipping-duotone",
  "ph:newspaper-clipping-fill",
  "ph:newspaper-clipping-light",
  "ph:newspaper-clipping-thin",
  "ph:newspaper-clipping",
  "ph:newspaper-duotone",
  "ph:newspaper-fill",
  "ph:newspaper-light",
  "ph:newspaper-thin",
  "ph:newspaper",
  "ph:notches-bold",
  "ph:notches-duotone",
  "ph:notches-fill",
  "ph:notches-light",
  "ph:notches-thin",
  "ph:notches",
  "ph:note-blank-bold",
  "ph:note-blank-duotone",
  "ph:note-blank-fill",
  "ph:note-blank-light",
  "ph:note-blank-thin",
  "ph:note-blank",
  "ph:note-bold",
  "ph:note-duotone",
  "ph:note-fill",
  "ph:note-light",
  "ph:note-pencil-bold",
  "ph:note-pencil-duotone",
  "ph:note-pencil-fill",
  "ph:note-pencil-light",
  "ph:note-pencil-thin",
  "ph:note-pencil",
  "ph:note-thin",
  "ph:note",
  "ph:notebook-bold",
  "ph:notebook-duotone",
  "ph:notebook-fill",
  "ph:notebook-light",
  "ph:notebook-thin",
  "ph:notebook",
  "ph:notepad-bold",
  "ph:notepad-duotone",
  "ph:notepad-fill",
  "ph:notepad-light",
  "ph:notepad-thin",
  "ph:notepad",
  "ph:notification-bold",
  "ph:notification-duotone",
  "ph:notification-fill",
  "ph:notification-light",
  "ph:notification-thin",
  "ph:notification",
  "ph:notion-logo-bold",
  "ph:notion-logo-duotone",
  "ph:notion-logo-fill",
  "ph:notion-logo-light",
  "ph:notion-logo-thin",
  "ph:notion-logo",
  "ph:number-circle-eight-bold",
  "ph:number-circle-eight-duotone",
  "ph:number-circle-eight-fill",
  "ph:number-circle-eight-light",
  "ph:number-circle-eight-thin",
  "ph:number-circle-eight",
  "ph:number-circle-five-bold",
  "ph:number-circle-five-duotone",
  "ph:number-circle-five-fill",
  "ph:number-circle-five-light",
  "ph:number-circle-five-thin",
  "ph:number-circle-five",
  "ph:number-circle-four-bold",
  "ph:number-circle-four-duotone",
  "ph:number-circle-four-fill",
  "ph:number-circle-four-light",
  "ph:number-circle-four-thin",
  "ph:number-circle-four",
  "ph:number-circle-nine-bold",
  "ph:number-circle-nine-duotone",
  "ph:number-circle-nine-fill",
  "ph:number-circle-nine-light",
  "ph:number-circle-nine-thin",
  "ph:number-circle-nine",
  "ph:number-circle-one-bold",
  "ph:number-circle-one-duotone",
  "ph:number-circle-one-fill",
  "ph:number-circle-one-light",
  "ph:number-circle-one-thin",
  "ph:number-circle-one",
  "ph:number-circle-seven-bold",
  "ph:number-circle-seven-duotone",
  "ph:number-circle-seven-fill",
  "ph:number-circle-seven-light",
  "ph:number-circle-seven-thin",
  "ph:number-circle-seven",
  "ph:number-circle-six-bold",
  "ph:number-circle-six-duotone",
  "ph:number-circle-six-fill",
  "ph:number-circle-six-light",
  "ph:number-circle-six-thin",
  "ph:number-circle-six",
  "ph:number-circle-three-bold",
  "ph:number-circle-three-duotone",
  "ph:number-circle-three-fill",
  "ph:number-circle-three-light",
  "ph:number-circle-three-thin",
  "ph:number-circle-three",
  "ph:number-circle-two-bold",
  "ph:number-circle-two-duotone",
  "ph:number-circle-two-fill",
  "ph:number-circle-two-light",
  "ph:number-circle-two-thin",
  "ph:number-circle-two",
  "ph:number-circle-zero-bold",
  "ph:number-circle-zero-duotone",
  "ph:number-circle-zero-fill",
  "ph:number-circle-zero-light",
  "ph:number-circle-zero-thin",
  "ph:number-circle-zero",
  "ph:number-eight-bold",
  "ph:number-eight-duotone",
  "ph:number-eight-fill",
  "ph:number-eight-light",
  "ph:number-eight-thin",
  "ph:number-eight",
  "ph:number-five-bold",
  "ph:number-five-duotone",
  "ph:number-five-fill",
  "ph:number-five-light",
  "ph:number-five-thin",
  "ph:number-five",
  "ph:number-four-bold",
  "ph:number-four-duotone",
  "ph:number-four-fill",
  "ph:number-four-light",
  "ph:number-four-thin",
  "ph:number-four",
  "ph:number-nine-bold",
  "ph:number-nine-duotone",
  "ph:number-nine-fill",
  "ph:number-nine-light",
  "ph:number-nine-thin",
  "ph:number-nine",
  "ph:number-one-bold",
  "ph:number-one-duotone",
  "ph:number-one-fill",
  "ph:number-one-light",
  "ph:number-one-thin",
  "ph:number-one",
  "ph:number-seven-bold",
  "ph:number-seven-duotone",
  "ph:number-seven-fill",
  "ph:number-seven-light",
  "ph:number-seven-thin",
  "ph:number-seven",
  "ph:number-six-bold",
  "ph:number-six-duotone",
  "ph:number-six-fill",
  "ph:number-six-light",
  "ph:number-six-thin",
  "ph:number-six",
  "ph:number-square-eight-bold",
  "ph:number-square-eight-duotone",
  "ph:number-square-eight-fill",
  "ph:number-square-eight-light",
  "ph:number-square-eight-thin",
  "ph:number-square-eight",
  "ph:number-square-five-bold",
  "ph:number-square-five-duotone",
  "ph:number-square-five-fill",
  "ph:number-square-five-light",
  "ph:number-square-five-thin",
  "ph:number-square-five",
  "ph:number-square-four-bold",
  "ph:number-square-four-duotone",
  "ph:number-square-four-fill",
  "ph:number-square-four-light",
  "ph:number-square-four-thin",
  "ph:number-square-four",
  "ph:number-square-nine-bold",
  "ph:number-square-nine-duotone",
  "ph:number-square-nine-fill",
  "ph:number-square-nine-light",
  "ph:number-square-nine-thin",
  "ph:number-square-nine",
  "ph:number-square-one-bold",
  "ph:number-square-one-duotone",
  "ph:number-square-one-fill",
  "ph:number-square-one-light",
  "ph:number-square-one-thin",
  "ph:number-square-one",
  "ph:number-square-seven-bold",
  "ph:number-square-seven-duotone",
  "ph:number-square-seven-fill",
  "ph:number-square-seven-light",
  "ph:number-square-seven-thin",
  "ph:number-square-seven",
  "ph:number-square-six-bold",
  "ph:number-square-six-duotone",
  "ph:number-square-six-fill",
  "ph:number-square-six-light",
  "ph:number-square-six-thin",
  "ph:number-square-six",
  "ph:number-square-three-bold",
  "ph:number-square-three-duotone",
  "ph:number-square-three-fill",
  "ph:number-square-three-light",
  "ph:number-square-three-thin",
  "ph:number-square-three",
  "ph:number-square-two-bold",
  "ph:number-square-two-duotone",
  "ph:number-square-two-fill",
  "ph:number-square-two-light",
  "ph:number-square-two-thin",
  "ph:number-square-two",
  "ph:number-square-zero-bold",
  "ph:number-square-zero-duotone",
  "ph:number-square-zero-fill",
  "ph:number-square-zero-light",
  "ph:number-square-zero-thin",
  "ph:number-square-zero",
  "ph:number-three-bold",
  "ph:number-three-duotone",
  "ph:number-three-fill",
  "ph:number-three-light",
  "ph:number-three-thin",
  "ph:number-three",
  "ph:number-two-bold",
  "ph:number-two-duotone",
  "ph:number-two-fill",
  "ph:number-two-light",
  "ph:number-two-thin",
  "ph:number-two",
  "ph:number-zero-bold",
  "ph:number-zero-duotone",
  "ph:number-zero-fill",
  "ph:number-zero-light",
  "ph:number-zero-thin",
  "ph:number-zero",
  "ph:nut-bold",
  "ph:nut-duotone",
  "ph:nut-fill",
  "ph:nut-light",
  "ph:nut-thin",
  "ph:nut",
  "ph:ny-times-logo-bold",
  "ph:ny-times-logo-duotone",
  "ph:ny-times-logo-fill",
  "ph:ny-times-logo-light",
  "ph:ny-times-logo-thin",
  "ph:ny-times-logo",
  "ph:octagon-bold",
  "ph:octagon-duotone",
  "ph:octagon-fill",
  "ph:octagon-light",
  "ph:octagon-thin",
  "ph:octagon",
  "ph:office-chair-bold",
  "ph:office-chair-duotone",
  "ph:office-chair-fill",
  "ph:office-chair-light",
  "ph:office-chair-thin",
  "ph:office-chair",
  "ph:option-bold",
  "ph:option-duotone",
  "ph:option-fill",
  "ph:option-light",
  "ph:option-thin",
  "ph:option",
  "ph:orange-slice-bold",
  "ph:orange-slice-duotone",
  "ph:orange-slice-fill",
  "ph:orange-slice-light",
  "ph:orange-slice-thin",
  "ph:orange-slice",
  "ph:package-bold",
  "ph:package-duotone",
  "ph:package-fill",
  "ph:package-light",
  "ph:package-thin",
  "ph:package",
  "ph:paint-brush-bold",
  "ph:paint-brush-broad-bold",
  "ph:paint-brush-broad-duotone",
  "ph:paint-brush-broad-fill",
  "ph:paint-brush-broad-light",
  "ph:paint-brush-broad-thin",
  "ph:paint-brush-broad",
  "ph:paint-brush-duotone",
  "ph:paint-brush-fill",
  "ph:paint-brush-household-bold",
  "ph:paint-brush-household-duotone",
  "ph:paint-brush-household-fill",
  "ph:paint-brush-household-light",
  "ph:paint-brush-household-thin",
  "ph:paint-brush-household",
  "ph:paint-brush-light",
  "ph:paint-brush-thin",
  "ph:paint-brush",
  "ph:paint-bucket-bold",
  "ph:paint-bucket-duotone",
  "ph:paint-bucket-fill",
  "ph:paint-bucket-light",
  "ph:paint-bucket-thin",
  "ph:paint-bucket",
  "ph:paint-roller-bold",
  "ph:paint-roller-duotone",
  "ph:paint-roller-fill",
  "ph:paint-roller-light",
  "ph:paint-roller-thin",
  "ph:paint-roller",
  "ph:palette-bold",
  "ph:palette-duotone",
  "ph:palette-fill",
  "ph:palette-light",
  "ph:palette-thin",
  "ph:palette",
  "ph:pants-bold",
  "ph:pants-duotone",
  "ph:pants-fill",
  "ph:pants-light",
  "ph:pants-thin",
  "ph:pants",
  "ph:paper-plane-bold",
  "ph:paper-plane-duotone",
  "ph:paper-plane-fill",
  "ph:paper-plane-light",
  "ph:paper-plane-right-bold",
  "ph:paper-plane-right-duotone",
  "ph:paper-plane-right-fill",
  "ph:paper-plane-right-light",
  "ph:paper-plane-right-thin",
  "ph:paper-plane-right",
  "ph:paper-plane-thin",
  "ph:paper-plane-tilt-bold",
  "ph:paper-plane-tilt-duotone",
  "ph:paper-plane-tilt-fill",
  "ph:paper-plane-tilt-light",
  "ph:paper-plane-tilt-thin",
  "ph:paper-plane-tilt",
  "ph:paper-plane",
  "ph:paperclip-bold",
  "ph:paperclip-duotone",
  "ph:paperclip-fill",
  "ph:paperclip-horizontal-bold",
  "ph:paperclip-horizontal-duotone",
  "ph:paperclip-horizontal-fill",
  "ph:paperclip-horizontal-light",
  "ph:paperclip-horizontal-thin",
  "ph:paperclip-horizontal",
  "ph:paperclip-light",
  "ph:paperclip-thin",
  "ph:paperclip",
  "ph:parachute-bold",
  "ph:parachute-duotone",
  "ph:parachute-fill",
  "ph:parachute-light",
  "ph:parachute-thin",
  "ph:parachute",
  "ph:paragraph-bold",
  "ph:paragraph-duotone",
  "ph:paragraph-fill",
  "ph:paragraph-light",
  "ph:paragraph-thin",
  "ph:paragraph",
  "ph:parallelogram-bold",
  "ph:parallelogram-duotone",
  "ph:parallelogram-fill",
  "ph:parallelogram-light",
  "ph:parallelogram-thin",
  "ph:parallelogram",
  "ph:park-bold",
  "ph:park-duotone",
  "ph:park-fill",
  "ph:park-light",
  "ph:park-thin",
  "ph:park",
  "ph:password-bold",
  "ph:password-duotone",
  "ph:password-fill",
  "ph:password-light",
  "ph:password-thin",
  "ph:password",
  "ph:path-bold",
  "ph:path-duotone",
  "ph:path-fill",
  "ph:path-light",
  "ph:path-thin",
  "ph:path",
  "ph:patreon-logo-bold",
  "ph:patreon-logo-duotone",
  "ph:patreon-logo-fill",
  "ph:patreon-logo-light",
  "ph:patreon-logo-thin",
  "ph:patreon-logo",
  "ph:pause-bold",
  "ph:pause-circle-bold",
  "ph:pause-circle-duotone",
  "ph:pause-circle-fill",
  "ph:pause-circle-light",
  "ph:pause-circle-thin",
  "ph:pause-circle",
  "ph:pause-duotone",
  "ph:pause-fill",
  "ph:pause-light",
  "ph:pause-thin",
  "ph:pause",
  "ph:paw-print-bold",
  "ph:paw-print-duotone",
  "ph:paw-print-fill",
  "ph:paw-print-light",
  "ph:paw-print-thin",
  "ph:paw-print",
  "ph:paypal-logo-bold",
  "ph:paypal-logo-duotone",
  "ph:paypal-logo-fill",
  "ph:paypal-logo-light",
  "ph:paypal-logo-thin",
  "ph:paypal-logo",
  "ph:peace-bold",
  "ph:peace-duotone",
  "ph:peace-fill",
  "ph:peace-light",
  "ph:peace-thin",
  "ph:peace",
  "ph:pedestrian-duotone",
  "ph:pen-bold",
  "ph:pen-duotone",
  "ph:pen-fill",
  "ph:pen-light",
  "ph:pen-nib-bold",
  "ph:pen-nib-duotone",
  "ph:pen-nib-fill",
  "ph:pen-nib-light",
  "ph:pen-nib-straight-bold",
  "ph:pen-nib-straight-duotone",
  "ph:pen-nib-straight-fill",
  "ph:pen-nib-straight-light",
  "ph:pen-nib-straight-thin",
  "ph:pen-nib-straight",
  "ph:pen-nib-thin",
  "ph:pen-nib",
  "ph:pen-thin",
  "ph:pen",
  "ph:pencil-bold",
  "ph:pencil-circle-bold",
  "ph:pencil-circle-duotone",
  "ph:pencil-circle-fill",
  "ph:pencil-circle-light",
  "ph:pencil-circle-thin",
  "ph:pencil-circle",
  "ph:pencil-duotone",
  "ph:pencil-fill",
  "ph:pencil-light",
  "ph:pencil-line-bold",
  "ph:pencil-line-duotone",
  "ph:pencil-line-fill",
  "ph:pencil-line-light",
  "ph:pencil-line-thin",
  "ph:pencil-line",
  "ph:pencil-simple-bold",
  "ph:pencil-simple-duotone",
  "ph:pencil-simple-fill",
  "ph:pencil-simple-light",
  "ph:pencil-simple-line-bold",
  "ph:pencil-simple-line-duotone",
  "ph:pencil-simple-line-fill",
  "ph:pencil-simple-line-light",
  "ph:pencil-simple-line-thin",
  "ph:pencil-simple-line",
  "ph:pencil-simple-slash-bold",
  "ph:pencil-simple-slash-duotone",
  "ph:pencil-simple-slash-fill",
  "ph:pencil-simple-slash-light",
  "ph:pencil-simple-slash-thin",
  "ph:pencil-simple-slash",
  "ph:pencil-simple-thin",
  "ph:pencil-simple",
  "ph:pencil-slash-bold",
  "ph:pencil-slash-duotone",
  "ph:pencil-slash-fill",
  "ph:pencil-slash-light",
  "ph:pencil-slash-thin",
  "ph:pencil-slash",
  "ph:pencil-thin",
  "ph:pencil",
  "ph:pentagram-bold",
  "ph:pentagram-duotone",
  "ph:pentagram-fill",
  "ph:pentagram-light",
  "ph:pentagram-thin",
  "ph:pentagram",
  "ph:pepper-bold",
  "ph:pepper-duotone",
  "ph:pepper-fill",
  "ph:pepper-light",
  "ph:pepper-thin",
  "ph:pepper",
  "ph:percent-bold",
  "ph:percent-duotone",
  "ph:percent-fill",
  "ph:percent-light",
  "ph:percent-thin",
  "ph:percent",
  "ph:person-arms-spread-bold",
  "ph:person-arms-spread-duotone",
  "ph:person-arms-spread-fill",
  "ph:person-arms-spread-light",
  "ph:person-arms-spread-thin",
  "ph:person-arms-spread",
  "ph:person-bold",
  "ph:person-duotone",
  "ph:person-fill",
  "ph:person-light",
  "ph:person-simple-bike-bold",
  "ph:person-simple-bike-duotone",
  "ph:person-simple-bike-fill",
  "ph:person-simple-bike-light",
  "ph:person-simple-bike-thin",
  "ph:person-simple-bike",
  "ph:person-simple-bold",
  "ph:person-simple-duotone",
  "ph:person-simple-fill",
  "ph:person-simple-light",
  "ph:person-simple-run-bold",
  "ph:person-simple-run-duotone",
  "ph:person-simple-run-fill",
  "ph:person-simple-run-light",
  "ph:person-simple-run-thin",
  "ph:person-simple-run",
  "ph:person-simple-thin",
  "ph:person-simple-throw-bold",
  "ph:person-simple-throw-duotone",
  "ph:person-simple-throw-fill",
  "ph:person-simple-throw-light",
  "ph:person-simple-throw-thin",
  "ph:person-simple-throw",
  "ph:person-simple-walk-bold",
  "ph:person-simple-walk-duotone",
  "ph:person-simple-walk-fill",
  "ph:person-simple-walk-light",
  "ph:person-simple-walk-thin",
  "ph:person-simple-walk",
  "ph:person-simple",
  "ph:person-thin",
  "ph:person",
  "ph:perspective-bold",
  "ph:perspective-duotone",
  "ph:perspective-fill",
  "ph:perspective-light",
  "ph:perspective-thin",
  "ph:perspective",
  "ph:phone-bold",
  "ph:phone-call-bold",
  "ph:phone-call-duotone",
  "ph:phone-call-fill",
  "ph:phone-call-light",
  "ph:phone-call-thin",
  "ph:phone-call",
  "ph:phone-disconnect-bold",
  "ph:phone-disconnect-duotone",
  "ph:phone-disconnect-fill",
  "ph:phone-disconnect-light",
  "ph:phone-disconnect-thin",
  "ph:phone-disconnect",
  "ph:phone-duotone",
  "ph:phone-fill",
  "ph:phone-incoming-bold",
  "ph:phone-incoming-duotone",
  "ph:phone-incoming-fill",
  "ph:phone-incoming-light",
  "ph:phone-incoming-thin",
  "ph:phone-incoming",
  "ph:phone-light",
  "ph:phone-outgoing-bold",
  "ph:phone-outgoing-duotone",
  "ph:phone-outgoing-fill",
  "ph:phone-outgoing-light",
  "ph:phone-outgoing-thin",
  "ph:phone-outgoing",
  "ph:phone-plus-bold",
  "ph:phone-plus-duotone",
  "ph:phone-plus-fill",
  "ph:phone-plus-light",
  "ph:phone-plus-thin",
  "ph:phone-plus",
  "ph:phone-slash-bold",
  "ph:phone-slash-duotone",
  "ph:phone-slash-fill",
  "ph:phone-slash-light",
  "ph:phone-slash-thin",
  "ph:phone-slash",
  "ph:phone-thin",
  "ph:phone-x-bold",
  "ph:phone-x-duotone",
  "ph:phone-x-fill",
  "ph:phone-x-light",
  "ph:phone-x-thin",
  "ph:phone-x",
  "ph:phone",
  "ph:phosphor-logo-bold",
  "ph:phosphor-logo-duotone",
  "ph:phosphor-logo-fill",
  "ph:phosphor-logo-light",
  "ph:phosphor-logo-thin",
  "ph:phosphor-logo",
  "ph:pi-bold",
  "ph:pi-duotone",
  "ph:pi-fill",
  "ph:pi-light",
  "ph:pi-thin",
  "ph:pi",
  "ph:piano-keys-bold",
  "ph:piano-keys-duotone",
  "ph:piano-keys-fill",
  "ph:piano-keys-light",
  "ph:piano-keys-thin",
  "ph:piano-keys",
  "ph:picture-in-picture-bold",
  "ph:picture-in-picture-duotone",
  "ph:picture-in-picture-fill",
  "ph:picture-in-picture-light",
  "ph:picture-in-picture-thin",
  "ph:picture-in-picture",
  "ph:piggy-bank-bold",
  "ph:piggy-bank-duotone",
  "ph:piggy-bank-fill",
  "ph:piggy-bank-light",
  "ph:piggy-bank-thin",
  "ph:piggy-bank",
  "ph:pill-bold",
  "ph:pill-duotone",
  "ph:pill-fill",
  "ph:pill-light",
  "ph:pill-thin",
  "ph:pill",
  "ph:pinterest-logo-bold",
  "ph:pinterest-logo-duotone",
  "ph:pinterest-logo-fill",
  "ph:pinterest-logo-light",
  "ph:pinterest-logo-thin",
  "ph:pinterest-logo",
  "ph:pinwheel-bold",
  "ph:pinwheel-duotone",
  "ph:pinwheel-fill",
  "ph:pinwheel-light",
  "ph:pinwheel-thin",
  "ph:pinwheel",
  "ph:pizza-bold",
  "ph:pizza-duotone",
  "ph:pizza-fill",
  "ph:pizza-light",
  "ph:pizza-thin",
  "ph:pizza",
  "ph:placeholder-bold",
  "ph:placeholder-duotone",
  "ph:placeholder-fill",
  "ph:placeholder-light",
  "ph:placeholder-thin",
  "ph:placeholder",
  "ph:planet-bold",
  "ph:planet-duotone",
  "ph:planet-fill",
  "ph:planet-light",
  "ph:planet-thin",
  "ph:planet",
  "ph:plant-bold",
  "ph:plant-duotone",
  "ph:plant-fill",
  "ph:plant-light",
  "ph:plant-thin",
  "ph:plant",
  "ph:play-bold",
  "ph:play-circle-bold",
  "ph:play-circle-duotone",
  "ph:play-circle-fill",
  "ph:play-circle-light",
  "ph:play-circle-thin",
  "ph:play-circle",
  "ph:play-duotone",
  "ph:play-fill",
  "ph:play-light",
  "ph:play-pause-bold",
  "ph:play-pause-duotone",
  "ph:play-pause-fill",
  "ph:play-pause-light",
  "ph:play-pause-thin",
  "ph:play-pause",
  "ph:play-thin",
  "ph:play",
  "ph:playlist-bold",
  "ph:playlist-duotone",
  "ph:playlist-fill",
  "ph:playlist-light",
  "ph:playlist-thin",
  "ph:playlist",
  "ph:plug-bold",
  "ph:plug-charging-bold",
  "ph:plug-charging-duotone",
  "ph:plug-charging-fill",
  "ph:plug-charging-light",
  "ph:plug-charging-thin",
  "ph:plug-charging",
  "ph:plug-duotone",
  "ph:plug-fill",
  "ph:plug-light",
  "ph:plug-thin",
  "ph:plug",
  "ph:plugs-bold",
  "ph:plugs-connected-bold",
  "ph:plugs-connected-duotone",
  "ph:plugs-connected-fill",
  "ph:plugs-connected-light",
  "ph:plugs-connected-thin",
  "ph:plugs-connected",
  "ph:plugs-duotone",
  "ph:plugs-fill",
  "ph:plugs-light",
  "ph:plugs-thin",
  "ph:plugs",
  "ph:plus-bold",
  "ph:plus-circle-bold",
  "ph:plus-circle-duotone",
  "ph:plus-circle-fill",
  "ph:plus-circle-light",
  "ph:plus-circle-thin",
  "ph:plus-circle",
  "ph:plus-duotone",
  "ph:plus-fill",
  "ph:plus-light",
  "ph:plus-minus-bold",
  "ph:plus-minus-duotone",
  "ph:plus-minus-fill",
  "ph:plus-minus-light",
  "ph:plus-minus-thin",
  "ph:plus-minus",
  "ph:plus-square-bold",
  "ph:plus-square-duotone",
  "ph:plus-square-fill",
  "ph:plus-square-light",
  "ph:plus-square-thin",
  "ph:plus-square",
  "ph:plus-thin",
  "ph:plus",
  "ph:poker-chip-bold",
  "ph:poker-chip-duotone",
  "ph:poker-chip-fill",
  "ph:poker-chip-light",
  "ph:poker-chip-thin",
  "ph:poker-chip",
  "ph:police-car-bold",
  "ph:police-car-duotone",
  "ph:police-car-fill",
  "ph:police-car-light",
  "ph:police-car-thin",
  "ph:police-car",
  "ph:polygon-bold",
  "ph:polygon-duotone",
  "ph:polygon-fill",
  "ph:polygon-light",
  "ph:polygon-thin",
  "ph:polygon",
  "ph:popcorn-bold",
  "ph:popcorn-duotone",
  "ph:popcorn-fill",
  "ph:popcorn-light",
  "ph:popcorn-thin",
  "ph:popcorn",
  "ph:potted-plant-bold",
  "ph:potted-plant-duotone",
  "ph:potted-plant-fill",
  "ph:potted-plant-light",
  "ph:potted-plant-thin",
  "ph:potted-plant",
  "ph:power-bold",
  "ph:power-duotone",
  "ph:power-fill",
  "ph:power-light",
  "ph:power-thin",
  "ph:power",
  "ph:prescription-bold",
  "ph:prescription-duotone",
  "ph:prescription-fill",
  "ph:prescription-light",
  "ph:prescription-thin",
  "ph:prescription",
  "ph:presentation-bold",
  "ph:presentation-chart-bold",
  "ph:presentation-chart-duotone",
  "ph:presentation-chart-fill",
  "ph:presentation-chart-light",
  "ph:presentation-chart-thin",
  "ph:presentation-chart",
  "ph:presentation-duotone",
  "ph:presentation-fill",
  "ph:presentation-light",
  "ph:presentation-thin",
  "ph:presentation",
  "ph:printer-bold",
  "ph:printer-duotone",
  "ph:printer-fill",
  "ph:printer-light",
  "ph:printer-thin",
  "ph:printer",
  "ph:prohibit-bold",
  "ph:prohibit-duotone",
  "ph:prohibit-fill",
  "ph:prohibit-inset-bold",
  "ph:prohibit-inset-duotone",
  "ph:prohibit-inset-fill",
  "ph:prohibit-inset-light",
  "ph:prohibit-inset-thin",
  "ph:prohibit-inset",
  "ph:prohibit-light",
  "ph:prohibit-thin",
  "ph:prohibit",
  "ph:projector-screen-bold",
  "ph:projector-screen-chart-bold",
  "ph:projector-screen-chart-duotone",
  "ph:projector-screen-chart-fill",
  "ph:projector-screen-chart-light",
  "ph:projector-screen-chart-thin",
  "ph:projector-screen-chart",
  "ph:projector-screen-duotone",
  "ph:projector-screen-fill",
  "ph:projector-screen-light",
  "ph:projector-screen-thin",
  "ph:projector-screen",
  "ph:pulse-bold",
  "ph:pulse-duotone",
  "ph:pulse-fill",
  "ph:pulse-light",
  "ph:pulse-thin",
  "ph:pulse",
  "ph:push-pin-bold",
  "ph:push-pin-duotone",
  "ph:push-pin-fill",
  "ph:push-pin-light",
  "ph:push-pin-simple-bold",
  "ph:push-pin-simple-duotone",
  "ph:push-pin-simple-fill",
  "ph:push-pin-simple-light",
  "ph:push-pin-simple-slash-bold",
  "ph:push-pin-simple-slash-duotone",
  "ph:push-pin-simple-slash-fill",
  "ph:push-pin-simple-slash-light",
  "ph:push-pin-simple-slash-thin",
  "ph:push-pin-simple-slash",
  "ph:push-pin-simple-thin",
  "ph:push-pin-simple",
  "ph:push-pin-slash-bold",
  "ph:push-pin-slash-duotone",
  "ph:push-pin-slash-fill",
  "ph:push-pin-slash-light",
  "ph:push-pin-slash-thin",
  "ph:push-pin-slash",
  "ph:push-pin-thin",
  "ph:push-pin",
  "ph:puzzle-piece-bold",
  "ph:puzzle-piece-duotone",
  "ph:puzzle-piece-fill",
  "ph:puzzle-piece-light",
  "ph:puzzle-piece-thin",
  "ph:puzzle-piece",
  "ph:qr-code-bold",
  "ph:qr-code-duotone",
  "ph:qr-code-fill",
  "ph:qr-code-light",
  "ph:qr-code-thin",
  "ph:qr-code",
  "ph:question-bold",
  "ph:question-duotone",
  "ph:question-fill",
  "ph:question-light",
  "ph:question-thin",
  "ph:question",
  "ph:queue-bold",
  "ph:queue-duotone",
  "ph:queue-fill",
  "ph:queue-light",
  "ph:queue-thin",
  "ph:queue",
  "ph:quotes-bold",
  "ph:quotes-duotone",
  "ph:quotes-fill",
  "ph:quotes-light",
  "ph:quotes-thin",
  "ph:quotes",
  "ph:radical-bold",
  "ph:radical-duotone",
  "ph:radical-fill",
  "ph:radical-light",
  "ph:radical-thin",
  "ph:radical",
  "ph:radio-bold",
  "ph:radio-button-bold",
  "ph:radio-button-duotone",
  "ph:radio-button-fill",
  "ph:radio-button-light",
  "ph:radio-button-thin",
  "ph:radio-button",
  "ph:radio-duotone",
  "ph:radio-fill",
  "ph:radio-light",
  "ph:radio-thin",
  "ph:radio",
  "ph:radioactive-bold",
  "ph:radioactive-duotone",
  "ph:radioactive-fill",
  "ph:radioactive-light",
  "ph:radioactive-thin",
  "ph:radioactive",
  "ph:rainbow-bold",
  "ph:rainbow-cloud-bold",
  "ph:rainbow-cloud-duotone",
  "ph:rainbow-cloud-fill",
  "ph:rainbow-cloud-light",
  "ph:rainbow-cloud-thin",
  "ph:rainbow-cloud",
  "ph:rainbow-duotone",
  "ph:rainbow-fill",
  "ph:rainbow-light",
  "ph:rainbow-thin",
  "ph:rainbow",
  "ph:read-cv-logo-bold",
  "ph:read-cv-logo-duotone",
  "ph:read-cv-logo-fill",
  "ph:read-cv-logo-light",
  "ph:read-cv-logo-thin",
  "ph:read-cv-logo",
  "ph:receipt-bold",
  "ph:receipt-duotone",
  "ph:receipt-fill",
  "ph:receipt-light",
  "ph:receipt-thin",
  "ph:receipt-x-bold",
  "ph:receipt-x-duotone",
  "ph:receipt-x-fill",
  "ph:receipt-x-light",
  "ph:receipt-x-thin",
  "ph:receipt-x",
  "ph:receipt",
  "ph:record-bold",
  "ph:record-duotone",
  "ph:record-fill",
  "ph:record-light",
  "ph:record-thin",
  "ph:record",
  "ph:rectangle-bold",
  "ph:rectangle-duotone",
  "ph:rectangle-fill",
  "ph:rectangle-light",
  "ph:rectangle-thin",
  "ph:rectangle",
  "ph:rectangles-two-bold",
  "ph:recycle-bold",
  "ph:recycle-duotone",
  "ph:recycle-fill",
  "ph:recycle-light",
  "ph:recycle-thin",
  "ph:recycle",
  "ph:reddit-logo-bold",
  "ph:reddit-logo-duotone",
  "ph:reddit-logo-fill",
  "ph:reddit-logo-light",
  "ph:reddit-logo-thin",
  "ph:reddit-logo",
  "ph:repeat-bold",
  "ph:repeat-duotone",
  "ph:repeat-fill",
  "ph:repeat-light",
  "ph:repeat-once-bold",
  "ph:repeat-once-duotone",
  "ph:repeat-once-fill",
  "ph:repeat-once-light",
  "ph:repeat-once-thin",
  "ph:repeat-once",
  "ph:repeat-thin",
  "ph:repeat",
  "ph:rewind-bold",
  "ph:rewind-circle-bold",
  "ph:rewind-circle-duotone",
  "ph:rewind-circle-fill",
  "ph:rewind-circle-light",
  "ph:rewind-circle-thin",
  "ph:rewind-circle",
  "ph:rewind-duotone",
  "ph:rewind-fill",
  "ph:rewind-light",
  "ph:rewind-thin",
  "ph:rewind",
  "ph:road-horizon-bold",
  "ph:road-horizon-duotone",
  "ph:road-horizon-fill",
  "ph:road-horizon-light",
  "ph:road-horizon-thin",
  "ph:road-horizon",
  "ph:robot-bold",
  "ph:robot-duotone",
  "ph:robot-fill",
  "ph:robot-light",
  "ph:robot-thin",
  "ph:robot",
  "ph:rocket-bold",
  "ph:rocket-duotone",
  "ph:rocket-fill",
  "ph:rocket-launch-bold",
  "ph:rocket-launch-duotone",
  "ph:rocket-launch-fill",
  "ph:rocket-launch-light",
  "ph:rocket-launch-thin",
  "ph:rocket-launch",
  "ph:rocket-light",
  "ph:rocket-thin",
  "ph:rocket",
  "ph:rows-bold",
  "ph:rows-duotone",
  "ph:rows-fill",
  "ph:rows-light",
  "ph:rows-thin",
  "ph:rows",
  "ph:rss-bold",
  "ph:rss-duotone",
  "ph:rss-fill",
  "ph:rss-light",
  "ph:rss-simple-bold",
  "ph:rss-simple-duotone",
  "ph:rss-simple-fill",
  "ph:rss-simple-light",
  "ph:rss-simple-thin",
  "ph:rss-simple",
  "ph:rss-thin",
  "ph:rss",
  "ph:rug-bold",
  "ph:rug-duotone",
  "ph:rug-fill",
  "ph:rug-light",
  "ph:rug-thin",
  "ph:rug",
  "ph:ruler-bold",
  "ph:ruler-duotone",
  "ph:ruler-fill",
  "ph:ruler-light",
  "ph:ruler-thin",
  "ph:ruler",
  "ph:scales-bold",
  "ph:scales-duotone",
  "ph:scales-fill",
  "ph:scales-light",
  "ph:scales-thin",
  "ph:scales",
  "ph:scan-bold",
  "ph:scan-duotone",
  "ph:scan-fill",
  "ph:scan-light",
  "ph:scan-thin",
  "ph:scan",
  "ph:scissors-bold",
  "ph:scissors-duotone",
  "ph:scissors-fill",
  "ph:scissors-light",
  "ph:scissors-thin",
  "ph:scissors",
  "ph:scooter-bold",
  "ph:scooter-duotone",
  "ph:scooter-fill",
  "ph:scooter-light",
  "ph:scooter-thin",
  "ph:scooter",
  "ph:screencast-bold",
  "ph:screencast-duotone",
  "ph:screencast-fill",
  "ph:screencast-light",
  "ph:screencast-thin",
  "ph:screencast",
  "ph:scribble-loop-bold",
  "ph:scribble-loop-duotone",
  "ph:scribble-loop-fill",
  "ph:scribble-loop-light",
  "ph:scribble-loop-thin",
  "ph:scribble-loop",
  "ph:scroll-bold",
  "ph:scroll-duotone",
  "ph:scroll-fill",
  "ph:scroll-light",
  "ph:scroll-thin",
  "ph:scroll",
  "ph:seal-bold",
  "ph:seal-check-bold",
  "ph:seal-check-duotone",
  "ph:seal-check-fill",
  "ph:seal-check-light",
  "ph:seal-check-thin",
  "ph:seal-check",
  "ph:seal-duotone",
  "ph:seal-fill",
  "ph:seal-light",
  "ph:seal-question-bold",
  "ph:seal-question-duotone",
  "ph:seal-question-fill",
  "ph:seal-question-light",
  "ph:seal-question-thin",
  "ph:seal-question",
  "ph:seal-thin",
  "ph:seal-warning-bold",
  "ph:seal-warning-duotone",
  "ph:seal-warning-fill",
  "ph:seal-warning-light",
  "ph:seal-warning-thin",
  "ph:seal-warning",
  "ph:seal",
  "ph:selection-all-bold",
  "ph:selection-all-duotone",
  "ph:selection-all-fill",
  "ph:selection-all-light",
  "ph:selection-all-thin",
  "ph:selection-all",
  "ph:selection-background-bold",
  "ph:selection-background-duotone",
  "ph:selection-background-fill",
  "ph:selection-background-light",
  "ph:selection-background-thin",
  "ph:selection-background",
  "ph:selection-bold",
  "ph:selection-duotone",
  "ph:selection-fill",
  "ph:selection-foreground-bold",
  "ph:selection-foreground-duotone",
  "ph:selection-foreground-fill",
  "ph:selection-foreground-light",
  "ph:selection-foreground-thin",
  "ph:selection-foreground",
  "ph:selection-inverse-bold",
  "ph:selection-inverse-duotone",
  "ph:selection-inverse-fill",
  "ph:selection-inverse-light",
  "ph:selection-inverse-thin",
  "ph:selection-inverse",
  "ph:selection-light",
  "ph:selection-plus-bold",
  "ph:selection-plus-duotone",
  "ph:selection-plus-fill",
  "ph:selection-plus-light",
  "ph:selection-plus-thin",
  "ph:selection-plus",
  "ph:selection-slash-bold",
  "ph:selection-slash-duotone",
  "ph:selection-slash-fill",
  "ph:selection-slash-light",
  "ph:selection-slash-thin",
  "ph:selection-slash",
  "ph:selection-thin",
  "ph:selection",
  "ph:shapes-bold",
  "ph:shapes-duotone",
  "ph:shapes-fill",
  "ph:shapes-light",
  "ph:shapes-thin",
  "ph:shapes",
  "ph:share-bold",
  "ph:share-duotone",
  "ph:share-fat-bold",
  "ph:share-fat-duotone",
  "ph:share-fat-fill",
  "ph:share-fat-light",
  "ph:share-fat-thin",
  "ph:share-fat",
  "ph:share-fill",
  "ph:share-light",
  "ph:share-network-bold",
  "ph:share-network-duotone",
  "ph:share-network-fill",
  "ph:share-network-light",
  "ph:share-network-thin",
  "ph:share-network",
  "ph:share-thin",
  "ph:share",
  "ph:shield-bold",
  "ph:shield-check-bold",
  "ph:shield-check-duotone",
  "ph:shield-check-fill",
  "ph:shield-check-light",
  "ph:shield-check-thin",
  "ph:shield-check",
  "ph:shield-checkered-bold",
  "ph:shield-checkered-duotone",
  "ph:shield-checkered-fill",
  "ph:shield-checkered-light",
  "ph:shield-checkered-thin",
  "ph:shield-checkered",
  "ph:shield-chevron-bold",
  "ph:shield-chevron-duotone",
  "ph:shield-chevron-fill",
  "ph:shield-chevron-light",
  "ph:shield-chevron-thin",
  "ph:shield-chevron",
  "ph:shield-duotone",
  "ph:shield-fill",
  "ph:shield-light",
  "ph:shield-plus-bold",
  "ph:shield-plus-duotone",
  "ph:shield-plus-fill",
  "ph:shield-plus-light",
  "ph:shield-plus-thin",
  "ph:shield-plus",
  "ph:shield-slash-bold",
  "ph:shield-slash-duotone",
  "ph:shield-slash-fill",
  "ph:shield-slash-light",
  "ph:shield-slash-thin",
  "ph:shield-slash",
  "ph:shield-star-bold",
  "ph:shield-star-duotone",
  "ph:shield-star-fill",
  "ph:shield-star-light",
  "ph:shield-star-thin",
  "ph:shield-star",
  "ph:shield-thin",
  "ph:shield-warning-bold",
  "ph:shield-warning-duotone",
  "ph:shield-warning-fill",
  "ph:shield-warning-light",
  "ph:shield-warning-thin",
  "ph:shield-warning",
  "ph:shield",
  "ph:shirt-folded-bold",
  "ph:shirt-folded-duotone",
  "ph:shirt-folded-fill",
  "ph:shirt-folded-light",
  "ph:shirt-folded-thin",
  "ph:shirt-folded",
  "ph:shooting-star-bold",
  "ph:shooting-star-duotone",
  "ph:shooting-star-fill",
  "ph:shooting-star-light",
  "ph:shooting-star-thin",
  "ph:shooting-star",
  "ph:shopping-bag-bold",
  "ph:shopping-bag-duotone",
  "ph:shopping-bag-fill",
  "ph:shopping-bag-light",
  "ph:shopping-bag-open-bold",
  "ph:shopping-bag-open-duotone",
  "ph:shopping-bag-open-fill",
  "ph:shopping-bag-open-light",
  "ph:shopping-bag-open-thin",
  "ph:shopping-bag-open",
  "ph:shopping-bag-thin",
  "ph:shopping-bag",
  "ph:shopping-cart-bold",
  "ph:shopping-cart-duotone",
  "ph:shopping-cart-fill",
  "ph:shopping-cart-light",
  "ph:shopping-cart-simple-bold",
  "ph:shopping-cart-simple-duotone",
  "ph:shopping-cart-simple-fill",
  "ph:shopping-cart-simple-light",
  "ph:shopping-cart-simple-thin",
  "ph:shopping-cart-simple",
  "ph:shopping-cart-thin",
  "ph:shopping-cart",
  "ph:shower-bold",
  "ph:shower-duotone",
  "ph:shower-fill",
  "ph:shower-light",
  "ph:shower-thin",
  "ph:shower",
  "ph:shrimp-bold",
  "ph:shrimp-duotone",
  "ph:shrimp-fill",
  "ph:shrimp-light",
  "ph:shrimp-thin",
  "ph:shrimp",
  "ph:shuffle-angular-bold",
  "ph:shuffle-angular-duotone",
  "ph:shuffle-angular-fill",
  "ph:shuffle-angular-light",
  "ph:shuffle-angular-thin",
  "ph:shuffle-angular",
  "ph:shuffle-bold",
  "ph:shuffle-duotone",
  "ph:shuffle-fill",
  "ph:shuffle-light",
  "ph:shuffle-simple-bold",
  "ph:shuffle-simple-duotone",
  "ph:shuffle-simple-fill",
  "ph:shuffle-simple-light",
  "ph:shuffle-simple-thin",
  "ph:shuffle-simple",
  "ph:shuffle-thin",
  "ph:shuffle",
  "ph:sidebar-bold",
  "ph:sidebar-duotone",
  "ph:sidebar-fill",
  "ph:sidebar-light",
  "ph:sidebar-simple-bold",
  "ph:sidebar-simple-duotone",
  "ph:sidebar-simple-fill",
  "ph:sidebar-simple-light",
  "ph:sidebar-simple-thin",
  "ph:sidebar-simple",
  "ph:sidebar-thin",
  "ph:sidebar",
  "ph:sigma-bold",
  "ph:sigma-duotone",
  "ph:sigma-fill",
  "ph:sigma-light",
  "ph:sigma-thin",
  "ph:sigma",
  "ph:sign-in-bold",
  "ph:sign-in-duotone",
  "ph:sign-in-fill",
  "ph:sign-in-light",
  "ph:sign-in-thin",
  "ph:sign-in",
  "ph:sign-out-bold",
  "ph:sign-out-duotone",
  "ph:sign-out-fill",
  "ph:sign-out-light",
  "ph:sign-out-thin",
  "ph:sign-out",
  "ph:signature-bold",
  "ph:signature-duotone",
  "ph:signature-fill",
  "ph:signature-light",
  "ph:signature-thin",
  "ph:signature",
  "ph:signpost-bold",
  "ph:signpost-duotone",
  "ph:signpost-fill",
  "ph:signpost-light",
  "ph:signpost-thin",
  "ph:signpost",
  "ph:sim-card-bold",
  "ph:sim-card-duotone",
  "ph:sim-card-fill",
  "ph:sim-card-light",
  "ph:sim-card-thin",
  "ph:sim-card",
  "ph:siren-bold",
  "ph:siren-duotone",
  "ph:siren-fill",
  "ph:siren-light",
  "ph:siren-thin",
  "ph:siren",
  "ph:sketch-logo-bold",
  "ph:sketch-logo-duotone",
  "ph:sketch-logo-fill",
  "ph:sketch-logo-light",
  "ph:sketch-logo-thin",
  "ph:sketch-logo",
  "ph:skip-back-bold",
  "ph:skip-back-circle-bold",
  "ph:skip-back-circle-duotone",
  "ph:skip-back-circle-fill",
  "ph:skip-back-circle-light",
  "ph:skip-back-circle-thin",
  "ph:skip-back-circle",
  "ph:skip-back-duotone",
  "ph:skip-back-fill",
  "ph:skip-back-light",
  "ph:skip-back-thin",
  "ph:skip-back",
  "ph:skip-forward-bold",
  "ph:skip-forward-circle-bold",
  "ph:skip-forward-circle-duotone",
  "ph:skip-forward-circle-fill",
  "ph:skip-forward-circle-light",
  "ph:skip-forward-circle-thin",
  "ph:skip-forward-circle",
  "ph:skip-forward-duotone",
  "ph:skip-forward-fill",
  "ph:skip-forward-light",
  "ph:skip-forward-thin",
  "ph:skip-forward",
  "ph:skull-bold",
  "ph:skull-duotone",
  "ph:skull-fill",
  "ph:skull-light",
  "ph:skull-thin",
  "ph:skull",
  "ph:slack-logo-bold",
  "ph:slack-logo-duotone",
  "ph:slack-logo-fill",
  "ph:slack-logo-light",
  "ph:slack-logo-thin",
  "ph:slack-logo",
  "ph:sliders-bold",
  "ph:sliders-duotone",
  "ph:sliders-fill",
  "ph:sliders-horizontal-bold",
  "ph:sliders-horizontal-duotone",
  "ph:sliders-horizontal-fill",
  "ph:sliders-horizontal-light",
  "ph:sliders-horizontal-thin",
  "ph:sliders-horizontal",
  "ph:sliders-light",
  "ph:sliders-thin",
  "ph:sliders",
  "ph:slideshow-bold",
  "ph:slideshow-duotone",
  "ph:slideshow-fill",
  "ph:slideshow-light",
  "ph:slideshow-thin",
  "ph:slideshow",
  "ph:smiley-angry-bold",
  "ph:smiley-angry-duotone",
  "ph:smiley-angry-fill",
  "ph:smiley-angry-light",
  "ph:smiley-angry-thin",
  "ph:smiley-angry",
  "ph:smiley-blank-bold",
  "ph:smiley-blank-duotone",
  "ph:smiley-blank-fill",
  "ph:smiley-blank-light",
  "ph:smiley-blank-thin",
  "ph:smiley-blank",
  "ph:smiley-bold",
  "ph:smiley-duotone",
  "ph:smiley-fill",
  "ph:smiley-light",
  "ph:smiley-meh-bold",
  "ph:smiley-meh-duotone",
  "ph:smiley-meh-fill",
  "ph:smiley-meh-light",
  "ph:smiley-meh-thin",
  "ph:smiley-meh",
  "ph:smiley-nervous-bold",
  "ph:smiley-nervous-duotone",
  "ph:smiley-nervous-fill",
  "ph:smiley-nervous-light",
  "ph:smiley-nervous-thin",
  "ph:smiley-nervous",
  "ph:smiley-sad-bold",
  "ph:smiley-sad-duotone",
  "ph:smiley-sad-fill",
  "ph:smiley-sad-light",
  "ph:smiley-sad-thin",
  "ph:smiley-sad",
  "ph:smiley-sticker-bold",
  "ph:smiley-sticker-duotone",
  "ph:smiley-sticker-fill",
  "ph:smiley-sticker-light",
  "ph:smiley-sticker-thin",
  "ph:smiley-sticker",
  "ph:smiley-thin",
  "ph:smiley-wink-bold",
  "ph:smiley-wink-duotone",
  "ph:smiley-wink-fill",
  "ph:smiley-wink-light",
  "ph:smiley-wink-thin",
  "ph:smiley-wink",
  "ph:smiley-x-eyes-bold",
  "ph:smiley-x-eyes-duotone",
  "ph:smiley-x-eyes-fill",
  "ph:smiley-x-eyes-light",
  "ph:smiley-x-eyes-thin",
  "ph:smiley-x-eyes",
  "ph:smiley",
  "ph:snapchat-logo-bold",
  "ph:snapchat-logo-duotone",
  "ph:snapchat-logo-fill",
  "ph:snapchat-logo-light",
  "ph:snapchat-logo-thin",
  "ph:snapchat-logo",
  "ph:sneaker-bold",
  "ph:sneaker-duotone",
  "ph:sneaker-fill",
  "ph:sneaker-light",
  "ph:sneaker-move-bold",
  "ph:sneaker-move-duotone",
  "ph:sneaker-move-fill",
  "ph:sneaker-move-light",
  "ph:sneaker-move-thin",
  "ph:sneaker-move",
  "ph:sneaker-thin",
  "ph:sneaker",
  "ph:snowflake-bold",
  "ph:snowflake-duotone",
  "ph:snowflake-fill",
  "ph:snowflake-light",
  "ph:snowflake-thin",
  "ph:snowflake",
  "ph:soccer-ball-bold",
  "ph:soccer-ball-duotone",
  "ph:soccer-ball-fill",
  "ph:soccer-ball-light",
  "ph:soccer-ball-thin",
  "ph:soccer-ball",
  "ph:sort-ascending-bold",
  "ph:sort-ascending-duotone",
  "ph:sort-ascending-fill",
  "ph:sort-ascending-light",
  "ph:sort-ascending-thin",
  "ph:sort-ascending",
  "ph:sort-descending-bold",
  "ph:sort-descending-duotone",
  "ph:sort-descending-fill",
  "ph:sort-descending-light",
  "ph:sort-descending-thin",
  "ph:sort-descending",
  "ph:soundcloud-logo-bold",
  "ph:soundcloud-logo-duotone",
  "ph:soundcloud-logo-fill",
  "ph:soundcloud-logo-light",
  "ph:soundcloud-logo-thin",
  "ph:soundcloud-logo",
  "ph:spade-bold",
  "ph:spade-duotone",
  "ph:spade-fill",
  "ph:spade-light",
  "ph:spade-thin",
  "ph:spade",
  "ph:sparkle-bold",
  "ph:sparkle-duotone",
  "ph:sparkle-fill",
  "ph:sparkle-light",
  "ph:sparkle-thin",
  "ph:sparkle",
  "ph:speaker-hifi-bold",
  "ph:speaker-hifi-duotone",
  "ph:speaker-hifi-fill",
  "ph:speaker-hifi-light",
  "ph:speaker-hifi-thin",
  "ph:speaker-hifi",
  "ph:speaker-high-bold",
  "ph:speaker-high-duotone",
  "ph:speaker-high-fill",
  "ph:speaker-high-light",
  "ph:speaker-high-thin",
  "ph:speaker-high",
  "ph:speaker-low-bold",
  "ph:speaker-low-duotone",
  "ph:speaker-low-fill",
  "ph:speaker-low-light",
  "ph:speaker-low-thin",
  "ph:speaker-low",
  "ph:speaker-none-bold",
  "ph:speaker-none-duotone",
  "ph:speaker-none-fill",
  "ph:speaker-none-light",
  "ph:speaker-none-thin",
  "ph:speaker-none",
  "ph:speaker-simple-high-bold",
  "ph:speaker-simple-high-duotone",
  "ph:speaker-simple-high-fill",
  "ph:speaker-simple-high-light",
  "ph:speaker-simple-high-thin",
  "ph:speaker-simple-high",
  "ph:speaker-simple-low-bold",
  "ph:speaker-simple-low-duotone",
  "ph:speaker-simple-low-fill",
  "ph:speaker-simple-low-light",
  "ph:speaker-simple-low-thin",
  "ph:speaker-simple-low",
  "ph:speaker-simple-none-bold",
  "ph:speaker-simple-none-duotone",
  "ph:speaker-simple-none-fill",
  "ph:speaker-simple-none-light",
  "ph:speaker-simple-none-thin",
  "ph:speaker-simple-none",
  "ph:speaker-simple-slash-bold",
  "ph:speaker-simple-slash-duotone",
  "ph:speaker-simple-slash-fill",
  "ph:speaker-simple-slash-light",
  "ph:speaker-simple-slash-thin",
  "ph:speaker-simple-slash",
  "ph:speaker-simple-x-bold",
  "ph:speaker-simple-x-duotone",
  "ph:speaker-simple-x-fill",
  "ph:speaker-simple-x-light",
  "ph:speaker-simple-x-thin",
  "ph:speaker-simple-x",
  "ph:speaker-slash-bold",
  "ph:speaker-slash-duotone",
  "ph:speaker-slash-fill",
  "ph:speaker-slash-light",
  "ph:speaker-slash-thin",
  "ph:speaker-slash",
  "ph:speaker-x-bold",
  "ph:speaker-x-duotone",
  "ph:speaker-x-fill",
  "ph:speaker-x-light",
  "ph:speaker-x-thin",
  "ph:speaker-x",
  "ph:spinner-bold",
  "ph:spinner-duotone",
  "ph:spinner-fill",
  "ph:spinner-gap-bold",
  "ph:spinner-gap-duotone",
  "ph:spinner-gap-fill",
  "ph:spinner-gap-light",
  "ph:spinner-gap-thin",
  "ph:spinner-gap",
  "ph:spinner-light",
  "ph:spinner-thin",
  "ph:spinner",
  "ph:spiral-bold",
  "ph:spiral-duotone",
  "ph:spiral-fill",
  "ph:spiral-light",
  "ph:spiral-thin",
  "ph:spiral",
  "ph:split-horizontal-bold",
  "ph:split-horizontal-duotone",
  "ph:split-horizontal-fill",
  "ph:split-horizontal-light",
  "ph:split-horizontal-thin",
  "ph:split-horizontal",
  "ph:split-vertical-bold",
  "ph:split-vertical-duotone",
  "ph:split-vertical-fill",
  "ph:split-vertical-light",
  "ph:split-vertical-thin",
  "ph:split-vertical",
  "ph:spotify-logo-bold",
  "ph:spotify-logo-duotone",
  "ph:spotify-logo-fill",
  "ph:spotify-logo-light",
  "ph:spotify-logo-thin",
  "ph:spotify-logo",
  "ph:square-bold",
  "ph:square-duotone",
  "ph:square-fill",
  "ph:square-half-bold",
  "ph:square-half-bottom-bold",
  "ph:square-half-bottom-duotone",
  "ph:square-half-bottom-fill",
  "ph:square-half-bottom-light",
  "ph:square-half-bottom-thin",
  "ph:square-half-bottom",
  "ph:square-half-duotone",
  "ph:square-half-fill",
  "ph:square-half-light",
  "ph:square-half-thin",
  "ph:square-half",
  "ph:square-light",
  "ph:square-logo-bold",
  "ph:square-logo-duotone",
  "ph:square-logo-fill",
  "ph:square-logo-light",
  "ph:square-logo-thin",
  "ph:square-logo",
  "ph:square-split-horizontal-bold",
  "ph:square-split-horizontal-duotone",
  "ph:square-split-horizontal-fill",
  "ph:square-split-horizontal-light",
  "ph:square-split-horizontal-thin",
  "ph:square-split-horizontal",
  "ph:square-split-vertical-bold",
  "ph:square-split-vertical-duotone",
  "ph:square-split-vertical-fill",
  "ph:square-split-vertical-light",
  "ph:square-split-vertical-thin",
  "ph:square-split-vertical",
  "ph:square-thin",
  "ph:square",
  "ph:squares-four-bold",
  "ph:squares-four-duotone",
  "ph:squares-four-fill",
  "ph:squares-four-light",
  "ph:squares-four-thin",
  "ph:squares-four",
  "ph:stack-bold",
  "ph:stack-duotone",
  "ph:stack-fill",
  "ph:stack-light",
  "ph:stack-overflow-logo-bold",
  "ph:stack-overflow-logo-duotone",
  "ph:stack-overflow-logo-fill",
  "ph:stack-overflow-logo-light",
  "ph:stack-overflow-logo-thin",
  "ph:stack-overflow-logo",
  "ph:stack-simple-bold",
  "ph:stack-simple-duotone",
  "ph:stack-simple-fill",
  "ph:stack-simple-light",
  "ph:stack-simple-thin",
  "ph:stack-simple",
  "ph:stack-thin",
  "ph:stack",
  "ph:stairs-bold",
  "ph:stairs-duotone",
  "ph:stairs-fill",
  "ph:stairs-light",
  "ph:stairs-thin",
  "ph:stairs",
  "ph:stamp-bold",
  "ph:stamp-duotone",
  "ph:stamp-fill",
  "ph:stamp-light",
  "ph:stamp-thin",
  "ph:stamp",
  "ph:star-and-crescent-bold",
  "ph:star-and-crescent-duotone",
  "ph:star-and-crescent-fill",
  "ph:star-and-crescent-light",
  "ph:star-and-crescent-thin",
  "ph:star-and-crescent",
  "ph:star-bold",
  "ph:star-duotone",
  "ph:star-fill",
  "ph:star-four-bold",
  "ph:star-four-duotone",
  "ph:star-four-fill",
  "ph:star-four-light",
  "ph:star-four-thin",
  "ph:star-four",
  "ph:star-half-bold",
  "ph:star-half-duotone",
  "ph:star-half-fill",
  "ph:star-half-light",
  "ph:star-half-thin",
  "ph:star-half",
  "ph:star-light",
  "ph:star-of-david-bold",
  "ph:star-of-david-duotone",
  "ph:star-of-david-fill",
  "ph:star-of-david-light",
  "ph:star-of-david-thin",
  "ph:star-of-david",
  "ph:star-thin",
  "ph:star",
  "ph:steering-wheel-bold",
  "ph:steering-wheel-duotone",
  "ph:steering-wheel-fill",
  "ph:steering-wheel-light",
  "ph:steering-wheel-thin",
  "ph:steering-wheel",
  "ph:steps-bold",
  "ph:steps-duotone",
  "ph:steps-fill",
  "ph:steps-light",
  "ph:steps-thin",
  "ph:steps",
  "ph:stethoscope-bold",
  "ph:stethoscope-duotone",
  "ph:stethoscope-fill",
  "ph:stethoscope-light",
  "ph:stethoscope-thin",
  "ph:stethoscope",
  "ph:sticker-bold",
  "ph:sticker-duotone",
  "ph:sticker-fill",
  "ph:sticker-light",
  "ph:sticker-thin",
  "ph:sticker",
  "ph:stool-bold",
  "ph:stool-duotone",
  "ph:stool-fill",
  "ph:stool-light",
  "ph:stool-thin",
  "ph:stool",
  "ph:stop-bold",
  "ph:stop-circle-bold",
  "ph:stop-circle-duotone",
  "ph:stop-circle-fill",
  "ph:stop-circle-light",
  "ph:stop-circle-thin",
  "ph:stop-circle",
  "ph:stop-duotone",
  "ph:stop-fill",
  "ph:stop-light",
  "ph:stop-thin",
  "ph:stop",
  "ph:storefront-bold",
  "ph:storefront-duotone",
  "ph:storefront-fill",
  "ph:storefront-light",
  "ph:storefront-thin",
  "ph:storefront",
  "ph:strategy-bold",
  "ph:strategy-duotone",
  "ph:strategy-fill",
  "ph:strategy-light",
  "ph:strategy-thin",
  "ph:strategy",
  "ph:stripe-logo-bold",
  "ph:stripe-logo-duotone",
  "ph:stripe-logo-fill",
  "ph:stripe-logo-light",
  "ph:stripe-logo-thin",
  "ph:stripe-logo",
  "ph:student-bold",
  "ph:student-duotone",
  "ph:student-fill",
  "ph:student-light",
  "ph:student-thin",
  "ph:student",
  "ph:subtitles-bold",
  "ph:subtitles-duotone",
  "ph:subtitles-fill",
  "ph:subtitles-light",
  "ph:subtitles-thin",
  "ph:subtitles",
  "ph:subtract-bold",
  "ph:subtract-duotone",
  "ph:subtract-fill",
  "ph:subtract-light",
  "ph:subtract-square-bold",
  "ph:subtract-square-duotone",
  "ph:subtract-square-fill",
  "ph:subtract-square-light",
  "ph:subtract-square-thin",
  "ph:subtract-square",
  "ph:subtract-thin",
  "ph:subtract",
  "ph:suitcase-bold",
  "ph:suitcase-duotone",
  "ph:suitcase-fill",
  "ph:suitcase-light",
  "ph:suitcase-rolling-bold",
  "ph:suitcase-rolling-duotone",
  "ph:suitcase-rolling-fill",
  "ph:suitcase-rolling-light",
  "ph:suitcase-rolling-thin",
  "ph:suitcase-rolling",
  "ph:suitcase-simple-bold",
  "ph:suitcase-simple-duotone",
  "ph:suitcase-simple-fill",
  "ph:suitcase-simple-light",
  "ph:suitcase-simple-thin",
  "ph:suitcase-simple",
  "ph:suitcase-thin",
  "ph:suitcase",
  "ph:sun-bold",
  "ph:sun-dim-bold",
  "ph:sun-dim-duotone",
  "ph:sun-dim-fill",
  "ph:sun-dim-light",
  "ph:sun-dim-thin",
  "ph:sun-dim",
  "ph:sun-duotone",
  "ph:sun-fill",
  "ph:sun-horizon-bold",
  "ph:sun-horizon-duotone",
  "ph:sun-horizon-fill",
  "ph:sun-horizon-light",
  "ph:sun-horizon-thin",
  "ph:sun-horizon",
  "ph:sun-light",
  "ph:sun-thin",
  "ph:sun",
  "ph:sunglasses-bold",
  "ph:sunglasses-duotone",
  "ph:sunglasses-fill",
  "ph:sunglasses-light",
  "ph:sunglasses-thin",
  "ph:sunglasses",
  "ph:swap-bold",
  "ph:swap-duotone",
  "ph:swap-fill",
  "ph:swap-light",
  "ph:swap-thin",
  "ph:swap",
  "ph:swatches-bold",
  "ph:swatches-duotone",
  "ph:swatches-fill",
  "ph:swatches-light",
  "ph:swatches-thin",
  "ph:swatches",
  "ph:swimming-pool-bold",
  "ph:swimming-pool-duotone",
  "ph:swimming-pool-fill",
  "ph:swimming-pool-light",
  "ph:swimming-pool-thin",
  "ph:swimming-pool",
  "ph:sword-bold",
  "ph:sword-duotone",
  "ph:sword-fill",
  "ph:sword-light",
  "ph:sword-thin",
  "ph:sword",
  "ph:synagogue-bold",
  "ph:synagogue-duotone",
  "ph:synagogue-fill",
  "ph:synagogue-light",
  "ph:synagogue-thin",
  "ph:synagogue",
  "ph:syringe-bold",
  "ph:syringe-duotone",
  "ph:syringe-fill",
  "ph:syringe-light",
  "ph:syringe-thin",
  "ph:syringe",
  "ph:t-shirt-bold",
  "ph:t-shirt-duotone",
  "ph:t-shirt-fill",
  "ph:t-shirt-light",
  "ph:t-shirt-thin",
  "ph:t-shirt",
  "ph:table-bold",
  "ph:table-duotone",
  "ph:table-fill",
  "ph:table-light",
  "ph:table-thin",
  "ph:table",
  "ph:tabs-bold",
  "ph:tabs-duotone",
  "ph:tabs-fill",
  "ph:tabs-light",
  "ph:tabs-thin",
  "ph:tabs",
  "ph:tag-bold",
  "ph:tag-chevron-bold",
  "ph:tag-chevron-duotone",
  "ph:tag-chevron-fill",
  "ph:tag-chevron-light",
  "ph:tag-chevron-thin",
  "ph:tag-chevron",
  "ph:tag-duotone",
  "ph:tag-fill",
  "ph:tag-light",
  "ph:tag-simple-bold",
  "ph:tag-simple-duotone",
  "ph:tag-simple-fill",
  "ph:tag-simple-light",
  "ph:tag-simple-thin",
  "ph:tag-simple",
  "ph:tag-thin",
  "ph:tag",
  "ph:target-bold",
  "ph:target-duotone",
  "ph:target-fill",
  "ph:target-light",
  "ph:target-thin",
  "ph:target",
  "ph:taxi-bold",
  "ph:taxi-duotone",
  "ph:taxi-fill",
  "ph:taxi-light",
  "ph:taxi-thin",
  "ph:taxi",
  "ph:telegram-logo-bold",
  "ph:telegram-logo-duotone",
  "ph:telegram-logo-fill",
  "ph:telegram-logo-light",
  "ph:telegram-logo-thin",
  "ph:telegram-logo",
  "ph:television-bold",
  "ph:television-duotone",
  "ph:television-fill",
  "ph:television-light",
  "ph:television-simple-bold",
  "ph:television-simple-duotone",
  "ph:television-simple-fill",
  "ph:television-simple-light",
  "ph:television-simple-thin",
  "ph:television-simple",
  "ph:television-thin",
  "ph:television",
  "ph:tennis-ball-bold",
  "ph:tennis-ball-duotone",
  "ph:tennis-ball-fill",
  "ph:tennis-ball-light",
  "ph:tennis-ball-thin",
  "ph:tennis-ball",
  "ph:tent-bold",
  "ph:tent-duotone",
  "ph:tent-fill",
  "ph:tent-light",
  "ph:tent-thin",
  "ph:tent",
  "ph:terminal-bold",
  "ph:terminal-duotone",
  "ph:terminal-fill",
  "ph:terminal-light",
  "ph:terminal-thin",
  "ph:terminal-window-bold",
  "ph:terminal-window-duotone",
  "ph:terminal-window-fill",
  "ph:terminal-window-light",
  "ph:terminal-window-thin",
  "ph:terminal-window",
  "ph:terminal",
  "ph:test-tube-bold",
  "ph:test-tube-duotone",
  "ph:test-tube-fill",
  "ph:test-tube-light",
  "ph:test-tube-thin",
  "ph:test-tube",
  "ph:text-a-underline-bold",
  "ph:text-a-underline-duotone",
  "ph:text-a-underline-fill",
  "ph:text-a-underline-light",
  "ph:text-a-underline-thin",
  "ph:text-a-underline",
  "ph:text-aa-bold",
  "ph:text-aa-duotone",
  "ph:text-aa-fill",
  "ph:text-aa-light",
  "ph:text-aa-thin",
  "ph:text-aa",
  "ph:text-align-center-bold",
  "ph:text-align-center-duotone",
  "ph:text-align-center-fill",
  "ph:text-align-center-light",
  "ph:text-align-center-thin",
  "ph:text-align-center",
  "ph:text-align-justify-bold",
  "ph:text-align-justify-duotone",
  "ph:text-align-justify-fill",
  "ph:text-align-justify-light",
  "ph:text-align-justify-thin",
  "ph:text-align-justify",
  "ph:text-align-left-bold",
  "ph:text-align-left-duotone",
  "ph:text-align-left-fill",
  "ph:text-align-left-light",
  "ph:text-align-left-thin",
  "ph:text-align-left",
  "ph:text-align-right-bold",
  "ph:text-align-right-duotone",
  "ph:text-align-right-fill",
  "ph:text-align-right-light",
  "ph:text-align-right-thin",
  "ph:text-align-right",
  "ph:text-b-bold",
  "ph:text-b-duotone",
  "ph:text-b-fill",
  "ph:text-b-light",
  "ph:text-b-thin",
  "ph:text-b",
  "ph:text-bolder-bold",
  "ph:text-bolder-light",
  "ph:text-bolder-thin",
  "ph:text-bolder",
  "ph:text-columns-bold",
  "ph:text-columns-duotone",
  "ph:text-columns-fill",
  "ph:text-columns-light",
  "ph:text-columns-thin",
  "ph:text-columns",
  "ph:text-h-bold",
  "ph:text-h-duotone",
  "ph:text-h-fill",
  "ph:text-h-five-bold",
  "ph:text-h-five-duotone",
  "ph:text-h-five-fill",
  "ph:text-h-five-light",
  "ph:text-h-five-thin",
  "ph:text-h-five",
  "ph:text-h-four-bold",
  "ph:text-h-four-duotone",
  "ph:text-h-four-fill",
  "ph:text-h-four-light",
  "ph:text-h-four-thin",
  "ph:text-h-four",
  "ph:text-h-light",
  "ph:text-h-one-bold",
  "ph:text-h-one-duotone",
  "ph:text-h-one-fill",
  "ph:text-h-one-light",
  "ph:text-h-one-thin",
  "ph:text-h-one",
  "ph:text-h-six-bold",
  "ph:text-h-six-duotone",
  "ph:text-h-six-fill",
  "ph:text-h-six-light",
  "ph:text-h-six-thin",
  "ph:text-h-six",
  "ph:text-h-thin",
  "ph:text-h-three-bold",
  "ph:text-h-three-duotone",
  "ph:text-h-three-fill",
  "ph:text-h-three-light",
  "ph:text-h-three-thin",
  "ph:text-h-three",
  "ph:text-h-two-bold",
  "ph:text-h-two-duotone",
  "ph:text-h-two-fill",
  "ph:text-h-two-light",
  "ph:text-h-two-thin",
  "ph:text-h-two",
  "ph:text-h",
  "ph:text-indent-bold",
  "ph:text-indent-duotone",
  "ph:text-indent-fill",
  "ph:text-indent-light",
  "ph:text-indent-thin",
  "ph:text-indent",
  "ph:text-italic-bold",
  "ph:text-italic-duotone",
  "ph:text-italic-fill",
  "ph:text-italic-light",
  "ph:text-italic-thin",
  "ph:text-italic",
  "ph:text-outdent-bold",
  "ph:text-outdent-duotone",
  "ph:text-outdent-fill",
  "ph:text-outdent-light",
  "ph:text-outdent-thin",
  "ph:text-outdent",
  "ph:text-strikethrough-bold",
  "ph:text-strikethrough-duotone",
  "ph:text-strikethrough-fill",
  "ph:text-strikethrough-light",
  "ph:text-strikethrough-thin",
  "ph:text-strikethrough",
  "ph:text-t-bold",
  "ph:text-t-duotone",
  "ph:text-t-fill",
  "ph:text-t-light",
  "ph:text-t-thin",
  "ph:text-t",
  "ph:text-underline-bold",
  "ph:text-underline-duotone",
  "ph:text-underline-fill",
  "ph:text-underline-light",
  "ph:text-underline-thin",
  "ph:text-underline",
  "ph:textbox-bold",
  "ph:textbox-duotone",
  "ph:textbox-fill",
  "ph:textbox-light",
  "ph:textbox-thin",
  "ph:textbox",
  "ph:thermometer-bold",
  "ph:thermometer-cold-bold",
  "ph:thermometer-cold-duotone",
  "ph:thermometer-cold-fill",
  "ph:thermometer-cold-light",
  "ph:thermometer-cold-thin",
  "ph:thermometer-cold",
  "ph:thermometer-duotone",
  "ph:thermometer-fill",
  "ph:thermometer-hot-bold",
  "ph:thermometer-hot-duotone",
  "ph:thermometer-hot-fill",
  "ph:thermometer-hot-light",
  "ph:thermometer-hot-thin",
  "ph:thermometer-hot",
  "ph:thermometer-light",
  "ph:thermometer-simple-bold",
  "ph:thermometer-simple-duotone",
  "ph:thermometer-simple-fill",
  "ph:thermometer-simple-light",
  "ph:thermometer-simple-thin",
  "ph:thermometer-simple",
  "ph:thermometer-thin",
  "ph:thermometer",
  "ph:thumbs-down-bold",
  "ph:thumbs-down-duotone",
  "ph:thumbs-down-fill",
  "ph:thumbs-down-light",
  "ph:thumbs-down-thin",
  "ph:thumbs-down",
  "ph:thumbs-up-bold",
  "ph:thumbs-up-duotone",
  "ph:thumbs-up-fill",
  "ph:thumbs-up-light",
  "ph:thumbs-up-thin",
  "ph:thumbs-up",
  "ph:ticket-bold",
  "ph:ticket-duotone",
  "ph:ticket-fill",
  "ph:ticket-light",
  "ph:ticket-thin",
  "ph:ticket",
  "ph:tidal-logo-bold",
  "ph:tidal-logo-duotone",
  "ph:tidal-logo-fill",
  "ph:tidal-logo-light",
  "ph:tidal-logo-thin",
  "ph:tidal-logo",
  "ph:tiktok-logo-bold",
  "ph:tiktok-logo-duotone",
  "ph:tiktok-logo-fill",
  "ph:tiktok-logo-light",
  "ph:tiktok-logo-thin",
  "ph:tiktok-logo",
  "ph:timer-bold",
  "ph:timer-duotone",
  "ph:timer-fill",
  "ph:timer-light",
  "ph:timer-thin",
  "ph:timer",
  "ph:tipi-bold",
  "ph:tipi-duotone",
  "ph:tipi-fill",
  "ph:tipi-light",
  "ph:tipi-thin",
  "ph:tipi",
  "ph:toggle-left-bold",
  "ph:toggle-left-duotone",
  "ph:toggle-left-fill",
  "ph:toggle-left-light",
  "ph:toggle-left-thin",
  "ph:toggle-left",
  "ph:toggle-right-bold",
  "ph:toggle-right-duotone",
  "ph:toggle-right-fill",
  "ph:toggle-right-light",
  "ph:toggle-right-thin",
  "ph:toggle-right",
  "ph:toilet-bold",
  "ph:toilet-duotone",
  "ph:toilet-fill",
  "ph:toilet-light",
  "ph:toilet-paper-bold",
  "ph:toilet-paper-duotone",
  "ph:toilet-paper-fill",
  "ph:toilet-paper-light",
  "ph:toilet-paper-thin",
  "ph:toilet-paper",
  "ph:toilet-thin",
  "ph:toilet",
  "ph:toolbox-bold",
  "ph:toolbox-duotone",
  "ph:toolbox-fill",
  "ph:toolbox-light",
  "ph:toolbox-thin",
  "ph:toolbox",
  "ph:tooth-bold",
  "ph:tooth-duotone",
  "ph:tooth-fill",
  "ph:tooth-light",
  "ph:tooth-thin",
  "ph:tooth",
  "ph:tote-bold",
  "ph:tote-duotone",
  "ph:tote-fill",
  "ph:tote-light",
  "ph:tote-simple-bold",
  "ph:tote-simple-duotone",
  "ph:tote-simple-fill",
  "ph:tote-simple-light",
  "ph:tote-simple-thin",
  "ph:tote-simple",
  "ph:tote-thin",
  "ph:tote",
  "ph:trademark-bold",
  "ph:trademark-duotone",
  "ph:trademark-fill",
  "ph:trademark-light",
  "ph:trademark-registered-bold",
  "ph:trademark-registered-duotone",
  "ph:trademark-registered-fill",
  "ph:trademark-registered-light",
  "ph:trademark-registered-thin",
  "ph:trademark-registered",
  "ph:trademark-thin",
  "ph:trademark",
  "ph:traffic-cone-bold",
  "ph:traffic-cone-duotone",
  "ph:traffic-cone-fill",
  "ph:traffic-cone-light",
  "ph:traffic-cone-thin",
  "ph:traffic-cone",
  "ph:traffic-sign-bold",
  "ph:traffic-sign-duotone",
  "ph:traffic-sign-fill",
  "ph:traffic-sign-light",
  "ph:traffic-sign-thin",
  "ph:traffic-sign",
  "ph:traffic-signal-bold",
  "ph:traffic-signal-duotone",
  "ph:traffic-signal-fill",
  "ph:traffic-signal-light",
  "ph:traffic-signal-thin",
  "ph:traffic-signal",
  "ph:train-bold",
  "ph:train-duotone",
  "ph:train-fill",
  "ph:train-light",
  "ph:train-regional-bold",
  "ph:train-regional-duotone",
  "ph:train-regional-fill",
  "ph:train-regional-light",
  "ph:train-regional-thin",
  "ph:train-regional",
  "ph:train-simple-bold",
  "ph:train-simple-duotone",
  "ph:train-simple-fill",
  "ph:train-simple-light",
  "ph:train-simple-thin",
  "ph:train-simple",
  "ph:train-thin",
  "ph:train",
  "ph:tram-bold",
  "ph:tram-duotone",
  "ph:tram-fill",
  "ph:tram-light",
  "ph:tram-thin",
  "ph:tram",
  "ph:translate-bold",
  "ph:translate-duotone",
  "ph:translate-fill",
  "ph:translate-light",
  "ph:translate-thin",
  "ph:translate",
  "ph:trash-bold",
  "ph:trash-duotone",
  "ph:trash-fill",
  "ph:trash-light",
  "ph:trash-simple-bold",
  "ph:trash-simple-duotone",
  "ph:trash-simple-fill",
  "ph:trash-simple-light",
  "ph:trash-simple-thin",
  "ph:trash-simple",
  "ph:trash-thin",
  "ph:trash",
  "ph:tray-bold",
  "ph:tray-duotone",
  "ph:tray-fill",
  "ph:tray-light",
  "ph:tray-thin",
  "ph:tray",
  "ph:tree-bold",
  "ph:tree-duotone",
  "ph:tree-evergreen-bold",
  "ph:tree-evergreen-duotone",
  "ph:tree-evergreen-fill",
  "ph:tree-evergreen-light",
  "ph:tree-evergreen-thin",
  "ph:tree-evergreen",
  "ph:tree-fill",
  "ph:tree-light",
  "ph:tree-palm-bold",
  "ph:tree-palm-duotone",
  "ph:tree-palm-fill",
  "ph:tree-palm-light",
  "ph:tree-palm-thin",
  "ph:tree-palm",
  "ph:tree-structure-bold",
  "ph:tree-structure-duotone",
  "ph:tree-structure-fill",
  "ph:tree-structure-light",
  "ph:tree-structure-thin",
  "ph:tree-structure",
  "ph:tree-thin",
  "ph:tree",
  "ph:trend-down-bold",
  "ph:trend-down-duotone",
  "ph:trend-down-fill",
  "ph:trend-down-light",
  "ph:trend-down-thin",
  "ph:trend-down",
  "ph:trend-up-bold",
  "ph:trend-up-duotone",
  "ph:trend-up-fill",
  "ph:trend-up-light",
  "ph:trend-up-thin",
  "ph:trend-up",
  "ph:triangle-bold",
  "ph:triangle-duotone",
  "ph:triangle-fill",
  "ph:triangle-light",
  "ph:triangle-thin",
  "ph:triangle",
  "ph:trophy-bold",
  "ph:trophy-duotone",
  "ph:trophy-fill",
  "ph:trophy-light",
  "ph:trophy-thin",
  "ph:trophy",
  "ph:truck-bold",
  "ph:truck-duotone",
  "ph:truck-fill",
  "ph:truck-light",
  "ph:truck-thin",
  "ph:truck",
  "ph:twitch-logo-bold",
  "ph:twitch-logo-duotone",
  "ph:twitch-logo-fill",
  "ph:twitch-logo-light",
  "ph:twitch-logo-thin",
  "ph:twitch-logo",
  "ph:twitter-logo-bold",
  "ph:twitter-logo-duotone",
  "ph:twitter-logo-fill",
  "ph:twitter-logo-light",
  "ph:twitter-logo-thin",
  "ph:twitter-logo",
  "ph:umbrella-bold",
  "ph:umbrella-duotone",
  "ph:umbrella-fill",
  "ph:umbrella-light",
  "ph:umbrella-simple-bold",
  "ph:umbrella-simple-duotone",
  "ph:umbrella-simple-fill",
  "ph:umbrella-simple-light",
  "ph:umbrella-simple-thin",
  "ph:umbrella-simple",
  "ph:umbrella-thin",
  "ph:umbrella",
  "ph:unite-bold",
  "ph:unite-duotone",
  "ph:unite-fill",
  "ph:unite-light",
  "ph:unite-square-bold",
  "ph:unite-square-duotone",
  "ph:unite-square-fill",
  "ph:unite-square-light",
  "ph:unite-square-thin",
  "ph:unite-square",
  "ph:unite-thin",
  "ph:unite",
  "ph:upload-bold",
  "ph:upload-duotone",
  "ph:upload-fill",
  "ph:upload-light",
  "ph:upload-simple-bold",
  "ph:upload-simple-duotone",
  "ph:upload-simple-fill",
  "ph:upload-simple-light",
  "ph:upload-simple-thin",
  "ph:upload-simple",
  "ph:upload-thin",
  "ph:upload",
  "ph:usb-bold",
  "ph:usb-duotone",
  "ph:usb-fill",
  "ph:usb-light",
  "ph:usb-thin",
  "ph:usb",
  "ph:user-bold",
  "ph:user-circle-bold",
  "ph:user-circle-duotone",
  "ph:user-circle-fill",
  "ph:user-circle-gear-bold",
  "ph:user-circle-gear-duotone",
  "ph:user-circle-gear-fill",
  "ph:user-circle-gear-light",
  "ph:user-circle-gear-thin",
  "ph:user-circle-gear",
  "ph:user-circle-light",
  "ph:user-circle-minus-bold",
  "ph:user-circle-minus-duotone",
  "ph:user-circle-minus-fill",
  "ph:user-circle-minus-light",
  "ph:user-circle-minus-thin",
  "ph:user-circle-minus",
  "ph:user-circle-plus-bold",
  "ph:user-circle-plus-duotone",
  "ph:user-circle-plus-fill",
  "ph:user-circle-plus-light",
  "ph:user-circle-plus-thin",
  "ph:user-circle-plus",
  "ph:user-circle-thin",
  "ph:user-circle",
  "ph:user-duotone",
  "ph:user-fill",
  "ph:user-focus-bold",
  "ph:user-focus-duotone",
  "ph:user-focus-fill",
  "ph:user-focus-light",
  "ph:user-focus-thin",
  "ph:user-focus",
  "ph:user-gear-bold",
  "ph:user-gear-duotone",
  "ph:user-gear-fill",
  "ph:user-gear-light",
  "ph:user-gear-thin",
  "ph:user-gear",
  "ph:user-light",
  "ph:user-list-bold",
  "ph:user-list-duotone",
  "ph:user-list-fill",
  "ph:user-list-light",
  "ph:user-list-thin",
  "ph:user-list",
  "ph:user-minus-bold",
  "ph:user-minus-duotone",
  "ph:user-minus-fill",
  "ph:user-minus-light",
  "ph:user-minus-thin",
  "ph:user-minus",
  "ph:user-plus-bold",
  "ph:user-plus-duotone",
  "ph:user-plus-fill",
  "ph:user-plus-light",
  "ph:user-plus-thin",
  "ph:user-plus",
  "ph:user-rectangle-bold",
  "ph:user-rectangle-duotone",
  "ph:user-rectangle-fill",
  "ph:user-rectangle-light",
  "ph:user-rectangle-thin",
  "ph:user-rectangle",
  "ph:user-square-bold",
  "ph:user-square-duotone",
  "ph:user-square-fill",
  "ph:user-square-light",
  "ph:user-square-thin",
  "ph:user-square",
  "ph:user-switch-bold",
  "ph:user-switch-duotone",
  "ph:user-switch-fill",
  "ph:user-switch-light",
  "ph:user-switch-thin",
  "ph:user-switch",
  "ph:user-thin",
  "ph:user",
  "ph:users-bold",
  "ph:users-duotone",
  "ph:users-fill",
  "ph:users-four-bold",
  "ph:users-four-duotone",
  "ph:users-four-fill",
  "ph:users-four-light",
  "ph:users-four-thin",
  "ph:users-four",
  "ph:users-light",
  "ph:users-thin",
  "ph:users-three-bold",
  "ph:users-three-duotone",
  "ph:users-three-fill",
  "ph:users-three-light",
  "ph:users-three-thin",
  "ph:users-three",
  "ph:users",
  "ph:van-bold",
  "ph:van-duotone",
  "ph:van-fill",
  "ph:van-light",
  "ph:van-thin",
  "ph:van",
  "ph:vault-bold",
  "ph:vault-duotone",
  "ph:vault-fill",
  "ph:vault-light",
  "ph:vault-thin",
  "ph:vault",
  "ph:vibrate-bold",
  "ph:vibrate-duotone",
  "ph:vibrate-fill",
  "ph:vibrate-light",
  "ph:vibrate-thin",
  "ph:vibrate",
  "ph:video-bold",
  "ph:video-camera-bold",
  "ph:video-camera-duotone",
  "ph:video-camera-fill",
  "ph:video-camera-light",
  "ph:video-camera-slash-bold",
  "ph:video-camera-slash-duotone",
  "ph:video-camera-slash-fill",
  "ph:video-camera-slash-light",
  "ph:video-camera-slash-thin",
  "ph:video-camera-slash",
  "ph:video-camera-thin",
  "ph:video-camera",
  "ph:video-duotone",
  "ph:video-fill",
  "ph:video-light",
  "ph:video-thin",
  "ph:video",
  "ph:vignette-bold",
  "ph:vignette-duotone",
  "ph:vignette-fill",
  "ph:vignette-light",
  "ph:vignette-thin",
  "ph:vignette",
  "ph:vinyl-record-bold",
  "ph:vinyl-record-duotone",
  "ph:vinyl-record-fill",
  "ph:vinyl-record-light",
  "ph:vinyl-record-thin",
  "ph:vinyl-record",
  "ph:virtual-reality-bold",
  "ph:virtual-reality-duotone",
  "ph:virtual-reality-fill",
  "ph:virtual-reality-light",
  "ph:virtual-reality-thin",
  "ph:virtual-reality",
  "ph:virus-bold",
  "ph:virus-duotone",
  "ph:virus-fill",
  "ph:virus-light",
  "ph:virus-thin",
  "ph:virus",
  "ph:voicemail-bold",
  "ph:voicemail-duotone",
  "ph:voicemail-fill",
  "ph:voicemail-light",
  "ph:voicemail-thin",
  "ph:voicemail",
  "ph:volleyball-bold",
  "ph:volleyball-duotone",
  "ph:volleyball-fill",
  "ph:volleyball-light",
  "ph:volleyball-thin",
  "ph:volleyball",
  "ph:wall-bold",
  "ph:wall-duotone",
  "ph:wall-fill",
  "ph:wall-light",
  "ph:wall-thin",
  "ph:wall",
  "ph:wallet-bold",
  "ph:wallet-duotone",
  "ph:wallet-fill",
  "ph:wallet-light",
  "ph:wallet-thin",
  "ph:wallet",
  "ph:warehouse-bold",
  "ph:warehouse-duotone",
  "ph:warehouse-fill",
  "ph:warehouse-light",
  "ph:warehouse-thin",
  "ph:warehouse",
  "ph:warning-bold",
  "ph:warning-circle-bold",
  "ph:warning-circle-duotone",
  "ph:warning-circle-fill",
  "ph:warning-circle-light",
  "ph:warning-circle-thin",
  "ph:warning-circle",
  "ph:warning-diamond-bold",
  "ph:warning-diamond-duotone",
  "ph:warning-diamond-fill",
  "ph:warning-diamond-light",
  "ph:warning-diamond-thin",
  "ph:warning-diamond",
  "ph:warning-duotone",
  "ph:warning-fill",
  "ph:warning-light",
  "ph:warning-octagon-bold",
  "ph:warning-octagon-duotone",
  "ph:warning-octagon-fill",
  "ph:warning-octagon-light",
  "ph:warning-octagon-thin",
  "ph:warning-octagon",
  "ph:warning-thin",
  "ph:warning",
  "ph:watch-bold",
  "ph:watch-duotone",
  "ph:watch-fill",
  "ph:watch-light",
  "ph:watch-thin",
  "ph:watch",
  "ph:wave-sawtooth-bold",
  "ph:wave-sawtooth-duotone",
  "ph:wave-sawtooth-fill",
  "ph:wave-sawtooth-light",
  "ph:wave-sawtooth-thin",
  "ph:wave-sawtooth",
  "ph:wave-sine-bold",
  "ph:wave-sine-duotone",
  "ph:wave-sine-fill",
  "ph:wave-sine-light",
  "ph:wave-sine-thin",
  "ph:wave-sine",
  "ph:wave-square-bold",
  "ph:wave-square-duotone",
  "ph:wave-square-fill",
  "ph:wave-square-light",
  "ph:wave-square-thin",
  "ph:wave-square",
  "ph:wave-triangle-bold",
  "ph:wave-triangle-duotone",
  "ph:wave-triangle-fill",
  "ph:wave-triangle-light",
  "ph:wave-triangle-thin",
  "ph:wave-triangle",
  "ph:waveform-bold",
  "ph:waveform-duotone",
  "ph:waveform-fill",
  "ph:waveform-light",
  "ph:waveform-thin",
  "ph:waveform",
  "ph:waves-bold",
  "ph:waves-duotone",
  "ph:waves-fill",
  "ph:waves-light",
  "ph:waves-thin",
  "ph:waves",
  "ph:webcam-bold",
  "ph:webcam-duotone",
  "ph:webcam-fill",
  "ph:webcam-light",
  "ph:webcam-slash-bold",
  "ph:webcam-slash-duotone",
  "ph:webcam-slash-fill",
  "ph:webcam-slash-light",
  "ph:webcam-slash-thin",
  "ph:webcam-slash",
  "ph:webcam-thin",
  "ph:webcam",
  "ph:webhooks-logo-bold",
  "ph:webhooks-logo-duotone",
  "ph:webhooks-logo-fill",
  "ph:webhooks-logo-light",
  "ph:webhooks-logo-thin",
  "ph:webhooks-logo",
  "ph:wechat-logo-bold",
  "ph:wechat-logo-duotone",
  "ph:wechat-logo-fill",
  "ph:wechat-logo-light",
  "ph:wechat-logo-thin",
  "ph:wechat-logo",
  "ph:whatsapp-logo-bold",
  "ph:whatsapp-logo-duotone",
  "ph:whatsapp-logo-fill",
  "ph:whatsapp-logo-light",
  "ph:whatsapp-logo-thin",
  "ph:whatsapp-logo",
  "ph:wheelchair-bold",
  "ph:wheelchair-duotone",
  "ph:wheelchair-fill",
  "ph:wheelchair-light",
  "ph:wheelchair-motion-bold",
  "ph:wheelchair-motion-duotone",
  "ph:wheelchair-motion-fill",
  "ph:wheelchair-motion-light",
  "ph:wheelchair-motion-thin",
  "ph:wheelchair-motion",
  "ph:wheelchair-thin",
  "ph:wheelchair",
  "ph:wifi-high-bold",
  "ph:wifi-high-duotone",
  "ph:wifi-high-fill",
  "ph:wifi-high-light",
  "ph:wifi-high-thin",
  "ph:wifi-high",
  "ph:wifi-low-bold",
  "ph:wifi-low-duotone",
  "ph:wifi-low-fill",
  "ph:wifi-low-light",
  "ph:wifi-low-thin",
  "ph:wifi-low",
  "ph:wifi-medium-bold",
  "ph:wifi-medium-duotone",
  "ph:wifi-medium-fill",
  "ph:wifi-medium-light",
  "ph:wifi-medium-thin",
  "ph:wifi-medium",
  "ph:wifi-none-bold",
  "ph:wifi-none-duotone",
  "ph:wifi-none-fill",
  "ph:wifi-none-light",
  "ph:wifi-none-thin",
  "ph:wifi-none",
  "ph:wifi-slash-bold",
  "ph:wifi-slash-duotone",
  "ph:wifi-slash-fill",
  "ph:wifi-slash-light",
  "ph:wifi-slash-thin",
  "ph:wifi-slash",
  "ph:wifi-x-bold",
  "ph:wifi-x-duotone",
  "ph:wifi-x-fill",
  "ph:wifi-x-light",
  "ph:wifi-x-thin",
  "ph:wifi-x",
  "ph:wind-bold",
  "ph:wind-duotone",
  "ph:wind-fill",
  "ph:wind-light",
  "ph:wind-thin",
  "ph:wind",
  "ph:windows-logo-bold",
  "ph:windows-logo-duotone",
  "ph:windows-logo-fill",
  "ph:windows-logo-light",
  "ph:windows-logo-thin",
  "ph:windows-logo",
  "ph:wine-bold",
  "ph:wine-duotone",
  "ph:wine-fill",
  "ph:wine-light",
  "ph:wine-thin",
  "ph:wine",
  "ph:wrench-bold",
  "ph:wrench-duotone",
  "ph:wrench-fill",
  "ph:wrench-light",
  "ph:wrench-thin",
  "ph:wrench",
  "ph:x-bold",
  "ph:x-circle-bold",
  "ph:x-circle-duotone",
  "ph:x-circle-fill",
  "ph:x-circle-light",
  "ph:x-circle-thin",
  "ph:x-circle",
  "ph:x-duotone",
  "ph:x-fill",
  "ph:x-light",
  "ph:x-square-bold",
  "ph:x-square-duotone",
  "ph:x-square-fill",
  "ph:x-square-light",
  "ph:x-square-thin",
  "ph:x-square",
  "ph:x-thin",
  "ph:x",
  "ph:yin-yang-bold",
  "ph:yin-yang-duotone",
  "ph:yin-yang-fill",
  "ph:yin-yang-light",
  "ph:yin-yang-thin",
  "ph:yin-yang",
  "ph:youtube-logo-bold",
  "ph:youtube-logo-duotone",
  "ph:youtube-logo-fill",
  "ph:youtube-logo-light",
  "ph:youtube-logo-thin",
  "ph:youtube-logo",
  "flag:ad-1x1",
"flag:ad-4x3",
"flag:ae-1x1",
"flag:ae-4x3",
"flag:af-1x1",
"flag:af-4x3",
"flag:ag-1x1",
"flag:ag-4x3",
"flag:ai-1x1",
"flag:ai-4x3",
"flag:al-1x1",
"flag:al-4x3",
"flag:am-1x1",
"flag:am-4x3",
"flag:ao-1x1",
"flag:ao-4x3",
"flag:aq-1x1",
"flag:aq-4x3",
"flag:ar-1x1",
"flag:ar-4x3",
"flag:arab-1x1",
"flag:arab-4x3",
"flag:as-1x1",
"flag:as-4x3",
"flag:at-1x1",
"flag:at-4x3",
"flag:au-1x1",
"flag:au-4x3",
"flag:aw-1x1",
"flag:aw-4x3",
"flag:ax-1x1",
"flag:ax-4x3",
"flag:az-1x1",
"flag:az-4x3",
"flag:ba-1x1",
"flag:ba-4x3",
"flag:bb-1x1",
"flag:bb-4x3",
"flag:bd-1x1",
"flag:bd-4x3",
"flag:be-1x1",
"flag:be-4x3",
"flag:bf-1x1",
"flag:bf-4x3",
"flag:bg-1x1",
"flag:bg-4x3",
"flag:bh-1x1",
"flag:bh-4x3",
"flag:bi-1x1",
"flag:bi-4x3",
"flag:bj-1x1",
"flag:bj-4x3",
"flag:bl-1x1",
"flag:bl-4x3",
"flag:bm-1x1",
"flag:bm-4x3",
"flag:bn-1x1",
"flag:bn-4x3",
"flag:bo-1x1",
"flag:bo-4x3",
"flag:bq-1x1",
"flag:bq-4x3",
"flag:br-1x1",
"flag:br-4x3",
"flag:bs-1x1",
"flag:bs-4x3",
"flag:bt-1x1",
"flag:bt-4x3",
"flag:bv-1x1",
"flag:bv-4x3",
"flag:bw-1x1",
"flag:bw-4x3",
"flag:by-1x1",
"flag:by-4x3",
"flag:bz-1x1",
"flag:bz-4x3",
"flag:ca-1x1",
"flag:ca-4x3",
"flag:cc-1x1",
"flag:cc-4x3",
"flag:cd-1x1",
"flag:cd-4x3",
"flag:cefta-1x1",
"flag:cefta-4x3",
"flag:cf-1x1",
"flag:cf-4x3",
"flag:cg-1x1",
"flag:cg-4x3",
"flag:ch-1x1",
"flag:ch-4x3",
"flag:ci-1x1",
"flag:ci-4x3",
"flag:ck-1x1",
"flag:ck-4x3",
"flag:cl-1x1",
"flag:cl-4x3",
"flag:cm-1x1",
"flag:cm-4x3",
"flag:cn-1x1",
"flag:cn-4x3",
"flag:co-1x1",
"flag:co-4x3",
"flag:cp-1x1",
"flag:cp-4x3",
"flag:cr-1x1",
"flag:cr-4x3",
"flag:cu-1x1",
"flag:cu-4x3",
"flag:cv-1x1",
"flag:cv-4x3",
"flag:cw-1x1",
"flag:cw-4x3",
"flag:cx-1x1",
"flag:cx-4x3",
"flag:cy-1x1",
"flag:cy-4x3",
"flag:cz-1x1",
"flag:cz-4x3",
"flag:de-1x1",
"flag:de-4x3",
"flag:dg-1x1",
"flag:dg-4x3",
"flag:dj-1x1",
"flag:dj-4x3",
"flag:dk-1x1",
"flag:dk-4x3",
"flag:dm-1x1",
"flag:dm-4x3",
"flag:do-1x1",
"flag:do-4x3",
"flag:dz-1x1",
"flag:dz-4x3",
"flag:eac-1x1",
"flag:eac-4x3",
"flag:ec-1x1",
"flag:ec-4x3",
"flag:ee-1x1",
"flag:ee-4x3",
"flag:eg-1x1",
"flag:eg-4x3",
"flag:eh-1x1",
"flag:eh-4x3",
"flag:er-1x1",
"flag:er-4x3",
"flag:es-1x1",
"flag:es-4x3",
"flag:es-ct-1x1",
"flag:es-ct-4x3",
"flag:es-ga-1x1",
"flag:es-ga-4x3",
"flag:es-pv-1x1",
"flag:es-pv-4x3",
"flag:et-1x1",
"flag:et-4x3",
"flag:eu-1x1",
"flag:eu-4x3",
"flag:fi-1x1",
"flag:fi-4x3",
"flag:fj-1x1",
"flag:fj-4x3",
"flag:fk-1x1",
"flag:fk-4x3",
"flag:fm-1x1",
"flag:fm-4x3",
"flag:fo-1x1",
"flag:fo-4x3",
"flag:fr-1x1",
"flag:fr-4x3",
"flag:ga-1x1",
"flag:ga-4x3",
"flag:gb-1x1",
"flag:gb-4x3",
"flag:gb-eng-1x1",
"flag:gb-eng-4x3",
"flag:gb-nir-1x1",
"flag:gb-nir-4x3",
"flag:gb-sct-1x1",
"flag:gb-sct-4x3",
"flag:gb-wls-1x1",
"flag:gb-wls-4x3",
"flag:gd-1x1",
"flag:gd-4x3",
"flag:ge-1x1",
"flag:ge-4x3",
"flag:gf-1x1",
"flag:gf-4x3",
"flag:gg-1x1",
"flag:gg-4x3",
"flag:gh-1x1",
"flag:gh-4x3",
"flag:gi-1x1",
"flag:gi-4x3",
"flag:gl-1x1",
"flag:gl-4x3",
"flag:gm-1x1",
"flag:gm-4x3",
"flag:gn-1x1",
"flag:gn-4x3",
"flag:gp-1x1",
"flag:gp-4x3",
"flag:gq-1x1",
"flag:gq-4x3",
"flag:gr-1x1",
"flag:gr-4x3",
"flag:gs-1x1",
"flag:gs-4x3",
"flag:gt-1x1",
"flag:gt-4x3",
"flag:gw-1x1",
"flag:gw-4x3",
"flag:gy-1x1",
"flag:gy-4x3",
"flag:hk-1x1",
"flag:hk-4x3",
"flag:hm-1x1",
"flag:hm-4x3",
"flag:hn-1x1",
"flag:hn-4x3",
"flag:hr-1x1",
"flag:hr-4x3",
"flag:ht-1x1",
"flag:ht-4x3",
"flag:hu-1x1",
"flag:hu-4x3",
"flag:ic-1x1",
"flag:ic-4x3",
"flag:id-1x1",
"flag:id-4x3",
"flag:ie-1x1",
"flag:ie-4x3",
"flag:il-1x1",
"flag:il-4x3",
"flag:im-1x1",
"flag:im-4x3",
"flag:in-1x1",
"flag:in-4x3",
"flag:io-1x1",
"flag:io-4x3",
"flag:iq-1x1",
"flag:iq-4x3",
"flag:ir-1x1",
"flag:ir-4x3",
"flag:is-1x1",
"flag:is-4x3",
"flag:it-1x1",
"flag:it-4x3",
"flag:je-1x1",
"flag:je-4x3",
"flag:jm-1x1",
"flag:jm-4x3",
"flag:jo-1x1",
"flag:jo-4x3",
"flag:jp-1x1",
"flag:jp-4x3",
"flag:ke-1x1",
"flag:ke-4x3",
"flag:kg-1x1",
"flag:kg-4x3",
"flag:kh-1x1",
"flag:kh-4x3",
"flag:ki-1x1",
"flag:ki-4x3",
"flag:km-1x1",
"flag:km-4x3",
"flag:kn-1x1",
"flag:kn-4x3",
"flag:kp-1x1",
"flag:kp-4x3",
"flag:kr-1x1",
"flag:kr-4x3",
"flag:kw-1x1",
"flag:kw-4x3",
"flag:ky-1x1",
"flag:ky-4x3",
"flag:kz-1x1",
"flag:kz-4x3",
"flag:la-1x1",
"flag:la-4x3",
"flag:lb-1x1",
"flag:lb-4x3",
"flag:lc-1x1",
"flag:lc-4x3",
"flag:li-1x1",
"flag:li-4x3",
"flag:lk-1x1",
"flag:lk-4x3",
"flag:lr-1x1",
"flag:lr-4x3",
"flag:ls-1x1",
"flag:ls-4x3",
"flag:lt-1x1",
"flag:lt-4x3",
"flag:lu-1x1",
"flag:lu-4x3",
"flag:lv-1x1",
"flag:lv-4x3",
"flag:ly-1x1",
"flag:ly-4x3",
"flag:ma-1x1",
"flag:ma-4x3",
"flag:mc-1x1",
"flag:mc-4x3",
"flag:md-1x1",
"flag:md-4x3",
"flag:me-1x1",
"flag:me-4x3",
"flag:mf-1x1",
"flag:mf-4x3",
"flag:mg-1x1",
"flag:mg-4x3",
"flag:mh-1x1",
"flag:mh-4x3",
"flag:mk-1x1",
"flag:mk-4x3",
"flag:ml-1x1",
"flag:ml-4x3",
"flag:mm-1x1",
"flag:mm-4x3",
"flag:mn-1x1",
"flag:mn-4x3",
"flag:mo-1x1",
"flag:mo-4x3",
"flag:mp-1x1",
"flag:mp-4x3",
"flag:mq-1x1",
"flag:mq-4x3",
"flag:mr-1x1",
"flag:mr-4x3",
"flag:ms-1x1",
"flag:ms-4x3",
"flag:mt-1x1",
"flag:mt-4x3",
"flag:mu-1x1",
"flag:mu-4x3",
"flag:mv-1x1",
"flag:mv-4x3",
"flag:mw-1x1",
"flag:mw-4x3",
"flag:mx-1x1",
"flag:mx-4x3",
"flag:my-1x1",
"flag:my-4x3",
"flag:mz-1x1",
"flag:mz-4x3",
"flag:na-1x1",
"flag:na-4x3",
"flag:nc-1x1",
"flag:nc-4x3",
"flag:ne-1x1",
"flag:ne-4x3",
"flag:nf-1x1",
"flag:nf-4x3",
"flag:ng-1x1",
"flag:ng-4x3",
"flag:ni-1x1",
"flag:ni-4x3",
"flag:nl-1x1",
"flag:nl-4x3",
"flag:no-1x1",
"flag:no-4x3",
"flag:np-1x1",
"flag:np-4x3",
"flag:nr-1x1",
"flag:nr-4x3",
"flag:nu-1x1",
"flag:nu-4x3",
"flag:nz-1x1",
"flag:nz-4x3",
"flag:om-1x1",
"flag:om-4x3",
"flag:pa-1x1",
"flag:pa-4x3",
"flag:pc-1x1",
"flag:pc-4x3",
"flag:pe-1x1",
"flag:pe-4x3",
"flag:pf-1x1",
"flag:pf-4x3",
"flag:pg-1x1",
"flag:pg-4x3",
"flag:ph-1x1",
"flag:ph-4x3",
"flag:pk-1x1",
"flag:pk-4x3",
"flag:pl-1x1",
"flag:pl-4x3",
"flag:pm-1x1",
"flag:pm-4x3",
"flag:pn-1x1",
"flag:pn-4x3",
"flag:pr-1x1",
"flag:pr-4x3",
"flag:ps-1x1",
"flag:ps-4x3",
"flag:pt-1x1",
"flag:pt-4x3",
"flag:pw-1x1",
"flag:pw-4x3",
"flag:py-1x1",
"flag:py-4x3",
"flag:qa-1x1",
"flag:qa-4x3",
"flag:re-1x1",
"flag:re-4x3",
"flag:ro-1x1",
"flag:ro-4x3",
"flag:rs-1x1",
"flag:rs-4x3",
"flag:ru-1x1",
"flag:ru-4x3",
"flag:rw-1x1",
"flag:rw-4x3",
"flag:sa-1x1",
"flag:sa-4x3",
"flag:sb-1x1",
"flag:sb-4x3",
"flag:sc-1x1",
"flag:sc-4x3",
"flag:sd-1x1",
"flag:sd-4x3",
"flag:se-1x1",
"flag:se-4x3",
"flag:sg-1x1",
"flag:sg-4x3",
"flag:sh-1x1",
"flag:sh-4x3",
"flag:sh-ac-1x1",
"flag:sh-ac-4x3",
"flag:sh-hl-1x1",
"flag:sh-hl-4x3",
"flag:sh-ta-1x1",
"flag:sh-ta-4x3",
"flag:si-1x1",
"flag:si-4x3",
"flag:sj-1x1",
"flag:sj-4x3",
"flag:sk-1x1",
"flag:sk-4x3",
"flag:sl-1x1",
"flag:sl-4x3",
"flag:sm-1x1",
"flag:sm-4x3",
"flag:sn-1x1",
"flag:sn-4x3",
"flag:so-1x1",
"flag:so-4x3",
"flag:sr-1x1",
"flag:sr-4x3",
"flag:ss-1x1",
"flag:ss-4x3",
"flag:st-1x1",
"flag:st-4x3",
"flag:sv-1x1",
"flag:sv-4x3",
"flag:sx-1x1",
"flag:sx-4x3",
"flag:sy-1x1",
"flag:sy-4x3",
"flag:sz-1x1",
"flag:sz-4x3",
"flag:tc-1x1",
"flag:tc-4x3",
"flag:td-1x1",
"flag:td-4x3",
"flag:tf-1x1",
"flag:tf-4x3",
"flag:tg-1x1",
"flag:tg-4x3",
"flag:th-1x1",
"flag:th-4x3",
"flag:tj-1x1",
"flag:tj-4x3",
"flag:tk-1x1",
"flag:tk-4x3",
"flag:tl-1x1",
"flag:tl-4x3",
"flag:tm-1x1",
"flag:tm-4x3",
"flag:tn-1x1",
"flag:tn-4x3",
"flag:to-1x1",
"flag:to-4x3",
"flag:tr-1x1",
"flag:tr-4x3",
"flag:tt-1x1",
"flag:tt-4x3",
"flag:tv-1x1",
"flag:tv-4x3",
"flag:tw-1x1",
"flag:tw-4x3",
"flag:tz-1x1",
"flag:tz-4x3",
"flag:ua-1x1",
"flag:ua-4x3",
"flag:ug-1x1",
"flag:ug-4x3",
"flag:um-1x1",
"flag:um-4x3",
"flag:un-1x1",
"flag:un-4x3",
"flag:us-1x1",
"flag:us-4x3",
"flag:uy-1x1",
"flag:uy-4x3",
"flag:uz-1x1",
"flag:uz-4x3",
"flag:va-1x1",
"flag:va-4x3",
"flag:vc-1x1",
"flag:vc-4x3",
"flag:ve-1x1",
"flag:ve-4x3",
"flag:vg-1x1",
"flag:vg-4x3",
"flag:vi-1x1",
"flag:vi-4x3",
"flag:vn-1x1",
"flag:vn-4x3",
"flag:vu-1x1",
"flag:vu-4x3",
"flag:wf-1x1",
"flag:wf-4x3",
"flag:ws-1x1",
"flag:ws-4x3",
"flag:xk-1x1",
"flag:xk-4x3",
"flag:xx-1x1",
"flag:xx-4x3",
"flag:ye-1x1",
"flag:ye-4x3",
"flag:yt-1x1",
"flag:yt-4x3",
"flag:za-1x1",
"flag:za-4x3",
"flag:zm-1x1",
"flag:zm-4x3",
"flag:zw-1x1",
"flag:zw-4x3",
"cib:500px-5",
"cib:500px",
"cib:about-me",
"cib:abstract",
"cib:acm",
"cib:addthis",
"cib:adguard",
"cib:adobe-acrobat-reader",
"cib:adobe-after-effects",
"cib:adobe-audition",
"cib:adobe-creative-cloud",
"cib:adobe-dreamweaver",
"cib:adobe-illustrator",
"cib:adobe-indesign",
"cib:adobe-lightroom-classic",
"cib:adobe-lightroom",
"cib:adobe-photoshop",
"cib:adobe-premiere",
"cib:adobe-typekit",
"cib:adobe-xd",
"cib:adobe",
"cib:airbnb",
"cib:algolia",
"cib:alipay",
"cib:allocine",
"cib:amazon-aws",
"cib:amazon-pay",
"cib:amazon",
"cib:amd",
"cib:american-express",
"cib:anaconda",
"cib:analogue",
"cib:android-alt",
"cib:android",
"cib:angellist",
"cib:angular-universal",
"cib:angular",
"cib:ansible",
"cib:apache-airflow",
"cib:apache-flink",
"cib:apache-spark",
"cib:apache",
"cib:app-store-ios",
"cib:app-store",
"cib:apple-music",
"cib:apple-pay",
"cib:apple-podcasts",
"cib:apple",
"cib:appveyor",
"cib:aral",
"cib:arch-linux",
"cib:archive-of-our-own",
"cib:arduino",
"cib:artstation",
"cib:arxiv",
"cib:asana",
"cib:at-and-t",
"cib:atlassian",
"cib:atom",
"cib:audible",
"cib:aurelia",
"cib:auth0",
"cib:automatic",
"cib:autotask",
"cib:aventrix",
"cib:azure-artifacts",
"cib:azure-devops",
"cib:azure-pipelines",
"cib:babel",
"cib:baidu",
"cib:bamboo",
"cib:bancontact",
"cib:bandcamp",
"cib:basecamp",
"cib:bathasu",
"cib:behance",
"cib:big-cartel",
"cib:bing",
"cib:bit",
"cib:bitbucket",
"cib:bitcoin",
"cib:bitdefender",
"cib:bitly",
"cib:blackberry",
"cib:blender",
"cib:blogger-b",
"cib:blogger",
"cib:bluetooth-b",
"cib:bluetooth",
"cib:boeing",
"cib:boost",
"cib:bootstrap",
"cib:bower",
"cib:brand-ai",
"cib:brave",
"cib:btc",
"cib:buddy",
"cib:buffer",
"cib:buy-me-a-coffee",
"cib:buysellads",
"cib:buzzfeed",
"cib:c",
"cib:cakephp",
"cib:campaign-monitor",
"cib:canva",
"cib:cashapp",
"cib:cassandra",
"cib:castro",
"cib:cc-amazon-pay",
"cib:cc-amex",
"cib:cc-apple-pay",
"cib:cc-diners-club",
"cib:cc-discover",
"cib:cc-jcb",
"cib:cc-mastercard",
"cib:cc-paypal",
"cib:cc-stripe",
"cib:cc-visa",
"cib:centos",
"cib:cevo",
"cib:chase",
"cib:chef",
"cib:chromecast",
"cib:circle",
"cib:circleci",
"cib:cirrusci",
"cib:cisco",
"cib:civicrm",
"cib:clockify",
"cib:clojure",
"cib:cloudbees",
"cib:cloudflare",
"cib:cmake",
"cib:co-op",
"cib:codacy",
"cib:code-climate",
"cib:codecademy",
"cib:codecov",
"cib:codeigniter",
"cib:codepen",
"cib:coderwall",
"cib:codesandbox",
"cib:codeship",
"cib:codewars",
"cib:codio",
"cib:coffeescript",
"cib:common-workflow-language",
"cib:composer",
"cib:conda-forge",
"cib:conekta",
"cib:confluence",
"cib:coreui-c",
"cib:coreui",
"cib:coursera",
"cib:coveralls",
"cib:cpanel",
"cib:cplusplus",
"cib:creative-commons-by",
"cib:creative-commons-nc-eu",
"cib:creative-commons-nc-jp",
"cib:creative-commons-nc",
"cib:creative-commons-nd",
"cib:creative-commons-pd-alt",
"cib:creative-commons-pd",
"cib:creative-commons-remix",
"cib:creative-commons-sa",
"cib:creative-commons-sampling-plus",
"cib:creative-commons-sampling",
"cib:creative-commons-share",
"cib:creative-commons-zero",
"cib:creative-commons",
"cib:crunchbase",
"cib:crunchyroll",
"cib:css3-shiled",
"cib:css3",
"cib:csswizardry",
"cib:d3-js",
"cib:dailymotion",
"cib:dashlane",
"cib:dazn",
"cib:dblp",
"cib:debian",
"cib:deepin",
"cib:deezer",
"cib:delicious",
"cib:dell",
"cib:deno",
"cib:dependabot",
"cib:designer-news",
"cib:dev-to",
"cib:deviantart",
"cib:devrant",
"cib:diaspora",
"cib:digg",
"cib:digital-ocean",
"cib:discord",
"cib:discourse",
"cib:discover",
"cib:disqus",
"cib:disroot",
"cib:django",
"cib:docker",
"cib:docusign",
"cib:dot-net",
"cib:draugiem-lv",
"cib:dribbble",
"cib:drone",
"cib:dropbox",
"cib:drupal",
"cib:dtube",
"cib:duckduckgo",
"cib:dynatrace",
"cib:ebay",
"cib:eclipseide",
"cib:elastic-cloud",
"cib:elastic-search",
"cib:elastic-stack",
"cib:elastic",
"cib:electron",
"cib:elementary",
"cib:eleventy",
"cib:ello",
"cib:elsevier",
"cib:emlakjet",
"cib:empirekred",
"cib:envato",
"cib:epic-games",
"cib:epson",
"cib:esea",
"cib:eslint",
"cib:ethereum",
"cib:etsy",
"cib:event-store",
"cib:eventbrite",
"cib:evernote",
"cib:everplaces",
"cib:evry",
"cib:exercism",
"cib:experts-exchange",
"cib:expo",
"cib:eyeem",
"cib:f-secure",
"cib:facebook-f",
"cib:facebook",
"cib:faceit",
"cib:fandango",
"cib:favro",
"cib:feathub",
"cib:fedex",
"cib:fedora",
"cib:feedly",
"cib:fido-alliance",
"cib:figma",
"cib:filezilla",
"cib:firebase",
"cib:fitbit",
"cib:flask",
"cib:flattr",
"cib:flickr",
"cib:flipboard",
"cib:flutter",
"cib:fnac",
"cib:foursquare",
"cib:framer",
"cib:freebsd",
"cib:freecodecamp",
"cib:fur-affinity",
"cib:furry-network",
"cib:garmin",
"cib:gatsby",
"cib:gauges",
"cib:genius",
"cib:gentoo",
"cib:geocaching",
"cib:gerrit",
"cib:gg",
"cib:ghost",
"cib:gimp",
"cib:git",
"cib:gitea",
"cib:github",
"cib:gitkraken",
"cib:gitlab",
"cib:gitpod",
"cib:gitter",
"cib:glassdoor",
"cib:glitch",
"cib:gmail",
"cib:gnu-privacy-guard",
"cib:gnu-social",
"cib:gnu",
"cib:go",
"cib:godot-engine",
"cib:gog-com",
"cib:goldenline",
"cib:goodreads",
"cib:google-ads",
"cib:google-allo",
"cib:google-analytics",
"cib:google-chrome",
"cib:google-cloud",
"cib:google-keep",
"cib:google-pay",
"cib:google-play",
"cib:google-podcasts",
"cib:google",
"cib:googles-cholar",
"cib:gov-uk",
"cib:gradle",
"cib:grafana",
"cib:graphcool",
"cib:graphql",
"cib:grav",
"cib:gravatar",
"cib:greenkeeper",
"cib:greensock",
"cib:groovy",
"cib:groupon",
"cib:grunt",
"cib:gulp",
"cib:gumroad",
"cib:gumtree",
"cib:habr",
"cib:hackaday",
"cib:hackerearth",
"cib:hackerone",
"cib:hackerrank",
"cib:hackhands",
"cib:hackster",
"cib:happycow",
"cib:hashnode",
"cib:haskell",
"cib:hatena-bookmark",
"cib:haxe",
"cib:helm",
"cib:here",
"cib:heroku",
"cib:hexo",
"cib:highly",
"cib:hipchat",
"cib:hitachi",
"cib:hockeyapp",
"cib:homify",
"cib:hootsuite",
"cib:hotjar",
"cib:houzz",
"cib:hp",
"cib:html5-shield",
"cib:html5",
"cib:htmlacademy",
"cib:huawei",
"cib:hubspot",
"cib:hulu",
"cib:humble-bundle",
"cib:iata",
"cib:ibm",
"cib:icloud",
"cib:iconjar",
"cib:icq",
"cib:ideal",
"cib:ifixit",
"cib:imdb",
"cib:indeed",
"cib:inkscape",
"cib:instacart",
"cib:instagram",
"cib:instapaper",
"cib:intel",
"cib:intellijidea",
"cib:intercom",
"cib:internet-explorer",
"cib:invision",
"cib:ionic",
"cib:issuu",
"cib:itch-io",
"cib:jabber",
"cib:java",
"cib:javascript",
"cib:jekyll",
"cib:jenkins",
"cib:jest",
"cib:jet",
"cib:jetbrains",
"cib:jira",
"cib:joomla",
"cib:jquery",
"cib:js",
"cib:jsdelivr",
"cib:jsfiddle",
"cib:json",
"cib:jupyter",
"cib:justgiving",
"cib:kaggle",
"cib:kaios",
"cib:kaspersky",
"cib:kentico",
"cib:keras",
"cib:keybase",
"cib:keycdn",
"cib:khan-academy",
"cib:kibana",
"cib:kickstarter",
"cib:kik",
"cib:kirby",
"cib:klout",
"cib:known",
"cib:ko-fi",
"cib:kodi",
"cib:koding",
"cib:kotlin",
"cib:krita",
"cib:kubernetes",
"cib:lanyrd",
"cib:laravel-horizon",
"cib:laravel-nova",
"cib:laravel",
"cib:last-fm",
"cib:latex",
"cib:launchpad",
"cib:leetcode",
"cib:lenovo",
"cib:less",
"cib:lets-encrypt",
"cib:letterboxd",
"cib:lgtm",
"cib:liberapay",
"cib:librarything",
"cib:libreoffice",
"cib:line",
"cib:linkedin-in",
"cib:linkedin",
"cib:linux-foundation",
"cib:linux-mint",
"cib:linux",
"cib:livejournal",
"cib:livestream",
"cib:logstash",
"cib:lua",
"cib:lumen",
"cib:lyft",
"cib:macys",
"cib:magento",
"cib:magisk",
"cib:mail-ru",
"cib:mailchimp",
"cib:makerbot",
"cib:manjaro",
"cib:markdown",
"cib:marketo",
"cib:mastercard",
"cib:mastodon",
"cib:material-design",
"cib:mathworks",
"cib:matrix",
"cib:mattermost",
"cib:matternet",
"cib:maxcdn",
"cib:mcafee",
"cib:media-temple",
"cib:mediafire",
"cib:medium-m",
"cib:medium",
"cib:meetup",
"cib:mega",
"cib:mendeley",
"cib:messenger",
"cib:meteor",
"cib:micro-blog",
"cib:microgenetics",
"cib:microsoft-edge",
"cib:microsoft",
"cib:minetest",
"cib:minutemailer",
"cib:mix",
"cib:mixcloud",
"cib:mixer",
"cib:mojang",
"cib:monero",
"cib:mongodb",
"cib:monkeytie",
"cib:monogram",
"cib:monzo",
"cib:moo",
"cib:mozilla-firefox",
"cib:mozilla",
"cib:musescore",
"cib:mxlinux",
"cib:myspace",
"cib:mysql",
"cib:nativescript",
"cib:nec",
"cib:neo4j",
"cib:netflix",
"cib:netlify",
"cib:next-js",
"cib:nextcloud",
"cib:nextdoor",
"cib:nginx",
"cib:nim",
"cib:nintendo-3ds",
"cib:nintendo-gamecube",
"cib:nintendo-switch",
"cib:nintendo",
"cib:node-js",
"cib:node-red",
"cib:nodemon",
"cib:nokia",
"cib:notion",
"cib:npm",
"cib:nucleo",
"cib:nuget",
"cib:nuxt-js",
"cib:nvidia",
"cib:ocaml",
"cib:octave",
"cib:octopus-deploy",
"cib:oculus",
"cib:odnoklassniki",
"cib:open-access",
"cib:open-collective",
"cib:open-id",
"cib:open-source-initiative",
"cib:openstreetmap",
"cib:opensuse",
"cib:openvpn",
"cib:opera",
"cib:opsgenie",
"cib:oracle-netsuite",
"cib:oracle",
"cib:orcid",
"cib:origin",
"cib:osi",
"cib:osmc",
"cib:overcast",
"cib:overleaf",
"cib:ovh",
"cib:pagekit",
"cib:palantir",
"cib:pandora",
"cib:pantheon",
"cib:patreon",
"cib:paypal",
"cib:periscope",
"cib:php",
"cib:picarto-tv",
"cib:pinboard",
"cib:pingdom",
"cib:pingup",
"cib:pinterest-p",
"cib:pinterest",
"cib:pivotaltracker",
"cib:plangrid",
"cib:player-me",
"cib:playerfm",
"cib:playstation",
"cib:playstation3",
"cib:playstation4",
"cib:plesk",
"cib:plex",
"cib:pluralsight",
"cib:plurk",
"cib:pocket",
"cib:postgresql",
"cib:postman",
"cib:postwoman",
"cib:powershell",
"cib:prettier",
"cib:prismic",
"cib:probot",
"cib:processwire",
"cib:product-hunt",
"cib:proto-io",
"cib:protonmail",
"cib:proxmox",
"cib:pypi",
"cib:python",
"cib:pytorch",
"cib:qgis",
"cib:qiita",
"cib:qq",
"cib:qualcomm",
"cib:quantcast",
"cib:quantopian",
"cib:quarkus",
"cib:quora",
"cib:qwiklabs",
"cib:qzone",
"cib:r",
"cib:radiopublic",
"cib:rails",
"cib:raspberry-pi",
"cib:react",
"cib:read-the-docs",
"cib:readme",
"cib:realm",
"cib:reason",
"cib:redbubble",
"cib:reddit-alt",
"cib:reddit",
"cib:redhat",
"cib:redis",
"cib:redux",
"cib:renren",
"cib:reverbnation",
"cib:riot",
"cib:ripple",
"cib:riseup",
"cib:rollup-js",
"cib:roots",
"cib:roundcube",
"cib:rss",
"cib:rstudio",
"cib:ruby",
"cib:rubygems",
"cib:runkeeper",
"cib:rust",
"cib:safari",
"cib:sahibinden",
"cib:salesforce",
"cib:saltstack",
"cib:samsung-pay",
"cib:samsung",
"cib:sap",
"cib:sass-alt",
"cib:sass",
"cib:saucelabs",
"cib:scala",
"cib:scaleway",
"cib:scribd",
"cib:scrutinizerci",
"cib:seagate",
"cib:sega",
"cib:sellfy",
"cib:semaphoreci",
"cib:sensu",
"cib:sentry",
"cib:server-fault",
"cib:shazam",
"cib:shell",
"cib:shopify",
"cib:showpad",
"cib:siemens",
"cib:signal",
"cib:sina-weibo",
"cib:sitepoint",
"cib:sketch",
"cib:skillshare",
"cib:skyliner",
"cib:skype",
"cib:slack",
"cib:slashdot",
"cib:slickpic",
"cib:slides",
"cib:slideshare",
"cib:smashingmagazine",
"cib:snapchat",
"cib:snapcraft",
"cib:snyk",
"cib:society6",
"cib:socket-io",
"cib:sogou",
"cib:solus",
"cib:songkick",
"cib:sonos",
"cib:soundcloud",
"cib:sourceforge",
"cib:sourcegraph",
"cib:spacemacs",
"cib:spacex",
"cib:sparkfun",
"cib:sparkpost",
"cib:spdx",
"cib:speaker-deck",
"cib:spectrum",
"cib:spotify",
"cib:spotlight",
"cib:spreaker",
"cib:spring",
"cib:sprint",
"cib:squarespace",
"cib:stackbit",
"cib:stackexchange",
"cib:stackoverflow",
"cib:stackpath",
"cib:stackshare",
"cib:stadia",
"cib:statamic",
"cib:staticman",
"cib:statuspage",
"cib:steam",
"cib:steem",
"cib:steemit",
"cib:stitcher",
"cib:storify",
"cib:storybook",
"cib:strapi",
"cib:strava",
"cib:stripe-s",
"cib:stripe",
"cib:stubhub",
"cib:stumbleupon",
"cib:styleshare",
"cib:stylus",
"cib:sublime-text",
"cib:subversion",
"cib:superuser",
"cib:svelte",
"cib:svg",
"cib:swagger",
"cib:swarm",
"cib:swift",
"cib:symantec",
"cib:symfony",
"cib:synology",
"cib:t-mobile",
"cib:tableau",
"cib:tails",
"cib:tapas",
"cib:teamviewer",
"cib:ted",
"cib:teespring",
"cib:telegram-plane",
"cib:telegram",
"cib:tencent-qq",
"cib:tencent-weibo",
"cib:tensorflow",
"cib:terraform",
"cib:tesla",
"cib:the-mighty",
"cib:the-movie-database",
"cib:tidal",
"cib:tiktok",
"cib:tinder",
"cib:todoist",
"cib:toggl",
"cib:topcoder",
"cib:toptal",
"cib:tor",
"cib:toshiba",
"cib:trainerroad",
"cib:trakt",
"cib:travisci",
"cib:treehouse",
"cib:trello",
"cib:tripadvisor",
"cib:trulia",
"cib:tumblr",
"cib:twilio",
"cib:twitch",
"cib:twitter",
"cib:twoo",
"cib:typescript",
"cib:typo3",
"cib:uber",
"cib:ubisoft",
"cib:ublock-origin",
"cib:ubuntu",
"cib:udacity",
"cib:udemy",
"cib:uikit",
"cib:umbraco",
"cib:unity",
"cib:unreal-engine",
"cib:unsplash",
"cib:untappd",
"cib:upwork",
"cib:usb",
"cib:v8",
"cib:vagrant",
"cib:venmo",
"cib:verizon",
"cib:viadeo",
"cib:viber",
"cib:vim",
"cib:vimeo-v",
"cib:vimeo",
"cib:vine",
"cib:virb",
"cib:visa",
"cib:visual-studio-code",
"cib:visual-studio",
"cib:vk",
"cib:vlc",
"cib:vsco",
"cib:vue-js",
"cib:wattpad",
"cib:weasyl",
"cib:webcomponents-org",
"cib:webpack",
"cib:webstorm",
"cib:wechat",
"cib:whatsapp",
"cib:when-i-work",
"cib:wii",
"cib:wiiu",
"cib:wikipedia",
"cib:windows",
"cib:wire",
"cib:wireguard",
"cib:wix",
"cib:wolfram-language",
"cib:wolfram-mathematica",
"cib:wolfram",
"cib:wordpress",
"cib:wpengine",
"cib:x-pack",
"cib:xbox",
"cib:xcode",
"cib:xero",
"cib:xiaomi",
"cib:xing",
"cib:xrp",
"cib:xsplit",
"cib:y-combinator",
"cib:yahoo",
"cib:yammer",
"cib:yandex",
"cib:yarn",
"cib:yelp",
"cib:youtube",
"cib:zalando",
"cib:zapier",
"cib:zeit",
"cib:zendesk",
"cib:zerply",
"cib:zillow",
"cib:zingat",
"cib:zoom",
"cib:zorin",
"cib:zulip",
]);
