
<template>
  <modal name="modal-qr"
        transition="nice-modal-fade"
        classes="ngdialog-content"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto">
    <div class="modal_wrapper">
    <div  slot="top-right">
        <button @click="$modal.hide('modal-qr')" class="ngdialog-close"></button>
    </div>
    <div class="modalSusc">
        
        <div class="col-md-4">
            <qr-code :text="urlform" :size="1000" error-level="L" class="mt10 mb10 pl20_mobile pr20_mobile qr_eventos"/>
        </div>
</div>
</div>  
</modal>
</template>

<script>
export default {
    
    data() {
        return {
            event_id:this.$route.params.event,
            getUser:this.$store.state.userProfile,
            urlform:'https://form.linkcard.app/'+'event/'+this.$route.params.loyalty+'/'+this.$store.state.userProfile.username,
            
        }
    },
    methods: {
    },
    components: {  },
}
</script>
<style>


</style>


