
<template>
  <modal name="modal-review"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
        
   <div class="modal_wrapper" style="background-color: #ededed;">
       <div slot="top-right">
            <button @click="$modal.hide('modal-review')" class="ngdialog-close"></button>
        </div>
        <div class="form-group mt30">
            <div class="input-group">
                <h4 class="input-add-on c_primary w100" style="width:100% !important;font-weight: 300;display:block;">{{$t('notis.leave')}}</h4>
                <select class="mb10" name="stars" v-model="review.stars"  v-validate="'required'">
                    <option value="1">1 {{$t('partners.stars')}}</option>
                    <option value="2">2 {{$t('partners.stars')}}</option>
                    <option value="3">3 {{$t('partners.stars')}}</option>
                    <option value="4">4 {{$t('partners.stars')}}</option>
                    <option value="5">5 {{$t('partners.stars')}}</option>
                </select>
                <client-only><div v-if="errors.has('stars')" class="invalid-feedback">{{ errors.first('stars') }}</div></client-only>
                <textarea style="overflow:hidden;width: 100% !important;" maxlength="400" class="form-control w100 " rows="5" name="name" :placeholder="'Leave your review'"  v-validate="'required'" v-model='review.text'/>
                <client-only><div v-if="errors.has('name')" class="invalid-feedback">{{ errors.first('name') }}</div></client-only>
                <span class="input-group-btn mt10">
                    <button class="btn btn-primary btn-initial text-uppercase" style="border-radius: 0;" @click="addReview()">{{$t('sectores.crear')}}</button>
                </span>
            </div>
        </div>
    </div>

</modal>
</template>
<script>
export default {
    data () {
      return {
          partnerUser:'',
          getUser:this.$store.state.userProfile,
          review:{
              stars:null,
              text:'',
          }
      }
    },
    
    methods: {
        beforeOpen (event) {
            this.review={
              date:this.$util.getDate(),
              stars:null,
              text:''
            }
            if(event.params.profile)this.partnerUser=event.params.profile;    
        },  
        addReview(){
            var dis=this;
            this.$validator.validateAll().then((result) => {
                if (result){
                    dis.$modal.hide('modal-review')
                    dis.$util.leaveReview(dis.partnerUser,dis.getUser,dis.review);
                }
            }).catch(error => {
                console.error(error)
            });
            
        },
        
    }
    
  }
</script>
<style>
  .modal_wrapper {
    padding: 10px;
    font-style: 13px;
    border-radius: 5px;
  }
  .v--modal-overlay[data-modal="size-modal"] {
    background: #ededed;
  }

</style>
