<template>
   <!-- BLOCK EDITAR "TITULO" -->
    <div class="edicion_block">
        <h4>Video</h4>

        <div class="row form_row">
            <div class="col-12">
                <div class="form-group">
                    <input  v-validate="'url:require_protocol'" name="video" type="text" maxlength="500" class="form-control input_wicon input_website"  placeholder="Youtube / Vimeo link" v-model="valor.url">
                    <client-only><span>{{ errors.first('video') }}</span></client-only>
                </div>
            </div>
        </div>

    </div>
</template>

<script>






export default {
    props: ['value'],
	data() {
		return { 
            valor:this.value
		}
    },  
  
    components:{

    }
}
</script>

