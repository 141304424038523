
<template>
<client-only>
  <modal name="modal-msg"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
         
   <div class="modal_wrapper " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-msg')" class="ngdialog-close"></button>
        </div>
        <form  class="card " novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <div class="form-group " style="min-height:60px;">
                    <div class="firma_avatar">
                         <img  :src="user_to.img"  @error="function(event){event.target.src=$util.getLincImage(user_to.cardVal,null)}" class="w-100">
                    </div>
                    <div class="firma_user_info">
                        <h6 class="firma_user_name">{{user_to.title}}</h6>
                        <span class="firma_user_prof">{{user_to.subtitle}}</span>
                    </div>
                </div>
               <!--- <h3 class="text-center">{{$t('msg.enter')}}</h3>-->
                <small class="text-center mb20"></small>
                <div v-if="!msg_from" class="form-group">
                    <textarea maxlength="400" style="overflow:hidden" rows="6"  name="desc" v-validate="{ required: true }" class="form-control dark" type="text"  :placeholder="$t('msg.write')"  v-model='msg_reply'/>
                    <client-only><span class="vuerror">{{ errors.first('desc') }}</span></client-only>
                </div>
                <div v-if="msg_from" class="form-group">
                    <p>{{msg_from}}</p>
                </div>
                <div v-if="!msg_from" class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase w-100" 
                    type="submit" 
                    :is-loading="loading"
                    :disabled="loading"
                    :status="status"> 
                        {{$t('msg.send')}} 
                    </button-spinner>
                </div>
                <div v-else class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button class="btn btn-initial btn-primary text-uppercase w-100" type="button" @click="reply()">  {{$t('msg.reply')}} </button>
                </div>
                

            </div>
        </form>
      
        
    </div>
  </modal>
</client-only>
</template>
<script>
import { Validator } from "vee-validate";
import {functions,db } from '@/services/firebaseConfig.js';

import { planes_businesscards} from '@/mixins/stripe.js'
import {sectors} from '@/mixins/categories.js'

export default {
    data () {
      return {
        getUser:this.$store.state.userProfile,
        status:'',
        loading:false,
        msg_reply:'',
        msg_from:null,
        user_to:{}
      }
    },
    mounted(){
    },
    methods: {
        reply(){
            //saco la linkcard
            this.user_to.linkcard_id=null
            this.$modal.hide('modal-msg')
            this.$modal.show('modal-msg',{user_to:this.user_to,msg_from:null})
        },
        beforeOpen (event) {
            this.loading=false
            this.user_to=event.params.user_to
            this.msg_from=event.params.msg_from?event.params.msg_from:''
            this.msg_reply=''
        },  
        async save(){
            var dis=this
            this.$util.registerStat_old('inbox','user','directory','bc',this.user_to,null,this.msg_reply)
            this.$util.notif_success(this.$t('msg.sent'))
            dis.status = '';
            dis.$modal.hide('modal-msg') 
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll().then((result) => {
                if(result){
                    dis.save()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
    },
  }
</script>

<style scoped>
.listaagentes{
max-height: 450px !important;
overflow-y: scroll;
}
.card-body h3{
    font-size:20px;
    text-align: left;
    margin-top:10px;
    color:#448FDE;
}
</style>