<template>
    <form autocomplete="off"> 
        
        <div  class="edicion_block">
            <h4> {{$t('info.informacion')}} </h4>
            <imagePicker imgid="profile" v-model="membre.img" :isCircle="true" :isCrop="true"  />
            <span class="info_text">* {{$t('info.formato')}}</span>
            
        </div>
        <div class="edicion_block" v-if="$util.isLoyaltyCustomizer()">
            <hr class="separator mt20 mb20">
            <h4 class="mb10"><b>{{$t('loyaltys.infodel')}}</b></h4>
            <span class="info_text mb20">{{$t('loyaltys.infodelsub')}}</span>
            <img src="@/assets/images/blocked_bg.svg" class="ml-auto mr-auto mt40 mb40 d_table" width="220" style="max-width: 100%;">
        </div>
        <div class="edicion_block" v-else>
            <h4 class="mb10"><b>{{$t('loyaltys.infodel')}}</b></h4>
            <div class="form-group">
                <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="$t('info.nombrecc')" v-model='membre.nombre'>
                <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
            </div>
            <div class="form-group">
                <input class="form-control" type="email" autocomplete="none"  v-validate="{ required: true}" name="email" maxlength="50" :placeholder="$t('info.correoelec')"  v-model='membre.email'>
                <client-only><span class="vuerror">{{ errors.first('email') }}</span></client-only>
            </div>
            <div class="form-group">

                <vue-phone-number-input
                v-model="mobilephone"
                :valid-color="'none'"
                :default-country-code="$store.getters.countryCode"
                :no-validator-state="false"
                v-validate="{ required: true}" 
                :placeholder="$t('info.telmovil')"
                @update="onUpdateMobile"
                :translations="$t('inputphone_mobile')" />
            </div>
            <div class="form-group">
                <label>{{$t('info.birthday')}}</label>
                <div style="width:100%;">
                    <input type="text" style="width:30%;display:inline-block;" pattern="\d*" maxlength="2" v-model.trim="birthday_day"  name="birthday_day" v-validate="'required|min:1|max:2'"  :class="{ 'is-invalid': errors.has('birthday_day') }" :placeholder="'DD'" class="form-control " >
                    <input type="text" style="width:30%;display:inline-block;" pattern="\d*" maxlength="2"  v-model.trim="birthday_month"  name="birthday_month" v-validate="'required|min:1|max:2'" :class="{ 'is-invalid': errors.has('birthday_month') }" :placeholder="'MM'" class="form-control " >
                    <input type="text" style="width:38%;display:inline-block;" pattern="\d*" maxlength="4"  v-model.trim="birthday_year"  name="birthday_year" v-validate="'required|min:4|max:4'" :class="{ 'is-invalid': errors.has('birthday_year') }" :placeholder="'AAAA'" class="form-control " >
                </div>
                <client-only><span class="vuerror">{{ errors.first('birthday_day') }}</span></client-only>
                <client-only><span class="vuerror">{{ errors.first('birthday_month') }}</span></client-only>
                <client-only><span class="vuerror">{{ errors.first('birthday_year') }}</span></client-only>
            </div>
            <div class="form-group">
                <input type="text" v-model.trim="membre.dni"  name="dni" v-validate="{ required: true}"  :class="{ 'is-invalid': errors.has('dni') }" :placeholder="$t('info.dni')" class="form-control " >
                <div class="vuerror" v-if="errors.has('dni')" >{{ errors.first('dni') }}</div>
            </div>
        </div>
       

    </form>

</template>


<script>

import leftColInfoD from "./leftColInfoD.vue"

import imagePicker from '@/components/imagePicker.vue'


import {sectors} from '@/mixins/categories.js'
import {regions} from '@/mixins/regions.js'
export default {
    data(){
        return{
            image: '',
            mobilephone:'',
            sectors:sectors,
            getUser:this.$store.state.userProfile,
            fechaFormateada:this.$store.getters.editingLinckard.membre.birthday,
            birthday_day:'',
            birthday_month:'',
            birthday_year:''
        }
    },
    computed: {
        membre() { return this.$store.getters.editingLinckard.membre},
    },
    beforeMount(){
        this.mobilephone=this.getNumberOK(this.membre.mobilephone)
        if(!this.membre.mobilephone_code)this.membre.mobilephone_code=this.$store.getters.countryCode
        if(!this.membre.phone_code)this.membre.phone_code=this.$store.getters.countryCode
        if(this.fechaFormateada){
            let fecha=new Date(this.fechaFormateada)
            this.birthday_day=fecha.getDate()
            this.birthday_month=fecha.getMonth()+1
            this.birthday_year=fecha.getFullYear()
        }
    },
    mounted(){
        this.$bus.$on('validateLinc',comp=>{
            this.$validator.validateAll()
        });
    },
    methods:{
        
        getNumberOK(myString){
            if(!myString)return '';
            var str = myString.substring(myString.indexOf(' ')+1)
            return str
        },
        onUpdateMobile(payload) {
            if(payload.formatInternational)this.membre.mobilephone=payload.formatInternational
            if(payload.countryCode)this.membre.mobilephone_code=payload.countryCode
        },
    },
    watch:{
        birthday_day(){ this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)},
        birthday_month(){this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)},
        birthday_year(){ this.getUser.account_details.account_birthday=this.$util.parseBirthday(this.birthday_year,this.birthday_month,this.birthday_day)}
    },
    components:{
        leftColInfoD,
        imagePicker
    }
}
</script>
<style>  
       
label::before,label::after{
    display:none !important;
}  
.radiobutton_group label{
    padding-top: 10px !important;
}
.radiobutton_group label{
color:grey !important;
}
.radiobutton_group .title{
display: block !important;
font-size:10px;
}

.edicion_block .dropdown-toggle::after{
    display:none;
}

input[type="file"] {
    display: none;
}

.pretty .state label:after, .pretty .state label:before {

    display:block !important;
}
.p-default{
    margin:0 auto !important;
}



.pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.pretty.p-curve .state label:after, .pretty.p-curve .state label:before{
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #d8d8d8;
    border:0px;
}

.pretty.p-default input:checked~.state label:after{

    color:#878787;
    background-color:#878787 !important;
    content: '';
    width: 10px;
    height: 10px;
    background: #878787;
    position: absolute;
    top: 0;
    margin-top: 5px;
    left: 50%;
    margin-left: -5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

}
.tags-input-badge{
font-weight:400;
margin-bottom:2px;
padding:7px !important;
}

</style>


