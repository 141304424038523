<template>
<div v-if="!$util.isMobile() && barra && $util.hayBarra()" class="barra" ><div v-html="$util.hayBarra()"></div><font-awesome-icon class="manito" style="color:grey;float:right;position:absolute;right:10;top:15;" @click="dismissBarra()" icon="times-circle" /></div>
</template>
    

<script>
export default {
    data(){
        return{
            barra:true,
        }
    },
    mounted() {
        var dis = this
        if(this.barra && this.$util.hayBarra())document.getElementById('panel').classList.add('hay_barra')
        else document.getElementById('panel').classList.remove('hay_barra')
    },
    methods:{
        dismissBarra(){
            this.$util.dismissBarra()
            this.barra=false
        }
    },
    watch:{
        barra(val){
            document.getElementById('panel').classList.remove('hay_barra')
        }
    }
}
</script>
<style >


@media (min-width: 799px) {
    .panel.hay_barra .barra{
        position: fixed !important;
        top:0 !important;
        z-index:56000 !important;
        width:100% !important;
        background: #FEF7DA !important;
        padding:12px 16px;
        text-align:center;
       /* padding-left:250px !important;*/
    }
    .panel.hay_barra #LeftNav,
    .panel.hay_barra .left_nav,
    .panel.hay_barra .nav_filter{
        padding-top:23px !important;
    }
}
@media (min-width: 1025px) {
    .panel.hay_barra .barra{
        position: fixed !important;
        top:0 !important;
        z-index:56000 !important;
        width:100% !important;
        background: #FEF7DA !important;
        padding:12px 16px;
        text-align:center;
        padding-left:250px !important;
    }
    .panel.hay_barra #LeftNav,
    .panel.hay_barra .card_list_wrapper ,
    .panel.hay_barra .left_nav,
    .panel.hay_barra .nav_filter{
        padding-top:45px !important;
    }
    .panel.hay_barra .nav_sector{
        top:45px;
    }
}

@media (min-width: 800px) {

    .panel.hay_barra .nav_sector{
        top:65px;
    }
}
@-moz-document url-prefix() {
    .hay_barra .barra svg{
        color: grey;
        float: right;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}
</style>