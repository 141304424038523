<template>
<div class="wrapper_mobile_view " style="bottom:0px;padding-top:0px;">
    <editorBottomMenu/>
    <div class="wrapper_mobile_inner no_scrollbar">
        <div id="edicionLeft" v-if="editor">
            <component v-if="$store.state.sobreComp=='leftColSobreDA' || $store.state.sobreComp=='leftColSettings' || (currentCompDesign == 'leftColDC' && $store.state.sobreComp=='leftColSobreDD')" :is="leftComp" :profile="getUser" :editcual="editCual" :key="editCual.index" ></component>
        </div>
    </div>
</div>

</template>
    
    
<script>


import editorBottomMenu from "./editorBottomMenu.vue"
import leftColInfoA from "./leftColInfoA.vue"
import leftColMembreA from "./leftColMembreA.vue"
import leftColMembreD from "./leftColMembreD.vue"
import leftColInfoD from "./leftColInfoD.vue"
import leftColSettings from "./leftColSettings.vue"
import leftColSobreA from "./leftColSobreA.vue"
import leftColSobreD from "./leftColSobreD.vue"
import leftColGuardarA from "./leftColGuardarA.vue"
import leftColGuardarD from "./leftColGuardarD.vue"
import leftColCompartirA from "./leftColCompartirA.vue"
import leftColCompartirD from "./leftColCompartirD.vue"
import rightColPlantilla from "@/layouts/main_Customizer/rightColPlantilla.vue"

export default {
  props:['profile'],
  data(){
      return{
          editor:true,
          cardVal:this.$store.getters.editingLinckard,
          getUser:this.profile,
          editCual:'editEmpty',
          currentComp: this.$util.isLoyaltyCustomizer()?'leftColMembre':this.$store.getters.editingLinckard.isLanding?'leftColSobre':'leftColInfo',
          currentCompDesign:'leftColDC'
            
      }
  },
  created(){
    this.$bus.$on('switchCompLeft', comp => {
        this.currentComp = comp;
        if(comp=='design')this.currentCompDesign='leftColDT'
    });
    this.$bus.$on('switchComponentRightDesign', comp => {
        this.currentCompDesign=comp
        console.log(comp)
    });
    this.$bus.$on('switchCompSobre', comp => {
            this.editCual='editEmpty';
            if(comp=='leftColSobreA')this.$store.state.infoMode='content'
            if(comp=='leftColSobreD')this.$store.state.infoMode='design'
            this.$store.state.sobreComp==comp
            this.currentComp='leftColSobre'
      });
      this.$bus.$on('currentCompSobreEditables',comp=>{
          this.editCual=comp;
          this.currentComp='leftColSobreD'
          this.$store.state.sobreComp=='leftColSobreDA'
          this.currentCompDesign='leftColDC'
          this.$store.state.infoMode='content'
        });
      this.$bus.$on('switchComp', comp => {
          comp=comp.replace('preview', "leftCol");
          this.currentComp = comp;
          console.log('cuatro',comp)
      });
      
      this.$bus.$on('toggleMobileEditor', state => {
            this.editor=state
      });
        
  },
  computed:{
    leftComp(){
      if(this.$store.state.infoMode=='settings')return 'leftColSettings'
      if(this.currentComp=='leftColSobreD')return 'leftColSobreD'
      let suffix=this.$store.state.infoMode=='design'?'D':'A'
      let comp=this.currentComp+suffix
      return comp
    },
  },
  methods: {

        menuToggle(){
          this.$bus.$emit('toggleMobileEditor',false)
        },
        closeAllDivs(){

          this.$store.state.sobreComp=='leftColSobreDA'
          this.$store.state.infoMode='content'
          this.currentComp='leftColSobre'
          this.$util.closeAllDivs()

        },
        switchComponentRightDesign(comp) {
          //this.$bus.$emit("switchCompLeft", comp);
          this.currentCompDesign=comp
        },
        switchComponentLeft(comp) {
          this.$bus.$emit("switchCompLeft", comp);
          this.currentComp=comp
        },
  },

  components:{
        'leftColInfoA': leftColInfoA,
        'leftColMembreA':leftColMembreA,
        'leftColMembreD':leftColMembreD,
        'leftColInfoD': leftColInfoD,
        'leftColGuardarA': leftColGuardarA,
        'leftColGuardarD': leftColGuardarD,
        'leftColSobreA': leftColSobreA,
        'leftColSobreD': leftColSobreD,
        'leftColCompartirA': leftColCompartirA,
        'leftColCompartirD': leftColCompartirD,
        'rightColPlantilla':rightColPlantilla,
        'leftColSettings':leftColSettings,
        'editorBottomMenu':editorBottomMenu
  }

  
};
</script>
