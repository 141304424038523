<template>
<div >
   <div class="edicion_block">
        <h4 class="mb10"><b>{{$t('firmas.editor_t1')}}</b></h4>
        <small class="info_text_sm mb20">{{$t('firmas.editor_t2')}}</small>
        <div class="row">
            <div class="col-12" v-for='(item) in csignautures' :key='item.id'>
                <div class="module_container">
                    <div class="module_head">
                        <div class="radiobutton_group">
                            <p-radio class="ml-0 p-default p-curve nueva" v-bind:id="'radio'+item.id" v-bind:value="item.id" v-bind:name="'radio'+item.id"  v-model="template_id" ></p-radio>
                            <label for="radio01"></label>
                        </div>
                        <span :class="'label mt5 mb5 '+(item.type=='Free'?'freemium':'pro')">{{item.type}}</span>
                    </div>
                    <img v-bind:id="'radio'+item.id" :src="require('@/assets/images/firma_template'+(item.id)+'.svg')" class="img_template mt10" alt="template1" :height="item.height">
                </div>
            </div>
        </div>
    </div>
</div>  
</template>

<script>
export default {
    props:['signature'],
    data(){
        return{
            template_id:this.signature.template_id,
        }
    },
    computed: {
        csignautures(){
            return [
                {id:1,height:70,type:'Free'},
                {id:2,height:70,type:'Free'},
                {id:3,height:120,type:'Pro'},
                {id:4,height:120,type:'Pro'},
                {id:5,height:180,type:'Pro'},
                {id:6,height:140,type:'Pro'},
                {id:7,height:180,type:'Pro'},
                {id:8,height:180,type:'Pro'},
            ]
        },
    },
    watch:{
        template_id(newVal,oldVal){
            if(newVal<=2 || this.$util.isTrialOrPro(this.$modal,'signature_template')){
                this.$bus.$emit('switchSignatureTemplate',newVal)
            }else{

                //this.$bus.$emit('switchSignatureTemplate',oldVal)
            }
        }
    }


  
};
</script>

<style >

.pretty.p-curve.nueva .state label:after, .pretty.p-curve.nueva .state label:before{
    content: '';
    position: absolute;
    left: 40px;
    margin-top: 10px;
}
.pretty.p-curve.nueva input:checked~.state label:after{
    content: '';
    position: absolute;
    left: 40px;
    margin-top: 14px;
}

</style>


