<template>
<div class="search_col edicion_container" id="searchPanel">
            <div>
                <div class="edicion_block mb10">
                    <img src="@/assets/images/searchColorIcon.svg" width="48" class="mb10">
                    <h4 class="mb5">{{$t('general.buscar')}}</h4>
                    <div class="row">
                        <div class="form-group col-12">
                            <ais-search-box >
                                <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
                                    <input @keyup.enter="searchToggle()"  @input="refine($event.currentTarget.value)" class="form-control dark" type="search" id="busqueda" maxlength="50" :placeholder="$t('general.cescribir')+'...'" value="">
                                </div>
                            </ais-search-box>
                            
                            <small>* {{$t('general.puedes')}}</small>

                            <small>Powered by <font-awesome-icon :icon="['fab','algolia']" style="font-size:20px;color:#5468FE;"/> </small>
                        </div>
                        <div class="col-12">
                             <div class="d_flex">
                                <button type="button"  @click="searchToggle()" class="btn btn-primary btn-initial text-uppercase">{{$t('general.buscar')}}</button>
                               <!--<button type="button" @click='avanz=!avanz' class="btn btn-initial ml-auto mr-0 btn-adv-search">
                                    <img src="@/assets/images/icon_settings_blue.svg" width="16" style="position: relative; top: -2px;">
                                    {{$t('general.avanz')}}
                                </button>-->
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <hr class="separator mt20 mb20">
                <form>
                    <div  class="edicion_block">
                            <!--<h4>{{$t('general.avanz')}}</h4>-->
                            <div class="form-group mt20" >
                                <label>{{$t('info.categoria')}}</label>
                               
                               <ais-menu-select 
                               :limit="100"
                               attribute="account_details.account_category" >
                                
                                <select
                                    class="form-control"
                                    slot-scope="{ items, canRefine, refine }"
                                    :disabled="!canRefine"
                                    @change="refine($event.currentTarget.value)">
                                    <option value="">All</option>
                                        <option
                                        v-for="item in items"
                                        :key="item.value"
                                        :value="item.value"
                                        :selected="item.isRefined">
                                        {{ sectors[$store.state.locale].find(x => x.id == item.value).label }}
                                        </option>
                                    </select>
                                </ais-menu-select>
                            </div>
                            <div class="form-group mt20">
                                <label>{{$t('info.pais')}}</label>
                                <ais-menu-select
                               :limit="100"
                               attribute="account_details.account_country" >
                                <select
                                    class="form-control"
                                    slot-scope="{ items, canRefine, refine }"
                                    :disabled="!canRefine"
                                    @change="refine($event.currentTarget.value)">
                                    <option value="">All</option>
                                    <option
                                    v-for="item in items"
                                    :key="item.value"
                                    :value="item.value"
                                    :selected="item.isRefined"
                                    >
                                    {{ item.label }}
                                    </option>
                                </select>
                                </ais-menu-select>
                            </div>
                            <div class="form-group mt20">
                               <label>{{$t('info.prov')}}</label>
                               <ais-menu-select 
                               :limit="100"
                               attribute="account_details.account_state" >
                                <select
                                    class="form-control"
                                    slot-scope="{ items, canRefine, refine }"
                                    :disabled="!canRefine"
                                    @change="refine($event.currentTarget.value)">
                                    <option value="">All</option>
                                    <option
                                    v-for="item in items"
                                    :key="item.value"
                                    :value="item.value"
                                    :selected="item.isRefined"
                                    >
                                    {{ item.label }}
                                    </option>
                                </select>
                                </ais-menu-select>
                            </div>
                            <div  v-if="($router.history.current.name=='partners')" 
                            class="form-group mt20">

                               <ais-toggle-refinement
                               
                                v-show="($router.history.current.name!='partners')"
                                attribute="isPartner"
                                label="Partners"
                                :off="($router.history.current.name=='partners')"
                                
                                />
                            </div>
                    </div>
                </form>
        </div>

</template>

<script>


import {sectors} from '@/mixins/categories.js'

export default {
    data() {
        return { 
            sectors:sectors,
            busqueda:''
        }
    },
    mounted(){

        let dis=this
        
        setTimeout(() => { 
            if(dis.$route.query.search){
                console.log(document.getElementById('busqueda'))
                document.getElementById('busqueda').value=dis.$route.query.search
                dis.$router.push(this.$route.path)
            }
        }, 800)

        
    },
    methods:{
        searchToggle(){
            const el = document.body.classList;
            if(el.contains("search_open"))el.remove("search_open");



            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'directorio')
            this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser", null)
            
            
        
        }
    },
}
</script>

