<template>
    <div style="width: 336px; align-items: center; min-height: 30px;">
        <center>
            <Icon 
            v-for="flag of element.flags ? element.flags : []"
            :icon="flag" 
            :style="'font-size: ' + (element.size ? element.size : '50px') + '; color: ' + element.color + '; margin-left: ' + element.separation + '; margin-right: ' + element.separation" 
            style="font-size: 18px; display: inline-block; vertical-align: middle;" />
        </center>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2"

export default {
    props: ['element'],
    components: {
        Icon
    },
}
</script>

