<template>
<form>
    <div class="edicion_block ">
        <h4  class="mb10">{{$t('sobre.loyalty')}}</h4>
        <span>{{$t('sobre.landingsub')}}</span>

        <div class="horizontal_scroll_wrapper no_scrollbar">
        <li class="module_row " >
          <div @click="check(element,index)" class="module_col"  :class="{checked: indexChecked==index}" v-for="(element,index) in $util.sobreElements()" :key="element.foto">
                <div :class="$util.isProElementosSobre(getUser,element)?'module_block handle  manito mt15 mb15':'module_block  manito mt15 mb15'">
                    <div class="module_img" >
                        <img :src="require('@/assets/images/blocks/modulo_'+(element.compo.replace('edit',''))+'.svg')">
                        <h6>{{ $t("drags." + element.compo)  }}</h6>
                    </div>
                    <span v-if="!($util.isLoyaltyCustomizer())" :class="'label '+element.tipo+' mt5 mb5'" style="text-transform:capitalize">{{element.tipo}}</span>
                </div>
            </div>
        </li>
    </div>
    </div>

</form>
</template>

<script>

export default {
  props:['profile'],
  data(){
      return{
          getUser:this.profile,
          editable: true,
          cardVal:this.$store.getters.editingLinckard,
          indexChecked:this.$store.state.currentCheckedSobre
    };
  },
  methods: {
    check(element,index){
      this.indexChecked=index
      
    }
  },
  watch:{
    indexChecked(val){
      this.$store.state.currentCheckedSobre=val
    }
  }
};
</script>

