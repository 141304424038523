
const phonecodes = [
  {"name":"Afghanistan","dial_code":"+93","code":"AF","latitude":33,"longitude":65},
  {"name":"Albania","dial_code":"+355","code":"AL","latitude":41,"longitude":20},
  {"name":"Algeria","dial_code":"+213","code":"DZ","latitude":28,"longitude":3},
  {"name":"AmericanSamoa","dial_code":"+1 684","code":"AS","latitude":-14.3333,"longitude":-170},
  {"name":"Andorra","dial_code":"+376","code":"AD","latitude":42.5,"longitude":1.6},
  {"name":"Angola","dial_code":"+244","code":"AO","latitude":-12.5,"longitude":18.5},
  {"name":"Anguilla","dial_code":"+1 264","code":"AI","latitude":18.25,"longitude":-63.1667},
  {"name":"Antarctica","dial_code":"+672","code":"AQ","latitude":-90,"longitude":"0"},
  {"name":"Antigua and Barbuda","dial_code":"+1268","code":"AG","latitude":17.05,"longitude":-61.8},
  {"name":"Argentina","dial_code":"+54","code":"AR","latitude":-34,"longitude":-64},
  {"name":"Armenia","dial_code":"+374","code":"AM","latitude":40,"longitude":45},
  {"name":"Aruba","dial_code":"+297","code":"AW","latitude":12.5,"longitude":-69.9667},
  {"name":"Australia","dial_code":"+61","code":"AU","latitude":-27,"longitude":133},
  {"name":"Austria","dial_code":"+43","code":"AT","latitude":47.3333,"longitude":13.3333},
  {"name":"Azerbaijan","dial_code":"+994","code":"AZ","latitude":40.5,"longitude":47.5},
  {"name":"Bahamas","dial_code":"+1 242","code":"BS","latitude":24.25,"longitude":-76},
  {"name":"Bahrain","dial_code":"+973","code":"BH","latitude":26,"longitude":50.55},
  {"name":"Bangladesh","dial_code":"+880","code":"BD","latitude":24,"longitude":90},
  {"name":"Barbados","dial_code":"+1 246","code":"BB","latitude":13.1667,"longitude":-59.5333},
  {"name":"Belarus","dial_code":"+375","code":"BY","latitude":53,"longitude":28},
  {"name":"Belgium","dial_code":"+32","code":"BE","latitude":50.8333,"longitude":4},
  {"name":"Belize","dial_code":"+501","code":"BZ","latitude":17.25,"longitude":-88.75},
  {"name":"Benin","dial_code":"+229","code":"BJ","latitude":9.5,"longitude":2.25},
  {"name":"Bermuda","dial_code":"+1 441","code":"BM","latitude":32.3333,"longitude":-64.75},
  {"name":"Bhutan","dial_code":"+975","code":"BT","latitude":27.5,"longitude":90.5},
  {"name":"Bolivia, Plurinational State of Bolivia","dial_code":"+591","code":"BO","latitude":-17,"longitude":-65},
  {"name":"Bosnia and Herzegovina","dial_code":"+387","code":"BA","latitude":44,"longitude":18},
  {"name":"Botswana","dial_code":"+267","code":"BW","latitude":-22,"longitude":24},
  {"name":"Bouvet Island","dial_code":"+55","code":"BV","latitude":-54.4333,"longitude":3.4},
  {"name":"Brazil","dial_code":"+55","code":"BR","latitude":-10,"longitude":-55},
  {"name":"British Indian Ocean Territory","dial_code":"+246","code":"IO","latitude":-6,"longitude":71.5},
  {"name":"Brunei Darussalam","dial_code":"+673","code":"BN","latitude":4.5,"longitude":114.6667},
  {"name":"Bulgaria","dial_code":"+359","code":"BG","latitude":43,"longitude":25},
  {"name":"Burkina Faso","dial_code":"+226","code":"BF","latitude":13,"longitude":-2},
  {"name":"Burundi","dial_code":"+257","code":"BI","latitude":-3.5,"longitude":30},
  {"name":"Cambodia","dial_code":"+855","code":"KH","latitude":13,"longitude":105},
  {"name":"Cameroon","dial_code":"+237","code":"CM","latitude":6,"longitude":12},
  {"name":"Canada","dial_code":"+1","code":"CA","latitude":60,"longitude":-95},
  {"name":"Cape Verde","dial_code":"+238","code":"CV","latitude":16,"longitude":-24},
  {"name":"Cayman Islands","dial_code":"+1345","code":"KY","latitude":19.5,"longitude":-80.5},
  {"name":"Central African Republic","dial_code":"+236","code":"CF","latitude":7,"longitude":21},
  {"name":"Chad","dial_code":"+235","code":"TD","latitude":15,"longitude":19},
  {"name":"Chile","dial_code":"+56","code":"CL","latitude":-30,"longitude":-71},
  {"name":"China","dial_code":"+86","code":"CN","latitude":35,"longitude":105},
  {"name":"Christmas Island","dial_code":"+61","code":"CX","latitude":-10.5,"longitude":105.6667},
  {"name":"Cocos (Keeling) Islands","dial_code":"+61","code":"CC","latitude":-12.5,"longitude":96.8333},
  {"name":"Colombia","dial_code":"+57","code":"CO","latitude":4,"longitude":-72},
  {"name":"Comoros","dial_code":"+269","code":"KM","latitude":-12.1667,"longitude":44.25},
  {"name":"Congo","dial_code":"+242","code":"CG","latitude":-1,"longitude":15},
  {"name":"Congo, The Democratic Republic of the","dial_code":"+243","code":"CD","latitude":"0","longitude":25},
  {"name":"Cook Islands","dial_code":"+682","code":"CK","latitude":-21.2333,"longitude":-159.7667},
  {"name":"Costa Rica","dial_code":"+506","code":"CR","latitude":10,"longitude":-84},
  {"name":"Cote d'Ivoire","dial_code":"+225","code":"CI","latitude":8,"longitude":-5},
  {"name":"Croatia","dial_code":"+385","code":"HR","latitude":45.1667,"longitude":15.5},
  {"name":"Cuba","dial_code":"+53","code":"CU","latitude":21.5,"longitude":-80},
  {"name":"Cyprus","dial_code":"+357","code":"CY","latitude":35,"longitude":33},
  {"name":"Czech Republic","dial_code":"+420","code":"CZ","latitude":49.75,"longitude":15.5},
  {"name":"Denmark","dial_code":"+45","code":"DK","latitude":56,"longitude":10},
  {"name":"Djibouti","dial_code":"+253","code":"DJ","latitude":11.5,"longitude":43},
  {"name":"Dominica","dial_code":"+1 767","code":"DM","latitude":15.4167,"longitude":-61.3333},
  {"name":"Dominican Republic","dial_code":"+1 849","code":"DO","latitude":19,"longitude":-70.6667},
  {"name":"Ecuador","dial_code":"+593","code":"EC","latitude":-2,"longitude":-77.5},
  {"name":"Egypt","dial_code":"+20","code":"EG","latitude":27,"longitude":30},
  {"name":"El Salvador","dial_code":"+503","code":"SV","latitude":13.8333,"longitude":-88.9167},
  {"name":"Equatorial Guinea","dial_code":"+240","code":"GQ","latitude":2,"longitude":10},
  {"name":"Eritrea","dial_code":"+291","code":"ER","latitude":15,"longitude":39},
  {"name":"Estonia","dial_code":"+372","code":"EE","latitude":59,"longitude":26},
  {"name":"Ethiopia","dial_code":"+251","code":"ET","latitude":8,"longitude":38},
  {"name":"Falkland Islands (Malvinas)","dial_code":"+500","code":"FK","latitude":-51.75,"longitude":-59},
  {"name":"Faroe Islands","dial_code":"+298","code":"FO","latitude":62,"longitude":-7},
  {"name":"Fiji","dial_code":"+679","code":"FJ","latitude":-18,"longitude":175},
  {"name":"Finland","dial_code":"+358","code":"FI","latitude":64,"longitude":26},
  {"name":"France","dial_code":"+33","code":"FR","latitude":46,"longitude":2},
  {"name":"French Guiana","dial_code":"+594","code":"GF","latitude":4,"longitude":-53},
  {"name":"French Polynesia","dial_code":"+689","code":"PF","latitude":-15,"longitude":-140},
  {"name":"French Southern and Antarctic Lands","dial_code":"+262","code":"TF","latitude":-43,"longitude":67},
  {"name":"Gabon","dial_code":"+241","code":"GA","latitude":-1,"longitude":11.75},
  {"name":"Gambia","dial_code":"+220","code":"GM","latitude":13.4667,"longitude":-16.5667},
  {"name":"Georgia","dial_code":"+995","code":"GE","latitude":42,"longitude":43.5},
  {"name":"Germany","dial_code":"+49","code":"DE","latitude":51,"longitude":9},
  {"name":"Ghana","dial_code":"+233","code":"GH","latitude":8,"longitude":-2},
  {"name":"Gibraltar","dial_code":"+350","code":"GI","latitude":36.1833,"longitude":-5.3667},
  {"name":"Greece","dial_code":"+30","code":"GR","latitude":39,"longitude":22},
  {"name":"Greenland","dial_code":"+299","code":"GL","latitude":72,"longitude":-40},
  {"name":"Grenada","dial_code":"+1 473","code":"GD","latitude":12.1167,"longitude":-61.6667},
  {"name":"Guadeloupe","dial_code":"+590","code":"GP","latitude":16.25,"longitude":-61.5833},
  {"name":"Guam","dial_code":"+1 671","code":"GU","latitude":13.4667,"longitude":144.7833},
  {"name":"Guatemala","dial_code":"+502","code":"GT","latitude":15.5,"longitude":-90.25},
  {"name":"Guernsey","dial_code":"+44","code":"GG","latitude":49.5,"longitude":-2.56},
  {"name":"Guinea","dial_code":"+224","code":"GN","latitude":11,"longitude":-10},
  {"name":"Guinea-Bissau","dial_code":"+245","code":"GW","latitude":12,"longitude":-15},
  {"name":"Guyana","dial_code":"+592","code":"GY","latitude":5,"longitude":-59},
  {"name":"Haiti","dial_code":"+509","code":"HT","latitude":19,"longitude":-72.4167},
  {"name":"Heard Island and McDonald Islands","dial_code":"+672","code":"HM","latitude":-53.1,"longitude":72.5167},
  {"name":"Holy See (Vatican City State)","dial_code":"+379","code":"VA","latitude":41.9,"longitude":12.45},
  {"name":"Honduras","dial_code":"+504","code":"HN","latitude":15,"longitude":-86.5},
  {"name":"Hong Kong","dial_code":"+852","code":"HK","latitude":22.25,"longitude":114.1667},
  {"name":"Hungary","dial_code":"+36","code":"HU","latitude":47,"longitude":20},
  {"name":"Iceland","dial_code":"+354","code":"IS","latitude":65,"longitude":-18},
  {"name":"India","dial_code":"+91","code":"IN","latitude":20,"longitude":77},
  {"name":"Indonesia","dial_code":"+62","code":"ID","latitude":-5,"longitude":120},
  {"name":"Iran, Islamic Republic of","dial_code":"+98","code":"IR","latitude":32,"longitude":53},
  {"name":"Iraq","dial_code":"+964","code":"IQ","latitude":33,"longitude":44},
  {"name":"Ireland","dial_code":"+353","code":"IE","latitude":53,"longitude":-8},
  {"name":"Isle of Man","dial_code":"+44","code":"IM","latitude":54.23,"longitude":-4.55},
  {"name":"Israel","dial_code":"+972","code":"IL","latitude":31.5,"longitude":34.75},
  {"name":"Italy","dial_code":"+39","code":"IT","latitude":42.8333,"longitude":12.8333},
  {"name":"Jamaica","dial_code":"+1 876","code":"JM","latitude":18.25,"longitude":-77.5},
  {"name":"Japan","dial_code":"+81","code":"JP","latitude":36,"longitude":138},
  {"name":"Jersey","dial_code":"+44","code":"JE","latitude":49.21,"longitude":-2.13},
  {"name":"Jordan","dial_code":"+962","code":"JO","latitude":31,"longitude":36},
  {"name":"Kazakhstan","dial_code":"+7","code":"KZ","latitude":48,"longitude":68},
  {"name":"Kenya","dial_code":"+254","code":"KE","latitude":1,"longitude":38},
  {"name":"Kiribati","dial_code":"+686","code":"KI","latitude":1.4167,"longitude":173},
  {"name":"Korea, Democratic People's Republic of","dial_code":"+850","code":"KP","latitude":40,"longitude":127},
  {"name":"Korea, Republic of","dial_code":"+82","code":"KR","latitude":37,"longitude":127.5},
  {"name":"Kuwait","dial_code":"+965","code":"KW","latitude":29.3375,"longitude":47.6581},
  {"name":"Kyrgyzstan","dial_code":"+996","code":"KG","latitude":41,"longitude":75},
  {"name":"Lao People's Democratic Republic","dial_code":"+856","code":"LA","latitude":18,"longitude":105},
  {"name":"Latvia","dial_code":"+371","code":"LV","latitude":57,"longitude":25},
  {"name":"Lebanon","dial_code":"+961","code":"LB","latitude":33.8333,"longitude":35.8333},
  {"name":"Lesotho","dial_code":"+266","code":"LS","latitude":-29.5,"longitude":28.5},
  {"name":"Liberia","dial_code":"+231","code":"LR","latitude":6.5,"longitude":-9.5},
  {"name":"Libyan Arab Jamahiriya","dial_code":"+218","code":"LY","latitude":25,"longitude":17},
  {"name":"Liechtenstein","dial_code":"+423","code":"LI","latitude":47.1667,"longitude":9.5333},
  {"name":"Lithuania","dial_code":"+370","code":"LT","latitude":56,"longitude":24},
  {"name":"Luxembourg","dial_code":"+352","code":"LU","latitude":49.75,"longitude":6.1667},
  {"name":"Macao","dial_code":"+853","code":"MO","latitude":22.1667,"longitude":113.55},
  {"name":"Macedonia, The Former Yugoslav Republic of","dial_code":"+389","code":"MK","latitude":41.8333,"longitude":22},
  {"name":"Madagascar","dial_code":"+261","code":"MG","latitude":-20,"longitude":47},
  {"name":"Malawi","dial_code":"+265","code":"MW","latitude":-13.5,"longitude":34},
  {"name":"Malaysia","dial_code":"+60","code":"MY","latitude":2.5,"longitude":112.5},
  {"name":"Maldives","dial_code":"+960","code":"MV","latitude":3.25,"longitude":73},
  {"name":"Mali","dial_code":"+223","code":"ML","latitude":17,"longitude":-4},
  {"name":"Malta","dial_code":"+356","code":"MT","latitude":35.8333,"longitude":14.5833},
  {"name":"Marshall Islands","dial_code":"+692","code":"MH","latitude":9,"longitude":168},
  {"name":"Martinique","dial_code":"+596","code":"MQ","latitude":14.6667,"longitude":-61},
  {"name":"Mauritania","dial_code":"+222","code":"MR","latitude":20,"longitude":-12},
  {"name":"Mauritius","dial_code":"+230","code":"MU","latitude":-20.2833,"longitude":57.55},
  {"name":"Mayotte","dial_code":"+262","code":"YT","latitude":-12.8333,"longitude":45.1667},
  {"name":"Mexico","dial_code":"+52","code":"MX","latitude":23,"longitude":-102},
  {"name":"Micronesia, Federated States of","dial_code":"+691","code":"FM","latitude":6.9167,"longitude":158.25},
  {"name":"Moldova, Republic of","dial_code":"+373","code":"MD","latitude":47,"longitude":29},
  {"name":"Monaco","dial_code":"+377","code":"MC","latitude":43.7333,"longitude":7.4},
  {"name":"Mongolia","dial_code":"+976","code":"MN","latitude":46,"longitude":105},
  {"name":"Montenegro","dial_code":"+382","code":"ME","latitude":42,"longitude":19},
  {"name":"Montserrat","dial_code":"+1664","code":"MS","latitude":16.75,"longitude":-62.2},
  {"name":"Morocco","dial_code":"+212","code":"MA","latitude":32,"longitude":-5},
  {"name":"Mozambique","dial_code":"+258","code":"MZ","latitude":-18.25,"longitude":35},
  {"name":"Myanmar","dial_code":"+95","code":"MM","latitude":22,"longitude":98},
  {"name":"Namibia","dial_code":"+264","code":"NA","latitude":-22,"longitude":17},
  {"name":"Nauru","dial_code":"+674","code":"NR","latitude":-0.5333,"longitude":166.9167},
  {"name":"Nepal","dial_code":"+977","code":"NP","latitude":28,"longitude":84},
  {"name":"Netherlands","dial_code":"+31","code":"NL","latitude":52.5,"longitude":5.75},
  {"name":"Netherlands Antilles","dial_code":"+599","code":"AN","latitude":12.25,"longitude":-68.75},
  {"name":"New Caledonia","dial_code":"+687","code":"NC","latitude":-21.5,"longitude":165.5},
  {"name":"New Zealand","dial_code":"+64","code":"NZ","latitude":-41,"longitude":174},
  {"name":"Nicaragua","dial_code":"+505","code":"NI","latitude":13,"longitude":-85},
  {"name":"Niger","dial_code":"+227","code":"NE","latitude":16,"longitude":8},
  {"name":"Nigeria","dial_code":"+234","code":"NG","latitude":10,"longitude":8},
  {"name":"Niue","dial_code":"+683","code":"NU","latitude":-19.0333,"longitude":-169.8667},
  {"name":"Norfolk Island","dial_code":"+672","code":"NF","latitude":-29.0333,"longitude":167.95},
  {"name":"Northern Mariana Islands","dial_code":"+1 670","code":"MP","latitude":15.2,"longitude":145.75},
  {"name":"Norway","dial_code":"+47","code":"NO","latitude":62,"longitude":10},
  {"name":"Oman","dial_code":"+968","code":"OM","latitude":21,"longitude":57},
  {"name":"Pakistan","dial_code":"+92","code":"PK","latitude":30,"longitude":70},
  {"name":"Palau","dial_code":"+680","code":"PW","latitude":7.5,"longitude":134.5},
  {"name":"Palestinian Territory, Occupied","dial_code":"+970","code":"PS","latitude":32,"longitude":35.25},
  {"name":"Panama","dial_code":"+507","code":"PA","latitude":9,"longitude":-80},
  {"name":"Papua New Guinea","dial_code":"+675","code":"PG","latitude":-6,"longitude":147},
  {"name":"Paraguay","dial_code":"+595","code":"PY","latitude":-23,"longitude":-58},
  {"name":"Peru","dial_code":"+51","code":"PE","latitude":-10,"longitude":-76},
  {"name":"Philippines","dial_code":"+63","code":"PH","latitude":13,"longitude":122},
  {"name":"Pitcairn","dial_code":"+870","code":"PN","latitude":-24.7,"longitude":-127.4},
  {"name":"Poland","dial_code":"+48","code":"PL","latitude":52,"longitude":20},
  {"name":"Portugal","dial_code":"+351","code":"PT","latitude":39.5,"longitude":-8},
  {"name":"Puerto Rico","dial_code":"+1 939","code":"PR","latitude":18.25,"longitude":-66.5},
  {"name":"Qatar","dial_code":"+974","code":"QA","latitude":25.5,"longitude":51.25},
  {"name":"Réunion","dial_code":"+262","code":"RE","latitude":-21.1,"longitude":55.6},
  {"name":"Romania","dial_code":"+40","code":"RO","latitude":46,"longitude":25},
  {"name":"Russia","dial_code":"+7","code":"RU","latitude":60,"longitude":100},
  {"name":"Rwanda","dial_code":"+250","code":"RW","latitude":-2,"longitude":30},
  {"name":"Saint Helena, Ascension and Tristan Da Cunha","dial_code":"+290","code":"SH","latitude":-15.9333,"longitude":-5.7},
  {"name":"Saint Kitts and Nevis","dial_code":"+1 869","code":"KN","latitude":17.3333,"longitude":-62.75},
  {"name":"Saint Lucia","dial_code":"+1 758","code":"LC","latitude":13.8833,"longitude":-61.1333},
  {"name":"Saint Pierre and Miquelon","dial_code":"+508","code":"PM","latitude":46.8333,"longitude":-56.3333},
  {"name":"Saint Vincent and the Grenadines","dial_code":"+1 784","code":"VC","latitude":13.25,"longitude":-61.2},
  {"name":"Samoa","dial_code":"+685","code":"WS","latitude":-13.5833,"longitude":-172.3333},
  {"name":"San Marino","dial_code":"+378","code":"SM","latitude":43.7667,"longitude":12.4167},
  {"name":"Sao Tome and Principe","dial_code":"+239","code":"ST","latitude":1,"longitude":7},
  {"name":"Saudi Arabia","dial_code":"+966","code":"SA","latitude":25,"longitude":45},
  {"name":"Senegal","dial_code":"+221","code":"SN","latitude":14,"longitude":-14},
  {"name":"Serbia","dial_code":"+381","code":"RS","latitude":44,"longitude":21},
  {"name":"Seychelles","dial_code":"+248","code":"SC","latitude":-4.5833,"longitude":55.6667},
  {"name":"Sierra Leone","dial_code":"+232","code":"SL","latitude":8.5,"longitude":-11.5},
  {"name":"Singapore","dial_code":"+65","code":"SG","latitude":1.3667,"longitude":103.8},
  {"name":"Slovakia","dial_code":"+421","code":"SK","latitude":48.6667,"longitude":19.5},
  {"name":"Slovenia","dial_code":"+386","code":"SI","latitude":46,"longitude":15},
  {"name":"Solomon Islands","dial_code":"+677","code":"SB","latitude":-8,"longitude":159},
  {"name":"Somalia","dial_code":"+252","code":"SO","latitude":10,"longitude":49},
  {"name":"South Africa","dial_code":"+27","code":"ZA","latitude":-29,"longitude":24},
  {"name":"South Georgia and the South Sandwich Islands","dial_code":"+500","code":"GS","latitude":-54.5,"longitude":-37},
  {"name":"Spain","dial_code":"+34","code":"ES","latitude":40,"longitude":-4},
  {"name":"Sri Lanka","dial_code":"+94","code":"LK","latitude":7,"longitude":81},
  {"name":"Sudan","dial_code":"+249","code":"SD","latitude":15,"longitude":30},
  {"name":"Suriname","dial_code":"+597","code":"SR","latitude":4,"longitude":-56},
  {"name":"Svalbard and Jan Mayen","dial_code":"+47","code":"SJ","latitude":78,"longitude":20},
  {"name":"Swaziland","dial_code":"+268","code":"SZ","latitude":-26.5,"longitude":31.5},
  {"name":"Sweden","dial_code":"+46","code":"SE","latitude":62,"longitude":15},
  {"name":"Switzerland","dial_code":"+41","code":"CH","latitude":47,"longitude":8},
  {"name":"Syrian Arab Republic","dial_code":"+963","code":"SY","latitude":35,"longitude":38},
  {"name":"Taiwan","dial_code":"+886","code":"TW","latitude":23.5,"longitude":121},
  {"name":"Tajikistan","dial_code":"+992","code":"TJ","latitude":39,"longitude":71},
  {"name":"Tanzania, United Republic of","dial_code":"+255","code":"TZ","latitude":-6,"longitude":35},
  {"name":"Thailand","dial_code":"+66","code":"TH","latitude":15,"longitude":100},
  {"name":"Timor-Leste","dial_code":"+670","code":"TL","latitude":-8.55,"longitude":125.5167},
  {"name":"Togo","dial_code":"+228","code":"TG","latitude":8,"longitude":1.1667},
  {"name":"Tokelau","dial_code":"+690","code":"TK","latitude":-9,"longitude":-172},
  {"name":"Tonga","dial_code":"+676","code":"TO","latitude":-20,"longitude":-175},
  {"name":"Trinidad and Tobago","dial_code":"+1 868","code":"TT","latitude":11,"longitude":-61},
  {"name":"Tunisia","dial_code":"+216","code":"TN","latitude":34,"longitude":9},
  {"name":"Turkey","dial_code":"+90","code":"TR","latitude":39,"longitude":35},
  {"name":"Turkmenistan","dial_code":"+993","code":"TM","latitude":40,"longitude":60},
  {"name":"Turks and Caicos Islands","dial_code":"+1 649","code":"TC","latitude":21.75,"longitude":-71.5833},
  {"name":"Tuvalu","dial_code":"+688","code":"TV","latitude":-8,"longitude":178},
  {"name":"Uganda","dial_code":"+256","code":"UG","latitude":1,"longitude":32},
  {"name":"Ukraine","dial_code":"+380","code":"UA","latitude":49,"longitude":32},
  {"name":"United Arab Emirates","dial_code":"+971","code":"AE","latitude":24,"longitude":54},
  {"name":"United Kingdom","dial_code":"+44","code":"GB","latitude":54,"longitude":-2},
  {"name":"United States","dial_code":"+1","code":"US","latitude":38,"longitude":-97},
  {"name":"United States Minor Outlying Islands","dial_code":"+1581","code":"UM","latitude":19.2833,"longitude":166.6},
  {"name":"Uruguay","dial_code":"+598","code":"UY","latitude":-33,"longitude":-56},
  {"name":"Uzbekistan","dial_code":"+998","code":"UZ","latitude":41,"longitude":64},
  {"name":"Vanuatu","dial_code":"+678","code":"VU","latitude":-16,"longitude":167},
  {"name":"Venezuela, Bolivarian Republic of","dial_code":"+58","code":"VE","latitude":8,"longitude":-66},
  {"name":"Viet Nam","dial_code":"+84","code":"VN","latitude":16,"longitude":106},
  {"name":"Virgin Islands, British","dial_code":"+1 284","code":"VG","latitude":18.5,"longitude":-64.5},
  {"name":"Virgin Islands, U.S.","dial_code":"+1 340","code":"VI","latitude":18.3333,"longitude":-64.8333},
  {"name":"Wallis and Futuna","dial_code":"+681","code":"WF","latitude":-13.3,"longitude":-176.2},
  {"name":"Western Sahara","dial_code":"+732","code":"EH","latitude":24.5,"longitude":-13},
  {"name":"Yemen","dial_code":"+967","code":"YE","latitude":15,"longitude":48},
  {"name":"Zambia","dial_code":"+260","code":"ZM","latitude":-15,"longitude":30},
  {"name":"Zimbabwe","dial_code":"+263","code":"ZW","latitude":-20,"longitude":30}
]

const regions = [
  {
    label:"Afghanistan",
    "countryShortCode":"AF",
    "regions":[
      {
        label:"Badakhshan",
        "shortCode":"BDS"
      },
      {
        label:"Badghis",
        "shortCode":"BDG"
      },
      {
        label:"Baghlan",
        "shortCode":"BGL"
      },
      {
        label:"Balkh",
        "shortCode":"BAL"
      },
      {
        label:"Bamyan",
        "shortCode":"BAM"
      },
      {
        label:"Daykundi",
        "shortCode":"DAY"
      },
      {
        label:"Farah",
        "shortCode":"FRA"
      },
      {
        label:"Faryab",
        "shortCode":"FYB"
      },
      {
        label:"Ghazni",
        "shortCode":"GHA"
      },
      {
        label:"Ghor",
        "shortCode":"GHO"
      },
      {
        label:"Helmand",
        "shortCode":"HEL"
      },
      {
        label:"Herat",
        "shortCode":"HER"
      },
      {
        label:"Jowzjan",
        "shortCode":"JOW"
      },
      {
        label:"Kabul",
        "shortCode":"KAB"
      },
      {
        label:"Kandahar",
        "shortCode":"KAN"
      },
      {
        label:"Kapisa",
        "shortCode":"KAP"
      },
      {
        label:"Khost",
        "shortCode":"KHO"
      },
      {
        label:"Kunar",
        "shortCode":"KNR"
      },
      {
        label:"Kunduz",
        "shortCode":"KDZ"
      },
      {
        label:"Laghman",
        "shortCode":"LAG"
      },
      {
        label:"Logar",
        "shortCode":"LOW"
      },
      {
        label:"Maidan Wardak",
        "shortCode":"WAR"
      },
      {
        label:"Nangarhar",
        "shortCode":"NAN"
      },
      {
        label:"Nimruz",
        "shortCode":"NIM"
      },
      {
        label:"Nuristan",
        "shortCode":"NUR"
      },
      {
        label:"Paktia",
        "shortCode":"PIA"
      },
      {
        label:"Paktika",
        "shortCode":"PKA"
      },
      {
        label:"Panjshir",
        "shortCode":"PAN"
      },
      {
        label:"Parwan",
        "shortCode":"PAR"
      },
      {
        label:"Samangan",
        "shortCode":"SAM"
      },
      {
        label:"Sar-e Pol",
        "shortCode":"SAR"
      },
      {
        label:"Takhar",
        "shortCode":"TAK"
      },
      {
        label:"Urozgan",
        "shortCode":"ORU"
      },
      {
        label:"Zabul",
        "shortCode":"ZAB"
      }
    ]
  },
  {
    label:"Åland Islands",
    "countryShortCode":"AX",
    "regions":[
      {
        label:"Brändö",
        "shortCode":"BR"
      },
      {
        label:"Eckerö",
        "shortCode":"EC"
      },
      {
        label:"Finström",
        "shortCode":"FN"
      },
      {
        label:"Föglö",
        "shortCode":"FG"
      },
      {
        label:"Geta",
        "shortCode":"GT"
      },
      {
        label:"Hammarland",
        "shortCode":"HM"
      },
      {
        label:"Jomala",
        "shortCode":"JM"
      },
      {
        label:"Kumlinge",
        "shortCode":"KM"
      },
      {
        label:"Kökar",
        "shortCode":"KK"
      },
      {
        label:"Lemland",
        "shortCode":"LE"
      },
      {
        label:"Lumparland",
        "shortCode":"LU"
      },
      {
        label:"Mariehamn",
        "shortCode":"MH"
      },
      {
        label:"Saltvik",
        "shortCode":"SV"
      },
      {
        label:"Sottunga",
        "shortCode":"ST"
      },
      {
        label:"Sund",
        "shortCode":"SD"
      },
      {
        label:"Vårdö",
        "shortCode":"VR"
      }
    ]
  },
  {
    label:"Albania",
    "countryShortCode":"AL",
    "regions":[
      {
        label:"Berat",
        "shortCode":"01"
      },
      {
        label:"Dibër",
        "shortCode":"09"
      },
      {
        label:"Durrës",
        "shortCode":"02"
      },
      {
        label:"Elbasan",
        "shortCode":"03"
      },
      {
        label:"Fier",
        "shortCode":"04"
      },
      {
        label:"Gjirokastër",
        "shortCode":"05"
      },
      {
        label:"Korçë",
        "shortCode":"06"
      },
      {
        label:"Kukës",
        "shortCode":"07"
      },
      {
        label:"Lezhë",
        "shortCode":"08"
      },
      {
        label:"Shkodër",
        "shortCode":"10"
      },
      {
        label:"Tirana",
        "shortCode":"11"
      },
      {
        label:"Vlorë",
        "shortCode":"12"
      }
    ]
  },
  {
    label:"Algeria",
    "countryShortCode":"DZ",
    "regions":[
      {
        label:"Adrar",
        "shortCode":"01"
      },
      {
        label:"Aïn Defla",
        "shortCode":"44"
      },
      {
        label:"Aïn Témouchent",
        "shortCode":"46"
      },
      {
        label:"Algiers",
        "shortCode":"16"
      },
      {
        label:"Annaba",
        "shortCode":"23"
      },
      {
        label:"Batna",
        "shortCode":"05"
      },
      {
        label:"Béchar",
        "shortCode":"08"
      },
      {
        label:"Béjaïa",
        "shortCode":"06"
      },
      {
        label:"Biskra",
        "shortCode":"07"
      },
      {
        label:"Blida",
        "shortCode":"09"
      },
      {
        label:"Bordj Bou Arréridj",
        "shortCode":"34"
      },
      {
        label:"Bouïra",
        "shortCode":"10"
      },
      {
        label:"Boumerdès",
        "shortCode":"35"
      },
      {
        label:"Chlef",
        "shortCode":"02"
      },
      {
        label:"Constantine",
        "shortCode":"25"
      },
      {
        label:"Djelfa",
        "shortCode":"17"
      },
      {
        label:"El Bayadh",
        "shortCode":"32"
      },
      {
        label:"El Oued",
        "shortCode":"39"
      },
      {
        label:"El Tarf",
        "shortCode":"36"
      },
      {
        label:"Ghardaïa",
        "shortCode":"47"
      },
      {
        label:"Guelma",
        "shortCode":"24"
      },
      {
        label:"Illizi",
        "shortCode":"33"
      },
      {
        label:"Jijel",
        "shortCode":"18"
      },
      {
        label:"Khenchela",
        "shortCode":"40"
      },
      {
        label:"Laghouat",
        "shortCode":"03"
      },
      {
        label:"Mascara",
        "shortCode":"29"
      },
      {
        label:"Médéa",
        "shortCode":"26"
      },
      {
        label:"Mila",
        "shortCode":"43"
      },
      {
        label:"Mostaganem",
        "shortCode":"27"
      },
      {
        label:"Msila",
        "shortCode":"28"
      },
      {
        label:"Naâma",
        "shortCode":"45"
      },
      {
        label:"Oran",
        "shortCode":"31"
      },
      {
        label:"Ouargla",
        "shortCode":"30"
      },
      {
        label:"Oum el Bouaghi",
        "shortCode":"04"
      },
      {
        label:"Relizane",
        "shortCode":"48"
      },
      {
        label:"Saïda",
        "shortCode":"20"
      },
      {
        label:"Sétif",
        "shortCode":"19"
      },
      {
        label:"Sidi Bel Abbès",
        "shortCode":"22"
      },
      {
        label:"Skikda",
        "shortCode":"21"
      },
      {
        label:"Souk Ahras",
        "shortCode":"41"
      },
      {
        label:"Tamanghasset",
        "shortCode":"11"
      },
      {
        label:"Tébessa",
        "shortCode":"12"
      },
      {
        label:"Tiaret",
        "shortCode":"14"
      },
      {
        label:"Tindouf",
        "shortCode":"37"
      },
      {
        label:"Tipaza",
        "shortCode":"42"
      },
      {
        label:"Tissemsilt",
        "shortCode":"38"
      },
      {
        label:"Tizi Ouzou",
        "shortCode":"15"
      },
      {
        label:"Tlemcen",
        "shortCode":"13"
      }
    ]
  },
  {
    label:"American Samoa",
    "countryShortCode":"AS",
    "regions":[
      {
        label:"Tutuila",
        "shortCode":"01"
      },
      {
        label:"Aunu'u",
        "shortCode":"02"
      },
      {
        label:"Ta'ū",
        "shortCode":"03"
      },
      {
        label:"Ofu‑Olosega",
        "shortCode":"04"
      },
      {
        label:"Rose Atoll",
        "shortCode":"21"
      },
      {
        label:"Swains Island",
        "shortCode":"22"
      }
    ]
  },
  {
    label:"Andorra",
    "countryShortCode":"AD",
    "regions":[
      {
        label:"Andorra la Vella",
        "shortCode":"07"
      },
      {
        label:"Canillo",
        "shortCode":"02"
      },
      {
        label:"Encamp",
        "shortCode":"03"
      },
      {
        label:"Escaldes-Engordany",
        "shortCode":"08"
      },
      {
        label:"La Massana",
        "shortCode":"04"
      },
      {
        label:"Ordino",
        "shortCode":"05"
      },
      {
        label:"Sant Julià de Lòria",
        "shortCode":"06"
      }
    ]
  },
  {
    label:"Angola",
    "countryShortCode":"AO",
    "regions":[
      {
        label:"Bengo",
        "shortCode":"BGO"
      },
      {
        label:"Benguela",
        "shortCode":"BGU"
      },
      {
        label:"Bié",
        "shortCode":"BIE"
      },
      {
        label:"Cabinda",
        "shortCode":"CAB"
      },
      {
        label:"Cuando Cubango",
        "shortCode":"CCU"
      },
      {
        label:"Cuanza Norte",
        "shortCode":"CNO"
      },
      {
        label:"Cuanza Sul",
        "shortCode":"CUS"
      },
      {
        label:"Cunene",
        "shortCode":"CNN"
      },
      {
        label:"Huambo",
        "shortCode":"HUA"
      },
      {
        label:"Huíla",
        "shortCode":"HUI"
      },
      {
        label:"Luanda",
        "shortCode":"LUA"
      },
      {
        label:"Lunda Norte",
        "shortCode":"LNO"
      },
      {
        label:"Lunda Sul",
        "shortCode":"LSU"
      },
      {
        label:"Malanje",
        "shortCode":"MAL"
      },
      {
        label:"Moxico",
        "shortCode":"MOX"
      },
      {
        label:"Namibe",
        "shortCode":"NAM"
      },
      {
        label:"Uíge",
        "shortCode":"UIG"
      },
      {
        label:"Zaire",
        "shortCode":"ZAI"
      }
    ]
  },
  {
    label:"Anguilla",
    "countryShortCode":"AI",
    "regions":[
      {
        label:"Anguilla",
        "shortCode":"01"
      },
      {
        label:"Anguillita Island",
        "shortCode":"02"
      },
      {
        label:"Blowing Rock",
        "shortCode":"03"
      },
      {
        label:"Cove Cay",
        "shortCode":"04"
      },
      {
        label:"Crocus Cay",
        "shortCode":"05"
      },
      {
        label:"Deadman's Cay",
        "shortCode":"06"
      },
      {
        label:"Dog Island",
        "shortCode":"07"
      },
      {
        label:"East Cay",
        "shortCode":"08"
      },
      {
        label:"Little Island",
        "shortCode":"09"
      },
      {
        label:"Little Scrub Island",
        "shortCode":"10"
      },
      {
        label:"Mid Cay",
        "shortCode":"11"
      },
      {
        label:"North Cay",
        "shortCode":"12"
      },
      {
        label:"Prickly Pear Cays",
        "shortCode":"13"
      },
      {
        label:"Rabbit Island",
        "shortCode":"14"
      },
      {
        label:"Sandy Island/Sand Island",
        "shortCode":"15"
      },
      {
        label:"Scilly Cay",
        "shortCode":"16"
      },
      {
        label:"Scrub Island",
        "shortCode":"17"
      },
      {
        label:"Seal Island",
        "shortCode":"18"
      },
      {
        label:"Sombrero/Hat Island",
        "shortCode":"19"
      },
      {
        label:"South Cay",
        "shortCode":"20"
      },
      {
        label:"South Wager Island",
        "shortCode":"21"
      },
      {
        label:"West Cay",
        "shortCode":"22"
      }
    ]
  },
  {
    label:"Antarctica",
    "countryShortCode":"AQ",
    "regions":[
      {
        label:"Antarctica",
        "shortCode":"AQ"
      }
    ]
  },
  {
    label:"Antigua and Barbuda",
    "countryShortCode":"AG",
    "regions":[
      {
        label:"Antigua Island",
        "shortCode":"01"
      },
      {
        label:"Barbuda Island",
        "shortCode":"02"
      },
      {
        label:"Bird Island",
        "shortCode":"04"
      },
      {
        label:"Bishop Island",
        "shortCode":"05"
      },
      {
        label:"Blake Island",
        "shortCode":"06"
      },
      {
        label:"Crump Island",
        "shortCode":"09"
      },
      {
        label:"Dulcina Island",
        "shortCode":"10"
      },
      {
        label:"Exchange Island",
        "shortCode":"11"
      },
      {
        label:"Five Islands",
        "shortCode":"12"
      },
      {
        label:"Great Bird Island",
        "shortCode":"13"
      },
      {
        label:"Green Island",
        "shortCode":"14"
      },
      {
        label:"Guiana Island",
        "shortCode":"15"
      },
      {
        label:"Hawes Island",
        "shortCode":"17"
      },
      {
        label:"Hells Gate Island",
        "shortCode":"16"
      },
      {
        label:"Henry Island",
        "shortCode":"18"
      },
      {
        label:"Johnson Island",
        "shortCode":"19"
      },
      {
        label:"Kid Island",
        "shortCode":"20"
      },
      {
        label:"Lobster Island",
        "shortCode":"22"
      },
      {
        label:"Maiden Island",
        "shortCode":"24"
      },
      {
        label:"Moor Island",
        "shortCode":"25"
      },
      {
        label:"Nanny Island",
        "shortCode":"26"
      },
      {
        label:"Pelican Island",
        "shortCode":"27"
      },
      {
        label:"Prickly Pear Island",
        "shortCode":"28"
      },
      {
        label:"Rabbit Island",
        "shortCode":"29"
      },
      {
        label:"Red Head Island",
        "shortCode":"31"
      },
      {
        label:"Redonda Island",
        "shortCode":"03"
      },
      {
        label:"Sandy Island",
        "shortCode":"32"
      },
      {
        label:"Smith Island",
        "shortCode":"33"
      },
      {
        label:"The Sisters",
        "shortCode":"34"
      },
      {
        label:"Vernon Island",
        "shortCode":"35"
      },
      {
        label:"Wicked Will Island",
        "shortCode":"36"
      },
      {
        label:"York Island",
        "shortCode":"37"
      }
    ]
  },
  {
    label:"Argentina",
    "countryShortCode":"AR",
    "regions":[
      {
        label:"Buenos Aires",
        "shortCode":"B"
      },
      {
        label:"Capital Federal",
        "shortCode":"C"
      },
      {
        label:"Catamarca",
        "shortCode":"K"
      },
      {
        label:"Chaco",
        "shortCode":"H"
      },
      {
        label:"Chubut",
        "shortCode":"U"
      },
      {
        label:"Córdoba",
        "shortCode":"X"
      },
      {
        label:"Corrientes",
        "shortCode":"W"
      },
      {
        label:"Entre Ríos",
        "shortCode":"E"
      },
      {
        label:"Formosa",
        "shortCode":"P"
      },
      {
        label:"Jujuy",
        "shortCode":"Y"
      },
      {
        label:"La Pampa",
        "shortCode":"L"
      },
      {
        label:"La Rioja",
        "shortCode":"F"
      },
      {
        label:"Mendoza",
        "shortCode":"M"
      },
      {
        label:"Misiones",
        "shortCode":"N"
      },
      {
        label:"Neuquén",
        "shortCode":"Q"
      },
      {
        label:"Río Negro",
        "shortCode":"R"
      },
      {
        label:"Salta",
        "shortCode":"A"
      },
      {
        label:"San Juan",
        "shortCode":"J"
      },
      {
        label:"San Luis",
        "shortCode":"D"
      },
      {
        label:"Santa Cruz",
        "shortCode":"Z"
      },
      {
        label:"Santa Fe",
        "shortCode":"S"
      },
      {
        label:"Santiago del Estero",
        "shortCode":"G"
      },
      {
        label:"Tierra del Fuego",
        "shortCode":"V"
      },
      {
        label:"Tucumán",
        "shortCode":"T"
      }
    ]
  },
  {
    label:"Armenia",
    "countryShortCode":"AM",
    "regions":[
      {
        label:"Aragatsotn",
        "shortCode":"AG"
      },
      {
        label:"Ararat",
        "shortCode":"AR"
      },
      {
        label:"Armavir",
        "shortCode":"AV"
      },
      {
        label:"Gegharkunik",
        "shortCode":"GR"
      },
      {
        label:"Kotayk",
        "shortCode":"KT"
      },
      {
        label:"Lori",
        "shortCode":"LO"
      },
      {
        label:"Shirak",
        "shortCode":"SH"
      },
      {
        label:"Syunik",
        "shortCode":"SU"
      },
      {
        label:"Tavush",
        "shortCode":"TV"
      },
      {
        label:"Vayots Dzor",
        "shortCode":"VD"
      },
      {
        label:"Yerevan",
        "shortCode":"ER"
      }
    ]
  },
  {
    label:"Aruba",
    "countryShortCode":"AW",
    "regions":[
      {
        label:"Aruba",
        "shortCode":"AW"
      }
    ]
  },
  {
    label:"Australia",
    "countryShortCode":"AU",
    "regions":[
      {
        label:"Australian Capital Territory",
        "shortCode":"ACT"
      },
      {
        label:"New South Wales",
        "shortCode":"NSW"
      },
      {
        label:"Northern Territory",
        "shortCode":"NT"
      },
      {
        label:"Queensland",
        "shortCode":"QLD"
      },
      {
        label:"South Australia",
        "shortCode":"SA"
      },
      {
        label:"Tasmania",
        "shortCode":"TAS"
      },
      {
        label:"Victoria",
        "shortCode":"VIC"
      },
      {
        label:"Western Australia",
        "shortCode":"WA"
      }
    ]
  },
  {
    label:"Austria",
    "countryShortCode":"AT",
    "regions":[
      {
        label:"Burgenland",
        "shortCode":"1"
      },
      {
        label:"Kärnten",
        "shortCode":"2"
      },
      {
        label:"Niederösterreich",
        "shortCode":"3"
      },
      {
        label:"Oberösterreich",
        "shortCode":"4"
      },
      {
        label:"Salzburg",
        "shortCode":"5"
      },
      {
        label:"Steiermark",
        "shortCode":"6"
      },
      {
        label:"Tirol",
        "shortCode":"7"
      },
      {
        label:"Vorarlberg",
        "shortCode":"8"
      },
      {
        label:"Wien",
        "shortCode":"9"
      }
    ]
  },
  {
    label:"Azerbaijan",
    "countryShortCode":"AZ",
    "regions":[
      {
        label:"Abşeron",
        "shortCode":"ABS"
      },
      {
        label:"Ağcabədi",
        "shortCode":"AGC"
      },
      {
        label:"Ağdam",
        "shortCode":"AGM"
      },
      {
        label:"Ağdaş",
        "shortCode":"AGS"
      },
      {
        label:"Ağstafa",
        "shortCode":"AGA"
      },
      {
        label:"Ağsu",
        "shortCode":"AGU"
      },
      {
        label:"Astara",
        "shortCode":"AST"
      },
      {
        label:"Bakı",
        "shortCode":"BAK"
      },
      {
        label:"Babək",
        "shortCode":"BAB"
      },
      {
        label:"Balakən",
        "shortCode":"BAL"
      },
      {
        label:"Bərdə",
        "shortCode":"BAR"
      },
      {
        label:"Beyləqan",
        "shortCode":"BEY"
      },
      {
        label:"Biləsuvar",
        "shortCode":"BIL"
      },
      {
        label:"Cəbrayıl",
        "shortCode":"CAB"
      },
      {
        label:"Cəlilabad",
        "shortCode":"CAL"
      },
      {
        label:"Culfa",
        "shortCode":"CUL"
      },
      {
        label:"Daşkəsən",
        "shortCode":"DAS"
      },
      {
        label:"Füzuli",
        "shortCode":"FUZ"
      },
      {
        label:"Gədəbəy",
        "shortCode":"GAD"
      },
      {
        label:"Goranboy",
        "shortCode":"GOR"
      },
      {
        label:"Göyçay",
        "shortCode":"GOY"
      },
      {
        label:"Göygöl",
        "shortCode":"GYG"
      },
      {
        label:"Hacıqabul",
        "shortCode":"HAC"
      },
      {
        label:"İmişli",
        "shortCode":"IMI"
      },
      {
        label:"İsmayıllı",
        "shortCode":"ISM"
      },
      {
        label:"Kəlbəcər",
        "shortCode":"KAL"
      },
      {
        label:"Kǝngǝrli",
        "shortCode":"KAN"
      },
      {
        label:"Kürdəmir",
        "shortCode":"KUR"
      },
      {
        label:"Laçın",
        "shortCode":"LAC"
      },
      {
        label:"Lənkəran",
        "shortCode":"LAN"
      },
      {
        label:"Lerik",
        "shortCode":"LER"
      },
      {
        label:"Masallı",
        "shortCode":"MAS"
      },
      {
        label:"Neftçala",
        "shortCode":"NEF"
      },
      {
        label:"Oğuz",
        "shortCode":"OGU"
      },
      {
        label:"Ordubad",
        "shortCode":"ORD"
      },
      {
        label:"Qəbələ",
        "shortCode":"QAB"
      },
      {
        label:"Qax",
        "shortCode":"QAX"
      },
      {
        label:"Qazax",
        "shortCode":"QAZ"
      },
      {
        label:"Qobustan",
        "shortCode":"QOB"
      },
      {
        label:"Quba",
        "shortCode":"QBA"
      },
      {
        label:"Qubadli",
        "shortCode":"QBI"
      },
      {
        label:"Qusar",
        "shortCode":"QUS"
      },
      {
        label:"Saatlı",
        "shortCode":"SAT"
      },
      {
        label:"Sabirabad",
        "shortCode":"SAB"
      },
      {
        label:"Şabran",
        "shortCode":"SBN"
      },
      {
        label:"Sədərək",
        "shortCode":"SAD"
      },
      {
        label:"Şahbuz",
        "shortCode":"SAH"
      },
      {
        label:"Şəki",
        "shortCode":"SAK"
      },
      {
        label:"Salyan",
        "shortCode":"SAL"
      },
      {
        label:"Şamaxı",
        "shortCode":"SMI"
      },
      {
        label:"Şəmkir",
        "shortCode":"SKR"
      },
      {
        label:"Samux",
        "shortCode":"SMX"
      },
      {
        label:"Şərur",
        "shortCode":"SAR"
      },
      {
        label:"Siyəzən",
        "shortCode":"SIY"
      },
      {
        label:"Şuşa",
        "shortCode":"SUS"
      },
      {
        label:"Tərtər",
        "shortCode":"TAR"
      },
      {
        label:"Tovuz",
        "shortCode":"TOV"
      },
      {
        label:"Ucar",
        "shortCode":"UCA"
      },
      {
        label:"Xaçmaz",
        "shortCode":"XAC"
      },
      {
        label:"Xızı",
        "shortCode":"XIZ"
      },
      {
        label:"Xocalı",
        "shortCode":"XCI"
      },
      {
        label:"Xocavənd",
        "shortCode":"XVD"
      },
      {
        label:"Yardımlı",
        "shortCode":"YAR"
      },
      {
        label:"Yevlax",
        "shortCode":"YEV"
      },
      {
        label:"Zəngilan",
        "shortCode":"ZAN"
      },
      {
        label:"Zaqatala",
        "shortCode":"ZAQ"
      },
      {
        label:"Zərdab",
        "shortCode":"ZAR"
      }
    ]
  },
  {
    label:"Bahamas",
    "countryShortCode":"BS",
    "regions":[
      {
        label:"Acklins Island",
        "shortCode":"01"
      },
      {
        label:"Berry Islands",
        "shortCode":"22"
      },
      {
        label:"Bimini",
        "shortCode":"02"
      },
      {
        label:"Black Point",
        "shortCode":"23"
      },
      {
        label:"Cat Island",
        "shortCode":"03"
      },
      {
        label:"Central Abaco",
        "shortCode":"24"
      },
      {
        label:"Crooked Island and Long Cay",
        "shortCode":"28"
      },
      {
        label:"East Grand Bahama",
        "shortCode":"29"
      },
      {
        label:"Exuma",
        "shortCode":"04"
      },
      {
        label:"Freeport",
        "shortCode":"05"
      },
      {
        label:"Fresh Creek",
        "shortCode":"06"
      },
      {
        label:"Governor's Harbour",
        "shortCode":"07"
      },
      {
        label:"Green Turtle Cay",
        "shortCode":"08"
      },
      {
        label:"Harbour Island",
        "shortCode":"09"
      },
      {
        label:"High Rock",
        "shortCode":"10"
      },
      {
        label:"Inagua",
        "shortCode":"11"
      },
      {
        label:"Kemps Bay",
        "shortCode":"12"
      },
      {
        label:"Long Island",
        "shortCode":"13"
      },
      {
        label:"Marsh Harbour",
        "shortCode":"14"
      },
      {
        label:"Mayaguana",
        "shortCode":"15"
      },
      {
        label:"Moore’s Island",
        "shortCode":"40"
      },
      {
        label:"New Providence",
        "shortCode":"16"
      },
      {
        label:"Nichollstown and Berry Islands",
        "shortCode":"17"
      },
      {
        label:"North Abaco",
        "shortCode":"42"
      },
      {
        label:"North Andros",
        "shortCode":"41"
      },
      {
        label:"North Eleuthera",
        "shortCode":"33"
      },
      {
        label:"Ragged Island",
        "shortCode":"18"
      },
      {
        label:"Rock Sound",
        "shortCode":"19"
      },
      {
        label:"San Salvador and Rum Cay",
        "shortCode":"20"
      },
      {
        label:"Sandy Point",
        "shortCode":"21"
      },
      {
        label:"South Abaco",
        "shortCode":"35"
      },
      {
        label:"South Andros",
        "shortCode":"36"
      },
      {
        label:"South Eleuthera",
        "shortCode":"37"
      },
      {
        label:"West Grand Bahama",
        "shortCode":"39"
      }
    ]
  },
  {
    label:"Bahrain",
    "countryShortCode":"BH",
    "regions":[
      {
        label:"Al Janūbīyah",
        "shortCode":"14"
      },
      {
        label:"Al Manāmah",
        "shortCode":"13"
      },
      {
        label:"Al Muḩarraq",
        "shortCode":"15"
      },
      {
        label:"Al Wusţá",
        "shortCode":"16"
      },
      {
        label:"Ash Shamālīyah",
        "shortCode":"17"
      }
    ]
  },
  {
    label:"Bangladesh",
    "countryShortCode":"BD",
    "regions":[
      {
        label:"Barisal",
        "shortCode":"A"
      },
      {
        label:"Chittagong",
        "shortCode":"B"
      },
      {
        label:"Dhaka",
        "shortCode":"C"
      },
      {
        label:"Khulna",
        "shortCode":"D"
      },
     {
	label:"Mymensingh",
        "shortCode":"M"
      },
      {
        label:"Rajshahi",
        "shortCode":"E"
      },
      {
        label:"Rangpur",
        "shortCode":"F"
      },
      {
        label:"Sylhet",
        "shortCode":"G"
      }
    ]
  },
  {
    label:"Barbados",
    "countryShortCode":"BB",
    "regions":[
      {
        label:"Christ Church",
        "shortCode":"01"
      },
      {
        label:"Saint Andrew",
        "shortCode":"02"
      },
      {
        label:"Saint George",
        "shortCode":"03"
      },
      {
        label:"Saint James",
        "shortCode":"04"
      },
      {
        label:"Saint John",
        "shortCode":"05"
      },
      {
        label:"Saint Joseph",
        "shortCode":"06"
      },
      {
        label:"Saint Lucy",
        "shortCode":"07"
      },
      {
        label:"Saint Michael",
        "shortCode":"08"
      },
      {
        label:"Saint Peter",
        "shortCode":"09"
      },
      {
        label:"Saint Philip",
        "shortCode":"10"
      },
      {
        label:"Saint Thomas",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Belarus",
    "countryShortCode":"BY",
    "regions":[
      {
        label:"Brest voblast",
        "shortCode":"BR"
      },
      {
        label:"Gorod Minsk",
        "shortCode":"HO"
      },
      {
        label:"Homiel voblast",
        "shortCode":"HO"
      },
      {
        label:"Hrodna voblast",
        "shortCode":"HR"
      },
      {
        label:"Mahilyow voblast",
        "shortCode":"MA"
      },
      {
        label:"Minsk voblast",
        "shortCode":"MI"
      },
      {
        label:"Vitsebsk voblast",
        "shortCode":"VI"
      }
    ]
  },
  {
    label:"Belgium",
    "countryShortCode":"BE",
    "regions":[
      {
        label:"Bruxelles-Capitale",
        "shortCode":"BRU"
      },
      {
        label:"Région Flamande",
        "shortCode":"VLG"
      },
      {
        label:"Région Wallonië",
        "shortCode":"WAL"
      }
    ]
  },
  {
    label:"Belize",
    "countryShortCode":"BZ",
    "regions":[
      {
        label:"Belize District",
        "shortCode":"BZ"
      },
      {
        label:"Cayo District",
        "shortCode":"CY"
      },
      {
        label:"Corozal District",
        "shortCode":"CZL"
      },
      {
        label:"Orange Walk District",
        "shortCode":"OW"
      },
      {
        label:"Stann Creek District",
        "shortCode":"SC"
      },
      {
        label:"Toledo District",
        "shortCode":"TOL"
      }
    ]
  },
  {
    label:"Benin",
    "countryShortCode":"BJ",
    "regions":[
      {
        label:"Alibori",
        "shortCode":"AL"
      },
      {
        label:"Atakora",
        "shortCode":"AK"
      },
      {
        label:"Atlantique",
        "shortCode":"AQ"
      },
      {
        label:"Borgou",
        "shortCode":"BO"
      },
      {
        label:"Collines Department",
        "shortCode":"CO"
      },
      {
        label:"Donga",
        "shortCode":"DO"
      },
      {
        label:"Kouffo",
        "shortCode":"KO"
      },
      {
        label:"Littoral Department",
        "shortCode":"LI"
      },
      {
        label:"Mono Department",
        "shortCode":"MO"
      },
      {
        label:"Ouémé",
        "shortCode":"OU"
      },
      {
        label:"Plateau",
        "shortCode":"PL"
      },
      {
        label:"Zou",
        "shortCode":"ZO"
      }
    ]
  },
  {
    label:"Bermuda",
    "countryShortCode":"BM",
    "regions":[
      {
        label:"City of Hamilton",
        "shortCode":"03"
      },
      {
        label:"Devonshire Parish",
        "shortCode":"01"
      },
      {
        label:"Hamilton Parish",
        "shortCode":"02"
      },
      {
        label:"Paget Parish",
        "shortCode":"04"
      },
      {
        label:"Pembroke Parish",
        "shortCode":"05"
      },
      {
        label:"Sandys Parish",
        "shortCode":"08"
      },
      {
        label:"Smith's Parish",
        "shortCode":"09"
      },
      {
        label:"Southampton Parish",
        "shortCode":"10"
      },
      {
        label:"St. George's Parish",
        "shortCode":"07"
      },
      {
        label:"Town of St. George",
        "shortCode":"06"
      },
      {
        label:"Warwick Parish",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Bhutan",
    "countryShortCode":"BT",
    "regions":[
      {
        label:"Bumthang",
        "shortCode":"33"
      },
      {
        label:"Chhukha",
        "shortCode":"12"
      },
      {
        label:"Dagana",
        "shortCode":"22"
      },
      {
        label:"Gasa",
        "shortCode":"GA"
      },
      {
        label:"Haa",
        "shortCode":"13"
      },
      {
        label:"Lhuntse",
        "shortCode":"44"
      },
      {
        label:"Mongar",
        "shortCode":"42"
      },
      {
        label:"Paro",
        "shortCode":"11"
      },
      {
        label:"Pemagatshel",
        "shortCode":"43"
      },
      {
        label:"Punakha",
        "shortCode":"23"
      },
      {
        label:"Samdrup Jongkhar",
        "shortCode":"45"
      },
      {
        label:"Samtse",
        "shortCode":"14"
      },
      {
        label:"Sarpang",
        "shortCode":"31"
      },
      {
        label:"Thimphu",
        "shortCode":"15"
      },
      {
        label:"Trashigang",
        "shortCode":"41"
      },
      {
        label:"Trashiyangtse",
        "shortCode":"TY"
      },
      {
        label:"Trongsa",
        "shortCode":"32"
      },
      {
        label:"Tsirang",
        "shortCode":"21"
      },
      {
        label:"Wangdue Phodrang",
        "shortCode":"24"
      },
      {
        label:"Zhemgang",
        "shortCode":"34"
      }
    ]
  },
  {
    label:"Bolivia",
    "countryShortCode":"BO",
    "regions":[
      {
        label:"Beni",
        "shortCode":"B"
      },
      {
        label:"Chuquisaca",
        "shortCode":"H"
      },
      {
        label:"Cochabamba",
        "shortCode":"C"
      },
      {
        label:"La Paz",
        "shortCode":"L"
      },
      {
        label:"Oruro",
        "shortCode":"O"
      },
      {
        label:"Pando",
        "shortCode":"N"
      },
      {
        label:"Potosí",
        "shortCode":"P"
      },
      {
        label:"Santa Cruz",
        "shortCode":"S"
      },
      {
        label:"Tarija",
        "shortCode":"T"
      }
    ]
  },
  {
    label:"Bonaire, Sint Eustatius and Saba",
    "countryShortCode":"BQ",
    "regions":[
      {
        label:"Bonaire",
        "shortCode":"BO"
      },
      {
        label:"Saba Isand",
        "shortCode":"SA"
      },
      {
        label:"Sint Eustatius",
        "shortCode":"SE"
      }
    ]
  },
  {
    label:"Bosnia and Herzegovina",
    "countryShortCode":"BA",
    "regions":[
      {
        label:"Brčko Distrikt",
        "shortCode":"BRC"
      },
      {
        label:"Federacija Bosne i Hercegovine",
        "shortCode":"BIH"
      },
      {
        label:"Republika Srpska",
        "shortCode":"SRP"
      }
    ]
  },
  {
    label:"Botswana",
    "countryShortCode":"BW",
    "regions":[
      {
        label:"Central",
        "shortCode":"CE"
      },
      {
        label:"Ghanzi",
        "shortCode":"GH"
      },
      {
        label:"Kgalagadi",
        "shortCode":"KG"
      },
      {
        label:"Kgatleng",
        "shortCode":"KL"
      },
      {
        label:"Kweneng",
        "shortCode":"KW"
      },
      {
        label:"North West",
        "shortCode":"NW"
      },
      {
        label:"North-East",
        "shortCode":"NE"
      },
      {
        label:"South East",
        "shortCode":"SE"
      },
      {
        label:"Southern",
        "shortCode":"SO"
      }
    ]
  },
  {
    label:"Bouvet Island",
    "countryShortCode":"BV",
    "regions":[
      {
        label:"Bouvet Island",
        "shortCode":"BV"
      }
    ]
  },
  {
    label:"Brazil",
    "countryShortCode":"BR",
    "regions":[
      {
        label:"Acre",
        "shortCode":"AC"
      },
      {
        label:"Alagoas",
        "shortCode":"AL"
      },
      {
        label:"Amapá",
        "shortCode":"AP"
      },
      {
        label:"Amazonas",
        "shortCode":"AM"
      },
      {
        label:"Bahia",
        "shortCode":"BA"
      },
      {
        label:"Ceará",
        "shortCode":"CE"
      },
      {
        label:"Distrito Federal",
        "shortCode":"DF"
      },
      {
        label:"Espírito Santo",
        "shortCode":"ES"
      },
      {
        label:"Goiás",
        "shortCode":"GO"
      },
      {
        label:"Maranhão",
        "shortCode":"MA"
      },
      {
        label:"Mato Grosso",
        "shortCode":"MT"
      },
      {
        label:"Mato Grosso do Sul",
        "shortCode":"MS"
      },
      {
        label:"Minas Gerais",
        "shortCode":"MG"
      },
      {
        label:"Pará",
        "shortCode":"PA"
      },
      {
        label:"Paraíba",
        "shortCode":"PB"
      },
      {
        label:"Paraná",
        "shortCode":"PR"
      },
      {
        label:"Pernambuco",
        "shortCode":"PE"
      },
      {
        label:"Piauí",
        "shortCode":"PI"
      },
      {
        label:"Rio de Janeiro",
        "shortCode":"RJ"
      },
      {
        label:"Rio Grande do Norte",
        "shortCode":"RN"
      },
      {
        label:"Rio Grande do Sul",
        "shortCode":"RS"
      },
      {
        label:"Rondônia",
        "shortCode":"RO"
      },
      {
        label:"Roraima",
        "shortCode":"RR"
      },
      {
        label:"Santa Catarina",
        "shortCode":"SC"
      },
      {
        label:"São Paulo",
        "shortCode":"SP"
      },
      {
        label:"Sergipe",
        "shortCode":"SE"
      },
      {
        label:"Tocantins",
        "shortCode":"TO"
      }
    ]
  },
  {
    label:"British Indian Ocean Territory",
    "countryShortCode":"IO",
    "regions":[
      {
        label:"British Indian Ocean Territory",
        "shortCode": "IO"
      }
    ]
  },
  {
    label:"Brunei Darussalam",
    "countryShortCode":"BN",
    "regions":[
      {
        label:"Belait",
        "shortCode":"BE"
      },
      {
        label:"Brunei Muara",
        "shortCode":"BM"
      },
      {
        label:"Temburong",
        "shortCode":"TE"
      },
      {
        label:"Tutong",
        "shortCode":"TU"
      }
    ]
  },
  {
    label:"Bulgaria",
    "countryShortCode":"BG",
    "regions":[
      {
        label:"Blagoevgrad",
        "shortCode":"01"
      },
      {
        label:"Burgas",
        "shortCode":"02"
      },
      {
        label:"Dobrich",
        "shortCode":"08"
      },
      {
        label:"Gabrovo",
        "shortCode":"07"
      },
      {
        label:"Jambol",
        "shortCode":"28"
      },
      {
        label:"Khaskovo",
        "shortCode":"26"
      },
      {
        label:"Kjustendil",
        "shortCode":"10"
      },
      {
        label:"Kurdzhali",
        "shortCode":"09"
      },
      {
        label:"Lovech",
        "shortCode":"11"
      },
      {
        label:"Montana",
        "shortCode":"12"
      },
      {
        label:"Pazardzhik",
        "shortCode":"13"
      },
      {
        label:"Pernik",
        "shortCode":"14"
      },
      {
        label:"Pleven",
        "shortCode":"15"
      },
      {
        label:"Plovdiv",
        "shortCode":"16"
      },
      {
        label:"Razgrad",
        "shortCode":"17"
      },
      {
        label:"Ruse",
        "shortCode":"18"
      },
      {
        label:"Shumen",
        "shortCode":"27"
      },
      {
        label:"Silistra",
        "shortCode":"19"
      },
      {
        label:"Sliven",
        "shortCode":"20"
      },
      {
        label:"Smoljan",
        "shortCode":"21"
      },
      {
        label:"Sofija",
        "shortCode":"23"
      },
      {
        label:"Sofija-Grad",
        "shortCode":"22"
      },
      {
        label:"Stara Zagora",
        "shortCode":"24"
      },
      {
        label:"Turgovishhe",
        "shortCode":"25"
      },
      {
        label:"Varna",
        "shortCode":"03"
      },
      {
        label:"Veliko Turnovo",
        "shortCode":"04"
      },
      {
        label:"Vidin",
        "shortCode":"05"
      },
      {
        label:"Vraca",
        "shortCode":"06"
      }
    ]
  },
  {
    label:"Burkina Faso",
    "countryShortCode":"BF",
    "regions":[
      {
        label:"Balé",
        "shortCode":"BAL"
      },
      {
        label:"Bam/Lake Bam",
        "shortCode":"BAM"
      },
      {
        label:"Banwa Province",
        "shortCode":"BAN"
      },
      {
        label:"Bazèga",
        "shortCode":"BAZ"
      },
      {
        label:"Bougouriba",
        "shortCode":"BGR"
      },
      {
        label:"Boulgou Province",
        "shortCode":"BLG"
      },
      {
        label:"Boulkiemdé",
        "shortCode":"BLK"
      },
      {
        label:"Comoé/Komoe",
        "shortCode":"COM"
      },
      {
        label:"Ganzourgou Province",
        "shortCode":"GAN"
      },
      {
        label:"Gnagna",
        "shortCode":"GNA"
      },
      {
        label:"Gourma Province",
        "shortCode":"GOU"
      },
      {
        label:"Houet",
        "shortCode":"HOU"
      },
      {
        label:"Ioba",
        "shortCode":"IOB"
      },
      {
        label:"Kadiogo",
        "shortCode":"KAD"
      },
      {
        label:"Kénédougou",
        "shortCode":"KEN"
      },
      {
        label:"Komondjari",
        "shortCode":"KMD"
      },
      {
        label:"Kompienga",
        "shortCode":"KMP"
      },
      {
        label:"Kossi Province",
        "shortCode":"KOS"
      },
      {
        label:"Koulpélogo",
        "shortCode":"KOP"
      },
      {
        label:"Kouritenga",
        "shortCode":"KOT"
      },
      {
        label:"Kourwéogo",
        "shortCode":"KOW"
      },
      {
        label:"Léraba",
        "shortCode":"LER"
      },
      {
        label:"Loroum",
        "shortCode":"LOR"
      },
      {
        label:"Mouhoun",
        "shortCode":"MOU"
      },
      {
        label:"Namentenga",
        "shortCode":"NAM"
      },
      {
        label:"Naouri/Nahouri",
        "shortCode":"NAO"
      },
      {
        label:"Nayala",
        "shortCode":"NAY"
      },
      {
        label:"Noumbiel",
        "shortCode":"NOU"
      },
      {
        label:"Oubritenga",
        "shortCode":"OUB"
      },
      {
        label:"Oudalan",
        "shortCode":"OUD"
      },
      {
        label:"Passoré",
        "shortCode":"PAS"
      },
      {
        label:"Poni",
        "shortCode":"PON"
      },
      {
        label:"Sanguié",
        "shortCode":"SNG"
      },
      {
        label:"Sanmatenga",
        "shortCode":"SMT"
      },
      {
        label:"Séno",
        "shortCode":"SEN"
      },
      {
        label:"Sissili",
        "shortCode":"SIS"
      },
      {
        label:"Soum",
        "shortCode":"SOM"
      },
      {
        label:"Sourou",
        "shortCode":"SOR"
      },
      {
        label:"Tapoa",
        "shortCode":"TAP"
      },
      {
        label:"Tui/Tuy",
        "shortCode":"TUI"
      },
      {
        label:"Yagha",
        "shortCode":"YAG"
      },
      {
        label:"Yatenga",
        "shortCode":"YAT"
      },
      {
        label:"Ziro",
        "shortCode":"ZIR"
      },
      {
        label:"Zondoma",
        "shortCode":"ZON"
      },
      {
        label:"Zoundwéogo",
        "shortCode":"ZOU"
      }
    ]
  },
  {
    label:"Burundi",
    "countryShortCode":"BI",
    "regions":[
      {
        label:"Bubanza",
        "shortCode":"BB"
      },
      {
        label:"Bujumbura Mairie",
        "shortCode":"BM"
      },
      {
        label:"Bujumbura Rural",
        "shortCode":"BL"
      },
      {
        label:"Bururi",
        "shortCode":"BR"
      },
      {
        label:"Cankuzo",
        "shortCode":"CA"
      },
      {
        label:"Cibitoke",
        "shortCode":"CI"
      },
      {
        label:"Gitega",
        "shortCode":"GI"
      },
      {
        label:"Karuzi",
        "shortCode":"KR"
      },
      {
        label:"Kayanza",
        "shortCode":"KY"
      },
      {
        label:"Kirundo",
        "shortCode":"KI"
      },
      {
        label:"Makamba",
        "shortCode":"MA"
      },
      {
        label:"Muramvya",
        "shortCode":"MU"
      },
      {
        label:"Muyinga",
        "shortCode":"MY"
      },
      {
        label:"Mwaro",
        "shortCode":"MW"
      },
      {
        label:"Ngozi",
        "shortCode":"NG"
      },
      {
        label:"Rutana",
        "shortCode":"RT"
      },
      {
        label:"Ruyigi",
        "shortCode":"RY"
      }
    ]
  },
  {
    label:"Cambodia",
    "countryShortCode":"KH",
    "regions":[
      {
        label:"Baat Dambang",
        "shortCode": "2"
      },
      {
        label:"Banteay Mean Chey",
        "shortCode": "1"
      },
      {
        label:"Kampong Chaam",
        "shortCode": "3"
      },
      {
        label:"Kampong Chhnang",
        "shortCode": "4"
      },
      {
        label:"Kampong Spueu",
        "shortCode": "5"
      },
      {
        label:"Kampong Thum",
        "shortCode": "6"
      },
      {
        label:"Kampot",
        "shortCode": "7"
      },
      {
        label:"Kandaal",
        "shortCode": "8"
      },
      {
        label:"Kaoh Kong",
        "shortCode": "9"
      },
      {
        label:"Kracheh",
        "shortCode": "10"
      },
      {
        label:"Krong Kaeb",
        "shortCode": "23"
      },
      {
        label:"Krong Pailin",
        "shortCode": "24"
      },
      {
        label:"Krong Preah Sihanouk",
        "shortCode": "18"
      },
      {
        label:"Mondol Kiri",
        "shortCode": "11"
      },
      {
        label:"Otdar Mean Chey",
        "shortCode": "22"
      },
      {
        label:"Phnom Penh",
        "shortCode": "12"
      },
      {
        label:"Pousaat",
        "shortCode": "15"
      },
      {
        label:"Preah Vihear",
        "shortCode": "13"
      },
      {
        label:"Prey Veaeng",
        "shortCode": "14"
      },
      {
        label:"Rotanah Kiri",
        "shortCode": "16"
      },
      {
        label:"Siem Reab",
        "shortCode": "17"
      },
      {
        label:"Stueng Treng",
        "shortCode": "19"
      },
      {
        label:"Svaay Rieng",
        "shortCode": "20"
      },
      {
        label:"Taakaev",
        "shortCode": "21"
      },
      {
        label:"Tbong Khmum",
        "shortCode": "25"
      }
    ]
  },
  {
    label:"Cameroon",
    "countryShortCode":"CM",
    "regions":[
      {
        label:"Adamaoua",
        "shortCode":"AD"
      },
      {
        label:"Centre",
        "shortCode":"CE"
      },
      {
        label:"Est",
        "shortCode":"ES"
      },
      {
        label:"Extrême-Nord",
        "shortCode":"EN"
      },
      {
        label:"Littoral",
        "shortCode":"LT"
      },
      {
        label:"Nord",
        "shortCode":"NO"
      },
      {
        label:"Nord-Ouest",
        "shortCode":"NW"
      },
      {
        label:"Ouest",
        "shortCode":"OU"
      },
      {
        label:"Sud",
        "shortCode":"SU"
      },
      {
        label:"Sud-Ouest",
        "shortCode":"SW"
      }
    ]
  },
  {
    label:"Canada",
    "countryShortCode":"CA",
    "regions":[
      {
        label:"Alberta",
        "shortCode":"AB"
      },
      {
        label:"British Columbia",
        "shortCode":"BC"
      },
      {
        label:"Manitoba",
        "shortCode":"MB"
      },
      {
        label:"New Brunswick",
        "shortCode":"NB"
      },
      {
        label:"Newfoundland and Labrador",
        "shortCode":"NL"
      },
      {
        label:"Northwest Territories",
        "shortCode":"NT"
      },
      {
        label:"Nova Scotia",
        "shortCode":"NS"
      },
      {
        label:"Nunavut",
        "shortCode":"NU"
      },
      {
        label:"Ontario",
        "shortCode":"ON"
      },
      {
        label:"Prince Edward Island",
        "shortCode":"PE"
      },
      {
        label:"Quebec",
        "shortCode":"QC"
      },
      {
        label:"Saskatchewan",
        "shortCode":"SK"
      },
      {
        label:"Yukon",
        "shortCode":"YT"
      }
    ]
  },
  {
    label:"Cape Verde",
    "countryShortCode":"CV",
    "regions":[
      {
        label:"Boa Vista",
        "shortCode":"BV"
      },
      {
        label:"Brava",
        "shortCode":"BR"
      },
      {
        label:"Calheta de São Miguel",
        "shortCode":"CS"
      },
      {
        label:"Maio",
        "shortCode":"MA"
      },
      {
        label:"Mosteiros",
        "shortCode":"MO"
      },
      {
        label:"Paúl",
        "shortCode":"PA"
      },
      {
        label:"Porto Novo",
        "shortCode":"PN"
      },
      {
        label:"Praia",
        "shortCode":"PR"
      },
      {
        label:"Ribeira Brava",
        "shortCode":"RB"
      },
      {
        label:"Ribeira Grande",
        "shortCode":"RG"
      },
      {
        label:"Sal",
        "shortCode":"SL"
      },
      {
        label:"Santa Catarina",
        "shortCode":"CA"
      },
      {
        label:"Santa Cruz",
        "shortCode":"CR"
      },
      {
        label:"São Domingos",
        "shortCode":"SD"
      },
      {
        label:"São Filipe",
        "shortCode":"SF"
      },
      {
        label:"São Nicolau",
        "shortCode":"SN"
      },
      {
        label:"São Vicente",
        "shortCode":"SV"
      },
      {
        label:"Tarrafal",
        "shortCode":"TA"
      },
      {
        label:"Tarrafal de São Nicolau",
        "shortCode":"TS"
      }
    ]
  },
  {
    label:"Cayman Islands",
    "countryShortCode":"KY",
    "regions":[
      {
        label:"Creek"
      },
      {
        label:"Eastern"
      },
      {
        label:"Midland"
      },
      {
        label:"South Town"
      },
      {
        label:"Spot Bay"
      },
      {
        label:"Stake Bay"
      },
      {
        label:"West End"
      },
      {
        label:"Western"
      }
    ]
  },
  {
    label:"Central African Republic",
    "countryShortCode":"CF",
    "regions":[
      {
        label:"Bamingui-Bangoran",
        "shortCode":"BB"
      },
      {
        label:"Bangui",
        "shortCode":"BGF"
      },
      {
        label:"Basse-Kotto",
        "shortCode":"BK"
      },
      {
        label:"Haute-Kotto",
        "shortCode":"HK"
      },
      {
        label:"Haut-Mbomou",
        "shortCode":"HM"
      },
      {
        label:"Kémo",
        "shortCode":"KG"
      },
      {
        label:"Lobaye",
        "shortCode":"LB"
      },
      {
        label:"Mambéré-Kadéï",
        "shortCode":"HS"
      },
      {
        label:"Mbomou",
        "shortCode":"MB"
      },
      {
        label:"Nana-Grebizi",
        "shortCode":"10"
      },
      {
        label:"Nana-Mambéré",
        "shortCode":"NM"
      },
      {
        label:"Ombella-M'Poko",
        "shortCode":"MP"
      },
      {
        label:"Ouaka",
        "shortCode":"UK"
      },
      {
        label:"Ouham",
        "shortCode":"AC"
      },
      {
        label:"Ouham Péndé",
        "shortCode":"OP"
      },
      {
        label:"Sangha-Mbaéré",
        "shortCode":"SE"
      },
      {
        label:"Vakaga",
        "shortCode":"VK"
      }
    ]
  },
  {
    label:"Chad",
    "countryShortCode":"TD",
    "regions":[
      {
        label:"Bahr el Ghazal",
        "shortCode": "BG"
      },
      {
        label:"Batha",
        "shortCode": "BA"
      },
      {
        label:"Borkou",
        "shortCode": "BO"
      },
      {
        label:"Chari-Baguirmi",
        "shortCode": "CB"
      },
      {
        label:"Ennedi-Est",
        "shortCode": "EE"
      },
      {
        label:"Ennedi-Ouest",
        "shortCode": "EO"
      },
      {
        label:"Guéra",
        "shortCode": "GR"
      },
      {
        label:"Hadjer Lamis",
        "shortCode": "HL"
      },
      {
        label:"Kanem",
        "shortCode": "KA"
      },
      {
        label:"Lac",
        "shortCode": "LC"
      },
      {
        label:"Logone Occidental",
        "shortCode": "LO"
      },
      {
        label:"Logone Oriental",
        "shortCode": "LR"
      },
      {
        label:"Mondoul",
        "shortCode": "MA"
      },
      {
        label:"Mayo-Kébbi-Est",
        "shortCode": "ME"
      },
      {
        label:"Moyen-Chari",
        "shortCode": "MC"
      },
      {
        label:"Ouaddai",
        "shortCode": "OD"
      },
      {
        label:"Salamat",
        "shortCode": "SA"
      },
      {
        label:"Sila",
        "shortCode": "SI"
      },
      {
        label:"Tandjilé",
        "shortCode": "TA"
      },
      {
        label:"Tibesti",
        "shortCode": "TI"
      },
      {
        label:"Ville de Ndjamena",
        "shortCode": "ND"
      },
      {
        label:"Wadi Fira",
        "shortCode": "WF"
      }
    ]
  },
  {
    label:"Chile",
    "countryShortCode":"CL",
    "regions":[
      {
        label:"Aisén del General Carlos Ibáñez del Campo",
        "shortCode":"AI"
      },
      {
        label:"Antofagasta",
        "shortCode":"AN"
      },
      {
        label:"Araucanía",
        "shortCode":"AR"
      },
      {
        label:"Arica y Parinacota",
        "shortCode":"AP"
      },
      {
        label:"Atacama",
        "shortCode":"AT"
      },
      {
        label:"Bío-Bío",
        "shortCode":"BI"
      },
      {
        label:"Coquimbo",
        "shortCode":"CO"
      },
      {
        label:"Libertador General Bernardo O'Higgins",
        "shortCode":"LI"
      },
      {
        label:"Los Lagos",
        "shortCode":"LL"
      },
      {
        label:"Los Ríos",
        "shortCode":"LR"
      },
      {
        label:"Magallanes y Antartica Chilena",
        "shortCode":"MA"
      },
      {
        label:"Marga-Marga",
        "shortCode":""
      },
      {
        label:"Maule",
        "shortCode":"ML"
      },
      {
        label:"Región Metropolitana de Santiago",
        "shortCode":"RM"
      },
      {
        label:"Tarapacá",
        "shortCode":"TA"
      },
      {
        label:"Valparaíso",
        "shortCode":"VS"
      }
    ]
  },
  {
    label:"China",
    "countryShortCode":"CN",
    "regions":[
      {
        label:"Anhui",
        "shortCode":"34"
      },
      {
        label:"Beijing",
        "shortCode":"11"
      },
      {
        label:"Chongqing",
        "shortCode":"50"
      },
      {
        label:"Fujian",
        "shortCode":"35"
      },
      {
        label:"Gansu",
        "shortCode":"62"
      },
      {
        label:"Guangdong",
        "shortCode":"44"
      },
      {
        label:"Guangxi",
        "shortCode":"45"
      },
      {
        label:"Guizhou",
        "shortCode":"52"
      },
      {
        label:"Hainan",
        "shortCode":"46"
      },
      {
        label:"Hebei",
        "shortCode":"13"
      },
      {
        label:"Heilongjiang",
        "shortCode":"23"
      },
      {
        label:"Henan",
        "shortCode":"41"
      },
      {
        label:"Hong Kong",
        "shortCode":"91"
      },
      {
        label:"Hubei",
        "shortCode":"42"
      },
      {
        label:"Hunan",
        "shortCode":"43"
      },
      {
        label:"Inner Mongolia",
        "shortCode":"15"
      },
      {
        label:"Jiangsu",
        "shortCode":"32"
      },
      {
        label:"Jiangxi",
        "shortCode":"36"
      },
      {
        label:"Jilin",
        "shortCode":"22"
      },
      {
        label:"Liaoning",
        "shortCode":"21"
      },
      {
        label:"Macau",
        "shortCode":"92"
      },
      {
        label:"Ningxia",
        "shortCode":"64"
      },
      {
        label:"Qinghai",
        "shortCode":"63"
      },
      {
        label:"Shaanxi",
        "shortCode":"61"
      },
      {
        label:"Shandong",
        "shortCode":"37"
      },
      {
        label:"Shanghai",
        "shortCode":"31"
      },
      {
        label:"Shanxi",
        "shortCode":"14"
      },
      {
        label:"Sichuan",
        "shortCode":"51"
      },
      {
        label:"Tianjin",
        "shortCode":"12"
      },
      {
        label:"Tibet",
        "shortCode":"54"
      },
      {
        label:"Xinjiang",
        "shortCode":"65"
      },
      {
        label:"Yunnan",
        "shortCode":"53"
      },
      {
        label:"Zhejiang",
        "shortCode":"33"
      }
    ]
  },
  {
    label:"Christmas Island",
    "countryShortCode":"CX",
    "regions":[
      {
        label:"Christmas Island",
        "shortCode":"CX"
      }
    ]
  },
  {
    label:"Cocos (Keeling) Islands",
    "countryShortCode":"CC",
    "regions":[
      {
        label:"Direction Island",
        "shortCode":"DI"
      },
      {
        label:"Home Island",
        "shortCode":"HM"
      },
      {
        label:"Horsburgh Island",
        "shortCode":"HR"
      },
      {
        label:"North Keeling Island",
        "shortCode":"NK"
      },
      {
        label:"South Island",
        "shortCode":"SI"
      },
      {
        label:"West Island",
        "shortCode":"WI"
      }
    ]
  },
  {
    label:"Colombia",
    "countryShortCode":"CO",
    "regions":[
      {
        label:"Amazonas",
        "shortCode":"AMA"
      },
      {
        label:"Antioquia",
        "shortCode":"ANT"
      },
      {
        label:"Arauca",
        "shortCode":"ARA"
      },
      {
        label:"Archipiélago de San Andrés",
        "shortCode":"SAP"
      },
      {
        label:"Atlántico",
        "shortCode":"ATL"
      },
      {
        label:"Bogotá D.C.",
        "shortCode":"DC"
      },
      {
        label:"Bolívar",
        "shortCode":"BOL"
      },
      {
        label:"Boyacá",
        "shortCode":"BOY"
      },
      {
        label:"Caldas",
        "shortCode":"CAL"
      },
      {
        label:"Caquetá",
        "shortCode":"CAQ"
      },
      {
        label:"Casanare",
        "shortCode":"CAS"
      },
      {
        label:"Cauca",
        "shortCode":"CAU"
      },
      {
        label:"Cesar",
        "shortCode":"CES"
      },
      {
        label:"Chocó",
        "shortCode":"CHO"
      },
      {
        label:"Córdoba",
        "shortCode":"COR"
      },
      {
        label:"Cundinamarca",
        "shortCode":"CUN"
      },
      {
        label:"Guainía",
        "shortCode":"GUA"
      },
      {
        label:"Guaviare",
        "shortCode":"GUV"
      },
      {
        label:"Huila",
        "shortCode":"HUI"
      },
      {
        label:"La Guajira",
        "shortCode":"LAG"
      },
      {
        label:"Magdalena",
        "shortCode":"MAG"
      },
      {
        label:"Meta",
        "shortCode":"MET"
      },
      {
        label:"Nariño",
        "shortCode":"NAR"
      },
      {
        label:"Norte de Santander",
        "shortCode":"NSA"
      },
      {
        label:"Putumayo",
        "shortCode":"PUT"
      },
      {
        label:"Quindío",
        "shortCode":"QUI"
      },
      {
        label:"Risaralda",
        "shortCode":"RIS"
      },
      {
        label:"Santander",
        "shortCode":"SAN"
      },
      {
        label:"Sucre",
        "shortCode":"SUC"
      },
      {
        label:"Tolima",
        "shortCode":"TOL"
      },
      {
        label:"Valle del Cauca",
        "shortCode":"VAC"
      },
      {
        label:"Vaupés",
        "shortCode":"VAU"
      },
      {
        label:"Vichada",
        "shortCode":"VID"
      }
    ]
  },
  {
    label:"Comoros",
    "countryShortCode":"KM",
    "regions":[
      {
        label:"Andjazîdja",
        "shortCode":"G"
      },
      {
        label:"Andjouân",
        "shortCode":"A"
      },
      {
        label:"Moûhîlî",
        "shortCode":"M"
      }
    ]
  },
  {
    label:"Congo, Republic of the (Brazzaville)",
    "countryShortCode":"CG",
    "regions":[
      {
        label:"Bouenza",
        "shortCode":"11"
      },
      {
        label:"Brazzaville",
        "shortCode":"BZV"
      },
      {
        label:"Cuvette",
        "shortCode":"8"
      },
      {
        label:"Cuvette-Ouest",
        "shortCode":"15"
      },
      {
        label:"Kouilou",
        "shortCode":"5"
      },
      {
        label:"Lékoumou",
        "shortCode":"2"
      },
      {
        label:"Likouala",
        "shortCode":"7"
      },
      {
        label:"Niari",
        "shortCode":"9"
      },
      {
        label:"Plateaux",
        "shortCode":"14"
      },
      {
        label:"Pointe-Noire",
        "shortCode":"16"
      },
      {
        label:"Pool",
        "shortCode":"12"
      },
      {
        label:"Sangha",
        "shortCode":"13"
      }
    ]
  },
  {
    label:"Congo, the Democratic Republic of the (Kinshasa)",
    "countryShortCode":"CD",
    "regions":[
      {
        label:"Bandundu",
        "shortCode":"BN"
      },
      {
        label:"Bas-Congo",
        "shortCode":"BC"
      },
      {
        label:"Équateur",
        "shortCode":"EQ"
      },
      {
        label:"Kasaï-Occidental",
        "shortCode":"KE"
      },
      {
        label:"Kasaï-Oriental",
        "shortCode":"KW"
      },
      {
        label:"Katanga",
        "shortCode":"KA"
      },
      {
        label:"Kinshasa",
        "shortCode":"KN"
      },
      {
        label:"Maniema",
        "shortCode":"MA"
      },
      {
        label:"Nord-Kivu",
        "shortCode":"NK"
      },
      {
        label:"Orientale",
        "shortCode":"OR"
      },
      {
        label:"Sud-Kivu",
        "shortCode":"SK"
      }
    ]
  },
  {
    label:"Cook Islands",
    "countryShortCode":"CK",
    "regions":[
      {
        label:"Aitutaki"
      },
      {
        label:"Atiu"
      },
      {
        label:"Avarua"
      },
      {
        label:"Mangaia"
      },
      {
        label:"Manihiki"
      },
      {
        label:"Ma'uke"
      },
      {
        label:"Mitiaro"
      },
      {
        label:"Nassau"
      },
      {
        label:"Palmerston"
      },
      {
        label:"Penrhyn"
      },
      {
        label:"Pukapuka"
      },
      {
        label:"Rakahanga"
      }
    ]
  },
  {
    label:"Costa Rica",
    "countryShortCode":"CR",
    "regions":[
      {
        label:"Alajuela",
        "shortCode":"2"
      },
      {
        label:"Cartago",
        "shortCode":"3"
      },
      {
        label:"Guanacaste",
        "shortCode":"5"
      },
      {
        label:"Heredia",
        "shortCode":"4"
      },
      {
        label:"Limón",
        "shortCode":"7"
      },
      {
        label:"Puntarenas",
        "shortCode":"6"
      },
      {
        label:"San José",
        "shortCode":"1"
      }
    ]
  },
  {
    label:"Côte d'Ivoire, Republic of",
    "countryShortCode":"CI",
    "regions":[
      {
        label:"Agnéby",
        "shortCode":"16"
      },
      {
        label:"Bafing",
        "shortCode":"17"
      },
      {
        label:"Bas-Sassandra",
        "shortCode":"09"
      },
      {
        label:"Denguélé",
        "shortCode":"10"
      },
      {
        label:"Dix-Huit Montagnes",
        "shortCode":"06"
      },
      {
        label:"Fromager",
        "shortCode":"18"
      },
      {
        label:"Haut-Sassandra",
        "shortCode":"02"
      },
      {
        label:"Lacs",
        "shortCode":"07"
      },
      {
        label:"Lagunes",
        "shortCode":"01"
      },
      {
        label:"Marahoué",
        "shortCode":"12"
      },
      {
        label:"Moyen-Cavally",
        "shortCode":"19"
      },
      {
        label:"Moyen-Comoé",
        "shortCode":"05"
      },
      {
        label:"N'zi-Comoé",
        "shortCode":"11"
      },
      {
        label:"Savanes",
        "shortCode":"03"
      },
      {
        label:"Sud-Bandama",
        "shortCode":"15"
      },
      {
        label:"Sud-Comoé",
        "shortCode":"13"
      },
      {
        label:"Vallée du Bandama",
        "shortCode":"04"
      },
      {
        label:"Worodougou",
        "shortCode":"14"
      },
      {
        label:"Zanzan",
        "shortCode":"08"
      }
    ]
  },
  {
    label:"Croatia",
    "countryShortCode":"HR",
    "regions":[
      {
        label:"Bjelovarsko-Bilogorska Županija",
        "shortCode": "07"
      },
      {
        label:"Brodsko-Posavska Županija",
        "shortCode": "12"
      },
      {
        label:"Dubrovačko-Neretvanska Županija",
        "shortCode": "19"
      },
      {
        label:"Grad Zagreb",
        "shortCode": "21"
      },
      {
        label:"Istarska Županija",
        "shortCode": "18"
      },
      {
        label:"Karlovačka Županija",
        "shortCode": "04"
      },
      {
        label:"Koprivničko-Krizevačka Županija",
        "shortCode": "06"
      },
      {
        label:"Krapinsko-Zagorska Županija",
        "shortCode": "02"
      },
      {
        label:"Ličko-Senjska Županija",
        "shortCode": "09"
      },
      {
        label:"Međimurska Županija",
        "shortCode": "20"
      },
      {
        label:"Osječko-Baranjska Županija",
        "shortCode": "14"
      },
      {
        label:"Požeško-Slavonska Županija",
        "shortCode": "11"
      },
      {
        label:"Primorsko-Goranska Županija",
        "shortCode": "08"
      },
      {
        label:"Sisačko-Moslavačka Županija",
        "shortCode": "03"
      },
      {
        label:"Splitsko-Dalmatinska Županija",
        "shortCode": "17"
      },
      {
        label:"Sibensko-Kninska Županija",
        "shortCode": "15"
      },
      {
        label:"Varaždinska Županija",
        "shortCode": "05"
      },
      {
        label:"Virovitičko-Podravska Županija",
        "shortCode": "10"
      },
      {
        label:"Vukovarsko-Srijemska Županija",
        "shortCode": "16"
      },
      {
        label:"Zadarska Županija",
        "shortCode": "13"
      },
      {
        label:"Zagrebacka Zupanija",
        "shortCode": "01"
      }
    ]
  },
  {
    label:"Cuba",
    "countryShortCode":"CU",
    "regions":[
      {
        label:"Artemisa",
        "shortCode":"15"
      },
      {
        label:"Camagüey",
        "shortCode":"09"
      },
      {
        label:"Ciego de Ávila",
        "shortCode":"08"
      },
      {
        label:"Cienfuegos",
        "shortCode":"06"
      },
      {
        label:"Granma",
        "shortCode":"12"
      },
      {
        label:"Guantánamo",
        "shortCode":"14"
      },
      {
        label:"Holguín",
        "shortCode":"11"
      },
      {
        label:"Isla de la Juventud",
        "shortCode":"99"
      },
      {
        label:"La Habana",
        "shortCode":"03"
      },
      {
        label:"Las Tunas",
        "shortCode":"10"
      },
      {
        label:"Matanzas",
        "shortCode":"04"
      },
      {
        label:"Mayabeque",
        "shortCode":"16"
      },
      {
        label:"Pinar del Río",
        "shortCode":"01"
      },
      {
        label:"Sancti Spíritus",
        "shortCode":"07"
      },
      {
        label:"Santiago de Cuba",
        "shortCode":"13"
      },
      {
        label:"Villa Clara",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Curaçao",
    "countryShortCode":"CW",
    "regions":[
      {
        label:"Curaçao",
        "shortCode":"CW"
      }
    ]
  },
  {
    label:"Cyprus",
    "countryShortCode":"CY",
    "regions":[
      {
        label:"Ammochostos",
        "shortCode":"04"
      },
      {
        label:"Keryneia",
        "shortCode":"05"
      },
      {
        label:"Larnaka",
        "shortCode":"03"
      },
      {
        label:"Lefkosia",
        "shortCode":"01"
      },
      {
        label:"Lemesos",
        "shortCode":"02"
      },
      {
        label:"Pafos",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Czech Republic",
    "countryShortCode":"CZ",
    "regions":[
      {
        label:"Hlavní město Praha",
        "shortCode":"PR"
      },
      {
        label:"Jihočeský kraj",
        "shortCode":"JC"
      },
      {
        label:"Jihomoravský kraj",
        "shortCode":"JM"
      },
      {
        label:"Karlovarský kraj",
        "shortCode":"KA"
      },
      {
        label:"Královéhradecký kraj",
        "shortCode":"KR"
      },
      {
        label:"Liberecký kraj",
        "shortCode":"LI"
      },
      {
        label:"Moravskoslezský kraj",
        "shortCode":"MO"
      },
      {
        label:"Olomoucký kraj",
        "shortCode":"OL"
      },
      {
        label:"Pardubický kraj",
        "shortCode":"PA"
      },
      {
        label:"Plzeňský kraj",
        "shortCode":"PL"
      },
      {
        label:"Středočeský kraj",
        "shortCode":"ST"
      },
      {
        label:"Ústecký kraj",
        "shortCode":"US"
      },
      {
        label:"Vysočina",
        "shortCode":"VY"
      },
      {
        label:"Zlínský kraj",
        "shortCode":"ZL"
      }
    ]
  },
  {
    label:"Denmark",
    "countryShortCode":"DK",
    "regions":[
      {
        label:"Hovedstaden",
        "shortCode":"84"
      },
      {
        label:"Kujalleq",
        "shortCode":"GL-KU"
      },
      {
        label:"Midtjylland",
        "shortCode":"82"
      },
      {
        label:"Norderøerne",
        "shortCode":"FO-01"
      },
      {
        label:"Nordjylland",
        "shortCode":"81"
      },
      {
        label:"Østerø",
        "shortCode":"FO-06"
      },
      {
        label:"Qaasuitsup",
        "shortCode":"GL-QA"
      },
      {
        label:"Qeqqata",
        "shortCode":"GL-QE"
      },
      {
        label:"Sandø",
        "shortCode":"FO-02"
      },
      {
        label:"Sermersooq",
        "shortCode":"GL-SM"
      },
      {
        label:"Sjælland",
        "shortCode":"85"
      },
      {
        label:"Strømø",
        "shortCode":"FO-03"
      },
      {
        label:"Suderø",
        "shortCode":"FO-04"
      },
      {
        label:"Syddanmark",
        "shortCode":"83"
      },
      {
        label:"Vågø",
        "shortCode":"FO-05"
      }
    ]
  },
  {
    label:"Djibouti",
    "countryShortCode":"DJ",
    "regions":[
      {
        label:"Ali Sabieh",
        "shortCode":"AS"
      },
      {
        label:"Arta",
        "shortCode":"AR"
      },
      {
        label:"Dikhil",
        "shortCode":"DI"
      },
      {
        label:"Obock",
        "shortCode":"OB"
      },
      {
        label:"Tadjourah",
        "shortCode":"TA"
      }
    ]
  },
  {
    label:"Dominica",
    "countryShortCode":"DM",
    "regions":[
      {
        label:"Saint Andrew Parish",
        "shortCode":"02"
      },
      {
        label:"Saint David Parish",
        "shortCode":"03"
      },
      {
        label:"Saint George Parish",
        "shortCode":"04"
      },
      {
        label:"Saint John Parish",
        "shortCode":"05"
      },
      {
        label:"Saint Joseph Parish",
        "shortCode":"06"
      },
      {
        label:"Saint Luke Parish",
        "shortCode":"07"
      },
      {
        label:"Saint Mark Parish",
        "shortCode":"08"
      },
      {
        label:"Saint Patrick Parish",
        "shortCode":"09"
      },
      {
        label:"Saint Paul Parish",
        "shortCode":"10"
      },
      {
        label:"Saint Peter Parish",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Dominican Republic",
    "countryShortCode":"DO",
    "regions":[
      {
        label:"Cibao Central",
        "shortCode":"02"
      },
      {
        label:"Del Valle",
        "shortCode":"37"
      },
      {
        label:"Distrito Nacional",
        "shortCode":"01"
      },
      {
        label:"Enriquillo",
        "shortCode":"38"
      },
      {
        label:"Norcentral",
        "shortCode":"04"
      },
      {
        label:"Nordeste",
        "shortCode":"34"
      },
      {
        label:"Noroeste",
        "shortCode":"34"
      },
      {
        label:"Norte",
        "shortCode":"35"
      },
      {
        label:"Valdesia",
        "shortCode":"42"
      }
    ]
  },
  {
    label:"Ecuador",
    "countryShortCode":"EC",
    "regions":[
      {
        label:"Azuay",
        "shortCode":"A"
      },
      {
        label:"Bolívar",
        "shortCode":"B"
      },
      {
        label:"Cañar",
        "shortCode":"F"
      },
      {
        label:"Carchi",
        "shortCode":"C"
      },
      {
        label:"Chimborazo",
        "shortCode":"H"
      },
      {
        label:"Cotopaxi",
        "shortCode":"X"
      },
      {
        label:"El Oro",
        "shortCode":"O"
      },
      {
        label:"Esmeraldas",
        "shortCode":"E"
      },
      {
        label:"Galápagos",
        "shortCode":"W"
      },
      {
        label:"Guayas",
        "shortCode":"G"
      },
      {
        label:"Imbabura",
        "shortCode":"I"
      },
      {
        label:"Loja",
        "shortCode":"L"
      },
      {
        label:"Los Ríos",
        "shortCode":"R"
      },
      {
        label:"Manabí",
        "shortCode":"M"
      },
      {
        label:"Morona-Santiago",
        "shortCode":"S"
      },
      {
        label:"Napo",
        "shortCode":"N"
      },
      {
        label:"Orellana",
        "shortCode":"D"
      },
      {
        label:"Pastaza",
        "shortCode":"Y"
      },
      {
        label:"Pichincha",
        "shortCode":"P"
      },
      {
        label:"Santa Elena",
        "shortCode":"SE"
      },
      {
        label:"Santo Domingo de los Tsáchilas",
        "shortCode":"SD"
      },
      {
        label:"Sucumbíos",
        "shortCode":"U"
      },
      {
        label:"Tungurahua",
        "shortCode":"T"
      },
      {
        label:"Zamora-Chinchipe",
        "shortCode":"Z"
      }
    ]
  },
  {
    label:"Egypt",
    "countryShortCode":"EG",
    "regions":[
      {
        label:"Alexandria",
        "shortCode":"ALX"
      },
      {
        label:"Aswan",
        "shortCode":"ASN"
      },
      {
        label:"Asyout",
        "shortCode":"AST"
      },
      {
        label:"Bani Sueif",
        "shortCode":"BNS"
      },
      {
        label:"Beheira",
        "shortCode":"BH"
      },
      {
        label:"Cairo",
        "shortCode":"C"
      },
      {
        label:"Daqahlia",
        "shortCode":"DK"
      },
      {
        label:"Dumiat",
        "shortCode":"DT"
      },
      {
        label:"El Bahr El Ahmar",
        "shortCode":"BA"
      },
      {
        label:"El Ismailia",
        "shortCode":"IS"
      },
      {
        label:"El Suez",
        "shortCode":"SUZ"
      },
      {
        label:"El Wadi El Gedeed",
        "shortCode":"WAD"
      },
      {
        label:"Fayoum",
        "shortCode":"FYM"
      },
      {
        label:"Gharbia",
        "shortCode":"GH"
      },
      {
        label:"Giza",
        "shortCode":"SUZ"
      },
      {
        label:"Helwan",
        "shortCode":"HU"
      },
      {
        label:"Kafr El Sheikh",
        "shortCode":"KFS"
      },
      {
        label:"Luxor",
        "shortCode":"LX"
      },
      {
        label:"Matrouh",
        "shortCode":"MT"
      },
      {
        label:"Menia",
        "shortCode":"MN"
      },
      {
        label:"Menofia",
        "shortCode":"MNF"
      },
      {
        label:"North Sinai",
        "shortCode":"SIN"
      },
      {
        label:"Port Said",
        "shortCode":"PTS"
      },
      {
        label:"Qalubia",
        "shortCode":"KB"
      },
      {
        label:"Qena",
        "shortCode":"KN"
      },
      {
        label:"Sharqia",
        "shortCode":"SHR"
      },
      {
        label:"Sixth of October",
        "shortCode":"SU"
      },
      {
        label:"Sohag",
        "shortCode":"SHG"
      },
      {
        label:"South Sinai",
        "shortCode":"JS"
      }
    ]
  },
  {
    label:"El Salvador",
    "countryShortCode":"SV",
    "regions":[
      {
        label:"Ahuachapán",
        "shortCode":"AH"
      },
      {
        label:"Cabañas",
        "shortCode":"CA"
      },
      {
        label:"Cuscatlán",
        "shortCode":"CU"
      },
      {
        label:"Chalatenango",
        "shortCode":"CH"
      },
      {
        label:"La Libertad",
        "shortCode":"LI"
      },
      {
        label:"La Paz",
        "shortCode":"PA"
      },
      {
        label:"La Unión",
        "shortCode":"UN"
      },
      {
        label:"Morazán",
        "shortCode":"MO"
      },
      {
        label:"San Miguel",
        "shortCode":"SM"
      },
      {
        label:"San Salvador",
        "shortCode":"SS"
      },
      {
        label:"Santa Ana",
        "shortCode":"SA"
      },
      {
        label:"San Vicente",
        "shortCode":"SV"
      },
      {
        label:"Sonsonate",
        "shortCode":"SO"
      },
      {
        label:"Usulután",
        "shortCode":"US"
      }
    ]
  },
  {
    label:"Equatorial Guinea",
    "countryShortCode":"GQ",
    "regions":[
      {
        label:"Annobón",
        "shortCode":"AN"
      },
      {
        label:"Bioko Norte",
        "shortCode":"BN"
      },
      {
        label:"Bioko Sur",
        "shortCode":"BS"
      },
      {
        label:"Centro Sur",
        "shortCode":"CS"
      },
      {
        label:"Kié-Ntem",
        "shortCode":"KN"
      },
      {
        label:"Litoral",
        "shortCode":"LI"
      },
      {
        label:"Wele-Nzas",
        "shortCode":"WN"
      }
    ]
  },
  {
    label:"Eritrea",
    "countryShortCode":"ER",
    "regions":[
      {
        label:"Anseba",
        "shortCode":"AN"
      },
      {
        label:"Debub",
        "shortCode":"DU"
      },
      {
        label:"Debub-Keih-Bahri",
        "shortCode":"DK"
      },
      {
        label:"Gash-Barka",
        "shortCode":"GB"
      },
      {
        label:"Maekel",
        "shortCode":"MA"
      },
      {
        label:"Semien-Keih-Bahri",
        "shortCode":"SK"
      }
    ]
  },
  {
    label:"Estonia",
    "countryShortCode":"EE",
    "regions":[
      {
        label:"Harjumaa (Tallinn)",
        "shortCode":"37"
      },
      {
        label:"Hiiumaa (Kardla)",
        "shortCode":"39"
      },
      {
        label:"Ida-Virumaa (Johvi)",
        "shortCode":"44"
      },
      {
        label:"Järvamaa (Paide)",
        "shortCode":"41"
      },
      {
        label:"Jõgevamaa (Jogeva)",
        "shortCode":"49"
      },
      {
        label:"Läänemaa",
        "shortCode":"57"
      },
      {
        label:"Lääne-Virumaa (Rakvere)",
        "shortCode":"59"
      },
      {
        label:"Pärnumaa (Parnu)",
        "shortCode":"67"
      },
      {
        label:"Põlvamaa (Polva)",
        "shortCode":"65"
      },
      {
        label:"Raplamaa (Rapla)",
        "shortCode":"70"
      },
      {
        label:"Saaremaa (Kuessaare)",
        "shortCode":"74"
      },
      {
        label:"Tartumaa (Tartu)",
        "shortCode":"78"
      },
      {
        label:"Valgamaa (Valga)",
        "shortCode":"82"
      },
      {
        label:"Viljandimaa (Viljandi)",
        "shortCode":"84"
      },
      {
        label:"Võrumaa (Voru)",
        "shortCode":"86"
      }
    ]
  },
  {
    label:"Ethiopia",
    "countryShortCode":"ET",
    "regions":[
      {
        label:"Addis Ababa",
        "shortCode":"AA"
      },
      {
        label:"Afar",
        "shortCode":"AF"
      },
      {
        label:"Amhara",
        "shortCode":"AM"
      },
      {
        label:"Benshangul-Gumaz",
        "shortCode":"BE"
      },
      {
        label:"Dire Dawa",
        "shortCode":"DD"
      },
      {
        label:"Gambela",
        "shortCode":"GA"
      },
      {
        label:"Harari",
        "shortCode":"HA"
      },
      {
        label:"Oromia",
        "shortCode":"OR"
      },
      {
        label:"Somali",
        "shortCode":"SO"
      },
      {
        label:"Southern Nations Nationalities and People's Region",
        "shortCode":"SN"
      },
      {
        label:"Tigray",
        "shortCode":"TI"
      }
    ]
  },
  {
    label:"Falkland Islands (Islas Malvinas)",
    "countryShortCode":"FK",
    "regions":[
      {
        label:"Falkland Islands (Islas Malvinas)"
      }
    ]
  },
  {
    label:"Faroe Islands",
    "countryShortCode":"FO",
    "regions":[
      {
        label:"Bordoy"
      },
      {
        label:"Eysturoy"
      },
      {
        label:"Mykines"
      },
      {
        label:"Sandoy"
      },
      {
        label:"Skuvoy"
      },
      {
        label:"Streymoy"
      },
      {
        label:"Suduroy"
      },
      {
        label:"Tvoroyri"
      },
      {
        label:"Vagar"
      }
    ]
  },
  {
    label:"Fiji",
    "countryShortCode":"FJ",
    "regions":[
      {
        label:"Ba",
        "shortCode":"01"
      },
      {
        label:"Bua",
        "shortCode":"01"
      },
      {
        label:"Cakaudrove",
        "shortCode":"03"
      },
      {
        label:"Kadavu",
        "shortCode":"04"
      },
      {
        label:"Lau",
        "shortCode":"05"
      },
      {
        label:"Lomaiviti",
        "shortCode":"06"
      },
      {
        label:"Macuata",
        "shortCode":"07"
      },
      {
        label:"Nadroga and Navosa",
        "shortCode":"08"
      },
      {
        label:"Naitasiri",
        "shortCode":"09"
      },
      {
        label:"Namosi",
        "shortCode":"10"
      },
      {
        label:"Ra",
        "shortCode":"011"
      },
      {
        label:"Rewa",
        "shortCode":"12"
      },
      {
        label:"Rotuma",
        "shortCode":"R"
      },
      {
        label:"Serua",
        "shortCode":"12"
      },
      {
        label:"Tailevu",
        "shortCode":"14"
      }
    ]
  },
  {
    label:"Finland",
    "countryShortCode":"FI",
    "regions":[
      {
        label:"Ahvenanmaan lääni",
        "shortCode":"AL"
      },
      {
        label:"Etelä-Suomen lääni",
        "shortCode":"ES"
      },
      {
        label:"Itä-Suomen lääni",
        "shortCode":"IS"
      },
      {
        label:"Länsi-Suomen lääni",
        "shortCode":"LS"
      },
      {
        label:"Lapin lääni",
        "shortCode":"LL"
      },
      {
        label:"Oulun lääni",
        "shortCode":"OL"
      }
    ]
  },
  {
    label:"France",
    "countryShortCode":"FR",
    "regions":[
      {
        label:"Auvergne-Rhône-Alpes",
        "shortCode": "ARA"
      },
      {
        label:"Bourgogne-Franche-Comté",
        "shortCode": "BFC"
      },
      {
        label:"Bretagne",
        "shortCode": "BRE"
      },
      {
        label:"Centre-Val de Loire",
        "shortCode": "CVL"
      },
      {
        label:"Corse",
        "shortCode": "COR"
      },
      {
        label:"Grand Est",
        "shortCode": "GES"
      },
      {
        label:"Hauts-de-France",
        "shortCode": "HDF"
      },
      {
        label:"Île-de-France",
        "shortCode": "IDF"
      },
      {
        label:"Normandie",
        "shortCode": "NOR"
      },
      {
        label:"Nouvelle-Aquitaine",
        "shortCode": "NAQ"
      },
      {
        label:"Occitanie",
        "shortCode": "OCC"
      },
      {
        label:"Pays de la Loire",
        "shortCode": "PDL"
      },
      {
        label:"Provence-Alpes-Cote d'Azur",
        "shortCode": "PAC"
      },
      {
        label:"Clipperton",
        "shortCode": "CP"
      },
      {
        label:"Guadeloupe",
        "shortCode": "GP"
      },
      {
        label:"Guyane",
        "shortCode": "GF"
      },
      {
        label:"Martinique",
        "shortCode": "MQ"
      },
      {
        label:"Mayotte",
        "shortCode": "YT"
      },
      {
        label:"Novelle-Calédonie",
        "shortCode": "NC"
      },
      {
        label:"Polynésie",
        "shortCode": "PF"
      },
      {
        label:"Saint-Pierre-et-Miquelon",
        "shortCode": "PM"
      },
      {
        label:"Saint Barthélemy",
        "shortCode": "BL"
      },
      {
        label:"Saint Martin",
        "shortCode": "MF"
      },
      {
        label:"Réunion",
        "shortCode": "RE"
      },
      {
        label:"Terres Australes Françaises",
        "shortCode": "TF"
      },
      {
        label:"Wallis-et-Futuna",
        "shortCode": "WF"
      }
    ]
  },
  {
    label:"French Guiana",
    "countryShortCode":"GF",
    "regions":[
      {
        label:"French Guiana"
      }
    ]
  },
  {
    label:"French Polynesia",
    "countryShortCode":"PF",
    "regions":[
      {
        label:"Archipel des Marquises"
      },
      {
        label:"Archipel des Tuamotu"
      },
      {
        label:"Archipel des Tubuai"
      },
      {
        label:"Iles du Vent"
      },
      {
        label:"Iles Sous-le-Vent"
      }
    ]
  },
  {
    label:"French Southern and Antarctic Lands",
    "countryShortCode":"TF",
    "regions":[
      {
        label:"Adelie Land"
      },
      {
        label:"Ile Crozet"
      },
      {
        label:"Iles Kerguelen"
      },
      {
        label:"Iles Saint-Paul et Amsterdam"
      }
    ]
  },
  {
    label:"Gabon",
    "countryShortCode":"GA",
    "regions":[
      {
        label:"Estuaire",
        "shortCode": "1"
      },
      {
        label:"Haut-Ogooué",
        "shortCode": "2"
      },
      {
        label:"Moyen-Ogooué",
        "shortCode": "3"
      },
      {
        label:"Ngounié",
        "shortCode": "4"
      },
      {
        label:"Nyanga",
        "shortCode": "5"
      },
      {
        label:"Ogooué-Ivindo",
        "shortCode": "6"
      },
      {
        label:"Ogooué-Lolo",
        "shortCode": "7"
      },
      {
        label:"Ogooué-Maritime",
        "shortCode": "8"
      },
      {
        label:"Woleu-Ntem",
        "shortCode": "9"
      }
    ]
  },
  {
    label:"Gambia, The",
    "countryShortCode":"GM",
    "regions":[
      {
        label:"Banjul",
        "shortCode": "B"
      },
      {
        label:"Central River",
        "shortCode": "M"
      },
      {
        label:"Lower River",
        "shortCode": "L"
      },
      {
        label:"North Bank",
        "shortCode": "N"
      },
      {
        label:"Upper River",
        "shortCode": "U"
      },
      {
        label:"Western",
        "shortCode": "W"
      }
    ]
  },
  {
    label:"Georgia",
    "countryShortCode":"GE",
    "regions":[
      {
        label:"Abkhazia (Sokhumi)",
        "shortCode":"AB"
      },
      {
        label:"Ajaria (Bat'umi)",
        "shortCode":"AJ"
      },
      {
        label:"Guria",
        "shortCode":"GU"
      },
      {
        label:"Imereti",
        "shortCode":"IM"
      },
      {
        label:"K'akheti",
        "shortCode":"KA"
      },
      {
        label:"Kvemo Kartli",
        "shortCode":"KK"
      },
      {
        label:"Mtshkheta-Mtianeti",
        "shortCode":"MM"
      },
      {
        label:"Rach'a-Lexhkumi-KvemoSvaneti",
        "shortCode":"RL"
      },
      {
        label:"Samegrelo-Zemo Svaneti",
        "shortCode":"SZ"
      },
      {
        label:"Samtskhe-Javakheti",
        "shortCode":"SJ"
      },
      {
        label:"Shida Kartli",
        "shortCode":"SK"
      },
      {
        label:"Tbilisi",
        "shortCode":"TB"
      }
    ]
  },
  {
    label:"Germany",
    "countryShortCode":"DE",
    "regions":[
      {
        label:"Baden-Württemberg",
        "shortCode":"BW"
      },
      {
        label:"Bayern",
        "shortCode":"BY"
      },
      {
        label:"Berlin",
        "shortCode":"BE"
      },
      {
        label:"Brandenburg",
        "shortCode":"BB"
      },
      {
        label:"Bremen",
        "shortCode":"HB"
      },
      {
        label:"Hamburg",
        "shortCode":"HH"
      },
      {
        label:"Hessen",
        "shortCode":"HE"
      },
      {
        label:"Mecklenburg-Vorpommern",
        "shortCode":"MV"
      },
      {
        label:"Niedersachsen",
        "shortCode":"NI"
      },
      {
        label:"Nordrhein-Westfalen",
        "shortCode":"NW"
      },
      {
        label:"Rheinland-Pfalz",
        "shortCode":"RP"
      },
      {
        label:"Saarland",
        "shortCode":"SL"
      },
      {
        label:"Sachsen",
        "shortCode":"SN"
      },
      {
        label:"Sachsen-Anhalt",
        "shortCode":"ST"
      },
      {
        label:"Schleswig-Holstein",
        "shortCode":"SH"
      },
      {
        label:"Thüringen",
        "shortCode":"TH"
      }
    ]
  },
  {
    label:"Ghana",
    "countryShortCode":"GH",
    "regions":[
      {
        label:"Ashanti",
        "shortCode":"AH"
      },
      {
        label:"Brong-Ahafo",
        "shortCode":"BA"
      },
      {
        label:"Central",
        "shortCode":"CP"
      },
      {
        label:"Eastern",
        "shortCode":"EP"
      },
      {
        label:"Greater Accra",
        "shortCode":"AA"
      },
      {
        label:"Northern",
        "shortCode":"NP"
      },
      {
        label:"Upper East",
        "shortCode":"UE"
      },
      {
        label:"Upper West",
        "shortCode":"UW"
      },
      {
        label:"Volta",
        "shortCode":"TV"
      },
      {
        label:"Western",
        "shortCode":"WP"
      }
    ]
  },
  {
    label:"Gibraltar",
    "countryShortCode":"GI",
    "regions":[
      {
        label:"Gibraltar"
      }
    ]
  },
  {
    label:"Greece",
    "countryShortCode":"GR",
    "regions":[
      {
        label:"Anatolikí Makedonía kai Thráki",
        "shortCode":"A"
      },
      {
        label:"Attikḯ",
        "shortCode":"I"
      },
      {
        label:"Dytikí Elláda",
        "shortCode":"G"
      },
      {
        label:"Dytikí Makedonía",
        "shortCode":"C"
      },
      {
        label:"Ionía Nísia",
        "shortCode":"F"
      },
      {
        label:"Kentrikí Makedonía",
        "shortCode":"B"
      },
      {
        label:"Krítí",
        "shortCode":"M"
      },
      {
        label:"Notío Aigaío",
        "shortCode":"L"
      },
      {
        label:"Peloponnísos",
        "shortCode":"J"
      },
      {
        label:"Stereá Elláda",
        "shortCode":"H"
      },
      {
        label:"Thessalía",
        "shortCode":"E"
      },
      {
        label:"Voreío Aigaío",
        "shortCode":"K"
      },
      {
        label:"Ípeiros",
        "shortCode":"D"
      },
      {
        label:"Ágion Óros",
        "shortCode": "69"
      }
    ]
  },
  {
    label:"Greenland",
    "countryShortCode":"GL",
    "regions":[
      {
        label:"Kommune Kujalleq",
        "shortCode": "KU"
      },
      {
        label:"Kommuneqarfik Sermersooq",
        "shortCode": "SM"
      },
      {
        label:"Qaasuitsup Kommunia",
        "shortCode": "QA"
      },
      {
        label: "Qeqqata Kommunia",
        "shortCode": "QE"
      }
    ]
  },
  {
    label:"Grenada",
    "countryShortCode":"GD",
    "regions":[
      {
        label:"Saint Andrew",
        "shortCode": "01"
      },
      {
        label:"Saint David",
        "shortCode": "02"
      },
      {
        label:"Saint George",
        "shortCode": "03"
      },
      {
        label:"Saint John",
        "shortCode": "04"
      },
      {
        label:"Saint Mark",
        "shortCode": "05"
      },
      {
        label:"Saint Patrick",
        "shortCode": "06"
      },
      {
        label:"Southern Grenadine Islands",
        "shortCode": "10"
      }
    ]
  },
  {
    label:"Guadeloupe",
    "countryShortCode":"GP",
    "regions":[
      {
        label:"Guadeloupe"
      }
    ]
  },
  {
    label:"Guam",
    "countryShortCode":"GU",
    "regions":[
      {
        label:"Guam"
      }
    ]
  },
  {
    label:"Guatemala",
    "countryShortCode":"GT",
    "regions":[
      {
        label:"Alta Verapaz",
        "shortCode": "AV"
      },
      {
        label:"Baja Verapaz",
        "shortCode": "BV"
      },
      {
        label:"Chimaltenango",
        "shortCode": "CM"
      },
      {
        label:"Chiquimula",
        "shortCode": "CQ"
      },
      {
        label:"El Progreso",
        "shortCode": "PR"
      },
      {
        label:"Escuintla",
        "shortCode": "ES"
      },
      {
        label:"Guatemala",
        "shortCode": "GU"
      },
      {
        label:"Huehuetenango",
        "shortCode": "HU"
      },
      {
        label:"Izabal",
        "shortCode": "IZ"
      },
      {
        label:"Jalapa",
        "shortCode": "JA"
      },
      {
        label:"Jutiapa",
        "shortCode": "JU"
      },
      {
        label:"Petén",
        "shortCode": "PE"
      },
      {
        label:"Quetzaltenango",
        "shortCode": "QZ"
      },
      {
        label:"Quiché",
        "shortCode": "QC"
      },
      {
        label:"Retalhuleu",
        "shortCode": "Re"
      },
      {
        label:"Sacatepéquez",
        "shortCode": "SA"
      },
      {
        label:"San Marcos",
        "shortCode": "SM"
      },
      {
        label:"Santa Rosa",
        "shortCode": "SR"
      },
      {
        label:"Sololá",
        "shortCode": "SO"
      },
      {
        label:"Suchitepéquez",
        "shortCode": "SU"
      },
      {
        label:"Totonicapán",
        "shortCode": "TO"
      },
      {
        label:"Zacapa",
        "shortCode": "ZA"
      }
    ]
  },
  {
    label:"Guernsey",
    "countryShortCode":"GG",
    "regions":[
      {
        label:"Castel"
      },
      {
        label:"Forest"
      },
      {
        label:"St. Andrew"
      },
      {
        label:"St. Martin"
      },
      {
        label:"St. Peter Port"
      },
      {
        label:"St. Pierre du Bois"
      },
      {
        label:"St. Sampson"
      },
      {
        label:"St. Saviour"
      },
      {
        label:"Torteval"
      },
      {
        label:"Vale"
      }
    ]
  },
  {
    label:"Guinea",
    "countryShortCode":"GN",
    "regions":[
      {
        label:"Boké",
        "shortCode": "B"
      },
      {
        label:"Conakry",
        "shortCode": "C"
      },
      {
        label:"Faranah",
        "shortCode": "F"
      },
      {
        label:"Kankan",
        "shortCode": "K"
      },
      {
        label:"Kindia",
        "shortCode": "D"
      },
      {
        label:"Labé",
        "shortCode": "L"
      },
      {
        label:"Mamou",
        "shortCode": "M"
      },
      {
        label:"Nzérékoré",
        "shortCode": "N"
      }
    ]
  },
  {
    label:"Guinea-Bissau",
    "countryShortCode":"GW",
    "regions":[
      {
        label:"Bafatá",
        "shortCode":"BA"
      },
      {
        label:"Biombo",
        "shortCode":"BM"
      },
      {
        label:"Bissau",
        "shortCode":"BS"
      },
      {
        label:"Bolama-Bijagos",
        "shortCode":"BL"
      },
      {
        label:"Cacheu",
        "shortCode":"CA"
      },
      {
        label:"Gabú",
        "shortCode":"GA"
      },
      {
        label:"Oio",
        "shortCode":"OI"
      },
      {
        label:"Quinara",
        "shortCode":"QU"
      },
      {
        label:"Tombali",
        "shortCode":"TO"
      }
    ]
  },
  {
    label:"Guyana",
    "countryShortCode":"GY",
    "regions":[
      {
        label:"Barima-Waini",
        "shortCode":"BA"
      },
      {
        label:"Cuyuni-Mazaruni",
        "shortCode":"CU"
      },
      {
        label:"Demerara-Mahaica",
        "shortCode":"DE"
      },
      {
        label:"East Berbice-Corentyne",
        "shortCode":"EB"
      },
      {
        label:"Essequibo Islands-West Demerara",
        "shortCode":"ES"
      },
      {
        label:"Mahaica-Berbice",
        "shortCode":"MA"
      },
      {
        label:"Pomeroon-Supenaam",
        "shortCode":"PM"
      },
      {
        label:"Potaro-Siparuni",
        "shortCode":"PT"
      },
      {
        label:"Upper Demerara-Berbice",
        "shortCode":"UD"
      },
      {
        label:"Upper Takutu-Upper Essequibo",
        "shortCode":"UT"
      }
    ]
  },
  {
    label:"Haiti",
    "countryShortCode":"HT",
    "regions":[
      {
        label:"Artibonite",
        "shortCode":"AR"
      },
      {
        label:"Centre",
        "shortCode":"CE"
      },
      {
        label:"Grand'Anse",
        "shortCode":"GA"
      },
      {  label:"Nippes",
         "shortCode":"NI"
      },
      {
        label:"Nord",
        "shortCode":"ND"
      },
      {
        label:"Nord-Est",
        "shortCode":"NE"
      },
      {
        label:"Nord-Ouest",
        "shortCode":"NO"
      },
      {
        label:"Ouest",
        "shortCode":"OU"
      },
      {
        label:"Sud",
        "shortCode":"SD"
      },
      {
        label:"Sud-Est",
        "shortCode":"SE"
      }
    ]
  },
  {
    label:"Heard Island and McDonald Islands",
    "countryShortCode":"HM",
    "regions":[
      {
        label:"Heard Island and McDonald Islands"
      }
    ]
  },
  {
    label:"Holy See (Vatican City)",
    "countryShortCode":"VA",
    "regions":[
      {
        label:"Holy See (Vatican City)",
        "shortCode":"01"
      }
    ]
  },
  {
    label:"Honduras",
    "countryShortCode":"HN",
    "regions":[
      {
        label:"Atlántida",
        "shortCode":"AT"
      },
      {
        label:"Choluteca",
        "shortCode":"CH"
      },
      {
        label:"Colón",
        "shortCode":"CL"
      },
      {
        label:"Comayagua",
        "shortCode":"CM"
      },
      {
        label:"Copán",
        "shortCode":"CP"
      },
      {
        label:"Cortés",
        "shortCode":"CR"
      },
      {
        label:"El Paraíso",
        "shortCode":"EP"
      },
      {
        label:"Francisco Morazan",
        "shortCode":"FM"
      },
      {
        label:"Gracias a Dios",
        "shortCode":"GD"
      },
      {
        label:"Intibucá",
        "shortCode":"IN"
      },
      {
        label:"Islas de la Bahía",
        "shortCode":"IB"
      },
      {
        label:"La Paz",
        "shortCode":"LP"
      },
      {
        label:"Lempira",
        "shortCode":"LE"
      },
      {
        label:"Ocotepeque",
        "shortCode":"OC"
      },
      {
        label:"Olancho",
        "shortCode":"OL"
      },
      {
        label:"Santa Bárbara",
        "shortCode":"SB"
      },
      {
        label:"Valle",
        "shortCode":"VA"
      },
      {
        label:"Yoro",
        "shortCode":"YO"
      }
    ]
  },
  {
    label:"Hong Kong",
    "countryShortCode":"HK",
    "regions":[
      {
        label:"Hong Kong"
      }
    ]
  },
  {
    label:"Hungary",
    "countryShortCode":"HU",
    "regions":[
      {
        label:"Bács-Kiskun",
        "shortCode":"BK"
      },
      {
        label:"Baranya",
        "shortCode":"BA"
      },
      {
        label:"Békés",
        "shortCode":"BE"
      },
      {
        label:"Békéscsaba",
        "shortCode":"BC"
      },
      {
        label:"Borsod-Abauj-Zemplen",
        "shortCode":"BZ"
      },
      {
        label:"Budapest",
        "shortCode":"BU"
      },
      {
        label:"Csongrád",
        "shortCode":"CS"
      },
      {
        label:"Debrecen",
        "shortCode":"DE"
      },
      {
        label:"Dunaújváros",
        "shortCode":"DU"
      },
      {
        label:"Eger",
        "shortCode":"EG"
      },
      {
        label:"Érd",
        "shortCode":"ER"
      },
      {
        label:"Fejér",
        "shortCode":"FE"
      },
      {
        label:"Győr",
        "shortCode":"GY"
      },
      {
        label:"Győr-Moson-Sopron",
        "shortCode":"GS"
      },
      {
        label:"Hajdú-Bihar",
        "shortCode":"HB"
      },
      {
        label:"Heves",
        "shortCode":"HE"
      },
      {
        label:"Hódmezővásárhely",
        "shortCode":"HV"
      },
      {
        label:"Jász-Nagykun-Szolnok",
        "shortCode":"N"
      },
      {
        label:"Kaposvár",
        "shortCode":"KV"
      },
      {
        label:"Kecskemét",
        "shortCode":"KM"
      },
      {
        label:"Komárom-Esztergom",
        "shortCode":"KE"
      },
      {
        label:"Miskolc",
        "shortCode":"MI"
      },
      {
        label:"Nagykanizsa",
        "shortCode":"NK"
      },
      {
        label:"Nógrád",
        "shortCode":"NO"
      },
      {
        label:"Nyíregyháza",
        "shortCode":"NY"
      },
      {
        label:"Pécs",
        "shortCode":"PS"
      },
      {
        label:"Pest",
        "shortCode":"PE"
      },
      { label:"Salgótarján",
        "shortCode":"ST"
      },
      {
        label:"Somogy",
        "shortCode":"SO"
      },
      {
        label:"Sopron",
        "shortCode":"SN"
      },
      {
        label:"Szabolcs-á-Bereg",
        "shortCode":"SZ"
      },
      {
        label:"Szeged",
        "shortCode":"SD"
      },
      {
        label:"Székesfehérvár",
        "shortCode":"SF"
      },
      { label:"Szekszárd",
        "shortCode":"SS"
      },
      {
        label:"Szolnok",
        "shortCode":"SK"
      },
      {
        label:"Szombathely",
        "shortCode":"SH"
      },
      {
        label:"Tatabánya",
        "shortCode":"TB"
      },
      {
        label:"Tolna",
        "shortCode":"TO"
      },
      {
        label:"Vas",
        "shortCode":"VA"
      },
      {
        label:"Veszprém",
        "shortCode":"VE"
      },
      {
        label:"Veszprém (City)",
        "shortCode":"VM"
      },
      {
        label:"Zala",
        "shortCode":"ZA"
      },
      {
        label:"Zalaegerszeg",
        "shortCode":"ZE"
      }
    ]
  },
  {
    label:"Iceland",
    "countryShortCode":"IS",
    "regions":[
      {
        label:"Austurland",
        "shortCode":"7"
      },
      {
        label:"Höfuðborgarsvæði utan Reykjavíkur",
        "shortCode":"1"
      },
      {
        label:"Norðurland eystra",
        "shortCode":"6"
      },
      {
        label:"Norðurland vestra",
        "shortCode":"5"
      },
      {
        label:"Suðurland",
        "shortCode":"8"
      },
      {
        label:"Suðurnes",
        "shortCode":"2"
      },
      {
        label:"Vestfirðir",
        "shortCode":"4"
      },
      {
        label:"Vesturland",
        "shortCode":"3"
      }
    ]
  },
  {
    label:"India",
    "countryShortCode":"IN",
    "regions":[
      {
        label:"Andaman and Nicobar Islands",
        "shortCode":"AN"
      },
      {
        label:"Andhra Pradesh",
        "shortCode":"AP"
      },
      {
        label:"Arunachal Pradesh",
        "shortCode":"AR"
      },
      {
        label:"Assam",
        "shortCode":"AS"
      },
      {
        label:"Bihar",
        "shortCode":"BR"
      },
      {
        label:"Chandigarh",
        "shortCode":"CH"
      },
      {
        label:"Chhattisgarh",
        "shortCode":"CT"
      },
      {
        label:"Dadra and Nagar Haveli",
        "shortCode":"DN"
      },
      {
        label:"Daman and Diu",
        "shortCode":"DD"
      },
      {
        label:"Delhi",
        "shortCode":"DL"
      },
      {
        label:"Goa",
        "shortCode":"GA"
      },
      {
        label:"Gujarat",
        "shortCode":"GJ"
      },
      {
        label:"Haryana",
        "shortCode":"HR"
      },
      {
        label:"Himachal Pradesh",
        "shortCode":"HP"
      },
      {
        label:"Jammu and Kashmir",
        "shortCode":"JK"
      },
      {
        label:"Jharkhand",
        "shortCode":"JH"
      },
      {
        label:"Karnataka",
        "shortCode":"KA"
      },
      {
        label:"Kerala",
        "shortCode":"KL"
      },
      {
        label:"Lakshadweep",
        "shortCode":"LD"
      },
      {
        label:"Madhya Pradesh",
        "shortCode":"MP"
      },
      {
        label:"Maharashtra",
        "shortCode":"MH"
      },
      {
        label:"Manipur",
        "shortCode":"MN"
      },
      {
        label:"Meghalaya",
        "shortCode":"ML"
      },
      {
        label:"Mizoram",
        "shortCode":"MZ"
      },
      {
        label:"Nagaland",
        "shortCode":"NL"
      },
      {
        label:"Odisha",
        "shortCode":"OR"
      },
      {
        label:"Puducherry",
        "shortCode":"PY"
      },
      {
        label:"Punjab",
        "shortCode":"PB"
      },
      {
        label:"Rajasthan",
        "shortCode":"RJ"
      },
      {
        label:"Sikkim",
        "shortCode":"WK"
      },
      {
        label:"Tamil Nadu",
        "shortCode":"TN"
      },
      {
        label:"Telangana",
        "shortCode":"TG"
      },
      {
        label:"Tripura",
        "shortCode":"TR"
      },
      {
        label:"Uttarakhand",
        "shortCode":"UT"
      },
      {
        label:"Uttar Pradesh",
        "shortCode":"UP"
      },
      {
        label:"West Bengal",
        "shortCode":"WB"
      }
    ]
  },
  {
    label:"Indonesia",
    "countryShortCode":"ID",
    "regions":[
      {
        label:"Aceh",
        "shortCode":"AC"
      },
      {
        label:"Bali",
        "shortCode":"BA"
      },
      {
        label:"Bangka Belitung",
        "shortCode":"BB"
      },
      {
        label:"Banten",
        "shortCode":"BT"
      },
      {
        label:"Bengkulu",
        "shortCode":"BE"
      },
      {
        label:"Gorontalo",
        "shortCode":"GO"
      },
      {
        label:"Jakarta Raya",
        "shortCode":"JK"
      },
      {
        label:"Jambi",
        "shortCode":"JA"
      },
      {
        label:"Jawa Barat",
        "shortCode":"JB"
      },
      {
        label:"Jawa Tengah",
        "shortCode":"JT"
      },
      {
        label:"Jawa Timur",
        "shortCode":"JI"
      },
      {
        label:"Kalimantan Barat",
        "shortCode":"KB"
      },
      {
        label:"Kalimantan Selatan",
        "shortCode":"KS"
      },
      {
        label:"Kalimantan Tengah",
        "shortCode":"KT"
      },
      {
        label:"Kalimantan Timur",
        "shortCode":"KI"
      },
      {
        label:"Kalimantan Utara",
        "shortCode":"KU"
      },
      {
        label:"Kepulauan Riau",
        "shortCode":"KR"
      },
      {
        label:"Lampung",
        "shortCode":"LA"
      },
      {
        label:"Maluku",
        "shortCode":"MA"
      },
      {
        label:"Maluku Utara",
        "shortCode":"MU"
      },
      {
        label:"Nusa Tenggara Barat",
        "shortCode":"NB"
      },
      {
        label:"Nusa Tenggara Timur",
        "shortCode":"NT"
      },
      {
        label:"Papua",
        "shortCode":"PA"
      },
      {
        label:"Papua Barat",
        "shortCode":"PB"
      },
      {
        label:"Riau",
        "shortCode":"RI"
      },
      {
        label:"Sulawesi Selatan",
        "shortCode":"SR"
      },
      {
        label:"Sulawesi Tengah",
        "shortCode":"ST"
      },
      {
        label:"Sulawesi Tenggara",
        "shortCode":"SG"
      },
      {
        label:"Sulawesi Utara",
        "shortCode":"SA"
      },
      {
        label:"Sumatera Barat",
        "shortCode":"SB"
      },
      {
        label:"Sumatera Selatan",
        "shortCode":"SS"
      },
      {
        label:"Sumatera Utara",
        "shortCode":"SU"
      },
      {
        label:"Yogyakarta",
        "shortCode":"YO"
      }
    ]
  },
  {
    label:"Iran, Islamic Republic of",
    "countryShortCode":"IR",
    "regions":[
      {
        label:"Alborz",
        "shortCode":"32"
      },
      {
        label:"Ardabīl",
        "shortCode":"03"
      },
      {
        label:"Āz̄arbāyjān-e Gharbī",
        "shortCode":"02"
      },
      {
        label:"Āz̄arbāyjān-e Sharqī",
        "shortCode":"01"
      },
      {
        label:"Būshehr",
        "shortCode":"06"
      },
      {
        label:"Chahār Maḩāl va Bakhtīārī",
        "shortCode":"08"
      },
      {
        label:"Eşfahān",
        "shortCode":"04"
      },
      {
        label:"Fārs",
        "shortCode":"14"
      },
      {
        label:"Gīlān",
        "shortCode":"19"
      },
      {
        label:"Golestān",
        "shortCode":"27"
      },
      {
        label:"Hamadān",
        "shortCode":"24"
      },
      {
        label:"Hormozgān",
        "shortCode":"23"
      },
      {
        label:"Īlām",
        "shortCode":"05"
      },
      {
        label:"Kermān",
        "shortCode":"15"
      },
      {
        label:"Kermānshāh",
        "shortCode":"17"
      },
      {
        label:"Khorāsān-e Jonūbī",
        "shortCode":"29"
      },
      {
        label:"Khorāsān-e Raẕavī",
        "shortCode":"30"
      },
      {
        label:"Khorāsān-e Shomālī",
        "shortCode":"61"
      },
      {
        label:"Khūzestān",
        "shortCode":"10"
      },
      {
        label:"Kohgīlūyeh va Bowyer Aḩmad",
        "shortCode":"18"
      },
      {
        label:"Kordestān",
        "shortCode":"16"
      },
      {
        label:"Lorestān",
        "shortCode":"20"
      },
      {
        label:"Markazi",
        "shortCode":"22"
      },
      {
        label:"Māzandarān",
        "shortCode":"21"
      },
      {
        label:"Qazvīn",
        "shortCode":"28"
      },
      {
        label:"Qom",
        "shortCode":"26"
      },
      {
        label:"Semnān",
        "shortCode":"12"
      },
      {
        label:"Sīstān va Balūchestān",
        "shortCode":"13"
      },
      {
        label:"Tehrān",
        "shortCode":"07"
      },
      {
        label:"Yazd",
        "shortCode":"25"
      },
      {
        label:"Zanjān",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Iraq",
    "countryShortCode":"IQ",
    "regions":[
      {
        label:"Al Anbār",
        "shortCode":"AN"
      },
      {
        label:"Al Başrah",
        "shortCode":"BA"
      },
      {
        label:"Al Muthanná",
        "shortCode":"MU"
      },
      {
        label:"Al Qādisīyah",
        "shortCode":"QA"
      },
      {
        label:"An Najaf",
        "shortCode":"NA"
      },
      {
        label:"Arbīl",
        "shortCode":"AR"
      },
      {
        label:"As Sulaymānīyah",
        "shortCode":"SU"
      },
      {
        label:"Bābil",
        "shortCode":"BB"
      },
      {
        label:"Baghdād",
        "shortCode":"BG"
      },
      {
        label:"Dohuk",
        "shortCode":"DA"
      },
      {
        label:"Dhī Qār",
        "shortCode":"DQ"
      },
      {
        label:"Diyālá",
        "shortCode":"DI"
      },
      {
        label:"Karbalā'",
        "shortCode":"KA"
      },
      {
        label:"Kirkuk",
        "shortCode":"KI"
      },
      {
        label:"Maysān",
        "shortCode":"MA"
      },
      {
        label:"Nīnawá",
        "shortCode":"NI"
      },
      {
        label:"Şalāḩ ad Dīn",
        "shortCode":"SD"
      },
      {
        label:"Wāsiţ",
        "shortCode":"WA"
      }
    ]
  },
  {
    label:"Ireland",
    "countryShortCode":"IE",
    "regions":[
      {
        label:"Carlow",
        "shortCode":"CW"
      },
      {
        label:"Cavan",
        "shortCode":"CN"
      },
      {
        label:"Clare",
        "shortCode":"CE"
      },
      {
        label:"Cork",
        "shortCode":"CO"
      },
      {
        label:"Donegal",
        "shortCode":"DL"
      },
      {
        label:"Dublin",
        "shortCode":"D"
      },
      {
        label:"Galway",
        "shortCode":"G"
      },
      {
        label:"Kerry",
        "shortCode":"KY"
      },
      {
        label:"Kildare",
        "shortCode":"KE"
      },
      {
        label:"Kilkenny",
        "shortCode":"KK"
      },
      {
        label:"Laois",
        "shortCode":"LS"
      },
      {
        label:"Leitrim",
        "shortCode":"LM"
      },
      {
        label:"Limerick",
        "shortCode":"LK"
      },
      {
        label:"Longford",
        "shortCode":"LD"
      },
      {
        label:"Louth",
        "shortCode":"LH"
      },
      {
        label:"Mayo",
        "shortCode":"MO"
      },
      {
        label:"Meath",
        "shortCode":"MH"
      },
      {
        label:"Monaghan",
        "shortCode":"MN"
      },
      {
        label:"Offaly",
        "shortCode":"OY"
      },
      {
        label:"Roscommon",
        "shortCode":"RN"
      },
      {
        label:"Sligo",
        "shortCode":"SO"
      },
      {
        label:"Tipperary",
        "shortCode":"TA"
      },
      {
        label:"Waterford",
        "shortCode":"WD"
      },
      {
        label:"Westmeath",
        "shortCode":"WH"
      },
      {
        label:"Wexford",
        "shortCode":"WX"
      },
      {
        label:"Wicklow",
        "shortCode":"WW"
      }
    ]
  },
  {
    label:"Isle of Man",
    "countryShortCode":"IM",
    "regions":[
      {
        label:"Isle of Man"
      }
    ]
  },
  {
    label:"Israel",
    "countryShortCode":"IL",
    "regions":[
      {
        label:"HaDarom",
        "shortCode":"D"
      },
      {
        label:"HaMerkaz",
        "shortCode":"M"
      },
      {
        label:"HaTsafon",
        "shortCode":"Z"
      },
      {
        label:"H̱efa",
        "shortCode":"HA"
      },
      {
        label:"Tel-Aviv",
        "shortCode":"TA"
      },
      {
        label:"Yerushalayim",
        "shortCode":"JM"
      }
    ]
  },
  {
    label:"Italy",
    "countryShortCode":"IT",
    "regions":[
      {
        label:"Abruzzo",
        "shortCode":"65"
      },
      {
        label:"Basilicata",
        "shortCode":"77"
      },
      {
        label:"Calabria",
        "shortCode":"78"
      },
      {
        label:"Campania",
        "shortCode":"72"
      },
      {
        label:"Emilia-Romagna",
        "shortCode":"45"
      },
      {
        label:"Friuli-Venezia Giulia",
        "shortCode":"36"
      },
      {
        label:"Lazio",
        "shortCode":"62"
      },
      {
        label:"Liguria",
        "shortCode":"42"
      },
      {
        label:"Lombardia",
        "shortCode":"25"
      },
      {
        label:"Marche",
        "shortCode":"57"
      },
      {
        label:"Molise",
        "shortCode":"67"
      },
      {
        label:"Piemonte",
        "shortCode":"21"
      },
      {
        label:"Puglia",
        "shortCode":"75"
      },
      {
        label:"Sardegna",
        "shortCode":"88"
      },
      {
        label:"Sicilia",
        "shortCode":"82"
      },
      {
        label:"Toscana",
        "shortCode":"52"
      },
      {
        label:"Trentino-Alto Adige",
        "shortCode":"32"
      },
      {
        label:"Umbria",
        "shortCode":"55"
      },
      {
        label:"Valle d'Aosta",
        "shortCode":"23"
      },
      {
        label:"Veneto",
        "shortCode":"34"
      }
    ]
  },
  {
    label:"Jamaica",
    "countryShortCode":"JM",
    "regions":[
      {
        label:"Clarendon",
        "shortCode":"13"
      },
      {
        label:"Hanover",
        "shortCode":"09"
      },
      {
        label:"Kingston",
        "shortCode":"01"
      },
      {
        label:"Manchester",
        "shortCode":"12"
      },
      {
        label:"Portland",
        "shortCode":"04"
      },
      {
        label:"Saint Andrew",
        "shortCode":"02"
      },
      {
        label:"Saint Ann",
        "shortCode":"06"
      },
      {
        label:"Saint Catherine",
        "shortCode":"14"
      },
      {
        label:"Saint Elizabeth",
        "shortCode":"11"
      },
      {
        label:"Saint James",
        "shortCode":"08"
      },
      {
        label:"Saint Mary",
        "shortCode":"05"
      },
      {
        label:"Saint Thomas",
        "shortCode":"03"
      },
      {
        label:"Trelawny",
        "shortCode":"07"
      },
      {
        label:"Westmoreland",
        "shortCode":"10"
      }
    ]
  },
  {
    label:"Japan",
    "countryShortCode":"JP",
    "regions":[
        {
            label:"Aichi",
            "shortCode":"23"
        },
        {
            label:"Akita",
            "shortCode":"05"
        },
        {
            label:"Aomori",
            "shortCode":"02"
        },
        {
            label:"Chiba",
            "shortCode":"12"
        },
        {
            label:"Ehime",
            "shortCode":"38"
        },
        {
            label:"Fukui",
            "shortCode":"18"
        },
        {
            label:"Fukuoka",
            "shortCode":"40"
        },
        {
            label:"Fukushima",
            "shortCode":"07"
        },
        {
            label:"Gifu",
            "shortCode":"21"
        },
        {
            label:"Gunma",
            "shortCode":"10"
        },
        {
            label:"Hiroshima",
            "shortCode":"34"
        },
        {
            label:"Hokkaido",
            "shortCode":"01"
        },
        {
            label:"Hyogo",
            "shortCode":"28"
        },
        {
            label:"Ibaraki",
            "shortCode":"08"
        },
        {
            label:"Ishikawa",
            "shortCode":"17"
        },
        {
            label:"Iwate",
            "shortCode":"03"
        },
        {
            label:"Kagawa",
            "shortCode":"37"
        },
        {
            label:"Kagoshima",
            "shortCode":"46"
        },
        {
            label:"Kanagawa",
            "shortCode":"14"
        },
        {
            label:"Kochi",
            "shortCode":"39"
        },
        {
            label:"Kumamoto",
            "shortCode":"43"
        },
        {
            label:"Kyoto",
            "shortCode":"26"
        },
        {
            label:"Mie",
            "shortCode":"24"
        },
        {
            label:"Miyagi",
            "shortCode":"04"
        },
        {
            label:"Miyazaki",
            "shortCode":"45"
        },
        {
            label:"Nagano",
            "shortCode":"20"
        },
        {
            label:"Nagasaki",
            "shortCode":"42"
        },
        {
            label:"Nara",
            "shortCode":"29"
        },
        {
            label:"Niigata",
            "shortCode":"15"
        },
        {
            label:"Oita",
            "shortCode":"44"
        },
        {
            label:"Okayama",
            "shortCode":"33"
        },
        {
            label:"Okinawa",
            "shortCode":"47"
        },
        {
            label:"Osaka",
            "shortCode":"27"
        },
        {
            label:"Saga",
            "shortCode":"41"
        },
        {
            label:"Saitama",
            "shortCode":"11"
        },
        {
            label:"Shiga",
            "shortCode":"25"
        },
        {
            label:"Shimane",
            "shortCode":"32"
        },
        {
            label:"Shizuoka",
            "shortCode":"22"
        },
        {
            label:"Tochigi",
            "shortCode":"09"
        },
        {
            label:"Tokushima",
            "shortCode":"36"
        },
        {
            label:"Tokyo",
            "shortCode":"13"
        },
        {
            label:"Tottori",
            "shortCode":"31"
        },
        {
            label:"Toyama",
            "shortCode":"16"
        },
        {
            label:"Wakayama",
            "shortCode":"30"
        },
        {
            label:"Yamagata",
            "shortCode":"06"
        },
        {
            label:"Yamaguchi",
            "shortCode":"35"
        },
        {
            label:"Yamanashi",
            "shortCode":"19"
        }
    ]
  },
  {
    label:"Jersey",
    "countryShortCode":"JE",
    "regions":[
      {
        label:"Jersey"
      }
    ]
  },
  {
    label:"Jordan",
    "countryShortCode":"JO",
    "regions":[
      {
        label:"‘Ajlūn",
        "shortCode":"AJ"
      },
      {
        label:"Al 'Aqabah",
        "shortCode":"AQ"
      },
      {
        label:"Al Balqā’",
        "shortCode":"BA"
      },
      {
        label:"Al Karak",
        "shortCode":"KA"
      },
      {
        label:"Al Mafraq",
        "shortCode":"MA"
      },
      {
        label:"Al ‘A̅şimah",
        "shortCode":"AM"
      },
      {
        label:"Aţ Ţafīlah",
        "shortCode":"AT"
      },
      {
        label:"Az Zarqā’",
        "shortCode":"AZ"
      },
      {
        label:"Irbid",
        "shortCode":"IR"
      },
      {
        label:"Jarash",
        "shortCode":"JA"
      },
      {
        label:"Ma‘ān",
        "shortCode":"MN"
      },
      {
        label:"Mādabā",
        "shortCode":"MD"
      }
    ]
  },
  {
    label:"Kazakhstan",
    "countryShortCode":"KZ",
    "regions":[
      {
        label:"Almaty",
        "shortCode":"ALA"
      },
      {
        label:"Aqmola",
        "shortCode":"AKM"
      },
      {
        label:"Aqtobe",
        "shortCode":"AKT"
      },
      {
        label:"Astana",
        "shortCode":"AST"
      },
      {
        label:"Atyrau",
        "shortCode":"ATY"
      },
      {
        label:"Batys Qazaqstan",
        "shortCode":"ZAP"
      },
      {
        label:"Bayqongyr"
      },
      {
        label:"Mangghystau",
        "shortCode":"MAN"
      },
      {
        label:"Ongtustik Qazaqstan",
        "shortCode":"YUZ"
      },
      {
        label:"Pavlodar",
        "shortCode":"PAV"
      },
      {
        label:"Qaraghandy",
        "shortCode":"KAR"
      },
      {
        label:"Qostanay",
        "shortCode":"KUS"
      },
      {
        label:"Qyzylorda",
        "shortCode":"KZY"
      },
      {
        label:"Shyghys Qazaqstan",
        "shortCode":"VOS"
      },
      {
        label:"Soltustik Qazaqstan",
        "shortCode":"SEV"
      },
      {
        label:"Zhambyl",
        "shortCode":"ZHA"
      }
    ]
  },
  {
    label:"Kenya",
    "countryShortCode":"KE",
    "regions":[
      {
        label:"Baringo",
        "shortCode":"01"
      },
      {
        label:"Bomet",
        "shortCode":"02"
      },
      {
        label:"Bungoma",
        "shortCode":"03"
      },
      {
        label:"Busia",
        "shortCode":"04"
      },
      {
        label:"Eleyo/Marakwet",
        "shortCode":"05"
      },
      {
        label:"Embu",
        "shortCode":"06"
      },
      {
        label:"Garissa",
        "shortCode":"07"
      },
      {
        label:"Homa Bay",
        "shortCode":"08"
      },
      {
        label:"Isiolo",
        "shortCode":"09"
      },
      {
        label:"Kajiado",
        "shortCode":"10"
      },
      {
        label:"Kakamega",
        "shortCode":"11"
      },
      {
        label:"Kericho",
        "shortCode":"12"
      },
      {
        label:"Kiambu",
        "shortCode":"13"
      },
      {
        label:"Kilifi",
        "shortCode":"14"
      },
      {
        label:"Kirinyaga",
        "shortCode":"15"
      },
      {
        label:"Kisii",
        "shortCode":"16"
      },
      {
        label:"Kisumu",
        "shortCode":"17"
      },
      {
        label:"Kitui",
        "shortCode":"18"
      },
      {
        label:"Kwale",
        "shortCode":"19"
      },
      {
        label:"Laikipia",
        "shortCode":"20"
      },
      {
        label:"Lamu",
        "shortCode":"21"
      },
      {
        label:"Machakos",
        "shortCode":"22"
      },
      {
        label:"Makueni",
        "shortCode":"23"
      },
      {
        label:"Mandera",
        "shortCode":"24"
      },
      {
        label:"Marsabit",
        "shortCode":"25"
      },
      {
        label:"Meru",
        "shortCode":"26"
      },
      {
        label:"Migori",
        "shortCode":"27"
      },
      {
        label:"Mombasa",
        "shortCode":"28"
      },
      {
        label:"Murang'a",
        "shortCode":"29"
      },
      {
        label:"Nairobi City",
        "shortCode":"30"
      },
      {
        label:"Nakuru",
        "shortCode":"31"
      },
      {
        label:"Nandi",
        "shortCode":"32"
      },
      {
        label:"Narok",
        "shortCode":"33"
      },
      {
        label:"Nyamira",
        "shortCode":"34"
      },
      {
        label:"Nyandarua",
        "shortCode":"35"
      },
      {
        label:"Nyeri",
        "shortCode":"36"
      },
      {
        label:"Samburu",
        "shortCode":"37"
      },
      {
        label:"Siaya",
        "shortCode":"38"
      },
      {
        label:"Taita/Taveta",
        "shortCode":"39"
      },
      {
        label:"Tana River",
        "shortCode":"40"
      },
      {
        label:"Tharaka-Nithi",
        "shortCode":"41"
      },
      {
        label:"Trans Nzoia",
        "shortCode":"42"
      },
      {
        label:"Turkana",
        "shortCode":"43"
      },
      {
        label:"Uasin Gishu",
        "shortCode":"44"
      },
      {
        label:"Vihiga",
        "shortCode":"45"
      },
      {
        label:"Wajir",
        "shortCode":"46"
      },
      {
        label:"West Pokot",
        "shortCode":"47"
      }
    ]
  },
  {
    label:"Kiribati",
    "countryShortCode":"KI",
    "regions":[
      {
        label:"Abaiang"
      },
      {
        label:"Abemama"
      },
      {
        label:"Aranuka"
      },
      {
        label:"Arorae"
      },
      {
        label:"Banaba"
      },
      {
        label:"Beru"
      },
      {
        label:"Butaritari"
      },
      {
        label:"Central Gilberts"
      },
      {
        label:"Gilbert Islands",
        "shortCode":"G"
      },
      {
        label:"Kanton"
      },
      {
        label:"Kiritimati"
      },
      {
        label:"Kuria"
      },
      {
        label:"Line Islands",
        "shortCode":"L"
      },
      {
        label:"Maiana"
      },
      {
        label:"Makin"
      },
      {
        label:"Marakei"
      },
      {
        label:"Nikunau"
      },
      {
        label:"Nonouti"
      },
      {
        label:"Northern Gilberts"
      },
      {
        label:"Onotoa"
      },
      {
        label:"Phoenix Islands",
        "shortCode":"P"
      },
      {
        label:"Southern Gilberts"
      },
      {
        label:"Tabiteuea"
      },
      {
        label:"Tabuaeran"
      },
      {
        label:"Tamana"
      },
      {
        label:"Tarawa"
      },
      {
        label:"Teraina"
      }
    ]
  },
  {
    label:"Korea, Democratic People's Republic of",
    "countryShortCode":"KP",
    "regions":[
      {
        label:"Chagang-do (Chagang Province)",
        "shortCode":"04"
      },
      {
        label:"Hamgyong-bukto (North Hamgyong Province)",
        "shortCode":"09"
      },
      {
        label:"Hamgyong-namdo (South Hamgyong Province)",
        "shortCode":"08"
      },
      {
        label:"Hwanghae-bukto (North Hwanghae Province)",
        "shortCode":"06"
      },
      {
        label:"Hwanghae-namdo (South Hwanghae Province)",
        "shortCode":"05"
      },
      {
        label:"Kangwon-do (Kangwon Province)",
        "shortCode":"07"
      },
      {
        label:"Nasŏn (Najin-Sŏnbong)",
        "shortCode":"13"
      },
      {
        label:"P'yongan-bukto (North P'yongan Province)",
        "shortCode":"03"
      },
      {
        label:"P'yongan-namdo (South P'yongan Province)",
        "shortCode":"02"
      },
      {
        label:"P'yongyang-si (P'yongyang City)",
        "shortCode":"01"
      },
      {
        label:"Yanggang-do (Yanggang Province)",
        "shortCode":"10"
      }
    ]
  },
  {
    label:"Korea, Republic of",
    "countryShortCode":"KR",
    "regions":[
      {
        label:"Ch'ungch'ongbuk-do",
        "shortCode":"43"
      },
      {
        label:"Ch'ungch'ongnam-do",
        "shortCode":"44"
      },
      {
        label:"Cheju-do",
        "shortCode":"49"
      },
      {
        label:"Chollabuk-do",
        "shortCode":"45"
      },
      {
        label:"Chollanam-do",
        "shortCode":"46"
      },
      {
        label:"Inch'on-Kwangyokhi",
        "shortCode":"28"
      },
      {
        label:"Kang-won-do",
        "shortCode":"42"
      },
      {
        label:"Kwangju-Kwangyokshi",
        "shortCode":"28"
      },
      {
        label:"Kyonggi-do",
        "shortCode":"41"
      },
      {
        label:"Kyongsangbuk-do",
        "shortCode":"47"
      },
      {
        label:"Kyongsangnam-do",
        "shortCode":"48"
      },
      {
        label:"Pusan-Kwangyokshi",
        "shortCode":"26"
      },
      {
        label:"Seoul-T'ukpyolshi",
        "shortCode":"11"
      },
      {
        label:"Sejong",
        "shortCode":"50"
      },
      {
        label:"Taegu-Kwangyokshi",
        "shortCode":"27"
      },
      {
        label:"Taejon-Kwangyokshi",
        "shortCode":"30"
      },
      {
        label:"Ulsan-Kwangyokshi",
        "shortCode":"31"
      }
    ]
  },
  {
    label:"Kuwait",
    "countryShortCode":"KW",
    "regions":[
      {
        label:"Al Aḩmadi",
        "shortCode":"AH"
      },
      {
        label:"Al Farwānīyah",
        "shortCode":"FA"
      },
      {
        label:"Al Jahrā’",
        "shortCode":"JA"
      },
      {
        label:"Al ‘Āşimah",
        "shortCode":"KU"
      },
      {
        label:"Ḩawallī",
        "shortCode":"HA"
      },
      {
        label:"Mubārak al Kabir",
        "shortCode":"MU"
      }
    ]
  },
  {
    label:"Kyrgyzstan",
    "countryShortCode":"KG",
    "regions":[
      {
        label:"Batken Oblasty",
        "shortCode":"B"
      },
      {
        label:"Bishkek Shaary",
        "shortCode":"GB"
      },
      {
        label:"Chuy Oblasty (Bishkek)",
        "shortCode":"C"
      },
      {
        label:"Jalal-Abad Oblasty",
        "shortCode":"J"
      },
      {
        label:"Naryn Oblasty",
        "shortCode":"N"
      },
      {
        label:"Osh Oblasty",
        "shortCode":"O"
      },
      {
        label:"Talas Oblasty",
        "shortCode":"T"
      },
      {
        label:"Ysyk-Kol Oblasty (Karakol)",
        "shortCode":"Y"
      }
    ]
  },
  {
    label:"Laos",
    "countryShortCode":"LA",
    "regions":[
      {
        label:"Attapu",
        "shortCode":"AT"
      },
      {
        label:"Bokèo",
        "shortCode":"BK"
      },
      {
        label:"Bolikhamxai",
        "shortCode":"BL"
      },
      {
        label:"Champasak",
        "shortCode":"CH"
      },
      {
        label:"Houaphan",
        "shortCode":"HO"
      },
      {
        label:"Khammouan",
        "shortCode":"KH"
      },
      {
        label:"Louang Namtha",
        "shortCode":"LM"
      },
      {
        label:"Louangphabang",
        "shortCode":"LP"
      },
      {
        label:"Oudômxai",
        "shortCode":"OU"
      },
      {
        label:"Phôngsali",
        "shortCode":"PH"
      },
      {
        label:"Salavan",
        "shortCode":"SL"
      },
      {
        label:"Savannakhét",
        "shortCode":"SV"
      },
      {
        label:"Vientiane",
        "shortCode":"VI"
      },
      {
        label:"Xaignabouli",
        "shortCode":"XA"
      },
      {
        label:"Xékong",
        "shortCode":"XE"
      },
      {
        label:"Xaisomboun",
        "shortCode":"XS"
      },
      {
        label:"Xiangkhouang",
        "shortCode":"XI"
      }
    ]
  },
  {
    label:"Latvia",
    "countryShortCode":"LV",
    "regions":[
      {
        label:"Aglona",
        "shortCode":"001"
      },
      {
        label:"Aizkraukle",
        "shortCode":"002"
      },
      {
        label:"Aizpute",
        "shortCode":"003"
      },
      {
        label:"Aknīste",
        "shortCode":"004"
      },
      {
        label:"Aloja",
        "shortCode":"005"
      },
      {
        label:"Alsunga",
        "shortCode":"06"
      },
      {
        label:"Alūksne",
        "shortCode":"007"
      },
      {
        label:"Amata",
        "shortCode":"008"
      },
      {
        label:"Ape",
        "shortCode":"009"
      },
      {
        label:"Auce",
        "shortCode":"010"
      },
      {
        label:"Ādaži",
        "shortCode":"011"
      },
      {
        label:"Babīte",
        "shortCode":"012"
      },
      {
        label:"Baldone",
        "shortCode":"013"
      },
      {
        label:"Baltinava",
        "shortCode":"014"
      },
      {
        label:"Balvi",
        "shortCode":"015"
      },
      {
        label:"Bauska",
        "shortCode":"016"
      },
      {
        label:"Beverīna",
        "shortCode":"017"
      },
      {
        label:"Brocēni",
        "shortCode":"018"
      },
      {
        label:"Burtnieki",
        "shortCode":"019"
      },
      {
        label:"Carnikava",
        "shortCode":"020"
      },
      {
        label:"Cesvaine",
        "shortCode":"021"
      },
      {
        label:"Cēsis",
        "shortCode":"022"
      },
      {
        label:"Cibla",
        "shortCode":"023"
      },
      {
        label:"Dagda",
        "shortCode":"024"
      },
      {
        label:"Daugavpils",
        "shortCode":"025"
      },
      {
        label:"Daugavpils (City)",
        "shortCode":"DGV"
      },
      {
        label:"Dobele",
        "shortCode":"026"
      },
      {
        label:"Dundaga",
        "shortCode":"027"
      },
      {
        label:"Durbe",
        "shortCode":"028"
      },
      {
        label:"Engure",
        "shortCode":"029"
      },
      {
        label:"Ērgļi",
        "shortCode":"030"
      },
      {
        label:"Garkalne",
        "shortCode":"031"
      },
      {
        label:"Grobiņa",
        "shortCode":"032"
      },
      {
        label:"Gulbene",
        "shortCode":"033"
      },
      {
        label:"Iecava",
        "shortCode":"034"
      },
      {
        label:"Ikšķile",
        "shortCode":"035"
      },
      {
        label:"Ilūkste",
        "shortCode":"036"
      },
      {
        label:"Inčukalns",
        "shortCode":"037"
      },
      {
        label:"Jaunjelgava",
        "shortCode":"038"
  },
  {
        label:"Jaunpiebalga",
        "shortCode":"039"
      },
      {
        label:"Jaunpils",
        "shortCode":"040"
      },
      {
        label:"Jelgava",
        "shortCode":"041"
      },
      {
        label:"Jelgava (City)",
        "shortCode":"JEL"
      },
      {
        label:"Jēkabpils",
        "shortCode":"042"
      },
      {
        label:"Jēkabpils (City)",
        "shortCode":"JKB"
      },
      {
        label:"Jūrmala (City)",
        "shortCode":"JUR"
      },
      {
        label:"Kandava",
        "shortCode":"043"
      },
      {
        label:"Kārsava",
        "shortCode":"044"
      },
      {
        label:"Kocēni",
        "shortCode":"045"
      },
      {
        label:"Koknese",
        "shortCode":"046"
      },
      {
        label:"Krāslava",
        "shortCode":"047"
      },
      {
        label:"Krimulda",
        "shortCode":"048"
      },
      {
        label:"Krustpils",
        "shortCode":"049"
      },
      {
        label:"Kuldīga",
        "shortCode":"050"
      },
      {
        label:"Ķegums",
        "shortCode":"051"
      },
      {
        label:"Ķekava",
        "shortCode":"052"
      },
      {
        label:"Lielvārde",
        "shortCode":"053"
      },
      {
        label:"Liepāja",
        "shortCode":"LPX"
      },
      {
        label:"Limbaži",
        "shortCode":"054"
      },
      {
        label:"Līgatne",
        "shortCode":"055"
      },
      {
        label:"Līvāni",
        "shortCode":"056"
      },
      {
        label:"Lubāna",
        "shortCode":"057"
      },
      {
        label:"Ludza",
        "shortCode":"058"
      },
      {
        label:"Madona",
        "shortCode":"059"
      },
      {
        label:"Mazsalaca",
        "shortCode":"060"
      },
      {
        label:"Mālpils",
        "shortCode":"061"
      },
      {
        label:"Mārupe",
        "shortCode":"062"
      },
      {
        label:"Mērsrags",
        "shortCode":"063"
      },
      {
        label:"Naukšēni",
        "shortCode":"064"
      },
      {
        label:"Nereta",
        "shortCode":"065"
      },
      {
        label:"Nīca",
        "shortCode":"066"
      },
      {
        label:"Ogre",
        "shortCode":"067"
      },
      {
        label:"Olaine",
        "shortCode":"068"
      },
      {
        label:"Ozolnieki",
        "shortCode":"069"
      },
      {
        label:"Pārgauja",
        "shortCode":"070"
      },
      {
        label:"Pāvilosta",
        "shortCode":"071"
      },
      {
        label:"Pļaviņas",
        "shortCode":"072"
      },
      {
        label:"Preiļi",
        "shortCode":"073"
      },
      {
        label:"Priekule",
        "shortCode":"074"
      },
      {
        label:"Priekuļi",
        "shortCode":"075"
      },
      {
        label:"Rauna",
        "shortCode":"076"
      },
      {
        label:"Rēzekne",
        "shortCode":"077"
      },
      {
        label:"Rēzekne (City)",
        "shortCode":"REZ"
      },
      {
        label:"Riebiņi",
        "shortCode":"078"
      },
      {
        label:"Rīga",
        "shortCode":"RIX"
      },
      {
        label:"Roja",
        "shortCode":"079"
      },
      {
        label:"Ropaži",
        "shortCode":"080"
      },
      {
        label:"Rucava",
        "shortCode":"081"
      },
      {
        label:"Rugāji",
        "shortCode":"082"
      },
      {
        label:"Rundāle",
        "shortCode":"083"
      },
      {
        label:"Rūjiena",
        "shortCode":"084"
      },
      {
        label:"Sala",
        "shortCode":"085"
      },
      {
        label:"Salacgrīva",
        "shortCode":"086"
      },
      {
        label:"Salaspils",
        "shortCode":"087"
      },
      {
        label:"Saldus",
        "shortCode":"088"
      },
      {
        label:"Saulkrasti",
        "shortCode":"089"
      },
      {
        label:"Sēja",
        "shortCode":"090"
      },
      {
        label:"Sigulda",
        "shortCode":"091"
      },
      {
        label:"Skrīveri",
        "shortCode":"092"
      },
      {
        label:"Skrunda",
        "shortCode":"093"
      },
      {
        label:"Smiltene",
        "shortCode":"094"
      },
      {
        label:"Stopiņi",
        "shortCode":"095"
      },
      {
        label:"Strenči",
        "shortCode":"096"
      },
      {
        label:"Talsi",
        "shortCode":"097"
      },
      {
        label:"Tērvete",
        "shortCode":"098"
      },
      {
        label:"Tukums",
        "shortCode":"099"
      },
      {
        label:"Vaiņode",
        "shortCode":"100"
      },
      {
        label:"Valka",
        "shortCode":"101"
      },
      {
        label:"Valmiera",
        "shortCode":"VMR"
      },
      {
        label:"Varakļāni",
        "shortCode":"102"
      },
      {
        label:"Vārkava",
        "shortCode":"103"
      },
      {
        label:"Vecpiebalga",
        "shortCode":"104"
      },
      {
        label:"Vecumnieki",
        "shortCode":"105"
      },
      {
        label:"Ventspils",
        "shortCode":"106"
      },
      {
        label:"Ventspils (City)",
        "shortCode":"VEN"
      },
      {
        label:"Viesīte",
        "shortCode":"107"
      },
      {
        label:"Viļaka",
        "shortCode":"108"
      },
      {
        label:"Viļāni",
        "shortCode":"109"
      },
      {
        label:"Zilupe",
        "shortCode":"110"
      }
    ]
  },
  {
    label:"Lebanon",
    "countryShortCode":"LB",
    "regions":[
      {
        label: "Aakkâr",
        "shortCode": "AK"
      },
      {
        label:"Baalbelk-Hermel",
        "shortCode":"BH"
      },
      {
        label:"Béqaa",
        "shortCode":"BI"
      },
      {
        label:"Beyrouth",
        "shortCode":"BA"
      },
      {
        label:"Liban-Nord",
        "shortCode":"AS"
      },
      {
        label:"Liban-Sud",
        "shortCode":"JA"
      },
      {
        label:"Mont-Liban",
        "shortCode":"JL"
      },
      {
        label:"Nabatîyé",
        "shortCode":"NA"
      }
    ]
      },
      {
    label:"Lesotho",
    "countryShortCode":"LS",
    "regions":[
      {
        label:"Berea",
        "shortCode":"D"
      },
      {
        label:"Butha-Buthe",
        "shortCode":"B"
      },
      {
        label:"Leribe",
        "shortCode":"C"
      },
      {
        label:"Mafeteng",
        "shortCode":"E"
      },
      {
        label:"Maseru",
        "shortCode":"A"
      },
      {
        label:"Mohales Hoek",
        "shortCode":"F"
      },
      {
        label:"Mokhotlong",
        "shortCode":"J"
      },
      {
        label:"Qacha's Nek",
        "shortCode":"H"
      },
      {
        label:"Quthing",
        "shortCode":"G"
      },
      {
        label:"Thaba-Tseka",
        "shortCode":"K"
      }
    ]
  },
  {
    label:"Liberia",
    "countryShortCode":"LR",
    "regions":[
      {
        label:"Bomi",
        "shortCode":"BM"
      },
      {
        label:"Bong",
        "shortCode":"BG"
      },
      {
        label:"Gbarpolu",
        "shortCode":"GP"
      },
      {
        label:"Grand Bassa",
        "shortCode":"GB"
      },
      {
        label:"Grand Cape Mount",
        "shortCode":"CM"
      },
      {
        label:"Grand Gedeh",
        "shortCode":"GG"
      },
      {
        label:"Grand Kru",
        "shortCode":"GK"
      },
      {
        label:"Lofa",
        "shortCode":"LO"
      },
      {
        label:"Margibi",
        "shortCode":"MG"
      },
      {
        label:"Maryland",
        "shortCode":"MY"
      },
      {
        label:"Montserrado",
        "shortCode":"MO"
      },
      {
        label:"Nimba",
        "shortCode":"NI"
      },
      {
        label:"River Cess",
        "shortCode":"RI"
      },
      {
        label:"River Geee",
        "shortCode":"RG"
      },
      {
        label:"Sinoe",
        "shortCode":"SI"
      }
    ]
  },
  {
    label:"Libya",
    "countryShortCode":"LY",
    "regions":[
      {
        label:"Al Buţnān",
        "shortCode":"BU"
      },
      {
        label:"Al Jabal al Akhḑar",
        "shortCode":"JA"
      },
      {
        label:"Al Jabal al Gharbī",
        "shortCode":"JG"
      },
      {
        label:"Al Jafārah",
        "shortCode":"JA"
      },
      {
        label:"Al Jufrah",
        "shortCode":"JU"
      },
      {
        label:"Al Kufrah",
        "shortCode":"FK"
      },
      {
        label:"Al Marj",
        "shortCode":"MJ"
      },
      {
        label:"Al Marquab",
        "shortCode":"MB"
      },
      {
        label:"Al Wāḩāt",
        "shortCode":"WA"
      },
      {
        label:"An Nuqaţ al Khams",
        "shortCode":"NQ"
      },
      {
        label:"Az Zāwiyah",
        "shortCode":"ZA"
      },
      {
        label:"Banghāzī",
        "shortCode":"BA"
      },
      {
        label:"Darnah",
        "shortCode":"DR"
      },
      {
        label:"Ghāt",
        "shortCode":"GH"
      },
      {
        label:"Mişrātah",
        "shortCode":"MI"
      },
      {
        label:"Murzuq",
        "shortCode":"MQ"
      },
      {
        label:"Nālūt",
        "shortCode":"NL"
      },
      {
        label:"Sabhā",
        "shortCode":"SB"
      },
      {
        label:"Surt",
        "shortCode":"SR"
      },
      {
        label:"Ţarābulus",
        "shortCode":"TB"
      },
      {
        label:"Yafran",
        "shortCode":"WD"
      },
      {
        label:"Wādī ash Shāţiʾ",
        "shortCode":"WS"
      }
    ]
  },
  {
    label:"Liechtenstein",
    "countryShortCode":"LI",
    "regions":[
      {
        label:"Balzers",
        "shortCode":"01"
      },
      {
        label:"Eschen",
        "shortCode":"02"
      },
      {
        label:"Gamprin",
        "shortCode":"03"
      },
      {
        label:"Mauren",
        "shortCode":"04"
      },
      {
        label:"Planken",
        "shortCode":"05"
      },
      {
        label:"Ruggell",
        "shortCode":"06"
      },
      {
        label:"Schaan",
        "shortCode":"07"
      },
      {
        label:"Schellenberg",
        "shortCode":"08"
      },
      {
        label:"Triesen",
        "shortCode":"09"
      },
      {
        label:"Triesenberg",
        "shortCode":"10"
      },
      {
        label:"Vaduz",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Lithuania",
    "countryShortCode":"LT",
    "regions":[
      {
        label:"Alytaus",
        "shortCode":"AL"
      },
      {
        label:"Kauno",
        "shortCode":"KU"
      },
      {
        label:"Klaipėdos",
        "shortCode":"KL"
      },
      {
        label:"Marijampolės",
        "shortCode":"MR"
      },
      {
        label:"Panevėžio",
        "shortCode":"PN"
      },
      {
        label:"Šiaulių",
        "shortCode":"SA"
      },
      {
        label:"Tauragės",
        "shortCode":"TA"
      },
      {
        label:"Telšių",
        "shortCode":"TE"
      },
      {
        label:"Utenos",
        "shortCode":"UT"
      },
      {
        label:"Vilniaus",
        "shortCode":"VL"
      }
    ]
  },
  {
    label:"Luxembourg",
    "countryShortCode":"LU",
    "regions":[
      {
        label:"Capellen",
        "shortCode":"CA"
      },
      {
        label:"Clevaux",
        "shortCode":"CL"
      },
      {
        label:"Diekirch",
        "shortCode":"DI"
      },
      {
        label:"Echternach",
        "shortCode":"EC"
      },
      {
        label:"Esch-sur-Alzette",
        "shortCode":"ES"
      },
      {
        label:"Grevenmacher",
        "shortCode":"GR"
      },
      {
        label:"Luxembourg",
        "shortCode":"LU"
      },
      {
        label:"Mersch",
        "shortCode":"ME"
      },
      {
        label:"Redange",
        "shortCode":"RD"
      },
      {
        label:"Remich",
        "shortCode":"RM"
      },
      {
        label:"Vianden",
        "shortCode":"VD"
      },
      {
        label:"Wiltz",
        "shortCode":"WI"
      }
    ]
  },
  {
    label:"Macao",
    "countryShortCode":"MO",
    "regions":[
      {
        label:"Macao"
      }
    ]
  },
  {
    label:"Macedonia, Republic of",
    "countryShortCode":"MK",
    "regions":[
      {
        label:"Aračinovo",
        "shortCode":"02"
      },
      {
        label:"Berovo",
        "shortCode":"03"
      },
      {
        label:"Bitola",
        "shortCode":"04"
      },
      {
        label:"Bogdanci",
        "shortCode":"05"
      },
      {
        label:"Bogovinje",
        "shortCode":"06"
      },
      {
        label:"Bosilovo",
        "shortCode":"07"
      },
      {
        label:"Brvenica",
        "shortCode":"08"
      },
      {
        label:"Centar Župa",
        "shortCode":"78"
      },
      {
        label:"Čaška",
        "shortCode":"08"
      },
      {
        label:"Češinovo-Obleševo",
        "shortCode":"81"
      },
      {
        label:"Čučer Sandevo",
        "shortCode":"82"
      },
      {
        label:"Debar",
        "shortCode":"21"
      },
      {
        label:"Debarca",
        "shortCode":"22"
      },
      {
        label:"Delčevo",
        "shortCode":"23"
      },
      {
        label:"Demir Hisar",
        "shortCode":"25"
      },
      {
        label:"Demir Kapija",
        "shortCode":"24"
      },
      {
        label:"Doran",
        "shortCode":"26"
      },
      {
        label:"Dolneni",
        "shortCode":"27"
      },
      {
        label:"Gevgelija",
        "shortCode":"18"
      },
      {
        label:"Gostivar",
        "shortCode":"19"
      },
      {
        label:"Gradsko",
        "shortCode":"20"
      },
      {
        label:"Ilinden",
        "shortCode":"34"
      },
      {
        label:"Jegunovce",
        "shortCode":"35"
      },
      {
        label:"Karbinci",
        "shortCode":"37"
      },
      {
        label:"Kavadarci",
        "shortCode":"36"
      },
      {
        label:"Kičevo",
        "shortCode":"40"
      },
      {
        label:"Kočani",
        "shortCode":"42"
      },
      {
        label:"Konče",
        "shortCode":"41"
      },
      {
        label:"Kratovo",
        "shortCode":"43"
      },
      {
        label:"Kriva Palanka",
        "shortCode":"44"
      },
      {
        label:"Krivogaštani",
        "shortCode":"45"
      },
      {
        label:"Kruševo",
        "shortCode":"46"
      },
      {
        label:"Kumanovo",
        "shortCode":"47"
      },
      {
        label:"Lipkovo",
        "shortCode":"48"
      },
      {
        label:"Lozovo",
        "shortCode":"49"
      },
      {
        label:"Makedonska Kamenica",
        "shortCode":"51"
      },
      {
        label:"Makedonski Brod",
        "shortCode":"52"
      },
      {
        label:"Mavrovo i Rostuša",
        "shortCode":"50"
      },
      {
        label:"Mogila",
        "shortCode":"53"
      },
      {
        label:"Negotino",
        "shortCode":"54"
      },
      {
        label:"Novaci",
        "shortCode":"55"
      },
      {
        label:"Novo Selo",
        "shortCode":"56"
      },
      {
        label:"Ohrid",
        "shortCode":"58"
      },
      {
        label:"Pehčevo",
        "shortCode":"60"
      },
      {
        label:"Petrovec",
        "shortCode":"59"
      },
      {
        label:"Plasnica",
        "shortCode":"61"
      },
      {
        label:"Prilep",
        "shortCode":"62"
      },
      {
        label:"Probištip",
        "shortCode":"63"
      },
      {
        label:"Radoviš",
        "shortCode":""
      },
      {
        label:"Rankovce",
        "shortCode":"65"
      },
      {
        label:"Resen",
        "shortCode":"66"
      },
      {
        label:"Rosoman",
        "shortCode":"67"
      },
      {
        label:"Skopje",
        "shortCode":"85"
      },
      {
        label:"Sopište",
        "shortCode":"70"
      },
      {
        label:"Staro Nagoričane",
        "shortCode":"71"
      },
      {
        label:"Struga",
        "shortCode":"72"
      },
      {
        label:"Strumica",
        "shortCode":"73"
      },
      {
        label:"Studeničani",
        "shortCode":"74"
      },
      {
        label:"Sveti Nikole",
        "shortCode":"69"
      },
      {
        label:"Štip",
        "shortCode":"83"
      },
      {
        label:"Tearce",
        "shortCode":"75"
      },
      {
        label:"Tetovo",
        "shortCode":"76"
      },
      {
        label:"Valandovo",
        "shortCode":"10"
      },
      {
        label:"Vasilevo",
        "shortCode":"11"
      },
      {
        label:"Veles",
        "shortCode":"13"
      },
      {
        label:"Vevčani",
        "shortCode":"12"
      },
      {
        label:"Vinica",
        "shortCode":"14"
      },
      {
        label:"Vrapčište",
        "shortCode":"16"
      },
      {
        label:"Zelenikovo",
        "shortCode":"32"
      },
      {
        label:"Zrnovci",
        "shortCode":"33"
      },
      {
        label:"Želino",
        "shortCode":"30"
      }
    ]
  },
  {
    label:"Madagascar",
    "countryShortCode":"MG",
    "regions":[
      {
        label:"Antananarivo",
        "shortCode":"T"
      },
      {
        label:"Antsiranana",
        "shortCode":"D"
      },
      {
        label:"Fianarantsoa",
        "shortCode":"F"
      },
      {
        label:"Mahajanga",
        "shortCode":"M"
      },
      {
        label:"Toamasina",
        "shortCode":"A"
      },
      {
        label:"Toliara",
        "shortCode":"U"
      }
    ]
  },
  {
    label:"Malawi",
    "countryShortCode":"MW",
    "regions":[
      {
        label:"Balaka",
        "shortCode":"BA"
      },
      {
        label:"Blantyre",
        "shortCode":"BL"
      },
      {
        label:"Chikwawa",
        "shortCode":"CK"
      },
      {
        label:"Chiradzulu",
        "shortCode":"CR"
      },
      {
        label:"Chitipa",
        "shortCode":"CT"
      },
      {
        label:"Dedza",
        "shortCode":"DE"
      },
      {
        label:"Dowa",
        "shortCode":"DO"
      },
      {
        label:"Karonga",
        "shortCode":"KR"
      },
      {
        label:"Kasungu",
        "shortCode":"KS"
      },
      {
        label:"Likoma",
        "shortCode":"LK"
      },
      {
        label:"Lilongwe",
        "shortCode":"LI"
      },
      {
        label:"Machinga",
        "shortCode":"MH"
      },
      {
        label:"Mangochi",
        "shortCode":"MG"
      },
      {
        label:"Mchinji",
        "shortCode":"MC"
      },
      {
        label:"Mulanje",
        "shortCode":"MU"
      },
      {
        label:"Mwanza",
        "shortCode":"MW"
      },
      {
        label:"Mzimba",
        "shortCode":"MZ"
      },
      {
        label:"Nkhata Bay",
        "shortCode":"NE"
      },
      {
        label:"Nkhotakota",
        "shortCode":"NB"
      },
      {
        label:"Nsanje",
        "shortCode":"NS"
      },
      {
        label:"Ntcheu",
        "shortCode":"NU"
      },
      {
        label:"Ntchisi",
        "shortCode":"NI"
      },
      {
        label:"Phalombe",
        "shortCode":"PH"
      },
      {
        label:"Rumphi",
        "shortCode":"RU"
      },
      {
        label:"Salima",
        "shortCode":"SA"
      },
      {
        label:"Thyolo",
        "shortCode":"TH"
      },
      {
        label:"Zomba",
        "shortCode":"ZO"
      }
    ]
  },
  {
    label:"Malaysia",
    "countryShortCode":"MY",
    "regions":[
      {
        label:"Johor",
        "shortCode":"01"
      },
      {
        label:"Kedah",
        "shortCode":"02"
      },
      {
        label:"Kelantan",
        "shortCode":"03"
      },
      {
        label:"Melaka",
        "shortCode":"04"
      },
      {
        label:"Negeri Sembilan",
        "shortCode":"05"
      },
      {
        label:"Pahang",
        "shortCode":"06"
      },
      {
        label:"Perak",
        "shortCode":"08"
      },
      {
        label:"Perlis",
        "shortCode":"09"
      },
      {
        label:"Pulau Pinang",
        "shortCode":"07"
      },
      {
        label:"Sabah",
        "shortCode":"12"
      },
      {
        label:"Sarawak",
        "shortCode":"13"
      },
      {
        label:"Selangor",
        "shortCode":"10"
      },
      {
        label:"Terengganu",
        "shortCode":"11"
      },
      {
        label:"Wilayah Persekutuan (Kuala Lumpur)",
        "shortCode":"14"
      },
      {
        label:"Wilayah Persekutuan (Labuan)",
        "shortCode":"15"
      },
      {
        label:"Wilayah Persekutuan (Putrajaya)",
        "shortCode":"16"
      }
    ]
  },
  {
    label:"Maldives",
    "countryShortCode":"MV",
    "regions":[
      {
        label:"Alifu Alifu",
        "shortCode":"02"
      },
      {
        label:"Alifu Dhaalu",
        "shortCode":"00"
      },
      {
        label:"Baa",
        "shortCode":"20"
      },
      {
        label:"Dhaalu",
        "shortCode":"17"
      },
      {
        label:"Faafu",
        "shortCode":"14"
      },
      {
        label:"Gaafu Alifu",
        "shortCode":"27"
      },
      {
        label:"Gaafu Dhaalu",
        "shortCode":"28"
      },
      {
        label:"Gnaviyani",
        "shortCode":"29"
      },
      {
        label:"Haa Alifu",
        "shortCode":"07"
      },
      {
        label:"Haa Dhaalu",
        "shortCode":"23"
      },
      {
        label:"Kaafu",
        "shortCode":"29"
      },
      {
        label:"Laamu",
        "shortCode":"05"
      },
      {
        label:"Lhaviyani",
        "shortCode":"03"
      },
      {
        label:"Malé",
        "shortCode":"MLE"
      },
      {
        label:"Meemu",
        "shortCode":"12"
      },
      {
        label:"Noonu",
        "shortCode":"25"
      },
      {
        label:"Raa",
        "shortCode":"13"
      },
      {
        label:"Seenu",
        "shortCode":"01"
      },
      {
        label:"Shaviyani",
        "shortCode":"24"
      },
      {
        label:"Thaa",
        "shortCode":"08"
      },
      {
        label:"Vaavu",
        "shortCode":"04"
      }
    ]
  },
  {
    label:"Mali",
    "countryShortCode":"ML",
    "regions":[
      {
        label:"Bamako",
        "shortCode":"BKO"
      },
      {
        label:"Gao",
        "shortCode":"7"
      },
      {
        label:"Kayes",
        "shortCode":"1"
      },
      {
        label:"Kidal",
        "shortCode":"8"
      },
      {
        label:"Koulikoro",
        "shortCode":"2"
      },
      {
        label:"Mopti",
        "shortCode":"5"
      },
      {
        label:"Segou",
        "shortCode":"4"
      },
      {
        label:"Sikasso",
        "shortCode":"3"
      },
      {
        label:"Tombouctou",
        "shortCode":"6"
      }
    ]
  },
  {
    label:"Malta",
    "countryShortCode":"MT",
    "regions":[
      {
        label:"Attard",
        "shortCode":"01"
      },
      {
        label:"Balzan",
        "shortCode":"02"
      },
      {
        label:"Birgu",
        "shortCode":"03"
      },
      {
        label:"Birkirkara",
        "shortCode":"04"
      },
      {
        label:"Birżebbuġa",
        "shortCode":"05"
      },
      {
        label:"Bormla",
        "shortCode":"06"
      },
      {
        label:"Dingli",
        "shortCode":"07"
      },
      {
        label:"Fgura",
        "shortCode":"08"
      },
      {
        label:"Floriana",
        "shortCode":"09"
      },
      {
        label:"Fontana",
        "shortCode":"10"
      },
      {
        label:"Guda",
        "shortCode":"11"
      },
      {
        label:"Gżira",
        "shortCode":"12"
      },
      {
        label:"Għajnsielem",
        "shortCode":"13"
      },
      {
        label:"Għarb",
        "shortCode":"14"
      },
      {
        label:"Għargħur",
        "shortCode":"15"
      },
      {
        label:"Għasri",
        "shortCode":"16"
      },
      {
        label:"Għaxaq",
        "shortCode":"17"
      },
      {
        label:"Ħamrun",
        "shortCode":"18"
      },
      {
        label:"Iklin",
        "shortCode":"19"
      },
      {
        label:"Isla",
        "shortCode":"20"
      },
      {
        label:"Kalkara",
        "shortCode":"21"
      },
      {
        label:"Kerċem",
        "shortCode":"22"
      },
      {
        label:"Kirkop",
        "shortCode":"23"
      },
      {
        label:"Lija",
        "shortCode":"24"
      },
      {
        label:"Luqa",
        "shortCode":"25"
      },
      {
        label:"Marsa",
        "shortCode":"26"
      },
      {
        label:"Marsaskala",
        "shortCode":"27"
      },
      {
        label:"Marsaxlokk",
        "shortCode":"28"
      },
      {
        label:"Mdina",
        "shortCode":"29"
      },
      {
        label:"Mellieħa",
        "shortCode":"30"
      },
      {
        label:"Mġarr",
        "shortCode":"31"
      },
      {
        label:"Mosta",
        "shortCode":"32"
      },
      {
        label:"Mqabba",
        "shortCode":"33"
      },
      {
        label:"Msida",
        "shortCode":"34"
      },
      {
        label:"Mtarfa",
        "shortCode":"35"
      },
      {
        label:"Munxar",
        "shortCode":"36"
      },
      {
        label:"Nadur",
        "shortCode":"37"
      },
      {
        label:"Naxxar",
        "shortCode":"38"
      },
      {
        label:"Paola",
        "shortCode":"39"
      },
      {
        label:"Pembroke",
        "shortCode":"40"
      },
      {
        label:"Pietà",
        "shortCode":"41"
      },
      {
        label:"Qala",
        "shortCode":"42"
      },
      {
        label:"Qormi",
        "shortCode":"43"
      },
      {
        label:"Qrendi",
        "shortCode":"44"
      },
      {
        label:"Rabat Għawdex",
        "shortCode":"45"
      },
      {
        label:"Rabat Malta",
        "shortCode":"46"
      },
      {
        label:"Safi",
        "shortCode":"47"
      },
      {
        label:"San Ġiljan",
        "shortCode":"48"
      },
      {
        label:"San Ġwann",
        "shortCode":"49"
      },
      {
        label:"San Lawrenz",
        "shortCode":"50"
      },
      {
        label:"San Pawl il-Baħar",
        "shortCode":"51"
      },
      {
        label:"Sannat",
        "shortCode":"52"
      },
      {
        label:"Santa Luċija",
        "shortCode":"53"
      },
      {
        label:"Santa Venera",
        "shortCode":"54"
      },
      {
        label:"Siġġiewi",
        "shortCode":"55"
      },
      {
        label:"Sliema",
        "shortCode":"56"
      },
      {
        label:"Swieqi",
        "shortCode":"57"
      },
      {
        label:"Tai Xbiex",
        "shortCode":"58"
      },
      {
        label:"Tarzien",
        "shortCode":"59"
      },
      {
        label:"Valletta",
        "shortCode":"60"
      },
      {
        label:"Xagħra",
        "shortCode":"61"
      },
      {
        label:"Xewkija",
        "shortCode":"62"
      },
      {
        label:"Xgħajra",
        "shortCode":"63"
      },
      {
        label:"Żabbar",
        "shortCode":"64"
      },
      {
        label:"Żebbuġ Għawde",
        "shortCode":"65"
      },
      {
        label:"Żebbuġ Malta",
        "shortCode":"66"
      },
      {
        label:"Żejtun",
        "shortCode":"67"
      },
      {
        label:"Żurrieq",
        "shortCode":"68"
      }
    ]
  },
  {
    label:"Marshall Islands",
    "countryShortCode":"MH",
    "regions":[
      {
        label:"Ailinglaplap",
        "shortCode":"ALL"
      },
      {
        label:"Ailuk",
        "shortCode":"ALK"
      },
      {
        label:"Arno",
        "shortCode":"ARN"
      },
      {
        label:"Aur",
        "shortCode":"AUR"
      },
      {
        label:"Bikini and Kili",
        "shortCode":"KIL"
      },
      {
        label:"Ebon",
        "shortCode":"EBO"
      },
      {
        label:"Jabat",
        "shortCode":"JAB"
      },
      {
        label:"Jaluit",
        "shortCode":"JAL"
      },
      {
        label:"Kwajalein",
        "shortCode":"KWA"
      },
      {
        label:"Lae",
        "shortCode":"LAE"
      },
      {
        label:"Lib",
        "shortCode":"LIB"
      },
      {
        label:"Likiep",
        "shortCode":"LIK"
      },
      {
        label:"Majuro",
        "shortCode":"MAJ"
      },
      {
        label:"Maloelap",
        "shortCode":"MAL"
      },
      {
        label:"Mejit",
        "shortCode":"MEJ"
      },
      {
        label:"Namdrik",
        "shortCode":"NMK"
      },
      {
        label:"Namu",
        "shortCode":"NMU"
      },
      {
        label:"Rongelap",
        "shortCode":"RON"
      },
      {
        label:"Ujae",
        "shortCode":"UJA"
      },
      {
        label:"Utrik",
        "shortCode":"UTI"
      },
      {
        label:"Wotho",
        "shortCode":"WTH"
      },
      {
        label:"Wotje",
        "shortCode":"WTJ"
      }
    ]
  },
  {
    label:"Martinique",
    "countryShortCode":"MQ",
    "regions":[
      {
        label:"Martinique"
      }
    ]
  },
  {
    label:"Mauritania",
    "countryShortCode":"MR",
    "regions":[
      {
        label:"Adrar",
        "shortCode":"07"
      },
      {
        label:"Assaba",
        "shortCode":"03"
      },
      {
        label:"Brakna",
        "shortCode":"05"
      },
      {
        label:"Dakhlet Nouadhibou",
        "shortCode":"08"
      },
      {
        label:"Gorgol",
        "shortCode":"04"
      },
      {
        label:"Guidimaka",
        "shortCode":"10"
      },
      {
        label:"Hodh Ech Chargui",
        "shortCode":"01"
      },
      {
        label:"Hodh El Gharbi",
        "shortCode":"02"
      },
      {
        label:"Inchiri",
        "shortCode":"12"
      },
      {
        label:"Nouakchott Nord",
        "shortCode":"14"
      },
      {
        label:"Nouakchott Ouest",
        "shortCode":"13"
      },
      {
        label:"Nouakchott Sud",
        "shortCode":"15"
      },
      {
        label:"Tagant",
        "shortCode":"09"
      },
      {
        label:"Tiris Zemmour",
        "shortCode":"11"
      },
      {
        label:"Trarza",
        "shortCode":"06"
      }
    ]
  },
  {
    label:"Mauritius",
    "countryShortCode":"MU",
    "regions":[
      {
        label:"Agalega Islands",
        "shortCode":"AG"
      },
      {
        label:"Beau Bassin-Rose Hill",
        "shortCode":"BR"
      },
      {
        label:"Black River",
        "shortCode":"BL"
      },
      {
        label:"Cargados Carajos Shoals",
        "shortCode":"CC"
      },
      {
        label:"Curepipe",
        "shortCode":"CU"
      },
      {
        label:"Flacq",
        "shortCode":"FL"
      },
      {
        label:"Grand Port",
        "shortCode":"GP"
      },
      {
        label:"Moka",
        "shortCode":"MO"
      },
      {
        label:"Pamplemousses",
        "shortCode":"PA"
      },
      {
        label:"Plaines Wilhems",
        "shortCode":"PW"
      },
      {
        label:"Port Louis (City)",
        "shortCode":"PU"
      },
      {
        label:"Port Louis",
        "shortCode":"PL"
      },
      {
        label:"Riviere du Rempart",
        "shortCode":"RR"
      },
      {
        label:"Rodrigues Island",
        "shortCode":"RO"
      },
      {
        label:"Savanne",
        "shortCode":"SA"
      },
      {
        label:"Vacoas-Phoenix",
        "shortCode":"CP"
      }
    ]
  },
  {
    label:"Mayotte",
    "countryShortCode":"YT",
    "regions":[
      {
        label:"Dzaoudzi",
        "shortCode":"01"
      },
      {
        label:"Pamandzi",
        "shortCode":"02"
      },
      {
        label:"Mamoudzou",
        "shortCode":"03"
      },
      {
        label:"Dembeni",
        "shortCode":"04"
      },
      {
        label:"Bandrélé",
        "shortCode":"05"
      },
      {
        label:"Kani-Kéli",
        "shortCode":"06"
      },
      {
        label:"Bouéni",
        "shortCode":"07"
      },
      {
        label:"Chirongui",
        "shortCode":"08"
      },
      {
        label:"Sada",
        "shortCode":"09"
      },
      {
        label:"Ouangani",
        "shortCode":"10"
      },
      {
        label:"Chiconi",
        "shortCode":"11"
      },
      {
        label:"Tsingoni",
        "shortCode":"12"
      },
      {
        label:"M'Tsangamouji",
        "shortCode":"13"
      },
      {
        label:"Acoua",
        "shortCode":"14"
      },
      {
        label:"Mtsamboro",
        "shortCode":"15"
      },
      {
        label:"Bandraboua",
        "shortCode":"16"
      },
      {
        label:"Koungou",
        "shortCode":"17"
      }
    ]
  },
  {
    label:"Mexico",
    "countryShortCode":"MX",
    "regions":[
      {
        label:"Aguascalientes",
        "shortCode":"AGU"
      },
      {
        label:"Baja California",
        "shortCode":"BCN"
      },
      {
        label:"Baja California Sur",
        "shortCode":"BCS"
      },
      {
        label:"Campeche",
        "shortCode":"CAM"
      },
      {
        label:"Ciudad de México",
        "shortCode":"DIF"
      },
      {
        label:"Chiapas",
        "shortCode":"CHP"
      },
      {
        label:"Chihuahua",
        "shortCode":"CHH"
      },
      {
        label:"Coahuila de Zaragoza",
        "shortCode":"COA"
      },
      {
        label:"Colima",
        "shortCode":"COL"
      },
      {
        label:"Durango",
        "shortCode":"DUR"
      },
      {
        label:"Estado de México",
        "shortCode":"MEX"
      },
      {
        label:"Guanajuato",
        "shortCode":"GUA"
      },
      {
        label:"Guerrero",
        "shortCode":"GRO"
      },
      {
        label:"Hidalgo",
        "shortCode":"HID"
      },
      {
        label:"Jalisco",
        "shortCode":"JAL"
      },
      {
        label:"Michoacán de Ocampo",
        "shortCode":"MIC"
      },
      {
        label:"Morelos",
        "shortCode":"MOR"
      },
      {
        label:"Nayarit",
        "shortCode":"NAY"
      },
      {
        label:"Nuevo León",
        "shortCode":"NLE"
      },
      {
        label:"Oaxaca",
        "shortCode":"OAX"
      },
      {
        label:"Puebla",
        "shortCode":"PUE"
      },
      {
        label:"Querétaro de Arteaga",
        "shortCode":"QUE"
      },
      {
        label:"Quintana Roo",
        "shortCode":"ROO"
      },
      {
        label:"San Luis Potosí",
        "shortCode":"SLP"
      },
      {
        label:"Sinaloa",
        "shortCode":"SIN"
      },
      {
        label:"Sonora",
        "shortCode":"SON"
      },
      {
        label:"Tabasco",
        "shortCode":"TAB"
      },
      {
        label:"Tamaulipas",
        "shortCode":"TAM"
      },
      {
        label:"Tlaxcala",
        "shortCode":"TLA"
      },
      {
        label:"Veracruz",
        "shortCode":"VER"
      },
      {
        label:"Yucatán",
        "shortCode":"YUC"
      },
      {
        label:"Zacatecas",
        "shortCode":"ZAC"
      }
    ]
  },
  {
    label:"Micronesia, Federated States of",
    "countryShortCode":"FM",
    "regions":[
      {
        label:"Chuuk (Truk)",
        "shortCode":"TRK"
      },
      {
        label:"Kosrae",
        "shortCode":"KSA"
      },
      {
        label:"Pohnpei",
        "shortCode":"PNI"
      },
      {
        label:"Yap",
        "shortCode":"YAP"
      }
    ]
  },
  {
    label:"Moldova",
    "countryShortCode":"MD",
    "regions":[
      {
        label:"Aenii Noi",
        "shortCode":"AN"
      },
      {
        label:"Basarabeasca",
        "shortCode":"BS"
      },
      {
        label:"Bălți",
        "shortCode":"BA"
      },
      {
        label:"Bender",
        "shortCode":"BD"
      },
      {
        label:"Briceni",
        "shortCode":"BR"
      },
      {
        label:"Cahul",
        "shortCode":"CA"
      },
      {
        label:"Cantemir",
        "shortCode":"CT"
      },
      {
        label:"Călărași",
        "shortCode":"CL"
      },
      {
        label:"Căușeni",
        "shortCode":"CS"
      },
      {
        label:"Chișinău",
        "shortCode":"CU"
      },
      {
        label:"Cimișlia",
        "shortCode":"CM"
      },
      {
        label:"Criuleni",
        "shortCode":"CR"
      },
      {
        label:"Dondușeni",
        "shortCode":"DO"
      },
      {
        label:"Drochia",
        "shortCode":"DR"
      },
      {
        label:"Dubăsari",
        "shortCode":"DU"
      },
      {
        label:"Edineț",
        "shortCode":"ED"
      },
      {
        label:"Fălești",
        "shortCode":"FA"
      },
      {
        label:"Florești",
        "shortCode":"FL"
      },
      {
        label:"Găgăuzia",
        "shortCode":"GA"
      },
      {
        label:"Glodeni",
        "shortCode":"GL"
      },
      {
        label:"Hîncești",
        "shortCode":"HI"
      },
      {
        label:"Ialoveni",
        "shortCode":"IA"
      },
      {
        label:"Leova",
        "shortCode":"LE"
      },
      {
        label:"Nisporeni",
        "shortCode":"NI"
      },
      {
        label:"Ocnița",
        "shortCode":"OC"
      },
      {
        label:"Orhei",
        "shortCode":"OR"
      },
      {
        label:"Rezina",
        "shortCode":"RE"
      },
      {
        label:"Rîșcani",
        "shortCode":"RI"
      },
      {
        label:"Sîngerei",
        "shortCode":"SI"
      },
      {
        label:"Soroca",
        "shortCode":"SO"
      },
      {
        label:"Stînga Nistrului",
        "shortCode":"SN"
      },
      {
        label:"Strășeni",
        "shortCode":"ST"
      },
      {
        label:"Șoldănești",
        "shortCode":"SD"
      },
      {
        label:"Ștefan Vodă",
        "shortCode":"SV"
      },
      {
        label:"Taraclia",
        "shortCode":"TA"
      },
      {
        label:"Telenești",
        "shortCode":"TE"
      },
      {
        label:"Ungheni",
        "shortCode":"UN"
      }
    ]
  },
  {
    label:"Monaco",
    "countryShortCode":"MC",
    "regions":[
      {
        label:"Colle",
        "shortCode":"CL"
      },
      {
        label:"Condamine",
        "shortCode":"CO"
      },
      {
        label:"Fontvieille",
        "shortCode":"FO"
      },
      {
        label:"Gare",
        "shortCode":"GA"
      },
      {
        label:"Jardin Exotique",
        "shortCode":"JE"
      },
      {
        label:"Larvotto",
        "shortCode":"LA"
      },
      {
        label:"Malbousquet",
        "shortCode":"MA"
      },
      {
        label:"Monaco-Ville",
        "shortCode":"MO"
      },
      {
        label:"Moneghetti",
        "shortCode":"MG"
      },
      {
        label:"Monte-Carlo",
        "shortCode":"MC"
      },
      {
        label:"Moulins",
        "shortCode":"MU"
      },
      {
        label:"Port-Hercule",
        "shortCode":"PH"
      },
      {
        label:"Saint-Roman",
        "shortCode":"SR"
      },
      {
        label:"Sainte-Dévote",
        "shortCode":"SD"
      },
      {
        label:"Source",
        "shortCode":"SO"
      },
      {
        label:"Spélugues",
        "shortCode":"SP"
      },
      {
        label:"Vallon de la Rousse",
        "shortCode":"VR"
      }
    ]
  },
  {
    label:"Mongolia",
    "countryShortCode":"MN",
    "regions":[
      {
        label:"Arhangay",
        "shortCode":"073"
      },
      {
        label:"Bayan-Olgiy",
        "shortCode":"071"
      },
      {
        label:"Bayanhongor",
        "shortCode":"069"
      },
      {
        label:"Bulgan",
        "shortCode":"067"
      },
      {
        label:"Darhan",
        "shortCode":"037"
      },
      {
        label:"Dornod",
        "shortCode":"061"
      },
      {
        label:"Dornogovi",
        "shortCode":"063"
      },
      {
        label:"Dundgovi",
        "shortCode":"059"
      },
      {
        label:"Dzavhan",
        "shortCode":"065"
      },
      {
        label:"Govi-Altay",
        "shortCode":"065"
      },
      {
        label:"Govi-Sumber",
        "shortCode":"064"
      },
      {
        label:"Hovd",
        "shortCode":"043"
      },
      {
        label:"Hovsgol",
        "shortCode":"041"
      },
      {
        label:"Omnogovi",
        "shortCode":"053"
      },
      {
        label:"Ovorhangay",
        "shortCode":"055"
      },
      {
        label:"Selenge",
        "shortCode":"049"
      },
      {
        label:"Suhbaatar",
        "shortCode":"051"
      },
      {
        label:"Tov",
        "shortCode":"047"
      },
      {
        label:"Ulaanbaatar",
        "shortCode":"1"
      },
      {
        label:"Uvs",
        "shortCode":"046"
      }
    ]
  },
  {
    label:"Montenegro",
    "countryShortCode":"ME",
    "regions":[
      {
        label:"Andrijevica",
        "shortCode":"01"
      },
      {
        label:"Bar",
        "shortCode":"02"
      },
      {
        label:"Berane",
        "shortCode":"03"
      },
      {
        label:"Bijelo Polje",
        "shortCode":"04"
      },
      {
        label:"Budva",
        "shortCode":"05"
      },
      {
        label:"Cetinje",
        "shortCode":"06"
      },
      {
        label:"Danilovgrad",
        "shortCode":"07"
      },
      {
        label:"Gusinje",
        "shortCode":"22"
      },
      {
        label:"Herceg Novi",
        "shortCode":"08"
      },
      {
        label:"Kolašin",
        "shortCode":"09"
      },
      {
        label:"Kotor",
        "shortCode":"10"
      },
      {
        label:"Mojkovac",
        "shortCode":"11"
      },
      {
        label:"Nikšić",
        "shortCode":"12"
      },
      {
        label:"Petnica",
        "shortCode":"23"
      },
      {
        label:"Plav",
        "shortCode":"13"
      },
      {
        label:"Plužine",
        "shortCode":"14"
      },
      {
        label:"Pljevlja",
        "shortCode":"15"
      },
      {
        label:"Podgorica",
        "shortCode":"16"
      },
      {
        label:"Rožaje",
        "shortCode":"17"
      },
      {
        label:"Šavnik",
        "shortCode":"18"
      },
      {
        label:"Tivat",
        "shortCode":"19"
      },
      {
        label:"Ulcinj",
        "shortCode":"20"
      },
      {
        label:"Žabljak",
        "shortCode":"21"
      }
    ]
  },
  {
    label:"Montserrat",
    "countryShortCode":"MS",
    "regions":[
      {
        label:"Saint Anthony"
      },
      {
        label:"Saint Georges"
      },
      {
        label:"Saint Peter's"
      }
    ]
  },
  {
    label:"Morocco",
    "countryShortCode":"MA",
    "regions":[
      {
        label:"Chaouia-Ouardigha",
        "shortCode":"09"
      },
      {
        label:"Doukhala-Abda",
        "shortCode":"10"
      },
      {
        label:"Fès-Boulemane",
        "shortCode":"05"
      },
      {
        label:"Gharb-Chrarda-Beni Hssen",
        "shortCode":"02"
      },
      {
        label:"Grand Casablanca",
        "shortCode":"08"
      },
      {
        label:"Guelmim-Es Semara",
        "shortCode":"14"
      },
      {
        label:"Laâyoune-Boujdour-Sakia el Hamra",
        "shortCode":"15"
      },
      {
        label:"Marrakech-Tensift-Al Haouz",
        "shortCode":"11"
      },
      {
        label:"Meknès-Tafilalet",
        "shortCode":"06"
      },
      {
        label:"Oriental",
        "shortCode":"04"
      },
      {
        label:"Oued ed Dahab-Lagouira",
        "shortCode":"16"
      },
      {
        label:"Souss-Massa-Drâa",
        "shortCode":"13"
      },
      {
        label:"Tadla-Azilal",
        "shortCode":"12"
      },
      {
        label:"Tanger-Tétouan",
        "shortCode":"01"
      },
      {
        label:"Taza-Al Hoceima-Taounate",
        "shortCode":"03"
      }
    ]
  },
  {
    label:"Mozambique",
    "countryShortCode":"MZ",
    "regions":[
      {
        label:"Cabo Delgado",
        "shortCode":"P"
      },
      {
        label:"Gaza",
        "shortCode":"G"
      },
      {
        label:"Inhambane",
        "shortCode":"I"
      },
      {
        label:"Manica",
        "shortCode":"B"
      },
      {
        label:"Maputo",
        "shortCode":"L"
      },
      {
        label:"Maputo (City)",
        "shortCode":"MPM"
      },
      {
        label:"Nampula",
        "shortCode":"N"
      },
      {
        label:"Niassa",
        "shortCode":"A"
      },
      {
        label:"Sofala",
        "shortCode":"S"
      },
      {
        label:"Tete",
        "shortCode":"T"
      },
      {
        label:"Zambezia",
        "shortCode":"Q"
      }
    ]
  },
  {
    label:"Myanmar",
    "countryShortCode":"MM",
    "regions":[
      {
        label:"Ayeyarwady",
        "shortCode":"07"
      },
      {
        label:"Bago",
        "shortCode":"02"
      },
      {
        label:"Chin",
        "shortCode":"14"
      },
      {
        label:"Kachin",
        "shortCode":"11"
      },
      {
        label:"Kayah",
        "shortCode":"12"
      },
      {
        label:"Kayin",
        "shortCode":"13"
      },
      {
        label:"Magway",
        "shortCode":"03"
      },
      {
        label:"Mandalay",
        "shortCode":"04"
      },
      {
        label:"Mon",
        "shortCode":"15"
      },
      {
        label:"Nay Pyi Taw",
        "shortCode":"18"
      },
      {
        label:"Rakhine",
        "shortCode":"16"
      },
      {
        label:"Sagaing",
        "shortCode":"01"
      },
      {
        label:"Shan",
        "shortCode":"17"
      },
      {
        label:"Tanintharyi",
        "shortCode":"05"
      },
      {
        label:"Yangon",
        "shortCode":"06"
      }
    ]
  },
  {
    label:"Namibia",
    "countryShortCode":"NA",
    "regions":[
      {
        label:"Erongo",
        "shortCode":"ER"
      },
      {
        label:"Hardap",
        "shortCode":"HA"
      },
      {
        label:"Kavango East",
        "shortCode":"KE"
      },
      {
        label:"Kavango West",
        "shortCode":"KW"
      },
      {
        label:"Karas",
        "shortCode":"KA"
      },
      {
        label:"Khomas",
        "shortCode":"KH"
      },
      {
        label:"Kunene",
        "shortCode":"KU"
      },
      {
        label:"Ohangwena",
        "shortCode":"OW"
      },
      {
        label:"Omaheke",
        "shortCode":"OH"
      },
      {
        label:"Omusati",
        "shortCode":"OS"
      },
      {
        label:"Oshana",
        "shortCode":"ON"
      },
      {
        label:"Oshikoto",
        "shortCode":"OT"
      },
      {
        label:"Otjozondjupa",
        "shortCode":"OD"
      },
      {
        label:"Zambezi",
        "shortCode":"CA"
      }
    ]
  },
  {
    label:"Nauru",
    "countryShortCode":"NR",
    "regions":[
      {
        label:"Aiwo",
        "shortCode":"01"
      },
      {
        label:"Anabar",
        "shortCode":"02"
      },
      {
        label:"Anetan",
        "shortCode":"03"
      },
      {
        label:"Anibare",
        "shortCode":"04"
      },
      {
        label:"Baiti",
        "shortCode":"05"
      },
      {
        label:"Boe",
        "shortCode":"06"
      },
      {
        label:"Buada",
        "shortCode":"07"
      },
      {
        label:"Denigomodu",
        "shortCode":"08"
      },
      {
        label:"Ewa",
        "shortCode":"09"
      },
      {
        label:"Ijuw",
        "shortCode":"10"
      },
      {
        label:"Meneng",
        "shortCode":"11"
      },
      {
        label:"Nibok",
        "shortCode":"12"
      },
      {
        label:"Uaboe",
        "shortCode":"13"
      },
      {
        label:"Yaren",
        "shortCode":"14"
      }
    ]
  },
  {
    label:"Nepal",
    "countryShortCode":"NP",
    "regions":[
      {
        label:"Bagmati",
        "shortCode":"BA"
      },
      {
        label:"Bheri",
        "shortCode":"BH"
      },
      {
        label:"Dhawalagiri",
        "shortCode":"DH"
      },
      {
        label:"Gandaki",
        "shortCode":"GA"
      },
      {
        label:"Janakpur",
        "shortCode":"JA"
      },
      {
        label:"Karnali",
        "shortCode":"KA"
      },
      {
        label:"Kosi",
        "shortCode":"KO"
      },
      {
        label:"Lumbini",
        "shortCode":"LU"
      },
      {
        label:"Mahakali",
        "shortCode":"MA"
      },
      {
        label:"Mechi",
        "shortCode":"ME"
      },
      {
        label:"Narayani",
        "shortCode":"NA"
      },
      {
        label:"Rapti",
        "shortCode":"RA"
      },
      {
        label:"Sagarmatha",
        "shortCode":"SA"
      },
      {
        label:"Seti",
        "shortCode":"SE"
      }
    ]
  },
  {
    label:"Netherlands",
    "countryShortCode":"NL",
    "regions":[
      {
        label:"Drenthe",
        "shortCode":"DR"
      },
      {
        label:"Flevoland",
        "shortCode":"FL"
      },
      {
        label:"Friesland",
        "shortCode":"FR"
      },
      {
        label:"Gelderland",
        "shortCode":"GE"
      },
      {
        label:"Groningen",
        "shortCode":"GR"
      },
      {
        label:"Limburg",
        "shortCode":"LI"
      },
      {
        label:"Noord-Brabant",
        "shortCode":"NB"
      },
      {
        label:"Noord-Holland",
        "shortCode":"NH"
      },
      {
        label:"Overijssel",
        "shortCode":"OV"
      },
      {
        label:"Utrecht",
        "shortCode":"UT"
      },
      {
        label:"Zeeland",
        "shortCode":"ZE"
      },
      {
        label:"Zuid-Holland",
        "shortCode":"ZH"
      }
    ]
  },
  {
    label:"New Caledonia",
    "countryShortCode":"NC",
    "regions":[
      {
        label:"Iles Loyaute"
      },
      {
        label:"Nord"
      },
      {
        label:"Sud"
      }
    ]
  },
  {
    label:"New Zealand",
    "countryShortCode":"NZ",
    "regions":[
      {
        label:"Auckland",
        "shortCode":"AUK"
      },
      {
        label:"Bay of Plenty",
        "shortCode":"BOP"
      },
      {
        label:"Canterbury",
        "shortCode":"CAN"
      },
      {
        label:"Gisborne",
        "shortCode":"GIS"
      },
      {
        label:"Hawke's Bay",
        "shortCode":"HKB"
      },
      {
        label:"Marlborough",
        "shortCode":"MBH"
      },
      {
        label:"Manawatu-Wanganui",
        "shortCode":"MWT"
      },
      {
        label:"Northland",
        "shortCode":"NTL"
      },
      {
        label:"Nelson",
        "shortCode":"NSN"
      },
      {
        label:"Otago",
        "shortCode":"OTA"
      },
      {
        label:"Southland",
        "shortCode":"STL"
      },
      {
        label:"Taranaki",
        "shortCode":"TKI"
      },
      {
        label:"Tasman",
        "shortCode":"TAS"
      },
      {
        label:"Waikato",
        "shortCode":"WKO"
      },
      {
        label:"Wellington",
        "shortCode":"WGN"
      },
      {
        label:"West Coast",
        "shortCode":"WTC"
      },
      {
        label:"Chatham Islands Territory",
        "shortCode":"CIT"
      }
    ]
  },
  {
    label:"Nicaragua",
    "countryShortCode":"NI",
    "regions":[
      {
        label:"Boaco",
        "shortCode":"BO"
      },
      {
        label:"Carazo",
        "shortCode":"CA"
      },
      {
        label:"Chinandega",
        "shortCode":"CI"
      },
      {
        label:"Chontales",
        "shortCode":"CO"
      },
      {
        label:"Estelí",
        "shortCode":"ES"
      },
      {
        label:"Granada",
        "shortCode":"GR"
      },
      {
        label:"Jinotega",
        "shortCode":"JI"
      },
      {
        label:"León",
        "shortCode":"LE"
      },
      {
        label:"Madriz",
        "shortCode":"MD"
      },
      {
        label:"Managua",
        "shortCode":"MN"
      },
      {
        label:"Masaya",
        "shortCode":"MS"
      },
      {
        label:"Matagalpa",
        "shortCode":"MT"
      },
      {
        label:"Nueva Segovia",
        "shortCode":"NS"
      },
      {
        label:"Río San Juan",
        "shortCode":"SJ"
      },
      {
        label:"Rivas",
        "shortCode":"RI"
      },
      {
        label:"Atlántico Norte",
        "shortCode":"AN"
      },
      {
        label:"Atlántico Sur",
        "shortCode":"AS"
      }
    ]
  },
  {
    label:"Niger",
    "countryShortCode":"NE",
    "regions":[
      {
        label:"Agadez",
        "shortCode":"1"
      },
      {
        label:"Diffa",
        "shortCode":"2"
      },
      {
        label:"Dosso",
        "shortCode":"3"
      },
      {
        label:"Maradi",
        "shortCode":"4"
      },
      {
        label:"Niamey",
        "shortCode":"8"
      },
      {
        label:"Tahoua",
        "shortCode":"5"
      },
      {
        label:"Tillabéri",
        "shortCode":"6"
      },
      {
        label:"Zinder",
        "shortCode":"7"
      }
    ]
  },
  {
    label:"Nigeria",
    "countryShortCode":"NG",
    "regions":[
      {
        label:"Abia",
        "shortCode":"AB"
      },
      {
        label:"Abuja Federal Capital Territory",
        "shortCode":"FC"
      },
      {
        label:"Adamawa",
        "shortCode":"AD"
      },
      {
        label:"Akwa Ibom",
        "shortCode":"AK"
      },
      {
        label:"Anambra",
        "shortCode":"AN"
      },
      {
        label:"Bauchi",
        "shortCode":"BA"
      },
      {
        label:"Bayelsa",
        "shortCode":"BY"
      },
      {
        label:"Benue",
        "shortCode":"BE"
      },
      {
        label:"Borno",
        "shortCode":"BO"
      },
      {
        label:"Cross River",
        "shortCode":"CR"
      },
      {
        label:"Delta",
        "shortCode":"DE"
      },
      {
        label:"Ebonyi",
        "shortCode":"EB"
      },
      {
        label:"Edo",
        "shortCode":"ED"
      },
      {
        label:"Ekiti",
        "shortCode":"EK"
      },
      {
        label:"Enugu",
        "shortCode":"EN"
      },
      {
        label:"Gombe",
        "shortCode":"GO"
      },
      {
        label:"Imo",
        "shortCode":"IM"
      },
      {
        label:"Jigawa",
        "shortCode":"JI"
      },
      {
        label:"Kaduna",
        "shortCode":"KD"
      },
      {
        label:"Kano",
        "shortCode":"KN"
      },
      {
        label:"Katsina",
        "shortCode":"KT"
      },
      {
        label:"Kebbi",
        "shortCode":"KE"
      },
      {
        label:"Kogi",
        "shortCode":"KO"
      },
      {
        label:"Kwara",
        "shortCode":"KW"
      },
      {
        label:"Lagos",
        "shortCode":"LA"
      },
      {
        label:"Nassarawa",
        "shortCode":"NA"
      },
      {
        label:"Niger",
        "shortCode":"NI"
      },
      {
        label:"Ogun",
        "shortCode":"OG"
      },
      {
        label:"Ondo",
        "shortCode":"ON"
      },
      {
        label:"Osun",
        "shortCode":"OS"
      },
      {
        label:"Oyo",
        "shortCode":"OY"
      },
      {
        label:"Plateau",
        "shortCode":"PL"
      },
      {
        label:"Rivers",
        "shortCode":"RI"
      },
      {
        label:"Sokoto",
        "shortCode":"SO"
      },
      {
        label:"Taraba",
        "shortCode":"TA"
      },
      {
        label:"Yobe",
        "shortCode":"YO"
      },
      {
        label:"Zamfara",
        "shortCode":"ZA"
      }
    ]
  },
  {
    label:"Niue",
    "countryShortCode":"NU",
    "regions":[
      {
        label:"Niue"
      }
    ]
  },
  {
    label:"Norfolk Island",
    "countryShortCode":"NF",
    "regions":[
      {
        label:"Norfolk Island"
      }
    ]
  },
  {
    label:"Northern Mariana Islands",
    "countryShortCode":"MP",
    "regions":[
      {
        label:"Northern Islands"
      },
      {
        label:"Rota"
      },
      {
        label:"Saipan"
      },
      {
        label:"Tinian"
      }
    ]
  },
  {
    label:"Norway",
    "countryShortCode":"NO",
    "regions":[
      {
        label:"Akershus",
        "shortCode":"02"
      },
      {
        label:"Aust-Agder",
        "shortCode":"09"
      },
      {
        label:"Buskerud",
        "shortCode":"06"
      },
      {
        label:"Finnmark",
        "shortCode":"20"
      },
      {
        label:"Hedmark",
        "shortCode":"04"
      },
      {
        label:"Hordaland",
        "shortCode":"12"
      },
      {
        label:"Møre og Romsdal",
        "shortCode":"15"
      },
      {
        label:"Nordland",
        "shortCode":"18"
      },
      {
        label:"Nord-Trøndelag",
        "shortCode":"17"
      },
      {
        label:"Oppland",
        "shortCode":"05"
      },
      {
        label:"Oslo",
        "shortCode":"03"
      },
      {
        label:"Rogaland",
        "shortCode":"11"
      },
      {
        label:"Sogn og Fjordane",
        "shortCode":"14"
      },
      {
        label:"Sør-Trøndelag",
        "shortCode":"16"
      },
      {
        label:"Telemark",
        "shortCode":"08"
      },
      {
        label:"Troms",
        "shortCode":"19"
      },
      {
        label:"Vest-Agder",
        "shortCode":"10"
      },
      {
        label:"Vestfold",
        "shortCode":"07"
      },
      {
        label:"Østfold",
        "shortCode":"01"
      },
      {
        label:"Jan Mayen",
        "shortCode":"22"
      },
      {
        label:"Svalbard",
        "shortCode":"21"
      }
    ]
  },
  {
    label:"Oman",
    "countryShortCode":"OM",
    "regions":[
      {
        label:"Ad Dakhiliyah",
        "shortCode":"DA"
      },
      {
        label:"Al Buraymi",
        "shortCode":"BU"
      },
      {
        label:"Al Wusta",
        "shortCode":"WU"
      },
      {
        label:"Az Zahirah",
        "shortCode":"ZA"
      },
      {
        label:"Janub al Batinah",
        "shortCode":"BS"
      },
      {
        label:"Janub ash Sharqiyah",
        "shortCode":"SS"
      },
      {
        label:"Masqat",
        "shortCode":"MA"
      },
      {
        label:"Musandam",
        "shortCode":"MU"
      },
      {
        label:"Shamal al Batinah",
        "shortCode":"BJ"
      },
      {
        label:"Shamal ash Sharqiyah",
        "shortCode":"SJ"
      },
      {
        label:"Zufar",
        "shortCode":"ZU"
      }
    ]
  },
  {
    label:"Pakistan",
    "countryShortCode":"PK",
    "regions":[
      {
        label:"Āzād Kashmīr",
        "shortCode":"JK"
      },
      {
        label:"Balōchistān",
        "shortCode":"BA"
      },
      {
        label:"Gilgit-Baltistān",
        "shortCode":"GB"
      },
      {
        label:"Islāmābād",
        "shortCode":"IS"
      },
      {
        label:"Khaībar Pakhtūnkhwās",
        "shortCode":"KP"
      },
      {
        label:"Punjāb",
        "shortCode":"PB"
      },
      {
        label:"Sindh",
        "shortCode":"SD"
      },
      {
        label:"Federally Administered Tribal Areas",
        "shortCode":"TA"
      }
    ]
  },
  {
    label:"Palau",
    "countryShortCode":"PW",
    "regions":[
      {
        label:"Aimeliik",
        "shortCode":"002"
      },
      {
        label:"Airai",
        "shortCode":"004"
      },
      {
        label:"Angaur",
        "shortCode":"010"
      },
      {
        label:"Hatobohei",
        "shortCode":"050"
      },
      {
        label:"Kayangel",
        "shortCode":"100"
      },
      {
        label:"Koror",
        "shortCode":"150"
      },
      {
        label:"Melekeok",
        "shortCode":"212"
      },
      {
        label:"Ngaraard",
        "shortCode":"214"
      },
      {
        label:"Ngarchelong",
        "shortCode":"218"
      },
      {
        label:"Ngardmau",
        "shortCode":"222"
      },
      {
        label:"Ngatpang",
        "shortCode":"224"
      },
      {
        label:"Ngchesar",
        "shortCode":"226"
      },
      {
        label:"Ngeremlengui",
        "shortCode":"227"
      },
      {
        label:"Ngiwal",
        "shortCode":"228"
      },
      {
        label:"Peleliu",
        "shortCode":"350"
      },
      {
        label:"Sonsoral",
        "shortCode":"350"
      }
    ]
  },
  {
    label:"Palestine, State of",
    "countryShortCode":"PS",
    "regions":[
      {
        label:"Ak Khalīl",
        "shortCode":"HBN"
      },
      {
        label:"Al Quds",
        "shortCode":"JEM"
      },
      {
        label:"Arīḩā wal Aghwār",
        "shortCode":"JRH"
      },
      {
        label:"Bayt Laḩm",
        "shortCode":"BTH"
      },
      {
        label:"Dayr al Balaḩ",
        "shortCode":"DEB"
      },
      {
        label:"Ghazzah",
        "shortCode":"GZA"
      },
      {
        label:"Janīn",
        "shortCode":"JEN"
      },
      {
        label:"Khān Yūnis",
        "shortCode":"KYS"
      },
      {
        label:"Nāblus",
        "shortCode":"NBS"
      },
      {
        label:"Qalqīyah",
        "shortCode":"QQA"
      },
      {
        label:"Rafaḩ",
        "shortCode":"RFH"
      },
      {
        label:"Rām Allāh wal Bīrah",
        "shortCode":"RBH"
      },
      {
        label:"Salfīt",
        "shortCode":"SLT"
      },
      {
        label:"Shamāl Ghazzah",
        "shortCode":"NGZ"
      },
      {
        label:"Ţūbās",
        "shortCode":"TBS"
      },
      {
        label:"Ţūlkarm",
        "shortCode":"TKM"
      }
    ]
  },
  {
    label:"Panama",
    "countryShortCode":"PA",
    "regions":[
      {
        label:"Bocas del Toro",
        "shortCode":"1"
      },
      {
        label:"Chiriquí",
        "shortCode":"4"
      },
      {
        label:"Coclé",
        "shortCode":"2"
      },
      {
        label:"Colón",
        "shortCode":"3"
      },
      {
        label:"Darién",
        "shortCode":"5"
      },
      {
        label:"Emberá",
        "shortCode":"EM"
      },
      {
        label:"Herrera",
        "shortCode":"6"
      },
      {
        label:"Kuna Yala",
        "shortCode":"KY"
      },
      {
        label:"Los Santos",
        "shortCode":"7"
      },
      {
        label:"Ngäbe-Buglé",
        "shortCode":"NB"
      },
      {
        label:"Panamá",
        "shortCode":"8"
      },
      {
        label:"Panamá Oeste",
        "shortCode":"10"
      },
      {
        label:"Veraguas",
        "shortCode":"9"
      }
    ]
  },
  {
    label:"Papua New Guinea",
    "countryShortCode":"PG",
    "regions":[
      {
        label:"Bougainville",
        "shortCode":"NSB"
      },
      {
        label:"Central",
        "shortCode":"CPM"
      },
      {
        label:"Chimbu",
        "shortCode":"CPK"
      },
      {
        label:"East New Britain",
        "shortCode":"EBR"
      },
      {
        label:"East Sepik",
        "shortCode":"ESW"
      },
      {
        label:"Eastern Highlands",
        "shortCode":"EHG"
      },
      {
        label:"Enga",
        "shortCode":"EPW"
      },
      {
        label:"Gulf",
        "shortCode":"GPK"
      },
      {
        label:"Hela",
        "shortCode":"HLA"
      },
      {
        label:"Jiwaka",
        "shortCode":"JWK"
      },
      {
        label:"Madang",
        "shortCode":"MOM"
      },
      {
        label:"Manus",
        "shortCode":"MRL"
      },
      {
        label:"Milne Bay",
        "shortCode":"MBA"
      },
      {
        label:"Morobe",
        "shortCode":"MPL"
      },
      {
        label:"Port Moresby",
        "shortCode":"NCD"
      },
      {
        label:"New Ireland",
        "shortCode":"NIK"
      },
      {
        label:"Northern",
        "shortCode":"NPP"
      },
      {
        label:"Southern Highlands",
        "shortCode":"SHM"
      },
      {
        label:"West New Britain",
        "shortCode":"WBK"
      },
      {
        label:"West Sepik",
        "shortCode":"SAN"
      },
      {
        label:"Western",
        "shortCode":"WPD"
      },
      {
        label:"Western Highlands",
        "shortCode":"WHM"
      }
    ]
  },
  {
    label:"Paraguay",
    "countryShortCode":"PY",
    "regions":[
      {
        label:"Alto Paraguay",
        "shortCode":"16"
      },
      {
        label:"Alto Parana",
        "shortCode":"10"
      },
      {
        label:"Amambay",
        "shortCode":"13"
      },
      {
        label:"Asuncion",
        "shortCode":"ASU"
      },
      {
        label:"Caaguazu",
        "shortCode":"5"
      },
      {
        label:"Caazapa",
        "shortCode":"6"
      },
      {
        label:"Canindeyu",
        "shortCode":"14"
      },
      {
        label:"Central",
        "shortCode":"11"
      },
      {
        label:"Concepcion",
        "shortCode":"1"
      },
      {
        label:"Cordillera",
        "shortCode":"3"
      },
      {
        label:"Guaira",
        "shortCode":"4"
      },
      {
        label:"Itapua",
        "shortCode":"7"
      },
      {
        label:"Misiones",
        "shortCode":"8"
      },
      {
        label:"Neembucu",
        "shortCode":"12"
      },
      {
        label:"Paraguari",
        "shortCode":"9"
      },
      {
        label:"Presidente Hayes",
        "shortCode":"15"
      },
      {
        label:"San Pedro",
        "shortCode":"2"
      }
    ]
  },
  {
    label:"Peru",
    "countryShortCode":"PE",
    "regions":[
      {
        label:"Amazonas",
        "shortCode":"AMA"
      },
      {
        label:"Ancash",
        "shortCode":"ANC"
      },
      {
        label:"Apurimac",
        "shortCode":"APU"
      },
      {
        label:"Arequipa",
        "shortCode":"ARE"
      },
      {
        label:"Ayacucho",
        "shortCode":"AYA"
      },
      {
        label:"Cajamarca",
        "shortCode":"CAJ"
      },
      {
        label:"Callao",
        "shortCode":"CAL"
      },
      {
        label:"Cusco",
        "shortCode":"CUS"
      },
      {
        label:"Huancavelica",
        "shortCode":"HUV"
      },
      {
        label:"Huanuco",
        "shortCode":"HUC"
      },
      {
        label:"Ica",
        "shortCode":"ICA"
      },
      {
        label:"Junin",
        "shortCode":"JUN"
      },
      {
        label:"La Libertad",
        "shortCode":"LAL"
      },
      {
        label:"Lambayeque",
        "shortCode":"LAM"
      },
      {
        label:"Lima",
        "shortCode":"LIM"
      },
      {
        label:"Loreto",
        "shortCode":"LOR"
      },
      {
        label:"Madre de Dios",
        "shortCode":"MDD"
      },
      {
        label:"Moquegua",
        "shortCode":"MOQ"
      },
      {
        label:"Municipalidad Metropolitana de Lima",
        "shortCode":"LMA"
      },
      {
        label:"Pasco",
        "shortCode":"PAS"
      },
      {
        label:"Piura",
        "shortCode":"PIU"
      },
      {
        label:"Puno",
        "shortCode":"PUN"
      },
      {
        label:"San Martin",
        "shortCode":"SAM"
      },
      {
        label:"Tacna",
        "shortCode":"TAC"
      },
      {
        label:"Tumbes",
        "shortCode":"TUM"
      },
      {
        label:"Ucayali",
        "shortCode":"UCA"
      }
    ]
  },
  {
    label:"Philippines",
    "countryShortCode":"PH",
    "regions":[
      {
        label:"Abra",
        "shortCode":"ABR"
      },
      {
        label:"Agusan del Norte",
        "shortCode":"AGN"
      },
      {
        label:"Agusan del Sur",
        "shortCode":"AGS"
      },
      {
        label:"Aklan",
        "shortCode":"AKL"
      },
      {
        label:"Albay",
        "shortCode":"ALB"
      },
      {
        label:"Antique",
        "shortCode":"ANT"
      },
      {
        label:"Apayao",
        "shortCode":"APA"
      },
      {
        label:"Aurora",
        "shortCode":"AUR"
      },
      {
        label:"Basilan",
        "shortCode":"BAS"
      },
      {
        label:"Bataan",
        "shortCode":"BAN"
      },
      {
        label:"Batanes",
        "shortCode":"BTN"
      },
      {
        label:"Batangas",
        "shortCode":"BTG"
      },
      {
        label:"Benguet",
        "shortCode":"BEN"
      },
      {
        label:"Biliran",
        "shortCode":"BIL"
      },
      {
        label:"Bohol",
        "shortCode":"BOH"
      },
      {
        label:"Bukidnon",
        "shortCode":"BUK"
      },
      {
        label:"Bulacan",
        "shortCode":"BUL"
      },
      {
        label:"Cagayan",
        "shortCode":"CAG"
      },
      {
        label:"Camarines Norte",
        "shortCode":"CAN"
      },
      {
        label:"Camarines Sur",
        "shortCode":"CAS"
      },
      {
        label:"Camiguin",
        "shortCode":"CAM"
      },
      {
        label:"Capiz",
        "shortCode":"CAP"
      },
      {
        label:"Catanduanes",
        "shortCode":"CAT"
      },
      {
        label:"Cavite",
        "shortCode":"CAV"
      },
      {
        label:"Cebu",
        "shortCode":"CEB"
      },
      {
        label:"Compostela",
        "shortCode":"COM"
      },
      {
        label:"Cotabato",
        "shortCode":"NCO"
      },
      {
        label:"Davao del Norte",
        "shortCode":"DAV"
      },
      {
        label:"Davao del Sur",
        "shortCode":"DAS"
      },
      {
        label:"Davao Occidental",
        "shortCode":"DVO"
      },
      {
        label:"Davao Oriental",
        "shortCode":"DAO"
      },
      {
        label:"Dinagat Islands",
        "shortCode":"DIN"
      },
      {
        label:"Eastern Samar",
        "shortCode":"EAS"
      },
      {
        label:"Guimaras",
        "shortCode":"GUI"
      },
      {
        label:"Ifugao",
        "shortCode":"IFU"
      },
      {
        label:"Ilocos Norte",
        "shortCode":"ILN"
      },
      {
        label:"Ilocos Sur",
        "shortCode":"ILS"
      },
      {
        label:"Iloilo",
        "shortCode":"ILI"
      },
      {
        label:"Isabela",
        "shortCode":"ISA"
      },
      {
        label:"Kalinga",
        "shortCode":"KAL"
      },
      {
        label:"La Union",
        "shortCode":"LUN"
      },
      {
        label:"Laguna",
        "shortCode":"LAG"
      },
      {
        label:"Lanao del Norte",
        "shortCode":"LAN"
      },
      {
        label:"Lanao del Sur",
        "shortCode":"LAS"
      },
      {
        label:"Leyte",
        "shortCode":"LEY"
      },
      {
        label:"Maguindanao",
        "shortCode":"MAG"
      },
      {
        label:"Masbate",
        "shortCode":"MAS"
      },
      {
        label:"Metro Manila",
        "shortCode":"00"
      },
      {
        label:"Mindoro Occidental",
        "shortCode":"MDC"
      },
      {
        label:"Mindoro Oriental",
        "shortCode":"MDR"
      },
      {
        label:"Misamis Occidental",
        "shortCode":"MSC"
      },
      {
        label:"Misamis Oriental",
        "shortCode":"MSR"
      },
      {
        label:"Mountain Province",
        "shortCode":"MOU"
      },
      {
        label:"Negros Occidental",
        "shortCode":"NEC"
      },
      {
        label:"Negros Oriental",
        "shortCode":"NER"
      },
      {
        label:"Northern Samar",
        "shortCode":"NSA"
      },
      {
        label:"Nueva Ecija",
        "shortCode":"NUE"
      },
      {
        label:"Nueva Vizcaya",
        "shortCode":"NUV"
      },
      {
        label:"Palawan",
        "shortCode":"PLW"
      },
      {
        label:"Pampanga",
        "shortCode":"PAM"
      },
      {
        label:"Pangasinan",
        "shortCode":"PAN"
      },
      {
        label:"Quezon",
        "shortCode":"QUE"
      },
      {
        label:"Quirino",
        "shortCode":"QUI"
      },
      {
        label:"Rizal",
        "shortCode":"RIZ"
      },
      {
        label:"Romblon",
        "shortCode":"ROM"
      },
     {
        label:"Samar",
        "shortCode":"WSA"
      },
      {
        label:"Sarangani",
        "shortCode":"SAR"
      },
      {
        label:"Siquijor",
        "shortCode":"SIG"
      },
      {
        label:"Sorsogon",
        "shortCode":"SOR"
      },
      {
        label:"Southern Leyte",
        "shortCode":"SLE"
      },
      {
        label:"Sultan Kudarat",
        "shortCode":"AUK"
      },
      {
        label:"Sulu",
        "shortCode":"SLU"
      },
      {
        label:"Surigao del Norte",
        "shortCode":"SUN"
      },
      {
        label:"Surigao del Sur",
        "shortCode":"SUR"
      },
      {
        label:"Tarlac",
        "shortCode":"TAR"
      },
      {
        label:"Tawi-Tawi",
        "shortCode":"TAW"
      },
      {
        label:"Zambales",
        "shortCode":"ZMB"
      },
      {
        label:"Zamboanga del Norte",
        "shortCode":"ZAN"
      },
      {
        label:"Zamboanga del Sur",
        "shortCode":"ZAS"
      },
      {
        label:"Zamboanga Sibugay",
        "shortCode":"ZSI"
      }
    ]
  },
  {
    label:"Pitcairn",
    "countryShortCode":"PN",
    "regions":[
      {
        label:"Pitcairn Islands"
      }
    ]
  },
  {
    label:"Poland",
    "countryShortCode":"PL",
    "regions":[
      {
        label:"Dolnośląskie",
        "shortCode":"DS"
      },
      {
        label:"Kujawsko-pomorskie",
        "shortCode":"KP"
      },
      {
        label:"Łódzkie",
        "shortCode":"LD"
      },
      {
        label:"Lubelskie",
        "shortCode":"LU"
      },
      {
        label:"Lubuskie",
        "shortCode":"LB"
      },
      {
        label:"Malopolskie",
        "shortCode":"MA"
      },
      {
        label:"Mazowieckie",
        "shortCode":"MZ"
      },
      {
        label:"Opolskie",
        "shortCode":"OP"
      },
      {
        label:"Podkarpackie",
        "shortCode":"PK"
      },
      {
        label:"Podlaskie",
        "shortCode":"PD"
      },
      {
        label:"Pomorskie",
        "shortCode":"PM"
      },
      {
        label:"Śląskie",
        "shortCode":"SL"
      },
      {
        label:"Świętokrzyskie",
        "shortCode":"SK"
      },
      {
        label:"Warmińsko-mazurskie",
        "shortCode":"WN"
      },
      {
        label:"Wielkopolskie",
        "shortCode":"WP"
      },
      {
        label:"Zachodniopomorskie",
        "shortCode":"ZP"
      }
    ]
  },
  {
    label:"Portugal",
    "countryShortCode":"PT",
    "regions":[
      {
        label:"Acores",
        "shortCode":"20"
      },
      {
        label:"Aveiro",
        "shortCode":"01"
      },
      {
        label:"Beja",
        "shortCode":"02"
      },
      {
        label:"Braga",
        "shortCode":"03"
      },
      {
        label:"Braganca",
        "shortCode":"04"
      },
      {
        label:"Castelo Branco",
        "shortCode":"05"
      },
      {
        label:"Coimbra",
        "shortCode":"06"
      },
      {
        label:"Evora",
        "shortCode":"07"
      },
      {
        label:"Faro",
        "shortCode":"08"
      },
      {
        label:"Guarda",
        "shortCode":"09"
      },
      {
        label:"Leiria",
        "shortCode":"10"
      },
      {
        label:"Lisboa",
        "shortCode":"11"
      },
      {
        label:"Madeira",
        "shortCode":"30"
      },
      {
        label:"Portalegre",
        "shortCode":"12"
      },
      {
        label:"Porto",
        "shortCode":"13"
      },
      {
        label:"Santarem",
        "shortCode":"14"
      },
      {
        label:"Setubal",
        "shortCode":"15"
      },
      {
        label:"Viana do Castelo",
        "shortCode":"16"
      },
      {
        label:"Vila Real",
        "shortCode":"17"
      },
      {
        label:"Viseu",
        "shortCode":"18"
      }
    ]
  },
  {
    label:"Puerto Rico",
    "countryShortCode":"PR",
    "regions":[
      {
        label:"Adjuntas"
      },
      {
        label:"Aguada"
      },
      {
        label:"Aguadilla"
      },
      {
        label:"Aguas Buenas"
      },
      {
        label:"Aibonito"
      },
      {
        label:"Anasco"
      },
      {
        label:"Arecibo"
      },
      {
        label:"Arroyo"
      },
      {
        label:"Barceloneta"
      },
      {
        label:"Barranquitas"
      },
      {
        label:"Bayamon"
      },
      {
        label:"Cabo Rojo"
      },
      {
        label:"Caguas"
      },
      {
        label:"Camuy"
      },
      {
        label:"Canovanas"
      },
      {
        label:"Carolina"
      },
      {
        label:"Cat"
      },
      {
        label:"Ceiba"
      },
      {
        label:"Ciales"
      },
      {
        label:"Cidra"
      },
      {
        label:"Coamo"
      },
      {
        label:"Comerio"
      },
      {
        label:"Corozal"
      },
      {
        label:"Culebra"
      },
      {
        label:"Dorado"
      },
      {
        label:"Fajardo"
      },
      {
        label:"Florida"
      },
      {
        label:"Guanica"
      },
      {
        label:"Guayama"
      },
      {
        label:"Guayanilla"
      },
      {
        label:"Guaynabo"
      },
      {
        label:"Gurabo"
      },
      {
        label:"Hatillo"
      },
      {
        label:"Hormigueros"
      },
      {
        label:"Humacao"
      },
      {
        label:"Isabe"
      },
      {
        label:"Juana Diaz"
      },
      {
        label:"Juncos"
      },
      {
        label:"Lajas"
      },
      {
        label:"Lares"
      },
      {
        label:"Las Marias"
      },
      {
        label:"Las oiza"
      },
      {
        label:"Luquillo"
      },
      {
        label:"Manati"
      },
      {
        label:"Maricao"
      },
      {
        label:"Maunabo"
      },
      {
        label:"Mayaguez"
      },
      {
        label:"Moca"
      },
      {
        label:"Morovis"
      },
      {
        label:"Naguabo"
      },
      {
        label:"Naranjito"
      },
      {
        label:"Orocovis"
      },
      {
        label:"Patillas"
      },
      {
        label:"Penuelas"
      },
      {
        label:"Ponce"
      },
      {
        label:"Quebradillas"
      },
      {
        label:"Rincon"
      },
      {
        label:"Rio Grande"
      },
      {
        label:"Sabana linas"
      },
      {
        label:"San German"
      },
      {
        label:"San Juan"
      },
      {
        label:"San Lorenzo"
      },
      {
        label:"San Sebastian"
      },
      {
        label:"Santa Isabel"
      },
      {
        label:"Toa Alta"
      },
      {
        label:"Toa Baja"
      },
      {
        label:"Trujillo Alto"
      },
      {
        label:"Utuado"
      },
      {
        label:"Vega Alta"
      },
      {
        label:"Vega ues"
      },
      {
        label:"Villalba"
      },
      {
        label:"Yabucoa"
      },
      {
        label:"Yauco"
      }
    ]
  },
  {
    label:"Qatar",
    "countryShortCode":"QA",
    "regions":[
      {
        label:"Ad Dawḩah",
        "shortCode":"DA"
      },
      {
        label:"Al Khawr wa adh Dhakhīrah",
        "shortCode":"KH"
      },
      {
        label:"Al Wakrah",
        "shortCode":"WA"
      },
      {
        label:"Ar Rayyān",
        "shortCode":"RA"
      },
      {
        label:"Ash Shamāl",
        "shortCode":"MS"
      },
      {
        label:"Az̧ Za̧`āyin",
        "shortCode":"ZA"
      },
      {
        label:"Umm Şalāl",
        "shortCode":"US"
      }
    ]
  },
  {
    label:"Réunion",
    "countryShortCode":"RE",
    "regions":[
      {
        label:"Réunion"
      }
    ]
  },
  {
    label:"Romania",
    "countryShortCode":"RO",
    "regions":[
      {
        label:"Alba",
        "shortCode":"AB"
      },
      {
        label:"Arad",
        "shortCode":"AR"
      },
      {
        label:"Arges",
        "shortCode":"AG"
      },
      {
        label:"Bacau",
        "shortCode":"BC"
      },
      {
        label:"Bihor",
        "shortCode":"BH"
      },
      {
        label:"Bistrita-Nasaud",
        "shortCode":"BN"
      },
      {
        label:"Botosani",
        "shortCode":"BT"
      },
      {
        label:"Braila",
        "shortCode":"BR"
      },
      {
        label:"Brasov",
        "shortCode":"BV"
      },
      {
        label:"Bucuresti",
        "shortCode":"B"
      },
      {
        label:"Buzau",
        "shortCode":"BZ"
      },
      {
        label:"Calarasi",
        "shortCode":"CL"
      },
      {
        label:"Caras-Severin",
        "shortCode":"CS"
      },
      {
        label:"Cluj",
        "shortCode":"CJ"
      },
      {
        label:"Constanta",
        "shortCode":"CT"
      },
      {
        label:"Covasna",
        "shortCode":"CV"
      },
      {
        label:"Dambovita",
        "shortCode":"DB"
      },
      {
        label:"Dolj",
        "shortCode":"DJ"
      },
      {
        label:"Galati",
        "shortCode":"GL"
      },
      {
        label:"Giurgiu",
        "shortCode":"GR"
      },
      {
        label:"Gorj",
        "shortCode":"GJ"
      },
      {
        label:"Harghita",
        "shortCode":"HR"
      },
      {
        label:"Hunedoara",
        "shortCode":"HD"
      },
      {
        label:"Ialomita",
        "shortCode":"IL"
      },
      {
        label:"Iasi",
        "shortCode":"IS"
      },
      {
        label:"Maramures",
        "shortCode":"MM"
      },
      {
        label:"Mehedinti",
        "shortCode":"MH"
      },
      {
        label:"Mures",
        "shortCode":"MS"
      },
      {
        label:"Neamt",
        "shortCode":"NT"
      },
      {
        label:"Olt",
        "shortCode":"OT"
      },
      {
        label:"Prahova",
        "shortCode":"PH"
      },
      {
        label:"Salaj",
        "shortCode":"SJ"
      },
      {
        label:"Satu Mare",
        "shortCode":"SM"
      },
      {
        label:"Sibiu",
        "shortCode":"SB"
      },
      {
        label:"Suceava",
        "shortCode":"SV"
      },
      {
        label:"Teleorman",
        "shortCode":"TR"
      },
      {
        label:"Timis",
        "shortCode":"TM"
      },
      {
        label:"Tulcea",
        "shortCode":"TL"
      },
      {
        label:"Valcea",
        "shortCode":"VL"
      },
      {
        label:"Vaslui",
        "shortCode":"VS"
      },
      {
        label:"Vrancea",
        "shortCode":"VN"
      }
    ]
  },
  {
    label:"Russian Federation",
    "countryShortCode":"RU",
    "regions":[
      {
        label:"Republic of Adygea",
        "shortCode":"AD"
      },
      {
        label:"Republic of Altai (Gorno-Altaysk)",
        "shortCode":"AL"
      },
      {
        label:"Altai Krai",
        "shortCode":"ALT"
      },
      {
        label:"Amur Oblast",
        "shortCode":"AMU"
      },
      {
        label:"Arkhangelsk Oblast",
        "shortCode":"ARK"
      },
      {
        label:"Astrakhan Oblast",
        "shortCode":"AST"
      },
      {
        label:"Republic of Bashkortostan",
        "shortCode":"BA"
      },
      {
        label:"Belgorod Oblast",
        "shortCode":"BEL"
      },
      {
        label:"Bryansk Oblast",
        "shortCode":"BRY"
      },
      {
        label:"Republic of Buryatia",
        "shortCode":"BU"
      },
      {
        label:"Chechen Republic",
        "shortCode":"CE"
      },
      {
        label:"Chelyabinsk Oblast",
        "shortCode":"CHE"
      },
      {
        label:"Chukotka Autonomous Okrug",
        "shortCode":"CHU"
      },
      {
        label:"Chuvash Republic",
        "shortCode":"CU"
      },
      {
        label:"Republic of Dagestan",
        "shortCode":"DA"
      },
      {
        label:"Republic of Ingushetia",
        "shortCode":"IN"
      },
      {
        label:"Irkutsk Oblast",
        "shortCode":"IRK"
      },
      {
        label:"Ivanovo Oblast",
        "shortCode":"IVA"
      },
      {
        label:"Jewish Autonomous Oblast",
        "shortCode":"JEW"
      },
      {
        label:"Kabardino-Balkar Republic",
        "shortCode":"KB"
      },
      {
        label:"Kaliningrad Oblast",
        "shortCode":"KLN"
      },
      {
        label:"Republic of Kalmykia",
        "shortCode":"KL"
      },
      {
        label:"Kaluga Oblast",
        "shortCode":"KLU"
      },
      {
        label:"Kamchatka Krai",
        "shortCode":"KAM"
      },
      {
        label:"Karachay-Cherkess Republic",
        "shortCode":"KC"
      },
      {
        label:"Republic of Karelia",
        "shortCode":"KR"
      },
      {
        label:"Khabarovsk Krai",
        "shortCode":"KHA"
      },
      {
        label:"Republic of Khakassia",
        "shortCode":"KK"
      },
      {
        label:"Khanty-Mansi Autonomous Okrug - Yugra",
        "shortCode":"KHM"
      },
      {
        label:"Kemerovo Oblast",
        "shortCode":"KEM"
      },
      {
        label:"Kirov Oblast",
        "shortCode":"KIR"
      },
      {
        label:"Komi Republic",
        "shortCode":"KO"
      },
      {
        label:"Kostroma Oblast",
        "shortCode":"KOS"
      },
      {
        label:"Krasnodar Krai",
        "shortCode":"KDA"
      },
      {
        label:"Krasnoyarsk Krai",
        "shortCode":"KYA"
      },
      {
        label:"Kurgan Oblast",
        "shortCode":"KGN"
      },
      {
        label:"Kursk Oblast",
        "shortCode":"KRS"
      },
      {
        label:"Leningrad Oblast",
        "shortCode":"LEN"
      },
      {
        label:"Lipetsk Oblast",
        "shortCode":"LIP"
      },
      {
        label:"Magadan Oblast",
        "shortCode":"MAG"
      },
      {
        label:"Mari El Republic",
        "shortCode":"ME"
      },
      {
        label:"Republic of Mordovia",
        "shortCode":"MO"
      },
      {
        label:"Moscow Oblast",
        "shortCode":"MOS"
      },
      {
        label:"Moscow",
        "shortCode":"MOW"
      },
      {
        label:"Murmansk Oblast",
        "shortCode":"MU"
      },
      {
        label:"Nenets Autonomous Okrug",
        "shortCode":"NEN"
      },
      {
        label:"Nizhny Novgorod Oblast",
        "shortCode":"NIZ"
      },
      {
        label:"Novgorod Oblast",
        "shortCode":"NGR"
      },
      {
        label:"Novosibirsk Oblast",
        "shortCode":"NVS"
      },
      {
        label:"Omsk Oblast",
        "shortCode":"OMS"
      },
      {
        label:"Orenburg Oblast",
        "shortCode":"ORE"
      },
      {
        label:"Oryol Oblast",
        "shortCode":"ORL"
      },
      {
        label:"Penza Oblast",
        "shortCode":"PNZ"
      },
      {
        label:"Perm Krai",
        "shortCode":"PER"
      },
      {
        label:"Primorsky Krai",
        "shortCode":"PRI"
      },
      {
        label:"Pskov Oblast",
        "shortCode":"PSK"
      },
      {
        label:"Rostov Oblast",
        "shortCode":"ROS"
      },
      {
        label:"Ryazan Oblast",
        "shortCode":"RYA"
      },
      {
        label:"Saint Petersburg",
        "shortCode":"SPE"
      },
      {
        label:"Sakha (Yakutia) Republic",
        "shortCode":"SA"
      },
      {
        label:"Sakhalin Oblast",
        "shortCode":"SAK"
      },
      {
        label:"Samara Oblast",
        "shortCode":"SAM"
      },
      {
        label:"Saratov Oblast",
        "shortCode":"SAR"
      },
      {
        label:"Republic of North Ossetia-Alania",
        "shortCode":"NOA"
      },
      {
        label:"Smolensk Oblast",
        "shortCode":"SMO"
      },
      {
        label:"Stavropol Krai",
        "shortCode":"STA"
      },
      {
        label:"Sverdlovsk Oblast",
        "shortCode":"SVE"
      },
      {
        label:"Tambov Oblast",
        "shortCode":"TAM"
      },
      {
        label:"Republic of Tatarstan",
        "shortCode":"TA"
      },
      {
        label:"Tomsk Oblast",
        "shortCode":"TOM"
      },
      {
        label:"Tuva Republic",
        "shortCode":"TU"
      },
      {
        label:"Tula Oblast",
        "shortCode":"TUL"
      },
      {
        label:"Tver Oblast",
        "shortCode":"TVE"
      },
      {
        label:"Tyumen Oblast",
        "shortCode":"TYU"
      },
      {
        label:"Udmurt Republic",
        "shortCode":"UD"
      },
      {
        label:"Ulyanovsk Oblast",
        "shortCode":"ULY"
      },
      {
        label:"Vladimir Oblast",
        "shortCode":"VLA"
      },
      {
        label:"Volgograd Oblast",
        "shortCode":"VGG"
      },
      {
        label:"Vologda Oblast",
        "shortCode":"VLG"
      },
      {
        label:"Voronezh Oblast",
        "shortCode":"VOR"
      },
      {
        label:"Yamalo-Nenets Autonomous Okrug",
        "shortCode":"YAN"
      },
      {
        label:"Yaroslavl Oblast",
        "shortCode":"YAR"
      },
      {
        label:"Zabaykalsky Krai",
        "shortCode":"ZAB"
      }
    ]
  },
  {
    label:"Rwanda",
    "countryShortCode":"RW",
    "regions":[
      {
        label:"Kigali",
        "shortCode":"01"
      },
      {
        label:"Eastern",
        "shortCode":"02"
      },
      {
        label:"Northern",
        "shortCode":"03"
      },
      {
        label:"Western",
        "shortCode":"04"
      },
      {
        label:"Southern",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Saint Barthélemy",
    "countryShortCode":"BL",
    "regions":[
      {
        label:"Au Vent",
        "shortCode":"02"
      },
      {
        label:"Sous le Vent",
        "shortCode":"01"
      }
    ]
  },
  {
    label:"Saint Helena, Ascension and Tristan da Cunha",
    "countryShortCode":"SH",
    "regions":[
      {
        label:"Ascension",
        "shortCode":"AC"
      },
      {
        label:"Saint Helena",
        "shortCode":"HL"
      },
      {
        label:"Tristan da Cunha",
        "shortCode":"TA"
      }
    ]
  },
  {
    label:"Saint Kitts and Nevis",
    "countryShortCode":"KN",
    "regions":[
      {
        label:"Saint Kitts",
        "shortCode":"K"
      },
      {
        label:"Nevis",
        "shortCode":"N"
      }
    ]
  },
  {
    label:"Saint Lucia",
    "countryShortCode":"LC",
    "regions":[
      {
        label:"Anse-la-Raye",
        "shortCode":"01"
      },
      {
        label:"Canaries",
        "shortCode":"12"
      },
      {
        label:"Castries",
        "shortCode":"02"
      },
      {
        label:"Choiseul",
        "shortCode":"03"
      },
      {
        label:"Dennery",
        "shortCode":"05"
      },
      {
        label:"Gros Islet",
        "shortCode":"06"
      },
      {
        label:"Laborie",
        "shortCode":"07"
      },
      {
        label:"Micoud",
        "shortCode":"08"
      },
      {
        label:"Soufriere",
        "shortCode":"10"
      },
      {
        label:"Vieux Fort",
        "shortCode":"11"
      }
    ]
  },
  {
    label:"Saint Martin",
    "countryShortCode":"MF",
    "regions":[
      {
        label:"Saint Martin"
      }
    ]
  },
  {
    label:"Saint Pierre and Miquelon",
    "countryShortCode":"PM",
    "regions":[
      {
        label:"Miquelon"
      },
      {
        label:"Saint Pierre"
      }
    ]
  },
  {
    label:"Saint Vincent and the Grenadines",
    "countryShortCode":"VC",
    "regions":[
      {
        label:"Charlotte",
        "shortCode":"01"
      },
      {
        label:"Grenadines",
        "shortCode":"06"
      },
      {
        label:"Saint Andrew",
        "shortCode":"02"
      },
      {
        label:"Saint David",
        "shortCode":"03"
      },
      {
        label:"Saint George",
        "shortCode":"04"
      },
      {
        label:"Saint Patrick",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Samoa",
    "countryShortCode":"WS",
    "regions":[
      {
        label:"A'ana",
        "shortCode":"AA"
      },
      {
        label:"Aiga-i-le-Tai",
        "shortCode":"AL"
      },
      {
        label:"Atua",
        "shortCode":"AT"
      },
      {
        label:"Fa'asaleleaga",
        "shortCode":"FA"
      },
      {
        label:"Gaga'emauga",
        "shortCode":"GE"
      },
      {
        label:"Gagaifomauga",
        "shortCode":"GI"
      },
      {
        label:"Palauli",
        "shortCode":"PA"
      },
      {
        label:"Satupa'itea",
        "shortCode":"SA"
      },
      {
        label:"Tuamasaga",
        "shortCode":"TU"
      },
      {
        label:"Va'a-o-Fonoti",
        "shortCode":"VF"
      },
      {
        label:"Vaisigano",
        "shortCode":"VS"
      }
    ]
  },
  {
    label:"San Marino",
    "countryShortCode":"SM",
    "regions":[
      {
        label:"Acquaviva",
        "shortCode":"01"
      },
      {
        label:"Borgo Maggiore",
        "shortCode":"06"
      },
      {
        label:"Chiesanuova",
        "shortCode":"02"
      },
      {
        label:"Domagnano",
        "shortCode":"03"
      },
      {
        label:"Faetano",
        "shortCode":"04"
      },
      {
        label:"Fiorentino",
        "shortCode":"05"
      },
      {
        label:"Montegiardino",
        "shortCode":"08"
      },
      {
        label:"San Marino",
        "shortCode":"07"
      },
      {
        label:"Serravalle",
        "shortCode":"09"
      }
    ]
  },
  {
    label:"Sao Tome and Principe",
    "countryShortCode":"ST",
    "regions":[
      {
        label:"Principe",
        "shortCode":"P"
      },
      {
        label:"Sao Tome",
        "shortCode":"S"
      }
    ]
  },
  {
    label:"Saudi Arabia",
    "countryShortCode":"SA",
    "regions":[
      {
        label:"'Asir",
        "shortCode":"14"
      },
      {
        label:"Al Bahah",
        "shortCode":"11"
      },
      {
        label:"Al Hudud ash Shamaliyah",
        "shortCode":"08"
      },
      {
        label:"Al Jawf",
        "shortCode":"12"
      },
      {
        label:"Al Madinah al Munawwarah",
        "shortCode":"03"
      },
      {
        label:"Al Qasim",
        "shortCode":"05"
      },
      {
        label:"Ar Riyad",
        "shortCode":"01"
      },
      {
        label:"Ash Sharqiyah",
        "shortCode":"04"
      },
      {
        label:"Ha'il",
        "shortCode":"06"
      },
      {
        label:"Jazan",
        "shortCode":"09"
      },
      {
        label:"Makkah al Mukarramah",
        "shortCode":"02"
      },
      {
        label:"Najran",
        "shortCode":"10"
      },
      {
        label:"Tabuk",
        "shortCode":"07"
      }
    ]
  },
  {
    label:"Senegal",
    "countryShortCode":"SN",
    "regions":[
      {
        label:"Dakar",
        "shortCode":"DK"
      },
      {
        label:"Diourbel",
        "shortCode":"DB"
      },
      {
        label:"Fatick",
        "shortCode":"FK"
      },
      {
        label:"Kaffrine",
        "shortCode":"KA"
      },
      {
        label:"Kaolack",
        "shortCode":"KL"
      },
      {
        label:"Kedougou",
        "shortCode":"KE"
      },
      {
        label:"Kolda",
        "shortCode":"KD"
      },
      {
        label:"Louga",
        "shortCode":"LG"
      },
      {
        label:"Matam",
        "shortCode":"MT"
      },
      {
        label:"Saint-Louis",
        "shortCode":"SL"
      },
      {
        label:"Sedhiou",
        "shortCode":"SE"
      },
      {
        label:"Tambacounda",
        "shortCode":"TC"
      },
      {
        label:"Thies",
        "shortCode":"TH"
      },
      {
        label:"Ziguinchor",
        "shortCode":"ZG"
      }
    ]
  },
  {
    label:"Serbia",
    "countryShortCode":"RS",
    "regions":[
      {
        label:"Beograd (Belgrade)",
        "shortCode":"00"
      },
      {
        label:"Borski",
        "shortCode":"14"
      },
      {
        label:"Braničevski",
        "shortCode":"11"
      },
      {
        label:"Jablanički",
        "shortCode":"23"
      },
      {
        label:"Južnobački",
        "shortCode":"06"
      },
      {
        label:"Južnobanatski",
        "shortCode":"04"
      },
      {
        label:"Kolubarski",
        "shortCode":"09"
      },
      {
        label:"Kosovski",
        "shortCode":"25"
      },
      {
        label:"Kosovsko-Mitrovački",
        "shortCode":"28"
      },
      {
        label:"Kosovsko-Pomoravski",
        "shortCode":"29"
      },
      {
        label:"Mačvanski",
        "shortCode":"08"
      },
      {
        label:"Moravički",
        "shortCode":"17"
      },
      {
        label:"Nišavski",
        "shortCode":"20"
      },
      {
        label:"Pčinjski",
        "shortCode":"24"
      },
      {
        label:"Pećki",
        "shortCode":"26"
      },
      {
        label:"Pirotski",
        "shortCode":"22"
      },
      {
        label:"Podunavski",
        "shortCode":"10"
      },
      {
        label:"Pomoravski",
        "shortCode":"13"
      },
      {
        label:"Prizrenski",
        "shortCode":"27"
      },
      {
        label:"Rasinski",
        "shortCode":"19"
      },
      {
        label:"Raški",
        "shortCode":"18"
      },
      {
        label:"Severnobački",
        "shortCode":"01"
      },
      {
        label:"Severnobanatski",
        "shortCode":"03"
      },
      {
        label:"Srednjebanatski",
        "shortCode":"02"
      },
      {
        label:"Sremski",
        "shortCode":"07"
      },
      {
        label:"Šumadijski",
        "shortCode":"12"
      },
      {
        label:"Toplički",
        "shortCode":"21"
      },
      {
        label:"Zaječarski",
        "shortCode":"15"
      },
      {
        label:"Zapadnobački",
        "shortCode":"05"
      },
      {
        label:"Zlatiborski",
        "shortCode":"16"
      }
    ]
  },
  {
    label:"Seychelles",
    "countryShortCode":"SC",
    "regions":[
      {
        label:"Anse aux Pins",
        "shortCode":"01"
      },
      {
        label:"Anse Boileau",
        "shortCode":"02"
      },
      {
        label:"Anse Etoile",
        "shortCode":"03"
      },
      {
        label:"Anse Royale",
        "shortCode":"05"
      },
      {
        label:"Anu Cap",
        "shortCode":"04"
      },
      {
        label:"Baie Lazare",
        "shortCode":"06"
      },
      {
        label:"Baie Sainte Anne",
        "shortCode":"07"
      },
      {
        label:"Beau Vallon",
        "shortCode":"08"
      },
      {
        label:"Bel Air",
        "shortCode":"09"
      },
      {
        label:"Bel Ombre",
        "shortCode":"10"
      },
      {
        label:"Cascade",
        "shortCode":"11"
      },
      {
        label:"Glacis",
        "shortCode":"12"
      },
      {
        label:"Grand'Anse Mahe",
        "shortCode":"13"
      },
      {
        label:"Grand'Anse Praslin",
        "shortCode":"14"
      },
      {
        label:"La Digue",
        "shortCode":"15"
      },
      {
        label:"La Riviere Anglaise",
        "shortCode":"16"
      },
      {
        label:"Les Mamelles",
        "shortCode":"24"
      },
      {
        label:"Mont Buxton",
        "shortCode":"17"
      },
      {
        label:"Mont Fleuri",
        "shortCode":"18"
      },
      {
        label:"Plaisance",
        "shortCode":"19"
      },
      {
        label:"Pointe La Rue",
        "shortCode":"20"
      },
      {
        label:"Port Glaud",
        "shortCode":"21"
      },
      {
        label:"Roche Caiman",
        "shortCode":"25"
      },
      {
        label:"Saint Louis",
        "shortCode":"22"
      },
      {
        label:"Takamaka",
        "shortCode":"23"
      }
    ]
  },
  {
    label:"Sierra Leone",
    "countryShortCode":"SL",
    "regions":[
      {
        label:"Eastern",
        "shortCode":"E"
      },
      {
        label:"Northern",
        "shortCode":"N"
      },
      {
        label:"Southern",
        "shortCode":"S"
      },
      {
        label:"Western",
        "shortCode":"W"
      }
    ]
  },
  {
    label:"Singapore",
    "countryShortCode":"SG",
    "regions":[
      {
        label:"Central Singapore",
        "shortCode":"01"
      },
      {
        label:"North East",
        "shortCode":"02"
      },
      {
        label:"North West",
        "shortCode":"03"
      },
      {
        label:"South East",
        "shortCode":"04"
      },
      {
        label:"South West",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Sint Maarten (Dutch part)",
    "countryShortCode":"SX",
    "regions":[
      {
        label:"Sint Maarten"
      }
    ]
  },
  {
    label:"Slovakia",
    "countryShortCode":"SK",
    "regions":[
      {
        label:"Banskobystricky",
        "shortCode":"BC"
      },
      {
        label:"Bratislavsky",
        "shortCode":"BL"
      },
      {
        label:"Kosicky",
        "shortCode":"KI"
      },
      {
        label:"Nitriansky",
        "shortCode":"NI"
      },
      {
        label:"Presovsky",
        "shortCode":"PV"
      },
      {
        label:"Trenciansky",
        "shortCode":"TC"
      },
      {
        label:"Trnavsky",
        "shortCode":"TA"
      },
      {
        label:"Zilinsky",
        "shortCode":"ZI"
      }
    ]
  },
  {
    label:"Slovenia",
    "countryShortCode":"SI",
    "regions":[
      {
        label:"Ajdovscina",
        "shortCode":"001"
      },
      {
        label:"Apace",
        "shortCode":"195"
      },
      {
        label:"Beltinci",
        "shortCode":"002"
      },
      {
        label:"Benedikt",
        "shortCode":"148"
      },
      {
        label:"Bistrica ob Sotli",
        "shortCode":"149"
      },
      {
        label:"Bled",
        "shortCode":"003"
      },
      {
        label:"Bloke",
        "shortCode":"150"
      },
      {
        label:"Bohinj",
        "shortCode":"004"
      },
      {
        label:"Borovnica",
        "shortCode":"005"
      },
      {
        label:"Bovec",
        "shortCode":"006"
      },
      {
        label:"Braslovce",
        "shortCode":"151"
      },
      {
        label:"Brda",
        "shortCode":"007"
      },
      {
        label:"Brezice",
        "shortCode":"009"
      },
      {
        label:"Brezovica",
        "shortCode":"008"
      },
      {
        label:"Cankova",
        "shortCode":"152"
      },
      {
        label:"Celje",
        "shortCode":"011"
      },
      {
        label:"Cerklje na Gorenjskem",
        "shortCode":"012"
      },
      {
        label:"Cerknica",
        "shortCode":"013"
      },
      {
        label:"Cerkno",
        "shortCode":"014"
      },
      {
        label:"Cerkvenjak",
        "shortCode":"153"
      },
      {
        label:"Cirkulane",
        "shortCode":"196"
      },
      {
        label:"Crensovci",
        "shortCode":"015"
      },
      {
        label:"Crna na Koroskem",
        "shortCode":"016"
      },
      {
        label:"Crnomelj",
        "shortCode":"017"
      },
      {
        label:"Destrnik",
        "shortCode":"018"
      },
      {
        label:"Divaca",
        "shortCode":"019"
      },
      {
        label:"Dobje",
        "shortCode":"154"
      },
      {
        label:"Dobrepolje",
        "shortCode":"020"
      },
      {
        label:"Dobrna",
        "shortCode":"155"
      },
      {
        label:"Dobrova-Polhov Gradec",
        "shortCode":"021"
      },
      {
        label:"Dobrovnik",
        "shortCode":"156"
      },
      {
        label:"Dol pri Ljubljani",
        "shortCode":"022"
      },
      {
        label:"Dolenjske Toplice",
        "shortCode":"157"
      },
      {
        label:"Domzale",
        "shortCode":"023"
      },
      {
        label:"Dornava",
        "shortCode":"024"
      },
      {
        label:"Dravograd",
        "shortCode":"025"
      },
      {
        label:"Duplek",
        "shortCode":"026"
      },
      {
        label:"Gorenja Vas-Poljane",
        "shortCode":"027"
      },
      {
        label:"Gorisnica",
        "shortCode":"028"
      },
      {
        label:"Gorje",
        "shortCode":"207"
      },
      {
        label:"Gornja Radgona",
        "shortCode":"029"
      },
      {
        label:"Gornji Grad",
        "shortCode":"030"
      },
      {
        label:"Gornji Petrovci",
        "shortCode":"031"
      },
      {
        label:"Grad",
        "shortCode":"158"
      },
      {
        label:"Grosuplje",
        "shortCode":"032"
      },
      {
        label:"Hajdina",
        "shortCode":"159"
      },
      {
        label:"Hoce-Slivnica",
        "shortCode":"160"
      },
      {
        label:"Hodos",
        "shortCode":"161"
      },
      {
        label:"Horjul",
        "shortCode":"162"
      },
      {
        label:"Hrastnik",
        "shortCode":"034"
      },
      {
        label:"Hrpelje-Kozina",
        "shortCode":"035"
      },
      {
        label:"Idrija",
        "shortCode":"036"
      },
      {
        label:"Ig",
        "shortCode":"037"
      },
      {
        label:"Ilirska Bistrica",
        "shortCode":"038"
      },
      {
        label:"Ivancna Gorica",
        "shortCode":"039"
      },
      {
        label:"Izola",
        "shortCode":"040s"
      },
      {
        label:"Jesenice",
        "shortCode":"041"
      },
      {
        label:"Jursinci",
        "shortCode":"042"
      },
      {
        label:"Kamnik",
        "shortCode":"043"
      },
      {
        label:"Kanal",
        "shortCode":"044"
      },
      {
        label:"Kidricevo",
        "shortCode":"045"
      },
      {
        label:"Kobarid",
        "shortCode":"046"
      },
      {
        label:"Kobilje",
        "shortCode":"047"
      },
      {
        label:"Kocevje",
        "shortCode":"048"
      },
      {
        label:"Komen",
        "shortCode":"049"
      },
      {
        label:"Komenda",
        "shortCode":"164"
      },
      {
        label:"Koper",
        "shortCode":"050"
      },
      {
        label:"Kodanjevica na Krki",
        "shortCode":"197"
      },
      {
        label:"Kostel",
        "shortCode":"165"
      },
      {
        label:"Kozje",
        "shortCode":"051"
      },
      {
        label:"Kranj",
        "shortCode":"052"
      },
      {
        label:"Kranjska Gora",
        "shortCode":"053"
      },
      {
        label:"Krizevci",
        "shortCode":"166"
      },
      {
        label:"Krsko",
        "shortCode":"054"
      },
      {
        label:"Kungota",
        "shortCode":"055"
      },
      {
        label:"Kuzma",
        "shortCode":"056"
      },
      {
        label:"Lasko",
        "shortCode":"057"
      },
      {
        label:"Lenart",
        "shortCode":"058"
      },
      {
        label:"Lendava",
        "shortCode":"059"
      },
      {
        label:"Litija",
        "shortCode":"068"
      },
      {
        label:"Ljubljana",
        "shortCode":"061"
      },
      {
        label:"Ljubno",
        "shortCode":"062"
      },
      {
        label:"Ljutomer",
        "shortCode":"063"
      },
      {
        label:"Log-Dragomer",
        "shortCode":"208"
      },
      {
        label:"Logatec",
        "shortCode":"064"
      },
      {
        label:"Loska Dolina",
        "shortCode":"065"
      },
      {
        label:"Loski Potok",
        "shortCode":"066"
      },
      {
        label:"Lovrenc na Pohorju",
        "shortCode":"167"
      },
      {
        label:"Lukovica",
        "shortCode":"068"
      },
      {
        label:"Luce",
        "shortCode":"067"
      },
      {
        label:"Majsperk",
        "shortCode":"069"
      },
      {
        label:"Makole",
        "shortCode":"198"
      },
      {
        label:"Maribor",
        "shortCode":"070"
      },
      {
        label:"Markovci",
        "shortCode":"168"
      },
      {
        label:"Medvode",
        "shortCode":"071"
      },
      {
        label:"Menges",
        "shortCode":"072"
      },
      {
        label:"Metlika",
        "shortCode":"073"
      },
      {
        label:"Mezica",
        "shortCode":"074"
      },
      {
        label:"Miklavz na Dravskem Polju",
        "shortCode":"169"
      },
      {
        label:"Miren-Kostanjevica",
        "shortCode":"075"
      },
      {
        label:"Mirna",
        "shortCode":"212"
      },
      {
        label:"Mirna Pec",
        "shortCode":"170"
      },
      {
        label:"Mislinja",
        "shortCode":"076"
      },
      {
        label:"Mokronog-Trebelno",
        "shortCode":"199"
      },
      {
        label:"Moravce",
        "shortCode":"077"
      },
      {
        label:"Moravske Toplice",
        "shortCode":"078"
      },
      {
        label:"Mozirje",
        "shortCode":"079"
      },
      {
        label:"Murska Sobota",
        "shortCode":"080"
      },
      {
        label:"Naklo",
        "shortCode":"082"
      },
      {
        label:"Nazarje",
        "shortCode":"083"
      },
      {
        label:"Nova Gorica",
        "shortCode":"084"
      },
      {
        label:"Novo Mesto",
        "shortCode":"085"
      },
      {
        label:"Odranci",
        "shortCode":"086"
      },
      {
        label:"Ormoz",
        "shortCode":"087"
      },
      {
        label:"Osilnica",
        "shortCode":"088"
      },
      {
        label:"Pesnica",
        "shortCode":"089"
      },
      {
        label:"Piran",
        "shortCode":"090"
      },
      {
        label:"Pivka",
        "shortCode":"091"
      },
      {
        label:"Podcetrtek",
        "shortCode":"092"
      },
      {
        label:"Podlehnik",
        "shortCode":"172"
      },
      {
        label:"Podvelka",
        "shortCode":"093"
      },
      {
        label:"Poljcane",
        "shortCode":"200"
      },
      {
        label:"Postojna",
        "shortCode":"094"
      },
      {
        label:"Prebold",
        "shortCode":"174"
      },
      {
        label:"Preddvor",
        "shortCode":"095"
      },
      {
        label:"Prevalje",
        "shortCode":"175"
      },
      {
        label:"Ptuj",
        "shortCode":"096"
      },
      {
        label:"Race-Fram",
        "shortCode":"098"
      },
      {
        label:"Radece",
        "shortCode":"099"
      },
      {
        label:"Radenci",
        "shortCode":"100"
      },
      {
        label:"Radlje ob Dravi",
        "shortCode":"101"
      },
      {
        label:"Radovljica",
        "shortCode":"102"
      },
      {
        label:"Ravne na Koroskem",
        "shortCode":"103"
      },
      {
        label:"Razkrizje",
        "shortCode":"176"
      },
      {
        label:"Recica ob Savinji",
        "shortCode":"209"
      },
      {
        label:"Rence-Vogrsko",
        "shortCode":"201"
      },
      {
        label:"Ribnica",
        "shortCode":"104"
      },
      {
        label:"Ribnica na Poboriu",
        "shortCode":"177"
      },
      {
        label:"Rogaska Slatina",
        "shortCode":"106"
      },
      {
        label:"Rogasovci",
        "shortCode":"105"
      },
      {
        label:"Rogatec",
        "shortCode":"107"
      },
      {
        label:"Ruse",
        "shortCode":"108"
      },
      {
        label:"Salovci",
        "shortCode":"033"
      },
      {
        label:"Selnica ob Dravi",
        "shortCode":"178"
      },
      {
        label:"Semic",
        "shortCode":"109"
      },
      {
        label:"Sempeter-Vrtojba",
        "shortCode":"183"
      },
      {
        label:"Sencur",
        "shortCode":"117"
      },
      {
        label:"Sentilj",
        "shortCode":"118"
      },
      {
        label:"Sentjernej",
        "shortCode":"119"
      },
      {
        label:"Sentjur",
        "shortCode":"120"
      },
      {
        label:"Sentrupert",
        "shortCode":"211"
      },
      {
        label:"Sevnica",
        "shortCode":"110"
      },
      {
        label:"Sezana",
        "shortCode":"111"
      },
      {
        label:"Skocjan",
        "shortCode":"121"
      },
      {
        label:"Skofja Loka",
        "shortCode":"122"
      },
      {
        label:"Skofljica",
        "shortCode":"123"
      },
      {
        label:"Slovenj Gradec",
        "shortCode":"112"
      },
      {
        label:"Slovenska Bistrica",
        "shortCode":"113"
      },
      {
        label:"Slovenske Konjice",
        "shortCode":"114"
      },
      {
        label:"Smarje pri elsah",
        "shortCode":"124"
      },
      {
        label:"Smarjeske Toplice",
        "shortCode":"206"
      },
      {
        label:"Smartno ob Paki",
        "shortCode":"125"
      },
      {
        label:"Smartno pri Litiji",
        "shortCode":"194"
      },
      {
        label:"Sodrazica",
        "shortCode":"179"
      },
      {
        label:"Solcava",
        "shortCode":"180"
      },
      {
        label:"Sostanj",
        "shortCode":"126"
      },
      {
        label:"Sredisce ob Dravi",
        "shortCode":"202"
      },
      {
        label:"Starse",
        "shortCode":"115"
      },
      {
        label:"Store",
        "shortCode":"127"
      },
      {
        label:"Straza",
        "shortCode":"203"
      },
      {
        label:"Sveta Ana",
        "shortCode":"181"
      },
      {
        label:"Sveta Trojica v Slovenskih Goricah",
        "shortCode":"204"
      },
      {
        label:"Sveta Andraz v Slovenskih Goricah",
        "shortCode":"182"
      },
      {
        label:"Sveti Jurij",
        "shortCode":"116"
      },
      {
        label:"Sveti Jurij v Slovenskih Goricah",
        "shortCode":"210"
      },
      {
        label:"Sveti Tomaz",
        "shortCode":"205"
      },
      {
        label:"Tabor",
        "shortCode":"184"
      },
      {
        label:"Tisina",
        "shortCode":"128"
      },
      {
        label:"Tolmin",
        "shortCode":"128"
      },
      {
        label:"Trbovlje",
        "shortCode":"129"
      },
      {
        label:"Trebnje",
        "shortCode":"130"
      },
      {
        label:"Trnovska Vas",
        "shortCode":"185"
      },
      {
        label:"Trzin",
        "shortCode":"186"
      },
      {
        label:"Trzic",
        "shortCode":"131"
      },
      {
        label:"Turnisce",
        "shortCode":"132"
      },
      {
        label:"Velenje",
        "shortCode":"133"
      },
      {
        label:"Velika Polana",
        "shortCode":"187"
      },
      {
        label:"Velike Lasce",
        "shortCode":"134"
      },
      {
        label:"Verzej",
        "shortCode":"188"
      },
      {
        label:"Videm",
        "shortCode":"135"
      },
      {
        label:"Vipava",
        "shortCode":"136"
      },
      {
        label:"Vitanje",
        "shortCode":"137"
      },
      {
        label:"Vodice",
        "shortCode":"138"
      },
      {
        label:"Vojnik",
        "shortCode":"139"
      },
      {
        label:"Vransko",
        "shortCode":"189"
      },
      {
        label:"Vrhnika",
        "shortCode":"140"
      },
      {
        label:"Vuzenica",
        "shortCode":"141"
      },
      {
        label:"Zagorje ob Savi",
        "shortCode":"142"
      },
      {
        label:"Zavrc",
        "shortCode":"143"
      },
      {
        label:"Zrece",
        "shortCode":"144"
      },
      {
        label:"Zalec",
        "shortCode":"190"
      },
      {
        label:"Zelezniki",
        "shortCode":"146"
      },
      {
        label:"Zetale",
        "shortCode":"191"
      },
      {
        label:"Ziri",
        "shortCode":"147"
      },
      {
        label:"Zirovnica",
        "shortCode":"192"
      },
      {
        label:"Zuzemberk",
        "shortCode":"193"
      }
    ]
  },
  {
    label:"Solomon Islands",
    "countryShortCode":"SB",
    "regions":[
      {
        label:"Central",
        "shortCode":"CE"
      },
      {
        label:"Choiseul",
        "shortCode":"CH"
      },
      {
        label:"Guadalcanal",
        "shortCode":"GU"
      },
      {
        label:"Honiara",
        "shortCode":"CT"
      },
      {
        label:"Isabel",
        "shortCode":"IS"
      },
      {
        label:"Makira-Ulawa",
        "shortCode":"MK"
      },
      {
        label:"Malaita",
        "shortCode":"ML"
      },
      {
        label:"Rennell and Bellona",
        "shortCode":"RB"
      },
      {
        label:"Temotu",
        "shortCode":"TE"
      },
      {
        label:"Western",
        "shortCode":"WE"
      }
    ]
  },
  {
    label:"Somalia",
    "countryShortCode":"SO",
    "regions":[
      {
        label:"Awdal",
        "shortCode":"AW"
      },
      {
        label:"Bakool",
        "shortCode":"BK"
      },
      {
        label:"Banaadir",
        "shortCode":"BN"
      },
      {
        label:"Bari",
        "shortCode":"BR"
      },
      {
        label:"Bay",
        "shortCode":"BY"
      },
      {
        label:"Galguduud",
        "shortCode":"GA"
      },
      {
        label:"Gedo",
        "shortCode":"GE"
      },
      {
        label:"Hiiraan",
        "shortCode":"HI"
      },
      {
        label:"Jubbada Dhexe",
        "shortCode":"JD"
      },
      {
        label:"Jubbada Hoose",
        "shortCode":"JH"
      },
      {
        label:"Mudug",
        "shortCode":"MU"
      },
      {
        label:"Nugaal",
        "shortCode":"NU"
      },
      {
        label:"Sanaag",
        "shortCode":"SA"
      },
      {
        label:"Shabeellaha Dhexe",
        "shortCode":"SD"
      },
      {
        label:"Shabeellaha Hoose",
        "shortCode":"SH"
      },
      {
        label:"Sool",
        "shortCode":"SO"
      },
      {
        label:"Togdheer",
        "shortCode":"TO"
      },
      {
        label:"Woqooyi Galbeed",
        "shortCode":"WO"
      }
    ]
  },
  {
    label:"South Africa",
    "countryShortCode":"ZA",
    "regions":[
      {
        label:"Eastern Cape",
        "shortCode":"EC"
      },
      {
        label:"Free State",
        "shortCode":"FS"
      },
      {
        label:"Gauteng",
        "shortCode":"GT"
      },
      {
        label:"KwaZulu-Natal",
        "shortCode":"NL"
      },
      {
        label:"Limpopo",
        "shortCode":"LP"
      },
      {
        label:"Mpumalanga",
        "shortCode":"MP"
      },
      {
        label:"Northern Cape",
        "shortCode":"NC"
      },
      {
        label:"North West",
        "shortCode":"NW"
      },
      {
        label:"Western Cape",
        "shortCode":"WC"
      }
    ]
  },
  {
    label:"South Georgia and South Sandwich Islands",
    "countryShortCode":"GS",
    "regions":[
      {
        label:"Bird Island"
      },
      {
        label:"Bristol Island"
      },
      {
        label:"Clerke Rocks"
      },
      {
        label:"Montagu Island"
      },
      {
        label:"Saunders Island"
      },
      {
        label:"South Georgia"
      },
      {
        label:"Southern Thule"
      },
      {
        label:"Traversay Islands"
      }
    ]
  },
  {
    label:"South Sudan",
    "countryShortCode":"SS",
    "regions":[
      {
        label:"Central Equatoria",
        "shortCode":"CE"
      },
      {
        label:"Eastern Equatoria",
        "shortCode":"EE"
      },
      {
        label:"Jonglei",
        "shortCode":"JG"
      },
      {
        label:"Lakes",
        "shortCode":"LK"
      },
      {
        label:"Northern Bahr el Ghazal",
        "shortCode":"BN"
      },
      {
        label:"Unity",
        "shortCode":"UY"
      },
      {
        label:"Upper Nile",
        "shortCode":"NU"
      },
      {
        label:"Warrap",
        "shortCode":"WR"
      },
      {
        label:"Western Bahr el Ghazal",
        "shortCode":"BW"
      },
      {
        label:"Western Equatoria",
        "shortCode":"EW"
      }
    ]
  },
  {
    label:"Spain",
    "countryShortCode":"ES",
    "regions":[
      {
        label:"Albacete",
        "shortCode":"CM"
      },
      {
        label:"Alicante",
        "shortCode":"VC"
      },
      {
        label:"Almería",
        "shortCode":"AN"
      },
      {
        label:"Araba/Álava",
        "shortCode":"VI"
      },
      {
        label:"Asturias",
        "shortCode":"O"
      },
      {
        label:"Ávila",
        "shortCode":"AV"
      },
      {
        label:"Badajoz",
        "shortCode":"BA"
      },
      {
        label:"Barcelona",
        "shortCode":"B"
      },
      {
        label:"Bizkaia",
        "shortCode":"BI"
      },
      {
        label:"Burgos",
        "shortCode":"BU"
      },
      {
        label:"Cáceres",
        "shortCode":"CC"
      },
      {
        label:"Cádiz",
        "shortCode":"CA"
      },
      {
        label:"Cantabria",
        "shortCode":"S"
      },
      {
        label:"Castellón",
        "shortCode":"CS"
      },
      {
        label:"Cueta",
        "shortCode":"CU"
      },
      {
        label:"Ciudad Real",
        "shortCode":"CR"
      },
      {
        label:"Córdoba",
        "shortCode":"CO"
      },
      {
        label:"A Coruña",
        "shortCode":"C"
      },
      {
        label:"Cuenca",
        "shortCode":"CU"
      },
      {
        label:"Gipuzkoa",
        "shortCode":"SS"
      },
      {
        label:"Girona",
        "shortCode":"GI"
      },
      {
        label:"Granada",
        "shortCode":"GR"
      },
      {
        label:"Guadalajara",
        "shortCode":"GU"
      },
      {
        label:"Huelva",
        "shortCode":"H"
      },
      {
        label:"Huesca",
        "shortCode":"HU"
      },
      {
        label:"Illes Balears",
        "shortCode":"PM"
      },
      {
        label:"Jaén",
        "shortCode":"J"
      },
      {
        label:"León",
        "shortCode":"LE"
      },
      {
        label:"Lleida",
        "shortCode":"L"
      },
      {
        label:"Lugo",
        "shortCode":"LU"
      },
      {
        label:"Madrid",
        "shortCode":"M"
      },
      {
        label:"Málaga",
        "shortCode":"MA"
      },
      {
        label:"Melilla",
        "shortCode":"ML"
      },
      {
        label:"Murcia",
        "shortCode":"MU"
      },
      {
        label:"Navarre",
        "shortCode":"NA"
      },
      {
        label:"Ourense",
        "shortCode":"OR"
      },
      {
        label:"Palencia",
        "shortCode":"P"
      },
      {
        label:"Las Palmas",
        "shortCode":"GC"
      },
      {
        label:"Pontevedra",
        "shortCode":"PO"
      },
      {
        label:"La Rioja",
        "shortCode":"LO"
      },
      {
        label:"Salamanca",
        "shortCode":"SA"
      },
      {
        label:"Santa Cruz de Tenerife",
        "shortCode":"TF"
      },
      {
        label:"Segovia",
        "shortCode":"SG"
      },
      {
        label:"Sevilla",
        "shortCode":"SE"
      },
      {
        label:"Soria",
        "shortCode":"SO"
      },
      {
        label:"Tarragona",
        "shortCode":"T"
      },
      {
        label:"Teruel",
        "shortCode":"TE"
      },
      {
        label:"Toledo",
        "shortCode":"TO"
      },
      {
        label:"Valencia",
        "shortCode":"V"
      },
      {
        label:"Valladolid",
        "shortCode":"VA"
      },
      {
        label:"Zamora",
        "shortCode":"ZA"
      },
      {
        label:"Zaragoza",
        "shortCode":"Z"
      }
    ]
  },
  {
    label:"Sri Lanka",
    "countryShortCode":"LK",
    "regions":[
      {
        label:"Basnahira",
        "shortCode":"1"
      },
      {
        label:"Dakunu",
        "shortCode":"3"
      },
      {
        label:"Madhyama",
        "shortCode":"2"
      },
      {
        label:"Naegenahira",
        "shortCode":"5"
      },
      {
        label:"Sabaragamuwa",
        "shortCode":"9"
      },
      {
        label:"Uturu",
        "shortCode":"4"
      },
      {
        label:"Uturumaeda",
        "shortCode":"7"
      },
      {
        label:"Vayamba",
        "shortCode":"6"
      },
      {
        label:"Uva",
        "shortCode":"8"
      }
    ]
  },
  {
    label:"Sudan",
    "countryShortCode":"SD",
    "regions":[
      {
        label:"Al Bahr al Ahmar",
        "shortCode":"RS"
      },
      {
        label:"Al Jazirah",
        "shortCode":"GZ"
      },
      {
        label:"Al Khartum",
        "shortCode":"KH"
      },
      {
        label:"Al Qadarif",
        "shortCode":"GD"
      },
      {
        label:"An Nil al Abyad",
        "shortCode":"NW"
      },
      {
        label:"An Nil al Azraq",
        "shortCode":"NB"
      },
      {
        label:"Ash Shamaliyah",
        "shortCode":"NO"
      },
      {
        label:"Gharb Darfur",
        "shortCode":"DW"
      },
      {
        label:"Gharb Kurdufan",
        "shortCode":"GK"
      },
      {
        label:"Janub Darfur",
        "shortCode":"DS"
      },
      {
        label:"Janub Kurdufan",
        "shortCode":"KS"
      },
      {
        label:"Kassala",
        "shortCode":"KA"
      },
      {
        label:"Nahr an Nil",
        "shortCode":"NR"
      },
      {
        label:"Shamal Darfur",
        "shortCode":"DN"
      },
      {
        label:"Sharq Darfur",
        "shortCode":"DE"
      },
      {
        label:"Shiamal Kurdufan",
        "shortCode":"KN"
      },
      {
        label:"Sinnar",
        "shortCode":"SI"
      },
      {
        label:"Wasat Darfur Zalinjay",
        "shortCode":"DC"
      }
    ]
  },
  {
    label:"Suriname",
    "countryShortCode":"SR",
    "regions":[
      {
        label:"Brokopondo",
        "shortCode":"BR"
      },
      {
        label:"Commewijne",
        "shortCode":"CM"
      },
      {
        label:"Coronie",
        "shortCode":"CR"
      },
      {
        label:"Marowijne",
        "shortCode":"MA"
      },
      {
        label:"Nickerie",
        "shortCode":"NI"
      },
      {
        label:"Para",
        "shortCode":"PR"
      },
      {
        label:"Paramaribo",
        "shortCode":"PM"
      },
      {
        label:"Saramacca",
        "shortCode":"SA"
      },
      {
        label:"Sipaliwini",
        "shortCode":"SI"
      },
      {
        label:"Wanica",
        "shortCode":"WA"
      }
    ]
  },
  {
    label:"Swaziland",
    "countryShortCode":"SZ",
    "regions":[
      {
        label:"Hhohho",
        "shortCode":"HH"
      },
      {
        label:"Lubombo",
        "shortCode":"LU"
      },
      {
        label:"Manzini",
        "shortCode":"MA"
      },
      {
        label:"Shiselweni",
        "shortCode":"SH"
      }
    ]
  },
  {
    label:"Sweden",
    "countryShortCode":"SE",
    "regions":[
      {
        label:"Blekinge",
        "shortCode":"K"
      },
      {
        label:"Dalarnas",
        "shortCode":"W"
      },
      {
        label:"Gotlands",
        "shortCode":"X"
      },
      {
        label:"Gavleborgs",
        "shortCode":"I"
      },
      {
        label:"Hallands",
        "shortCode":"N"
      },
      {
        label:"Jamtlands",
        "shortCode":"Z"
      },
      {
        label:"Jonkopings",
        "shortCode":"F"
      },
      {
        label:"Kalmar",
        "shortCode":"H"
      },
      {
        label:"Kronobergs",
        "shortCode":"G"
      },
      {
        label:"Norrbottens",
        "shortCode":"BD"
      },
      {
        label:"Orebro",
        "shortCode":"T"
      },
      {
        label:"Ostergotlands",
        "shortCode":"E"
      },
      {
        label:"Skane",
        "shortCode":"M"
      },
      {
        label:"Sodermanlands",
        "shortCode":"D"
      },
      {
        label:"Stockholm",
        "shortCode":"AB"
      },
      {
        label:"Varmlands",
        "shortCode":"S"
      },
      {
        label:"Vasterbottens",
        "shortCode":"AC"
      },
      {
        label:"Vasternorrlands",
        "shortCode":"Y"
      },
      {
        label:"Vastmanlands",
        "shortCode":"U"
      },
      {
        label:"Vastra Gotalands",
        "shortCode":"O"
      }
    ]
  },
  {
    label:"Switzerland",
    "countryShortCode":"CH",
    "regions":[
      {
        label:"Aargau",
        "shortCode":"AG"
      },
      {
        label:"Appenzell Ausserrhoden",
        "shortCode":"AR"
      },
      {
        label:"Appenzell Innerhoden",
        "shortCode":"AI"
      },
      {
        label:"Basel-Landschaft",
        "shortCode":"BL"
      },
      {
        label:"Basel-Stadt",
        "shortCode":"BS"
      },
      {
        label:"Bern",
        "shortCode":"BE"
      },
      {
        label:"Fribourg",
        "shortCode":"FR"
      },
      {
        label:"Genève",
        "shortCode":"GE"
      },
      {
        label:"Glarus",
        "shortCode":"GL"
      },
      {
        label:"Graubünden",
        "shortCode":"GR"
      },
      {
        label:"Jura",
        "shortCode":"JU"
      },
      {
        label:"Luzern",
        "shortCode":"LU"
      },
      {
        label:"Neuchâtel",
        "shortCode":"NE"
      },
      {
        label:"Nidwalden",
        "shortCode":"NW"
      },
      {
        label:"Obwalden",
        "shortCode":"OW"
      },
      {
        label:"Sankt Gallen",
        "shortCode":"SG"
      },
      {
        label:"Schaffhausen",
        "shortCode":"SH"
      },
      {
        label:"Schwyz",
        "shortCode":"SZ"
      },
      {
        label:"Solothurn",
        "shortCode":"SO"
      },
      {
        label:"Thurgau",
        "shortCode":"TG"
      },
      {
        label:"Ticino",
        "shortCode":"TI"
      },
      {
        label:"Uri",
        "shortCode":"UR"
      },
      {
        label:"Valais",
        "shortCode":"VS"
      },
      {
        label:"Vaud",
        "shortCode":"VD"
      },
      {
        label:"Zug",
        "shortCode":"ZG"
      },
      {
        label:"Zürich",
        "shortCode":"ZH"
      }
    ]
  },
  {
    label:"Syrian Arab Republic",
    "countryShortCode":"SY",
    "regions":[
      {
        label:"Al Hasakah",
        "shortCode":"HA"
      },
      {
        label:"Al Ladhiqiyah",
        "shortCode":"LA"
      },
      {
        label:"Al Qunaytirah",
        "shortCode":"QU"
      },
      {
        label:"Ar Raqqah",
        "shortCode":"RA"
      },
      {
        label:"As Suwayda'",
        "shortCode":"SU"
      },
      {
        label:"Dar'a",
        "shortCode":"DR"
      },
      {
        label:"Dayr az Zawr",
        "shortCode":"DY"
      },
      {
        label:"Dimashq",
        "shortCode":"DI"
      },
      {
        label:"Halab",
        "shortCode":"HL"
      },
      {
        label:"Hamah",
        "shortCode":"HM"
      },
      {
        label:"Hims",
        "shortCode":"HI"
      },
      {
        label:"Idlib",
        "shortCode":"ID"
      },
      {
        label:"Rif Dimashq",
        "shortCode":"RD"
      },
      {
        label:"Tartus",
        "shortCode":"TA"
      }
    ]
  },
  {
    label:"Taiwan",
    "countryShortCode":"TW",
    "regions":[
      {
        label:"Chang-hua",
        "shortCode":"CHA"
      },
      {
        label:"Chia-i",
        "shortCode":"CYQ"
      },
      {
        label:"Hsin-chu",
        "shortCode":"HSQ"
      },
      {
        label:"Hua-lien",
        "shortCode":"HUA"
      },
      {
        label:"Kao-hsiung",
        "shortCode":"KHH"
      },
      {
        label:"Keelung",
        "shortCode":"KEE"
      },
      {
        label:"Kinmen",
        "shortCode":"KIN"
      },
      {
        label:"Lienchiang",
        "shortCode":"LIE"
      },
      {
        label:"Miao-li",
        "shortCode":"MIA"
      },
      {
        label:"Nan-t'ou",
        "shortCode":"NAN"
      },
      {
        label:"P'eng-hu",
        "shortCode":"PEN"
      },
      {
        label:"New Taipei",
        "shortCode":"NWT"
      },
      {
        label:"P'ing-chung",
        "shortCode":"PIF"
      },
      {
        label:"T'ai-chung",
        "shortCode":"TXG"
      },
      {
        label:"T'ai-nan",
        "shortCode":"TNN"
      },
      {
        label:"T'ai-pei",
        "shortCode":"TPE"
      },
      {
        label:"T'ai-tung",
        "shortCode":"TTT"
      },
      {
        label:"T'ao-yuan",
        "shortCode":"TAO"
      },
      {
        label:"Yi-lan",
        "shortCode":"ILA"
      },
      {
        label:"Yun-lin",
        "shortCode":"YUN"
      }
    ]
  },
  {
    label:"Tajikistan",
    "countryShortCode":"TJ",
    "regions":[
      {
        label:"Dushanbe",
        "shortCode":"DU"
      },
      {
        label:"Kŭhistoni Badakhshon",
        "shortCode":"GB"
      },
      {
        label:"Khatlon",
        "shortCode":"KT"
      },
      {
        label:"Sughd",
        "shortCode":"SU"
      }
    ]
  },
  {
    label:"Tanzania, United Republic of",
    "countryShortCode":"TZ",
    "regions":[
      {
        label:"Arusha",
        "shortCode":"01"
      },
      {
        label:"Coast",
        "shortCode":"19"
      },
      {
        label:"Dar es Salaam",
        "shortCode":"02"
      },
      {
        label:"Dodoma",
        "shortCode":"03"
      },
      {
        label:"Iringa",
        "shortCode":"04"
      },
      {
        label:"Kagera",
        "shortCode":"05"
      },
      {
        label:"Kigoma",
        "shortCode":"08"
      },
      {
        label:"Kilimanjaro",
        "shortCode":"09"
      },
      {
        label:"Lindi",
        "shortCode":"12"
      },
      {
        label:"Manyara",
        "shortCode":"26"
      },
      {
        label:"Mara",
        "shortCode":"13"
      },
      {
        label:"Mbeya",
        "shortCode":"14"
      },
      {
        label:"Morogoro",
        "shortCode":"16"
      },
      {
        label:"Mtwara",
        "shortCode":"17"
      },
      {
        label:"Mwanza",
        "shortCode":"18"
      },
      {
        label:"Pemba North",
        "shortCode":"06"
      },
      {
        label:"Pemba South",
        "shortCode":"10"
      },
      {
        label:"Rukwa",
        "shortCode":"20"
      },
      {
        label:"Ruvuma",
        "shortCode":"21"
      },
      {
        label:"Shinyanga",
        "shortCode":"22"
      },
      {
        label:"Singida",
        "shortCode":"23"
      },
      {
        label:"Tabora",
        "shortCode":"24"
      },
      {
        label:"Tanga",
        "shortCode":"25"
      },
      {
        label:"Zanzibar North",
        "shortCode":"07"
      },
      {
        label:"Zanzibar Central/South",
        "shortCode":"11"
      },
      {
        label:"Zanzibar Urban/West",
        "shortCode":"15"
      }
    ]
  },
  {
    label:"Thailand",
    "countryShortCode":"TH",
    "regions":[
      {
        label:"Amnat Charoen",
        "shortCode":"37"
      },
      {
        label:"Ang Thong",
        "shortCode":"15"
      },
      {
        label:"Bueng Kan",
        "shortCode":"38"
      },
      {
        label:"Buri Ram",
        "shortCode":"31"
      },
      {
        label:"Chachoengsao",
        "shortCode":"24"
      },
      {
        label:"Chai Nat",
        "shortCode":"18"
      },
      {
        label:"Chaiyaphum",
        "shortCode":"36"
      },
      {
        label:"Chanthaburi",
        "shortCode":"22"
      },
      {
        label:"Chiang Mai",
        "shortCode":"50"
      },
      {
        label:"Chiang Rai",
        "shortCode":"57"
      },
      {
        label:"Chon Buri",
        "shortCode":"20"
      },
      {
        label:"Chumphon",
        "shortCode":"86"
      },
      {
        label:"Kalasin",
        "shortCode":"46"
      },
      {
        label:"Kamphaeng Phet",
        "shortCode":"62"
      },
      {
        label:"Kanchanaburi",
        "shortCode":"71"
      },
      {
        label:"Khon Kaen",
        "shortCode":"40"
      },
      {
        label:"Krabi",
        "shortCode":"81"
      },
      {
        label:"Krung Thep Mahanakhon (Bangkok)",
        "shortCode":"10"
      },
      {
        label:"Lampang",
        "shortCode":"52"
      },
      {
        label:"Lamphun",
        "shortCode":"51"
      },
      {
        label:"Loei",
        "shortCode":"42"
      },
      {
        label:"Lop Buri",
        "shortCode":"16"
      },
      {
        label:"Mae Hong Son",
        "shortCode":"58"
      },
      {
        label:"Maha Sarakham",
        "shortCode":"44"
      },
      {
        label:"Mukdahan",
        "shortCode":"49"
      },
      {
        label:"Nakhon Nayok",
        "shortCode":"26"
      },
      {
        label:"Nakhon Phathom",
        "shortCode":"73"
      },
      {
        label:"Nakhon Phanom",
        "shortCode":"48"
      },
      {
        label:"Nakhon Ratchasima",
        "shortCode":"30"
      },
      {
        label:"Nakhon Sawan",
        "shortCode":"60"
      },
      {
        label:"Nakhon Si Thammarat",
        "shortCode":"80"
      },
      {
        label:"Nan",
        "shortCode":"55"
      },
      {
        label:"Narathiwat",
        "shortCode":"96"
      },
      {
        label:"Nong Bua Lam Phu",
        "shortCode":"39"
      },
      {
        label:"Nong Khai",
        "shortCode":"43"
      },
      {
        label:"Nonthaburi",
        "shortCode":"12"
      },
      {
        label:"Pathum Thani",
        "shortCode":"13"
      },
      {
        label:"Pattani",
        "shortCode":"94"
      },
      {
        label:"Phangnga",
        "shortCode":"82"
      },
      {
        label:"Phatthalung",
        "shortCode":"93"
      },
      {
        label:"Phayao",
        "shortCode":"56"
      },
      {
        label:"Phetchabun",
        "shortCode":"76"
      },
      {
        label:"Phetchaburi",
        "shortCode":"76"
      },
      {
        label:"Phichit",
        "shortCode":"66"
      },
      {
        label:"Phitsanulok",
        "shortCode":"65"
      },
      {
        label:"Phra Nakhon Si Ayutthaya",
        "shortCode":"14"
      },
      {
        label:"Phrae",
        "shortCode":"54"
      },
      {
        label:"Phuket",
        "shortCode":"83"
      },
      {
        label:"Prachin Buri",
        "shortCode":"25"
      },
      {
        label:"Prachuap Khiri Khan",
        "shortCode":"77"
      },
      {
        label:"Ranong",
        "shortCode":"85"
      },
      {
        label:"Ratchaburi",
        "shortCode":"70"
      },
      {
        label:"Rayong",
        "shortCode":"21"
      },
      {
        label:"Roi Et",
        "shortCode":"45"
      },
      {
        label:"Sa Kaeo",
        "shortCode":"27"
      },
      {
        label:"Sakon Nakhon",
        "shortCode":"47"
      },
      {
        label:"Samut Prakan",
        "shortCode":"11"
      },
      {
        label:"Samut Sakhon",
        "shortCode":"74"
      },
      {
        label:"Samut Songkhram",
        "shortCode":"75"
      },
      {
        label:"Saraburi",
        "shortCode":"19"
      },
      {
        label:"Satun",
        "shortCode":"91"
      },
      {
        label:"Sing Buri",
        "shortCode":"17"
      },
      {
        label:"Si Sa ket",
        "shortCode":"33"
      },
      {
        label:"Songkhla",
        "shortCode":"90"
      },
      {
        label:"Sukhothai",
        "shortCode":"64"
      },
      {
        label:"Suphan Buri",
        "shortCode":"72"
      },
      {
        label:"Surat Thani",
        "shortCode":"84"
      },
      {
        label:"Surin",
        "shortCode":"32"
      },
      {
        label:"Tak",
        "shortCode":"63"
      },
      {
        label:"Trang",
        "shortCode":"92"
      },
      {
        label:"Trat",
        "shortCode":"23"
      },
      {
        label:"Ubon Ratchathani",
        "shortCode":"34"
      },
      {
        label:"Udon Thani",
        "shortCode":"41"
      },
      {
        label:"Uthai Thani",
        "shortCode":"61"
      },
      {
        label:"Uttaradit",
        "shortCode":"53"
      },
      {
        label:"Yala",
        "shortCode":"95"
      },
      {
        label:"Yasothon",
        "shortCode":"35"
      }
    ]
  },
  {
    label:"Timor-Leste",
    "countryShortCode":"TL",
    "regions":[
      {
        label:"Aileu",
        "shortCode":"AL"
      },
      {
        label:"Ainaro",
        "shortCode":"AN"
      },
      {
        label:"Baucau",
        "shortCode":"BA"
      },
      {
        label:"Bobonaro",
        "shortCode":"BO"
      },
      {
        label:"Cova Lima",
        "shortCode":"CO"
      },
      {
        label:"Dili",
        "shortCode":"DI"
      },
      {
        label:"Ermera",
        "shortCode":"ER"
      },
      {
        label:"Lautem",
        "shortCode":"LA"
      },
      {
        label:"Liquica",
        "shortCode":"LI"
      },
      {
        label:"Manatuto",
        "shortCode":"MT"
      },
      {
        label:"Manufahi",
        "shortCode":"MF"
      },
      {
        label:"Oecussi",
        "shortCode":"OE"
      },
      {
        label:"Viqueque",
        "shortCode":"VI"
      }
    ]
  },
  {
    label:"Togo",
    "countryShortCode":"TG",
    "regions":[
      {
        label:"Centre",
        "shortCode":"C"
      },
      {
        label:"Kara",
        "shortCode":"K"
      },
      {
        label:"Maritime",
        "shortCode":"M"
      },
      {
        label:"Plateaux",
        "shortCode":"P"
      },
      {
        label:"Savannes",
        "shortCode":"S"
      }
    ]
  },
  {
    label:"Tokelau",
    "countryShortCode":"TK",
    "regions":[
      {
        label:"Atafu"
      },
      {
        label:"Fakaofo"
      },
      {
        label:"Nukunonu"
      }
    ]
  },
  {
    label:"Tonga",
    "countryShortCode":"TO",
    "regions":[
      {
        label:"'Eua",
        "shortCode":"01"
      },
      {
        label:"Ha'apai",
        "shortCode":"02"
      },
      {
        label:"Niuas",
        "shortCode":"03"
      },
      {
        label:"Tongatapu",
        "shortCode":"04"
      },
      {
        label:"Vava'u",
        "shortCode":"05"
      }
    ]
  },
  {
    label:"Trinidad and Tobago",
    "countryShortCode":"TT",
    "regions":[
      {
        label:"Arima",
        "shortCode":"ARI"
      },
      {
        label:"Chaguanas",
        "shortCode":"CHA"
      },
      {
        label:"Couva-Tabaquite-Talparo",
        "shortCode":"CTT"
      },
      {
        label:"Diefo Martin",
        "shortCode":"DMN"
      },
      {
        label:"Mayaro-Rio Claro",
        "shortCode":"MRC"
      },
      {
        label:"Penal-Debe",
        "shortCode":"PED"
      },
      {
        label:"Point Fortin",
        "shortCode":"PTF"
      },
      {
        label:"Port-of-Spain",
        "shortCode":"POS"
      },
      {
        label:"Princes Town",
        "shortCode":"PRT"
      },
      {
        label:"San Fernando",
        "shortCode":"SFO"
      },
      {
        label:"San Juan-Laventille",
        "shortCode":"SJL"
      },
      {
        label:"Sangre Grande",
        "shortCode":"SGE"
      },
      {
        label:"Siparia",
        "shortCode":"SIP"
      },
      {
        label:"Tobago",
        "shortCode":"TOB"
      },
      {
        label:"Tunapuna-Piarco",
        "shortCode":"TUP"
      }
    ]
  },
  {
    label:"Tunisia",
    "countryShortCode":"TN",
    "regions":[
      {
        label:"Ariana",
        "shortCode":"12"
      },
      {
        label:"Beja",
        "shortCode":"31"
      },
      {
        label:"Ben Arous",
        "shortCode":"13"
      },
      {
        label:"Bizerte",
        "shortCode":"23"
      },
      {
        label:"Gabes",
        "shortCode":"81"
      },
      {
        label:"Gafsa",
        "shortCode":"71"
      },
      {
        label:"Jendouba",
        "shortCode":"32"
      },
      {
        label:"Kairouan",
        "shortCode":"41"
      },
      {
        label:"Kasserine",
        "shortCode":"42"
      },
      {
        label:"Kebili",
        "shortCode":"73"
      },
      {
        label:"Kef",
        "shortCode":"33"
      },
      {
        label:"Mahdia",
        "shortCode":"53"
      },
      {
        label:"Medenine",
        "shortCode":"82"
      },
      {
        label:"Monastir",
        "shortCode":"52"
      },
      {
        label:"Nabeul",
        "shortCode":"21"
      },
      {
        label:"Sfax",
        "shortCode":"61"
      },
      {
        label:"Sidi Bouzid",
        "shortCode":"43"
      },
      {
        label:"Siliana",
        "shortCode":"34"
      },
      {
        label:"Sousse",
        "shortCode":"51"
      },
      {
        label:"Tataouine",
        "shortCode":"83"
      },
      {
        label:"Tozeur",
        "shortCode":"72"
      },
      {
        label:"Tunis",
        "shortCode":"11"
      },
      {
        label:"Zaghouan",
        "shortCode":"22"
      }
    ]
  },
  {
    label:"Turkey",
    "countryShortCode":"TR",
    "regions":[
      {
        label:"Adana",
        "shortCode":"01"
      },
      {
        label:"Adiyaman",
        "shortCode":"02"
      },
      {
        label:"Afyonkarahisar",
        "shortCode":"03"
      },
      {
        label:"Agri",
        "shortCode":"04"
      },
      {
        label:"Aksaray",
        "shortCode":"68"
      },
      {
        label:"Amasya",
        "shortCode":"05"
      },
      {
        label:"Ankara",
        "shortCode":"06"
      },
      {
        label:"Antalya",
        "shortCode":"07"
      },
      {
        label:"Ardahan",
        "shortCode":"75"
      },
      {
        label:"Artvin",
        "shortCode":"08"
      },
      {
        label:"Aydin",
        "shortCode":"09"
      },
      {
        label:"Balikesir",
        "shortCode":"10"
      },
      {
        label:"Bartin",
        "shortCode":"74"
      },
      {
        label:"Batman",
        "shortCode":"72"
      },
      {
        label:"Bayburt",
        "shortCode":"69"
      },
      {
        label:"Bilecik",
        "shortCode":"11"
      },
      {
        label:"Bingol",
        "shortCode":"12"
      },
      {
        label:"Bitlis",
        "shortCode":"13"
      },
      {
        label:"Bolu",
        "shortCode":"14"
      },
      {
        label:"Burdur",
        "shortCode":"15"
      },
      {
        label:"Bursa",
        "shortCode":"16"
      },
      {
        label:"Canakkale",
        "shortCode":"17"
      },
      {
        label:"Cankiri",
        "shortCode":"18"
      },
      {
        label:"Corum",
        "shortCode":"19"
      },
      {
        label:"Denizli",
        "shortCode":"20"
      },
      {
        label:"Diyarbakir",
        "shortCode":"21"
      },
      {
        label:"Duzce",
        "shortCode":"81"
      },
      {
        label:"Edirne",
        "shortCode":"22"
      },
      {
        label:"Elazig",
        "shortCode":"23"
      },
      {
        label:"Erzincan",
        "shortCode":"24"
      },
      {
        label:"Erzurum",
        "shortCode":"25"
      },
      {
        label:"Eskisehir",
        "shortCode":"26"
      },
      {
        label:"Gaziantep",
        "shortCode":"27"
      },
      {
        label:"Giresun",
        "shortCode":"28"
      },
      {
        label:"Gumushane",
        "shortCode":"29"
      },
      {
        label:"Hakkari",
        "shortCode":"30"
      },
      {
        label:"Hatay",
        "shortCode":"31"
      },
      {
        label:"Igdir",
        "shortCode":"76"
      },
      {
        label:"Isparta",
        "shortCode":"32"
      },
      {
        label:"Istanbul",
        "shortCode":"34"
      },
      {
        label:"Izmir",
        "shortCode":"35"
      },
      {
        label:"Kahramanmaras",
        "shortCode":"46"
      },
      {
        label:"Karabuk",
        "shortCode":"78"
      },
      {
        label:"Karaman",
        "shortCode":"70"
      },
      {
        label:"Kars",
        "shortCode":"36"
      },
      {
        label:"Kastamonu",
        "shortCode":"37"
      },
      {
        label:"Kayseri",
        "shortCode":"38"
      },
      {
        label:"Kilis",
        "shortCode":"79"
      },
      {
        label:"Kirikkale",
        "shortCode":"71"
      },
      {
        label:"Kirklareli",
        "shortCode":"39"
      },
      {
        label:"Kirsehir",
        "shortCode":"40"
      },
      {
        label:"Kocaeli",
        "shortCode":"41"
      },
      {
        label:"Konya",
        "shortCode":"42"
      },
      {
        label:"Kutahya",
        "shortCode":"43"
      },
      {
        label:"Malatya",
        "shortCode":"44"
      },
      {
        label:"Manisa",
        "shortCode":"45"
      },
      {
        label:"Mardin",
        "shortCode":"47"
      },
      {
        label:"Mersin",
        "shortCode":"33"
      },
      {
        label:"Mugla",
        "shortCode":"48"
      },
      {
        label:"Mus",
        "shortCode":"49"
      },
      {
        label:"Nevsehir",
        "shortCode":"50"
      },
      {
        label:"Nigde",
        "shortCode":"51"
      },
      {
        label:"Ordu",
        "shortCode":"52"
      },
      {
        label:"Osmaniye",
        "shortCode":"80"
      },
      {
        label:"Rize",
        "shortCode":"53"
      },
      {
        label:"Sakarya",
        "shortCode":"54"
      },
      {
        label:"Samsun",
        "shortCode":"55"
      },
      {
        label:"Sanliurfa",
        "shortCode":"63"
      },
      {
        label:"Siirt",
        "shortCode":"56"
      },
      {
        label:"Sinop",
        "shortCode":"57"
      },
      {
        label:"Sirnak",
        "shortCode":"73"
      },
      {
        label:"Sivas",
        "shortCode":"58"
      },
      {
        label:"Tekirdag",
        "shortCode":"59"
      },
      {
        label:"Tokat",
        "shortCode":"60"
      },
      {
        label:"Trabzon",
        "shortCode":"61"
      },
      {
        label:"Tunceli",
        "shortCode":"62"
      },
      {
        label:"Usak",
        "shortCode":"64"
      },
      {
        label:"Van",
        "shortCode":"65"
      },
      {
        label:"Yalova",
        "shortCode":"77"
      },
      {
        label:"Yozgat",
        "shortCode":"66"
      },
      {
        label:"Zonguldak",
        "shortCode":"67"
      }
    ]
  },
  {
    label:"Turkmenistan",
    "countryShortCode":"TM",
    "regions":[
      {
        label:"Ahal",
        "shortCode":"A"
      },
      {
        label: "Asgabat",
        "shortCode":"S"
      },
      {
        label:"Balkan",
        "shortCode":"B"
      },
      {
        label:"Dashoguz",
        "shortCode":"D"
      },
      {
        label:"Lebap",
        "shortCode":"L"
      },
      {
        label:"Mary",
        "shortCode":"M"
      }
    ]
  },
  {
    label:"Turks and Caicos Islands",
    "countryShortCode":"TC",
    "regions":[
      {
        label:"Turks and Caicos Islands"
      }
    ]
  },
  {
    label:"Tuvalu",
    "countryShortCode":"TV",
    "regions":[
      {
        label:"Funafuti",
        "shortCode":"FUN"
      },
      {
        label:"Nanumanga",
        "shortCode":"NMG"
      },
      {
        label:"Nanumea",
        "shortCode":"NMA"
      },
      {
        label:"Niutao",
        "shortCode":"NIT"
      },
      {
        label:"Nui",
        "shortCode":"NUI"
      },
      {
        label:"Nukufetau",
        "shortCode":"NKF"
      },
      {
        label:"Nukulaelae",
        "shortCode":"NKL"
      },
      {
        label:"Vaitupu",
        "shortCode":"VAU"
      }
    ]
  },
  {
    label:"Uganda",
    "countryShortCode":"UG",
    "regions":[
      {
        label:"Abim",
        "shortCode":"317"
      },
      {
        label:"Adjumani",
        "shortCode":"301"
      },
      {
        label:"Amolatar",
        "shortCode":"314"
      },
      {
        label:"Amuria",
        "shortCode":"216"
      },
      {
        label:"Amuru",
        "shortCode":"319"
      },
      {
        label:"Apac",
        "shortCode":"302"
      },
      {
        label:"Arua",
        "shortCode":"303"
      },
      {
        label:"Budaka",
        "shortCode":"217"
      },
      {
        label:"Bududa",
        "shortCode":"223"
      },
      {
        label:"Bugiri",
        "shortCode":"201"
      },
      {
        label:"Bukedea",
        "shortCode":"224"
      },
      {
        label:"Bukwa",
        "shortCode":"218"
      },
      {
        label:"Buliisa",
        "shortCode":"419"
      },
      {
        label:"Bundibugyo",
        "shortCode":"401"
      },
      {
        label:"Bushenyi",
        "shortCode":"402"
      },
      {
        label:"Busia",
        "shortCode":"202"
      },
      {
        label:"Butaleja",
        "shortCode":"219"
      },
      {
        label:"Dokolo",
        "shortCode":"318"
      },
      {
        label:"Gulu",
        "shortCode":"304"
      },
      {
        label:"Hoima",
        "shortCode":"403"
      },
      {
        label:"Ibanda",
        "shortCode":"416"
      },
      {
        label:"Iganga",
        "shortCode":"203"
      },
      {
        label:"Isingiro",
        "shortCode":"417"
      },
      {
        label:"Jinja",
        "shortCode":"204"
      },
      {
        label:"Kaabong",
        "shortCode":"315"
      },
      {
        label:"Kabale",
        "shortCode":"404"
      },
      {
        label:"Kabarole",
        "shortCode":"405"
      },
      {
        label:"Kaberamaido",
        "shortCode":"213"
      },
      {
        label:"Kalangala",
        "shortCode":"101"
      },
      {
        label:"Kaliro",
        "shortCode":"220"
      },
      {
        label:"Kampala",
        "shortCode":"102"
      },
      {
        label:"Kamuli",
        "shortCode":"205"
      },
      {
        label:"Kamwenge",
        "shortCode":"413"
      },
      {
        label:"Kanungu",
        "shortCode":"414"
      },
      {
        label:"Kapchorwa",
        "shortCode":"206"
      },
      {
        label:"Kasese",
        "shortCode":"406"
      },
      {
        label:"Katakwi",
        "shortCode":"207"
      },
      {
        label:"Kayunga",
        "shortCode":"112"
      },
      {
        label:"Kibaale",
        "shortCode":"407"
      },
      {
        label:"Kiboga",
        "shortCode":"103"
      },
      {
        label:"Kiruhura",
        "shortCode":"418"
      },
      {
        label:"Kisoro",
        "shortCode":"408"
      },
      {
        label:"Kitgum",
        "shortCode":"305"
      },
      {
        label:"Koboko",
        "shortCode":"316"
      },
      {
        label:"Kotido",
        "shortCode":"306"
      },
      {
        label:"Kumi",
        "shortCode":"208"
      },
      {
        label:"Kyenjojo",
        "shortCode":"415"
      },
      {
        label:"Lira",
        "shortCode":"307"
      },
      {
        label:"Luwero",
        "shortCode":"104"
      },
      {
        label:"Lyantonde",
        "shortCode":"116"
      },
      {
        label:"Manafwa",
        "shortCode":"221"
      },
      {
        label:"Maracha",
        "shortCode":"320"
      },
      {
        label:"Masaka",
        "shortCode":"105"
      },
      {
        label:"Masindi",
        "shortCode":"409"
      },
      {
        label:"Mayuge",
        "shortCode":"214"
      },
      {
        label:"Mbale",
        "shortCode":"209"
      },
      {
        label:"Mbarara",
        "shortCode":"410"
      },
      {
        label:"Mityana",
        "shortCode":"114"
      },
      {
        label:"Moroto",
        "shortCode":"308"
      },
      {
        label:"Moyo",
        "shortCode":"309"
      },
      {
        label:"Mpigi",
        "shortCode":"106"
      },
      {
        label:"Mubende",
        "shortCode":"107"
      },
      {
        label:"Mukono",
        "shortCode":"108"
      },
      {
        label:"Nakapiripirit",
        "shortCode":"311"
      },
      {
        label:"Nakaseke",
        "shortCode":"115"
      },
      {
        label:"Nakasongola",
        "shortCode":"109"
      },
      {
        label:"Namutumba",
        "shortCode":"222"
      },
      {
        label:"Nebbi",
        "shortCode":"310"
      },
      {
        label:"Ntungamo",
        "shortCode":"411"
      },
      {
        label:"Oyam",
        "shortCode":"321"
      },
      {
        label:"Pader",
        "shortCode":"312"
      },
      {
        label:"Pallisa",
        "shortCode":"210"
      },
      {
        label:"Rakai",
        "shortCode":"110"
      },
      {
        label:"Rukungiri",
        "shortCode":"412"
      },
      {
        label:"Sembabule",
        "shortCode":"111"
      },
      {
        label:"Sironko",
        "shortCode":"215"
      },
      {
        label:"Soroti",
        "shortCode":"211"
      },
      {
        label:"Tororo",
        "shortCode":"212"
      },
      {
        label:"Wakiso",
        "shortCode":"113"
      },
      {
        label:"Yumbe",
        "shortCode":"313"
      }
    ]
  },
  {
    label:"Ukraine",
    "countryShortCode":"UA",
    "regions":[
      {
        label:"Cherkasy",
        "shortCode":"71"
      },
      {
        label:"Chernihiv",
        "shortCode":"74"
      },
      {
        label:"Chernivtsi",
        "shortCode":"77"
      },
      {
        label:"Dnipropetrovsk",
        "shortCode":"12"
      },
      {
        label:"Donetsk",
        "shortCode":"14"
      },
      {
        label:"Ivano-Frankivsk",
        "shortCode":"26"
      },
      {
        label:"Kharkiv",
        "shortCode":"63"
      },
      {
        label:"Kherson",
        "shortCode":"65"
      },
      {
        label:"Khmelnytskyi",
        "shortCode":"68"
      },
      {
        label:"Kiev",
        "shortCode":"32"
      },
      {
        label:"Kirovohrad",
        "shortCode":"35"
      },
      {
        label:"Luhansk",
        "shortCode":"09"
      },
      {
        label:"Lviv",
        "shortCode":"46"
      },
      {
        label:"Mykolaiv",
        "shortCode":"48"
      },
      {
        label:"Odessa",
        "shortCode":"51"
      },
      {
        label:"Poltava",
        "shortCode":"53"
      },
      {
        label:"Rivne",
        "shortCode":"56"
      },
      {
        label:"Sumy",
        "shortCode":"59"
      },
      {
        label:"Ternopil",
        "shortCode":"61"
      },
      {
        label:"Vinnytsia",
        "shortCode":"05"
      },
      {
        label:"Volyn",
        "shortCode":"07"
      },
      {
        label:"Zakarpattia",
        "shortCode":"21"
      },
      {
        label:"Zaporizhia",
        "shortCode":"23"
      },
      {
        label:"Zhytomyr",
        "shortCode":"18"
      },
      {
        label:"Avtonomna Respublika Krym",
        "shortCode":"43"
      },
      {
        label:"Kyïv",
        "shortCode":"30"
      },
      {
        label:"Sevastopol",
        "shortCode":"40"
      }
    ]
  },
  {
    label:"United Arab Emirates",
    "countryShortCode":"AE",
    "regions":[
      {
        label:"Abu Dhabi",
        "shortCode":"AZ"
      },
      {
        label:"Ajman",
        "shortCode":"AJ"
      },
      {
        label:"Dubai",
        "shortCode":"DU"
      },
      {
        label:"Fujairah",
        "shortCode":"FU"
      },
      {
        label:"Ras al Khaimah",
        "shortCode":"RK"
      },
      {
        label:"Sharjah",
        "shortCode":"SH"
      },
      {
        label:"Umm Al Quwain",
        "shortCode":"UQ"
      }
    ]
  },
  {
    label:"United Kingdom",
    "countryShortCode":"GB",
    "regions":[
      {
        label:"Avon",
        "shortCode":"AVN"
      },
      {
        label:"Bedfordshire",
        "shortCode":"BDF"
      },
      {
        label:"Berkshire",
        "shortCode":"BRK"
      },
      {
        label:"Bristol, City of",
        "shortCode":"COB"
      },
      {
        label:"Buckinghamshire",
        "shortCode":"BKM"
      },
      {
        label:"Cambridgeshire",
        "shortCode":"CAM"
      },
      {
        label:"Cheshire",
        "shortCode":"CHS"
      },
      {
        label:"Cleveland",
        "shortCode":"CLV"
      },
      {
        label:"Cornwall",
        "shortCode":"CON"
      },
      {
        label:"Cumbria",
        "shortCode":"CMA"
      },
      {
        label:"Derbyshire",
        "shortCode":"DBY"
      },
      {
        label:"Devon",
        "shortCode":"DEV"
      },
      {
        label:"Dorset",
        "shortCode":"DOR"
      },
      {
        label:"Durham",
        "shortCode":"DUR"
      },
      {
        label:"East Sussex",
        "shortCode":"SXE"
      },
      {
        label:"Essex",
        "shortCode":"ESS"
      },
      {
        label:"Gloucestershire",
        "shortCode":"GLS"
      },
      {
        label:"Greater London",
        "shortCode":"LND"
      },
      {
        label:"Greater Manchester",
        "shortCode":"GTM"
      },
      {
        label:"Hampshire",
        "shortCode":"HAM"
      },
      {
        label:"Hereford and Worcester",
        "shortCode":"HWR"
      },
      {
        label:"Herefordshire",
        "shortCode":"HEF"
      },
      {
        label:"Hertfordshire",
        "shortCode":"HRT"
      },
      {
        label:"Isle of Wight",
        "shortCode":"IOW"
      },
      {
        label:"Kent",
        "shortCode":"KEN"
      },
      {
        label:"Lancashire",
        "shortCode":"LAN"
      },
      {
        label:"Leicestershire",
        "shortCode":"LEI"
      },
      {
        label:"Lincolnshire",
        "shortCode":"LIN"
      },
      {
        label:"London",
        "shortCode":"LDN"
      },
      {
        label:"Merseyside",
        "shortCode":"MSY"
      },
      {
        label:"Middlesex",
        "shortCode":"MDX"
      },
      {
        label:"Norfolk",
        "shortCode":"NFK"
      },
      {
        label:"Northamptonshire",
        "shortCode":"NTH"
      },
      {
        label:"Northumberland",
        "shortCode":"NBL"
      },
      {
        label:"North Humberside",
        "shortCode":"NHM"
      },
      {
        label:"North Yorkshire",
        "shortCode":"NYK"
      },
      {
        label:"Nottinghamshire",
        "shortCode":"NTT"
      },
      {
        label:"Oxfordshire",
        "shortCode":"OXF"
      },
      {
        label:"Rutland",
        "shortCode":"RUT"
      },
      {
        label:"Shropshire",
        "shortCode":"SAL"
      },
      {
        label:"Somerset",
        "shortCode":"SOM"
      },
      {
        label:"South Humberside",
        "shortCode":"SHM"
      },
      {
        label:"South Yorkshire",
        "shortCode":"SYK"
      },
      {
        label:"Staffordshire",
        "shortCode":"STS"
      },
      {
        label:"Suffolk",
        "shortCode":"SFK"
      },
      {
        label:"Surrey",
        "shortCode":"SRY"
      },
      {
        label:"Tyne and Wear",
        "shortCode":"TWR"
      },
      {
        label:"Warwickshire",
        "shortCode":"WAR"
      },
      {
        label:"West Midlands",
        "shortCode":"WMD"
      },
      {
        label:"West Sussex",
        "shortCode":"SXW"
      },
      {
        label:"West Yorkshire",
        "shortCode":"WYK"
      },
      {
        label:"Wiltshire",
        "shortCode":"WIL"
      },
      {
        label:"Worcestershire",
        "shortCode":"WOR"
      },
      {
        label:"Antrim",
        "shortCode":"ANT"
      },
      {
        label:"Armagh",
        "shortCode":"ARM"
      },
      {
        label:"Belfast, City of",
        "shortCode":"BLF"
      },
      {
        label:"Down",
        "shortCode":"DOW"
      },
      {
        label:"Fermanagh",
        "shortCode":"FER"
      },
      {
        label:"Londonderry",
        "shortCode":"LDY"
      },
      {
        label:"Derry, City of",
        "shortCode":"DRY"
      },
      {
        label:"Tyrone",
        "shortCode":"TYR"
      },
      {
        label:"Aberdeen, City of",
        "shortCode":"AN"
      },
      {
        label:"Aberdeenshire",
        "shortCode":"ABD"
      },
      {
        label:"Angus (Forfarshire)",
        "shortCode":"ANS"
      },
      {
        label:"Argyll",
        "shortCode":"AGB"
      },
      {
        label:"Ayrshire",
        "shortCode":"ARG"
      },
      {
        label:"Banffshire",
        "shortCode":"BAN"
      },
      {
        label:"Berwickshire",
        "shortCode":"BEW"
      },
      {
        label:"Bute",
        "shortCode":"BUT"
      },
      {
        label:"Caithness",
        "shortCode":"CAI"
      },
      {
        label:"Clackmannanshire",
        "shortCode":"CLK"
      },
      {
        label:"Cromartyshire",
        "shortCode":"COC"
      },
      {
        label:"Dumfriesshire",
        "shortCode":"DFS"
      },
      {
        label:"Dunbartonshire (Dumbarton)",
        "shortCode":"DNB"
      },
      {
        label:"Dundee, City of",
        "shortCode":"DD"
      },
      {
        label:"East Lothian (Haddingtonshire)",
        "shortCode":"ELN"
      },
      {
        label:"Edinburgh, City of",
        "shortCode":"EB"
      },
      {
        label:"Fife",
        "shortCode":"FIF"
      },
      {
        label:"Glasgow, City of",
        "shortCode":"GLA"
      },
      {
        label:"Inverness-shire",
        "shortCode":"INV"
      },
      {
        label:"Kincardineshire",
        "shortCode":"KCD"
      },
      {
        label:"Kinross-shire",
        "shortCode":"KRS"
      },
      {
        label:"Kirkcudbrightshire",
        "shortCode":"KKD"
      },
      {
        label:"Lanarkshire",
        "shortCode":"LKS"
      },
      {
        label:"Midlothian (County of Edinburgh)",
        "shortCode":"MLN"
      },
      {
        label:"Moray (Elginshire)",
        "shortCode":"MOR"
      },
      {
        label:"Nairnshire",
        "shortCode":"NAI"
      },
      {
        label:"Orkney",
        "shortCode":"OKI"
      },
      {
        label:"Peeblesshire",
        "shortCode":"PEE"
      },
      {
        label:"Perthshire",
        "shortCode":"PER"
      },
      {
        label:"Renfrewshire",
        "shortCode":"RFW"
      },
      {
        label:"Ross and Cromarty",
        "shortCode":"ROC"
      },
      {
        label:"Ross-shire",
        "shortCode":"ROS"
      },
      {
        label:"Roxburghshire",
        "shortCode":"ROX"
      },
      {
        label:"Selkirkshire",
        "shortCode":"SEL"
      },
      {
        label:"Shetland (Zetland)",
        "shortCode":"SHI"
      },
      {
        label:"Stirlingshire",
        "shortCode":"STI"
      },
      {
        label:"Sutherland",
        "shortCode":"SUT"
      },
      {
        label:"West Lothian (Linlithgowshire)",
        "shortCode":"WLN"
      },
      {
        label:"Wigtownshire",
        "shortCode":"WIG"
      },
      {
        label:"Clwyd",
        "shortCode":"CWD"
      },
      {
        label:"Dyfed",
        "shortCode":"DFD"
      },
      {
        label:"Gwent",
        "shortCode":"GNT"
      },
      {
        label:"Gwynedd",
        "shortCode":"GWN"
      },
      {
        label:"Mid Glamorgan",
        "shortCode":"MGM"
      },
      {
        label:"Powys",
        "shortCode":"POW"
      },
      {
        label:"South Glamorgan",
        "shortCode":"SGM"
      },
      {
        label:"West Glamorgan",
        "shortCode":"WGM"
      }
    ]
  },
  {
    label:"United States",
    "countryShortCode":"US",
    "regions":[
      {
        label:"Alabama",
        "shortCode":"AL"
      },
      {
        label:"Alaska",
        "shortCode":"AK"
      },
      {
        label:"American Samoa",
        "shortCode":"AS"
      },
      {
        label:"Arizona",
        "shortCode":"AZ"
      },
      {
        label:"Arkansas",
        "shortCode":"AR"
      },
      {
        label:"California",
        "shortCode":"CA"
      },
      {
        label:"Colorado",
        "shortCode":"CO"
      },
      {
        label:"Connecticut",
        "shortCode":"CT"
      },
      {
        label:"Delaware",
        "shortCode":"DE"
      },
      {
        label:"District of Columbia",
        "shortCode":"DC"
      },
      {
        label:"Micronesia",
        "shortCode":"FM"
      },
      {
        label:"Florida",
        "shortCode":"FL"
      },
      {
        label:"Georgia",
        "shortCode":"GA"
      },
      {
        label:"Guam",
        "shortCode":"GU"
      },
      {
        label:"Hawaii",
        "shortCode":"HI"
      },
      {
        label:"Idaho",
        "shortCode":"ID"
      },
      {
        label:"Illinois",
        "shortCode":"IL"
      },
      {
        label:"Indiana",
        "shortCode":"IN"
      },
      {
        label:"Iowa",
        "shortCode":"IA"
      },
      {
        label:"Kansas",
        "shortCode":"KS"
      },
      {
        label:"Kentucky",
        "shortCode":"KY"
      },
      {
        label:"Louisiana",
        "shortCode":"LA"
      },
      {
        label:"Maine",
        "shortCode":"ME"
      },
      {
        label:"Marshall Islands",
        "shortCode":"MH"
      },
      {
        label:"Maryland",
        "shortCode":"MD"
      },
      {
        label:"Massachusetts",
        "shortCode":"MA"
      },
      {
        label:"Michigan",
        "shortCode":"MI"
      },
      {
        label:"Minnesota",
        "shortCode":"MN"
      },
      {
        label:"Mississippi",
        "shortCode":"MS"
      },
      {
        label:"Missouri",
        "shortCode":"MO"
      },
      {
        label:"Montana",
        "shortCode":"MT"
      },
      {
        label:"Nebraska",
        "shortCode":"NE"
      },
      {
        label:"Nevada",
        "shortCode":"NV"
      },
      {
        label:"New Hampshire",
        "shortCode":"NH"
      },
      {
        label:"New Jersey",
        "shortCode":"NJ"
      },
      {
        label:"New Mexico",
        "shortCode":"NM"
      },
      {
        label:"New York",
        "shortCode":"NY"
      },
      {
        label:"North Carolina",
        "shortCode":"NC"
      },
      {
        label:"North Dakota",
        "shortCode":"ND"
      },
      {
        label:"Northern Mariana Islands",
        "shortCode":"MP"
      },
      {
        label:"Ohio",
        "shortCode":"OH"
      },
      {
        label:"Oklahoma",
        "shortCode":"OK"
      },
      {
        label:"Oregon",
        "shortCode":"OR"
      },
      {
        label:"Palau",
        "shortCode":"PW"
      },
      {
        label:"Pennsylvania",
        "shortCode":"PA"
      },
      {
        label:"Puerto Rico",
        "shortCode":"PR"
      },
      {
        label:"Rhode Island",
        "shortCode":"RI"
      },
      {
        label:"South Carolina",
        "shortCode":"SC"
      },
      {
        label:"South Dakota",
        "shortCode":"SD"
      },
      {
        label:"Tennessee",
        "shortCode":"TN"
      },
      {
        label:"Texas",
        "shortCode":"TX"
      },
      {
        label:"Utah",
        "shortCode":"UT"
      },
      {
        label:"Vermont",
        "shortCode":"VT"
      },
      {
        label:"Virgin Islands",
        "shortCode":"VI"
      },
      {
        label:"Virginia",
        "shortCode":"VA"
      },
      {
        label:"Washington",
        "shortCode":"WA"
      },
      {
        label:"West Virginia",
        "shortCode":"WV"
      },
      {
        label:"Wisconsin",
        "shortCode":"WI"
      },
      {
        label:"Wyoming",
        "shortCode":"WY"
      },
      {
        label:"Armed Forces Americas",
        "shortCode":"AA"
      },
      {
        label:"Armed Forces Europe, Canada, Africa and Middle East",
        "shortCode":"AE"
      },
      {
        label:"Armed Forces Pacific",
        "shortCode":"AP"
      }
    ]
  },
  {
    label:"United States Minor Outlying Islands",
    "countryShortCode":"UM",
    "regions":[
      {
        label:"Baker Island",
        "shortCode":"81"
      },
      {
        label:"Howland Island",
        "shortCode":"84"
      },
      {
        label:"Jarvis Island",
        "shortCode":"86"
      },
      {
        label:"Johnston Atoll",
        "shortCode":"67"
      },
      {
        label:"Kingman Reef",
        "shortCode":"89"
      },
      {
        label:"Midway Islands",
        "shortCode":"71"
      },
      {
        label:"Navassa Island",
        "shortCode":"76"
      },
      {
        label:"Palmyra Atoll",
        "shortCode":"95"
      },
      {
        label:"Wake Island",
        "shortCode":"79"
      },
      {
        label:"Bajo Nuevo Bank",
        "shortCode":"BN"
      },
      {
        label:"Serranilla Bank",
        "shortCode":"SB"
      }
    ]
  },
  {
    label:"Uruguay",
    "countryShortCode":"UY",
    "regions":[
      {
        label:"Artigas",
        "shortCode":"AR"
      },
      {
        label:"Canelones",
        "shortCode":"CA"
      },
      {
        label:"Cerro Largo",
        "shortCode":"CL"
      },
      {
        label:"Colonia",
        "shortCode":"CO"
      },
      {
        label:"Durazno",
        "shortCode":"DU"
      },
      {
        label:"Flores",
        "shortCode":"FS"
      },
      {
        label:"Florida",
        "shortCode":"FD"
      },
      {
        label:"Lavalleja",
        "shortCode":"LA"
      },
      {
        label:"Maldonado",
        "shortCode":"MA"
      },
      {
        label:"Montevideo",
        "shortCode":"MO"
      },
      {
        label:"Paysandú",
        "shortCode":"PA"
      },
      {
        label:"Río Negro",
        "shortCode":"RN"
      },
      {
        label:"Rivera",
        "shortCode":"RV"
      },
      {
        label:"Rocha",
        "shortCode":"RO"
      },
      {
        label:"Salto",
        "shortCode":"SA"
      },
      {
        label:"San José",
        "shortCode":"SJ"
      },
      {
        label:"Soriano",
        "shortCode":"SO"
      },
      {
        label:"Tacuarembó",
        "shortCode":"TA"
      },
      {
        label:"Treinta y Tres",
        "shortCode":"TT"
      }
    ]
  },
  {
    label:"Uzbekistan",
    "countryShortCode":"UZ",
    "regions":[
      {
        label:"Toshkent shahri",
        "shortCode":"TK"
      },
      {
        label:"Andijon",
        "shortCode":"AN"
      },
      {
        label:"Buxoro",
        "shortCode":"BU"
      },
      {
        label:"Farg‘ona",
        "shortCode":"FA"
      },
      {
        label:"Jizzax",
        "shortCode":"JI"
      },
      {
        label:"Namangan",
        "shortCode":"NG"
      },
      {
        label:"Navoiy",
        "shortCode":"NW"
      },
      {
        label:"Qashqadaryo (Qarshi)",
        "shortCode":"QA"
      },
      {
        label:"Samarqand",
        "shortCode":"SA"
      },
      {
        label:"Sirdaryo (Guliston)",
        "shortCode":"SI"
      },
      {
        label:"Surxondaryo (Termiz)",
        "shortCode":"SU"
      },
      {
        label:"Toshkent wiloyati",
        "shortCode":"TO"
      },
      {
        label:"Xorazm (Urganch)",
        "shortCode":"XO"
      },
      {
        label:"Qoraqalpog‘iston Respublikasi (Nukus)",
        "shortCode":"QR"
      }
    ]
  },
  {
    label:"Vanuatu",
    "countryShortCode":"VU",
    "regions":[
      {
        label:"Malampa",
        "shortCode":"MAP"
      },
      {
        label:"Pénama",
        "shortCode":"PAM"
      },
      {
        label:"Sanma",
        "shortCode":"SAM"
      },
      {
        label:"Shéfa",
        "shortCode":"SEE"
      },
      {
        label:"Taféa",
        "shortCode":"TAE"
      },
      {
        label:"Torba",
        "shortCode":"TOB"
      }
    ]
  },
  {
    label:"Venezuela, Bolivarian Republic of",
    "countryShortCode":"VE",
    "regions":[
      {
        label:"Dependencias Federales",
        "shortCode":"W"
      },
      {
        label:"Distrito Federal",
        "shortCode":"A"
      },
      {
        label:"Amazonas",
        "shortCode":"Z"
      },
      {
        label:"Anzoátegui",
        "shortCode":"B"
      },
      {
        label:"Apure",
        "shortCode":"C"
      },
      {
        label:"Aragua",
        "shortCode":"D"
      },
      {
        label:"Barinas",
        "shortCode":"E"
      },
      {
        label:"Bolívar",
        "shortCode":"F"
      },
      {
        label:"Carabobo",
        "shortCode":"G"
      },
      {
        label:"Cojedes",
        "shortCode":"H"
      },
      {
        label:"Delta Amacuro",
        "shortCode":"Y"
      },
      {
        label:"Falcón",
        "shortCode":"I"
      },
      {
        label:"Guárico",
        "shortCode":"J"
      },
      {
        label:"Lara",
        "shortCode":"K"
      },
      {
        label:"Mérida",
        "shortCode":"L"
      },
      {
        label:"Miranda",
        "shortCode":"M"
      },
      {
        label:"Monagas",
        "shortCode":"N"
      },
      {
        label:"Nueva Esparta",
        "shortCode":"O"
      },
      {
        label:"Portuguesa",
        "shortCode":"P"
      },
      {
        label:"Sucre",
        "shortCode":"R"
      },
      {
        label:"Táchira",
        "shortCode":"S"
      },
      {
        label:"Trujillo",
        "shortCode":"T"
      },
      {
        label:"Vargas",
        "shortCode":"X"
      },
      {
        label:"Yaracuy",
        "shortCode":"U"
      },
      {
        label:"Zulia",
        "shortCode":"V"
      }
    ]
  },
  {
    label:"Vietnam",
    "countryShortCode":"VN",
    "regions":[
      {
        label:"Đồng Nai",
        "shortCode":"39"
      },
      {
        label:"Đồng Tháp",
        "shortCode":"45"
      },
      {
        label:"Gia Lai",
        "shortCode":"30"
      },
      {
        label:"Hà Giang",
        "shortCode":"03"
      },
      {
        label:"Hà Nam",
        "shortCode":"63"
      },
      {
        label:"Hà Tây",
        "shortCode":"15"
      },
      {
        label:"Hà Tĩnh",
        "shortCode":"23"
      },
      {
        label:"Hải Dương",
        "shortCode":"61"
      },
      {
        label:"Hậu Giang",
        "shortCode":"73"
      },
      {
        label:"Hòa Bình",
        "shortCode":"14"
      },
      {
        label:"Hưng Yên",
        "shortCode":"66"
      },
      {
        label:"Khánh Hòa",
        "shortCode":"34"
      },
      {
        label:"Kiên Giang",
        "shortCode":"47"
      },
      {
        label:"Kon Tum",
        "shortCode":"28"
      },
      {
        label:"Lai Châu",
        "shortCode":"01"
      },
      {
        label:"Lâm Đồng",
        "shortCode":"35"
      },
      {
        label:"Lạng Sơn",
        "shortCode":"09"
      },
      {
        label:"Lào Cai",
        "shortCode":"02"
      },
      {
        label:"Long An",
        "shortCode":"41"
      },
      {
        label:"Nam Định",
        "shortCode":"67"
      },
      {
        label:"Nghệ An",
        "shortCode":"22"
      },
      {
        label:"Ninh Bình",
        "shortCode":"18"
      },
      {
        label:"Ninh Thuận",
        "shortCode":"36"
      },
      {
        label:"Phú Thọ",
        "shortCode":"68"
      },
      {
        label:"Phú Yên",
        "shortCode":"32"
      },
      {
        label:"Quảng Bình",
        "shortCode":"24"
      },
      {
        label:"Quảng Nam",
        "shortCode":"27"
      },
      {
        label:"Quảng Ngãi",
        "shortCode":"29"
      },
      {
        label:"Quảng Ninh",
        "shortCode":"13"
      },
      {
        label:"Quảng Trị",
        "shortCode":"25"
      },
      {
        label:"Sóc Trăng",
        "shortCode":"52"
      },
      {
        label:"Sơn La",
        "shortCode":"05"
      },
      {
        label:"Tây Ninh",
        "shortCode":"37"
      },
      {
        label:"Thái Bình",
        "shortCode":"20"
      },
      {
        label:"Thái Nguyên",
        "shortCode":"69"
      },
      {
        label:"Thanh Hóa",
        "shortCode":"21"
      },
      {
        label:"Thừa Thiên–Huế",
        "shortCode":"26"
      },
      {
        label:"Tiền Giang",
        "shortCode":"46"
      },
      {
        label:"Trà Vinh",
        "shortCode":"51"
      },
      {
        label:"Tuyên Quang",
        "shortCode":"07"
      },
      {
        label:"Vĩnh Long",
        "shortCode":"49"
      },
      {
        label:"Vĩnh Phúc",
        "shortCode":"70"
      },
      {
        label:"Yên Bái",
        "shortCode":"06"
      },
      {
        label:"Cần Thơ",
        "shortCode":"CT"
      },
      {
        label:"Đà Nẵng",
        "shortCode":"DN"
      },
      {
        label:"Hà Nội",
        "shortCode":"HN"
      },
      {
        label:"Hải Phòng",
        "shortCode":"HP"
      },
      {
        label:"Hồ Chí Minh (Sài Gòn)",
        "shortCode":"SG"
      }
    ]
  },
  {
    label:"Virgin Islands, British",
    "countryShortCode":"VG",
    "regions":[
      {
        label:"Anegada",
        "shortCode":"ANG"
      },
      {
        label:"Jost Van Dyke",
        "shortCode":"JVD"
      },
      {
        label:"Tortola",
        "shortCode":"TTA"
      },
      {
        label:"Virgin Gorda",
        "shortCode":"VGD"
      }
    ]
  },
  {
    label:"Virgin Islands, U.S.",
    "countryShortCode":"VI",
    "regions":[
      {
        label:"St. Thomas",
        "shortCode":"STH"
      },
      {
        label:"St. John",
        "shortCode":"SJO"
      },
      {
        label:"St. Croix",
        "shortCode":"SCR"
      }
    ]
  },
  {
    label:"Wallis and Futuna",
    "countryShortCode":"WF",
    "regions":[
      {
        label:"Alo",
        "shortCode":"ALO"
      },
      {
        label:"Sigave",
        "shortCode":"SIG"
      },
      {
        label:"Wallis",
        "shortCode":"WAL"
      }
    ]
  },
  {
    label:"Western Sahara",
    "countryShortCode":"EH",
    "regions":[
      {
        label:"Es Smara",
        "shortCode":"ESM"
      },
      {
        label:"Boujdour",
        "shortCode":"BOD"
      },
      {
        label:"Laâyoune",
        "shortCode":"LAA"
      },
      {
        label:"Aousserd",
        "shortCode":"AOU"
      },
      {
        label:"Oued ed Dahab",
        "shortCode":"OUD"
      }
    ]
  },
  {
    label:"Yemen",
    "countryShortCode":"YE",
    "regions":[
      {
        label:"Abyān",
        "shortCode":"AB"
      },
      {
        label:"'Adan",
        "shortCode":"AD"
      },
      {
        label:"Aḑ Ḑāli'",
        "shortCode":"DA"
      },
      {
        label:"Al Bayḑā'",
        "shortCode":"BA"
      },
      {
        label:"Al Ḩudaydah",
        "shortCode":"HU"
      },
      {
        label:"Al Jawf",
        "shortCode":"JA"
      },
      {
        label:"Al Mahrah",
        "shortCode":"MR"
      },
      {
        label:"Al Maḩwīt",
        "shortCode":"MW"
      },
      {
        label:"'Amrān",
        "shortCode":"AM"
      },
      {
        label:"Dhamār",
        "shortCode":"DH"
      },
      {
        label:"Ḩaḑramawt",
        "shortCode":"HD"
      },
      {
        label:"Ḩajjah",
        "shortCode":"HJ"
      },
      {
        label:"Ibb",
        "shortCode":"IB"
      },
      {
        label:"Laḩij",
        "shortCode":"LA"
      },
      {
        label:"Ma'rib",
        "shortCode":"MA"
      },
      {
        label:"Raymah",
        "shortCode":"RA"
      },
      {
        label:"Şā‘dah",
        "shortCode":"SD"
      },
      {
        label:"Şan‘ā'",
        "shortCode":"SN"
      },
      {
        label:"Shabwah",
        "shortCode":"SH"
      },
      {
        label:"Tā‘izz",
        "shortCode":"TA"
      }
    ]
  },
  {
    label:"Zambia",
    "countryShortCode":"ZM",
    "regions":[
      {
        label:"Central",
        "shortCode":"02"
      },
      {
        label:"Copperbelt",
        "shortCode":"08"
      },
      {
        label:"Eastern",
        "shortCode":"03"
      },
      {
        label:"Luapula",
        "shortCode":"04"
      },
      {
        label:"Lusaka",
        "shortCode":"09"
      },
      {
        label:"Northern",
        "shortCode":"05"
      },
      {
        label:"North-Western",
        "shortCode":"06"
      },
      {
        label:"Southern",
        "shortCode":"07"
      },
      {
        label:"Western",
        "shortCode":"01"
      }
    ]
  },
  {
    label:"Zimbabwe",
    "countryShortCode":"ZW",
    "regions":[
      {
        label:"Bulawayo",
        "shortCode":"BU"
      },
      {
        label:"Harare",
        "shortCode":"HA"
      },
      {
        label:"Manicaland",
        "shortCode":"MA"
      },
      {
        label:"Mashonaland Central",
        "shortCode":"MC"
      },
      {
        label:"Mashonaland East",
        "shortCode":"ME"
      },
      {
        label:"Mashonaland West",
        "shortCode":"MW"
      },
      {
        label:"Masvingo",
        "shortCode":"MV"
      },
      {
        label:"Matabeleland North",
        "shortCode":"MN"
      },
      {
        label:"Matabeleland South",
        "shortCode":"MS"
      },
      {
        label:"Midlands",
        "shortCode":"MI"
      }
    ]
  }
];

export {
  regions,
  phonecodes
} 