import Vue from 'vue'
import VueSanitize from "vue-sanitize";

let defaultOptions = {
    allowedTags: ['a', 'button','b','img','label','div','span','iframe','style','br','p'],
    allowedAttributes: {
      'a': [ 'style', 'href' ],
      'b': ['style' ],
      'button': ['style' ],
      'img': ['style' ],
      'label': ['style'],
      'p': ['style'],
      'div': ['style' ],
      'span': ['style'],
      'iframe':['src','height','width','style']
    }
};
Vue.use(VueSanitize, defaultOptions);

