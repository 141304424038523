
<template>
  <modal name="modal-payment"
        transition="nice-modal-fade"
        classes="ngdialog-content"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen">
    <div class="modal_wrapper">
    <div  slot="top-right">
        <button @click="$modal.hide('modal-payment')" class="ngdialog-close"></button>
    </div>
    <div class="modalSusc">
        <div class="edicion_block">
            <h2 class="mt10 mb20"><b>{{$t('modals.tipode')}}</b></h2>
            <div class="tab_wrapper">
                <div class="tab_nav">
                    <div @click="monthyear=1" :class="monthyear==1?'radiobutton_group active':'radiobutton_group '">
                        <label for="radioSusc01">
                            <h6>{{$t('modals.anual')}} (25% OFF)</h6>
                            <span v-if='product!=null && product.plans != undefined'>USD {{product.plans[1].price}}</span>
                        </label>
                    </div>

                    <div @click="monthyear=0" :class="monthyear==0?'radiobutton_group active':'radiobutton_group '">
                        <label for="radioSusc02">
                            <h6>{{$t('modals.mensual')}}</h6>
                            <span v-if='product!=null && product.plans != undefined'>USD {{product.plans[0].price}}</span>
                        </label>
                    </div>
                </div>

                <form @submit.prevent="pay()" class="tab_content">
                    <h2 class="mt20 mb20"><b>{{$t('modals.info')}}</b></h2>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <card-number class="form-control dark input_wicon input_cardnumber stripe-element card-number" 
                            ref='cardNumber'
                            :stripe='stripeKey'
                            :options='stripeOptions'
                            @change='number = $event.complete'/>
                        </div>
                        <div class="form-group col-sm-6">
                            <card-expiry class='form-control dark input_wicon input_date stripe-element card-expiry'
                            ref='cardExpiry'
                            :stripe='stripeKey'
                            :options='stripeOptions'
                            @change='expiry = $event.complete'/>
                        </div>
                        <div class="form-group col-sm-6">
                            <card-cvc class='form-control dark input_wicon input_cardnumber stripe-element card-number'
                            ref='cardCvc'
                            :stripe='stripeKey'
                            :options='stripeOptions'
                            @change='cvc = $event.complete'
                            />
                        </div>
                        <!--
                        <div class="col-sm-12 text-left mb20 checkbox_wrapper">
                            <input class="form-control dark " placeholder="Coupon" type="text" />
                        </div>-->
                        <div class="col-sm-12 text-left mb20 checkbox_wrapper">
                            <span class=" d_inlineb mt-3 t_13px d_block text-center">{{$t('login.copy1')}} <a  style="color:#3290E4 !important;" :href="$util.termsURL()">{{$t('login.copy2')}}</a> {{$t('login.copy4')}} <a style="color:#3290E4 !important;" :href="$util.termsURL()">{{$t('login.copy3')}}</a>.</span>
                        </div>

                    </div>

                    <div class="text-center">
                        <client-only><button-spinner :is-loading="isLoading" :disabled="isLoading" :status="status" class="btn btn-secondary w-100" >{{$t('billing.confirm')}}</button-spinner></client-only>
                    
                    </div>

                </form>
            </div>
    </div>
</div>   
</div>
</modal>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc ,Card, createToken} from 'vue-stripe-elements-plus'
import { functions,stripe_public } from '@/services/firebaseConfig.js'

export default {
    data () {
        return {
            complete: false,
            number: false,
            expiry: false,
            cvc: false,
            cupon:'',
            place:'modal',
            name:false,
            zip:false,
            monthyear:1,
            stripeOptions: {
                // see https://stripe.com/docs/stripe.js#element-options for details
            },
            stripeKey:stripe_public,
            product:'',
            getUser:'',
            //Button
            isLoading: false,
            status: '',
        }
    },
    methods: {
        beforeOpen (event) {
            var dis=this;
            if(event.params.place)this.place=event.params.place;  
            this.product=event.params.product;  
            this.getUser=event.params.user 
        },
        async pay () {
            this.isLoading=true;
            var dis=this;
            const {token,error} = await createToken();
            if(token==undefined){
                dis.isLoading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                dis.$util.notif_error(error);
                return;
            }
            //Toma el plan segun la ubicacion del check
            //1 es monthly
            //0 es yearly
            var plan_id=dis.product.plans[dis.monthyear].plan_id;
            const updateSubscriptions = functions.httpsCallable('updateSubscriptions');
            updateSubscriptions({uid:dis.getUser.uid,source: token.id,plan_id:plan_id,type:dis.product.type,prod_id:dis.product.prod_id,customer_id:dis.getUser.stripe_details.stripe_customer_id})
            .then(function(result) {
                dis.getUser.stripe_details=result.data
                dis.$util.notif_success(dis.$t('notis.pago'));
                dis.$util.registerStat_old('payment',{price:dis.product.plans[dis.monthyear].price,place:dis.place})
                dis.isLoading = false
                dis.$modal.hide('modal-payment')
                dis.$modal.hide('modal-planes')
                dis.status = true
                setTimeout(() => { dis.status = '' }, 1000)
            }).catch(function(err) {
                dis.isLoading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                dis.$util.notif_error('ERROR:<br>'+err);
                //dis.$util.notif_error(dis.$t('billing.pagosint'));
            });
        },
    },
    components: { CardNumber, CardExpiry, CardCvc  },
}
</script>
<style>
</style>


