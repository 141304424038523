<template>
    <div class="edicion_block">
        <h4>{{$t('drags.space')}}</h4>
        <sizePicker v-model="valor.size" :min="20" :max="100" :interval="10"/>
    </div>
</template>

<script>
import sizePicker from '../dinputs/sizePicker'

export default {
    props: ['value'],
	data() {
		return { 
            valor:this.value,
		}
    },
    components:{
        sizePicker,
    }
}
</script>


