<template>
<div class="mb-3">
	<label style="display:block;">{{$t('pickers.fuente')}}</label>
	<!--<font-picker :api-key="'AIzaSyBwfGKibAwdi1IYP8pOnTVBfuTsByKrvn8'" @change="myFunc"></font-picker>-->
	<multiselect v-model="inputVal" 
		:allow-empty="false"
		:options="options" 
		:option-height="104" 
		selectLabel="">
		<template slot="singleLabel" slot-scope="props">{{ props.option }}</template>
    	<template slot="option" slot-scope="props">
        <span :style="'color:black;font-family: '+props.option"> {{ props.option }} </span>
    </template>
	</multiselect>
</div>
</template>

<script>
export default {
	props: ['value'],
	data() {
		return { 
			options:this.$store.state.gfonts,
			inputVal: this.value 
		}
	},
	methods:{
		myFunc(val){
			this.$emit('input', val.family);
		}
	},
	watch: {
		inputVal(val) {
			this.$emit('input', val);
		}
	},
}
</script>
