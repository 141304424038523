<template>
<div class="search_col edicion_container" id="searchPanel">
            <div class="partner_block">

        <div class="partner_header">
            <div class="partner_top">
                <div class="partner_avatar">
                    <img :src="getImage()" width="100%">
                </div>
                <div class="partner_info">
                    <h6 class="partner_user_name">{{partnerUser.account_details.account_name}}</h6>
                    <span v-if="$util.isTesting()">{{partnerUser.uid}}</span>
                    <span class="partner_user_prof">{{partnerUser.account_details.account_subtitle}}</span>
                    <a  class="partner_url" @click="showLinckard()" style="color:white;">{{$t('general.ver')}} LINKCARD</a>
                </div>
                <ul class="partner_botonera fav_active"><!-- agregar "fav_active" para cuando el botón favorito está activo-->
                    <li><button type="button" @click="close()"><img src="@/assets/images/icon_close_gray.svg" width="15"></button></li>
                    <li><button><i class="far fa-comment-alt"></i></button></li>
                    <li>
                        <button type="button" v-if="isFav()" @click='removeFav()'  class="firma_btn_fav"><font-awesome-icon icon="heart"  style="color:#F531A6;"/></button>
                        <button type="button" v-else class="firma_btn_fav"  @click='addFav()' ><font-awesome-icon icon="heart"/></button>
                    </li>
                </ul>
            </div>
            <div class="parnter_header_foot">
                <div class="row ml-0 mr-0">
                    <div class="col-6 pl-0 pr-0 pt15 pb15" style="border-right: solid 1px #e5e5e5;">
                        <h6>Linckards</h6>
                        <span class="text_bg">{{isSize}}</span>
                    </div>
                    <div  class="col-6 pl-0 pr-0 pt15 pb15">
                        <div class="partner_valuaciones">
                            <h6>{{$t('partners.vals')}}</h6>
                            <reviewStars  :value="getAverage()"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="edicion_block mt20" v-if="getUser.isPartner">{{$t('partners.cant')}}</div>
        <div class="edicion_block mt20" v-else-if="getUser.hasAccess.uid && !isMyPartner">{{$t('partners.debespri')}}</div>
        <div v-else-if="!getUser.isPartner" class="edicion_block mt20">
            <button v-if="!isMyPartner" @click="wantAddAccess()"   class="btn btn-info btn-initial mr-auto mb10" ng-click="modalConcederPermiso()">
                <img src="@/assets/images/icon_user_add.svg" width="16" style="position: relative; top: -1px;">
                {{$t('partners.invitar')}}
            </button>
            <!--
            <button class="btn btn-info btn-initial mr-auto mb10" ng-click="modalConcederPermiso()">
                <img src="@/assets/images/icon_user_remove.svg" width="16" style="position: relative; top: -1px;">
               {{$t('partners.pend')}}
            </button>
            -->
            <button  v-else @click="$util.removePartnership($modal,partnerUser,getUser)"   class="btn btn-success btn-initial mr-auto mb10" ng-click="modalConcederPermiso()">
                <img src="@/assets/images/icon_user_remove.svg" width="16" style="position: relative; top: -1px;">
                {{$t('partners.desv')}}
            </button>
            <small>{{$t('partners.conoce')}}</small>
        </div>
        <hr  v-if="!getUser.isPartner" class="separator ">
        <div   class="edicion_block mt20">
            <div id="perfilProfesional" class="wrapper">
                <h4 class="mb10">{{$t('partners.perf')}}</h4>
                <p v-if="partnerUser.account_details.account_shortdesc.length">{{partnerUser.account_details.account_shortdesc}}</p>
                <p v-else>{{$t('partners.missi')}}</p>
            </div> 
        
        </div>
                
        <hr class="separator ">
        <div class="edicion_block mt10">
            <h4 class="mb20">{{$t('partners.revie')}}</h4>
            <button type="button" v-if="!getUser.isPartner && !$util.alreadyReviewed(partnerUser,getUser) && isMyPartner"  @click="$modal.show('modal-review',{profile:partnerUser})" class="btn btn-primary mb10" style="color:white;">
            {{$t('partners.eval')}}</button>
            <p v-if="!partnerUser.reviews || (!partnerUser.reviews.data || partnerUser.reviews.data.length==0 ) ">{{$t('partners.noreyet')}}</p>
            <div v-for="review in partnerUser.reviews.data" :key="review.uid_from+review.text" id="perfilProfesional" class="wrapper">
                <div class="partner_valuaciones">
                    <reviewStars :value="review.stars"/>
                </div>
                <p >{{review.text}}</p>
            </div>
        </div>
    </div>
</div>

</template>

<script>

import {usersCollection } from '@/services/firebaseConfig.js';
import reviewStars  from '@/components/reviewStars';

export default {
    props: ['partner','cant'],
    data() {

        return { 
            isSize:this.cant,
            getUser:this.$store.state.userProfile,
            partnerUser:this.partner,
            
        }

    },

    beforeMount(){
        if(!this.getUser.hasAccess)this.getUser.hasAccess={uid:null,img:null}
        this.$store.state.isInPartnerView=true
    },
    computed:{
        isMyPartner(){
            return this.partnerUser.uid==this.getUser.hasAccess.uid
        },
    },
    methods:{
        getAverage(){
            if(!this.partnerUser.rating)this.partnerUser.rating=0
            return this.partnerUser.rating
        },
        showLinckard() {
            //this.$bus.$emit(this.$util.compPrefix()+"switchMainDirLista", null);
            this.$bus.$emit(this.$util.compPrefix()+"switchMainPartnerUser",this.partnerUser);//esta solo cambia
            this.$bus.$emit(this.$util.compPrefix()+"switchMainDirLinckard",this.partnerUser.linckards[0]);
            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'linckard');

            
            this.$util.toggleDiv("search_open");
        },
        wantAddAccess(){
            var dis=this
            this.$modal.show('dialog', {title: dis.$t('notis.atencion'),text: dis.$t('notis.accessfrom'),
                buttons: [
                    {title: dis.$t('notis.si'), handler: () => { 
                        dis.$modal.hide('dialog')
                        dis.addAccessToEdit()
                    }},
                    {title: dis.$t('general.cerrar')}
                ]
                })
        },
        hasCompany(profile,company){
            if(!profile.companies)return false
            let index=profile.companies.findIndex(function(i){return i.uid == company.uid})
            return index>=0
        },
        addAccessToEdit(){
            var dis=this
            
            let company=this.$util.clearUserData(dis.$store.state.userProfile)
           
            if(!this.hasCompany(this.partnerUser,company)){
                if(!this.partnerUser.companies)this.partnerUser.companies=[]
                this.partnerUser.companies.push(company)
            }

            return usersCollection.doc(dis.partnerUser.uid)
            .update({companies:dis.partnerUser.companies})
            .then(function(){
                //Add a favoritos
                dis.$util.addFav(dis.$modal,dis.$store.state.userProfile,dis.$util.contactObj(dis.partnerUser))
                
                let img = dis.$util.getImage(dis.partnerUser)
                dis.$store.state.userProfile.hasAccess={uid:dis.partnerUser.uid,img:img}
                dis.getUser.hasAccess=dis.$store.state.userProfile.hasAccess
                let profile=JSON.parse(JSON.stringify(dis.$store.state.userProfile))
                usersCollection.doc(profile.uid)
                .update({hasAccess:profile.hasAccess})
                .then(function() {
                    dis.isLoading = false
                    dis.$bus.$emit('partner_newAccess',profile)
                    dis.status = true
                    setTimeout(() => { dis.status = '' }, 2000)
                }).catch(function(error) {
                    dis.$util.notif_error(error.message);
                });

                
            })
            .catch(function(error) {
                dis.$util.notif_error("2:"+error.message);
                dis.isLoading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 2000)
            });
        },
        getImage(){
            var img=this.$util.getImage(this.partnerUser);
            if(img==null)return  require("@/assets/images/profile_nophoto_color.png");
            else if(img && img.obj)return img.obj;
            else if(img)return this.$util.removeToken(img)
        },
        close(){
            var dis=this
            this.$util.toggleDiv("search_open");
            this.$store.state.isInPartnerView=false
            this.$bus.$emit(this.$util.compPrefix()+"switchMainPartnerUser",null)
            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'busqueda');
        },
        isFav(){
            return this.$util.isFav(this.getUser,this.partnerUser.uid);
        },
        addFav(){
            this.$util.addFav(this.$modal,this.getUser,this.partnerUser);
        },
        removeFav(){
            this.$util.removeFav(this.getUser,this.partnerUser.uid);
        },

    },

    components:{
        reviewStars
    },
}
</script>

