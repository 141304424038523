<template>

    <div v-if="!loading" class="firma_wrapper tarjeta_focus">
        <div class="firma_container">
            <div class="firma_avatar_wrapper">
                <div class="firma_avatar">
                    <img :src="getImage()" width="100%">
                </div>
              <!--  <span v-if="dirUser.isPro" class="firma_plan">Pro</span>-->
            </div>
            <div class="firma_user_info">
                <h6 class="firma_user_name">{{dirUser.account_details.account_name}}</h6>
                    <span v-if="dirUser.account_details.account_subtitle" class="firma_user_prof">{{dirUser.account_details.account_subtitle}}</span>
                    <span v-else class="firma_user_prof">{{dirUser.activeLincs}} Linkcards</span>
                <button type="button" @click="switchMainDirectorio()" class="firma_url">{{$t('general.direct')}}</button>
            </div>
            <div class="firma_buttons" style="opacity: 1;">
                <button type="button" v-if="isFav()" @click='removeFav()'  class="firma_btn_fav"><font-awesome-icon icon="heart" style="color:#F531A6;"/></button>
                <button type="button" v-else class="firma_btn_fav"  @click='addFav()' ><font-awesome-icon icon="heart" /></button>  
            </div>
        </div>
    </div>
    <div v-else-if="loading"  class="firma_wrapper">
        <font-awesome-icon icon="circle-notch" style="color:#99287B;" spin />
    </div>
 

</template>


<script>



export default {
    props: ['user','users'],
	data() {
		return { 
            dirUser: this.user,
            getUser:this.$store.state.userProfile,
            loading:false,
            laLista:this.users,
        }
    },
    methods:{

        getImage(){
            var img=this.$util.getImage(this.dirUser);
            if(img==null)return  require("@/assets/images/profile_nophoto_color.png");
            else if(img && img.obj)return img.obj;
            else if(img)return this.$util.removeToken(img)
        },
        async switchMainDirectorio() {
            var dis=this
            if(!this.$util.isSubLinckard(this.dirUser)){
                this.loading=true
                this.dirUser=await dis.$util.dbgetUser(dis.dirUser.uid)
                this.dirUser.linckards=this.$util.getActiveLinckards(dis.dirUser)
                let index=this.laLista.findIndex(function(i){return i.uid == dis.dirUser.uid})
                this.laLista[index]=this.dirUser
                this.loading=false
            }
            
            this.$bus.$emit(this.$util.compPrefix()+"switchMainDirLista", this.laLista);
            this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser", this.dirUser);
            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'subdirectorio');

            

            
        },
        isFav(){
            return this.$util.isFav(this.getUser,this.dirUser.uid);
        },
        addFav(){
            this.$util.addFav(this.$modal,this.getUser,this.dirUser);
        },
        removeFav(){
            this.$util.removeFav(this.getUser,this.dirUser.uid);
        },
        
      
    }
  
}

</script>


<style>

.directorio .directorio_list > li .tarjeta_focus{
    background-color:#0090e4 !important;
}


.directorio .directorio_list > li .tarjeta_focus .firma_url{
    color:#0090e4 !important;
}
.directorio .directorio_list > li .tarjeta_focus .firma_buttons button{
    opacity: 1;
}

/*fix ancho nombre*/


</style>
