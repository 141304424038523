<template>
    <div class="edicion_block">
        <h4>Icons</h4>
        <div class="row form_row">
            <div class="col-lg-6">
                <colorPicker
                    label="Color"
                    :color="valor.color ?? 'none'"
                    v-model="valor.color"
                />
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <sizePicker
                    :value="valor.size == undefined ? 100 : valor.size"
                    min="10"
                    max="300"
                    unit="px"
                    v-model="valor.size"
                />
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <sizePicker
                    :value="valor.size == undefined ? 0 : valor.separation"
                    min="0"
                    max="50"
                    unit="px"
                    v-model="valor.separation"
                    label="Separation"
                />
            </div>
        </div>
        <div class="row form_row">
            <iconPicker v-model="value.flags" multiple="true" />
        </div>
    </div>
</template>

<script>
import colorPicker from "@/layouts/main_Customizer/dinputs/colorPicker";
import sizePicker from "@/layouts/main_Customizer/dinputs/sizePicker";
import iconPicker from "@/layouts/main_Customizer/dinputs/iconPicker";

export default {
    props: ["value"],
    data() {
        return {
            flags: [],
            valor: this.value,
        };
    },
    components: {
        colorPicker,
        sizePicker,
        iconPicker,
    },
};
</script>
