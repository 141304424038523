<template>
<div :class="'tarjeta_section editar '+isFullwidth()" id="tarjetaSobreMi" style="padding: 30px 20px;/*min-height: 645px;*/" >
    <!-- Bloque solo para edición -->
      <draggable 
      tag="span" 
      class="area" 
      v-model="preview.sobredrags" 
      v-bind="dragOptions" 
      :add="onAdd"
      :class="preview.sobredrags.length==0?'empty':'full'" >
        <transition-group name="no" :class="preview.sobredrags.length==0 && $util.isCustomizer()?'list-group laul':'list-group'" tag="ul" :style="{ backgroundImage: `url(${backgroundUrl})` }">
            <div class="tarjeta_edit_block"  v-for="(element,index) in $util.parseVariables(preview).sobredrags" :key="index+0" > 
              <div class="edit_area " 
              :class="{ focus: index === editingIndex && compPresente === 'leftColSobreD'}" 
              style="border:0px;margin:0px;" 
              :id="'editable'+index"  
              @mouseover="mouseOverEdit(index)" 
              @mouseleave="mouseLeaveEdit(index)"  
              v-touch:tap="toggleEdit(index)">
                <div class="edit_header manito" style="display:none;" >
                  <div style="margin: 5px;">
                    <span >{{$t('drags.editando')}} </span>
                    <Icon icon="ph:arrows-out-cardinal-light" style="font-size: 22px; color: white;" />
                    &nbsp;{{ $t("drags." + element.compo) }}
                  </div>
                  <ul class="edit_buttons"  >
                    <li><button type="button" @click="editarCurrent(preview.sobredrags[index],index);"><Icon icon="ph:pencil-simple-light" style="font-size: 22px; color: white;" /></button></li>
                    <li><button v-if="!element.auto" type="button" @click="duplicarCurrent(preview.sobredrags[index],index);" title="Duplicar"><Icon icon="ph:copy-simple-light" style="font-size: 22px; color: white;" /></button></li>
                    <li><button v-if="!element.auto" type="button" @click="eliminarCurrent(index);" title="Eliminar"><Icon icon="ph:trash-light" style="font-size: 22px; color: white;" /></button></li>
                  </ul>
                </div>
                <div class="edit_content text-center" style="padding:0px;">
                  <div v-if='element.compo=="editHTML"' v-html="$sanitize(element.value?element.value:'<label>'+$t('general.codigoaca')+'</label>')"></div>
                  <hr v-else-if='element.compo=="editLine"' :style="'height: ' + element.size+'px;margin-top: ' + element.weight+'px;margin-bottom: ' + element.weight+'px;background-color: ' + element.color" />
                  <pre v-else-if='element.compo=="editParrafo_new"' class="input_edit " rows="4" 
                  :style="';background-color: ' + element.background+';color: ' + element.color+';font-size: ' + element.size+';font-weight: ' + element.weight+';font-family: ' + element.family+ '!important;'+element.weight+';line-height: ' + $util.minLineh(element.size,element.lineh)" >
                    {{element.value?element.value:$t('drags.paraplace')}}</pre>
                  <p v-else-if='element.compo=="editParrafo"' class="input_edit " rows="4" 
                  :style="';background-color: ' + element.background+';color: ' + element.color+';font-size: ' + element.size+';font-weight: ' + element.weight+';font-family: ' + element.family+ '!important;'+element.weight+';line-height: ' + $util.minLineh(element.size,element.lineh)" >
                    {{element.value?element.value:$t('drags.paraplace')}}</p>
                  <h3 v-else-if='element.compo=="editTitulo"' class="input_edit input_edit_title" 
                    :style="'text-transform:uppercase !important;background-color: ' + element.background+';color: ' + element.color+';font-size: ' + element.size+' !important;font-weight: ' + element.weight+'!important;font-family: ' + element.family+';line-height: ' + element.lineh+'!important;'" >
                    {{element.value?element.value:$t('drags.titulo')}}</h3>
                  <button v-else-if='element.compo=="editBoton"' type="button" @click="openUrl(element.url)" class="btn btn_principal" value="Nombre del botón"
                    :style="'text-transform:uppercase !important;background-color: ' + element.background+';color: ' + element.color+';font-size: ' + element.size+';font-weight: ' + element.weight+';font-family: ' + element.family" >
                    <Icon v-if="element.icon" :icon="element.icon" style="font-size: 24px; display: inline-block; vertical-align: middle" />
                    <div style="display: inline-block; vertical-align: middle">
                      {{element.value?element.value:$t('drags.boton')}}    
                    </div>
                  </button>
                    <div v-else-if='element.compo=="editSpace"' class="emptyspace" :style="'height: ' + element.size" ></div>
                  <div v-else-if='element.compo=="editAvatar"' class="tarjeta_foto animated fadeInDown" style="margin-top:0px;margin-bottom:0px;" :style="'width: ' + (element.size ? element.size : '50%') + '; height: ' + (element.size ? element.size : '50%') + ''"><img :src="$util.getSobreImagen(element.img,'111x111')"  @error="function(event){event.target.src=$util.getSobreImagen(element.img,null)}" class="w-100" /></div>
                  <img v-else-if='element.compo=="editImagen"' :alt="element.alt" style="border-radius:10px;" :style="'width: ' + (element.size ? element.size : '100%') + ''" class="mt10 mb10" :src="$util.getSobreImagen(element.img,null)"/>
                  <div v-else-if='element.compo=="editVideo"' width="100%" height="150">
                    <iframe frameBorder="0" v-if='element.url!=undefined && element.url.length>0' width="100%" height="200" :src="$util.getvideo(element.url)" />
                    <div v-else style="width:100%;height:150px;text-align:center;border:0px solid #B4B4B4;" class="d-flex align-items-center text-center"><font-awesome-icon style="font-size:50px !important; tex-align:center;margin:0 auto !important;color:#B4B4B4" :icon="['fab','youtube']"/> </div>
                  </div>
                  
                  <ul v-else-if='element.value && element.compo=="editSocial"' class="tarjeta_redes animated delay" style="margin:0px;">
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.website"><a  @click="openUrl($util.sanitizar(element.value.website))" ><font-awesome-icon  icon="globe" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.facebook"><a @click="openUrl($util.sanitizar(element.value.facebook,'facebook'))" ><font-awesome-icon :icon="['fab','facebook-f']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.twitter"><a  @click="openUrl($util.sanitizar(element.value.twitter,'twitter'))" ><font-awesome-icon :icon="['fab','twitter']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.linkedin"><a  @click="openUrl($util.sanitizar(element.value.linkedin,'linkedin'))" ><font-awesome-icon :icon="['fab','linkedin']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.instagram"><a  @click="openUrl($util.sanitizar(element.value.instagram,'instagram'))" ><font-awesome-icon :icon="['fab','instagram']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.youtube"><a  @click="openUrl($util.sanitizar(element.value.youtube,'youtube'))" ><font-awesome-icon :icon="['fab','youtube']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.vimeo"><a  @click="openUrl($util.sanitizar(element.value.vimeo,'vimeo'))" ><font-awesome-icon :icon="['fab','vimeo']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.skype"><a  @click="openUrl('skype:'+element.value.skype+'?call')" ><font-awesome-icon :icon="['fab','skype']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.github"><a  @click="openUrl($util.sanitizar(element.value.github,'github'))" ><font-awesome-icon :icon="['fab','github']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.spotify"><a  @click="openUrl($util.sanitizar(element.value.spotify,'spotify'))" ><font-awesome-icon :icon="['fab','spotify']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.snapchat"><a  @click="openUrl($util.sanitizar(element.value.snapchat,'snapchat'))" ><font-awesome-icon :icon="['fab','snapchat']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.behance"><a  @click="openUrl($util.sanitizar(element.value.behance,'behance'))" ><font-awesome-icon :icon="['fab','behance']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.tiktok"><a  @click="openUrl($util.sanitizar(element.value.tiktok,'tiktok'))" ><font-awesome-icon :icon="['fab','tiktok']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.line"><a  @click="openUrl($util.sanitizar(element.value.line,'line'))" ><font-awesome-icon :icon="['fab','line']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.telegram"><a  @click="openUrl($util.sanitizar(element.value.telegram,'line'))" ><font-awesome-icon :icon="['fab','telegram']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                    <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="element.value.googlemaps"><a @click="openUrl($util.sanitizar(element.value.googlemaps,'googlemaps'))" ><font-awesome-icon icon="map" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                  </ul>
                  <ul v-else-if='(!element.value || Object.keys(element.value).length==0) && element.compo=="editSocial"' class="tarjeta_redes animated delay"  style="margin:0px;">
                      <li class="animated fadeInLeft" ><a><font-awesome-icon icon="globe" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                      <li class="animated fadeInLeft" ><a><font-awesome-icon :icon="['fab','facebook-f']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                      <li class="animated fadeInLeft" ><a><font-awesome-icon :icon="['fab','twitter']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                      <li class="animated fadeInLeft" ><a><font-awesome-icon :icon="['fab','linkedin']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                      <li class="animated fadeInLeft" ><a><font-awesome-icon :icon="['fab','instagram']" :style="{ color: element.color,'font-size':element.size }"/></a></li>
                  </ul>
                  <iframe v-else-if='element.compo=="editUbicacion"' frameBorder="0"  width="100%" height="150" :src="getMapa(element.place)"  style="border:0" allowfullscreen></iframe>
                  <template v-else-if='element.compo=="editPremio"' :class="element.compo">

                      <font-awesome-icon icon="trophy" :style="{color:  element.icon_color+'!important', 'font-size': element.icon_size+'!important'}" />

                      <p class="input_edit input_edit_title titpremio" 
                      :style="{color:  element.color, 'font-size': element.size+'!important','font-weight': element.weight,'font-family': element.family+'!important','line-height': element.lineh+'!important'}">
                      {{element.value?element.value:$t('drags.titulo')}}</p>

                      <p class="input_edit mb-0" rows="8" 
                      :style="{color:  element.desc_color, 'font-size': element.desc_size,'font-weight': element.desc_weight,'font-family': element.desc_family,'line-height': element.desc_lineh+'!important'}">
                      {{element.desc?element.desc:$t('general.desc')}}</p>
                  </template>
                  <template v-if='element.compo=="editBeneficio"' :class="element.compo">
                      <font-awesome-icon icon="star" :style="{color:  element.icon_color+'!important', 'font-size': element.icon_size+'!important'}" />
                      <p class="input_edit input_edit_title titpremio" 
                      :style="{color:  element.color, 'font-size': element.size+'!important','font-weight': element.weight,'font-family': element.family,'line-height': element.lineh+'!important'}">
                      {{element.value?element.value:$t('drags.titulo')}}</p>
                      <p class="input_edit mb-0" rows="8"
                      :style="{color:  element.desc_color, 'font-size': element.desc_size+'!important','font-weight': element.desc_weight+'!important','font-family': element.desc_family+'!important','line-height': element.desc_lineh+'!important'}">
                      {{element.desc?element.desc:$t('general.desc')}}</p>
                  </template>
                  <QRCodeBlock    :element="element" v-if='element.compo=="editQRCode"'/>
                  <VCFBlock       :element="element" v-if='element.compo=="editVCF"'   />
                  <IconBlock      :element="element" v-if='element.compo=="editIcon"'/>
                  <SaveDateBlock  :element="element" v-if='element.compo=="editSaveDate"'/>
                  <FlagsBlock     :element="element" v-if='element.compo=="editFlags"'/>
                </div>
              </div>
            </div>  

        </transition-group>
    </draggable>
</div>
</template>

<script>

import draggable from "vuedraggable";
import QRCodeBlock from "@/components/blocks/qrcode/render.vue";
import IconBlock from "@/components/blocks/icon/render.vue";
import FlagsBlock from "@/components/blocks/flags/render.vue";
import VCFBlock from "@/components/blocks/vcf/render.vue";
import SaveDateBlock from "@/components/blocks/saveDate/render.vue";
import { Icon } from "@iconify/vue2"

export default {
  props:['preview'],
  data() {
    return {
      animCounter:0.4,
      editable: true,
      delayedDragging: false,
			compPresente: 'leftColSobreA', 
      editingIndex:-1,
      backgroundUrl: require('@/assets/images/icon_dragdrop_'+this.$store.state.locale+'.svg')
    }
  },
  mounted(){
    this.$bus.$on('switchCompSobre', comp => {
          this.editingIndex=-1;
          this.compPresente = comp;
    });
   
  },
  methods: {
    isFullwidth(){
      try{
        if(this.preview.style.sobre.fondo.fullwidth && this.preview.style.sobre.fondo.active)return 'fullwidth'
      }catch(e){
        return ''
      }
    },
    openUrl(url){
      window.open(url,'_blank')
    },
    onAdd({ relatedContext, draggedContext }) {
        if (this.preview.sobredrags.length > 10){
            dis.$util.notif_error(dis.$t('notis.sobremax'));
            this.preview.sobredrags.splice(this.preview.sobredrags.length-1,1);
        } 
    },
    mouseLeaveEdit(e){
      if(this.$util.isCustomizer()){
        document.getElementById('editable'+e).getElementsByClassName('edit_header')[0].style.display='none';
       // document.getElementById('editable'+e).getElementsByClassName('edit_content')[0].style.padding='0px';
        document.getElementById('editable'+e).style.border='0px';
      }
    },
    toggleEdit(e){
        for(var i = 0; i < this.preview.sobredrags.length; i++) {
          let elem=document.getElementById('editable'+i);
          if(!elem)continue;
          if(i==e){
            elem.getElementsByClassName('edit_header')[0].style.display='flex';
         //   elem.getElementsByClassName('edit_content')[0].style.padding='5px';      
            elem.style.border='1px solid grey';
          }else{
            elem.getElementsByClassName('edit_header')[0].style.display='none';
            elem.getElementsByClassName('edit_content')[0].style.padding='0px';
            elem.style.border='0px';
          
          }
        }


    },
    mouseOverEdit(e){
      if(this.$util.isCustomizer()){
        document.getElementById('editable'+e).getElementsByClassName('edit_header')[0].style.display='flex';
        //document.getElementById('editable'+e).getElementsByClassName('edit_content')[0].style.padding='5px';
        document.getElementById('editable'+e).style.border='1px solid grey';
      }
      
    
    },
    getMapa(element){
      var mapskey='AIzaSyDhyuzW32WIEYtnmA3vdiGfTjQjZ_DyGlU';
      if(!element)return 'https://www.google.com/maps/embed/v1/place?key='+mapskey+'&q=Apple+Garage';
      var id=element.place_id;
      var address=element.formatted_address;
   
      
      if(id)address="place_id:"+id;
      return 'https://www.google.com/maps/embed/v1/place?key='+mapskey+'&q='+address;

    },

    editarCurrent(element,index) {
      element.index=index;
      this.$bus.$emit("currentCompSobreEditables", element);
      this.editingIndex=index;
      this.switchSobreBackground()
		  
    },
    switchSobreBackground(){
        this.$util.toggleDiv('leftcol_open')
        
    },
		eliminarCurrent(index){
      var dis=this;
      this.$modal.show('dialog', {title: dis.$t('notis.atencion'),text: dis.$t('general.estaseguro'),
      buttons: [
          {title: dis.$t('notis.si'), handler: () => { 
              dis.$modal.hide('dialog')
              dis.preview.sobredrags.splice(index,1);
          }},
          {title: dis.$t('general.no')}
      ]
      })
     
		},
		duplicarCurrent(value,index){
      var cloneOfA = JSON.parse(JSON.stringify(value));
      this.preview.sobredrags.splice(index, 0, cloneOfA);
		}
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,

        group:{ name:'people',  pull:'clone' },
        disabled: false,
        ghostClass: "ghost"
      };
    },


  },
  components:{
      draggable,QRCodeBlock,Icon,VCFBlock,IconBlock,SaveDateBlock,FlagsBlock
  },
  
};
</script>

<style>


.fullwidth .list-group{
		padding-bottom:100px;
}

#card .tarjeta_view_block {
  /*fix width en mobile sobre directorio*/
  width: 100%;
  max-width: 336px;
}


.tarjeta_content.previewSobre{
  /*color de fondo*/
    background-color: var(--sobre-fondo-background) !important;
}
/*magen de fonto*/
.laul{
  height:100px !important;
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-size: contain !important; /* Resize the background image to cover the entire container */
}
/*el fix del repetir del fondo*/
.list-group:not(.laul){
  background-image:none !important;
}



/*css de la lista de la izq* al arrastrar*/
.tarjeta_section.editar  .module_block{
  width:100% !important;
  border:1px solid grey;
  padding:20px !important;
}


.edit_content p{
  /*un margin que viene con el p*/
  margin-bottom:0px !important;
  margin-top:0px !important;
  padding: 0px;
}

.edit_content .emptyspace{
border:1px solid #C9C9C9 !important;
}


#edicionMiddle #tarjetaMuestra #tarjetaCoantainer{
  /*Esto fixea todo*/
  width:inherit !important;
}



#card .tarjeta_view_block h1,
#card .tarjeta_view_block h2,
#card .tarjeta_view_block h3,
#card .tarjeta_view_block h4,
#card .tarjeta_view_block h5,
#card .tarjeta_view_block h6,
#card .tarjeta_view_block p,
#card .tarjeta_edit_block button.btn_principal,
#panel .tarjeta_edit_block h1,
#panel .tarjeta_edit_block h2,
#panel .tarjeta_edit_block h3,
#panel .tarjeta_edit_block h4,
#panel .tarjeta_edit_block h5,
#panel .tarjeta_edit_block h6,
#panel .tarjeta_edit_block p:not(.input_edit_title)  ,
#panel .tarjeta_edit_block button.btn_principal  {
    word-break: break-word;
    margin-bottom:10px !important;
}

/*youtube*/
pre{
    white-space: pre-line;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    margin-bottom:10px;
    overflow: auto;
    padding: 3px;
}
</style>
