<template>
    <div class="edicion_block">
        <h4>QRCode</h4>
        <div class="row form_row">
            <div class="col-12">
                <label>Content</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "
                        :placeholder="$t('general.entervalue')" v-model="valor.content">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <!--div class="col-lg-6">
                <colorPicker label="Color" :color="valor.color" v-model="valor.color" />
            </div-->
            <div class="col-lg-6">
                <colorPicker label="Background" :color="valor.bgcolor ?? 'none'" v-model="valor.bgcolor" />
            </div>
        </div>
        <!--div class="row form_row">
            <div class="col-12">
                <sizePicker :value="valor.size == undefined ? '100' : valor.size" min=10 max=100 unit="%" v-model="valor.size" />
            </div>
        </div-->
    </div>
</template>

<script>



import colorPicker from '@/layouts/main_Customizer/dinputs/colorPicker'
import sizePicker from '@/layouts/main_Customizer/dinputs/sizePicker'

export default {
    props: ['value'],
    data() {
        return {
            valor: this.value
        }
    },
    components: {
        colorPicker,
        sizePicker
    },

}
</script>

