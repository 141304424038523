<template>
    <div class="edicion_block">
        <h4>Icon</h4>
        <div class="row form_row">
            <div class="col-lg-6">
                <colorPicker label="Color" :color="valor.color ?? 'none'" v-model="valor.color" />
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <sizePicker :value="valor.size == undefined ? 100 : valor.size" min=10 max=300 unit="px" v-model="valor.size" />
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <label>Icon (debug)</label>
                <div class="form-group">
                    <input name="url" type="text" maxlength="500" class="form-control  "
                        :placeholder="$t('general.entervalue')" v-model="valor.icon">
                </div>
            </div>
        </div>
    </div>
</template>

<script>



import colorPicker from '@/layouts/main_Customizer/dinputs/colorPicker'
import sizePicker from '@/layouts/main_Customizer/dinputs/sizePicker'
import iconPicker from '@/layouts/main_Customizer/dinputs/iconPicker'

export default {
    props: ['value'],
    data() {
        return {
            valor: this.value
        }
    },
    components: {
        colorPicker,
        sizePicker,
        iconPicker
    },

}
</script>

