<template>
<div class="main_content ">
        <div class="nav_filter">
            <div class="nav_wrapper">
                <div class="form-group group_filtro" style="min-width: 0;">
                    <div class="input-group">
                        <label class="mb-0 mt5 mr-2 d-none d-md-flex">{{$t('filtros.mostrar')}}</label>
                        <div class="input-group-append">
                            <select class="form-control" id="comboMostrar" v-model='orderBy'>
                                <option value='relevance'>{{$t('filtros.relevance')}}</option>
                                <option value='byname'>{{$t('filtros.alfa')}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper pt20 pb20 pl20 pr20 scolable" >
                <!--VOLVER ATRAS -->
                <h5 class="text_results d_inlineb mb5 mr10 ">
                <font-awesome-icon @click="switchDir('directorio')" class="ml5 mr5 iconovolver manito" :icon="['fas','chevron-circle-left']"/>
                {{dirUser.account_details.account_name}}
                </h5>         

                <!--SECTORES -->
                <div class="form-group d_inlineb">
                    <select class="form-control input_sm" v-model="dirActiveSector" >
                        <option value='all'>{{$t('filtros.todos')}}</option>
                        <option v-for="(sector) in getActiveSectors()" :key="sector.id" :value="sector.id"> 
                            {{sector.name}}
                        </option>
                    </select>
                </div>
                <h6 class="text_results mb-0" v-if="linckards.length>0">{{$t('general.mostrando')}} {{linckards.length}} {{$t('general.results')}} </h6> 
                <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
              
                <ul class="directorio_list ">
                    <li v-for="linckard in linckards" :key="linckard.id" >
                        <mLinckard  :linc='linckard' :user="dirUser" :users="laLista" /> 
                    </li>
                </ul>

        </div>
</div>
</template>



<script>

import mLinckard from "@/layouts/main_Directorio/main/miniLinckard.vue";
import mPartner from "@/layouts/main_Directorio/main/miniPartner.vue";
import mDirectory from "@/layouts/main_Directorio/main/miniDirectory.vue";
import mMulticard from "@/layouts/main_Directorio/main/miniMulticard.vue";

export default {
    props:['user','users'],
    data() {
        return{
            dirUser:this.user,
            dirActiveSector:'all',
            //varios
            laLista:this.users,
            orderBy:this.$store.state.orderBy,
            loading:false,
        }
    },
    computed:{
        linckards() {
            var dis=this;


            var devolver=dis.dirUser.linckards
            if(dis.dirActiveSector!='all'){
                devolver=devolver.filter(u=>u.sector_id==dis.dirActiveSector)
            }
            
            if(this.orderBy=='byname'){
                devolver=devolver.sort((a, b) => a.info.nombre.localeCompare(b.info.nombre))
            }else if(this.orderBy=='relevance'){
                devolver=devolver.sort((a, b) =>  a.id - b.id)
            }
            
            return devolver;
        },   
    },
    watch:{
        orderBy(val){
            this.$store.state.orderBy=val
        }
    },
    methods:{
        switchDir(){
            this.$bus.$emit(this.$util.compPrefix()+"switchMainDirUser", null);
            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'directorio');
        },
        getActiveSectors(){
            if(!this.dirUser || !this.dirUser.sectors  )return
            return this.dirUser.sectors.filter(u=>{
                return u.status=='active';
            });
        },
    },
    components:{
        mLinckard,   mPartner,mDirectory,mMulticard
    }
}

</script>


<style>

/*Para que aparezca la manito en todos*/
ul.directorio_list li,ul.directorio_list button{
cursor:pointer !important;
}


.panel .nav_filter .nav_wrapper{
   /*fix a un borde blanco que aparecia abajo de la barra en el direcotrio*/
   border:0px;
}


.firma_user_name{
    /*fix nombres*/
    max-width:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*pagination mobile*/
@media (max-width: 799px) {

    .pagination{
        margin-bottom: 100px !important;
    }

}
</style>