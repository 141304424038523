import firebase from "firebase/app"; 

import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/firestore";


let algolia={
  apikey:'aa2a8a0b82252e770d52632632dcba75',
  apikeys:'92cb894e973b372c75dd4bd97b23d056',
  index:'users'
}
let config = {
    apiKey: "AIzaSyBVuo_tYafMa98SWuttEacSmbO17HI4edE",
    authDomain: "linckard-1545486188489.firebaseapp.com",
    databaseURL: "https://linckard-1545486188489.firebaseio.com",
    projectId: "linckard-1545486188489",
    storageBucket: "linckard-1545486188489",
    messagingSenderId: "963157049746"
};
if(process.env.LOCATION=='EU'){
  config= {
    apiKey: "AIzaSyCiMuwgY4VqzGvyAeawQN5gPfmyaghA0Pg",
    authDomain: "linkcard-eu.firebaseapp.com",
    projectId: "linkcard-eu",
    storageBucket: "linkcard-eu.appspot.com",
    messagingSenderId: "476060770290",
    appId: "1:476060770290:web:a48bccd266328f6763d56b"
  };
}

var stripe_public='pk_live_KM0WAadM3l0YbDQtCkvnaSLE00OuTdfw3Q'

if (process.env.AMBIENTE=='development') {
  algolia={
    apikey:'6cb397a9ace5eec495b93d29787c3423',
    apikeys:'92cb894e973b372c75dd4bd97b23d056',
    index:'dev_users'
  };
  stripe_public='pk_test_I4TmbbLJ8MAlKhVnN4X4fwqh00jOaw8GLh';
  config = {

    apiKey: "AIzaSyDUryhCw_NjeLA-y2C_xcbAnfShs959Dhg",
    authDomain: "testinglinckard.firebaseapp.com",
    databaseURL: "https://testinglinckard.firebaseio.com",
    projectId: "testinglinckard",
    storageBucket: "linkcard",
    messagingSenderId: "149630195434",
  };
}
!firebase.apps.length ? firebase.initializeApp(config) : firebase.app()
            
// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
const storage = firebase.storage()
const storagesmall = firebase.app().storage('gs://'+process.env.STORAGE_SMALL)
const storagelarge = firebase.app().storage('gs://'+process.env.STORAGE_LARGE)

const currentUser = auth.currentUser

const usersCollection = db.collection('users')
const fbProvider = new firebase.auth.FacebookAuthProvider();
const gProvider = new firebase.auth.GoogleAuthProvider();
const aProvider = new firebase.auth.OAuthProvider('apple.com');


export {
    config,
    db,
    storagelarge,
    storagesmall,
    firebase,
    storage,
    stripe_public,
    functions,
    auth,
    algolia,
    currentUser,
    fbProvider,
    gProvider,aProvider,
    usersCollection
}