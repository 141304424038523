import Vue from 'vue'
import modalSector from '@/components/modals/modalSector.vue';
import modalLimitededition from '@/components/modals/modalLimitededition.vue';
import modalQR from '@/components/modals/modalQR.vue';
import modalLoyalty from '@/components/modals/modalLoyalty.vue';
import modalTransfer from '@/components/modals/modalTransfer.vue';
import modalCategory from '@/components/modals/modalCategory.vue';
import modalReview from '@/components/modals/modalReview.vue';
import modalAgentes from '@/components/modals/modalAgentes.vue';
import modalBeneficio from '@/components/modals/modalBeneficio.vue';
import modalSignup from '@/components/modals/modalSignup.vue';
import modalChurn from '@/components/modals/modalChurn.vue';
import modalWidget from '@/components/modals/modalWidget.vue';
import modalApplyTemplateAll from '@/components/modals/modalApplyTemplateAll.vue';
import modalDatosmember from '@/components/modals/modalDatosmember.vue';
import modalMsg from '@/components/modals/modalMsg.vue';
import modalShare from '@/components/modals/modalShare.vue';
import modalRefer from '@/components/modals/modalRefer.vue';
import modalFirma from '@/components/modals/modalFirma.vue';
import modalJson from '@/components/modals/modalJson.vue';
import modalPayment from '@/components/modals/modalPayment.vue';
import modalVideoTour from '@/components/modals/modalVideoTour.vue';
import modalPlanes from '@/components/modals/modalPlanes.vue';
import modalCrop from '@/components/modals/modalCrop.vue';
import modalDirinit from '@/components/modals/modalDirinit.vue';
import modalBlocked from '@/components/modals/modalBlocked.vue';
import modalTransactions from '@/components/modals/modalTransactions.vue';
import modalLoyaltyTerms from '@/components/modals/modalLoyaltyTerms.vue';
import modalNews from '@/components/modals/modalNews.vue';
Vue.component('modal-payment', modalPayment)
Vue.component('modal-agentes', modalAgentes)
Vue.component('modal-beneficio', modalBeneficio)
Vue.component('modal-share', modalShare)
Vue.component('modal-refer', modalRefer)
Vue.component('modal-widget', modalWidget)
Vue.component('modal-category', modalCategory)
Vue.component('modal-applytemplateall', modalApplyTemplateAll)
Vue.component('modal-transfer', modalTransfer)
Vue.component('modal-json', modalJson)
Vue.component('modal-firma', modalFirma)
Vue.component('modal-msg', modalMsg)
Vue.component('modal-dirinit', modalDirinit)
Vue.component('modal-blocked', modalBlocked)
Vue.component('modal-videotour', modalVideoTour)
Vue.component('modal-limitededition', modalLimitededition)
Vue.component('modal-churn', modalChurn)
Vue.component('modal-signup', modalSignup)
Vue.component('modal-datosmember', modalDatosmember)
Vue.component('modal-transactions', modalTransactions)
Vue.component('modal-planes', modalPlanes)
Vue.component('modal-crop', modalCrop)
Vue.component('modal-sector', modalSector)
Vue.component('modal-loyalty', modalLoyalty)
Vue.component('modal-qr', modalQR)
Vue.component('modal-loyalty-terms', modalLoyaltyTerms)
Vue.component('modal-review', modalReview)
Vue.component('modal-news', modalNews)