const sectors={
  'en':[
    { id:47, label:'Accounting'},
    { id:94, label:'Airlines/Aviation'},
    { id:120, label:'Alternative Dispute Resolution'},
    { id:125, label:'Alternative Medicine'},
    { id:127, label:'Animation'},
    { id:19, label:'Apparel & Fashion'},
    { id:50, label:'Architecture & Planning'},
    { id:111, label:'Arts & Crafts'},
    { id:53, label:'Automotive'},
    { id:52, label:'Aviation & Aerospace'},
    { id:41, label:'Banking'},
    { id:12, label:'Biotechnology'},
    { id:36, label:'Broadcast Media'},
    { id:49, label:'Building Materials'},
    { id:138, label:'Business Supplies & Equipment'},
    { id:129, label:'Capital Markets'},
    { id:54, label:'Chemicals'},
    { id:90, label:'Civic & Social Organization'},
    { id:51, label:'Civil Engineering'},
    { id:128, label:'Commercial Real Estate'},
    { id:118, label:'Computer & Network Security'},
    { id:109, label:'Computer Games'},
    { id:3, label:'Computer Hardware'},
    { id:5, label:'Computer Networking'},
    { id:4, label:'Computer Software'},
    { id:48, label:'Construction'},
    { id:24, label:'Consumer Electronics'},
    { id:25, label:'Consumer Goods'},
    { id:91, label:'Consumer Services'},
    { id:18, label:'Cosmetics'},
    { id:65, label:'Dairy'},
    { id:1, label:'Defense & Space'},
    { id:99, label:'Design'},
    { id:132, label:'E-learning'},
    { id:69, label:'Education Management'},
    { id:112, label:'Electrical & Electronic Manufacturing'},
    { id:28, label:'Entertainment'},
    { id:86, label:'Environmental Services'},
    { id:110, label:'Events Services'},
    { id:76, label:'Executive Office'},
    { id:122, label:'Facilities Services'},
    { id:63, label:'Farming'},
    { id:43, label:'Financial Services'},
    { id:38, label:'Fine Art'},
    { id:66, label:'Fishery'},
    { id:34, label:'Food & Beverages'},
    { id:23, label:'Food Production'},
    { id:101, label:'Fundraising'},
    { id:26, label:'Furniture'},
    { id:29, label:'Gambling & Casinos'},
    { id:145, label:'Glass, Ceramics & Concrete'},
    { id:75, label:'Government Administration'},
    { id:148, label:'Government Relations'},
    { id:140, label:'Graphic Design'},
    { id:124, label:'Health, Wellness & Fitness'},
    { id:68, label:'Higher Education'},
    { id:14, label:'Hospital & Health Care'},
    { id:31, label:'Hospitality'},
    { id:137, label:'Human Resources'},
    { id:134, label:'Import & Export'},
    { id:88, label:'Individual & Family Services'},
    { id:147, label:'Industrial Automation'},
    { id:84, label:'Information Services'},
    { id:96, label:'Information Technology & Services'},
    { id:42, label:'Insurance'},
    { id:74, label:'International Affairs'},
    { id:141, label:'International Trade & Development'},
    { id:6, label:'Internet'},
    { id:45, label:'Investment Banking'},
    { id:46, label:'Investment Management'},
    { id:73, label:'Judiciary'},
    { id:77, label:'Law Enforcement'},
    { id:9, label:'Law Practice'},
    { id:10, label:'Legal Services'},
    { id:72, label:'Legislative Office'},
    { id:30, label:'Leisure, Travel & Tourism'},
    { id:85, label:'Libraries'},
    { id:116, label:'Logistics & Supply Chain'},
    { id:143, label:'Luxury Goods & Jewelry'},
    { id:55, label:'Machinery'},
    { id:11, label:'Management Consulting'},
    { id:95, label:'Maritime'},
    { id:97, label:'Market Research'},
    { id:80, label:'Marketing & Advertising'},
    { id:135, label:'Mechanical Or Industrial Engineering'},
    { id:126, label:'Media Production'},
    { id:17, label:'Medical Device'},
    { id:13, label:'Medical Practice'},
    { id:139, label:'Mental Health Care'},
    { id:71, label:'Military'},
    { id:56, label:'Mining & Metals'},
    { id:35, label:'Motion Pictures & Film'},
    { id:37, label:'Museums & Institutions'},
    { id:115, label:'Music'},
    { id:114, label:'Nanotechnology'},
    { id:81, label:'Newspapers'},
    { id:100, label:'Non-profit Organization Management'},
    { id:57, label:'Oil & Energy'},
    { id:113, label:'Online Media'},
    { id:123, label:'Outsourcing/Offshoring'},
    { id:87, label:'Package/Freight Delivery'},
    { id:146, label:'Packaging & Containers'},
    { id:61, label:'Paper & Forest Products'},
    { id:39, label:'Performing Arts'},
    { id:15, label:'Pharmaceuticals'},
    { id:131, label:'Philanthropy'},
    { id:136, label:'Photography'},
    { id:117, label:'Plastics'},
    { id:107, label:'Political Organization'},
    { id:67, label:'Primary/Secondary Education'},
    { id:83, label:'Printing'},
    { id:105, label:'Professional Training & Coaching'},
    { id:102, label:'Program Development'},
    { id:79, label:'Public Policy'},
    { id:98, label:'Public Relations & Communications'},
    { id:78, label:'Public Safety'},
    { id:82, label:'Publishing'},
    { id:62, label:'Railroad Manufacture'},
    { id:64, label:'Ranching'},
    { id:44, label:'Real Estate'},
    { id:40, label:'Recreational Facilities & Services'},
    { id:89, label:'Religious Institutions'},
    { id:144, label:'Renewables & Environment'},
    { id:70, label:'Research'},
    { id:32, label:'Restaurants'},
    { id:27, label:'Retail'},
    { id:121, label:'Security & Investigations'},
    { id:7, label:'Semiconductors'},
    { id:58, label:'Shipbuilding'},
    { id:20, label:'Sporting Goods'},
    { id:33, label:'Sports'},
    { id:104, label:'Staffing & Recruiting'},
    { id:22, label:'Supermarkets'},
    { id:8, label:'Telecommunications'},
    { id:60, label:'Textiles'},
    { id:130, label:'Think Tanks'},
    { id:21, label:'Tobacco'},
    { id:108, label:'Translation & Localization'},
    { id:92, label:'Transportation/Trucking/Railroad'},
    { id:59, label:'Utilities'},
    { id:106, label:'Venture Capital & Private Equity'},
    { id:16, label:'Veterinary'},
    { id:93, label:'Warehousing'},
    { id:133, label:'Wholesale'},
    { id:142, label:'Wine & Spirits'},
    { id:119, label:'Wireless'},
    { id:103, label:'Writing & Editing'},
  ],
  'pt':[
    { id:64,label:'Administração agrícola'},
    { id:122,label:'Administração de serviços'},
    { id:75,label:'Administração governamental'},
    { id:63,label:'Agricultura'},
    { id:34,label:'Alimentos e bebidas'},
    { id:127,label:'Animação'},
    { id:93,label:'Armazenagem'},
    { id:50,label:'Arquitetura e planejamento'},
    { id:101,label:'Arrecadação de recursos'},
    { id:39,label:'Artes cênicas'},
    { id:111,label:'Artes e artesanato'},
    { id:133,label:'Atacado'},
    { id:91,label:'Atendimento ao consumidor'},
    { id:14,label:'Atendimento médico e hospitalar'},
    { id:139,label:'Atendimento médico psiquiátrico'},
    { id:72,label:'Atividades parlamentares'},
    { id:147,label:'Automação industrial'},
    { id:52,label:'Aviação e aeroespacial'},
    { id:41,label:'Bancos'},
    { id:45,label:'Bancos de investimento'},
    { id:38,label:'Belas-artes'},
    { id:25,label:'Bens de consumo'},
    { id:85,label:'Bibliotecas'},
    { id:12,label:'Biotecnologia'},
    { id:106,label:'Capital de risco e participações privadas'},
    { id:35,label:'Cinema e filmes'},
    { id:103,label:'Composição e revisão de textos'},
    { id:141,label:'Comércio e desenvolvimento internacional'},
    { id:48,label:'Construção'},
    { id:62,label:'Construção de ferrovia'},
    { id:58,label:'Construção naval'},
    { id:11,label:'Consultoria de gerenciamento'},
    { id:47,label:'Contabilidade'},
    { id:18,label:'Cosmética'},
    { id:1,label:'Defesa e espaço'},
    { id:102,label:'Desenvolvimento de programas'},
    { id:99,label:'Design'},
    { id:140,label:'Design gráfico'},
    { id:17,label:'Dispositivos médicos'},
    { id:82,label:'Editoração'},
    { id:132,label:'Educação à distância'},
    { id:146,label:'Embalagens e recipientes'},
    { id:51,label:'Engenharia civil'},
    { id:135,label:'Engenharia mecânica ou industrial'},
    { id:67,label:'Ensino fundamental/médio'},
    { id:68,label:'Ensino superior'},
    { id:87,label:'Entrega de encomendas e fretes'},
    { id:28,label:'Entretenimento'},
    { id:33,label:'Esportes'},
    { id:77,label:'Execução da lei'},
    { id:131,label:'Filantropia'},
    { id:136,label:'Fotografia'},
    { id:76,label:'Gabinete presidencial'},
    { id:46,label:'Gestão de investimentos'},
    { id:100,label:'Gestão de organização sem fins lucrativos'},
    { id:69,label:'Gestão educacional'},
    { id:3,label:'Hardware'},
    { id:31,label:'Hotelaria'},
    { id:44,label:'Imobiliário'},
    { id:134,label:'Importação e exportação'},
    { id:83,label:'Impressão'},
    { id:128,label:'Imóveis comerciais'},
    { id:53,label:'Indústria automotiva'},
    { id:15,label:'Indústria farmacêutica'},
    { id:54,label:'Indústria química'},
    { id:60,label:'Indústria têxtil'},
    { id:40,label:'Instalações e serviços de recreação'},
    { id:89,label:'Instituições religiosas'},
    { id:6,label:'Internet'},
    { id:29,label:'Jogos de azar'},
    { id:109,label:'Jogos de computador'},
    { id:81,label:'Jornais'},
    { id:73,label:'Judiciário'},
    { id:65,label:'Laticínios'},
    { id:30,label:'Lazer, viagens e turismo'},
    { id:94,label:'Linhas aéreas/Aviação'},
    { id:116,label:'Logística e cadeia de suprimentos'},
    { id:112,label:'Manufatura de eletroeletrônicos'},
    { id:55,label:'Maquinário'},
    { id:80,label:'Marketing e publicidade'},
    { id:49,label:'Materiais de construção'},
    { id:20,label:'Materiais esportivos'},
    { id:125,label:'Medicina alternativa'},
    { id:129,label:'Mercados de capital'},
    { id:71,label:'Militar'},
    { id:56,label:'Mineração e metais'},
    { id:37,label:'Museus e instituições'},
    { id:36,label:'Mídia de difusão'},
    { id:113,label:'Mídia online'},
    { id:26,label:'Móveis'},
    { id:115,label:'Música'},
    { id:114,label:'Nanotecnologia'},
    { id:90,label:'Organização cívica e social'},
    { id:107,label:'Organização política'},
    { id:130,label:'Organizações de pesquisa e orientação'},
    { id:61,label:'Papel e produtos florestais'},
    { id:66,label:'Pesca'},
    { id:70,label:'Pesquisa'},
    { id:97,label:'Pesquisa de mercado'},
    { id:57,label:'Petróleo e energia'},
    { id:117,label:'Plástico'},
    { id:79,label:'Política pública'},
    { id:23,label:'Produtos alimentícios'},
    { id:143,label:'Produtos de luxo e joias'},
    { id:24,label:'Produtos eletrônicos'},
    { id:126,label:'Produção de mídia'},
    { id:9,label:'Prática jurídica'},
    { id:13,label:'Prática médica'},
    { id:104,label:'Recrutamento e seleção'},
    { id:137,label:'Recursos humanos'},
    { id:144,label:'Recursos renováveis e meio ambiente'},
    { id:119,label:'Rede sem fio'},
    { id:5,label:'Redes'},
    { id:148,label:'Relações governamentais'},
    { id:74,label:'Relações internacionais'},
    { id:98,label:'Relações públicas e comunicações'},
    { id:120,label:'Resolução alternativa de litígios'},
    { id:32,label:'Restaurantes'},
    { id:19,label:'Roupas e moda'},
    { id:124,label:'Saúde, bem-estar e educação física'},
    { id:118,label:'Segurança de redes e computadores'},
    { id:121,label:'Segurança e investigações'},
    { id:78,label:'Segurança pública'},
    { id:42,label:'Seguros'},
    { id:7,label:'Semicondutores'},
    { id:86,label:'Serviços ambientais'},
    { id:84,label:'Serviços da informação'},
    { id:43,label:'Serviços financeiros'},
    { id:88,label:'Serviços individuais e familiares'},
    { id:10,label:'Serviços jurídicos'},
    { id:110,label:'Serviços para eventos'},
    { id:59,label:'Serviços públicos'},
    { id:4,label:'Software'},
    { id:22,label:'Supermercados'},
    { id:138,label:'Suprimentos e equipamentos comerciais'},
    { id:21,label:'Tabaco'},
    { id:96,label:'Tecnologia da informação e serviços'},
    { id:8,label:'Telecomunicações'},
    { id:123,label:'Terceirização e offshoring'},
    { id:108,label:'Tradução e localização'},
    { id:95,label:'Transporte marítimo'},
    { id:92,label:'Transporte/Caminhões/Trens'},
    { id:105,label:'Treinamento e orientação profissional'},
    { id:27,label:'Varejo'},
    { id:16,label:'Veterinária'},
    { id:145,label:'Vidro, cerâmica e concreto'},
    { id:142,label:'Vinhos e destilados'},
  ],
  'es':[
  { id:75, label:'Administración gubernamental'},
  { id:94, label:'Aeronáutica/Aviación'},
  { id:63, label:'Agricultura'},
  { id:34, label:'Alimentación y bebidas'},
  { id:93, label:'Almacenamiento'},
  { id:127, label:'Animación'},
  { id:29, label:'Apuestas y casinos'},
  { id:50, label:'Arquitectura y planificación'},
  { id:39, label:'Artes escénicas'},
  { id:111, label:'Artesanía'},
  { id:25, label:'Artículos de consumo'},
  { id:143, label:'Artículos de lujo y joyas'},
  { id:20, label:'Artículos deportivos'},
  { id:74, label:'Asuntos internacionales'},
  { id:139, label:'Atención a la salud mental'},
  { id:14, label:'Atención sanitaria y hospitalaria'},
  { id:147, label:'Automación industrial'},
  { id:41, label:'Banca'},
  { id:45, label:'Banca de inversiones'},
  { id:38, label:'Bellas Artes'},
  { id:85, label:'Bibliotecas'},
  { id:44, label:'Bienes inmobiliarios'},
  { id:128, label:'Bienes inmobiliarios comerciales'},
  { id:12, label:'Biotecnología'},
  { id:106, label:'Capital de riesgo y capital privado'},
  { id:48, label:'Construcción'},
  { id:58, label:'Construcción naval'},
  { id:11, label:'Consultoría de estrategia y operaciones'},
  { id:47, label:'Contabilidad'},
  { id:18, label:'Cosmética'},
  { id:145, label:'Cristal, cerámica y hormigón'},
  { id:77, label:'Cumplimiento de la ley'},
  { id:1, label:'Departamento de defensa y del espacio exterior'},
  { id:33, label:'Deportes'},
  { id:9, label:'Derecho'},
  { id:102, label:'Desarrollo de programación'},
  { id:141, label:'Desarrollo y comercio internacional'},
  { id:99, label:'Diseño'},
  { id:140, label:'Diseño gráfico'},
  { id:104, label:'Dotación y selección de personal'},
  { id:132, label:'E-learning'},
  { id:67, label:'Educación primaria/secundaria'},
  { id:71, label:'Ejército'},
  { id:24, label:'Electrónica de consumo'},
  { id:146, label:'Embalaje y contenedores'},
  { id:144, label:'Energía renovable y medio ambiente'},
  { id:68, label:'Enseñanza superior'},
  { id:28, label:'Entretenimiento'},
  { id:87, label:'Envío de paquetes y carga'},
  { id:3, label:'Equipos informáticos'},
  { id:103, label:'Escritura y edición'},
  { id:131, label:'Filantropía'},
  { id:105, label:'Formación profesional y capacitación'},
  { id:136, label:'Fotografía'},
  { id:130, label:'Gabinetes estratégicos'},
  { id:64, label:'Ganadería'},
  { id:46, label:'Gestión de inversiones'},
  { id:100, label:'Gestión de organizaciones sin ánimo de lucro'},
  { id:69, label:'Gestión educativa'},
  { id:31, label:'Hostelería'},
  { id:134, label:'Importación y exportación'},
  { id:83, label:'Imprenta'},
  { id:52, label:'Industria aeroespacial y aviación'},
  { id:15, label:'Industria farmacéutica'},
  { id:19, label:'Industria textil y moda'},
  { id:51, label:'Ingeniería civil'},
  { id:135, label:'Ingeniería industrial o mecánica'},
  { id:40, label:'Instalaciones y servicios recreativos'},
  { id:89, label:'Instituciones religiosas'},
  { id:5, label:'Interconexión en red'},
  { id:6, label:'Internet'},
  { id:70, label:'Investigación'},
  { id:97, label:'Investigación de mercado'},
  { id:73, label:'Judicial'},
  { id:116, label:'Logística y cadena de suministro'},
  { id:65, label:'Lácteos'},
  { id:112, label:'Manufactura eléctrica/electrónica'},
  { id:62, label:'Manufactura ferroviaria'},
  { id:55, label:'Maquinaria'},
  { id:80, label:'Marketing y publicidad'},
  { id:138, label:'Material y equipo de negocios'},
  { id:49, label:'Materiales de construcción'},
  { id:125, label:'Medicina alternativa'},
  { id:113, label:'Medios de comunicación en línea'},
  { id:36, label:'Medios de difusión'},
  { id:129, label:'Mercados de capital'},
  { id:56, label:'Minería y metalurgia'},
  { id:26, label:'Mobiliario'},
  { id:37, label:'Museos e instituciones'},
  { id:115, label:'Música'},
  { id:114, label:'Nanotecnología'},
  { id:95, label:'Naval'},
  { id:30, label:'Ocio, viajes y turismo'},
  { id:76, label:'Oficina ejecutiva'},
  { id:72, label:'Oficina legislativa'},
  { id:90, label:'Organización cívica y social'},
  { id:107, label:'Organización política'},
  { id:35, label:'Películas y cine'},
  { id:81, label:'Periódicos'},
  { id:57, label:'Petróleo y energía'},
  { id:66, label:'Piscicultura'},
  { id:117, label:'Plásticos'},
  { id:79, label:'Política pública'},
  { id:23, label:'Producción alimentaria'},
  { id:126, label:'Producción multimedia'},
  { id:61, label:'Productos de papel y forestales'},
  { id:54, label:'Productos químicos'},
  { id:13, label:'Profesiones médicas'},
  { id:78, label:'Protección civil'},
  { id:82, label:'Publicaciones'},
  { id:101, label:'Recaudación de fondos'},
  { id:137, label:'Recursos humanos'},
  { id:148, label:'Relaciones gubernamentales'},
  { id:98, label:'Relaciones públicas y comunicaciones'},
  { id:120, label:'Resolución de conflictos por terceras partes'},
  { id:32, label:'Restaurantes'},
  { id:124, label:'Sanidad, bienestar y ejercicio'},
  { id:53, label:'Sector automovilístico'},
  { id:60, label:'Sector textil'},
  { id:118, label:'Seguridad del ordenador y de las redes'},
  { id:121, label:'Seguridad e investigaciones'},
  { id:42, label:'Seguros'},
  { id:7, label:'Semiconductores'},
  { id:91, label:'Servicio al consumidor'},
  { id:84, label:'Servicio de información'},
  { id:110, label:'Servicios de eventos'},
  { id:43, label:'Servicios financieros'},
  { id:122, label:'Servicios infraestructurales'},
  { id:10, label:'Servicios jurídicos'},
  { id:86, label:'Servicios medioambientales'},
  { id:17, label:'Servicios médicos'},
  { id:88, label:'Servicios para el individuo y la familia'},
  { id:59, label:'Servicios públicos'},
  { id:96, label:'Servicios y tecnologías de la información'},
  { id:4, label:'Software'},
  { id:123, label:'Subcontrataciones/Offshoring'},
  { id:22, label:'Supermercados'},
  { id:21, label:'Tabaco'},
  { id:119, label:'Tecnología inalámbrica'},
  { id:8, label:'Telecomunicaciones'},
  { id:108, label:'Traducción y localización'},
  { id:92, label:'Transporte por carretera o ferrocarril'},
  { id:133, label:'Venta al por mayor'},
  { id:27, label:'Venta al por menor'},
  { id:16, label:'Veterinaria'},
  { id:109, label:'Videojuegos'},
  { id:142, label:'Vinos y licores'},
  ]
}


export {
  sectors
} 