<template>
<div id="directorio" class="panelContent directorio" >
    <PartnerInfo v-if="partnerUser && partnerUser.reviews && currentCompDirectorio!='busqueda'"  :partner="partnerUser" :cant="users.length" :key="partnerUser.reviews.data.length" />
    <Search v-else-if="!partnerUser" :search.sync='searchString' />
    <!--ACA ENTRA PARA LAS EMPRESAS ETC -->
    <dirPreview v-if="currentCompDirectorio=='linckard' && dirUser && cardVal" :linc="cardVal" :user="dirUser" :profile="$store.state.userProfile"  :users="users" :key="cardVal.id" />
    <!--ACA ENTRA SOLO PARA LA MAIN LINCKARD -->
    <dirPreview v-else-if="currentCompDirectorio=='linckard' && partnerUser && cardVal" :linc="cardVal" :user="partnerUser" :profile="$store.state.userProfile" :key="cardVal.id"  :users="null"/>
    <subDir  v-else-if="dirUser && currentCompDirectorio=='subdirectorio'"  :user="dirUser" />
    <mainDir  v-else-if="partnerUser && currentCompDirectorio=='catalogo'" :users="users" />
    <partnerDir v-else-if="currentCompDirectorio=='busqueda'" :users="users" :profile="$store.state.userProfile" :search='searchString' :key="searchString"  />
</div>
</template>

<script>




import subDir from "@/layouts/main_Directorio/main_Busqueda/subDir.vue";
import mainDir from "@/layouts/main_Directorio/main_Busqueda/mainDir.vue";
;
import dirPreview from "@/layouts/main_Directorio/main/dirPreview.vue";
import PartnerInfo from "../main_Partners/PartnerInfo.vue";
import Search from "@/layouts/main_Directorio/main_Busqueda/Search.vue";
import partnerDir from "./contactDir_p.vue";


export default {
    data() {
        //7299 linckard weight
        return{
            //Searchs
            searchString: '',
            dirActiveSector:'all',
            currentCompDirectorio: 'busqueda',
            dirUser:null,
            partnerUser:null,
            cardVal:null,
            getUser:this.$store.state.userProfile

            
        }
    },
    created(){
        var dis=this;
        
        this.$bus.$on('contacts_switchMainDirUser', dir => {
            dis.dirUser = dir;
            if(dir){
                dis.currentCompDirectorio='subdirectorio'
            }else{
                dis.currentCompDirectorio='catalogo'

            }
        });
        this.$bus.$on('contacts_switchMainPartnerUser', dir => {
            dis.dirUser = null;
            dis.partnerUser = dir;
        });

        this.$bus.$on('contacts_switchMainDirLinckard', index => {
            dis.cardVal=index
        });
        
         this.$bus.$on('contacts_switchRootMainDirComp', comp => {
             if(comp=='directorio'){
                 //dis.partnerUser=null
                 comp='catalogo'
             }else if(comp=='catalogo'){
                 dis.dirUser=null
             }
             dis.currentCompDirectorio=comp
        });
    
    },
    computed: {
        users() {
            var dis=this;
            var devolver=[]
            if(!this.partnerUser)devolver=this.getUser.contacts
            if(this.partnerUser)devolver=this.partnerUser.reviews.users
            
            if(this.sublincs/* && this.searchString*/)devolver=this.sublincs
            return devolver;
            
        },    
        
    },
    components:{
        Search,
        partnerDir,
        PartnerInfo,
        mainDir,
        subDir,
        dirPreview
    },
}

</script>

