<template>
<div>
    <div class="edicion_block">
        <h4 class="mb10"><b>{{$t('firmas.editor_e1')}}</b></h4>
        <small class="info_text_sm">{{$t('firmas.editor_e2')}}</small>
    </div>
    <hr class="mt20 mb20">
     <div class="edicion_block">
        <h4 class="mb10"><b>Logo</b></h4>
        <small class="info_text_sm">{{$t('firmas.editor_e2')}}</small>
        <div class="btn_container mt20 mb20">
            <imagePicker imgid="profile" v-model="preview.img" :aspect="getRatio" />
            <small class="info_text_sm mt15">{{$t('firmas.editor_e3')}} </small>
        </div>
        <div v-if="signature.template_id==7" class="form-group">
            <sizePicker v-model="preview.logo_width" :value="100" :label="$t('pickers.width')" :unit="''" :min='50' :max='180' :interval="1"/>
        </div>
    </div>
    <hr class="mt20 mb20">
    <div class="edicion_block">
        <h4 class="mb10">{{$t('firmas.editor_e4')}}</h4>
        <small class="info_text_sm">{{$t('firmas.editor_e5')}}</small>
        <div class="row form_row mt20">
            <div class="col-lg-6">
                <colorPicker  :label="$t('firmas.ed1')"  :color="preview.color_nombre" v-model="preview.color_nombre"/>
            </div>
            <div class="col-lg-6">
                <colorPicker :label="$t('firmas.ed2')" :color="preview.color_btn_principal" v-model="preview.color_btn_principal" />
            </div>
        </div>
         <div class="row form_row mt10">
            <div class="col-lg-6">
                <colorPicker :label="$t('firmas.ed3')"  :color="preview.color_btn_sec" v-model="preview.color_btn_sec"/>
            </div>
            <div class="col-lg-6">
                <colorPicker :label="$t('firmas.ed4')" :color="preview.color_redes" v-model="preview.color_redes" />
            </div>
        </div>
    </div>
    <hr class="mt20 mb20">
    <div class="edicion_block">
        <h4 class="mb10 d-flex">Banner <span class="label pro mt-auto mb-auto ml-auto mr-0">Pro</span></h4>
        <small class="info_text_sm">{{$t('firmas.editor_e7')}}</small>
        <div class="btn_container mt20 mb25"><!--{{imgBanner}}-->
            <imagePicker :btext="$t('pickers.loadi')" imgid="sobre" v-model="imgBanner" /> 
        </div>
        <div  class="form-group">
            <sizePicker v-model="preview.banner.width" :value="400" :label="$t('pickers.width')" :unit="''" :min='50' :max='400' :interval="50"/>
        </div>
        <div  class="form-group">
            <label>{{$t('firmas.ed5')}}</label>
            <input class="form-control"  name="url" type="text" maxlength="200" placeholder="Website" v-model='preview.url'>
        </div>
    </div>
    <hr class="mt20 mb20">
    <div class="edicion_block">
        <h4 class="mb10 d-flex">{{$t('general.desc')}}</h4>
        <small class="info_text_sm mb10">{{$t('firma.descsub')}}</small>
        <div  class="form-group">
            <textarea maxlength="1500"  rows="7" type="text"  v-model="preview.desc"  style="width:100% !important;border:0px;overflow:hidden;" ></textarea>
        </div>
    </div>
</div>  
</template>

<script>

import sizePicker from '@/layouts/main_Customizer/dinputs/sizePicker'
import imagePicker from '@/components/imagePicker.vue'
import colorPicker from '@/layouts/main_Customizer/dinputs/colorPicker'

export default {
    props:['signature'],
    data(){
      return{
            getUser:this.$store.state.userProfile,
            preview:this.signature,
            imgBanner:this.signature.banner.img
      }
    },
    watch:{
        imgBanner(val){
            if(!this.signature.banner.img && val && this.$util.isTrialOrPro(this.$modal,'signature_banner')){
                this.preview.banner.img=val
            }else if (this.preview.banner.img){
                this.preview.banner.img=val
            }
        }
    },
    computed:{
        getRatio(){
            console.log(this.preview.template_id)
            if(this.preview.template_id==4
            || this.preview.template_id==5
            || this.preview.template_id==7
            || this.preview.template_id==8)return 16/9
            else return 1
        }
    },
    components:{
        imagePicker,colorPicker,sizePicker
    }
};
</script>

<style>

</style>


