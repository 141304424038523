<template>

            <div v-if="!loading" class="partner_wrapper">
                <div class="partner_header">
                    <button type="button" v-if="isFav()" @click='removeFav()'  class="partner_btn_right"><font-awesome-icon icon="heart"  style="color:#F531A6;"/></button>
                    <button type="button" v-else class="partner_btn_right"  @click='addFav()' ><font-awesome-icon icon="heart"/></button>
                    <div class="partner_avatar">
                         <img :src="getImage()" width="100%">
                    </div>
                     <h6 class="partner_user_name">{{partnerUser.account_details.account_name}}</h6>
                    <span v-if="partnerUser.account_details.account_subtitle && partnerUser.account_details.account_subtitle.length>0" class="partner_user_prof">{{partnerUser.account_details.account_subtitle}}</span>
                    <span v-else class="partner_user_prof">{{$t('partners.missi')}}</span>

                    
                </div>

               <!-- <div class="partner_contador">
                    <h6 >{{$t('partners.gener')}}</h6>
                    <span>{{partnerUser.activeLincs}}</span>
                </div>-->
       
                <div class="partner_valuaciones">
                    <h6>{{$t('partners.vals')}}</h6>
                    <reviewStars :value="getAverage()"/>
                </div>
                <div class="pb20 pr10 pl10 pt10">
                    <button type="button" @click="switchMainDirectorio()"  class="partner_button">{{$t('partners.cata')}}</button>
                </div>
            </div>
            <div v-else-if="loading"  class="partner_wrapper">
                <font-awesome-icon icon="circle-notch" style="color:#99287B;font-size:50px;margin-top:50px;" spin />
            </div>

</template>

<script>


import {usersCollection } from '@/services/firebaseConfig.js';
import reviewStars  from '@/components/reviewStars';

export default {
    props: ['user','users'],
	data() {
		return { 
            partnerUser: this.user,
            getUser:this.$store.state.userProfile,
            loading:false,
            laLista:this.users,
        }
    },
    methods:{
        getAverage(){
            if(!this.partnerUser.rating)this.partnerUser.rating=0
            return this.partnerUser.rating
        },
        getImage(){
            var img=this.$util.getImage(this.partnerUser);
            if(img==null)return  require("@/assets/images/profile_nophoto_color.png");
            else if(img && img.obj)return img.obj;
            else if(img)return this.$util.removeToken(img)
        },
        async dbgetUser(uid){
            return usersCollection.doc(uid).get()
            .then(function(doc) {   
                let user=doc.data()
                return user
            }).catch(function(error) {
                dis.$util.notif_error(error.message)
            });
        },
        async switchMainDirectorio() {
                
            var dis=this
            let subusers=[]
            this.loading=true
            this.partnerUser=await dis.$util.dbgetUser(dis.partnerUser.uid)
            
            if(!this.partnerUser.reviews || !this.partnerUser.reviews.users )this.partnerUser.reviews={data:[],users:[]}
            //this.$bus.$emit(this.$util.compPrefix()+"switchMainDirLista", this.laLista);
            this.$bus.$emit(this.$util.compPrefix()+"switchMainPartnerUser", dis.partnerUser);
            this.$bus.$emit(this.$util.compPrefix()+"switchRootMainDirComp", 'catalogo')
        },
        isFav(){
            return this.$util.isFav(this.getUser,this.partnerUser.uid);
        },
        addFav(){
            this.$util.addFav(this.$modal,this.getUser,this.partnerUser);
        },
        removeFav(){
            this.$util.removeFav(this.getUser,this.partnerUser.uid);
        },
        
    },
    components:{
        reviewStars
    }
  
}

</script>

