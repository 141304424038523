<template>
<div id="directorio" class="panelContent directorio" >
    <Search :search.sync='searchString'/>
    <!--ACA ENTRA PARA LAS EMPRESAS ETC -->
    <dirPreview v-if="currentCompDirectorio=='linckard' && dirUser && cardVal" :linc="cardVal" :user="dirUser" :profile="$store.state.userProfile"  :users="users" :key="cardVal.id" />
    <!--ACA ENTRA SOLO PARA LA MAIN LINCKARD -->

    <subDir  v-else-if="dirUser && users.length>0" :user="dirUser" :users="users" />
    <contactDirc  v-else-if="!dirUser && currentCompDirectorio=='directorio'  && users.length>0" :search='searchString' :key="searchString" />
    
    <div  v-else-if="users.length==0"  class="main_content scolable">
        <div class="eventos_wrapper_empty">
            <div class="wrapper_middle">
                <img src="@/assets/images/Icon_No_Contacts.svg" width="180">
                <h3>{{$t('general.contactno')}}</h3>
                <p>{{$t('general.contactnosub1')}} <a style="color:#448FDE !important;" @click="$router.push('/directory')"><b>{{$t('general.contactnosub2')}}</b></a> {{$t('general.contactnosub3')}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>



import subDir from "@/layouts/main_Directorio/main_Busqueda/subDir.vue";
;
import Search from "@/layouts/main_Directorio/main_Busqueda/Search.vue";
import dirPreview from "@/layouts/main_Directorio/main/dirPreview.vue";
import contactDirc from "@/layouts/main_Directorio/main_Contactos/contactDir_c.vue";

export default {
    data() {
        //7299 linckard weight
        return{
            getUser:this.$store.state.userProfile,
            //Searchs
            searchString:'',
            orderBy:this.$store.state.orderBy,
            currentCompDirectorio: 'directorio',
            dirUser:null,
            cardVal:null,

            //Esta sirve para los subdirs creo
            sublincs:null,
            
        }
    },
    async beforeMount(){
        var dis=this;
        this.orderBy='byname'
        this.getUser=this.$store.state.userProfile
        
    },

    created(){
        var dis=this;
       
        this.$bus.$on('contacts_switchMainDirUser', dir => {
            dis.dirUser = dir;
        });

        this.$bus.$on('contacts_switchMainDirLista', lista => {
            dis.sublincs = lista;
        });
        this.$bus.$on('contacts_switchMainDirLinckard', index => {
            dis.cardVal=index
        });

        this.$bus.$on('contacts_switchRootMainDirComp', comp => {
            dis.currentCompDirectorio = comp;
            
        });


    },
    computed: {
        users() {
            let dis=this;
            let devolver=this.getUser.contacts
            if(this.sublincs)devolver=this.sublincs
            ///Ojo cuando quieras ordenar
            /*if(this.orderBy=='byname'){
                devolver=devolver.sort((a, b) => a.account_details.account_name.localeCompare(b.account_details.account_name))
            }else if(this.orderBy=='relevance'){
                devolver=devolver.sort((a, b) =>  a.id - b.id)
            }*/

            
            return devolver
            
        }, 
        
        
    },
    components:{
        contactDirc,
        Search,
        subDir,
        dirPreview
    },
}

</script>

