<template>
    <div class="edicion_block">
        <h4>{{$t('drags.imagen')}}</h4>
        <div class="row form_row">
            <div class="col-12">
                <imagePicker :btext="$t('pickers.loadi')" imgid="profile" v-model="valor.img" :isCircle="true" :aspect="true" :isCrop="true"  />
                <img v-if="$util.isTesting()" :src="valor.img"/>
                <span v-if="$util.isTesting()" >{{valor.img?valor.img:'no hay'}}</span>
            </div>
            <div class="col-12" style="width: 100%;">
                <sizePicker :value="valor.size==undefined?'50%':valor.size" min="10" max="100" unit="%"  v-model="valor.size"/>
            </div>
            <div class="row form_row">
        </div>
        </div>
    </div>
</template>

<script>
import imagePicker from '@/components/imagePicker.vue'
import sizePicker from '../dinputs/sizePicker'

export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value
		}
    },
    components:{
        imagePicker,sizePicker
    },
    
}
</script>

