<template>
  <modal
    name="modal-applytemplateall"
    transition="nice-modal-fade"
    :min-width="200"
    :min-height="200"
    :pivot-y="0.5"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    width="400"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal_wrapper ">
      <div slot="top-right">
        <button
          @click="$modal.hide('modal-applytemplateall')"
          class="ngdialog-close"
        ></button>
      </div>
      <form class="card mb20" novalidate @submit.prevent="validateForm()">
        <div class="card-body">
          <h4
            style="color:#438FDE;font-size: 28px;text-align:center; margin: 0 0 0px;display:block !important;"
          >
            Apply to all
          </h4>
          <p style="text-align:center;font-size: 10px;">
            Selecciona el rango de ID's de linkcards en las que aplicar
          </p>
          <div class="form-group mt20 ">
            <input
              class="form-control dark"
              type="text"
              v-validate="{ required: true }"
              name="range"
              maxlength="5000"
              :placeholder="'Ids separados por con comma (,) Ej. 61,62,65,78'"
              v-model="range"
            />
            <client-only
              ><span class="vuerror">{{
                errors.first("range")
              }}</span></client-only
            >
          </div>
          <div
            class="form-group mt20"
            style="margin-bottom:0px;width:100% !important;display:block;"
          >
            <button-spinner
              class="btn btn-initial btn-primary text-uppercase  w-100"
              type="submit"
              :is-loading="loading"
              :disabled="loading"
              :status="status"
            >
              APPLY</button-spinner
            >
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>
<script>
import { db } from "@/services/firebaseConfig.js";

export default {
  data() {
    return {
      getUser: this.$store.state.userProfile,
      loading: false,
      status: "",
      range: "",
      templateVal: null,
      funcionRange: "applytemplate",
    };
  },

  methods: {
    beforeOpen(event) {
      if (event.params.template)
        this.templateVal = JSON.parse(JSON.stringify(event.params.template));
    },
    befreClose(event) {
      dis.loading = false;
    },
    validateForm() {
      var dis = this;
      dis.loading = true;
      dis.status = true;

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            dis.applytemplate();
          } else {
            dis.loading = false;
            dis.status = false;
            setTimeout(() => {
              dis.status = "";
            }, 1000);
          }
        })
        .catch((error) => {
          dis.loading = false;
          dis.status = false;
          setTimeout(() => {
            dis.status = "";
          }, 1000);
        });
    },

    async applytemplate() {
      var dis = this;
      if (!dis.range) return;
      dis.loading = true;
      let init = this.range.split(",");
      console.log(init);
      for (let el_id in init) {
        let je = init[el_id] ? init[el_id] : init;
        let i = dis.getUser.linckards.findIndex(function(i) {
          return i.id == je;
        });
        if (!dis.getUser.linckards[i]) continue;
        console.log(dis.getUser.linckards[i]);
        console.log("pasa", je, i, dis.getUser.linckards[i].template_load);
        if (dis.getUser.linckards[i].template_load >= 0) {
          alert("ojo aca, solo para nordec");
          return;
        } else {
          dis.getUser.linckards[i].sobredrags = dis.templateVal.sobredrags;
          dis.getUser.linckards[i].style = dis.templateVal.style;
        }
      }
      await this.$util.E(dis.getUser.linckards);
      dis.$util.notif_success(null);
      dis.loading = false;
      dis.$modal.hide("modal-applytemplateall");
    },
  },
};
</script>
<style>
.modal_wrapper {
  padding: 10px;
  font-style: 13px;
  border-radius: 5px;
}
.v--modal-overlay[data-modal="size-modal"] {
  background: #ededed;
}
</style>
