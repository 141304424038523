<template>
  <modal
    name="modal-transfer"
    transition="nice-modal-fade"
    :min-width="200"
    :min-height="200"
    :pivot-y="0.5"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    width="400"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal_wrapper ">
      <div slot="top-right">
        <button
          @click="$modal.hide('modal-transfer')"
          class="ngdialog-close"
        ></button>
      </div>
      <form class="card mb20" novalidate @submit.prevent="validateForm()">
        <div class="card-body">
          <h4
            style="color:#438FDE;font-size: 28px;text-align:center; margin: 0 0 0px;display:block !important;"
          >
            Email to
          </h4>
          <p style="text-align:center;font-size: 10px;">
            Seleccionar el email al cual transferir (!isEnterprise)
          </p>
          <div class="form-group mt20 ">
            <input
              class="form-control dark"
              type="text"
              v-validate="{ required: true }"
              name="nombre"
              maxlength="50"
              :placeholder="'Email'"
              v-model="email"
            />
            <client-only
              ><span class="vuerror">{{
                errors.first("nombre")
              }}</span></client-only
            >
          </div>
          <div v-if="!isTemplate" class="form-group mt20 ">
            <input
              class="form-control dark"
              type="text"
              v-validate="{ required: true }"
              name="sector_id"
              maxlength="50"
              :placeholder="'Sector ID'"
              v-model="sector_id"
            />
            <client-only
              ><span class="vuerror">{{
                errors.first("nombre")
              }}</span></client-only
            >
          </div>

          <div
            class="form-group mt20"
            style="margin-bottom:0px;width:100% !important;display:block;"
          >
            <button-spinner
              class="btn btn-initial btn-primary text-uppercase  w-100"
              type="submit"
              :is-loading="loading"
              :disabled="loading"
              :status="status"
            >
              TRANSFER (COPY)
            </button-spinner>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>
<script>
import { db } from "@/services/firebaseConfig.js";

export default {
  data() {
    return {
      getUser: this.$store.state.userProfile,
      loading: false,
      status: "",
      email: "",
      cardVal: null,
      isTemplate: false,
      yaseedito: false,
      sector_id: 0,
      changeType: "bc",
    };
  },

  methods: {
    beforeOpen(event) {
      this.cardVal = JSON.parse(JSON.stringify(event.params.linkcard));
      this.isTemplate = event.params.isTemplate;
      this.changeType = event.params.changeType;
    },
    validateForm() {
      var dis = this;
      dis.loading = true;
      dis.status = true;

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            if (!dis.isTemplate) dis.transferLinkcard();
            else dis.transferTemplate();
          } else {
            dis.loading = false;
            dis.status = false;
            setTimeout(() => {
              dis.status = "";
            }, 1000);
          }
        })
        .catch((error) => {
          dis.loading = false;
          dis.status = false;
          setTimeout(() => {
            dis.status = "";
          }, 1000);
        });
    },
    async transferTemplate() {
      var dis = this;
      dis.loading = true;
      if (!this.email) return;
      let destino = await db
        .collection("users")
        .where("email", "==", this.email)
        .get()
        .then(async (querySnapshot) => {
          let data = querySnapshot.docs[0];
          if (!data) return;
          return data.data();
        });
      dis.cardVal.from = destino.uid;
      if (this.changeType)
        dis.cardVal.type = dis.cardVal.type == "bc" ? "mp" : "bc";

      let last_id = await db
        .collection("templates")
        .get()
        .then(async (querySnapshot) => {
          return querySnapshot.size;
        });
      console.log("El last id: ", last_id);
      await db
        .collection("templates")
        .doc(last_id + "")
        .set(dis.cardVal)
        .then(function() {
          dis.$util.notif_success(null);
          dis.loading = false;
          dis.$modal.hide("modal-transfer");
        });
    },
    async transferLinkcard() {
      var dis = this;
      dis.loading = true;
      if (!this.email) return;
      let destino = await db
        .collection("users")
        .where("email", "==", this.email)
        .get()
        .then(async (querySnapshot) => {
          let data = querySnapshot.docs[0];
          if (!data) return;
          return data.data();
        });
      console.log(destino.uid, this.cardVal, destino.stats.c_linckards);

      //Set id
      if (!destino.stats.c_linckards || isNaN(destino.stats.c_linckards))
        destino.stats.c_linckards = 0;
      destino.stats.c_linckards++;

      this.cardVal.sector_id = this.sector_id;
      this.cardVal.id = destino.stats.c_linckards;
      destino.linckards.push(this.cardVal);

      console.log(destino.linckards);
      await this.$util.E(destino.linckards, destino);
      await db
        .collection("users")
        .doc(destino.uid)
        .update({ stats: destino.stats });
      dis.loading = false;
      dis.$modal.hide("modal-transfer");
    },
  },
};
</script>
<style>
.modal_wrapper {
  padding: 10px;
  font-style: 13px;
  border-radius: 5px;
}
.v--modal-overlay[data-modal="size-modal"] {
  background: #ededed;
}
</style>
