const middleware = {}

middleware['admin-check'] = require('../middleware/admin-check.js')
middleware['admin-check'] = middleware['admin-check'].default || middleware['admin-check']

middleware['auth-check'] = require('../middleware/auth-check.js')
middleware['auth-check'] = middleware['auth-check'].default || middleware['auth-check']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
