
<template>
    <modal name="modal-news" transition="nice-modal-fade" classes="ngdialog-content" :min-width="200" :min-height="200"
        :pivot-y="0.5" :adaptive="true" :scrollable="true" :reset="true" width="400" height="auto">
        <div class="modal_wrapper">
            <div slot="top-right">
                <button @click="$modal.hide('modal-qr')" class="ngdialog-close"></button>
            </div>
            <div class="modalSusc">
                Hola
            </div>
        </div>
    </modal>
</template>

<script>
export default {

    data() {
        return {
        }
    },
    methods: {},
    components: {},
}
</script>
<style></style>


