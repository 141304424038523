<template>
    <div style="width: 336px; align-items: center;">
        <center>
            <Icon 
            :icon="element.icon ? element.icon : 'ph:warning-octagon-thin'" 
            :style="'font-size: ' + (element.size ? element.size : '150px') + ';'" 
            style="font-size: 18px; display: inline-block; vertical-align: middle" />
        </center>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2"

export default {
    props: ['element'],
    components: {
        Icon
    },
}
</script>

