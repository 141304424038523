
<template>
<client-only>
  <modal name="modal-beneficio"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
         
   <div class="modal_wrapper " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-beneficio')" class="ngdialog-close"></button>
        </div>
        <form v-if="benefit" class="card " novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h3 >{{$t('loyaltys.titulodelb')}}</h3>
                <div class="form-group  ">
                    <input class="form-control dark" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="$t('loyaltys.titulodelb_p')" v-model='benefit.name'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <h3 >{{$t('loyaltys.descdelb')}}</h3>
                <div class="form-group">
                    <textarea maxlength="400" style="overflow:hidden" rows="6"  name="desc" v-validate="{ required: true }" class="form-control dark" type="text"  :placeholder="$t('loyaltys.descdelb_p')"  v-model='benefit.desc'/>
                    <client-only><span class="vuerror">{{ errors.first('desc') }}</span></client-only>
                </div>
                <h3 >{{$t('loyaltys.pointsdelb')}}</h3>
                <div class="form-group  ">
                    <input class="form-control w-50 dark" v-validate="{ required: true }" name="points" type="number"  :placeholder="$t('loyaltys.puntos')" v-model='benefit.points'>
                    <client-only><span class="vuerror">{{ errors.first('points') }}</span></client-only>
                    <p class=" pr20 mb20 d_block t_11px">* {{$t('loyaltys.pointsdelbsub')}}</p>
                </div>
                <div class="form-group " style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100" 
                    type="submit" 
                    :is-loading="loading"
                    :disabled="loading"
                    :status="status"> 
                        {{$t('general.guardar')}} 
                    </button-spinner>
                </div>

            </div>
        </form>
      
        
    </div>
  </modal>
</client-only>
</template>
<script>
import { Validator } from "vee-validate";
import {functions,usersCollection } from '@/services/firebaseConfig.js';

import { planes_businesscards} from '@/mixins/stripe.js'
import {sectors} from '@/mixins/categories.js'

export default {
    data () {
      return {
        getUser:this.$store.state.userProfile,
        status:'',
        loading:false,
        benefit:{
            name:'',
            desc:'',
            points:''
        },
        objMain:null
      }
    },
    methods: {
        beforeOpen (event) {
            var dis=this
            this.objMain=event.params.objMain
            let elid=event.params.id
            if(elid!=null){
                let index=this.objMain.benefits.findIndex(e=>{return e.id==elid})
                this.benefit=JSON.parse(JSON.stringify(this.objMain.benefits[index]))
            }else{
                let newBenefit={
                    name:'',
                    desc:'',
                    points:''
                }
                this.benefit=JSON.parse(JSON.stringify(newBenefit))
            }
            
        },
        async saveBenefit(){
            var dis=this
            //nuevo
            if(dis.benefit.id!=null){
                let index=this.objMain.benefits.findIndex(e=>{return e.id==dis.benefit.id})
                this.objMain.benefits.splice(index,1,this.benefit)
            }else{
                //Nuevo
                this.objMain.benefits.push({id:this.objMain.benefits.length+1,name:dis.benefit.name,desc:dis.benefit.desc,status:'active',deleted:false,points:dis.benefit.points})
            }
            if(!this.$util.isOnboarding())await this.$util.saveBenefits(this.objMain.id,this.objMain.benefits)
            dis.loading = false
            dis.status = '';
            dis.$modal.hide('modal-beneficio') 
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll().then((result) => {
                if(result){
                    dis.saveBenefit()
                }else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
    },
  }
</script>

<style scoped>
.listaagentes{
max-height: 450px !important;
overflow-y: scroll;
}
.card-body h3{
    font-size:20px;
    text-align: left;
    margin-top:10px;
    color:#448FDE;
}
</style>