<template>
<div v-if="$store.state.sobreComp=='leftColSobreDA'" class="edicion_block">
        <h4>{{$t('drags.premio')}}</h4>
        <div class="row form_row">
            <div class="col-12">
                <label>{{$t('drags.titulo')}}</label>
                <div class="form-group">
                    <input class="form-control" type="text" maxlength="50" :placeholder="$t('general.entervalue')" v-model="valor.value">
                </div>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <label>{{$t('general.desc')}}</label>
                <div class="form-group">
                    <textarea  name="desc" type="text" maxlength="500" class="form-control  "  :placeholder="$t('general.entervalue')" v-model="valor.desc" rows='7'></textarea>
                </div>
            </div>
        </div>
</div>
<div v-else >
    <div class="edicion_block">
        <h4>{{$t('drags.editando')}} <b>"{{$t('drags.icon')}}"</b></h4>
        <div class="row form_row">
            <div class="col-lg-12">
                <sizePicker :value="valor.icon_size==undefined?'45px':valor.icon_size"  v-model="valor.icon_size"/>
            </div>
            <div class="col-lg-12">
                <colorPicker :color="valor.icon_color"  v-model="valor.icon_color" />
           
            </div>
        </div>
    </div>
    <hr class="separator mt20 mb20">
    <div class="edicion_block">
        <h4>{{$t('drags.editando')}} <b>"{{$t('drags.titulo')}}"</b></h4>
        <div class="row form_row">
            <div class="col-12">
                <fontPicker v-model="valor.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-12">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="valor.weight" />
            </div>
        </div>

        <div class="row form_row">

            <div class="col-lg-6">
                <sizePicker   v-model="valor.size"/>
           
            </div>
            <div class="col-lg-6">

                <colorPicker :color="valor.color"   v-model="valor.color" />
            
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-6">
                <sizePicker :label="$t('pickers.inter')" v-model="valor.lineh"/>
     
            </div>
        </div>
    </div>
    <hr class="separator mt20 mb20">
    <div class="edicion_block">
        <h4>{{$t('drags.editando')}} <b>"{{$t('general.desc')}}"</b></h4>
        <div class="row form_row">
           
            <div class="col-12">
                <fontPicker v-model="valor.desc_family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-6">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''" v-model="valor.desc_weight" />
            </div>
            <div class="col-lg-6">
                <sizePicker :value="valor.desc_size==undefined?'20px':valor.desc_size"  v-model="valor.desc_size"/>
           
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-6">
                <colorPicker :color="valor.desc_color"  v-model="valor.desc_color" />
            </div>
            <div class="col-lg-6">
                <sizePicker :label="$t('pickers.inter')" v-model="valor.desc_lineh"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'
import fontPicker from '../dinputs/fontPicker'

export default {
	props: ['value'],
	data() {
		return { 
            valor:this.value,
		}
    },  
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'fontPicker':fontPicker
    }
}
</script>

