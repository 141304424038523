import Vue from 'vue'
import VueGtag from 'vue-gtag'
/*
Vue.use(VueGtag, {
  config: { id: 'G-XXXXXXXXXX' }
})*/


export default ({ app }) => {
  let gtag='G-06623M8331'
  Vue.use(VueGtag, {
    config: { id: gtag },
  }, app.router);
}