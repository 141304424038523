<template>
<div class="colapsables" id="v-step-2"> 
     <div class="edicion_block">
        <div  @click="show.s2=!show.s2" class="navcola manito" style="border:0px !important;">
            <h4>{{$t('style.generales.fondo')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s2" v-show-slide="show.s2">
            <div class="row form_row">
              <div class="col-lg-6">
                  <colorPicker :label="$t('pickers.colorfondo')"  :color="fondo.background" v-model="fondo.background" />
              </div>
              <div class="col-lg-6">
                  <opacityPicker v-model="fondo.opacity"/>
              </div>
              <div class="col-lg-6">
                  <label>{{$t('general.bckimage')}}</label>
                  <switchPicker v-model='fondo.active'/>
              </div>
              <div class="col-lg-6">
                    <label>{{$t('general.fullwidth')}}</label>
                    <switchPicker v-model='fondo.fullwidth'/>
              </div>
            </div>
            <div class="row form_row">
              <div class="col-lg-6">
                  <imagePicker imgid="background" :btext="$t('pickers.loadi')" v-model="fondo.img" />
              </div>
            </div>
           <div v-if="!fondo.fullwidth" class="row form_row mt20">
              <div class="col-lg-6">
                  <switchPicker v-model='fondo.animacion' :label="$t('pickers.movement')"/>
              </div>
                <div class="col-lg-6">
                    <sizePicker v-model="fondo.width" :min='50' :max='1200' :interval="50"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker :label="$t('pickers.hori')" v-model="fondo.left" :min='-200' :max='200' :interval="10" unit="%"/>
                </div>
                <div class="col-lg-6">
                    <sizePicker :label="$t('pickers.verti')" v-model="fondo.top" :min='-100' :max='200' :interval="10" />
                </div>

           </div>
        </div>
    </div> 
    <div class="edicion_block " >
      
        <h4 v-if="$util.isLoyaltyCustomizer()" class="mb10">{{$t('sobre.loyalty')}}</h4>
        <h4 v-else-if="!cardVal.isLanding" class="mb10">{{$t('sobre.loyalty')}}</h4>
        <h4 v-else-if="cardVal.isLanding" class="mb10"></h4>
        
        <span v-if="$util.isLoyaltyCustomizer()">{{$t('sobre.dedi')}}</span>
        <span v-else-if="!cardVal.isLanding" class="mb10">{{$t('sobre.landingsub')}}</span>
        <span v-else-if="cardVal.isLanding" class="mb10">{{$t('sobre.landingsub')}}</span>

        <draggable class="row text-center module_row" 
        v-model="list" 
        handle=".handle"
        :options="dragOptions" 
        :move="onMove" 
        @start="isDragging=true" 
        @end="isDragging=false">
       
          <div  class="module_col"  v-for="element in $util.sobreElements()" :key="element.foto" v-if="element.tipo !== 'beta' || $util.isFounder(getUser) || $util.isTesting()">
                <div :class="$util.isProElementosSobre(getUser,element)?'module_block handle  manito mt15 mb15':'module_block handle manito mt15 mb15'">
                    <div class="module_img" >
                        <img v-if="element.dragIcon" :src="require('@/assets/images/blocks/modulo_'+(element.dragIcon.replace('edit',''))+'.svg')">
                        <img v-else :src="require('@/assets/images/blocks/modulo_'+(element.compo.replace('edit',''))+'.svg')">
                        <h6 v-if="element.dragLabel">{{ element.dragLabel }}</h6>
                        <h6 v-else>{{ $t("drags." + element.compo)  }}</h6>
                    </div>
                    <span v-if="!($util.isLoyaltyCustomizer()) && element.tipo!='freemium'" :class="'label '+element.tipo+' mt5 mb5'" style="text-transform:capitalize">{{element.tipo}} <font-awesome-icon v-if="element.tipo=='pro' && !$util.isTrialOrPro()" :icon="['fas','lock']" /></span>
                </div>
            </div>
      </draggable>
    </div>
</div>
</template>

<script>

import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import animacionPicker from './dinputs/animacionPicker'
import efectPicker from './dinputs/efectPicker'
import opacityPicker from './dinputs/opacityPicker'
import imagePicker from '@/components/imagePicker.vue'
import fontPicker from './dinputs/fontPicker'
import switchPicker from './dinputs/switchPicker'
import draggable from "vuedraggable";

export default {
  props:['profile'],
  data(){
      return{

          show:{s1:true,s2:false },
          getUser:this.profile,
          list:[],
          editable: true,
          cardVal:this.$store.getters.editingLinckard,
          isDragging: false,
          delayedDragging: false
    };
  },
  created:function(){
     this.list= this.$util.sobreElements().map((elemento, index) => {
        return { 
          ...elemento,
          order: index + 1, 
          fixed: false ,
        };
      });
  },
  methods: {
      switchSobreBackground(){
          this.$util.closeAllDivs();
          this.switchCompSobre('leftColSobreA');
      },
      switchCompSobre(comp) {
          this.$bus.$emit("switchCompSobre", comp);
      },
      onMove({ relatedContext, draggedContext }) {
        if(!this.$util.isProElementosSobreModal(this.$modal,this.getUser,draggedContext.element))return false
        
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        );
        
      }
    },
 
  computed: {
      fondo () { return this.$store.getters.editingLinckard.style.sobre.fondo},  
      dragOptions() {
        return {

          group:{ name:'people',  pull:'clone' , put:false},
          animation: 0,
          disabled: !this.editable,
          ghostClass: "ghost",
        };
      },
      
  },
  watch: {
    isDragging(newValue) {

      this.switchSobreBackground();
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
          this.delayedDragging = false;
        });
      this.list= this.$util.sobreElements().map((elemento, index) => {
        return { 
          foto:elemento.foto,
          nombre:elemento.nombre,
          family:elemento.family,
          color:elemento.color,
          size:elemento.size,
          background:elemento.background,
          lineh:elemento.lineh,
          weight:elemento.weight,
          compo:elemento.compo,
          design:elemento.design, 
          tipo:elemento.tipo,
          order: index + 1, 
          fixed: false ,

          desc_family:elemento.desc_family,
          desc_color:elemento.desc_color,
          desc_size:elemento.desc_size,
          desc_lineh:elemento.desc_lineh,
          desc_weight:elemento.desc_weight,

          icon_size:elemento.icon_size,
          icon_color:elemento.icon_color,
          value:elemento.value,
          desc:elemento.desc,
        };
      }
      );
    },
    
  },
  components: {
    'colorPicker':colorPicker,
    'fontPicker':fontPicker,
    'switchPicker':switchPicker,
    'imagePicker':imagePicker,
    'sizePicker':sizePicker,
    'animacionPicker':animacionPicker,
    'efectPicker':efectPicker,
    'opacityPicker':opacityPicker,
    draggable
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.module_img img{
margin-bottom:5px !important;
}
.edicion_block .module_col{
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
</style>
