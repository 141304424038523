
<template>
<client-only>
  <modal name="modal-blocked"
        transition="nice-modal-fade"
        :min-width="200"
        class="dirinit"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-close="beforeClose"
        @before-open="beforeOpen">
   <div class="modal_wrapper " >
        <div  class="card " >
            <div class="card-body text-center">
                <img src="@/assets/images/Icon_Networking.svg" />
                <h3 class="text-center" style="color:#458EDD;">{{text}}</h3>
                <small class="text-center mb20">{{stext}}</small>
            </div>
        </div>
    </div>
  </modal>
</client-only>
</template>
<script>
export default {
    data () {
        return {
            cantClose:true,
            text:this.$t('general.edidesktop'),
            stext:this.$t('general.edidesktopsub'),
        }
    },
    methods:{
        beforeClose(){
            if(this.cantClose)this.$util.gotoHOME();
        },
        beforeOpen (event) {
            if(event && event.params)this.cantClose=event.params.close?true:false
        }
    }
}
</script>
