
<template>
  <modal name="modal-sector"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen" >
        
    <div class="modal_wrapper" style="background-color: #ededed;">
       <div slot="top-right">
            <button @click="$modal.hide('modal-sector')" class="ngdialog-close"></button>
        </div>
        <div class="form-group mt30">
            <div class="input-group">
                <h4 class="input-add-on c_primary mt5 mb-0 mr10" style="font-weight: 300;">{{$t('sectores.nombre')}}</h4>
                <client-only><input class="form-control" v-validate="'required'" name="name"  type="text" maxlength="50" v-model="sector.name" :placeholder="$t('sectores.ej')" :class="{ 'is-invalid': errors.has('name') }" ></client-only>
                <span class="input-group-btn">
                    <button v-if="sector.id==null" class="btn btn-primary btn-initial text-uppercase" style="border-radius: 0;" @click="add()">{{$t('sectores.crear')}}</button>
                    <button v-else class="btn btn-primary btn-initial text-uppercase" style="border-radius: 0;" @click="add()">{{$t('bc.renombrar')}}</button>
                </span>
            </div>
            <client-only><div v-if="errors.has('name')" class="invalid-feedback">{{ errors.first('name') }}</div></client-only>
                
        </div>
    </div>
</modal>
</template>
<script>
export default {
    data () {
      return {
          getUser:this.$store.state.userProfile,
          sector:{
              name:'',
              id:null,
              isLanding:this.$router.history.current.name.includes('landing')
          }
      }
    },
    
    methods: {
        beforeOpen (event) {
            this.sector.name=''
            this.sector.id=null
            if(event.params.sector)this.sector=JSON.parse(JSON.stringify(event.params.sector));   
            if(event.params.type)this.sector.type=event.params.type;   
            console.log(this.sector) 
        },  
        add(){
            var dis=this;
            this.$validator.validateAll().then((result) => {
                if (result){
                    var cloneOfA = JSON.parse(JSON.stringify(dis.sector));
                    dis.$modal.hide('modal-sector')
                    dis.$util.addOrUpdateSector(dis.$modal,cloneOfA);
                }
            }).catch(error => {
                console.error(error)
                
            });
            
        },
        
    }
    
  }
</script>
<style>
  .modal_wrapper {
    padding: 10px;
    font-style: 13px;
    border-radius: 5px;
  }
  .v--modal-overlay[data-modal="size-modal"] {
    background: #ededed;
  }

</style>
