<template>
    <div style="width: 336px; align-items: center;">
        <center>
            <div style="padding: 25px;   border-radius: 20px;" :style="'background-color: ' + element.bgcolor">
                <qr-code 
                :text="element.content ? element.content : 'https://linkcard.app'" 
                :value="element.content ? element.content : 'https://linkcard.app'" 
                size="256"
                :color="element.color" 
                bg-color="rgba(0, 0, 0, 0)" 
                :foreground="element.color" 
                background="rgba(0, 0, 0, 0)"
                class="mt10 mb10 pl20_mobile pr20_mobile qr_eventos" />
            </div>
        </center>
    </div>
</template>
<script>
export default {
    props: ['element'],
    data() {
        return {
            color: { dark: '#000000ff', light: '#ffffffff' },
            type: 'image/png',
        }
    },
    components: {
    },
}
</script>