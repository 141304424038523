
<template>
<modal name="modal-json"
        transition="nice-modal-fade"
        :min-height="450"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="500"
        height="auto"
        @before-open="beforeOpen" >
         
   <div class="modal_wrapper card_share_dropdown account panel_bc " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-json')" class="ngdialog-close"></button>
        </div>
        <div class="container">
        <JsonTree :level="1" :raw="sample"></JsonTree>

        </div>
    </div>
  </modal>
</template>

<script>
import JsonTree from 'vue-json-tree'

export default {
    data(){
        return{
            sample: ''
        }
    },
    methods:{
        beforeOpen (event) {
            var dis=this
            this.sample=event.params.info
        },
    },
    components:{
        JsonTree
    }
}
</script>


<style scoped>
.radiobutton_group {
    padding-top: 10px !important;
}

#firma_wrapper{
    display: flex;
    justify-content: center;
}
@media (max-width: 1800px) {
    #rdFirmas #radio3,#rdFirmas  #radio4{
        width:67px;
    }   
}
@media (max-width: 1600px) {
    #rdFirmas  #radio3,#rdFirmas  #radio4{
        width:57px;
    }   
}
.previewfirma{
    padding:0px
}
.previewfirma > .info_text{
    max-width:80%;
    margin:0 auto;margin-bottom:20px;

}
</style>
