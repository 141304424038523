<template>
 <div id="edicionMenuMid" class="edicion_menu">
  <div id="edicionLeft" class="edicion_container scolable">
      <component :is="leftComp" :profile="getUser" :editcual="editCual" :key="editCual.index"></component>
      <div class="edicion_block  d-sm-none d-md-none"><button type="button" class="btn btn-primary btn-initial text-uppercase mb20" @click='closeAllDivs()' >{{$t('general.guardar')}}</button></div>
  </div>
</div>

  
</template>

<script>


import leftColInfoA from "./leftColInfoA.vue"
import leftColMembreA from "./leftColMembreA.vue"
export default {
  props:['profile'],
  data(){
      return{
          getUser:this.profile,
          editCual:'editEmpty',
          currentComp: this.$store.getters.editingLinckard.loyalty_from?'leftColMembre':'leftColInfo'
            
      }
  },
  created(){
      this.$bus.$on('switchCompLeft', comp => {
              
           this.currentComp = comp;
      });
      this.$bus.$on('switchCompSobre', comp => {
            this.editCual='editEmpty';
            if(comp=='leftColSobreA')this.$store.state.infoMode='content'
            if(comp=='leftColSobreD')this.$store.state.infoMode='design'
            this.$store.state.sobreComp==comp
            this.currentComp='leftColSobre'
      });
      this.$bus.$on('currentCompSobreEditables',comp=>{
            this.editCual=comp;
            this.currentComp='leftColSobreD'
            this.$store.state.sobreComp=='leftColSobreDA'
            this.$store.state.infoMode='content'
            
        });
      this.$bus.$on('switchComp', comp => {
        comp=comp.replace('preview', "leftCol");
        this.currentComp = comp;
      });
  },
  computed:{
    leftComp(){
      if(this.currentComp=='leftColSobreD')return 'leftColSobreD'
      let suffix=this.$store.state.infoMode=='design'?'D':'A'
      let comp=this.currentComp+suffix
      return comp
    },
  },
  methods: {
    closeAllDivs(){

      this.$store.state.sobreComp=='leftColSobreDA'
      this.$store.state.infoMode='content'
      this.currentComp='leftColSobre'
      this.$util.closeAllDivs()

    },
    switchComponentLeft(comp) {
      this.$bus.$emit("switchCompLeft", comp);
      this.currentComp=comp
    },
     menuToggle(){            
       this.$util.toggleDiv("menu_open");
    },
  },

  components:{
        'leftColInfoA': leftColInfoA,
        'leftColMembreA': leftColMembreA
  }

  
};
</script>

<style>

</style>


