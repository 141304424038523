<template>
 <div class="tarjeta_section" >
        <div class="tarjeta_edit_wrapper" style="min-height: 645px;">
        <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
        <div class="tarjeta_foot_wrapper">
         <div class="qr_profile">
            <div class="user_block">
                    <template v-if="$util.isStandAlone() && $util.isIOSSafari()">
                        NO FUNCIONA EN MODO APP PARA IPHONE
                    </template>
                    <template v-if="loading">
                        <font-awesome-icon icon="circle-notch" style="color:#99287B;font-size:50px;" spin />
                    </template>
                    <template v-else-if="laLinc && !result" >
                        <div class="user_photo_wrapper">
                            <img :src="$util.getLincImage(laLinc)" class="w-100">
                        </div>
                        <h6 class="user_name">{{laLinc.info.nombre}}</h6>  
                        <span class="user_prof">{{laLinc.info.trabajo}}</span>
                    <button v-if="isQrScannerEvent()" class="btn btn-primary btn-initial text-uppercase" @click="confirm()">{{$t('qr.conf')}} </button>
                    <button v-else-if="!(laLinc.loyalty_id>=0)" class="btn btn-primary btn-initial text-uppercase" @click="confirm()">{{$t('qr.addto')}} </button>
                    </template>
                    <template v-else-if="result">
                        <h6 class="user_name">{{result}}</h6>
                    <button class="btn btn-primary btn-initial text-uppercase" @click="reset()">{{$t('qr.reini')}}  </button>
                    </template>
                    <template v-else>
                        <h6 class="user_name">{{$t('qr.acerc')}}</h6>
                    </template>
            </div>
        </div>
        </div>
       

    </div>
</div><!-- /Seccion "QR" -->
</template>



<script>

export default {
    props:['preview','diruser'],
    data () {
        return {
            lincFrom:this.preview,
            result: '',
            error: '',
            dirUser:this.diruser,
            loading:false,
            laLinc:null,
        }
    },

    methods: {
        isQrScannerEvent(){
            return (this.$router.history.current.name.includes('event') &&
            this.$router.history.current.name.includes('qr') &&
            this.laLinc.event_id>=0)
        },
        reset(){
            this.result= ''
            this.error= ''

            this.loading=false
            this.laLinc=null
        },
        async confirm(){
            var dis=this
            dis.result=''
            dis.loading=true

            if(!this.$util.isLincFav(dis.dirUser,dis.getUser.uid,dis.laLinc.id))
                //await this.$util.saveFav(dis.dirUser,dis.$util.contactObj(dis.getUser,dis.laLinc.id),dis.laLinc.id)

            
            if(!this.$util.isLincFav(dis.getUser,dis.dirUser.uid,dis.lincFrom.id))
                //this.$util.saveFav(dis.getUser,dis.$util.contactObj(dis.dirUser,dis.lincFrom.id),dis.lincFrom.id)

            dis.result='Listo!'
            dis.loading=false
            
            
        },
        async onDecode (result) {
            var dis=this
            this.loading=true
            if(result.indexOf("https://linkcard.app") > -1 || result.indexOf("http://localhost") > -1) {
                let parts=result.split("/")
                let username=parts[3]
                let elid=parts[4]
                if(!this.getUser)this.getUser=await this.$util.dbgetUserByUsername(username)
                if(!this.getUser){
                    this.loading=false
                    this.result=this.$t('qr.err_noex')
                }else if(this.getUser.username==this.dirUser.username){
                    this.loading=false
                    this.result=this.$t('qr.cantyou')
                }else{
                    let index=this.getUser.linckards.findIndex(function(i){return i.id == elid;}) 
                    this.laLinc=this.getUser.linckards[index]
                    if(this.$util.isLincFav(dis.dirUser,dis.getUser.uid,dis.laLinc.id)){
                        this.loading=false
                        this.result=this.$t('qr.already')
                    }else{
                        this.loading=false
                    }
                }
            }else{
                this.loading=false
                this.result=this.$t('qr.err_est')
            }
        },

        async onInit (promise) {
            var dis=this
            dis.loading=true
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: Stream API is not supported in this browser"
                }
            }finally {
                dis.loading=false
            }
        }
  }
};
</script>