
<template>
  <modal name="modal-videotour"
        transition="nice-modal-fade"
        class="limitededition"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="500"
        height="auto"
        @before-open="beforeOpen" >
   <div class="modal_wrapper videotour " >
       <div slot="top-right"><button @click="$modal.hide('modal-videotour')" class="ngdialog-close"></button></div>
       
        <div v-if="type=='tour'" class="card">
            <div class="card-body text-center" style="">
                <div class="pvw_video mt20 mb20"><iframe :src="'https://www.youtube.com/embed/'+$util.getVideoTourID()+'?rel=0&amp;fs=0&amp;showinfo=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>
                <h3 class="text-center  mt20" style="font-weight:400;margin-bottom:0px;">{{$t('tour.take')}}</h3>
                <h3 class="text-center mb20 " style="font-weight:400;margin-top:0px;"><b>{{$t('tour.take1')}}</b></h3>
                <p class="text-center mb20">{{$t('tour.take2')}} <a target="_blank" :href="$util.getURL_Blog()">blog</a></p>
                <button v-if="!$util.isMobile()" @click="cerrar()" class="btn btn-primary btn-initial " type="button" >{{$t('tour.start')}}</button> 
                <button v-else @click="$modal.hide('modal-videotour')" class="btn btn-primary btn-initial " type="button" >{{$t('general.siguiente')}}</button> 
            </div>
        </div>
        <div v-else class="card">
            <div class="card-body text-center" style="">
                <div class="pvw_video mt10 mb20"><iframe :src="'https://www.youtube.com/embed/'+$util.getSignatureVideoID()+'?rel=0&amp;fs=0&amp;showinfo=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>
                <h3 class="text-center  mt20 mb20" style="font-weight:400;margin-bottom:0px;">{{$t('tutomodals.signatures.tit')}}</h3>
                <p class="text-center mb20">{{$t('tutomodals.signatures.subtit')}}</p>
                <button @click="$modal.hide('modal-videotour')" class="btn btn-primary btn-initial " type="button" >{{$t('tutomodals.signatures.buton')}}</button> 
            </div>
        </div>
    </div>
  </modal>
</template>
<script>
export default {
    data () {
        return {
            type:'tour'
        }
    },
    methods:{
        beforeOpen (event) {
            if(event && event.params)this.type=event.params.type
        },
        cerrar(){
            this.$tours['myTour'].start()
            this.$modal.hide('modal-videotour')
        }
    }
}
</script>


<style scoped>
@media (max-width: 799px) {
    h3{
        font-size: 22px;;
    }
    p{
        font-size:12px;
    }
}
</style>