<template> 
<div class="colapsables">
     <div class="edicion_block">
        <div  @click="show.s2=!show.s2" class="navcola manito" style="border-top:0px !important;">
        <h4>{{$t('style.comparte.formato')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s2" v-show-slide="show.s2">
            <div class="row text-center ">
                <div class="col-sm-12 rb_col mb15">
                <ul class="list_check ">
                    <li>
                        <span class="label_wicon">
                            <b>{{$t('style.comparte.mono')}}</b>
                        </span>
                        <label class="switch">
                            <input type="checkbox" v-model="preview.monocromo">
                            <span class="slider round"></span>
                        </label>
                    </li>
                </ul>
                </div>
                <div class="col-sm-6 rb_col mb15">
                    <div class="radiobutton_group">
                        <p-radio class="p-default p-curve" name="radio-group" v-bind:value="1" v-model="layout" ></p-radio>
                        <label for="radio01" ></label>
                    </div>
                    <img src="@/assets/images/tipoboton01.svg">
                </div>

                <div class="col-sm-6 rb_col mb15 border_sm_left">
                    <div class="radiobutton_group">
                        <p-radio class="p-default p-curve" name="radio-group" v-bind:value="2" v-model="layout" ></p-radio>
                        <label for="radio02" ></label>
                    </div>
                    <img src="@/assets/images/tipoboton02.svg">
                </div>
            </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s3=!show.s3" class="navcola manito" >
             <h4>{{$t('style.generales.fondo')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s3?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s3" v-show-slide="show.s3">
            <div class="row form_row">
                <div class="col-12">
                    <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.fondo.background"  v-model="preview.fondo.background" />
                </div>
            </div>
        </div>
    </div>


     <div class="edicion_block">
        <div  @click="show.s4=!show.s4" class="navcola manito" >
            <h4>{{$t('style.comparte.tit')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s4?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s4" v-show-slide="show.s4">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker v-model="preview.tit_compartir.family"/>
                </div>
            </div>
            <div class="row form_row">
                <div class="col-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''" v-model="preview.tit_compartir.weight" />
                </div>
                <div class="col-lg-6">
                    <sizePicker   v-model="preview.tit_compartir.size"/>
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-12">
                    <colorPicker :color="preview.tit_compartir.color"  v-model="preview.tit_compartir.color" />
                </div>
            </div>
        </div>
    </div>
     <div class="edicion_block">
        <div  @click="show.s5=!show.s5" class="navcola manito" >
            <h4>{{$t('style.comparte.sec')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s5?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s5" v-show-slide="show.s5">
        <div class="row form_row">
            <div class="col-12">
                <fontPicker v-model="preview.tit_secundario.family"/>
            </div>
        </div>
        <div class="row form_row">
            <div class="col-lg-12">
                <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"   v-model="preview.tit_secundario.weight" />
            </div>
            

        </div>

        <div class="row form_row">

            <div class="col-lg-6">
                <sizePicker  :label="$t('pickers.inter')"  v-model="preview.tit_secundario.lineh"/>
            </div>
            <div class="col-lg-6">
                <sizePicker   v-model="preview.tit_secundario.size"/>
            </div>


            <div class="col-lg-12">
                <colorPicker :color="preview.tit_secundario.color"  v-model="preview.tit_secundario.color" />
            </div>
        </div>
        </div>
    </div>

     <div class="edicion_block">
        <div  @click="show.s6=!show.s6" class="navcola manito" >
            <h4> {{$t('style.generales.botones')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s6?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s6" v-show-slide="show.s6">
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker v-model="preview.botones.family"/>
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-6">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"   v-model="preview.botones.weight" />
                </div>
                <div class="col-lg-6">
                    <sizePicker  v-model="preview.botones.size"/>
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-12">
                    <colorPicker :color="preview.botones.color"  v-model="preview.botones.color" />
                </div>
                <div class="col-lg-12">{{preview.botones.background}}
                    <colorPicker  :label="$t('pickers.colorfondo')"  :color="preview.botones.background"  v-model="preview.botones.background" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import colorPicker from './dinputs/colorPicker'
import sizePicker from './dinputs/sizePicker'
import animacionPicker from './dinputs/animacionPicker'
import fontPicker from './dinputs/fontPicker'

export default {
    data(){
        return{
            show:{
                s1:false,s2:false,s3:false,s4:false,s5:false,s6:false,s7:false,s8:false,
            },
            layout:1,
            activeLinckard:this.$store.getters.editingLinckard
        }
    },
    created: function(){
        this.layout=this.preview.layout;
    },
    computed: {
        preview () { return this.activeLinckard.style.compartir},
        state () { return this.activeLinckard.compartir},
    },
    
    components: {
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'animacionPicker':animacionPicker,
        'fontPicker':fontPicker
    },
    watch:{
        layout(val){
            const el = document.body.classList;
            if(val==1)el.remove("view_circular_buttons");
            else el.add("view_circular_buttons");
            this.preview.layout=val;
      
        }
    }
}


</script>
