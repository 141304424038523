<template>
    <div class="mb-3">
        <label style="display:block;">{{ label }}</label>
        <div class="row mr-0 ml-0">
            <vs-input type="date" v-model="inputVal.date" />
            <vs-input type="time" v-model="inputVal.time" />
        </div>
    </div>
</template>

<script>
export default {
	props: ['value','label'],
	data() {
		return { 
			options:this.$store.state.gfonts,
			inputVal: this.value ? this.value : {}
		}
	},
	methods:{
		myFunc(val){
			this.$emit('input', val.family);
		}
	},
	watch: {
		inputVal(val) {
			this.$emit('input', val);
		}
	},
}
</script>