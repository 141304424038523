<template>
    <div id="LeftNav">
        <div class="left_nav custom_scrollbar main_menu ">
            <div class="nav_wrapper">
                <navigation :user="getUser" />
                <!-- Logout -->
                <div class="nav_foot">
                    <slider src="https://linkcard.app/linkcard/banner-menu-principal" />
                </div>
            </div>
        </div>
        <bottomNav v-if="!hidebottom" />

    </div>
</template>

<script>
import { Icon } from "@iconify/vue2"
import bottomNav from "@/components/bottomNav.vue"
import navigation from "@/components/menu/navigation.vue"
import slider from "@/components/external/slider.vue"

export default {
    props: ['hidebottom'],
    data() {
        return {
            activeTab: this.$util.isBCDash(),
            getUser: this.$store.state.userProfile,
            plan: this.$store.state.userProfile.stripe_details.stripe_subscriptions[0].plan_id.substring(0, 4) === 'free'
        }
    },
    mounted() {
        if (this.$store.state.mode == null) {
            if (this.$util.isBCDash()) this.activeTab = 0;
            else if (this.$util.isLDDash()) this.activeTab = 1
            else if (this.$router.history.current.name.includes('leads')) this.activeTab = 2
            else this.activeTab = 3
        }
        this.$gtag.set("user_properties", {
            founder: this.$util.isFounder(this.$store.state.userProfile),
            pro: this.$util.isPro(this.$store.state.userProfile),
            enterprise: this.$util.isEnterprise(this.$store.state.userProfile),
        });
        this.$gtag.event('set_user_properties', {
            founder: this.$util.isFounder(this.$store.state.userProfile),
            pro: this.$util.isPro(this.$store.state.userProfile),
            enterprise: this.$util.isEnterprise(this.$store.state.userProfile),
        })
    },
    methods: {
        openLanding() {
            window.open(this.$util.landingURL(), '_blank')
        },
        iralhome(mode) {
            this.$store.state.mode = mode;
            this.$router.push(this.$util.DASHHOME_url(mode))
        },
        enable_Route(route) {
            if (this.$util.isTrialOrPro(this.$modal, route)) this.toggleRoute(route)
        },
        toggleRoute(route) {
            this.$router.push(route)
            this.$util.closeAllDivs()
        },
        getLincImage_error(event) {
            console.log(event)
            event.target.src = this.$util.getLincImage(this.preview, null)
        },
        salir() {
            var dis = this;
            if (this.$store.getters.hasBeenEdited) {
                this.$modal.show('dialog', {
                    title: dis.$t('notis.atencion'), text: dis.$t('notis.seguroque'),
                    buttons: [
                        { title: dis.$t('notis.si'), handler: () => { dis.logout() } },
                        { title: dis.$t('general.no') }
                    ]
                })
            } else this.logout()
        },
        logout() {
            this.$util.logout()
        },
        getImage(size) {
            var img = this.getUser ? this.getUser.account_details.account_picture : this.$util.getImage(this.getUser)
            if (!img) return require("@/assets/images/profile_nophoto_color.png");
            else if (img && img.obj) return img.obj;
            else if (img) return this.$util.removeToken(img, size)
        },
    },
    computed: {
    },
    components: {
        bottomNav,
        Icon,
        navigation,
        slider
    }
};

</script>

<style scoped>
.panel .left_nav .primary_menu .leftsinbor>li>a {
    border: 0px !important;
}

.panel .left_nav .social_list a {
    font-size: 16px;
    margin: 0 10px;
}

.panel .left_nav {
    z-index: 4000 !important;
}

@media (max-width: 799px) {
    .panel .left_nav .secondary_menu>li>a {
        font-size: 14px !important;
        padding: 2px !important;
    }
}

.usr_btn.active {
    background: #828282;
    border-color: #828282 !important;
    color: white !important;
}

a.open {
    color: grey !important;
}

li.active a {
    color: black !important;
    font-weight: 700 !important;
}
</style>

