<template>
    <li :class="{ active: $router.history.current.path === route }"><a @click="toggleRoute()">
            <Icon :icon="icon" style="font-size: 18px;" />
            {{ text }}
        </a></li>
</template>
<script>
export default {
    props: ['icon', 'text', 'open', 'route'],
    methods: {
        toggleRoute() {
            this.$router.push(this.route)
            this.$util.closeAllDivs()
        },
    }
}
</script>

<style scoped>
li.active a {
    color: black !important;
    font-weight: 700 !important;
}
</style>