import Vue from 'vue'
import VueGAPI from 'vue-gapi'

const apiConfig = {
  apiKey: 'AIzaSyBQ8JVqQQorNrEn19NlwbcoV9E-leDoXcg',
  clientId: '963157049746-a16uv38lcnrerpruel4jf97dmtmt4dmj.apps.googleusercontent.com',
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest","https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  scope: 'https://www.googleapis.com/auth/admin.directory.user',
  refreshToken: true,
}

Vue.use(VueGAPI, apiConfig)