<template>
 <div id="edicionMenuTop" class="edicion_menu ">
      <nav class="edicion_top_nav" style="padding-top:8px;">
          <div class="d-flex justify-content-between">
                <ul v-if="editor" class="etn_menu" >
                    <li @click="$bus.$emit('toggleMobileEditor',false)" style="width:100% !important;text-align:right;float:right;"> <span style="margin-right:10px;text-align:right;">{{$t('filtros.ocultar')}}</span> <font-awesome-icon :icon="['fas','chevron-down']" /></li>
                </ul>
                <ul v-else class="etn_menu " style="width:100%;">
                    <li @click="$bus.$emit('toggleMobileEditor',true)" style="width:100% !important;text-align:right;"> <span style="margin-right:10px;text-align:right;">{{$t('filtros.mostrar')}}</span> <font-awesome-icon :icon="['fas','chevron-up']" /></li>
                </ul>
          </div>
      </nav>
</div>
</template>

<script>

export default {
    data() {
        return {
            editor:true,
            currentCompDesign:this.$store.state.sobreComp=='leftColSobreD'?'leftColDC':'leftColDT'
        }
    },
    created(){

        this.$bus.$on('toggleMobileEditor', state => {
            this.editor=state
        });

    },
    methods:{
        switchComponentRightDesign(comp) {
            this.currentCompDesign=comp
            this.$bus.$emit('switchComponentRightDesign', comp)
        },
    },    
}

</script>


<style scoped>
.edicion_top_nav{
    padding-top:0px !important;
}
.edicion_top_nav .etn_menu > li{
    border:0px;
}
.etn_menu .active .etn_hide,
.etn_menu .active a .svg{
    color: white !important;
}
</style>