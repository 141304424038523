
<template>
  <modal name="modal-category"
        transition="nice-modal-fade"
        :min-width="200"
        :min-height="200"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="400"
        height="auto"
        @before-open="beforeOpen">
   <div class="modal_wrapper " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-category')" class="ngdialog-close"></button>
        </div>
        <form class="card mb20" novalidate @submit.prevent="validateForm()">
            <div class="card-body">
                <h4  style="color:#438FDE;font-size: 28px;text-align:center; margin: 0 0 0px;display:block !important;">Settings</h4>
               
                <div class="row form-row mt20">
                    <select class="form-control col-6 dark"  v-model="category">
                        <option value='all'>{{$t('filtros.select')}}</option>
                        <template v-for="item in categorias" >
                            <option :key="item" :value='item'>{{item}}</option>
                        </template>
                    </select>
                </div>
                <div class="row form-row mt20">
                    <div class="form-group ">
                        <label>Portada</label>
                        <imagePicker imgid="background" :btext="$t('pickers.loadi')" v-model="img" />
                    </div>
                </div>
                <div class="row form-row">
                    <div class="form-group ">
                        <label>Premium</label>
                        <switchPicker v-model="premium" />
                    </div>
                </div>
                <div class="form-group mt20" style="margin-bottom:0px;width:100% !important;display:block;">
                    <button-spinner class="btn btn-initial btn-primary text-uppercase  w-100" 
                    type="submit" 
                    :is-loading="loading"
                    :disabled="loading"
                    :status="status"> SAVE 
                    </button-spinner>
                </div>

            </div>
        </form>
      
        
    </div>

</modal>
</template>
<script>

import imagePicker from '@/components/imagePicker.vue'
import {db } from '@/services/firebaseConfig.js';
import switchPicker from '@/layouts/main_Customizer/dinputs/switchPicker.vue';

export default {
    data () {
      return {
            getUser:this.$store.state.userProfile,
            loading:false,
            status:'',
            premium:false,
            category:'all',
            categorias:this.$util.getCatesTemplates(),
            templateVal:null,
            img:''
      }
    },
    mounted(){
        
    },
    methods: {
        beforeOpen (event) {
            this.status=''
            this.templateVal=(event.params.linkcard)
            this.category=this.templateVal.category?this.templateVal.category:''
            this.premium=this.templateVal.premium==undefined?false:this.templateVal.premium
            this.img=this.templateVal.img?this.templateVal.img:''
        },
        validateForm(){
            var dis=this;
            dis.loading = true
            dis.status = true
            
            this.$validator.validateAll()
            .then((result) => {
                if(result)dis.saveCategory()
                else{
                    dis.loading = false
                    dis.status = false
                    setTimeout(() => { dis.status = '' }, 1000)
                }
            }).catch(error => {
                dis.loading = false
                dis.status = false
                setTimeout(() => { dis.status = '' }, 1000)
                
            });
        },
        async saveCategory(){
            var dis=this;
            dis.loading=true
            this.templateVal.category = this.category
            this.templateVal.premium = this.premium
            this.templateVal.img = this.$util.isImgUploaded(this.img)?await this.$util.getImageUrl(this.img):this.img
            await db.collection("templates").doc(this.templateVal.id+'').update(dis.templateVal)
            .then(function() {
                dis.$util.notif_success(null)
                dis.loading=false
                dis.$modal.hide('modal-category')
            });
            
        },
        
    },
    components:{ 
        switchPicker,
        imagePicker
    }
    
  }
</script>
<style>
.modal_wrapper {
padding: 10px;
font-style: 13px;
border-radius: 5px;
}
.v--modal-overlay[data-modal="size-modal"] {
background: #ededed;
}
</style>
