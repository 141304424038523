import Vue from 'vue'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
    defaultCountryCode: 'AR', // e.g. 'CA'
    defaultLanguage:    'es', // e.g. 'en'
    defaultMode:        'lat-lng', // or 'lat-lng'
    googleMapsApiKey:   'AIzaSyDhyuzW32WIEYtnmA3vdiGfTjQjZ_DyGlU'
});