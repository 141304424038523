<template>
   <div  class="edicion_block">
        <div  @click="show.s1=!show.s1" class="navcola manito" style="border-top:0px !important;">
            <h4>{{$t('bc.content')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s1" v-show-slide="show.s1">
            <div class="row form_row">
                <div class="col-12">
                    <div class="form-group">
                        <textarea maxlength="400"  rows="7" type="text" :placeholder="$t('general.entervalue')" v-model="valor.value"  style="width:100% !important;border:0px;overflow:hidden;" ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div  @click="show.s2=!show.s2" class="navcola manito" style="border-top:0px !important;">
            <h4>{{$t('bc.design')}}</h4>
            <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
        </div>
        <div :class="'contenido '+show.s2" v-show-slide="show.s2">
            <div class="row form_row">
                <div class="col-12">
                    <sizePicker :min="400" :max="900" :interval="100" :label="$t('pickers.weight')" :unit="''"  v-model="valor.weight" />
                </div>
            </div>

            <div class="row form_row">

                <div class="col-lg-6">
                    <sizePicker   v-model="valor.size"/>
            
                </div>
                <div class="col-lg-6">

                    <colorPicker :color="valor.color"   v-model="valor.color" />
                
                </div>
            </div>
            <div class="row form_row">
                <div class="col-lg-6">
                    <sizePicker :label="$t('pickers.inter')" v-model="valor.lineh"/>
        
                </div>
            </div>
            <div class="row form_row">
                <div class="col-12">
                    <fontPicker v-model="valor.family"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>





import colorPicker from '../dinputs/colorPicker'
import sizePicker from '../dinputs/sizePicker'
import fontPicker from '../dinputs/fontPicker'

export default {
	props: ['value'],
	data() {
		return { 
            show:{s1:true,s2:false },
            valor:this.value,
		}
    },  
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'fontPicker':fontPicker
    }
}
</script>

