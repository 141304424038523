<template>
<div>
    <div class="edicion_block">
        <h4 class="mb10">{{$t('firmas.editor_s1')}}</h4>
        <small class="info_text_sm mb30">{{$t('firmas.editor_s2')}}</small>
        <div  :class="{active: currentComp === 'leftCol_SC_G' }" @click="switchEmailConf('G')" class="form-group">
            <button class="btn btn-card btn-initial mr5 w-100"><img src="@/assets/images/icon_google_color.svg"><span>{{$t('firmas.editor_s3')}} G Suite</span></button>
        </div>
        <div  :class="{active: currentComp === 'leftCol_SC_E' }" @click="switchEmailConf('E')" class="form-group">
            <button class="btn btn-card btn-initial mr5 w-100"><img src="@/assets/images/icon_email_gray.svg"><span>{{$t('firmas.act1_n')}}</span></button>
        </div>
        <!--
        <div  :class="{active: currentComp === 'leftCol_SC_O' }" @click="switchEmailConf('O')" class="form-group">
            <button class="btn btn-card btn-initial mr5 w-100"><img src="@/assets/images/icon_outlook_color.svg"><span>{{$t('firmas.editor_s3')}} Outlook</span></button>
        </div>-->
    </div>
</div>  
</template>



<script>
export default {
  data(){
      return{
          currentComp:'leftCol_SC_G',
          getUser:this.$store.state.userProfile,
            
      }
  },
  methods:{
      switchEmailConf(type){
          this.currentComp='leftCol_SC_'+type
          this.$bus.$emit('switchEmailConf',this.currentComp)
      }
  }
};
</script>

<style scoped>
.form-group.active button{
    background: #686868 !important;
    color:white;

}
</style>
