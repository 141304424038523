
<template>
<modal name="modal-widget"
        transition="nice-modal-fade"
        :min-height="500"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        width="500"
        height="auto"
        @before-open="beforeOpen" >
         
   <div class="modal_wrapper card_share_dropdown account panel_bc " >
       <div slot="top-right">
            <button @click="$modal.hide('modal-widget')" class="ngdialog-close"></button>
        </div>
        <div class="card ">
                    <div class="card-body" style="padding-left: 0; padding-right: 0;">
                        <div class="card_section">
                            <div class="row form_row d-flex">
                                <div class="col-12">
                                <div class="card_section_head pl20 pr20">
                                    <h4 class="mb10">{{$t('bc.insertin')}}</h4>
                                </div>
                                <p class="pl20 pr20 mb20 d_block t_11px">{{$t('bc.insertinsub')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card_section mt10">
                            <div class="row row_sm">
                                <div class="col-md-12">
                                    <div class="ws_wrapper pl20 pr20">
                                        <div class="d_flex" style="justify-content:around;">
                                            <div class="edicion_block" width="20px"><colorPicker  :color="colorWidget"  v-model="colorWidget" /></div>
                                            <label class="mb10 label_special d_inlineb ml-0 ml-auto" style="margin-right:0px;"><font-awesome-icon :icon="['fas','code']"/> {{$t('loyaltys.insert')}}</label>
                                          
                                        </div>
                                        <textarea id="copiarcode" 
                                        v-model="getiframe" 
                                        class="custom_scrollbar mt10" 
                                        style="height: 90px;
                                        border:0px !important;
                                        width:100% !important;
                                        color:grey !important;
                                        display: inline-block;
                                        white-space: normal;
                                        color: rgb(73, 80, 87);
                                        font-size: 12px;
                                        line-height: 1.5;
                                        padding: 10px 15px;
                                        border-radius: 5px;
                                        background-color: rgb(237, 237, 237);">
                                        </textarea>
                                    </div>
                                    <div class="btn_container text-left pl20 pr20 mt10">
                                        <button @click="doCopyCode"  class="btn btn-primary btn-sm"> <img src="@/assets/images/icon_duplicate_white.svg" width="16" style="position: relative;top: -2px;"> {{$t('loyaltys.copy')}}</button>
                                    </div>
                                </div>
                                <div class="mt20 col-md-12">
                                    <div class="ws_wrapper pl20 pr20">
                                        <div class="d_flex">
                                            <label class="mb10 label_special d_inlineb ml-0 mr-auto"><font-awesome-icon  :icon="['fas','eye']" /> {{$t('firma.preview')}}</label>
                                            
                                        </div>
                                        <div class="pv_container mb20">
                                            <div class="pv_header"><span class="bullet"></span><span class="bullet"></span><span class="bullet"></span></div>
                                            <div class="pv_body">
                                                <!-- ngInclude: 'app/panel/websiteWidget.html'-->
                                                <div class="wsw_wrapper wsw_open ">
                                                    <button class="wsw_button" :style="'background:'+colorWidget">
                                                    <img src="@/assets/images/iso_white.svg">
                                                    </button>
                                                    <div class="wsw_card" :style="'background:'+colorWidget">
                                                        <div class="wsw_card_header">
                                                            <h4>{{$t('bc.abre')}}</h4>
                                                            <button class="wsw_close"><img src="@/assets/images/icon_close.svg"></button>
                                                        </div>
                                                        <div class="wsw_card_body">
                                                            <div class="wsw_lc_wrapper">
                                                            <div class="wsw_lc_container">
                                                                <div class="wsw_lc_inner_wrapper">
                                                                    <div class="wsw_lc_avatar_wrapper">
                                                                        <div class="wsw_lc_avatar">
                                                                        <img :src="cardVal.info.img" width="100%">
                                                                        </div>
                                                                    </div>
                                                                    <div class="wsw_lc_user_info">
                                                                        <h6 class="wsw_lc_user_name">{{cardVal.info.nombre}}</h6>
                                                                        <span class="wsw_lc_user_prof">{{cardVal.info.trabajo}}</span>
                                                                        <a href="#" class="wsw_lc_url" :style="'background:'+colorWidget">{{$t('bc.view')}}</a>
                                                                    </div>
                                                                    <div class="wsw_lc_buttons">
                                                                        <button class="wsw_lc_btn_top wsw_lc_btn_linkcard"><img src="@/assets/images/button_linkcard.svg"></button>
                                                                        <button class="wsw_lc_btn_bottom"><i class="fas fa-info-circle"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  </modal>
</template>




<script>
import colorPicker from '@/layouts/main_Customizer/dinputs/colorPicker'
import sizePicker from '@/layouts/main_Customizer/dinputs/sizePicker'
import imagePicker from '@/components/imagePicker'
export default {
    data(){
        return{
            getUser:this.$store.state.userProfile,
            edit:true,
            colorWidget:'#448FDE',
            clayouts:[
                {id:1},
                {id:2},  
            ],
            layout:1,
            cardVal:{
                info:{},
                style:{
                    firma_new:{}
                }
            }
        }
    },
    methods:{
        beforeOpen (event) {
            this.cardVal=JSON.parse(JSON.stringify(event.params.linkcard))
        },
        doCopyCode() {
            if(process.client){
                let textarea = document.getElementById("copiarcode");
                textarea.select();
                document.execCommand("copy");
                this.$util.notif_success(this.$t('firma.docopy'))
            }
        },
    },

    computed: {
        getiframe(){
            return '<script type="text/javascript"> wTitle = "'+this.cardVal.info.nombre+'"; wJob = "'+this.cardVal.info.trabajo+'";wImage = "'+this.cardVal.info.img+'"; wColorMain = "'+this.colorWidget+'";wURL= "'+this.$util.cardURL()+this.getUser.username+'/'+this.cardVal.id+'"; document.write(\'<div id=\"linkcardWidget\"></div>\');</scr'+''+'ipt><script type="text/javaScript" src="https://my.linkcard.app/linkcard-widget.js"></scr'+''+'ipt>';
        
        },
    },
    components:{
        'colorPicker':colorPicker,
        'sizePicker':sizePicker,
        'imagePicker':imagePicker
    }
}
</script>

<style scoped>

/* Widget for your website Abril 2020 */

.ws_wrapper {
	display: inline-block;
	width: 100%;
}

.ws_wrapper .label_special {
	color: #666;
	display: table;
	margin: auto;
}

.ws_wrapper pre {
	background-color: #fff;
	border: solid 1px #ddd;
}

.pv_container {
	background: #fff;
	border: solid 1px #ddd;
	border-radius: 8px;
	display: inline-block;
	width: 100%;
}

.pv_container .pv_header {
	padding: 10px;
	line-height: 0;
	border-bottom: solid 1px #ddd;
}

.pv_container .pv_header .bullet {
	width: 10px;
	height: 10px;
	background-color: #ddd;
	display: inline-block;
	border-radius: 50%;
	margin-right: 10px;
}

.pv_container .pv_body {
	position: relative;
	overflow: hidden;
}

.wsw_wrapper {
	font-size: 14px;
	position: absolute;
	bottom: 3.57em;
	right: 15px;
	z-index: 3000;
}

.pv_container .wsw_wrapper {
	font-size: 12px;
	right: 15px;
}

.wsw_wrapper a,
.wsw_wrapper button {
	transition: all 0.4s !important;
    -webkit-transition: all 0.4s !important;
    -o-transition: all 0.4s !important;
	-moz-transition: all 0.4s !important;
	text-decoration: none !important;
}

.wsw_wrapper .wsw_button {
	position: relative;
	z-index: 2;
	border: solid 2px #fff;
	border-radius: 50%;
	width: 50px /*3.57em*/;
	height: 50px /*3.57em*/;
	/*-webkit-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15) !important;
    -moz-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15) !important;
	box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15) !important;*/
	cursor: pointer;
	text-align: center;
}

.pv_container .wsw_wrapper .wsw_button {
	width: 38px /*3.21em*/;
	height: 38px /*3.21em*/;
	pointer-events: none;
	animation: clickBounce 5000ms infinite ease !important;
}

@keyframes clickBounce {
	/*2.5% {
		width: 3.05em;
		height: 3.05em;
	}
	5% {
		width: 3.21em;
		height: 3.21em;
	}
	50% {
		width: 3.21em;
		height: 3.21em;
	}
	52.5% {
		width: 3.05em;
		height: 3.05em;
	}
	55% {
		width: 3.21em;
		height: 3.21em;
	}*/

	10% {
		transform: rotate(360deg);
	}
	50% {
		transform: rotate(360deg);
	}
	60% {
		transform: rotate(0deg);
	}



}

.wsw_wrapper .wsw_button:hover {
	background: #0286d4 !important;
}

.wsw_wrapper .wsw_button img {
	width: 70%;
    line-height: 50px;
}

.wsw_card {
	border-radius: 1.07em;
	min-width: 320px;
	position: absolute;
	top: 50%;
	right: 100%;
	transform: translateY(-50%);
	margin-right: .71em;
	transition: all 0.4s !important;
    -webkit-transition: all 0.4s !important;
    -o-transition: all 0.4s !important;
	-moz-transition: all 0.4s !important;
}



.wsw_wrapper.wsw_open .wsw_card {
	animation: wsw_card_In 1000ms ease;
}

.wsw_wrapper.wsw_close .wsw_card {
	animation: wsw_card_Out 1000ms ease;
}

.wsw_wrapper.wsw_open .wsw_button {
	animation: wsw_button_In 400ms ease;
}

.wsw_wrapper.wsw_close .wsw_button {
	animation: wsw_button_Out 400ms ease;
}

.wsw_wrapper.wsw_novisible .wsw_card {
	display: none;
}


@keyframes wsw_card_In {
	from {
		margin-right: -.71em;
		opacity: 0;
    }

	to {
		margin-right: .71em;
		opacity: 1;
	}
}

@keyframes wsw_card_Out {
	from {
		margin-right: .71em;
		opacity: 1;
    }

	to {
		margin-right: -.71em;
		opacity: 0;
	}
}

@keyframes wsw_button_In {
	from {
		transform: rotate(0deg);
    }

	to {
		transform: rotate(360deg);
	}
}

@keyframes wsw_button_Out {
	from {
		transform: rotate(360deg);
    }

	to {
		transform: rotate(0deg);
	}
}

.pv_container .wsw_wrapper .wsw_card {
	min-width: initial;
	animation: fadeInOut 5000ms infinite ease;
}

@keyframes fadeInOut {
    0% {
		margin-right: -.71em;
		opacity: 0;
    }

    25% {
		margin-right: .71em;
		opacity: 1;
	}
		
	50% {
		margin-right: .71em;
		opacity: 1;
	}
		
	75% {
		margin-right: -.71em;
		opacity: 0;
	}
		
	100% {
		margin-right: -.71em;
		opacity: 0;
    }
}



.pv_container .wsw_card a,
.pv_container .wsw_card button {
	pointer-events: none;
}

.wsw_card .wsw_card_header {
	position: relative;
	padding: .71em;
}

.wsw_card .wsw_card_header h4 {
	color: #fff;
    text-transform: uppercase;
    margin: 0;
    font-size: .92em;
	font-weight: 700;
	line-height: 1;
}

.wsw_card .wsw_card_header .wsw_close {
	background: transparent;
	border: none;
	padding: 0;
	position: absolute;
	top: .53em;
	right: .71em;
	cursor: pointer;
}

.wsw_card .wsw_card_header .wsw_close:hover {
	opacity: .6;
}

.wsw_card .wsw_card_header .wsw_close img {
	width: 1em;
}

.wsw_card .wsw_card_body {
	padding: 0 .35em .35em;
}

.wsw_card .wsw_card_body .wsw_lc_wrapper {
	max-width: initial;
	border: none;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    height: auto;
    border-radius: .71em;
    background-color: #fff;
    padding: 0px;
    display: flex;
    width: 100%;
    margin: auto;
    position: relative;
}

.wsw_card .wsw_card_body .wsw_lc_container {
	position: relative;
    width: 100%;
	display: block;
	margin: inherit;
}

.wsw_card .wsw_card_body .wsw_lc_inner_wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    min-height: 3.57em;
}

.wsw_card .wsw_card_body .wsw_lc_avatar_wrapper {
    position: absolute;
    width: 4.28em;
	height: 4.28em;
	top: .71em;
    left: .71em;
}

.wsw_card .wsw_card_body .wsw_lc_avatar {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.wsw_card .wsw_card_body .wsw_lc_user_info {
	width: 100%;
	width: 18.92em;
    padding: .71em .71em .71em 5.71em;
}

.pv_container .wsw_card .wsw_card_body .wsw_lc_user_info {
	width: 14.92em;
}

.wsw_card .wsw_card_body .wsw_lc_user_name {
    max-width: initial;
	min-height: 1.5em;
	width: 100%;
    white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: #515151;
    margin: 0;
    font-weight: 600;
	font-size: 1em;
	line-height: 1.4;
}

.wsw_card .wsw_card_body .wsw_lc_user_prof {
    font-size: .64em;
    color: #828282;
	display: block;
	line-height: 1.5;
}

.wsw_card .wsw_card_body .wsw_lc_url {
	display: inline-block;
    line-height: 1;
    text-align: center;
    text-decoration: none !important;
    font-weight: 600;
    font-size: .71em;
    text-transform: uppercase;
    padding: .57em .57em;
    border-radius: .21em;
    margin: .35em 0;
    color: #fff !important;
}

.wsw_card .wsw_card_body .wsw_lc_buttons {
	padding: .71em;
	display: flex;
	flex-direction: column;
	margin: 0 0 0 auto;
}

.wsw_card .wsw_card_body .wsw_lc_buttons button {
    background: transparent;
    padding: 0;
    border: none;
    color: #b2b2b2;
    cursor: pointer;
	margin-left: auto !important;
	font-size: 16px;
	line-height: 1;
}

.wsw_card .wsw_card_body .wsw_lc_buttons button:hover {
    color: #8e8e8e;
}

.wsw_card .wsw_card_body .wsw_lc_buttons button img {
	width: 16px;
}

.wsw_card .wsw_card_body .wsw_lc_buttons .wsw_lc_btn_linkcard img {
	width: 20px;
}

.wsw_card .wsw_card_body .wsw_lc_buttons .wsw_lc_btn_top {
	margin-top: 0;
	margin-bottom: auto;
}

.wsw_card .wsw_card_body .wsw_lc_buttons .wsw_lc_btn_middle {
	margin-top: auto;
	margin-bottom: auto;
}
.wsw_card .wsw_card_body .wsw_lc_buttons .wsw_lc_btn_middle {
	margin-top: auto;
	margin-bottom: 0;
}
/* FIXES */
.panel .nav_filter {
	z-index: 100;
}
.panel .left_nav {
	z-index: 105;
}
.pthumb_name_wrapper_text h4 {
	font-size: 1.6em !important;
}
</style>

<style>
.ws_wrapper .edicion_block{
    width:150px !important;
    padding:0px;
}
.ws_wrapper .edicion_block label{
    display:none  !important;
}
.ws_wrapper .input-group {
    margin-bottom:5px !important;
}
</style>